import { Group } from '@visx/group';
import { Line } from '@visx/shape';
import React, { CSSProperties, FC, useContext } from 'react';
import { ChartContext } from '../ChartContext';
import { callOrValue, isDefined } from '../utils/chartUtils';

const GROUP_STYLE: CSSProperties = { pointerEvents: 'none' };

/**
 * The props for the {@link ChartCrossHair} component.
 */
export interface ChartCrossHairProps {
    fullHeight?: boolean;
    fullWidth?: boolean;
    circleSize?: number;
    circleFill?: string;
    circleStroke?: string;
    circleStyles?: CSSProperties;
    lineStyles?: CSSProperties;
    showCircle?: boolean;
    showMultipleCircles?: boolean;
    showHorizontalLine?: boolean;
    showVerticalLine?: boolean;
    stroke?: string;
    strokeDasharray?: string;
    strokeWidth?: number;

    // likely injected by parent
    axis?;
    getX?;
    getY?;
    getOpen?;
    getClose?;
    point?: any; // eslint-disable-line react/forbid-prop-types
    series?: {
        [key: string]: any;
    };
}

/**
 * @category Component
 * @group Chart
 */
const ChartCrossHair: FC<ChartCrossHairProps> = ({
    circleSize = 4,
    circleFill = 'gray',
    circleStroke = '#ffffff',
    circleStyles = {
        pointerEvents: 'none',
    },
    axis,
    getX,
    getY,
    getOpen,
    getClose,
    point = {},
    lineStyles = {
        pointerEvents: 'none',
    },
    fullHeight = true,
    fullWidth = true,
    series = {},
    showCircle = true,
    showMultipleCircles = false,
    showHorizontalLine = true,
    showVerticalLine = true,
    stroke = 'lightgray',
    strokeDasharray = '5,2',
    strokeWidth = 1,
}) => {
    const { legend, innerWidth, innerHeight, groupDimensions, xScales, yScales } = useContext(ChartContext);

    if (!xScales || !yScales) return null;

    const [xMin, xMax] = [0, innerWidth];
    const [yMin, yMax] = [0, innerHeight];

    const circleData =
        showMultipleCircles && series && Object.keys(series).length > 0
            ? Object.keys(series)
                  .filter((x) => !legend.state[x])
                  .map((seriesKey) => ({
                      seriesKey,
                      ...series[seriesKey],
                  }))
            : [
                  {
                      axis,
                      getX,
                      getY,
                      getOpen,
                      getClose,
                      point,
                  },
              ];

    const circlePositions = circleData.map(({ getX, getY, getOpen, getClose, axis, stroke, point }) => {
        const {
            position: { top, left },
            offset,
        } = groupDimensions[axis];
        const xScale = xScales[axis] ?? xScales['undefined'];
        const yScale = yScales[axis] ?? yScales['undefined'];

        const getScaledX = (point) => xScale(getX(point) || 0) + (xScale.bandwidth ? xScale.bandwidth() / 2 : 0);
        const getScaledY = (point) =>
            yScale((getOpen?.(point) ?? getClose?.(point) ?? getY?.(point)) || 0) +
            (yScale.bandwidth ? yScale.bandwidth() / 2 : 0);
        return { x: getScaledX(point) + left + offset.left, y: getScaledY(point) + top + offset.top, stroke };
    });

    return (
        <Group style={GROUP_STYLE}>
            {showHorizontalLine && !showMultipleCircles && isDefined(circlePositions?.[0]?.y) && (
                <Line
                    from={{ x: xMin, y: circlePositions[0].y }}
                    to={{ x: fullWidth ? xMax : circlePositions[0].x, y: circlePositions[0].y }}
                    style={lineStyles}
                    stroke={stroke}
                    strokeDasharray={strokeDasharray}
                    strokeWidth={strokeWidth}
                />
            )}
            {showVerticalLine && isDefined(circlePositions?.[0]?.x) && (
                <Line
                    from={{ x: circlePositions[0].x, y: yMax }}
                    to={{ x: circlePositions[0].x, y: fullHeight ? yMin : circlePositions[0].y }}
                    style={lineStyles}
                    stroke={stroke}
                    strokeDasharray={strokeDasharray}
                    strokeWidth={strokeWidth}
                />
            )}

            {(showCircle || showMultipleCircles) &&
                circleData.map((point, index) => {
                    const { x, y, stroke } = circlePositions[index];

                    return (
                        isDefined(x) &&
                        isDefined(y) && (
                            <circle
                                key={`circle-${point.seriesKey || index}`}
                                cx={x}
                                cy={y}
                                r={callOrValue(circleSize, point, index)}
                                fill={stroke ?? callOrValue(circleFill, point, index)}
                                strokeWidth={1}
                                stroke={callOrValue(circleStroke, point, index)}
                                style={callOrValue(circleStyles, point, index)}
                            />
                        )
                    );
                })}
        </Group>
    );
};

ChartCrossHair.displayName = 'ChartCrossHair';

/** @ignore */
export { ChartCrossHair };
