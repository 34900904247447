import React, { FC, memo, useContext } from 'react';
import { Popover } from '../../Popover';
import { ChartContextMenu } from '../ChartContext';
import { ChartOptions } from '../chartTypes';

interface RenderChartMenuProps {
    ContextMenuComponent: ChartOptions['contextMenu'];
}
const Component: FC<RenderChartMenuProps> = ({ ContextMenuComponent }) => {
    const ctx = useContext(ChartContextMenu);

    const { open, args, hideContextMenu } = ctx;

    if (!ContextMenuComponent || !open) {
        return null;
    }

    return (
        <Popover
            open={open}
            onClose={hideContextMenu}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center'
            }}
            anchorEl={args?.event.target as Element}
        >
            <ContextMenuComponent {...args} />
        </Popover>
    );
};

const RenderChartMenu = memo(Component);

export { RenderChartMenu };
