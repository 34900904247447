import cx from 'classnames';
import React, { FC, forwardRef, HTMLProps } from 'react';
import { Paper, PaperProps } from '../Paper/Paper';
import styles from './Toolbar.module.css';

/**
 * The props of the {@link ToolbarWrapper} component.
 * @category Props
 */
export interface ToolbarWrapperProps extends PaperProps {
    disableMargin?: boolean;
}

/**
 * @category Component
 * @group Toolbar
 */
export const ToolbarWrapper: FC<ToolbarWrapperProps> = ({ disableMargin = false, className = '', ...other }) => {
    return (
        <Paper
            className={cx(styles.toolbar_wrapper, className, { [styles.disableMargin]: disableMargin })}
            {...other}
        />
    );
};

/**
 * The props of the {@link Toolbar} component.
 * @category Props
 */
export interface ToolbarProps extends Omit<HTMLProps<HTMLDivElement>, 'wrap'> {
    disablePadding?: boolean;
    wrap?: boolean;
    component?: any;
}

/**
 * @category Component
 * @group Toolbar
 */
const Toolbar = forwardRef(
    ({ disablePadding = true, component: Component = 'div', wrap = false, className, ...other }: ToolbarProps, ref) => {
        return (
            <Component
                ref={ref}
                className={cx(styles.toolbar, { [styles.padding]: !disablePadding, [styles.wrap]: wrap }, className)}
                {...other}
            />
        );
    }
);

Toolbar.displayName = 'Toolbar';

export { Toolbar };
