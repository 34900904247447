import { Node } from 'reactflow';

import { ILineupSchemeNodes } from 'api';
import { DiagramNodeData, NodeHandle } from 'domain/scheme';

export const convertToSchemeNode = (node: ILineupSchemeNodes, handles: NodeHandle[] = []): Node<DiagramNodeData> => ({
  id: node.id.toString(),
  type: node.componentType,
  position: {
    x: node.coordinateX,
    y: node.coordinateY
  },
  data: {
    handles,
    label: node.name,
    facilityId: node.componentId,
    status: node.status,
    backgroundColor: node.backgroundColor,
    textColor: node.textColor,
    size: {
      width: node.width,
      height: node.height
    },
    original: node
  }
});

export const convertToDiagramNodeData = (
  nodes: ILineupSchemeNodes[],
  handles: Record<string, NodeHandle[]>
): Node<DiagramNodeData>[] =>
  nodes.map<Node<DiagramNodeData>>((node) => convertToSchemeNode(node, handles[node.id.toString()] ?? []));
