import { Draft } from '@reduxjs/toolkit';
import { SchemeComponentType } from 'api';
import { castDraft, castImmutable, Immutable } from 'immer';
import {
  setMeterStationStatusChange,
  syncConnectionsWithParent
} from 'store/official-inputs/lineup/actions/lineupStatusChanges';
import { ToggleStatusPayload } from '../../lineup/types/ToggleStatusPayload';
import { OfficialInputChanges, OfficialInputState } from '../../OfficialInputState';
import {
  SetWellStatusBulkPayload,
  ToggleMSStatusPayload,
  ToggleWellInputStatusPayload,
  ToggleWellStatusPayload
} from '../types/ToggleStatusPayload';
import { WellInputTableRow } from '../utils/convertWellInputData';

function getWeekDatesBetween(state: Draft<OfficialInputState>, startWeekDateId: number, endWeekDateId: number) {
  const startTime = state.wellInput.datesById.get(startWeekDateId).weekDate.getTime();
  const endTime = state.wellInput.datesById.get(endWeekDateId).weekDate.getTime();

  const weekDates = state.wellInput.dates.filter((x) => {
    const time = x.weekDate.getTime();
    return time >= startTime && time < endTime;
  });

  return weekDates.map((x) => x.id);
}

export function toggleWellInputMeterStationStatusChange(
  state: Draft<OfficialInputState>,
  payload: ToggleMSStatusPayload
) {
  const { rowId, weekDate, value, event, syncWells, syncValves } = payload;

  const row = state.wellInput.rowsById.get(rowId);
  let rowChanges = state.changes.MeterStation.get(rowId);
  if (!rowChanges) {
    rowChanges = new Map();
    state.changes.MeterStation.set(rowId, rowChanges);
  }

  const originalStatus = row.msStatuses.get(weekDate);
  const previousStatus = rowChanges.get(weekDate)?.status ?? originalStatus?.status;
  const newStatus = value ?? !previousStatus;

  if (originalStatus.status !== newStatus) {
    const lineupRow = state.lineup.rowsById.get(rowId);
    const lineup = lineupRow?.parentStatuses?.get(weekDate);
    rowChanges.set(weekDate, {
      lineup,
      wellInput: originalStatus,
      status: newStatus
    });
  } else {
    rowChanges.delete(weekDate);
  }

  if (syncWells) {
    // eslint-disable-next-line no-use-before-define
    syncWellsWithMS(state, row, weekDate, newStatus);
  }

  if (syncValves) {
    const lineupRow = state.lineup.rowsById?.get(rowId);
    if (lineupRow) syncConnectionsWithParent(state, lineupRow, weekDate, newStatus);
  }

  if (!event) {
    return;
  }

  if (event.shiftKey && state.wellInput.previousStatusChange?.rowId === rowId) {
    const weekDates = getWeekDatesBetween(state, state.wellInput.previousStatusChange.weekDate, weekDate);
    for (const weekDate of weekDates) {
      toggleWellInputMeterStationStatusChange(state, { rowId, weekDate, value: newStatus, syncWells, syncValves });
    }
  } else {
    state.wellInput.previousStatusChange = castDraft(payload);
  }
}

export function getWellStatus(changes: Immutable<OfficialInputChanges>, row: WellInputTableRow, weekDate: number) {
  if (row.rowType !== SchemeComponentType.Well) {
    return undefined;
  }

  return changes.Well.get(row.id)?.get(weekDate) ?? row.wellStatuses.get(weekDate);
}

export function toggleWellStatusChange(state: Draft<OfficialInputState>, payload: ToggleWellStatusPayload) {
  const { rowId, weekDate, value, event, syncMS } = payload;

  const row = state.wellInput.rowsById.get(rowId);
  let rowChanges = state.changes.Well.get(rowId);
  if (!rowChanges) {
    rowChanges = new Map();
    state.changes.Well.set(rowId, rowChanges);
  }

  const originalStatus = row.wellStatuses.get(weekDate);
  const previousStatus = rowChanges.get(weekDate)?.status ?? originalStatus?.status;
  const newStatus = value ?? !previousStatus;

  if (originalStatus && originalStatus.status !== newStatus) {
    rowChanges.set(weekDate, {
      ...originalStatus,
      status: newStatus
    });
  } else {
    rowChanges.delete(weekDate);
  }

  if (newStatus) {
    // eslint-disable-next-line no-use-before-define
    setWellParentStatusToYes(state, row.parentId, weekDate);
  } else {
    if (syncMS) {
      // eslint-disable-next-line no-use-before-define
      syncMSWithWells(state, row, weekDate, newStatus);
    }
  }

  if (!event) {
    return;
  }

  if (event.shiftKey && state.wellInput.previousStatusChange?.rowId === rowId) {
    const weekDates = getWeekDatesBetween(state, state.wellInput.previousStatusChange.weekDate, weekDate);
    for (const weekDate of weekDates) {
      toggleWellStatusChange(state, { rowId, weekDate, value: newStatus, syncMS });
    }
  } else {
    state.wellInput.previousStatusChange = castDraft(payload);
  }
}

export function setWellStatusBulkChange(state: Draft<OfficialInputState>, payload: SetWellStatusBulkPayload) {
  const { rowId, items, syncMS } = payload;

  for (const { range, status } of items) {
    const [startDate, endDate] = range;
    const startTime = startDate.getTime();
    const endTime = endDate.getTime();

    const weekDates = state.wellInput.dates.filter(
      (weekDate) => weekDate.weekDate.getTime() >= startTime && weekDate.weekDate.getTime() <= endTime
    );

    for (const weekDate of weekDates) {
      toggleWellStatusChange(state, { rowId, weekDate: weekDate.id, value: status, syncMS });
    }
  }
}

export function setWellInputStatusChanges(state: Draft<OfficialInputState>, payload: ToggleWellInputStatusPayload) {
  const { origin, selectedRows, selectedWeekDates } = payload;
  const { rowId, weekDate } = origin;

  const row = state.wellInput.rowsById.get(rowId);
  let newStatus: boolean = undefined;

  const msPayload = row.rowType === SchemeComponentType.MeterStation ? (origin as ToggleMSStatusPayload) : undefined;
  const wellPayload = row.rowType === SchemeComponentType.Well ? (origin as ToggleWellStatusPayload) : undefined;

  if (row.rowType === SchemeComponentType.MeterStation) {
    toggleWellInputMeterStationStatusChange(state, msPayload);
    newStatus = state.changes.MeterStation.get(rowId)?.get(weekDate)?.status ?? row.msStatuses.get(weekDate)?.status;
  }

  if (row.rowType === SchemeComponentType.Well) {
    toggleWellStatusChange(state, wellPayload);
    newStatus = getWellStatus(castImmutable(state).changes, row, weekDate).status;
  }

  const isSelectedRow = selectedRows.some((x) => x === rowId);
  const isSelectedWeekDate = selectedWeekDates.some((x) => x === weekDate);

  if ((!isSelectedRow && !isSelectedWeekDate) || newStatus === undefined) {
    return;
  }

  const rows = isSelectedWeekDate && !isSelectedRow ? [rowId] : selectedRows;
  const weekDates = isSelectedRow && !isSelectedWeekDate ? [weekDate] : selectedWeekDates;

  for (const selectedRowId of rows) {
    const selectedRow = state.wellInput.rowsById.get(selectedRowId);
    for (const selectedWeekDate of weekDates) {
      if (selectedRow.rowType === SchemeComponentType.MeterStation) {
        toggleWellInputMeterStationStatusChange(state, {
          rowId: selectedRowId,
          weekDate: selectedWeekDate,
          value: newStatus,
          syncWells: msPayload?.syncWells,
          syncValves: msPayload?.syncValves
        });
      }

      if (selectedRow.rowType === SchemeComponentType.Well) {
        toggleWellStatusChange(state, {
          rowId: selectedRowId,
          weekDate: selectedWeekDate,
          value: newStatus,
          syncMS: wellPayload?.syncMS
        });
      }
    }
  }
}

export function setWellInputStatusArray(state: Draft<OfficialInputState>, payload: ToggleStatusPayload[]) {
  if (payload?.length === 0) {
    return;
  }

  for (const payloadRow of payload) {
    const { rowId, weekDate, value } = payloadRow;
    const row = state.wellInput.rowsById.get(rowId);

    if (row.rowType === SchemeComponentType.MeterStation) {
      toggleWellInputMeterStationStatusChange(state, {
        rowId,
        weekDate,
        value,
        syncWells: !value,
        syncValves: !value
      });
    }

    if (row.rowType === SchemeComponentType.Well) {
      toggleWellStatusChange(state, {
        rowId,
        weekDate,
        value,
        syncMS: true
      });
    }
  }
}

function setWellParentStatusToYes(state: Draft<OfficialInputState>, parentRowId: string, weekDate: number) {
  const parentRow = state.wellInput.rowsById.get(parentRowId);
  const parentRowStatus = parentRow.msStatuses.get(weekDate)?.status;
  const status = state.changes.MeterStation.get(parentRow.id)?.get(weekDate)?.status ?? parentRowStatus;
  if (status !== true) {
    setMeterStationStatusChange(state, { rowId: parentRowId, weekDate, value: true });
  }
}

export function syncWellsWithMS(
  state: Draft<OfficialInputState>,
  row: WellInputTableRow,
  weekDate: number,
  newStatus: boolean
) {
  for (const wellRow of row.subRows) {
    toggleWellStatusChange(state, { rowId: wellRow.id, weekDate, value: newStatus });
  }
}

function syncMSWithWells(
  state: Draft<OfficialInputState>,
  row: WellInputTableRow,
  weekDate: number,
  newStatus: boolean
) {
  const msRow = state.wellInput.rowsById.get(row.parentId);
  const isSameStatus = msRow.subRows.every((wellRow) => {
    const connectionStatus = getWellStatus(castImmutable(state).changes, wellRow, weekDate).status;
    return connectionStatus === newStatus;
  });

  if (!isSameStatus) {
    return;
  }

  const msStatus =
    state.changes.MeterStation.get(msRow.id)?.get(weekDate)?.status ?? msRow.msStatuses.get(weekDate)?.status;

  if (msStatus !== newStatus) {
    toggleWellInputMeterStationStatusChange(state, {
      rowId: msRow.id,
      weekDate,
      value: newStatus,
      syncValves: true
    });
  }
}
