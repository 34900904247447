import { Group } from '@visx/group';
import React, { forwardRef, Ref, useMemo } from 'react';
import { Paper } from '../Paper';
import { PaperProps } from '../Paper/Paper';
import { setRef } from '../util';
import styles from './Chart.module.css';
import { ChartContext, ChartContextMenu, ChartTooltipContext } from './ChartContext';
import { ChartInstance, ChartOptions } from './chartTypes';
import { ChartMiniMapProvider } from './components/Zoom/ChartMiniMapProvider';
import { ChartZoomMiniMap } from './components/Zoom/ChartZoomMiniMap';
import { ZoomButtonController } from './components/Zoom/ZoomButtonController';
import {
    RenderAnnotations,
    RenderAxis,
    RenderChart,
    RenderContainer,
    RenderCrossHairs,
    RenderGrid,
    RenderTooltip,
    useChart
} from './instance';
import RenderChartBackground from './instance/RenderChartBackground';
import { RenderChartMenu } from './instance/RenderChartMenu';
import { DEFAULT_SIZE } from './utils/chartUtils';

export interface ChartProps extends ChartOptions {
    innerRef?: Ref<SVGSVGElement>;
    PaperProps?: PaperProps;
    backgroundColor?: string;
    backgroundOpacity?: number;
}
/**
 * @category Component
 * @group Chart
 */
export const Chart = forwardRef((props: ChartProps, ref: Ref<ChartInstance>) => {
    const {
        height,
        width,
        innerRef,
        eventTrigger = 'series',
        PaperProps,
        contextMenu: contextMenuComponent,
        miniMap
    } = props;
    const { containerRef, instance, tooltip, contextMenu } = useChart(props);

    setRef(ref, instance);

    const { margin, layers } = instance;

    const paperStyle = useMemo(() => ({ ...PaperProps?.style, width }), [PaperProps?.style, width]);
    return (
        <ChartContext.Provider value={instance}>
            <ChartTooltipContext.Provider value={tooltip}>
                <ChartContextMenu.Provider value={contextMenu}>
                    <ChartMiniMapProvider options={miniMap}>
                        <Paper className={styles.root} {...PaperProps} style={paperStyle}>
                            {layers.Toolbar}
                            <div className={styles.container} ref={containerRef}>
                                <svg
                                    ref={innerRef}
                                    role="img"
                                    viewBox={`0 0 ${width ?? DEFAULT_SIZE.width} ${height ?? DEFAULT_SIZE.height}`}
                                    preserveAspectRatio="xMinYMin meet"
                                >
                                    <Group left={margin.left} top={margin.top}>
                                        <RenderChartBackground />
                                        <RenderGrid />
                                        <RenderAxis />
                                        <RenderChart layer="back" />
                                        {layers.Zoom}
                                        <RenderChart layer="front" />
                                        <RenderAnnotations layer="back" />
                                        {eventTrigger === 'container' && <RenderContainer />}
                                        <RenderAnnotations layer="front" />
                                        <RenderCrossHairs />
                                        {layers.RestChildren}
                                    </Group>
                                    <ChartZoomMiniMap />
                                </svg>
                                <RenderTooltip />
                                <ZoomButtonController />
                                <RenderChartMenu ContextMenuComponent={contextMenuComponent} />
                            </div>
                            {layers.Legend}
                            {layers.Brush}
                        </Paper>
                    </ChartMiniMapProvider>
                </ChartContextMenu.Provider>
            </ChartTooltipContext.Provider>
        </ChartContext.Provider>
    );
});
