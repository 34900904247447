import { EventEmitter } from 'events';
import { isEqual } from 'lodash';
import React, {
    forwardRef,
    memo,
    PropsWithChildren,
    ReactElement,
    Ref,
    RefAttributes,
    useEffect,
    useMemo,
    useRef
} from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { useTable } from 'react-table';

import { setRef } from '../util';
import { DataGridTextCellFull } from './cells';
import { DataGridContext } from './DataGridContext';
import { DataGridDefaultFilter } from './filters';

import { DataGridColumnOptions, DataGridProps, DataGridSettings } from './types';

import { useComponentsConfiguration } from '../configuration/useComponentsConfiguration';
import { DataGridContent } from './DataGridContent';
import { DataGridTable } from './DataGridTable';
import { DataGridToolbar } from './DataGridToolbar';
import { useDataGridColumns } from './hooks/useDataGridColumns';
import { useDataGridPlugins } from './hooks/useDataGridPlugins';
import { useDataGridOptions } from './hooks/useDataGridSettings';
import { useDataGridState } from './hooks/useDataGridState';
import * as sortTypes from './sortTypes';
import { DataGridInstance } from './types/DataGridInstance';
import { DataGridReactTableOptions } from './types/DataGridReactTableOptions';

const defaultChildren = (
    <>
        <DataGridToolbar />
        <DataGridContent />
    </>
);

const defaultData = [];

/**
 * @category Component
 * @group Data Grid
 */
export const DataGrid = memo(
    forwardRef(function DataGrid<T extends object = any>(props: DataGridProps<T>, ref: Ref<DataGridInstance>) {
        const mergedProps = useComponentsConfiguration('DataGrid', props);

        const {
            getRowId,
            height = null,
            fullWidth = true,
            width = null,
            rowHeight = 35,
            virtualScrolling = false,
            blockLayout: blockLayoutProp = false,
            dragAndDrop: dragAndDropProp = false,
            dragKey = null,
            onDrag,
            chooseColumns = false,
            resizeColumns = false,
            sorting: sortingProp = false,
            filtering: filteringProp = false,
            selection: selectionProp = false,
            expanding: expandingProp = false,
            selectKey = null,
            initialSelect = null,
            onSelect,
            onSelectRow,
            grouping: groupingProp = false,
            onGroup,
            onFilter,
            onSort,
            initialExpaned = null,
            onExpanded,
            pagination: paginationProp = false,
            inlineEdit = false,
            onInlineEdit,
            onAdd,
            onView,
            formProps,
            onEdit,
            onDelete,
            contextMenu: contextMenuProp = undefined,
            columns: dirtyColumns = [],
            data = defaultData,
            InlineToolbar,
            children = defaultChildren,
            paperProps,
            initialScroll,
            RowWrapper,
            CellWrapper,
            HeaderCellWrapper,
            ExpandCellComponent,
            RowComponent,
            CellComponent,
            HeaderCellComponent,
            BodyComponent,
            HeaderComponent,
            TableComponent,
            ...other
        } = mergedProps;

        const options = useDataGridOptions(
            {
                blockLayout: blockLayoutProp,
                dragAndDrop: dragAndDropProp,
                sorting: sortingProp,
                filtering: filteringProp,
                selection: selectionProp,
                grouping: groupingProp,
                expanding: expandingProp,
                pagination: paginationProp,
                contextMenu: contextMenuProp
            },
            props
        );

        const {
            blockLayout,
            dragAndDrop,
            sorting,
            filtering,
            selection,
            grouping,
            pagination,
            contextMenu,
            expanding
        } = options;

        const plugins = useDataGridPlugins({
            ...options,
            resizeColumns,
            inlineEdit
        });

        const columns = useDataGridColumns(dirtyColumns);

        const initialState = useDataGridState(
            initialSelect,
            initialExpaned,
            { pagination, sorting, filtering, grouping },
            columns
        );

        const defaultColumn = useMemo(
            () =>
                ({
                    ...DataGridTextCellFull,
                    Filter: DataGridDefaultFilter,
                    accessor: null,
                    minWidth: 35,
                    width: 150,
                    maxWidth: 500
                } as DataGridColumnOptions<T>),
            []
        );

        const settings = useMemo(
            () =>
                ({
                    height,
                    fullWidth,
                    width,
                    rowHeight,
                    virtualScrolling,
                    blockLayout,
                    chooseColumns,
                    resizeColumns,
                    sorting,
                    columns,
                    selectKey,
                    selection,
                    onSelect,
                    onSelectRow,
                    filtering,
                    grouping,
                    expanding,
                    onGroup,
                    pagination,
                    inlineEdit,
                    onInlineEdit,
                    onAdd,
                    onView,
                    onEdit,
                    onDelete,
                    contextMenu,
                    dragAndDrop,
                    dragKey,
                    onDrag,
                    InlineToolbar,
                    initialScroll,
                    RowWrapper,
                    CellWrapper,
                    HeaderCellWrapper,
                    ExpandCellComponent,
                    RowComponent,
                    CellComponent,
                    HeaderCellComponent,
                    BodyComponent,
                    HeaderComponent,
                    TableComponent
                } as DataGridSettings<T>),
            [
                height,
                fullWidth,
                width,
                rowHeight,
                virtualScrolling,
                blockLayout,
                chooseColumns,
                resizeColumns,
                sorting,
                columns,
                selectKey,
                selection,
                onSelect,
                onSelectRow,
                filtering,
                grouping,
                expanding,
                onGroup,
                pagination,
                inlineEdit,
                onInlineEdit,
                onAdd,
                onView,
                onEdit,
                onDelete,
                contextMenu,
                dragAndDrop,
                dragKey,
                onDrag,
                InlineToolbar,
                initialScroll,
                RowWrapper,
                CellWrapper,
                HeaderCellWrapper,
                ExpandCellComponent,
                RowComponent,
                CellComponent,
                HeaderCellComponent,
                BodyComponent,
                HeaderComponent,
                TableComponent
            ]
        );

        const bodyRef = useRef<HTMLElement>(null);
        const events = useMemo(() => new EventEmitter(), []);

        const form = useForm({
            mode: 'onSubmit',
            reValidateMode: 'onSubmit',
            shouldUnregister: true,
            shouldFocusError: false,
            ...formProps
        });

        const fieldArray = useFieldArray({
            control: form.control,
            name: 'row'
        });

        const reactTableOptions = useMemo(
            () => {
                const result: DataGridReactTableOptions<T> = {
                    bodyRef,
                    columns,
                    data,
                    defaultColumn,
                    initialState,
                    settings,
                    events,
                    form,
                    fieldArray,
                    autoResetExpanded: false,
                    autoResetHiddenColumns: false,
                    autoResetSortBy: false,
                    sortTypes,
                    manualRowSelectedKey: selectKey,
                    getRowId
                };

                if (sorting?.autoResetSortBy !== undefined) {
                    result.autoResetSortBy = sorting.autoResetSortBy;
                }

                if (filtering?.autoResetFilters !== undefined) {
                    result.autoResetFilters = filtering.autoResetFilters;
                }

                if (grouping?.autoResetGroupBy !== undefined) {
                    result.autoResetGroupBy = grouping.autoResetGroupBy;
                }

                if (selection?.autoResetSelectedRows !== undefined) {
                    result.autoResetSelectedRows = selection.autoResetSelectedRows;
                }

                if (pagination) {
                    result.manualPagination = pagination.manual;
                    result.paginateExpandedRows = pagination.paginateGroups;
                }

                if (expanding?.autoResetExpanded !== undefined) {
                    result.autoResetExpanded = expanding.autoResetExpanded;
                }

                return result;
            },

            // eslint-disable-next-line react-hooks/exhaustive-deps
            [getRowId, filtering, sorting, grouping, columns, pagination, settings, data]
        );

        const instance = useTable<T>(reactTableOptions, ...plugins) as DataGridInstance;

        // useWhyDidYouUpdate('DataGrid', {
        //     options,
        //     plugins,
        //     columns,
        //     initialState,
        //     settings,
        //     filtering,
        //     sorting,
        //     grouping,
        //     pagination,
        //     data,
        //     reactTableOptions,
        //     instance
        // });

        const sideEffects = useMemo(() => {
            const { rowState, filters, sortBy } = instance.state;
            const editing = rowState ? Object.values(rowState).some((row: any) => row.isEditing) : false;
            const filtering = filters?.length > 0 ? true : false;
            const sorting = sortBy?.length > 0 ? true : false;
            return editing || filtering || sorting;
        }, [instance.state]);

        instance.sideEffects = sideEffects;

        setRef(ref, instance);

        const {
            state: { selectedRowIds, groupBy, filters, sortBy, expanded },
            selectedFlatRows
        } = instance;

        const prevSelected = useRef([]);
        useEffect(() => {
            if (selectedRowIds == null) return;
            const currentSelected = Object.entries(selectedRowIds)
                .filter(([, value]) => value)
                .map(([key]) => key)
                .sort();
            if (!isEqual(prevSelected.current, currentSelected) && onSelect) {
                prevSelected.current = currentSelected;
                const originalRows = selectedFlatRows.map((row) => row.original);
                onSelect(originalRows);
            }

            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [selectedFlatRows]);

        const prevGroup = useRef([]);
        useEffect(() => {
            if (groupBy == null) return;
            if (!isEqual(prevGroup.current, groupBy) && onGroup) {
                prevGroup.current = groupBy;
                onGroup(groupBy);
            }
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [groupBy]);

        const prevFilters = useRef([]);
        useEffect(() => {
            if (filters == null) {
                return;
            }
            if (!isEqual(prevFilters.current, filters) && onFilter) {
                prevFilters.current = filters;
                onFilter(filters);
            }
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [filters]);

        const prevSort = useRef(null);
        useEffect(() => {
            if (sortBy == null) return;
            if (!isEqual(prevSort.current, sortBy) && onSort) {
                prevSort.current = sortBy;
                onSort(sortBy);
            }
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [sortBy]);

        const prevExpanded = useRef(null);
        useEffect(() => {
            if (expanded == null) return;
            if (!isEqual(prevExpanded.current, expanded) && onExpanded) {
                prevExpanded.current = expanded;
                onExpanded(expanded);
            }
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [expanded]);

        events.emit('updated');

        return (
            <DataGridContext.Provider value={{ ...instance }}>
                <DataGridTable paperProps={paperProps} {...other}>
                    {children}
                </DataGridTable>
            </DataGridContext.Provider>
        );
    })
) as <T extends object>(
    props: PropsWithChildren<DataGridProps<T>> & RefAttributes<DataGridInstance<T>>
) => ReactElement;
