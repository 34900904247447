import { GetWeekDatesDto, IGetWeekDatesDto, IWellPIDto, IWellPIInputDto, SchemeComponentType } from 'api';
import { sortBy } from 'lodash';
import { getMondaysOnlyDates, groupDatesByWeekNumber } from 'store/official-inputs/lineup/utils/groupDatesByWeekNumber';
import { getDifferenceInDays } from 'utils/dateUtil';
import { calculateWellPI } from './wellPIInputChange';

export type WellPITableRowType = SchemeComponentType;

export interface WellPITableRow {
  id: string;
  parentId?: string;
  groupId?: string;
  rowType: WellPITableRowType;
  name: string;
  subRows?: WellPITableRow[];
  originalId?: number;
  wellId?: number;
  rateOfChange?: number;
  piValue?: number;
  inputs?: Map<number, IWellPIInputDto>;
}

function processWellPIData(
  wellPIs: IWellPIDto[],
  datesLookup: Map<number, IGetWeekDatesDto>,
  rowsLookup: Map<string, WellPITableRow>,
  parentRowsLookup: Map<string, WellPITableRow>,
  adjustWeekDates?: GetWeekDatesDto[]
) {
  for (const { meterStationName, id, wellId, wellName, wellPIInputs, piValue, rateOfChange } of wellPIs) {
    const msRowId = `${meterStationName}`;
    let msRow = rowsLookup.get(msRowId);
    if (!msRow) {
      msRow = {
        id: msRowId,
        rowType: SchemeComponentType.MeterStation,
        name: meterStationName,
        subRows: []
      };
      rowsLookup.set(msRowId, msRow);
      parentRowsLookup.set(msRowId, msRow);
    }

    const wellRowId = msRowId + `, ${wellId}`;

    let wellRow = rowsLookup.get(wellRowId);
    if (!wellRow) {
      wellRow = {
        id: wellRowId,
        parentId: msRowId,
        rowType: SchemeComponentType.Well,
        name: wellName,
        originalId: id,
        wellId,
        piValue,
        rateOfChange,
        inputs: new Map()
      };
      msRow.subRows.push(wellRow);
      rowsLookup.set(wellRowId, wellRow);
    }

    for (const input of wellPIInputs) {
      const { weekDateId, weekDate } = input;
      if (!datesLookup.has(weekDateId)) {
        datesLookup.set(weekDateId, {
          id: weekDateId,
          weekDate: new Date(weekDate)
        });
      }

      wellRow.inputs.set(weekDateId, input);
    }
  }

  const sortedAdjustWeekDates = sortBy(adjustWeekDates, 'weekDate');
  for (const [index, weekDate] of sortedAdjustWeekDates.entries()) {
    if (!datesLookup.has(weekDate.id)) {
      datesLookup.set(weekDate.id, weekDate);
    }

    for (const row of rowsLookup.values()) {
      if (row.rowType !== SchemeComponentType.Well) {
        continue;
      }

      if (!row.inputs.has(weekDate.id)) {
        const prevWeekDate = sortedAdjustWeekDates[index - 1];
        const prevInput = row.inputs.get(prevWeekDate?.id);
        const dayDifference = getDifferenceInDays(prevWeekDate?.weekDate, weekDate?.weekDate) ?? 7;
        row.inputs.set(weekDate.id, {
          weekDateId: weekDate.id,
          weekDate: weekDate.weekDate,
          piValue: calculateWellPI(row.piValue, row.rateOfChange, dayDifference, prevInput?.piValue)
        });
      }
    }
  }
}

function sortRows(parentRowsLookup: Map<string, WellPITableRow>) {
  const rows = Array.from(parentRowsLookup.values());
  rows.sort((a, b) => a.name?.localeCompare(b.name));
  for (const row of rows) {
    row.subRows.sort((a, b) => a.name?.localeCompare(b.name));
  }
  return rows;
}

function sortDates(datesLookup: Map<number, IGetWeekDatesDto>) {
  const dates = Array.from(datesLookup.values());
  dates.sort((a, b) => a.weekDate.getTime() - b.weekDate.getTime());
  return dates;
}

export function convertWellPIData(wellPIs: IWellPIDto[], adjustWeekDates?: GetWeekDatesDto[]) {
  const datesLookup = new Map<number, IGetWeekDatesDto>();
  const rowsLookup = new Map<string, WellPITableRow>();
  const parentRowsLookup = new Map<string, WellPITableRow>();

  processWellPIData(wellPIs, datesLookup, rowsLookup, parentRowsLookup, adjustWeekDates);

  const rows = sortRows(parentRowsLookup);

  const dates = sortDates(datesLookup);

  const datesByWeekNumber = groupDatesByWeekNumber(dates);
  const splitableWeekDateIds = getMondaysOnlyDates(dates);

  return {
    dates,
    datesByWeekNumber,
    splitableWeekDateIds,
    datesLookup,
    rows,
    rowsLookup
  };
}

export type WellPIData = ReturnType<typeof convertWellPIData>;
