import React, { useMemo } from 'react';
import { DataGridContextMenu } from '../components';
import { DataGridProps, DataGridSettings } from '../types';
import { useDirtyProperty } from './useDirtyProperty';

const defaultDragAndDrop: DataGridSettings['dragAndDrop'] = {
    allow: true,
    enabled: true,
    disableWhenSideEffects: true
};

const defaultSorting: DataGridSettings['sorting'] = {
    allow: true,
    autoResetSortBy: false
};

const defaultSelection: DataGridSettings['selection'] = {
    showColumn: false,
    showSelectAll: true,
    mode: 'single'
};

const defaultFiltering: DataGridSettings['filtering'] = {
    allow: true,
    autoResetFilters: false
};

const defaultGrouping: DataGridSettings['grouping'] = {
    allow: true,
    autoResetGroupBy: false
};

const defaultPagination: DataGridSettings['pagination'] = {
    manual: false,
    pageSize: 20,
    pageIndex: 0,
    pageCount: null,
    paginateGroups: false
};

const defaultExpanding: DataGridSettings['expanding'] = {
    allow: true,
    autoResetExpanded: false
};

type DataGridDirtyOptions = Pick<
    DataGridProps,
    | 'blockLayout'
    | 'dragAndDrop'
    | 'sorting'
    | 'filtering'
    | 'selection'
    | 'grouping'
    | 'pagination'
    | 'contextMenu'
    | 'expanding'
>;

export function useDataGridOptions(
    {
        blockLayout: dirtyBlockLayout,
        dragAndDrop: dirtyDragAndDrop,
        sorting: dirtySorting,
        filtering: dirtyFiltering,
        selection: dirtySelection,
        grouping: dirtyGrouping,
        pagination: dirtyPagination,
        contextMenu: dirtyContextMenu,
        expanding: dirtyExpanding
    }: DataGridDirtyOptions,
    { resizeColumns, virtualScrolling, onView, onEdit, onDelete }: DataGridProps
) {
    const blockLayout = dirtyBlockLayout || resizeColumns || virtualScrolling;
    const dragAndDrop = useDirtyProperty(dirtyDragAndDrop, defaultDragAndDrop, true);
    const sorting = useDirtyProperty(dirtySorting, defaultSorting);
    const filtering = useDirtyProperty(dirtyFiltering, defaultFiltering);
    const selection = useDirtyProperty(dirtySelection, defaultSelection);
    const grouping = useDirtyProperty(dirtyGrouping, defaultGrouping);
    const pagination = useDirtyProperty(dirtyPagination, defaultPagination, true);
    const expanding = useDirtyProperty(dirtyExpanding, defaultExpanding);
    const contextMenu = useMemo(() => {
        if (typeof dirtyContextMenu === 'boolean') {
            if (dirtyContextMenu && (onView || onEdit || onDelete)) {
                return <DataGridContextMenu />;
            }

            return undefined;
        }

        return dirtyContextMenu;
    }, [dirtyContextMenu, onView, onDelete, onEdit]);

    return useMemo(
        () => ({
            blockLayout,
            dragAndDrop,
            sorting,
            filtering,
            selection,
            grouping,
            pagination,
            contextMenu,
            expanding
        }),
        [blockLayout, dragAndDrop, sorting, filtering, selection, grouping, pagination, contextMenu, expanding]
    );
}
