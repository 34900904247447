import {
    curveCardinal,
    curveLinear,
    curveMonotoneX,
    curveMonotoneY,
    curveNatural,
    curveStep,
    curveStepAfter,
} from '@visx/curve';
import { CurveFactory } from 'd3-shape';
import { Interpolation } from '..';

export const interpolatorLookup: Record<Interpolation, CurveFactory> = {
    linear: curveLinear,
    cardinal: curveCardinal,
    monotoneX: curveMonotoneX,
    monotoneY: curveMonotoneY,
    natural: curveNatural,
    step: curveStep,
    stepAfter: curveStepAfter,
};
