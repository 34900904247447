import { Group } from '@visx/group';
import React, { cloneElement, FC, memo, useContext } from 'react';

import { ChartContext } from '../ChartContext';
import { componentName, isXAxis } from '../utils/chartUtils';

/**
 * @category Component
 * @group Chart
 */
let RenderAxis: FC = () => {
    const instance = useContext(ChartContext);

    const {
        groups,
        AxisGroups,
        groupDimensions,
        axisGrid,
        xScales,
        yScales,
        xAxisOrientationRef: xAxisOrientation,
        yAxisOrientationRef: yAxisOrientation
    } = instance;

    return (
        <>
            {groups.map((group) => {
                const axis = AxisGroups[group];
                const {
                    position: { top, left },
                    size: { height, width }
                } = groupDimensions[group];

                return (
                    <Group key={group} top={top} left={left} height={height} width={width} className="render-axis">
                        {axis?.map((Child, index) => {
                            const name = componentName(Child);
                            const axis = Child.props.id;

                            if (isXAxis(name)) {
                                xAxisOrientation.current = Child.props.orientation;
                            } else if (!yAxisOrientation) {
                                yAxisOrientation.current = Child.props.orientation;
                            }

                            const { numTicks } = axisGrid[group][index];

                            return (
                                <g key={`${group}-${index}`} clipPath={`url(#${group}-axis-${index}-clip-path`}>
                                    {cloneElement(Child, {
                                        key: `${group}-axis-${index}`,
                                        numTicks,
                                        scale: isXAxis(name) ? xScales[axis] : yScales[axis],
                                        rangePadding:
                                            Child.props.rangePadding ||
                                            (isXAxis(name) ? xScales[axis]?.barOffset : undefined)
                                    })}
                                </g>
                            );
                        })}
                    </Group>
                );
            })}
        </>
    );
};

RenderAxis = memo(RenderAxis);

export { RenderAxis };
