import moment, * as Moment from 'moment';
import { extendMoment } from 'moment-range';

export type ValidDate = moment.MomentInput;

moment.updateLocale('kk', {
  week: {
    dow: 1
  }
});

export const MAX_DATE = new Date(126227808000000);
export const MIN_DATE = new Date(0);

export const dateTimeFormat = 'DD.MM.yyyy HH:mm:ss';
export const dateFormat = 'DD.MM.yyyy';
export const timeFormat = 'HH:mm:ss';
export const dashSeparatedFormat = 'DD-MM-yyyy';
export const dayMonthYearPattern = 'DD MMM yyyy';

const ONE_MINUTE_IN_MILLISECONDS = 60 * 1000;

export const getDateBySubstractingOffset = (date: Date) => {
  const timeZoneOffsetMinutes = date.getTimezoneOffset();
  return new Date(date.getTime() - timeZoneOffsetMinutes * ONE_MINUTE_IN_MILLISECONDS);
};

export const getStartOfYear = (year?: moment.MomentInput) => {
  return moment(year).startOf('year').toDate();
};

export const isStartOfYear = (year: moment.MomentInput) => {
  return moment(year).isSame(getStartOfYear(year));
};

export const endOfYear = (formatType: string) => {
  return moment().endOf('year').format(formatType);
};

export const getDateTime = (isoString: moment.MomentInput) => {
  return isoString ? moment(isoString).format(dateTimeFormat) : null;
};

export const getDate = (isoString: moment.MomentInput) => {
  return isoString ? moment(isoString).format(dateFormat) : null;
};

export const getTime = (isoString: moment.MomentInput) => {
  return isoString ? moment(isoString).format(timeFormat) : null;
};

export const getFormattedDateTime = (isoString: moment.MomentInput, format = 'yyyy-MM-DDTHH:mm:ss') => {
  return isoString ? moment(isoString).format(format) : null;
};

export const getFormattedDate = (isoString: moment.MomentInput, format = 'yyyy-MM-DD') => {
  return isoString ? moment(isoString).format(format) : null;
};

export const now = (asMomentInstance = false) => {
  return asMomentInstance ? moment() : moment().format(dateTimeFormat);
};

export const today = (format = dateFormat, asMomentInstance = false) => {
  return asMomentInstance ? moment() : moment().format(format);
};

export const todayDashSeparated = (format = dashSeparatedFormat, asMomentInstance = false) => {
  return asMomentInstance ? moment() : moment().format(format);
};

export const getMonthYear = (value: moment.MomentInput) => {
  const dateFormat = 'MMM.YYYY';
  return value ? moment(value).format(dateFormat) : null;
};

export const getMonth = (dateString) => {
  return dateString ? moment(dateString).format('MMM YYYY') : null;
};

export const addDays = (value: moment.MomentInput, count = 1) => {
  return moment(value).add(count, 'days');
};

export const addMonths = (value: moment.MomentInput, count = 1) => {
  return moment(value).add(count, 'months');
};

export const startOfDay = (value: moment.MomentInput) => {
  return moment(value).startOf('day');
};

export const endOfDay = (value: moment.MomentInput) => {
  return moment(value).endOf('day');
};

export const startOfWeek = (value: moment.MomentInput) => {
  return moment(value).startOf('week');
};

export const isStartOfWeek = (date: moment.MomentInput) => {
  return moment(date).isSame(startOfWeek(date));
};

export const isStartOfDay = (date: moment.MomentInput) => {
  return moment(date).isSame(startOfDay(date));
};

export const endOfWeek = (value: moment.MomentInput) => {
  return moment(value).endOf('week');
};

export const subtractWeek = (to: Date, weeks: number) => {
  return moment(to).subtract(weeks, 'week').toDate();
};

export const addWeek = (to: Date, weeks = 1) => {
  return moment(to).add(weeks, 'week').toDate();
};

export const getMonthName = (value: number) => {
  return moment().month(value).format('MMMM');
};

export const getMinDate = (...dates: ValidDate[]): Date => {
  return moment.min(dates.map((date) => moment(date))).toDate();
};

export const getMaxDate = (...dates: ValidDate[]): Date => {
  return moment.max(dates.map((date) => moment(date))).toDate();
};

export const getUtcDate = (value: moment.MomentInput) => {
  return moment(value).utc(true).toDate();
};
export const weekInDays = 7;
export const yearInWeeks = 52;
export const monthInWeeks = 4.3;
export const getDatesWithinWeek = (date: Date) => {
  const startOfWeek = moment(date).startOf('isoWeek');
  const datesWithinWeek = [];
  for (let i = 0; i < weekInDays; i++) {
    datesWithinWeek.push(moment(startOfWeek).add(i, 'days').toDate());
  }
  return datesWithinWeek;
};

export const getIsoWeek = (date: Date) => moment(date).isoWeek();
export const getIsoWeekYear = (date: Date) => moment(date).isoWeekYear();
export function isMonday(date: Date) {
  const momentItem = moment(date);
  return momentItem.isValid() && momentItem.day() === 1;
}
export const isSameDates = (a: Date, b: Date) => moment(a).isSame(moment(b));
/**
 * Checks if range1 and range2 overlaps with each other
 * @param range1;
 * @param range2;
 * @returns Boolean;
 */
export function rangesOverlaps(range1: [Date, Date], range2: [Date, Date]): boolean {
  const [start1, end1] = range1;
  const [start2, end2] = range2;

  const firstContainsSecond = start1 <= start2 && end1 >= end2;
  const secondContainsFirst = start1 >= start2 && end1 <= end2;
  const datesOverlap = start1 <= end2 && start2 <= end1;

  return firstContainsSecond || secondContainsFirst || datesOverlap;
}

/**
 * Checks if given range has overlapping range in the list of ranges
 * @param ranges Ranges list
 * @param range Range being checked
 * @returns true if any range overlap including start and end dates
 */
export function containsOverlappedRange(ranges: [Date, Date][], range: [Date, Date]): boolean {
  if (!ranges || ranges.length === 0 || !range) {
    return false;
  }

  ranges.sort((range1, range2) => range1[0].getTime() - range2[0].getTime());

  return ranges.some((next) => rangesOverlaps(next, range));
}

export const weekDateIntersectsWithRange = (weekDate: Date, startDate: Date, endDate: Date): boolean => {
  const momentExtended = extendMoment(Moment);
  const range1 = momentExtended.range(startOfDay(weekDate), endOfWeek(weekDate));
  const range2 = momentExtended.range(startDate, endDate);

  return range1.overlaps(range2, { adjacent: true });
};

export const getDifference = (startDate: Date | undefined, endDate: Date | undefined, unitOfTime?: moment.unitOfTime.Diff): number | undefined => {
  if (!startDate || !endDate) return undefined;
  const start = moment(startDate);
  const end = moment(endDate);
  return end.diff(start, unitOfTime);
};

export const getDifferenceInDays = (startDate: Date | undefined, endDate: Date | undefined): number | undefined => {
  return getDifference(startDate, endDate, 'days');
};

export const getDifferenceInWeeks = (startDate: Date | undefined, endDate: Date | undefined): number | undefined => {
  return getDifference(startDate, endDate, 'weeks');
};
