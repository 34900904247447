import React, { useContext } from 'react';

import { faAngleDoubleLeft, faAngleDoubleRight, faAngleLeft, faAngleRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { IconButton } from '../Button/IconButton';
import { Select } from '../Input';
import { Toolbar } from '../Toolbar';
import { Typography } from '../Typography';
import { DataGridContext } from './DataGridContext';

const pageSizeOptions = [10, 20, 30, 40, 50].map((size) => ({
    Id: size,
    Name: `Show ${size}`
}));

/**
 * @category Component
 * @group Data Grid
 */
export function DataGridPagination() {
    const instance = useContext(DataGridContext);
    const {
        canPreviousPage,
        canNextPage,
        gotoPage,
        previousPage,
        nextPage,
        setPageSize,
        pageCount,
        state,
        pageOptions
    } = instance;
    const { pageIndex, pageSize } = state;

    return (
        <Toolbar disablePadding={false}>
            <IconButton size="small" onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
                <FontAwesomeIcon icon={faAngleDoubleLeft} />
            </IconButton>
            <IconButton size="small" onClick={() => previousPage()} disabled={!canPreviousPage}>
                <FontAwesomeIcon icon={faAngleLeft} />
            </IconButton>
            <IconButton size="small" onClick={() => nextPage()} disabled={!canNextPage}>
                <FontAwesomeIcon icon={faAngleRight} />
            </IconButton>
            <IconButton size="small" onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
                <FontAwesomeIcon icon={faAngleDoubleRight} />
            </IconButton>
            <div style={{ flex: 1 }} />
            <Typography variant="body2">
                Page {pageIndex + 1} of {pageOptions.length}
            </Typography>
            <Select
                disableMargin
                variant="blank"
                value={pageSizeOptions.find((x) => x.Id === pageSize)}
                options={pageSizeOptions}
                onChange={(e) => setPageSize(Number(e.Id))}
                style={{
                    minWidth: 48
                }}
                inputProps={{
                    menuPlacement: 'top'
                }}
                width={100}
            />
        </Toolbar>
    );
}
