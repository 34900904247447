import { PlantInputsClient, UpliftFactorClient } from 'api';
import { createAppAsyncThunk } from 'store/createAppAsyncThunk';
import { inletPressureValuesSelector, upliftFactorsValuesSelector } from '../plantInputsSelector';
import { convertSaveInletPressure, convertSaveUpliftFactors } from '../utils/convertPlantInputs';

const plantInputsClient = new PlantInputsClient();
const upliftFactorsClient = new UpliftFactorClient();

export const savePlantInputs = createAppAsyncThunk(
  'plantInputs/savePlantInputs',
  async (caseId: number, { getState }): Promise<void> => {
    const inletPressureRows = inletPressureValuesSelector(getState(), caseId);
    const upliftFactorsData = upliftFactorsValuesSelector(getState(), caseId);

    const inletPressure = convertSaveInletPressure(inletPressureRows);
    const upliftFactors = convertSaveUpliftFactors(upliftFactorsData);

    if (caseId) {
      plantInputsClient.saveScenario(caseId, inletPressure);
      upliftFactorsClient.saveScenario(caseId, upliftFactors);
    } else {
      plantInputsClient.save(inletPressure);
      upliftFactorsClient.save(upliftFactors);
    }
  }
);
