import cx from 'classnames';
import React, { cloneElement, forwardRef, ReactElement, ReactNode, Ref } from 'react';
import { SwitchBase, SwitchBaseProps } from '../SwitchBase';
import { capitalize, useId } from '../util';
import styles from './Checkbox.module.css';
import { CheckBoxIcon } from './CheckBoxIcon';
import { CheckBoxIndeterminateIcon } from './CheckBoxIndeterminateIcon';
import { CheckBoxOutlineBlankIcon } from './CheckBoxOutlineBlankIcon';

const defaultCheckedIcon = <CheckBoxIcon />;
const defaultIcon = <CheckBoxOutlineBlankIcon />;
const defaultIndeterminateIcon = <CheckBoxIndeterminateIcon />;

export interface CheckboxProps extends Omit<SwitchBaseProps, 'checkedIcon' | 'label' | 'icon'> {
    fullWidth?: boolean;
    className?: string;
    checkedIcon?: ReactNode;
    label?: ReactElement | string;
    color?: 'primary' | 'secondary' | 'default';
    icon?: ReactNode;
    indeterminate?: boolean;
    indeterminateIcon?: ReactNode;
    inputRef?: Ref<HTMLInputElement>;
    size?: 'small' | 'medium';
}

/**
 * @category Component
 * @group Switch
 */
export const Checkbox = forwardRef(function Checkbox(props: CheckboxProps, ref) {
    const {
        fullWidth,
        className,
        id: idProp,
        name,
        label,
        checkedIcon = defaultCheckedIcon,
        color = 'secondary',
        icon: iconProp = defaultIcon,
        indeterminate = false,
        indeterminateIcon: indeterminateIconProp = defaultIndeterminateIcon,
        inputProps,
        size = 'medium',
        ...other
    } = props;

    const id = useId(idProp ?? name);
    const icon: any = indeterminate ? indeterminateIconProp : iconProp;
    const indeterminateIcon: any = indeterminate ? indeterminateIconProp : checkedIcon;

    let result = (
        <SwitchBase
            type="checkbox"
            id={id}
            name={name}
            classes={{
                root: cx(styles.root, styles[`color${capitalize(color)}`], {
                    [styles.indeterminate]: indeterminate,
                }),
                checked: styles.checked,
                disabled: styles.disabled,
            }}
            color={color}
            inputProps={{
                'data-indeterminate': indeterminate,
                ...inputProps,
            }}
            icon={cloneElement(icon, {
                fontSize: icon.props.fontSize === undefined && size !== 'medium' ? size : icon.props.fontSize,
            })}
            checkedIcon={cloneElement(indeterminateIcon, {
                fontSize:
                    indeterminateIcon.props.fontSize === undefined && size !== 'medium'
                        ? size
                        : indeterminateIcon.props.fontSize,
            })}
            ref={ref}
            {...other}
        />
    );

    if (label) {
        result = (
            <label
                className={cx(styles.container, { [styles.fullWidth]: fullWidth }, styles.label, className)}
                htmlFor={id}
            >
                {result}
                <span className={styles.labelText}>{label}</span>
            </label>
        );
    }

    return result;
});
