import { Navigate, RouteObject } from 'react-router-dom';
import { lazyComponent } from 'utils/lazyComponent';

const HistoryLog = lazyComponent(() => import('./HistoryLog'), 'historyLog');
const HistoryValves = lazyComponent(() => import('./lineup/LineupHistory'), 'lineupHistory');
const HistoryWells = lazyComponent(() => import('./wells/WellInputHistory'), 'wellInputHistory');

export const inputsHistoryRoute: RouteObject = {
  path: 'official-inputs/history',
  element: <HistoryLog />,
  children: [
    { index: true, element: <Navigate replace to="lineup" /> },
    {
      path: 'lineup',
      element: <HistoryValves />
    },
    {
      path: 'wells',
      element: <HistoryWells />
    }
  ]
};
