import { GuardedRoute } from 'components/Security/GuardedRoute';
import { ROLES } from 'constants/roles';
import { Navigate, RouteObject } from 'react-router-dom';
import { lazyComponent } from 'utils/lazyComponent';

const Settings = lazyComponent(() => import('./SettingsPage'), 'settings');
const SchemePage = lazyComponent(() => import('./scheme/mainScheme/SchemeSettingsPage'), 'SchemePage');
const MSSchemePage = lazyComponent(
  () => import('./scheme/meterStationScheme/MeterStationSchemeSettingsPage'),
  'MSSchemePage'
);

export const settingsRoutes: RouteObject[] = [
  {
    path: 'settings',
    element: <GuardedRoute roles={[ROLES.wsoUser]} />,
    children: [
      {
        index: true,
        element: <Navigate to="scheme" />
      },
      {
        path: '',
        element: <Settings />,
        children: [
          {
            path: 'scheme',
            element: <SchemePage />
          },
          {
            path: 'scheme/:meterStationId',
            element: <MSSchemePage />
          }
        ]
      }
    ]
  }
];
