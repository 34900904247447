import { useMemo } from 'react';

import { Dimensions, Margin, Position, Size } from '../chartTypes';
import { componentName, isYAxis } from './chartUtils';
import { AxisGroups } from './useLayersGroups';

export type GroupDimensions = Record<string, Dimensions>;

export const DEFAULT_AXIS_HEIGHT = 42;
export const DEFAULT_AXIS_WIDTH = 42;

export function useGroupDimensions(
    AxisGroups: AxisGroups,
    groups: string[],
    innerHeight: number,
    innerWidth: number,
    heightScale: number,
    widthScale: number
): GroupDimensions {
    return useMemo(() => {
        const scaledHeight = innerHeight * heightScale;
        const scaledWidth = innerWidth * widthScale;

        const stackedAxisOffset: Margin = {
            left: 0,
            right: 0,
            top: 0,
            bottom: 0
        };

        const entries: [string, Dimensions][] = [];

        for (const group of groups) {
            const axis = AxisGroups[group];

            let position: Position = {
                top: 0,
                left: 0
            };

            let size: Size = {
                height: 0,
                width: 0
            };

            let offset: Margin = {
                top: stackedAxisOffset.top,
                left: stackedAxisOffset.left,
                bottom: stackedAxisOffset.bottom,
                right: stackedAxisOffset.right
            };

            axis.forEach((axis) => {
                let axisHeight = axis?.props?.height !== undefined ? axis.props.height * heightScale : undefined;
                let axisWidth = axis?.props?.width !== undefined ? axis.props.width * widthScale : undefined;

                const name = componentName(axis);

                if (isYAxis(name)) {
                    axisWidth = axisWidth ?? DEFAULT_AXIS_WIDTH * widthScale;
                    size.height = axisHeight;

                    if (axis.props.orientation === 'right') {
                        offset.right += axisWidth;
                    } else {
                        offset.left += axisWidth;
                    }
                } else {
                    axisHeight = axisHeight ?? DEFAULT_AXIS_HEIGHT * heightScale;
                    size.width = axisWidth;

                    if (axis.props.orientation === 'top') {
                        offset.top += axisHeight;
                    } else {
                        offset.bottom += axisHeight;
                    }
                }
            });

            stackedAxisOffset.top = offset.top;
            stackedAxisOffset.left = offset.left;
            stackedAxisOffset.bottom = offset.bottom;
            stackedAxisOffset.right = offset.right;

            axis.forEach((axis) => {
                const topOffset = axis?.props?.topOffset !== undefined ? axis.props.topOffset * heightScale : 0;
                const leftOffset = axis?.props?.leftOffset !== undefined ? axis.props.leftOffset * widthScale : 0;
                const bottomOffset =
                    axis?.props?.bottomOffset !== undefined ? axis.props.bottomOffset * heightScale : 0;
                const rightOffset = axis?.props?.rightOffset !== undefined ? axis.props.rightOffset * widthScale : 0;

                offset.top += topOffset;
                offset.bottom += bottomOffset;
                offset.left += leftOffset;
                offset.right += rightOffset;
            });

            entries.push([group, { position, size, offset }]);
        }

        const areaMargin: Margin = {
            top: Number.MAX_SAFE_INTEGER,
            left: Number.MIN_SAFE_INTEGER,
            bottom: Number.MAX_SAFE_INTEGER,
            right: Number.MIN_SAFE_INTEGER
        };

        for (const [, { offset }] of entries) {
            areaMargin.top = Math.min(areaMargin.top, offset.top);
            areaMargin.left = Math.max(areaMargin.left, offset.left);
            areaMargin.bottom = Math.min(areaMargin.bottom, offset.bottom);
            areaMargin.right = Math.max(areaMargin.right, offset.right);
        }

        for (const [, { position, offset, size }] of entries) {
            position.top = offset.top;
            position.left = offset.left;

            offset.top = Math.max(areaMargin.top - offset.top, 0);
            offset.left = Math.max(areaMargin.left - offset.left, 0);
            offset.bottom = Math.max(areaMargin.bottom - offset.bottom, offset.bottom);
            offset.right = Math.max(areaMargin.right - offset.right, offset.right);

            size.height = scaledHeight - (position.top + offset.top + offset.bottom);
            size.width = scaledWidth - (position.left + offset.left + offset.right);
        }

        return Object.fromEntries(entries);
    }, [AxisGroups, groups, innerHeight, innerWidth, heightScale, widthScale]);
}
