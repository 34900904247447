import HelpIcon from '@mui/icons-material/Help';
import InfoIcon from '@mui/icons-material/Info';
import { Box, Collapse, Divider, Grid, Paper, Typography } from '@mui/material';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import sidebarItems from './sidebarItems';

export interface SidebarProps {
  open: boolean;
}
function Sidebar(props: SidebarProps) {
  const location = useLocation();
  return (
    <Collapse
      in={props.open}
      orientation="horizontal"
      sx={{ position: 'fixed', height: '100vh', pt: 8, overflowY: 'auto' }}
    >
      <Paper elevation={0} square sx={{ height: '100%', minHeight: '600px', width: 256, pb: 1 }}>
        <Grid container sx={{ height: '100%' }} direction="column">
          <Grid item xs>
            <List sx={{ px: 1 }}>
              {sidebarItems.map((item) => (
                <ListItemButton
                  key={item.id}
                  sx={{ borderRadius: (theme) => theme.shape.borderRadius }}
                  component={RouterLink}
                  to={item.route}
                  selected={
                    (location.pathname.startsWith(item.route) && item.route !== '/') || location.pathname === item.route
                  }
                >
                  <ListItemIcon>{item.icon}</ListItemIcon>
                  <ListItemText primary={item.label} primaryTypographyProps={{ fontWeight: 700 }} />
                </ListItemButton>
              ))}
            </List>
          </Grid>
          <Grid item xs="auto">
            <Divider />
            <List>
              <ListItemButton
                sx={{ borderRadius: (theme) => theme.shape.borderRadius }}
                component={RouterLink}
                to="/help"
              >
                <ListItemIcon>
                  <HelpIcon />
                </ListItemIcon>
                <ListItemText primary="Help" primaryTypographyProps={{ fontWeight: 700 }} />
              </ListItemButton>
              <ListItemButton
                sx={{ borderRadius: (theme) => theme.shape.borderRadius }}
                component={RouterLink}
                to="/about"
              >
                <ListItemIcon>
                  <InfoIcon />
                </ListItemIcon>
                <ListItemText primary="About" primaryTypographyProps={{ fontWeight: 700 }} />
              </ListItemButton>
            </List>
            <Box sx={{ textAlign: 'center' }}>
              <Typography fontSize={12}>WS Dashboard V {process.env.REACT_APP_VERSION}</Typography>
              <img style={{ maxWidth: 50, margin: '4px 0' }} src="/images/TCO.svg" />
              <Typography fontSize={10}>&copy;TENGIZCHEVROIL {new Date().getFullYear()}</Typography>
            </Box>
          </Grid>
        </Grid>
      </Paper>
    </Collapse>
  );
}

export default Sidebar;
