import React, { PropsWithChildren, PureComponent } from 'react';

/**
 * The props for the {@link FocusBlurHandler} component.
 */
export interface FocusBlurHandlerProps {
    onFocus?: (event: React.FocusEvent<HTMLAnchorElement>) => void;
    onBlur?: (event: React.FocusEvent<HTMLAnchorElement>) => void;
}

/**
 * This component wraps its children in an <a /> tag which is the most reliable way to
 * support tabIndex focusing accross browsers for SVG < v2.0
 * @category Component
 * @group Chart
 */
export class FocusBlurHandler extends PureComponent<PropsWithChildren<FocusBlurHandlerProps>> {
    handleOnClick: React.MouseEventHandler<HTMLAnchorElement> = (e) => {
        e.preventDefault();
    };

    render() {
        const { children, onFocus, onBlur } = this.props;

        return (
            <a
                //@ts-ignore-enable
                xlinkHref={(onBlur || onFocus) && '#'}
                role="presentation"
                onBlur={onBlur}
                onFocus={onFocus}
                onClick={this.handleOnClick}
            >
                {children}
            </a>
        );
    }
}
