import {
  LineupClient,
  SaveLineupChildStateDto,
  SaveLineupParentStateDto,
  SaveLineupStateDto,
  SaveWellChildStateDto,
  SaveWellParentStateDto,
  SaveWellStateDto,
  WellInputClient
} from 'api';
import { createAppAsyncThunk } from 'store/createAppAsyncThunk';
import { selectOfficialInputPresentState } from 'store/official-inputs/OfficialSelectors';
import { OfficialInputState } from '../OfficialInputState';

const lineupClient = new LineupClient();
const wellInputClient = new WellInputClient();

// TODO: Maybe better to store child statuses (Well and Valve) by StatusId, not to spend time for converting back and forth
// TODO: As a result ApplyLineupStatus logic is creating unnecessary changes in Lineup and WellInput states

export function getWellInputSaveState(officialInputState: OfficialInputState, scenarioCaseId?: number) {
  const parentStates: SaveWellParentStateDto[] = [];
  const childStates: Record<number, SaveWellChildStateDto> = {};

  for (const msChanges of officialInputState.changes.MeterStation.values()) {
    for (const [weekDateId, value] of msChanges) {
      const newStatus = new SaveWellParentStateDto({
        ...value.wellInput,
        status: value.status,
        weekDateId
      });
      parentStates.push(newStatus);
    }
  }

  for (const wellChanges of officialInputState.changes.Well.values()) {
    for (const status of wellChanges.values()) {
      const newStatus = new SaveWellChildStateDto(status);
      childStates[newStatus.id] = newStatus;
    }
  }

  return new SaveWellStateDto({
    scenarioCaseId,
    parentStates,
    childStates: Object.values(childStates)
  });
}

export function getLineupSaveState(officialInputState: OfficialInputState, scenarioCaseId?: number) {
  const parentStates: SaveLineupParentStateDto[] = [];
  const childStates: Record<number, SaveLineupChildStateDto> = {};

  for (const parentChanges of officialInputState.changes.MeterStation.values()) {
    parentChanges.forEach(({ status, lineup }, weekDateId) => {
      const newStatus = new SaveLineupParentStateDto({
        ...lineup,
        status,
        weekDateId
      });
      parentStates.push(newStatus);
    });
  }

  for (const parentChanges of officialInputState.changes.Header.values()) {
    parentChanges.forEach(({ status, headerId }, weekDateId) => {
      const newStatus = new SaveLineupParentStateDto({
        headerId,
        status,
        weekDateId
      });
      parentStates.push(newStatus);
    });
  }

  for (const connectionChanges of officialInputState.changes.Valve.values()) {
    for (const status of connectionChanges.values()) {
      const newStatus = new SaveLineupChildStateDto(status);
      childStates[newStatus.id] = newStatus;
    }
  }

  return new SaveLineupStateDto({
    scenarioCaseId,
    parentStates,
    childStates: Object.values(childStates)
  });
}

export const saveOfficialInputChanges = createAppAsyncThunk('official-inputs/save', (_: void, { getState }) => {
  const officialInputState = selectOfficialInputPresentState(getState());

  const lineData = getLineupSaveState(officialInputState);

  const saveLineup = lineupClient.saveStates(lineData);

  const wellInputData = getWellInputSaveState(officialInputState);

  const saveWellInput = wellInputClient.saveStates(wellInputData);

  return Promise.all([saveLineup, saveWellInput]);
});
