import { Draft } from '@reduxjs/toolkit';
import { IConstraintInputDto, IGetWeekDatesDto } from 'api';
import { getColumnWeekDate } from 'components/common/DataGrid/getWeekDaysColumns';
import { EquipmentNameRow } from '../types/ConstraintsRow';
import { ConstraintsState, ConstraintsStateValue } from '../types/ConstraintsState';
import { ConstraintsAsInputsKeyAccessor } from './getConstrainsAsInputData';

function updateInput(inputs: Map<number, IConstraintInputDto>, weekDateId: number, constraintValue: number) {
  const inputDto = inputs.get(weekDateId);
  inputs.set(weekDateId, { ...inputDto, weekDateId, constraintValue });
}

function copyToNextTimesteps(
  inputs: Map<number, IConstraintInputDto>,
  state: ConstraintsStateValue,
  weekDateId: number
) {
  const weekDates: IGetWeekDatesDto[] = state.weekDates;
  const startIndex = Math.max(
    weekDates.findIndex((x) => x.id === weekDateId),
    0
  );
  for (let i = startIndex + 1; i < weekDates.length; i++) {
    const prevWeekDateId = weekDates[i - 1].id;
    const prevWeekDateValue = inputs.get(prevWeekDateId).constraintValue;

    state.enableSave = state.enableSave || prevWeekDateValue !== inputs.get(weekDates[i].id).constraintValue;
    updateInput(inputs, weekDates[i].id, prevWeekDateValue);
  }
}

export type ChangeConstraintsInputPayload = {
  columnId: string;
  value: string;
};
export type ConstraintEquipmentNameRowPayload = ConstraintsAsInputsKeyAccessor & {
  original: EquipmentNameRow;
  edited: ChangeConstraintsInputPayload;
};

export function updateConstraintEquipmentNameRow(
  state: Draft<ConstraintsState>,
  payload: ConstraintEquipmentNameRowPayload,
  copyValuesToNextTimesteps = true
) {
  const { original, edited, keyAccessor } = payload;
  const { constraintRowId, equipmentTypeRowId } = original;
  const rows = state[keyAccessor].rows;
  const { subRows: eqTypeRows } = rows.find((r) => r.id === constraintRowId);
  const { subRows: eqNameRows } = eqTypeRows.find((eqType) => eqType.id === equipmentTypeRowId);
  const { inputs } = eqNameRows.find((eqName) => eqName.id === original.id);

  const { columnId, value } = edited;
  const weekDateId = getColumnWeekDate(columnId);
  if (weekDateId) {
    const constraintValue = Math.max(parseFloat(value) || 0, 0);
    state[keyAccessor].enableSave = inputs.get(weekDateId)?.constraintValue !== constraintValue;
    updateInput(inputs, weekDateId, constraintValue);

    if (copyValuesToNextTimesteps) {
      copyToNextTimesteps(inputs, state[keyAccessor], weekDateId);
    }
  } else {
    // eslint-disable-next-line no-console
    console.error(`WeekDateId from column ${columnId} was not found`);
  }
}
