import { SyntheticEvent, useCallback, useRef } from 'react';

export const useDoubleClick = (onClick, onDoubleClick, latency = 300) => {
    const clickRef = useRef(0);
    const timeoutRef = useRef(null);

    const reset = useCallback(() => {
        clickRef.current = 0;
        if (timeoutRef.current) {
            clearTimeout(timeoutRef.current);
            timeoutRef.current = null;
        }
    }, []);

    return useCallback(
        (event: SyntheticEvent<MouseEvent>) => {
            event.persist();

            clickRef.current += 1;

            if (clickRef.current === 2) {
                reset();
                if (onDoubleClick) onDoubleClick(event);
            } else {
                timeoutRef.current = setTimeout(() => {
                    reset();
                    if (onClick) onClick(event);
                }, latency);
            }
        },
        [latency, onClick, onDoubleClick, reset]
    );
};

export const createDoubleClickHandler = (onClick, onDoubleClick, latency = 300, prefire = undefined) => {
    let clickCount = 0;
    let timeout = null;

    function reset() {
        clickCount = 0;
        if (timeout) {
            clearTimeout(timeout);
            timeout = null;
        }
    }

    return (originalEvent: SyntheticEvent<MouseEvent>) => {
        const event = originalEvent;
        event.persist();

        clickCount += 1;

        if (clickCount === 1) {
            prefire?.(event);
            timeout = setTimeout(() => {
                reset();
                onClick?.(event);
            }, latency);
        }

        if (clickCount === 2) {
            reset();
            onDoubleClick?.(event);
        }
    };
};
