import cx from 'classnames';
import React, { ComponentPropsWithoutRef, FC, memo, useContext } from 'react';
import styles from './Table.module.css';

/**
 * The props for the {@link Table} component.
 * @category Props
 */
export interface TableProps extends ComponentPropsWithoutRef<'table'> {
    fullWidth?: boolean;
    blockLayout?: boolean;
}

/**
 * @category Component
 * @group Table
 */
export const Table: FC<TableProps> = memo(function Table({ blockLayout, fullWidth, className, children, ...other }) {
    let Component: any = blockLayout ? 'div' : 'table';

    return (
        <Component
            className={cx(
                styles.table,
                blockLayout ? styles.blockTable : styles.regularTable,
                { [styles.fullWidth]: fullWidth },
                className
            )}
            {...other}
        >
            {children}
        </Component>
    );
});
