import { IUseAsInputsDto } from 'api';
import { RootState } from 'store';
import { UseAsInputsKey } from './types/useAsInputsState';

export const selectUseAsInputsState = (state: RootState) => state.useAsInputs;
export const selectUseAsInputsLoadingState = (state: RootState, keyAccessor: UseAsInputsKey) =>
  state.useAsInputs[keyAccessor]?.loading;

export const selectUseAsInputsChangesValue = (
  state: RootState,
  keyAccessor: UseAsInputsKey,
  valueAccessor: keyof IUseAsInputsDto
) => {
  const originalValue = selectUseAsInputsState(state)[keyAccessor]?.data?.[valueAccessor];
  const currentValue = selectUseAsInputsState(state)[keyAccessor]?.changes?.[valueAccessor];
  const error = selectUseAsInputsState(state)[keyAccessor]?.error;
  return { value: currentValue ?? true, isChanged: currentValue !== originalValue, error };
};
