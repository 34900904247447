import { applyMatrixToPoint, Zoom } from '@visx/zoom';
import { TransformMatrix } from '@visx/zoom/lib/types';
import { ZoomProps } from '@visx/zoom/lib/Zoom';
import { isEqual } from 'lodash';
import React, { FC, useContext, useEffect } from 'react';
import { ChartContext } from '../../ChartContext';
import { DEFAULT_SIZE } from '../../utils';
import { defaultInitialTransformMatrix } from '../../utils/useZoom';
import './ChartZoom.css';
import { ZoomController } from './ZoomController';

/**
 * @category Component
 * @group Chart
 */
const ChartZoom: FC<Partial<ZoomProps<SVGSVGElement>>> = (props) => {
    const instance = useContext(ChartContext);
    const {
        height: originalHeight = DEFAULT_SIZE.height,
        width: originalWidth = DEFAULT_SIZE.width,
        margin,
        zoom
    } = instance;

    function constrain(transformMatrix: TransformMatrix, prevTransformMatrix: TransformMatrix) {
        //default view while zoom out
        if (
            transformMatrix.scaleX < defaultInitialTransformMatrix.scaleX ||
            transformMatrix.scaleY < defaultInitialTransformMatrix.scaleY
        ) {
            return defaultInitialTransformMatrix;
        }
        //view within [0, 0, width, height] while dragging
        const min = applyMatrixToPoint(transformMatrix, { x: 0, y: 0 });
        const max = applyMatrixToPoint(transformMatrix, { x: originalWidth, y: originalHeight });
        if (max.x < originalWidth || max.y < originalHeight) {
            return prevTransformMatrix;
        }
        if (min.x > 0 || min.y > 0) {
            return prevTransformMatrix;
        }
        return transformMatrix;
    }

    return (
        <Zoom<SVGSVGElement>
            height={originalHeight}
            width={originalWidth}
            scaleXMin={1 / 2}
            scaleYMin={1 / 2}
            scaleXMax={10}
            scaleYMax={10}
            initialTransformMatrix={defaultInitialTransformMatrix}
            constrain={constrain}
            {...props}
            children={(providedZoom) => {
                useEffect(() => {
                    if (!isEqual(providedZoom.transformMatrix, zoom.transformMatrix)) {
                        zoom.ref.current = providedZoom;
                        zoom.updateTransformMatrix(providedZoom.transformMatrix);
                    }
                }, [providedZoom, zoom]);

                return (
                    <ZoomController
                        zoom={providedZoom}
                        x={margin.left}
                        y={0}
                        width={originalWidth - margin.left * 2 - margin.right}
                        height={originalHeight - margin.top * 2 - margin.bottom}
                    />
                );
            }}
        />
    );
};

ChartZoom.displayName = 'ChartZoom';

export { ChartZoom };
