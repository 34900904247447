import { useMemo, useCallback } from 'react';

import { UniversalTimelineInstance, TimelineRow } from '../TimelineTypes';

import { actions } from '../utils/actions';
import { makePropGetter } from '../utils/utils';

actions.resetExpanded = 'resetExpanded';
actions.toggleRowExpanded = 'toggleRowExpanded';
actions.toggleAllRowsExpanded = 'toggleAllRowsExpanded';

export function expandReducer(state, action, previousState, instance) {
    if (action.type === actions.init) {
        return {
            expanded: {},
            ...state,
        };
    }

    if (action.type === actions.resetExpanded) {
        return {
            ...state,
            expanded: instance.initialState.expanded || {},
        };
    }

    if (action.type === actions.toggleAllRowsExpanded) {
        const { value } = action;
        const { isAllRowsExpanded, rowsById } = instance;

        const expandAll = typeof value !== 'undefined' ? value : !isAllRowsExpanded;

        if (expandAll) {
            const expanded = {};

            Object.keys(rowsById).forEach((rowId) => {
                expanded[rowId] = true;
            });

            return {
                ...state,
                expanded,
            };
        }

        return {
            ...state,
            expanded: {},
        };
    }

    if (action.type === actions.toggleRowExpanded) {
        const { id, value: setExpanded } = action;
        const exists = state.expanded[id];

        const shouldExist = typeof setExpanded !== 'undefined' ? setExpanded : !exists;

        if (!exists && shouldExist) {
            return {
                ...state,
                expanded: {
                    ...state.expanded,
                    [id]: true,
                },
            };
        } else if (exists && !shouldExist) {
            const { [id]: _, ...rest } = state.expanded;
            return {
                ...state,
                expanded: rest,
            };
        } else {
            return state;
        }
    }

    return state;
}

export function useExpand(instance: UniversalTimelineInstance) {
    const {
        data,
        rows,
        rowsById,

        state: { expanded },
        dispatch,
    } = instance;

    let isAllRowsExpanded = Boolean(Object.keys(rowsById).length && Object.keys(expanded).length);

    if (isAllRowsExpanded) {
        if (Object.keys(rowsById).some((id) => !expanded[id])) {
            isAllRowsExpanded = false;
        }
    }

    const toggleRowExpanded = useCallback(
        (id, value) => {
            dispatch({ type: actions.toggleRowExpanded, id, value });
        },
        [dispatch]
    );

    const toggleAllRowsExpanded = useCallback(
        (value) => dispatch({ type: actions.toggleAllRowsExpanded, value }),
        [dispatch]
    );

    const expandedRows = useMemo(() => {
        return expandRows(rows, { expanded });
    }, [expanded, rows]);

    const expandedDepth = useMemo(() => findExpandedDepth(expanded), [expanded]);

    Object.assign(instance, {
        preExpandedRows: rows,
        expandedRows,
        rows: expandedRows,
        expandedDepth,
        isAllRowsExpanded,
        toggleRowExpanded,
        toggleAllRowsExpanded,
    });
}

function findExpandedDepth(expanded) {
    let maxDepth = 0;

    Object.keys(expanded).forEach((id) => {
        const splitId = id.split('.');
        maxDepth = Math.max(maxDepth, splitId.length);
    });

    return maxDepth;
}

export function expandRows<TItem, TResource>(
    rows: TimelineRow<TItem, TResource>[],
    { expanded, expandSubRows = true }
) {
    const expandedRows = [];

    const handleRow = (row: TimelineRow<TItem, TResource>) => {
        const expandUselessRow =
            (!row.groupByVal && !row.group?.original) ||
            (row.subRows && row.subRows.filter((x) => x.groupByVal === row.groupByVal).length == row.subRows.length);

        if (expandUselessRow) {
            row.subRows.forEach(handleRow);
            return;
        }

        row.isExpanded = (row.items && row.group?.original?.[row.resource.expandedKey]) || expanded[row.id];

        row.canExpand = row.subRows && !!row.subRows.length;

        expandedRows.push(row);

        if (expandSubRows && row.subRows && row.subRows.length && row.isExpanded) {
            row.subRows.forEach(handleRow);
        }
    };

    rows.forEach(handleRow);

    return expandedRows;
}

export function prepareRow(row, instance) {
    row.toggleRowExpanded = (set) => instance.toggleRowExpanded(row.id, set);

    row.getToggleRowExpandedProps = makePropGetter([getToggleRowExpandedProps], {
        instance,
        row,
    });
}

const getToggleRowExpandedProps = (props, { row }) => [
    props,
    {
        onClick: () => {
            row.toggleRowExpanded();
        },
        style: {
            cursor: 'pointer',
        },
        title: 'Toggle Row Expanded',
    },
];
