import React, { useCallback, useContext } from 'react';
import { ContextMenuContentProps } from '../../ContextMenu';
import { ListHeader } from '../../List';
import { MenuItem } from '../../Menu';
import { DataGridContext } from '../DataGridContext';
import { DataGridContextMenuComponent, DataGridContextMenuProps } from '../types';

/**
 * The props for the {@link DataGridContextMenu} component.
 */
export interface DataGridContextMenuComponentProps extends DataGridContextMenuProps<any>, ContextMenuContentProps {}

/**
 * @category Component
 * @group Data Grid
 */
const DataGridContextMenu: DataGridContextMenuComponent<any> = ({
    row,
    data,
    onCloseMenu,
}: DataGridContextMenuComponentProps) => {
    const instance = useContext(DataGridContext);
    const { settings, selectedFlatRows } = instance;
    const { onView, onEdit, onDelete } = settings;

    const selected = row.getIsSelected?.() ?? false;
    const selectionGroup = selected && selectedFlatRows.length > 1;

    const handleView = useCallback(
        (event) => {
            onView(data, event);
            onCloseMenu(event);
        },
        [data, onCloseMenu, onView]
    );

    const handleEdit = useCallback(
        (event) => {
            if (typeof onEdit === 'function') {
                onEdit(data, event);
            } else {
                row?.startInlineEdit();
            }
            onCloseMenu(event);
        },
        [data, onCloseMenu, onEdit, row]
    );

    const handleDelete = useCallback(
        (event) => {
            onDelete([data], event);
            onCloseMenu(event);
        },
        [data, onCloseMenu, onDelete]
    );

    const handleDeleteItems = useCallback(
        (event) => {
            onDelete(
                selectedFlatRows.map((x) => x.original),
                event
            );
            onCloseMenu(event);
        },
        [onCloseMenu, onDelete, selectedFlatRows]
    );

    return (
        <>
            {selectionGroup && (
                <ListHeader disableMargin gutters>
                    Selected: {selectedFlatRows.length}
                </ListHeader>
            )}
            {selectionGroup && onDelete && <MenuItem onClick={handleDeleteItems}>Delete</MenuItem>}
            {!selectionGroup && onView && <MenuItem onClick={handleView}>View</MenuItem>}
            {!selectionGroup && onEdit && <MenuItem onClick={handleEdit}>Edit</MenuItem>}
            {!selectionGroup && onDelete && <MenuItem onClick={handleDelete}>Delete</MenuItem>}
        </>
    );
};

DataGridContextMenu.isRenderContextMenu = (instance, row) => {
    const { settings, selectedFlatRows } = instance;
    const { onView, onEdit, onDelete } = settings;

    const selected = row.getIsSelected?.() ?? false;
    const selectionGroup = selected && selectedFlatRows.length > 1;

    return (!selectionGroup && (Boolean(onView) || Boolean(onEdit))) || Boolean(onDelete);
};

/** @ignore */
export { DataGridContextMenu };
