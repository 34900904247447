import { createSelector } from '@reduxjs/toolkit';
import { selectOfficialInputHiddenWeekDateIds } from 'store/official-input-page/OfficialInputPageSelectors';
import { RootState } from '..';
import { InletPressureState } from './types/PlantInputsState';
import { getPlantInputsSnapshot, getUpliftFactorsSnapshot } from './utils/getPlantInputsSnapshot';
import { plantInputsKeyAccessor } from './utils/helpers';

export const plantInputsState = (state: RootState) => state.plantInputs.present;
export const selectPlantInputsStateByAccessor = (caseId?: number) => (state: RootState) => {
  const currentState = plantInputsState(state);
  return currentState[plantInputsKeyAccessor(caseId)] ?? {};
};

export const plantInputsHasChangesSelector = (caseId?: number) => (state: RootState) => {
  const itemState = selectPlantInputsStateByAccessor(caseId)(state);
  const hasInletPressureChanges = itemState.inletPressure?.changes?.size > 0;
  const hasGasConstraintChanges = itemState.gasConstraint?.changes?.size > 0;
  const hasUpliftFactorChanges = Object.keys(itemState.upliftFactors?.changes ?? {}).length > 0;

  return hasInletPressureChanges || hasGasConstraintChanges || hasUpliftFactorChanges;
};

// INLET PRESSURE
const defaultInletPressureState: InletPressureState = {
  datesById: new Map(),
  datesByWeekNumber: new Map(),
  splitableWeekDateIds: new Set<number>()
};
export const selectInletPressureState = (caseId?: number) => (state: RootState) =>
  selectPlantInputsStateByAccessor(caseId)(state).inletPressure ?? defaultInletPressureState;

const selectPlantInputsDate = (caseId?: number) => (state: RootState) => selectInletPressureState(caseId)(state).dates;
const selectPlantInputsSplitableWeekDateIds = (caseId?: number) => (state: RootState) =>
  selectInletPressureState(caseId)(state).splitableWeekDateIds;

export const selectPlantInputsWeekDays = createSelector(
  [
    (state, caseId?: number) => selectPlantInputsDate(caseId)(state),
    (state, caseId?: number) => selectPlantInputsSplitableWeekDateIds(caseId)(state),
    (state) => selectOfficialInputHiddenWeekDateIds(state)
  ],
  (dates, splitableWeekDateIds, hiddenWeekDateIds) => {
    return dates?.filter((x) => splitableWeekDateIds.has(x.id) || !hiddenWeekDateIds.has(x.id));
  }
);
export const inletPressureWeekDatesSelector = (caseId?: number) => (state: RootState) =>
  selectInletPressureState(caseId)(state)?.dates;
export const inletPressureLoadingSelector = (caseId?: number) => (state: RootState) =>
  selectInletPressureState(caseId)(state)?.loading;
export const inletPressureChangesSelector = (caseId?: number) => (state: RootState) =>
  selectInletPressureState(caseId)(state)?.changes;

export const inletPressureValuesSelector = createSelector(
  [
    (state, caseId?: number) => selectInletPressureState(caseId)(state)?.rows,
    (state, caseId?: number) => inletPressureChangesSelector(caseId)(state)
  ],
  (rows, changes) => {
    return getPlantInputsSnapshot(rows, changes);
  }
);

// GAS CONSTRAINT
const defaultGC: InletPressureState = {
  datesById: new Map(),
  datesByWeekNumber: new Map(),
  splitableWeekDateIds: new Set<number>()
};
const selectGC = (caseId?: number) => (state: RootState) =>
  selectPlantInputsStateByAccessor(caseId)(state).gasConstraint ?? defaultGC;
export const gasConstraintWeekDatesSelector = (caseId?: number) => (state: RootState) => selectGC(caseId)(state)?.dates;

export const gasConstraintLoadingSelector = (caseId?: number) => (state: RootState) => selectGC(caseId)(state)?.loading;
export const gasConstraintChangesSelector = (caseId?: number) => (state: RootState) => selectGC(caseId)(state)?.changes;

export const gasConstraintValuesSelector = createSelector(
  [
    (state: RootState, caseId?: number) => selectGC(caseId)(state)?.rows,
    (state: RootState, caseId?: number) => gasConstraintChangesSelector(caseId)(state)
  ],
  (rows, changes) => {
    return getPlantInputsSnapshot(rows, changes);
  }
);

// UPLIFT FACTORS
const selectUF = (caseId?: number) => (state: RootState) =>
  selectPlantInputsStateByAccessor(caseId)(state).upliftFactors;
export const upliftFactorsSelector = (caseId?: number) => (state: RootState) => selectUF(caseId)(state);

export const upliftFactorsLoadingSelector = (caseId?: number) => (state: RootState) => selectUF(caseId)(state)?.loading;
export const upliftFactorChangesSelector = (caseId?: number) => (state: RootState) => selectUF(caseId)(state)?.changes;

export const upliftFactorsValuesSelector = createSelector(
  [
    (state: RootState, caseId?: number) => selectUF(caseId)(state)?.record,
    (state: RootState, caseId?: number) => upliftFactorChangesSelector(caseId)(state)
  ],
  (upliftFactors, changes) => {
    return getUpliftFactorsSnapshot(upliftFactors, changes);
  }
);
