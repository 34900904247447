import React, { FC, useCallback } from 'react';
import { useForm } from 'react-hook-form';
import { PopoverBody } from 'reactstrap';
import { Button } from '../../Button/Button';
import { Input } from '../../Input';
import { Toolbar } from '../../Toolbar';
import { DataGridFilterProps } from '../types/DataGridFilterProps';

export const DataGridDefaultFilter: FC<DataGridFilterProps> = ({
    column: { filterValue, preFilteredRows, setFilter },
    onClose
}) => {
    const { register, handleSubmit } = useForm({
        shouldUnregister: false,
        defaultValues: {
            search: filterValue
        }
    });

    const count = preFilteredRows.length;

    const onSubmit = useCallback(
        (result) => {
            setFilter(result.search);
            onClose();
        },
        [onClose, setFilter]
    );

    const handleClear = useCallback(() => {
        setFilter(undefined);
        onClose();
    }, [onClose, setFilter]);

    return (
        <>
            <form onSubmit={handleSubmit(onSubmit)}>
                <PopoverBody>
                    <Input
                        inputRef={register()}
                        name="search"
                        defaultValue={''}
                        placeholder={`Search ${count} records...`}
                    />
                </PopoverBody>
                <Toolbar disablePadding={false}>
                    <div style={{ flex: 1 }} />
                    <Button color="primary" type="submit" size="small">
                        Filter
                    </Button>
                    <Button onClick={handleClear} size="small">
                        Clear
                    </Button>
                </Toolbar>
            </form>
        </>
    );
};
