import { WellGorClient } from 'api';
import { saveDeactivatedTimesteps } from 'store/cases/actions/deactivatedTimesteps';
import { createAppAsyncThunk } from 'store/createAppAsyncThunk';
import { getWellGorSaveState } from 'store/wellGor/actions/saveWellGor';
import { GetWellGorPayload } from 'store/wellGor/types/GetWellGorPayload';
import { wellGorCaseStateSelector } from '../wellGorCaseSelectors';
import { WELL_GOR_CASE_UNDO_CLEAR } from '../wellGorCaseSlice';

export interface WellGorCaseParams {
  scenarioCaseId: number;
}

const service = new WellGorClient();

export const getWellGorCase = createAppAsyncThunk(
  'wellGorCase/get',
  async ({ scenarioCaseId }: WellGorCaseParams, { dispatch }): Promise<GetWellGorPayload> => {
    dispatch({ payload: undefined, type: WELL_GOR_CASE_UNDO_CLEAR });

    return { items: await service.getScenario(scenarioCaseId) };
  },
  {
    condition: (arg, { getState }) => {
      const wellGor = wellGorCaseStateSelector(getState());

      if (!wellGor.loading && wellGor.data === undefined) {
        return true;
      }

      if (wellGor.arg && arg.scenarioCaseId !== wellGor.arg.scenarioCaseId) {
        return true;
      }

      return false;
    }
  }
);

export const saveWellGorCase = createAppAsyncThunk('wellGorCase/save', (_: void, { getState, dispatch }) => {
  const wellGor = wellGorCaseStateSelector(getState());
  const data = getWellGorSaveState(wellGor);
  const saveLineupDeactivatedWeeks = dispatch(saveDeactivatedTimesteps(wellGor.arg.scenarioCaseId));

  return Promise.allSettled([service.saveScenario(wellGor.arg.scenarioCaseId, data), saveLineupDeactivatedWeeks]);
});
