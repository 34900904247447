import { ApplicationInsights, SeverityLevel } from '@microsoft/applicationinsights-web';
import { Component, ErrorInfo, PropsWithChildren } from 'react';

type Props = {
  appInsights: ApplicationInsights;
};

type State = {
  error?: Error;
  errorInfo?: ErrorInfo;
  errorId?: string;
};

class ErrorBoundary extends Component<PropsWithChildren<Props>, State> {
  constructor(props: Props) {
    super(props);
    this.state = { error: undefined, errorInfo: undefined, errorId: undefined };
  }

  static getDerivedStateFromError(error: Error, errorInfo: ErrorInfo) {
    return { error, errorInfo };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    const errorId = window.crypto.randomUUID();

    this.setState({ errorId });
    if (process.env.NODE_ENV === 'development') {
      // eslint-disable-next-line no-console
      console.error({ errorId, error });
    } else {
      this.props.appInsights.trackException(
        {
          id: errorId,
          exception: error,
          severityLevel: SeverityLevel.Error,
          properties: { ...errorInfo }
        },
        { errorId }
      );
    }
  }

  errorFallback = () => {
    return (
      <div>
        <h2>Something went wrong.</h2>
        <details style={{ whiteSpace: 'pre-wrap' }}>
          <span>ErrorId: {this.state.errorId}</span>
          <br />
          {this.state.error?.stack}
          <br />
          {this.state.errorInfo?.componentStack}
        </details>
      </div>
    );
  };

  render() {
    if (this.state.error) {
      return this.errorFallback();
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
