import { Draft } from '@reduxjs/toolkit';
import { IGetWeekDatesDto } from 'api';
import { getPropOrDefault } from 'utils/getPropOrDefault';
import { PlantInputsState } from '../types/PlantInputsState';
import { SetPlantInputValuePayload, SetUpliftFactorsValuePayload } from '../types/helperTypes';
import { PlantInputsCell, PlantInputsRow } from '../types/tableTypes';
import { plantInputsKeyAccessor } from './helpers';

const recalculateRow = (
  row: PlantInputsRow,
  rowChanges: Map<number, PlantInputsCell>,
  weekDates: IGetWeekDatesDto[],
  weekDateId: number,
  newValue: number
) => {
  const startDateIndex = weekDates.findIndex((week) => week.id === weekDateId);

  for (let i = startDateIndex + 1; i < weekDates.length; i++) {
    const currentId = weekDates[i].id;
    const currentCell = row.inputs.get(currentId);
    rowChanges.set(currentId, {
      ...currentCell,
      value: newValue
    });
  }
};

export function setPlantInputsChange(
  state: Draft<PlantInputsState>,
  payload: SetPlantInputValuePayload,
  needRecalculateRow = true
): void {
  const { table, row, weekDateId, newValue, caseId } = payload;
  const key = plantInputsKeyAccessor(caseId);
  const currentStatus = row.inputs.get(weekDateId);
  getPropOrDefault(key, state);
  getPropOrDefault(table, state[key]);

  let inputChanges = state[key][table].changes;

  if (!inputChanges) {
    inputChanges = new Map();
    state[key][table].changes = inputChanges;
  }

  let rowChanges = inputChanges.get(row.rowId);

  if (!rowChanges) {
    rowChanges = new Map();
    inputChanges.set(row.rowId, rowChanges);
  }

  rowChanges.set(weekDateId, {
    ...currentStatus,
    value: newValue
  });

  if (needRecalculateRow) {
    recalculateRow(row, rowChanges, state[key][table].dates, weekDateId, newValue);
  }
}

export function setUpliftFactorsChange(state: Draft<PlantInputsState>, payload: SetUpliftFactorsValuePayload): void {
  const { caseId, newItem, plant } = payload;
  const key = plantInputsKeyAccessor(caseId);
  getPropOrDefault(key, state);
  const caseChanges = getPropOrDefault('upliftFactors', state[key]);

  let upliftFactorsChange = caseChanges.changes;

  if (!upliftFactorsChange) {
    upliftFactorsChange = {};
    caseChanges.changes = upliftFactorsChange;
  }

  upliftFactorsChange[plant] = newItem;
}
