import { cloneElement, forwardRef, isValidElement, ReactNode, useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import { setRef, useForkRef } from '../util';

function getContainer(container) {
    return typeof container === 'function' ? container() : container;
}

/**
 * The props of the {@link Portal} component.
 * @category Props
 */
export interface PortalProps {
    children?: ReactNode;
    container?: Element | (() => Element | null) | null;
    disablePortal?: boolean;
}

/**
 * @category Component
 * @group Portal
 */
export const Portal = forwardRef(function Portal(props: PortalProps, ref) {
    const { children, container, disablePortal = false } = props;
    const [mountNode, setMountNode] = useState(null);
    const handleRef = useForkRef(isValidElement(children) ? (children as any).ref : null, ref);

    useEffect(() => {
        if (!disablePortal) {
            setMountNode(getContainer(container) || document.body);
        }
    }, [container, disablePortal]);

    useEffect(() => {
        if (mountNode && !disablePortal) {
            setRef(ref, mountNode);
            return () => {
                setRef(ref, null);
            };
        }

        return undefined;
    }, [ref, mountNode, disablePortal]);

    if (disablePortal) {
        if (isValidElement(children)) {
            return cloneElement(children, {
                ref: handleRef
            } as unknown);
        }
        return children;
    }

    return mountNode ? createPortal(children, mountNode) : mountNode;
});
