import cx from 'classnames';
import React, { cloneElement, forwardRef, HTMLProps, ReactElement, ReactNode } from 'react';
import { capitalize } from '../util';
import { ButtonProps } from './Button';
import styles from './ButtonGroup.module.css';

/**
 * The props for the {@link ButtonGroup} component
 */
export interface ButtonGroupProps extends Omit<HTMLProps<HTMLDivElement>, 'size'> {
    component?: any;
    children?: ReactElement<ButtonProps> | ReactElement<ButtonProps>[] | ReactNode;
    color?: ButtonProps['color'];
    disabled?: boolean;
    disableElevation?: boolean;
    fullWidth?: boolean;
    orientation?: 'vertical' | 'horizontal';
    size?: 'small' | 'medium' | 'large';
    variant?: 'text' | 'outlined' | 'contained';
}

/**
 * @category Component
 * @group Button
 */
export const ButtonGroup = forwardRef(function ButtonGroup(props: ButtonGroupProps, ref) {
    const {
        children,
        className,
        color = 'inherit',
        component: Component = 'div',
        disabled = false,
        disableElevation = false,
        fullWidth = false,
        orientation = 'horizontal',
        size = 'medium',
        variant = 'text',
        ...other
    } = props;

    const buttonClassName = cx(
        styles.grouped,
        styles[`grouped${capitalize(orientation)}`],
        styles[`grouped${capitalize(variant)}`],
        styles[`grouped${capitalize(variant)}${capitalize(orientation)}`],
        styles[`grouped${capitalize(variant)}${capitalize(color)}`],
        {
            [styles.disabled]: disabled
        }
    );

    return (
        <Component
            role="group"
            className={cx(
                styles.root,
                {
                    [styles.contained]: variant === 'contained',
                    [styles.vertical]: orientation === 'vertical',
                    [styles.fullWidth]: fullWidth,
                    [styles.disableElevation]: disableElevation
                },
                className
            )}
            ref={ref}
            {...other}
        >
            {React.Children.map(children, (child) => {
                if (!React.isValidElement(child)) {
                    return null;
                }

                return cloneElement(child, {
                    className: cx(buttonClassName, child.props.className),
                    color: child.props.color || color,
                    disabled: child.props.disabled || disabled,
                    disableElevation: child.props.disableElevation || disableElevation,
                    fullWidth,
                    size: child.props.size || size,
                    variant: child.props.variant || variant
                } as unknown);
            })}
        </Component>
    );
});
