import { Accessor } from '@visx/shape/lib/types';
import { Children, ReactNode, useMemo } from 'react';
import { isElement, isFragment } from 'react-is';
import { ChartInstance } from '../chartTypes';
import { componentName, isSeries, isStackedGroup } from './chartUtils';
import { getStackedData } from './getStackedData';
import { createAccessor } from './useAccessors';

export const xMinAccessor: Accessor<DataPoint, number | undefined> = (d) => (typeof d.x0 === 'undefined' ? d.x : d.x0);
export const xMaxAccessor: Accessor<DataPoint, number | undefined> = (d) => (typeof d.x1 === 'undefined' ? d.x : d.x1);

export const yMinAccessor: Accessor<DataPoint, number | undefined> = (d) => (typeof d.y0 === 'undefined' ? d.y : d.y0);
export const yMaxAccessor: Accessor<DataPoint, number | undefined> = (d) => (typeof d.y1 === 'undefined' ? d.y : d.y1);

export interface DataPoint {
    axis?: string;
    x?: number;
    x0?: number;
    x1?: number;
    y?: number;
    y0?: number;
    y1?: number;
}

export type AllData = DataPoint[];

export function getDataFromChildSeries(
    children: ChartInstance['children'],
    legend: ChartInstance['legend'],
    globalGetX: ChartInstance['getX'],
    globalGetY: ChartInstance['getY']
) {
    let allData: DataPoint[] = [];

    function processChild(Child: ReactNode) {
        if (Array.isArray(Child)) {
            Children.forEach(Child, processChild);
            return;
        }

        if (isFragment(Child)) {
            Children.forEach(Child.props.children, processChild);
            return;
        }

        if (!isElement(Child)) {
            return;
        }

        const { id, data, axis, xAccessor, yAccessor } = Child.props;

        if (legend.state[id]) {
            return;
        }

        const name = componentName(Child);
        if (isStackedGroup(name)) {
            const series = getStackedData(Child.props, Child.props.children, legend, globalGetX, globalGetY);
            series.forEach((item) => {
                const [Child, data] = item;
                allData = allData.concat(
                    data.map((point) => ({
                        axis: Child.props.axis,
                        ...point,
                    }))
                );
            });
        } else if (name === 'AreaDifferenceSeries') {
            allData = allData.concat(getDataFromChildSeries(Child.props.children, legend, globalGetX, globalGetY));
        } else if (Array.isArray(data) && isSeries(name)) {
            const getX = createAccessor(xAccessor) ?? globalGetX;
            const getY = createAccessor(yAccessor) ?? globalGetY;
            allData = allData.concat(
                data.map((point) => ({
                    axis,
                    x: getX?.(point),
                    y: getY?.(point),
                }))
            );
        }
    }

    Children.forEach(children, processChild);

    return allData;
}

export function useDataFromChild(...args: Parameters<typeof getDataFromChildSeries>) {
    return useMemo(
        () => getDataFromChildSeries(...args),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [...args]
    );
}
