import { LoadingOverlay } from 'components/common/Loading/LoadingOverlay';
import { DataGrid, DataGridColumnOptions } from 'components/library';
import { getDate } from 'utils/dateUtil';
import { roundToTwo } from 'utils/roundToTwoDigits';
import { useWellPiHistorianData } from './hooks/useWellPiHistorianData';

interface PiHistorianTableRow {
  tubingPressure: number | string;
  ftpRate: number | string;
  date?: string;
}

const columns: DataGridColumnOptions<PiHistorianTableRow>[] = [
  {
    accessor: 'date',
    Header: 'Date'
  },
  {
    accessor: 'tubingPressure',
    Header: 'Tubing Pressure'
  },
  {
    accessor: 'ftpRate',
    Header: 'FTP Oil Rate'
  }
];

export function PiHistorianTable() {
  const { data, isLoading } = useWellPiHistorianData();

  const formattedData = data?.map(
    (item): PiHistorianTableRow => ({
      tubingPressure: roundToTwo(item.tubingPressure),
      ftpRate: roundToTwo(item.ftpRate),
      date: getDate(item.timestamp)
    })
  );

  return (
    <LoadingOverlay loading={isLoading}>
      <DataGrid columns={columns} data={formattedData} />
    </LoadingOverlay>
  );
}
