import { Draft } from '@reduxjs/toolkit';
import { IWellPressureInputDto, WellPressureInputDto } from 'api';
import { getDifferenceInDays } from 'utils/dateUtil';
import { roundToFour } from 'utils/roundToTwoDigits';
import { WellPressureState } from '../WellPressureState';
import { WellPressureTableRow } from './convertWellPressureData';

export function calculateWellPressure(
  reservoirPressure: number,
  pressureDecline: number,
  dayDifference: number,
  prevValue?: number
) {
  return parseFloat(roundToFour((prevValue ?? reservoirPressure) + pressureDecline * dayDifference));
}

export function reCalculateWellPressure(
  state: Draft<WellPressureState>,
  row: WellPressureTableRow,
  startWeekDate?: number
) {
  const rowDraft = state.rows.find((x) => x.id === row.parentId)?.subRows?.find((x) => x.id === row.id);

  if (!rowDraft) {
    return;
  }

  const reservoirPressure = state.reservoirPressureChanges.get(row.id) ?? rowDraft.reservoirPressure ?? 0;
  const pressureDecline = state.pressureDeclineChanges.get(row.id) ?? rowDraft.pressureDecline ?? 0;

  const startIndex = startWeekDate ? state.dates.findIndex((x) => x.id === startWeekDate) : 0;
  for (let i = startIndex + 1; i < state.dates.length; i++) {
    const prevWeekDate = state.dates[i - 1];
    const prevValue =
      state.inputChanges.get(row.id)?.get(prevWeekDate?.id)?.pressureValue ??
      rowDraft.inputs.get(prevWeekDate?.id)?.pressureValue;

    const currentWeekDate = state.dates[i];
    const currentStatus = rowDraft.inputs.get(currentWeekDate.id);
    const differenceInDays = getDifferenceInDays(prevWeekDate.weekDate, currentWeekDate.weekDate);
    const newValue = calculateWellPressure(reservoirPressure, pressureDecline, differenceInDays, prevValue);

    // eslint-disable-next-line no-use-before-define
    setWellPressureInputChange(state, { row, currentStatus, weekDate: currentWeekDate.id, value: newValue }, false);
  }
}

export type ChangeWellPressureInputPayload = {
  row: WellPressureTableRow;
  weekDate: number;
  currentStatus: IWellPressureInputDto;
  value: number;
};

export function setWellPressureInputChange(
  state: Draft<WellPressureState>,
  payload: ChangeWellPressureInputPayload,
  calculateNestedChanges = true
) {
  const { row, weekDate, currentStatus, value } = payload;
  let rowChanges = state.inputChanges.get(row.id);
  if (!rowChanges) {
    rowChanges = new Map();
    state.inputChanges.set(row.id, rowChanges);
  }

  rowChanges.set(
    weekDate,
    new WellPressureInputDto({
      ...currentStatus,
      pressureValue: value
    })
  );

  if (calculateNestedChanges) {
    reCalculateWellPressure(state, row, weekDate);
  }
}

export function setWellPressureInputBulkChange(
  state: Draft<WellPressureState>,
  payload: ChangeWellPressureInputPayload[],
  calculateNestedChanges = false
) {
  for (const payloadItem of payload) {
    setWellPressureInputChange(state, payloadItem, calculateNestedChanges);
  }
}

export type ChangeWellPressureDeclinePayload = {
  row: WellPressureTableRow;
  value: number;
};

export function setWellPressureDeclineChange(
  state: Draft<WellPressureState>,
  payload: ChangeWellPressureDeclinePayload,
  calculateNestedChanges = true
) {
  const { row, value } = payload;

  state.pressureDeclineChanges.set(row.id, value);

  if (calculateNestedChanges) {
    reCalculateWellPressure(state, row);
  }
}
export function setWellPressureDeclineBulkChange(
  state: Draft<WellPressureState>,
  payload: ChangeWellPressureDeclinePayload[],
  calculateNestedChanges = false
) {
  for (const payloadItem of payload) {
    setWellPressureDeclineChange(state, payloadItem, calculateNestedChanges);
  }
}

export type ChangeWellReservoirPressurePayload = {
  row: WellPressureTableRow;
  value: number;
};

export function setWellReservoirPressureChange(
  state: Draft<WellPressureState>,
  payload: ChangeWellReservoirPressurePayload
) {
  const { row, value } = payload;

  state.reservoirPressureChanges.set(row.id, value);
}
