import { useMemo, useState } from 'react';
import { BrushState, ChartInstance } from '../chartTypes';

export function useBrush(): ChartInstance['brush'] {
    const [state, setState] = useState<ChartInstance['brush']['state']>({
        x: {},
        y: {}
    });
    const [initialState, setInitialState] = useState<ChartInstance['brush']['initialState']>({
        x: {},
        y: {}
    });

    const handleSetBrush = (isXOrientation: boolean, group: string, brushState: Partial<BrushState>) => {
        setState((state) => {
            const side = isXOrientation ? 'x' : 'y';

            return {
                ...state,
                [side]: {
                    ...state[side],
                    [group]: brushState
                }
            };
        });
    };

    const handleSetInitialState = (isXOrientation: boolean, group: string, brushState: Partial<BrushState>) => {
        setInitialState((state) => {
            const side = isXOrientation ? 'x' : 'y';

            return {
                ...state,
                [side]: {
                    ...state[side],
                    [group]: brushState
                }
            };
        });
    };

    return useMemo(
        () => ({
            state,
            setBrush: handleSetBrush,
            initialState,
            setInitialState: handleSetInitialState
        }),
        [handleSetBrush, state]
    );
}
