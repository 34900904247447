import React, { FC, FormEventHandler, useCallback } from 'react';
import { HeaderProps } from 'react-table';
import { Checkbox } from '../../Checkbox';
import { DataGridCellProps, DataGridInstance, DataGridPluginHook } from '../types';
import { serviceColumns } from '../utils/serviceColumns';
import styles from './Columns.module.css';

const SelectAllHeader = <T extends object>({
    settings,
    getToggleAllRowsSelectedProps,
    getToggleAllPageRowsSelectedProps
}: HeaderProps<T> & DataGridInstance<T>) => {
    const { selection } = settings;
    const toggleProps = settings.pagination ? getToggleAllPageRowsSelectedProps() : getToggleAllRowsSelectedProps();

    return selection.showSelectAll ? <Checkbox {...(toggleProps as any)} /> : null;
};

const SelectColumn: FC<DataGridCellProps> = ({ row }) => {
    const props = row.getToggleRowSelectedProps();

    delete props.onChange;

    const handleClick: FormEventHandler<HTMLInputElement> = useCallback(
        (event) => {
            row.selectRow(event);
        },
        [row]
    );

    return <Checkbox {...props} onChange={handleClick} />;
};

export const selectColumnHook: DataGridPluginHook = (hooks) => {
    hooks.visibleColumns.push((columns) => [
        {
            id: serviceColumns.selection,
            className: styles.selectColumn,
            Header: SelectAllHeader,
            Cell: SelectColumn,
            CellEdit: SelectColumn,
            minWidth: 42,
            width: 42,
            maxWidth: 42,
            disableResizing: true,
            sticky: columns?.[0]?.sticky,
            style: {
                padding: 0
            },
            align: 'center'
        },
        ...columns
    ]);
};
