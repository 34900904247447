import _, { map, union } from 'lodash';
import { useMemo } from 'react';
import { DataGridProps, DataGridSettings, DataGridState } from '../types';

type DataGridStatesOptions = Pick<DataGridSettings, 'filtering' | 'grouping' | 'pagination' | 'sorting'>;

export function useDataGridState(
    initialSelect: DataGridProps['initialSelect'],
    initialExpaned: DataGridProps['initialExpaned'],
    { pagination, sorting, filtering, grouping }: DataGridStatesOptions,
    columns
) {
    return useMemo(() => {
        const hiddenColumns = _(columns as any)
            .thru((column) => union(column, (map(column, 'columns') as any) || []))
            .flatten()
            .filter((column) => column?.hidden)
            .map((column) => column.id ?? column.accessor)
            .value();

        const result: DataGridState = {
            hiddenColumns
        };

        if (initialSelect) {
            result.selectedRowIds = initialSelect;
        }

        if (initialExpaned) {
            result.expanded = initialExpaned;
        }

        if (pagination) {
            result.pageIndex = pagination.pageIndex;
            result.pageSize = pagination.pageSize;
        }

        if (Array.isArray(sorting?.fields)) result.sortBy = sorting.fields;
        if (Array.isArray(filtering?.fields)) result.filters = filtering.fields;
        if (Array.isArray(grouping?.fields)) result.groupBy = grouping.fields;

        return result;
    }, [initialSelect, initialExpaned, filtering, grouping, pagination, sorting, columns]);
}
