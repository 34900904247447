import { SchemeClient } from 'api';
import { createAppAsyncThunk } from 'store/createAppAsyncThunk';
import { schemeSelector } from '../schemeSelectors';

const service = new SchemeClient();

interface GetSchemeParams {
  weekDateId: number;
  meterStationId?: number;
}

export const getScheme = createAppAsyncThunk(
  'scheme/getScheme',
  ({ weekDateId, meterStationId }: GetSchemeParams) => {
    return service.getScheme(weekDateId, meterStationId);
  },
  {
    condition: ({ weekDateId, meterStationId }, { getState }) => {
      const scheme = schemeSelector(getState());

      return !scheme.loading && scheme.data[meterStationId ?? 'main']?.weekDateId !== weekDateId;
    }
  }
);
