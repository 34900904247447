import { AnyAction, createSlice, PayloadAction, Reducer } from '@reduxjs/toolkit';
import { castDraft } from 'immer';
import { toast } from 'react-toastify';
import undoable, { GroupByFunction, includeAction, StateWithHistory } from 'redux-undo';
import { handleError, handleLoading } from 'store/base/baseStateHandlers';
import { CopySimulationCaseType } from 'store/wellPressure/types/CopySimulationCaseType';
import { getWellPI } from './actions/getWellPI';
import { saveWellPI } from './actions/saveWellPI';
import { convertWellPIData, WellPITableRow } from './utils/convertWellPIData';
import { copyWellPIColumns, pasteWellPIColumns } from './utils/copyPasteWellPI';
import {
  ChangeWellPIInputPayload,
  ChangeWellPIRatePayload,
  sealWellPIChanges,
  setWellPIInputBulkChange,
  setWellPIInputChange,
  setWellPIRateBulkChange,
  setWellPIRateChange
} from './utils/wellPIInputChange';
import { WellPIState } from './WellPIState';

const initialState: WellPIState = {
  splitableWeekDateIds: new Set(),
  rateChanges: new Map(),
  piChanges: new Map(),
  inputChanges: new Map()
};

const { actions, reducer } = createSlice({
  initialState,
  name: 'wellPI',
  reducers: {
    reset(state) {
      state.rateChanges.clear();
      state.piChanges.clear();
      state.inputChanges.clear();
    },
    setWellPIInput(state, action: PayloadAction<ChangeWellPIInputPayload>) {
      setWellPIInputChange(state, action.payload);
    },
    setWellPIInputBulk(state, action: PayloadAction<ChangeWellPIInputPayload[]>) {
      setWellPIInputBulkChange(state, action.payload, false);
    },
    setWellPIRate(state, action: PayloadAction<ChangeWellPIRatePayload>) {
      setWellPIRateChange(state, action.payload);
    },
    setWellPIRateBulk(state, action: PayloadAction<ChangeWellPIRatePayload[]>) {
      setWellPIRateBulkChange(state, action.payload, false);
    },
    copyColumns(state, action: PayloadAction<number[]>) {
      copyWellPIColumns(state, state.rows, action.payload);
    },
    pasteColumns(state, action: PayloadAction<number[]>) {
      pasteWellPIColumns(state, action.payload);
    },
    copySimulationCaseWellPI(state, action: PayloadAction<CopySimulationCaseType<WellPITableRow>>) {
      try {
        const { rows, columns, targetWeekDateIds } = action.payload;
        copyWellPIColumns(state, rows, columns);
        pasteWellPIColumns(state, targetWeekDateIds, false);
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);
        toast.error(`PI Data: ${error instanceof Error ? error.message : 'Error on transfer data'}`);
      }
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getWellPI.pending, (state, action) => {
        state.arg = castDraft(action.meta.arg);
        handleLoading(state);
      })
      .addCase(getWellPI.fulfilled, (state, action) => {
        state.arg = castDraft(action.meta.arg);
        state.loading = false;
        state.data = action.payload.items;
        const { rows, dates, rowsLookup, datesByWeekNumber, datesLookup, splitableWeekDateIds } = convertWellPIData(
          action.payload.items,
          action.payload.adjustWeekDates
        );
        state.rows = rows;
        state.dates = dates;
        state.datesByWeekNumber = datesByWeekNumber;
        state.splitableWeekDateIds = splitableWeekDateIds;
        state.datesById = datesLookup;
        state.rowsLookup = rowsLookup;
      })
      .addCase(getWellPI.rejected, (state, action) => {
        state.arg = undefined;
        handleError(state, action);
      });

    builder.addCase(saveWellPI.fulfilled, (state) => {
      sealWellPIChanges(state);
      state.rateChanges.clear();
      state.piChanges.clear();
      state.inputChanges.clear();
    });
  }
});
export const wellPIActions = actions;
export const { reset, setWellPIRate, setWellPIInput, copyColumns, pasteColumns } = actions;

const groupBy: GroupByFunction<WellPIState, AnyAction> = (action) => {
  if (action.type === getWellPI.fulfilled.type) {
    return 'well-gor-data';
  }

  return null;
};

export const getWellPIUndoableReducer = (
  reducer: Reducer<typeof initialState>
): Reducer<StateWithHistory<WellPIState>, AnyAction> =>
  undoable(reducer, {
    ignoreInitialState: true,
    groupBy,
    undoType: 'WELL_PI_UNDO',
    clearHistoryType: 'WELL_PI_UNDO_CLEAR',
    filter: includeAction([
      setWellPIInput.type,
      getWellPI.fulfilled.type,
      saveWellPI.fulfilled.type,
      pasteColumns.type,
      setWellPIRate.type,
      reset.type
    ])
  });

export default getWellPIUndoableReducer(reducer);
