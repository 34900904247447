import { useContext } from 'react';
import { ITearecsComponentsConfiguration } from './ComponentsConfiguration';
import { TearecsComponentsConfiguration } from './ConfigurationContext';

export function useComponentsConfiguration<TKey extends keyof ITearecsComponentsConfiguration, TProps>(
    property: TKey,
    props: TProps
): TProps {
    const configuration = useContext(TearecsComponentsConfiguration);

    if (!configuration?.[property]) {
        return props;
    }

    return {
        ...configuration[property],
        ...props
    };
}
