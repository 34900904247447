import cx from 'classnames';
import React, { FC, forwardRef, HTMLAttributes } from 'react';
import { Typography, TypographyProps } from '../Typography';
import styles from './List.module.css';

/**
 * The props of the {@link List} component.
 * @category Props
 */
interface ListItemTextProps extends TypographyProps {}

/**
 * @category Component
 * @group List
 */
export const ListItemText: FC<ListItemTextProps> = ({ className = '', ...other }) => {
    return <Typography className={cx(styles.item_text, className)} variant="body2" {...other} />;
};

/**
 * The props of the {@link List} component.
 * @category Props
 */
export interface ListItemProps extends HTMLAttributes<HTMLLIElement> {
    component?: any;
    disableMargin?: boolean;
    gutters?: boolean;
    button?: boolean;
    selected?: boolean;
    disabled?: boolean;
}

/**
 * @category Component
 * @group List
 */
export const ListItem = forwardRef(function ListItem(
    { className, component: Component = 'li', disableMargin = false, gutters = false, button, ...other }: ListItemProps,
    ref
) {
    return (
        <Component
            ref={ref}
            className={cx(
                styles.item,
                {
                    [styles.item_margin]: !disableMargin,
                    [styles.item_gutters]: gutters,
                    [styles.item_button]: button,
                },
                className
            )}
            {...other}
        />
    );
});
