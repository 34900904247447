export enum OUTLOOK_TAB {
  forecast = 'forecast',
  lookback = 'lookback',
  wedgeGraph = 'wedge graph',
  wedgeGraphRoute = 'wedge-graph'
}

export enum OUTLOOK_VIEW_MODE {
  chart = 'chart',
  table = 'table'
}

export enum OUTLOOK_PLANT {
  KTL = 'KTL',
  SGP = 'SGP',
  TGP = 'TGP',
  TGP_VALUE = '3GP'
}

export const SMPC_SERIES = 'SMPC';
