import { Navigate, RouteObject } from 'react-router-dom';
import { lazyComponent } from 'utils/lazyComponent';

const ScenarioCompare = lazyComponent(() => import('.'), 'scenarioCompare');
const CompareLineup = lazyComponent(() => import('./lineup/CompareLineup'), 'compareLineup');
const CompareWellInput = lazyComponent(() => import('./wellInput/CompareWellInput'), 'compareWellInput');

const CompareSubsurfaceData = lazyComponent(
  () => import('./subsurface/CompareSubsurfaceData'),
  'compareSubsurfaceData'
);
const CompareWellPressure = lazyComponent(() => import('./subsurface/CompareWellPressure'), 'compareWellPressure');
const CompareWellGor = lazyComponent(() => import('./subsurface/CompareWellGor'), 'compareWellGor');
const CompareWellPI = lazyComponent(() => import('./subsurface/CompareWellPI'), 'compareWellPI');

export const scenarioCompareCaseRoute: RouteObject = {
  path: 'compare',
  element: <ScenarioCompare />,
  children: [
    {
      index: true,
      element: <Navigate replace to="lineup" />
    },
    {
      path: 'lineup',
      element: <CompareLineup />
    },
    {
      path: 'wells',
      element: <CompareWellInput />
    },
    {
      path: 'input-data',
      element: <CompareSubsurfaceData />,
      children: [
        {
          index: true,
          element: <Navigate replace to="pressure" />
        },
        {
          path: 'pressure',
          element: <CompareWellPressure />
        },
        {
          path: 'gor',
          element: <CompareWellGor />
        },
        {
          path: 'pi',
          element: <CompareWellPI />
        }
      ]
    }
  ]
};
