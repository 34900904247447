import React, { cloneElement, FC, memo, useContext } from 'react';
import { ChartContext, ChartTooltipContext } from '../ChartContext';

/**
 * @category Component
 * @group Chart
 */
let RenderCrossHairs: FC = () => {
    const instance = useContext(ChartContext);
    const tooltip = useContext(ChartTooltipContext);

    const { layers } = instance;

    return (
        <>
            {tooltip.tooltipData &&
                layers.CrossHairs.length > 0 &&
                layers.CrossHairs.map((CrossHair, i) =>
                    cloneElement(CrossHair, {
                        key: `crosshair-${i}`,
                        ...tooltip.tooltipData,
                    })
                )}
        </>
    );
};

RenderCrossHairs = memo(RenderCrossHairs);

export { RenderCrossHairs };
