import { DiagramEdgeData, DiagramNodeData } from 'domain/scheme';
import produce from 'immer';
import { Edge, EdgeMarker, Node } from 'reactflow';
import { defaultEdgeColor } from './convertToDiagramEdges';

function getEdgeColor(closedNodeIds: Set<string>, edge: Edge<DiagramEdgeData>) {
  if (closedNodeIds.has(edge.source) || closedNodeIds.has(edge.target)) {
    return defaultEdgeColor;
  }

  return edge.data.original.backgroundColor ?? defaultEdgeColor;
}

export function applyEdgeStatuses(nodes: Node<DiagramNodeData>[], edges: Edge<DiagramEdgeData>[]) {
  const closedNodeIds = new Set(nodes.filter((x) => x.data.status === false).map((x) => x.id));

  return edges.map((edge) => {
    const color = getEdgeColor(closedNodeIds, edge);

    return produce(edge, (draft) => {
      draft.style.stroke = color;
      if (draft.markerEnd) {
        (draft.markerEnd as EdgeMarker).color = color;
      }
    });
  });
}
