import { CircularProgress, Grid, Typography } from '@mui/material';

export function LoadingComponent() {
  return (
    <Grid container direction={'column'} alignItems={'center'} justifyContent={'center'}>
      <Grid item pt={5}>
        <CircularProgress size="3rem" />
      </Grid>
      <Grid item pt={2}>
        <Typography sx={{ fontSize: '10', fontFamily: 'Roboto' }}>Loading...</Typography>
      </Grid>
      <Grid item pt={50}>
        <img src="/images/logo.svg" alt="logo" height={100} />
      </Grid>
    </Grid>
  );
}
