import { ReactElement, useContext } from 'react';
import { RouteObject, UNSAFE_RouteContext, useOutlet } from 'react-router-dom';

function findMatchInProps(element: ReactElement) {
  if (!element) {
    return undefined;
  }

  if (element.props.match) {
    return element.props?.match?.route;
  }

  if (element.props.children) {
    return findMatchInProps(element.props.children);
  }

  return undefined;
}

export function useOutletRoute(): RouteObject | undefined {
  const outlet = useOutlet();
  return findMatchInProps(outlet);
}

export function useLatestMatch() {
  const { matches } = useContext(UNSAFE_RouteContext);
  return matches[matches.length - 1];
}
