import React, { FC, useContext } from 'react';
import { Typography } from '../../Typography';
import { DataGridContext } from '../DataGridContext';
import styles from './DragIndicator.module.css';

/**
 * @category Component
 * @group Data Grid
 */
export const DataGridDragIndicator: FC = () => {
    const instance = useContext(DataGridContext);
    const { selectedFlatRows } = instance;

    if (selectedFlatRows.length < 2) {
        return null;
    }

    return (
        <Typography highlight color="primary" className={styles.indicator}>
            {selectedFlatRows.length}
        </Typography>
    );
};
