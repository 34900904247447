import { Draft } from '@reduxjs/toolkit';
import { IP6DetailsDto, IP6Dto } from 'api';
import { P6State } from '../P6State';

export function getRowChange(state: Draft<P6State>, rowId: number) {
  let rowChanges = state.edits.get(rowId);
  if (!rowChanges) {
    const row = state.rowsById.get(rowId);
    state.edits.set(rowId, {
      ...row,
      p6Detail: {
        ...row?.p6Detail
      }
    });
    rowChanges = state.edits.get(rowId);
  }

  return rowChanges;
}

export type ChangeDetailsPayload<TProperty extends keyof IP6DetailsDto> = {
  rowId: number;
  property: TProperty;
  value: IP6DetailsDto[TProperty];
};

export function setComponentChange(state: Draft<P6State>, payload: ChangeDetailsPayload<'componentId'>) {
  const { rowId, value } = payload;
  const rowChanges = getRowChange(state, rowId);

  rowChanges.p6Detail.componentId = value;
  rowChanges.p6Detail.headerId = undefined;
  rowChanges.p6Detail.meterStationId = undefined;
  rowChanges.p6Detail.wellId = undefined;
  rowChanges.p6Detail.pipelineId = undefined;
}

const detailProperties = ['headerId', 'meterStationId', 'wellId', 'pipelineId'] as const;

export type P6DetailProperties = typeof detailProperties[number];

export function setDetailChange(state: Draft<P6State>, payload: ChangeDetailsPayload<P6DetailProperties>) {
  const { rowId, property, value } = payload;
  const rowChanges = getRowChange(state, rowId);

  rowChanges.p6Detail[property] = value;
  const otherProperties = detailProperties.filter((x) => x !== property);
  for (const otherProperty of otherProperties) {
    rowChanges.p6Detail[otherProperty] = undefined;
  }
}

export function setPlantChange(state: Draft<P6State>, payload: ChangeDetailsPayload<'plantId'>) {
  const { rowId, value } = payload;
  const rowChanges = getRowChange(state, rowId);

  rowChanges.p6Detail.plantId = value;
}

export function setStatusChange(state: Draft<P6State>, payload: ChangeDetailsPayload<'status'>) {
  const { rowId, value } = payload;
  const rowChanges = getRowChange(state, rowId);

  rowChanges.p6Detail.status = value;
}

const timeStepProperties = ['startDateTS', 'finishDateTS'] as const;

export type P6TimeStepProperties = typeof timeStepProperties[number];

export function setTimeStepChange(state: Draft<P6State>, payload: ChangeDetailsPayload<P6TimeStepProperties>) {
  const { rowId, property, value } = payload;
  const rowChanges = getRowChange(state, rowId);

  rowChanges.p6Detail[property] = value;
}

type DetailsProperties = 'componentId' | P6DetailProperties | 'plantId' | 'status' | P6TimeStepProperties;

export type ChangeAnyDetailsPayload = ChangeDetailsPayload<DetailsProperties>;

export function setDetailsChange(state: Draft<P6State>, payload: ChangeAnyDetailsPayload) {
  switch (true) {
    case payload.property === 'componentId':
      setComponentChange(state, payload as never);
      break;
    case detailProperties.includes(payload.property as never):
      setDetailChange(state, payload as never);
      break;
    case payload.property === 'plantId':
      setPlantChange(state, payload as never);
      break;
    case payload.property === 'status':
      setStatusChange(state, payload as never);
      break;
    case timeStepProperties.includes(payload.property as never):
      setTimeStepChange(state, payload as never);
      break;
  }
}

export type EditActivityPayload = {
  rowId: number;
  model: IP6Dto;
};

export function setEditChange(state: Draft<P6State>, payload: EditActivityPayload) {
  const { rowId, model } = payload;
  const { p6Detail, ...restModel } = model;

  const rowChanges = getRowChange(state, rowId);

  Object.assign(rowChanges, restModel);
  Object.assign(rowChanges.p6Detail, p6Detail);
}
