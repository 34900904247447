import {
    TimelineResource,
    Accessor,
    TimelineRow,
    UniversalTimelineInstance,
    DecoratedResourceItem
} from '../TimelineTypes';
import { makeRenderer } from './makeRenderer';

const defaultHeader = () => null;

export function decorateResource<TItem, TResource>(
    resource: TimelineResource<TItem, TResource>,
    index: number,
    instance: UniversalTimelineInstance
): TimelineResource<TItem, TResource> {
    const {
        id: originalId,
        itemAccessor,
        idAccessor,
        data: originalData,
        dragKey = 'drag',
        Header = defaultHeader,
        ...other
    } = resource;

    const id = originalId ?? index.toString();

    const newResource: TimelineResource<TItem, TResource> = {
        id,
        idAccessor: decorateAccessor(idAccessor ?? 'Id'),
        itemAccessor: decorateAccessor(itemAccessor ?? id),
        Header,
        dragKey,
        data: null,
        ...other
    };

    newResource.data = (originalData as TResource[]).map((item, index) => {
        const drag = item?.[dragKey] ?? true;
        const render = makeRenderer(instance, newResource, { original: item });
        return { original: item, drag, order: index, render } as DecoratedResourceItem<TItem, TResource>;
    });

    return newResource;
}

export function decorateAccessor<TItem>(accessor): Accessor<TItem> {
    if (typeof accessor === 'string') {
        const accessorPath = accessor.split('.');
        return row => getBy(row, accessorPath);
    }

    return accessor;
}

const pathObjCache = new Map();

export function getBy(obj, path, def = null) {
    if (!path) {
        return obj;
    }
    const cacheKey = typeof path === 'function' ? path : JSON.stringify(path);

    const pathObj =
        pathObjCache.get(cacheKey) ||
        (() => {
            const pathObj = makePathArray(path);
            pathObjCache.set(cacheKey, pathObj);
            return pathObj;
        })();

    let val;

    try {
        val = pathObj.reduce((cursor, pathPart) => cursor[pathPart], obj);
    } catch (e) {
        //
    }
    return typeof val !== 'undefined' ? val : def;
}

const reOpenBracket = /\[/g;
const reCloseBracket = /\]/g;

function makePathArray(obj) {
    return flattenDeep(obj)
        .map(d => String(d).replace('.', '_'))
        .join('.')
        .replace(reOpenBracket, '.')
        .replace(reCloseBracket, '')
        .split('.');
}

function flattenDeep(arr, newArr = []) {
    if (!Array.isArray(arr)) {
        newArr.push(arr);
    } else {
        for (let i = 0; i < arr.length; i += 1) {
            flattenDeep(arr[i], newArr);
        }
    }
    return newArr;
}
