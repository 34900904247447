import { faLongArrowAltDown, faLongArrowAltUp, faStream, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cx from 'classnames';
import React, { CSSProperties, forwardRef, ForwardRefExoticComponent, memo, RefAttributes, useContext } from 'react';
import { IconButton } from '../../Button/IconButton';
import { TableCellProps, TableHeaderCell } from '../../Table/TableCell';
import cellStyles from '../../Table/TableCell.module.css';
import styles from '../../Table/TableHeader.module.css';
import { Toolbar } from '../../Toolbar';
import { Typography } from '../../Typography';
import { useForkRef } from '../../util';
import { DataGridContext } from '../DataGridContext';
import { DataGridFilter } from '../filters/DataGridFilter';
import { useStikyColumnHeader } from '../hooks/stickyColumnHook';
import { DataGridColumnInstance } from '../types';

export interface DataGridHeaderCellRendererProps<T extends object = any> extends TableCellProps {
    index?: number;
    column: DataGridColumnInstance<T>;
    totalWidth: number;
}

export type DataGridHeaderCellRenderer = ForwardRefExoticComponent<
    React.PropsWithoutRef<DataGridHeaderCellRendererProps> & RefAttributes<HTMLTableHeaderCellElement>
>;

export const RenderDataGridHeaderCell: DataGridHeaderCellRenderer = memo(
    forwardRef(function RenderDataGridHeaderCell(
        { className: classNameProp, blockLayout, column, totalWidth, style: styleProp, ...other },
        ref
    ) {
        const instance = useContext(DataGridContext);

        const { headerRef, stickyStyle } = useStikyColumnHeader(column, instance);

        const handleRef = useForkRef(ref, headerRef);

        const {
            sorting,
            grouping,
            filtering,
            resizeColumns,
            HeaderCellComponent = TableHeaderCell
        } = instance.settings;

        const style: CSSProperties = styleProp ?? {};
        let className = classNameProp ?? '';

        Object.assign(style, stickyStyle);

        (function collectStyles(column: DataGridColumnInstance) {
            if (column.placeholderOf) {
                collectStyles(column.placeholderOf);
            }

            Object.assign(style, column.style);
            className = cx(className, column.className);
        })(column);

        const columnProps = column.getHeaderProps({
            className: cx(styles.cell, className),
            style,
            ...other
        });

        let sortProps = null;

        if (sorting?.allow && column.canSort) sortProps = column.getSortByToggleProps();

        if (!blockLayout) {
            columnProps.style = {
                ...columnProps.style,
                width: `${((column.totalWidth * 100) / totalWidth).toFixed(2)}%`
            };
        }

        const result = (
            <Toolbar className={cx(styles.toolbar)}>
                {grouping?.allow && column.canGroupBy && (
                    <IconButton {...column.getGroupByToggleProps()} size="small">
                        <FontAwesomeIcon icon={column.isGrouped ? faTimesCircle : faStream} size="xs" />
                    </IconButton>
                )}
                <Typography
                    noWrap
                    align={column.align}
                    className={styles.cellHeader}
                    component="div"
                    variant={sortProps ? 'subtitle2' : 'inherit'}
                    {...sortProps}
                    {...column.typographyProps}
                >
                    {column.render('Header')}
                </Typography>
                {column.isSorted && (
                    <IconButton {...sortProps} size="small">
                        <FontAwesomeIcon icon={column.isSortedDesc ? faLongArrowAltDown : faLongArrowAltUp} size="xs" />
                    </IconButton>
                )}
                {filtering?.allow && column.canFilter && (
                    <DataGridFilter column={column}>{column.render('Filter')}</DataGridFilter>
                )}
            </Toolbar>
        );

        // if (!isBlockLayout) {
        //     result = (
        //         <div
        //             style={{
        //                 minWidth: column.minWidth,
        //                 width: column.width,
        //                 maxWidth: column.maxWidth,
        //                 overflow: 'hidden'
        //             }}
        //         >
        //             {result}
        //         </div>
        //     );
        // }

        return (
            <HeaderCellComponent ref={handleRef} blockLayout={blockLayout} align={column.align} {...columnProps}>
                {result}
                {resizeColumns && column.canResize && (
                    <div
                        {...column.getResizerProps()}
                        className={cx(cellStyles.resizer, {
                            [cellStyles.isResizing]: column.isResizing
                        })}
                    />
                )}
            </HeaderCellComponent>
        );
    })
);

export const defaultDataGridHeaderCellWrapper = function DefaultDataGridRowWrapper(
    headerCellRenderer: DataGridHeaderCellRenderer
) {
    return headerCellRenderer;
};
