import { useCallback } from 'react';
import { DataGridInstance, DataGridPluginHook, DataGridState } from '../types';

const UPDATE_STATE = 'UPDATE_STATE';

export const setStateHook: DataGridPluginHook = (hooks) => {
    hooks.stateReducers.push((state: DataGridState, action) => {
        if (action.type === UPDATE_STATE) {
            return { ...state, ...action.payload };
        }

        return state;
    });

    hooks.useInstance.push((instance: DataGridInstance) => {
        const { dispatch } = instance;
        const setState = useCallback(
            (state: Partial<DataGridState>) => {
                dispatch({ type: UPDATE_STATE, payload: state });
            },
            [dispatch]
        );

        Object.assign(instance, {
            setState
        });
    });
};
