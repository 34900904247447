import { Draft } from '@reduxjs/toolkit';
import { ConstraintsState, ConstraintsStateKeyAccessor } from '../types/ConstraintsState';
import { constraintsOptions } from './constraintOptions';
import { equipmentTypeOptions } from './equipmentTypeOptions';

export function setConstraintsInitialState(state: Draft<ConstraintsState>, keyAccessor: ConstraintsStateKeyAccessor) {
  if (!state[keyAccessor]) {
    state[keyAccessor] = {
      rows: [],
      weekDates: [],
      constraintsOptions,
      equipmentTypeOptions,
      enableSave: false
    };
  }
}
