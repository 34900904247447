import { Children, ReactElement, ReactNode } from 'react';
import { isElement, isFragment } from 'react-is';
import { SeriesOptions } from '../chartTypes';
import { componentName, isSeries } from './chartUtils';

export function getChildSeries<TOptions = SeriesOptions>(children: ReactNode): [ReactElement<TOptions>[], ReactNode[]] {
    const restChilds: ReactNode[] = [];
    const Series: ReactElement<TOptions>[] = [];

    function precessChild(Child: ReactNode): void {
        if (Array.isArray(Child)) {
            Children.forEach(Child, precessChild);
            return;
        }

        if (isFragment(Child)) {
            Children.forEach(Child.props.children, precessChild);
            return;
        }

        const name = componentName(Child);

        if (isElement(Child) && isSeries(name)) {
            Series.push(Child);
            return;
        }

        restChilds.push(Child);
    }

    Children.forEach(children, precessChild);

    return [Series, restChilds];
}
