import { groupBy } from 'lodash';
import React, { cloneElement, createRef, useMemo, useRef } from 'react';
import { ChartInstance } from '../chartTypes';
import { createAccessor } from './useAccessors';

const labelSizeFunction = ({ label, title, wrap: wrapProp }, characterWidth = 8, lineHeight = 20, padding = 1) => {
    const text = label || title;

    const textLength = text.length;
    const wrap = wrapProp || 120;
    const width = Math.min(wrap, textLength * characterWidth) + padding * 2;
    const height = Math.ceil((textLength * characterWidth) / 120) * lineHeight + padding * 2;
    return [width, height];
};

interface Properties {
    allData: ChartInstance['allData'];
    Annotations: ChartInstance['layers']['Annotations'];
    groupDimensions: ChartInstance['groupDimensions'];
    xScales: ChartInstance['xScales'];
    yScales: ChartInstance['yScales'];
    getX: ChartInstance['getX'];
    getY: ChartInstance['getY'];
    innerHeight: number;
    innerWidth: number;
}

export function useAnnotations({
    allData,
    Annotations,
    groupDimensions,
    xScales,
    yScales,
    getX: globalGetX,
    getY: globalGetY,
    innerHeight,
    innerWidth,
}: Properties) {
    const rawFlag = useRef(false);

    const dataPoints = useMemo(
        () =>
            allData.map(({ x, y, axis }) => {
                const xScale = xScales[axis] ?? xScales['undefined'];
                const yScale = yScales[axis] ?? yScales['undefined'];

                return {
                    x: xScale?.(x),
                    y: yScale?.(y),
                    width: 1,
                    height: 1,
                    name: '',
                };
            }),
        [allData, xScales, yScales]
    );

    const [list, refs] = useMemo(() => {
        rawFlag.current = true;

        const refs = [];
        const list = Annotations.map((annotation) => {
            const { x, y, point, axis, xAccessor, yAccessor } = annotation.props;

            const positionProps = {
                x,
                y,
                dx: 15,
                dy: 15,
            };

            if (point) {
                const getX = createAccessor(xAccessor) ?? globalGetX;
                const getY = createAccessor(yAccessor) ?? globalGetY;

                const xScale = xScales[axis] ?? xScales['undefined'];
                const yScale = yScales[axis] ?? yScales['undefined'];

                positionProps.x = positionProps.x ?? xScale?.(getX(point));
                positionProps.y = positionProps.y ?? yScale?.(getY(point));
            }

            const ref = createRef();

            refs.push(ref);

            return cloneElement(annotation, {
                ...positionProps,
                noteRef: ref,
            });
        });

        return [list, refs];
    }, [Annotations, globalGetX, globalGetY, xScales, yScales]);

    //const [adjustedList, setAdjustedList] = useState(list);

    //adjust annotations
    // useLayoutEffect(() => {
    //     if (!rawFlag.current) return;
    //     rawFlag.current = false;

    //     const [labels, anchors] = list.reduce(
    //         ([labels, anchors], annotation, index) => {
    //             const { x, y, dx, dy, axis, radius } = annotation.props;
    //             const {
    //                 position: { top, left },
    //             } = groupDimensions[axis];

    //             const ref = refs[index];

    //             if (!ref.current) {
    //                 return [labels, anchors];
    //             }

    //             const { width, height } = ref.current.note.getBBox();

    //             anchors.push({
    //                 x: left + x ?? 0,
    //                 y: top + y ?? 0,
    //                 r: radius ?? 15,
    //             });

    //             labels.push({
    //                 x: left + x + dx ?? 0,
    //                 y: top + y + dy ?? 0,
    //                 width: Math.max(width, 40) ?? 0,
    //                 height: Math.max(height, 40) ?? 0,
    //                 name: '',
    //             });

    //             return [labels, anchors];
    //         },
    //         [[], []]
    //     );

    //     // const instantiatedLabeler = labeler();
    //     // instantiatedLabeler.label(labels.concat(dataPoints));
    //     // instantiatedLabeler.anchor(anchors.concat(dataPoints));
    //     // instantiatedLabeler.width(innerWidth);
    //     // instantiatedLabeler.height(innerHeight);
    //     // instantiatedLabeler.start(20);

    //     const newList = list.map((annotation, index) => {
    //         const label = labels[index];
    //         const { x, y, axis } = annotation.props;
    //         const {
    //             position: { top, left },
    //         } = groupDimensions[axis];

    //         return cloneElement(annotation, {
    //             dx: label.x - left - x,
    //             dy: label.y - top - y,
    //         });
    //     });

    //     setAdjustedList(newList);
    // }, [allData, dataPoints, groupDimensions, innerHeight, innerWidth, list, refs]);

    const result = list; // rawFlag.current ? list : adjustedList;

    const AnnotationsGroups = useMemo(() => groupBy(result, (item) => item.props.axis), [result]);

    return {
        Annotations: result,
        AnnotationsGroups,
    };
}
