import cx from 'classnames';
import React, { forwardRef, ReactNode } from 'react';
import { capitalize } from '../util';
import { ButtonBase, ButtonBaseProps } from './ButtonBase';
import styles from './IconButton.module.css';

/**
 * The props for the {@link IconButton} component
 */
export interface IconButtonProps extends ButtonBaseProps {
    children?: ReactNode;
    color?: 'inherit' | 'primary' | 'secondary' | 'default';
    disabled?: boolean;
    edge?: 'start' | 'end' | false;
    size?: 'small' | 'medium';
    to?: string;
}

/**
 * @category Component
 * @group Button
 */
export const IconButton = forwardRef<HTMLButtonElement, IconButtonProps>(function IconButton(props, ref) {
    const { edge = false, children, className, color = 'inherit', disabled = false, size = 'medium', ...other } = props;

    return (
        <ButtonBase
            className={cx(
                styles.root,
                {
                    [styles[`color${capitalize(color)}`]]: color !== 'default',
                    [styles.disabled]: disabled,
                    [styles[`size${capitalize(size)}`]]: size !== 'medium',
                    [styles.edgeStart]: edge === 'start',
                    [styles.edgeEnd]: edge === 'end',
                },
                className
            )}
            disabled={disabled}
            ref={ref}
            {...other}
        >
            <span className={styles.label}>{children}</span>
        </ButtonBase>
    );
});
