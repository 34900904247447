import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { handleError, handleLoading } from 'store/base/baseStateHandlers';
import { defaultPaging, getP6Data } from './actions/getP6Data';
import { saveP6Data } from './actions/saveP6Data';
import { P6State } from './P6State';
import { convertP6Data } from './utils/convertP6Data';
import { ChangeAnyDetailsPayload, EditActivityPayload, setDetailsChange, setEditChange } from './utils/p6Changes';

const initialState: P6State = {
  edits: new Map()
};

const { actions, reducer } = createSlice({
  initialState,
  name: 'p6',
  reducers: {
    reset(state) {
      state.edits.clear();
    },
    editActivity(state, action: PayloadAction<EditActivityPayload>) {
      setEditChange(state, action.payload);
    },
    changeDetails(state, action: PayloadAction<ChangeAnyDetailsPayload>) {
      setDetailsChange(state, action.payload);
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getP6Data.pending, (state, action) => {
        handleLoading(state);
        state.paging = action.meta.arg?.paging ?? defaultPaging;
      })
      .addCase(getP6Data.fulfilled, (state, action) => {
        const { data } = action.payload;
        const { rows, rowsById } = convertP6Data(data?.items);

        state.loading = false;
        state.data = rows;
        state.rowsById = rowsById;
      })
      .addCase(getP6Data.rejected, (state, action) => {
        handleError(state, action);
      })
      .addCase(saveP6Data.fulfilled, (state) => {
        state.edits.clear();
      });
  }
});

export const { reset, editActivity, changeDetails } = actions;

export default reducer;
