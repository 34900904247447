import moment from 'moment';
import React, { FC, useContext, useMemo } from 'react';
import { Controller } from 'react-hook-form';
import { DatePicker, DatePickerProps } from '../../DatePicker';
import { Typography } from '../../Typography';
import { DataGridContext } from '../DataGridContext';
import { DataGridCellProps } from '../types';

/**
 * @category Component
 * @group Data Grid Cell
 */
export const DateTimeCell = <T extends object>({ value }: DataGridCellProps<T>) => {
    const date = moment(value);
    return (
        <Typography noWrap variant="inherit" component="div">
            {value && date.isValid() ? date.format('DD-MMM-YYYY | HH:mm') : null}
        </Typography>
    );
};

/**
 * @category Component
 * @group Data Grid Cell
 */
export const DataGridDateCell = <T extends object>({ value }: DataGridCellProps<T>) => {
    const date = moment(value);
    return (
        <Typography noWrap variant="inherit" component="div">
            {value && date.isValid() ? date.format('DD-MMM-YYYY') : null}
        </Typography>
    );
};

/**
 * @category Component
 * @group Data Grid Cell
 */
export const DataGridDateCellEdit = <T extends object>({
    column: { id },
    row: { index, original },
    ...other
}: DataGridCellProps<T> & DatePickerProps) => {
    const { form } = useContext(DataGridContext);
    const { control, getValues } = form;

    const key = useMemo(() => `row[${index}].${id}`, [id, index]);
    const defaultValue = useMemo(() => getValues(key) ?? original[id as string], [getValues, id, key, original]);

    return (
        <Controller
            name={key}
            defaultValue={defaultValue}
            control={control}
            render={({ value, onChange }) => (
                <DatePicker
                    disableMargin
                    disableMinWidth
                    hideCalendar
                    openOnFocus
                    {...other}
                    value={value}
                    onChange={(value) => onChange(value)}
                />
            )}
        />
    );
};

/**
 * @category Component
 * @group Data Grid Cell
 */
export const DataGridOptionalDateCellEdit: FC<DataGridCellProps & DatePickerProps> = (props) => {
    return <DataGridDateCellEdit enableClear {...props} />;
};

/**
 * @category Component
 * @group Data Grid Cell Config
 */
export const DataGridDateCellFull = {
    Cell: DataGridDateCell,
    CellEdit: DataGridDateCellEdit,
    sortType: 'datetime'
};

const calendarConfig = {
    sameDay: '[Today]',
    nextDay: '[Tomorrow]',
    nextWeek: 'dddd',
    lastDay: '[Yesterday]',
    lastWeek: '[Last] dddd',
    sameElse: 'DD-MMM-YYYY'
};

/**
 * @category Component
 * @group Data Grid Cell
 */
export const DataGridHumanDateCell: FC<DataGridCellProps> = ({ value, typographyProps }) => {
    return (
        <Typography noWrap variant="inherit" component="div" {...typographyProps}>
            {moment(value).calendar(null, calendarConfig)}
        </Typography>
    );
};
