import { Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import { DataGridProps } from '@tearecs/components/src/DataGrid';
import {
  TableBodyProps,
  TableCellProps,
  TableHeaderProps,
  TableProps,
  TableRowProps
} from '@tearecs/components/src/Table';
import cx from 'classnames';
import { forwardRef, Ref } from 'react';
import styles from './DataGrid.module.css';

export const DataGridRow = forwardRef(function DataGridRow(
  { className, blockLayout, ...other }: TableRowProps,
  ref: Ref<HTMLTableRowElement>
) {
  const component = blockLayout ? 'div' : 'tr';
  return (
    <TableRow
      ref={ref}
      className={cx(className, {
        [styles.blockRow]: blockLayout
      })}
      component={component}
      {...other}
    />
  );
});

export const DataGridHeaderCell = forwardRef(function DataGridHeaderCell(
  { className, blockLayout, ...other }: TableCellProps,
  ref: Ref<HTMLTableCellElement>
) {
  const component = blockLayout ? 'div' : 'th';
  return (
    <TableCell
      ref={ref}
      className={cx(
        className,
        {
          [styles.blockCell]: blockLayout,
          [styles.cellAlignLeft]: other.align === 'left',
          [styles.cellAlignRight]: other.align === 'right',
          [styles.cellAlignCenter]: other.align === 'center'
        },
        styles.cell,
        styles.headerCell
      )}
      component={component}
      variant="head"
      size="small"
      {...other}
    />
  );
});

export const DataGridCell = forwardRef(function DataGridCell(
  { className, blockLayout, ...other }: TableCellProps,
  ref: Ref<HTMLTableCellElement>
) {
  const component = blockLayout ? 'div' : 'td';
  return (
    <TableCell
      ref={ref}
      className={cx(
        className,
        {
          [styles.blockCell]: blockLayout,
          [styles.cellAlignLeft]: other.align === 'left',
          [styles.cellAlignRight]: other.align === 'right',
          [styles.cellAlignCenter]: other.align === 'center'
        },
        styles.cell
      )}
      component={component}
      variant="body"
      size="small"
      {...other}
    />
  );
});

export const DataGridBody = forwardRef(function DataGridBody(
  { className, blockLayout, ...other }: TableBodyProps,
  ref: Ref<HTMLTableSectionElement>
) {
  const component = blockLayout ? 'div' : 'tbody';
  return (
    <TableBody
      ref={ref}
      className={cx(className, {
        [styles.blockBody]: blockLayout
      })}
      component={component}
      {...other}
    />
  );
});

export const DataGridHeader = forwardRef(function DataGridHeader(
  { className, blockLayout, ...other }: TableHeaderProps,
  ref: Ref<HTMLTableSectionElement>
) {
  const component = blockLayout ? 'div' : 'thead';
  return (
    <TableHead
      ref={ref}
      className={cx(className, {
        [styles.blockHead]: blockLayout
      })}
      component={component}
      {...other}
    />
  );
});

export const DataGridTable = forwardRef(function DataGridTable(
  { className, blockLayout, fullWidth, ...other }: TableProps,
  ref: Ref<HTMLTableElement>
) {
  const component = blockLayout ? 'div' : 'table';
  return (
    <Table
      ref={ref}
      className={cx(className, {
        [styles.fullWidth]: fullWidth,
        [styles.blockTable]: blockLayout,
        [styles.regularTable]: !blockLayout
      })}
      component={component}
      {...other}
    />
  );
});

export const configuration: Partial<DataGridProps> = {
  RowComponent: DataGridRow,
  CellComponent: DataGridCell,
  HeaderCellComponent: DataGridHeaderCell,
  HeaderComponent: DataGridHeader,
  BodyComponent: DataGridBody,
  TableComponent: DataGridTable
};
