import React, { useCallback } from 'react';
import { useController, useForm } from 'react-hook-form';
import { PopoverBody } from 'reactstrap';
import { Button } from '../../Button/Button';
import { Select } from '../../Input/Select';
import { Toolbar } from '../../Toolbar';
import { DataGridColumnOptions } from '../types';
import { DataGridFilterProps } from '../types/DataGridFilterProps';
import { DATA_GRID_FILTER_BLANK_OPTIONS } from './DataGridFilterBlankOptions';

enum BooleanFilterMode {
    Blank,
    NotBlank,
    True,
    False
}

const modeNames: Partial<Record<BooleanFilterMode, string>> = {
    [BooleanFilterMode.NotBlank]: 'Not blank'
};

const modeOptions = Object.values(BooleanFilterMode)
    .filter((value) => typeof value === 'string')
    .map((option) => {
        const value = BooleanFilterMode[option];
        return {
            value,
            label: modeNames[value] ?? option
        };
    });

function filterOptions(rowValue, filterValue: BooleanFilterMode) {
    switch (filterValue) {
        case BooleanFilterMode.Blank:
            return DATA_GRID_FILTER_BLANK_OPTIONS.includes(rowValue);
        case BooleanFilterMode.NotBlank:
            return !DATA_GRID_FILTER_BLANK_OPTIONS.includes(rowValue);
        case BooleanFilterMode.True:
            return rowValue;
        case BooleanFilterMode.False: {
            return !rowValue;
        }
        default:
            return true;
    }
}

const filterBoolean: DataGridColumnOptions['filter'] = (rows, id, filterValue) => {
    return rows.filter((row) => {
        const rowValue = row.values[id];
        return filterOptions(rowValue, filterValue);
    });
};

export const DataGridCheckboxFilter = <T extends object>({
    column: { filterValue, setFilter },
    onClose
}: DataGridFilterProps<T>) => {
    const { control, handleSubmit } = useForm({
        shouldUnregister: false,
        defaultValues: {
            mode: filterValue
        }
    });

    const {
        field: { value: selectedMode, onChange: selectMode }
    } = useController({
        name: 'mode',
        defaultValue: BooleanFilterMode.True,
        rules: { required: 'Please select mode' },
        control
    });

    const onSubmit = useCallback(
        (result) => {
            setFilter(result.mode);
            onClose();
        },
        [onClose, setFilter]
    );

    const handleClear = useCallback(() => {
        setFilter(undefined);
        onClose();
    }, [onClose, setFilter]);

    return (
        <>
            <form onSubmit={handleSubmit(onSubmit)}>
                <PopoverBody>
                    <Select
                        portal
                        options={modeOptions}
                        fields={{ label: 'label', value: 'value' }}
                        value={modeOptions.find((x) => x.value === selectedMode)}
                        onChange={(option) => selectMode(option.value)}
                    />
                </PopoverBody>
                <Toolbar disablePadding={false}>
                    <div style={{ flex: 1 }} />
                    <Button color="primary" type="submit" size="small">
                        Filter
                    </Button>
                    <Button onClick={handleClear} size="small">
                        Clear
                    </Button>
                </Toolbar>
            </form>
        </>
    );
};

export const DataGridCheckboxFilterFull = {
    filter: filterBoolean,
    Filter: DataGridCheckboxFilter
};
