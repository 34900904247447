import { createSelector } from 'reselect';
import { RootState } from 'store';

export const selectCasesState = (state: RootState) => state.cases;
export const selectCasesPresentState = (state: RootState) => state.cases.present;

export const selectCasesChanges = (state: RootState) => selectCasesPresentState(state).changes;

export const selectDeactivatedTimestepsDefault = (state: RootState) =>
  selectCasesPresentState(state).deactivatedTimestepsDefault;
export const selectDeactivatedTimestepsChanged = (state: RootState) =>
  selectCasesPresentState(state).deactivatedTimesteps;

export const selectDeactivatedTimesteps = createSelector(
  [selectDeactivatedTimestepsDefault, selectDeactivatedTimestepsChanged],
  (defaultDeactivated, changed) => new Map([...(defaultDeactivated ?? new Map()), ...changed])
);

export const selectHasCaseInputChanges = createSelector(selectCasesChanges, (changes) => {
  const hasMeterStationChanges = Array.from(changes.MeterStation.values()).some((rowChanges) => rowChanges.size > 0);
  const hasHeaderChanges = Array.from(changes.Header.values()).some((rowChanges) => rowChanges.size > 0);
  const hasWellChanges = Array.from(changes.Well.values()).some((rowChanges) => rowChanges.size > 0);
  const hasValveChanges = Array.from(changes.Valve.values()).some((rowChanges) => rowChanges.size > 0);

  const hasChanges = hasMeterStationChanges || hasHeaderChanges || hasValveChanges || hasWellChanges;

  return hasChanges;
});

export const selectCasesStateHasChanges = createSelector(
  [selectHasCaseInputChanges, selectDeactivatedTimestepsChanged],
  (hasChanges, deactivatedTimesteps) => {
    return hasChanges || deactivatedTimesteps.size;
  }
);
