import React, { FC, memo, useContext } from 'react';
import { Table, TableProps } from '../Table/Table';
import { DataGridBody } from './DataGridBody';
import { DataGridContext } from './DataGridContext';
import { DataGridHeader } from './DataGridHeader';

const defaultChildren = (
    <>
        <DataGridHeader />
        <DataGridBody />
    </>
);

/**
 * The props for the {@link DataGridTable} component.
 * @category Props
 */
export interface DataGridContentProps extends TableProps {}

/**
 * @category Component
 * @group Data Grid
 */
export const DataGridContent: FC<DataGridContentProps> = memo(function DataGridContent({
    blockLayout: blockLayoutProp,
    fullWidth: fullWidthProp,
    children = defaultChildren,
    ...other
}) {
    const instance = useContext(DataGridContext);
    const { settings, getTableProps } = instance;

    const { fullWidth, blockLayout, TableComponent = Table } = settings;

    return (
        <TableComponent
            fullWidth={fullWidthProp || fullWidth}
            blockLayout={blockLayoutProp || blockLayout}
            {...other}
            {...getTableProps()}
        >
            {children}
        </TableComponent>
    );
});
