import { Group } from '@visx/group';
import React, { FC, memo, useContext, useEffect, useMemo, useRef } from 'react';
import Draggable, { DraggableProps } from 'react-draggable';
import usePrevious from '../../../util/usePrevious';
import { ChartContext, ChartMiniMapContext } from '../../ChartContext';
import { RenderChart, RenderGrid } from '../../instance';
import { DEFAULT_SCALE, useGroupDimensions, useScalesFromChild } from '../../utils';

const ZoomMiniMap: FC = () => {
    const zoomRef = useRef<Draggable>();
    const instance = useContext(ChartContext);
    const { open, options } = useContext(ChartMiniMapContext);

    const prevPosition = usePrevious(options?.defaultPosition);

    useEffect(() => {
        if (!zoomRef.current || !options?.defaultPosition) {
            return;
        }

        const zoomState = zoomRef.current.state as DraggableProps['defaultPosition'];
        const isStateUpdatedInternally = zoomState.x !== prevPosition.x || zoomState.y !== prevPosition.y;

        if (isStateUpdatedInternally) {
            return;
        }

        zoomRef.current.setState(options.defaultPosition);
    }, [options?.defaultPosition]);

    const {
        allData,
        children,
        AxisGroups,
        xScale: xScaleProp,
        yScale: yScaleProp,
        groups,
        innerHeight: originalInnerHeight,
        innerWidth: originalInnerWidth,
        height,
        width,
        margin,
        zoom
    } = instance;

    const groupDimensions = useGroupDimensions(
        AxisGroups,
        groups,
        originalInnerHeight,
        originalInnerWidth,
        DEFAULT_SCALE,
        DEFAULT_SCALE
    );

    const { xScales, yScales } = useScalesFromChild(
        allData,
        AxisGroups,
        groupDimensions,
        children,
        xScaleProp,
        yScaleProp
    );

    const newInstance = useMemo(
        () => ({ ...instance, groupDimensions, xScales, yScales }),
        [groupDimensions, instance, xScales, yScales]
    );

    if (!zoom.transformMatrix || !open || !options) return null;

    return (
        <>
            <Draggable ref={zoomRef} defaultPosition={options?.defaultPosition}>
                <Group>
                    <Group transform="scale(0.25)" className="chart-mini-map">
                        <rect width={width} height={height} fill="#FFCCCC" />
                        <ChartContext.Provider value={newInstance}>
                            <Group left={margin.left} top={margin.top}>
                                <RenderGrid gridProps={{ stroke: 'gray' }} />
                                <RenderChart layer="back" />
                                <RenderChart layer="front" />
                            </Group>
                        </ChartContext.Provider>
                        <rect
                            width={width}
                            height={height}
                            fill="white"
                            fillOpacity={0.2}
                            stroke="black"
                            strokeWidth={4}
                            transform={zoom.ref.current?.toStringInvert()}
                        />
                    </Group>
                </Group>
            </Draggable>
        </>
    );
};

export const ChartZoomMiniMap = memo(ZoomMiniMap);
