import { useMemo } from 'react';
import { setRef } from './setRef';

/**
 * @category Util
 */
export function useForkRef(refA, refB) {
    return useMemo(() => {
        if (refA == null && refB == null) {
            return null;
        }
        return (refValue) => {
            setRef(refA, refValue);
            setRef(refB, refValue);
        };
    }, [refA, refB]);
}
