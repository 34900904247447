import { Group } from '@visx/group';
import React, { FC, Fragment, memo, useContext } from 'react';

import { ChartContext } from '../ChartContext';
import { AnnotationLayer } from '../chartTypes';

interface RenderAnnotationsProps {
    layer: AnnotationLayer;
}

/**
 * @category Component
 * @group Chart
 */
let RenderAnnotations: FC<RenderAnnotationsProps> = ({ layer }) => {
    const instance = useContext(ChartContext);

    const { groups, AnnotationsGroups, groupDimensions } = instance;

    return (
        <>
            <Group className={`annotations ${layer}`}>
                {groups.map((group) => {
                    const annotations = AnnotationsGroups?.[group]?.filter(
                        (annotation) => (annotation.props.layer ?? 'front') === layer
                    );

                    const {
                        position: { top, left },
                        offset,
                        size: { height, width },
                    } = groupDimensions[group];

                    return (
                        <Fragment key={group}>
                            <defs key={group + 'defs'}>
                                <clipPath id={`${group}-annotations-clip-path`}>
                                    <rect x={0} y={0} width={width} height={height} />
                                </clipPath>
                            </defs>
                            <Group
                                key={group + 'annotations'}
                                top={top + offset.top}
                                left={left + offset.left}
                                height={height}
                                width={width}
                                clipPath={`url(#${group}-annotations-clip-path)`}
                            >
                                {annotations?.map((Child) => Child)}
                            </Group>
                        </Fragment>
                    );
                })}
            </Group>
        </>
    );
};

RenderAnnotations = memo(RenderAnnotations);

export { RenderAnnotations };
