import { ILineupSchemeNodes } from 'api';
import { NodeHandle } from 'domain/scheme';
import { HandleType, Position } from 'reactflow';
import { getNodeHandleId } from './convertToDiagramEdges';
const positionMapper: Record<
  Position,
  keyof Pick<ILineupSchemeNodes, 'leftHandlesCount' | 'rightHandlesCount' | 'topHandlesCount' | 'bottomHandlesCount'>
> = {
  [Position.Left]: 'leftHandlesCount',
  [Position.Top]: 'topHandlesCount',
  [Position.Right]: 'rightHandlesCount',
  [Position.Bottom]: 'bottomHandlesCount'
};
const types: HandleType[] = ['source', 'target'];
function getNodeHandlesByPosition(schemeNode: ILineupSchemeNodes, position: Position) {
  const handlesCount = schemeNode[positionMapper[position]];
  const nodeHandles = [];
  for (const type of types) {
    for (let i = 0; i < handlesCount; i++) {
      const id = getNodeHandleId(position, i);
      nodeHandles.push({ id, position, type });
    }
  }
  return nodeHandles;
}
export function getNodeHandles(schemeNode: ILineupSchemeNodes) {
  const nodeHandles = [];
  for (const position of Object.values(Position)) {
    nodeHandles.push(...getNodeHandlesByPosition(schemeNode, position));
  }
  return nodeHandles;
}
/**
 * Returns the dictionary.
 * Example:
 * {
 *    nodeId - count of handles
 * }
 */
export function createHandlesBasedOnNodes(nodes: ILineupSchemeNodes[]): Record<string, NodeHandle[]> {
  const handles = {};
  for (const schemeNode of nodes) {
    const key = schemeNode.id.toString();
    if (!handles[key]) {
      handles[key] = [];
    }

    handles[key].push(...getNodeHandles(schemeNode));
  }

  return handles;
}
