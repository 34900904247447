import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { unstable_batchedUpdates } from 'react-dom';
import { isFragment } from 'react-is';
import { ChartInstance } from '../chartTypes';
import { componentName, isSeries, isStackedGroup } from './chartUtils';

export function getLegendSeries(series) {
    const result = [];
    function processChildren(Child) {
        if (isFragment(Child)) {
            React.Children.forEach(Child.props.children, processChildren);
        }

        const name = componentName(Child);

        if (isStackedGroup(name)) {
            React.Children.forEach(Child.props.children, processChildren);
        }

        if (isSeries(name)) {
            result.push(Child);
        }
    }

    React.Children.forEach(series, processChildren);

    return result;
}

export function useLegend(series): ChartInstance['legend'] {
    const [state, setState] = useState({});

    const list = useMemo(() => getLegendSeries(series), [series]);

    const handleToggle = useCallback(
        (seriesId) => {
            const currentStatus = state[seriesId];
            if (!currentStatus) {
                setState({
                    ...state,
                    [seriesId]: true,
                });
            } else {
                setState((state) => {
                    const newState = { ...state };
                    delete newState[seriesId];
                    return newState;
                });
            }
        },
        [state]
    );

    useEffect(() => {
        unstable_batchedUpdates(() => {
            const hiddenSeries = Object.keys(state);
            const currentSeries = list.map((x, index) => x?.props?.id?.toString() ?? index.toString());

            hiddenSeries.filter((x) => !currentSeries.includes(x)).forEach((x) => handleToggle(x));
        });
    }, [handleToggle, list, state]);

    return useMemo(
        () => ({
            state,
            toggleSeries: handleToggle,
        }),
        [handleToggle, state]
    );
}
