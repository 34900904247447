import {
    faCaretDown,
    faCaretRight,
    faSquareCaretDown,
    faSquareCaretRight,
    faStream,
    faTimesCircle
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { FC, useMemo } from 'react';
import { HeaderProps } from 'react-table';
import { IconButton } from '../../Button/IconButton';
import headerStyles from '../../Table/TableHeader.module.css';
import { Toolbar } from '../../Toolbar';
import { Typography } from '../../Typography';
import {
    DataGridCellProps,
    DataGridColumnInstance,
    DataGridInstance,
    DataGridPluginHook,
    DataGridSettings,
    DataGridState
} from '../types';
import { serviceColumns } from '../utils/serviceColumns';
import styles from './Columns.module.css';
import { useControlledState } from './useControlledState';

const defaultExpandCell: FC<DataGridCellProps> = ({ row }) => {
    const color = useMemo(() => (row.depth === 0 ? '#2D2D2D' : '#b3b3b3'), [row.depth]);

    return (
        <IconButton size="small" {...row.getToggleRowExpandedProps()} style={{ color }}>
            {row.isExpanded ? <FontAwesomeIcon icon={faCaretDown} /> : <FontAwesomeIcon icon={faCaretRight} />}
        </IconButton>
    );
};
interface ExpandCell extends DataGridCellProps {
    settings: DataGridSettings;
}
const ExpandCell = ({ row, settings, ...others }: ExpandCell) => {
    if (!row.canExpand) {
        return null;
    }

    const { ExpandCellComponent = defaultExpandCell } = settings;

    return <ExpandCellComponent row={row} {...others} />;
};

const ExpandAllHeader = <T extends object>({
    isAllRowsExpanded,
    toggleAllRowsExpanded
}: HeaderProps<T> & DataGridInstance<T>) => {
    return (
        <IconButton size="small" onClick={() => toggleAllRowsExpanded()} style={{ color: '#2D2D2D' }}>
            {isAllRowsExpanded ? (
                <FontAwesomeIcon icon={faSquareCaretDown} />
            ) : (
                <FontAwesomeIcon icon={faSquareCaretRight} />
            )}
        </IconButton>
    );
};

export const expanderColumnHook: DataGridPluginHook = (hooks) => {
    hooks.visibleColumns.push((columns) => {
        return [
            {
                id: serviceColumns.expand,
                className: styles.expanderColumn,
                Header: ExpandAllHeader,
                minWidth: 34,
                width: 34,
                maxWidth: 34,
                Cell: ExpandCell,
                CellEdit: ExpandCell,
                style: {
                    padding: 0
                },
                align: 'center',
                sticky: columns?.[0]?.sticky
            },
            ...columns
        ];
    });
};

export const groupExpanderColumnHook: DataGridPluginHook = (hooks) => {
    hooks.useControlledState.push(useControlledState);
    hooks.visibleColumns.push((columns, { instance }) => {
        if (!instance.state.groupBy?.length) {
            return columns;
        }

        const template = ({ row }) => {
            if (!row.canExpand) {
                return null;
            }
            const groupedCell = row.allCells.find((d) => d.isGrouped);

            return (
                <Typography
                    noWrap
                    component="div"
                    className={styles.expandCell}
                    {...row.getToggleRowExpandedProps({
                        style: {
                            paddingLeft: `${row.depth * 2}rem`
                        }
                    })}
                >
                    {row.isExpanded ? <FontAwesomeIcon icon={faCaretDown} /> : <FontAwesomeIcon icon={faCaretRight} />}
                    {groupedCell.render('Cell', { typographyProps: { component: 'span' } })}
                    <span>({row.subRows.length})</span>
                </Typography>
            );
        };

        return [
            {
                id: serviceColumns.expander,
                Header: ({ allColumns, state }) => {
                    const { groupBy } = state as DataGridState;

                    return (
                        <Toolbar className={headerStyles.toolbar}>
                            {groupBy.map((columnId) => {
                                const column = allColumns.find((d) => d.id === columnId) as DataGridColumnInstance;

                                return (
                                    <Toolbar
                                        key={columnId}
                                        className={headerStyles.toolbar}
                                        {...column.getHeaderProps()}
                                    >
                                        {instance.settings?.grouping?.allow && column.canGroupBy ? (
                                            <IconButton {...column.getGroupByToggleProps()} size="small">
                                                <FontAwesomeIcon
                                                    icon={column.isGrouped ? faTimesCircle : faStream}
                                                    size="xs"
                                                />
                                            </IconButton>
                                        ) : null}
                                        {column.render('Header')}
                                    </Toolbar>
                                );
                            })}
                        </Toolbar>
                    );
                },
                Cell: template,
                CellEdit: template,
                sticky: columns?.[0]?.sticky
            },
            ...columns
        ];
    });
};
