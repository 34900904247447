import { Button, Grid, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import '../../styles/Error404.css';

function Error404() {
  return (
    <div
      className="page"
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        minHeight: '100vh',
        maxWidth: '800px',
        margin: '0 auto'
      }}
    >
      <div
        className="box1"
        style={{
          display: 'flex',
          flexDirection: 'column',
          marginTop: '12%'
        }}
      >
        <div style={{ textAlign: 'center' }} className="bolded-3d-text">
          404
        </div>
        <Grid container direction={'column'} alignItems={'center'} justifyContent={'center'}>
          <Grid item pt={2}>
            <Typography align="center" sx={{ fontSize: '23px' }}>
              The page you were looking for was moved or doesn&apos;t exist
            </Typography>
          </Grid>
          <Grid item pt={2}>
            <Button variant="contained" component={Link} to="/">
              To the start page
            </Button>
          </Grid>
        </Grid>
      </div>
      <div
        className="box2"
        style={{
          marginTop: 'auto',
          paddingBottom: '38px',
          textAlign: 'center',
          color: '#ababab'
        }}
      >
        Error code: 404
      </div>
    </div>
  );
}

export default Error404;
