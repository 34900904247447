import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cx from 'classnames';
import { Calendar as DayzedCalendar } from 'dayzed';
import React, { forwardRef, useMemo } from 'react';
import { Button } from '../Button/Button';
import { ButtonGroup } from '../Button/ButtonGroup';
import { Paper } from '../Paper';
import { PaperProps } from '../Paper/Paper';
import { Toolbar } from '../Toolbar';
import styles from './Calendar.module.css';
import { CalendarContext, CalendarDepthValue, CalendarOptions } from './CalendarContext';
import { monthNamesFull } from './calendarUtils';
import { Decade, getDecadeStart } from './Decade';
import { Month } from './Month';
import { TodayButton } from './TodayButton';
import { CalendarSettings, useCalendar } from './useCalendar';
import { CLIENT_UTC_OFFSET } from './utils';
import { Year } from './Year';

/**
 * The props for the {@link Calendar} component.
 * @category Props
 */
export interface CalendarProps
    extends Omit<CalendarOptions, 'onDateSelected'>,
        Omit<PaperProps, 'selected' | 'ref' | 'onChange'> {
    enableToday?: boolean;
    enableClear?: boolean;
    disableToolbar?: boolean;
    calendar?: DayzedCalendar;
}

/**
 * @category Component
 * @group Date Picker
 */
export const Calendar = forwardRef<HTMLDivElement, CalendarProps>(
    (
        {
            disabled,
            className,
            date: dateProp,
            calendar: calendarProp,
            disableToolbar,
            enableToday,
            enableClear,
            defaultDepth,
            minDepth = 'month',
            selectRange,
            date,
            maxDate,
            minDate,
            monthsToDisplay,
            firstDayOfWeek,
            showOutsideDays,
            value,
            offset,
            onOffsetChanged,
            onChange,
            DayWrapper,
            MonthWrapper,
            YearWrapper,
            DayComponent,
            MonthComponent,
            YearComponent,
            utcOffset = CLIENT_UTC_OFFSET,
            ...other
        },
        ref
    ) => {
        const options = useMemo(() => {
            const result: CalendarSettings = {
                monthsToDisplay: 1,
                date: calendarProp?.firstDayOfMonth
            };
            if (dateProp) {
                result.date = dateProp;
                result.offset = 0;
            }
            return result;
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [calendarProp?.firstDayOfMonth?.getTime(), dateProp?.getTime?.()]);

        const instance = useCalendar({
            defaultDepth,
            minDepth,
            disabled,
            selectRange,
            date,
            maxDate,
            minDate,
            monthsToDisplay,
            firstDayOfWeek,
            showOutsideDays,
            value,
            offset,
            onOffsetChanged,
            DayWrapper,
            MonthWrapper,
            YearWrapper,
            DayComponent,
            MonthComponent,
            YearComponent,
            utcOffset,
            onChange,
            ...options
        });

        const { depth, setDepth, calendars, getBackProps, getForwardProps, handleResetHover, handleClear } = instance;
        const calendar = calendars[0];

        const stepOffset = useMemo(
            () => (depth === 'month' ? 1 : depth == 'year' ? 12 : depth == 'decade' ? 120 : 1),
            [depth]
        );

        return (
            <CalendarContext.Provider value={instance}>
                <Paper ref={ref} className={cx(styles.root, className)} {...other} onMouseLeave={handleResetHover}>
                    {disableToolbar ? undefined : (
                        <ButtonGroup className={styles.toolbar} size="small">
                            <Button {...getBackProps({ calendars, offset: stepOffset })}>
                                <FontAwesomeIcon size="sm" icon={faChevronLeft} />
                            </Button>
                            {CalendarDepthValue[depth] <= CalendarDepthValue.month && (
                                <Button className={styles.name} onClick={() => setDepth('year')}>
                                    {monthNamesFull[calendar.month]}
                                </Button>
                            )}
                            {CalendarDepthValue[depth] <= CalendarDepthValue.year && (
                                <Button className={styles.name} onClick={() => setDepth('decade')}>
                                    {calendar.year}
                                </Button>
                            )}
                            {CalendarDepthValue[depth] == CalendarDepthValue.decade && (
                                <Button className={styles.name}>
                                    {getDecadeStart(calendar.year)} - {getDecadeStart(calendar.year) + 9}
                                </Button>
                            )}
                            <Button {...getForwardProps({ calendars, offset: stepOffset })}>
                                <FontAwesomeIcon size="sm" icon={faChevronRight} />
                            </Button>
                        </ButtonGroup>
                    )}

                    {depth === 'month' && <Month disabled={disabled} />}
                    {depth === 'year' && <Year disabled={disabled} />}
                    {depth === 'decade' && <Decade disabled={disabled} />}

                    {(enableClear || enableToday) && (
                        <Toolbar>
                            {enableClear && (
                                <Button disabled={disabled} size="small" onClick={handleClear}>
                                    Clear
                                </Button>
                            )}
                            {enableToday && <TodayButton />}
                        </Toolbar>
                    )}
                </Paper>
            </CalendarContext.Provider>
        );
    }
);
