import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { handleError, handleLoading } from 'store/base/baseStateHandlers';
import { getDashboardDraData } from './actions/getDashboardDraData';
import { updateDashboardDraData } from './actions/saveDashboardDraData';
import { DashboardDraState } from './types/DashboardDraState';
import { DraInputCellPayload, DraInputRowPayload } from './types/DraInputCellPayload';
import { convertDraData } from './utils/convertDraData';

const initialState: DashboardDraState = {
  data: {}
};

const { reducer, actions } = createSlice({
  initialState,
  name: 'dashboard-dra',
  reducers: {
    updateRow(state, action: PayloadAction<DraInputRowPayload>) {
      const { accessor, row, payload, caseId } = action.payload;
      const draRow = state.data[caseId][accessor].rows.find((draRow) => draRow.id === row.id);
      const weekDateIds = new Set<number>();

      for (const { value, weekIds } of payload) {
        for (const i of weekIds) {
          weekDateIds.add(i);
          draRow.weekDates.set(i, value);
        }
      }

      // resets those timestep are deleted in Datepicker
      draRow.weekDates.forEach((_, key) => {
        if (!weekDateIds.has(key)) {
          draRow.weekDates.set(key, 0);
        }
      });
    },
    updateCell(state, action: PayloadAction<DraInputCellPayload>) {
      const { accessor, row, weekDateId, value, caseId } = action.payload;
      const draRow = state.data[caseId][accessor].rows.find((draRow) => draRow.id === row.id);

      draRow.weekDates.set(weekDateId, value);
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getDashboardDraData.pending, (state) => {
        handleLoading(state);
      })
      .addCase(getDashboardDraData.fulfilled, (state, action) => {
        const caseId = action.meta.arg;
        const content = convertDraData(action.payload);
        state.data[caseId] = content;

        state.loading = false;
      })
      .addCase(getDashboardDraData.rejected, (state, action) => {
        handleError(state, action);
      });

    builder.addCase(updateDashboardDraData.fulfilled, () => {
      return initialState;
    });
  }
});

export const draActions = actions;
export default reducer;
