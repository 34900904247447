import { isEqual, isPlainObject } from 'lodash';
import { createSelectorCreator, defaultMemoize } from 'reselect';

import { IGetWeekDatesDto } from 'api';
import { OfficialInputChanges } from 'store/official-inputs/OfficialInputState';
import { getWellStatus } from '../actions/wellInputStatusChanges';
import { WellInputTableRow } from './convertWellInputData';

export const getWellInputSnapshot = (
  rows: WellInputTableRow[],
  dates: IGetWeekDatesDto[],
  changes: OfficialInputChanges,
  searchQuery = '',
  hideOffline = false
): WellInputTableRow[] => {
  const search = searchQuery?.toLocaleLowerCase();

  function isWellOnline(connectionRow: WellInputTableRow) {
    return dates.some((weekDate) => getWellStatus(changes, connectionRow, weekDate.id).status === true);
  }

  return rows
    ?.map((msRow) => {
      const isMsName = msRow.name?.toLocaleLowerCase().includes(search);

      if (isMsName && !hideOffline) {
        return msRow;
      }

      const wellRows = msRow.subRows.filter(
        (wellRow) => wellRow.name?.toLocaleLowerCase().includes(search) && (hideOffline ? isWellOnline(wellRow) : true)
      );

      if (wellRows.length > 0) {
        return {
          ...msRow,
          subRows: wellRows
        };
      }

      return undefined;
    })
    ?.filter(Boolean);
};

// to prevent recalculation of row array when `changes` map has been changed
const equalityFunction = (a: unknown, b: unknown) => isPlainObject(a) || isPlainObject(b) || isEqual(a, b);
export const createWellInputSelector = createSelectorCreator(defaultMemoize, equalityFunction);
