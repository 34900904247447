import { createSelector } from 'reselect';
import { RootState } from 'store';

export const selectOfficialInputSharedState = (state: RootState) => state.officialInputPage;

export const selectOfficialInputHiddenWeekDateIds = (state: RootState) =>
  selectOfficialInputSharedState(state).hiddenWeekDateIds;

export const filterWeekDays = (selectDates, selectSplitableWeekDateIds) =>
  createSelector(
    [selectDates, selectSplitableWeekDateIds, selectOfficialInputHiddenWeekDateIds],
    (dates, splitableWeekDateIds, hiddenWeekDateIds) => {
      return dates?.filter((x) => splitableWeekDateIds.has(x.id) || !hiddenWeekDateIds.has(x.id));
    }
  );
