import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'store';
import { selectOfficialInputHiddenWeekDateIds } from 'store/official-input-page/OfficialInputPageSelectors';
import { selectOfficialInputPresentState } from '../OfficialSelectors';
import { getLineupSnapshot } from './utils/getLineupSnapshot';

export const lineupStateSelector = (state: RootState) => selectOfficialInputPresentState(state).lineup;

export const selectLineupRowsLookup = (state: RootState) => lineupStateSelector(state).rowsById;
const selectLineupDates = (state: RootState) => lineupStateSelector(state).dates;
const selectLineupSplitableWeekDates = (state: RootState) => lineupStateSelector(state).splitableWeekDateIds;

export const filterLineupWeekDays = createSelector(
  [selectLineupDates, selectLineupSplitableWeekDates, selectOfficialInputHiddenWeekDateIds],
  (dates, splitableWeekDateIds, hiddenWeekDateIds) => {
    return dates?.filter((x) => splitableWeekDateIds.has(x.id) || !hiddenWeekDateIds.has(x.id));
  }
);

export const lineupLoadingSelector = (state: RootState) => lineupStateSelector(state).loading;

const lineupRowsSelector = (state: RootState) => lineupStateSelector(state).rows;

export const filterLineupRowsSelector = createSelector(
  [lineupRowsSelector, (_state, searchQuery?: string) => searchQuery],
  (rows, searchQuery) => getLineupSnapshot(rows, searchQuery)
);
