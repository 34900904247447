import { createAction } from '@reduxjs/toolkit';

export const officialInputUndo = createAction<undefined>('official-inputs/undo');
export const officialInputClearHistory = createAction<undefined>('official-inputs/clear-history');

export const OfficialInputJumpToPastType = 'official-inputs/jump-to-past';
export function officialInputJumpToPast(index) {
  return {
    type: OfficialInputJumpToPastType,
    index: index
  };
}
