import { WellGorClient } from 'api';
import { ForceableQuery } from 'domain/QueryParams';
import { createAppAsyncThunk } from 'store/createAppAsyncThunk';
import { weekDatesApi } from 'store/weekDates/weekDatesApi';
import { GetWellGorPayload } from '../types/GetWellGorPayload';
import { wellGorPresentStateSelector } from '../wellGorSelectors';

const service = new WellGorClient();

export const getWellGor = createAppAsyncThunk(
  'wellGor/get',
  async (_: ForceableQuery, { dispatch }): Promise<GetWellGorPayload> => {
    const weekDates = dispatch(weekDatesApi.endpoints.getWeekDates.initiate(undefined)).unwrap();
    const items = service.get();

    await Promise.allSettled([weekDates, items]);

    return { adjustWeekDates: await weekDates, items: await items };
  },
  {
    condition: ({ force }, { getState }) => {
      const wellGor = wellGorPresentStateSelector(getState());

      if (!wellGor.loading && (force || wellGor.data === undefined)) {
        return true;
      }

      return false;
    }
  }
);
