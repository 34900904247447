import { DashboardDraResponseDto, IDashboardDraResponseDto } from 'api';
import { OUTLOOK_PLANT } from 'pages/dashboard/outlook/constants';
import { CaseDraContent } from '../types/DashboardDraState';
import { DraRow, DRA_TABLE_ROW } from '../types/DraRow';

const KTL_ROWS = [DRA_TABLE_ROW.DRA, DRA_TABLE_ROW.LDHI, DRA_TABLE_ROW.TL14];
const SGP_ROWS = [DRA_TABLE_ROW.DRA, DRA_TABLE_ROW.LDHI];

export function convertDraData(dtos: DashboardDraResponseDto[]): CaseDraContent {
  const ktl = new Map<string, DraRow>(KTL_ROWS.map((name) => [name, { id: name, weekDates: new Map() }]));
  const sgp = new Map<string, DraRow>(SGP_ROWS.map((name) => [name, { id: name, weekDates: new Map() }]));

  const ktlDto = new Map<number, IDashboardDraResponseDto>();
  const sgpDto = new Map<number, IDashboardDraResponseDto>();

  for (const dto of dtos ?? []) {
    const { isKTL, dra, ldhi, tL14, weekDateId } = dto;

    if (isKTL) {
      ktl.get(DRA_TABLE_ROW.DRA).weekDates.set(weekDateId, dra);
      ktl.get(DRA_TABLE_ROW.LDHI).weekDates.set(weekDateId, ldhi);
      ktl.get(DRA_TABLE_ROW.TL14).weekDates.set(weekDateId, tL14);
      ktlDto.set(weekDateId, dto);
    } else {
      sgp.get(DRA_TABLE_ROW.DRA).weekDates.set(weekDateId, dra);
      sgp.get(DRA_TABLE_ROW.LDHI).weekDates.set(weekDateId, ldhi);
      sgpDto.set(weekDateId, dto);
    }
  }

  return {
    [OUTLOOK_PLANT.KTL]: {
      rows: Array.from(ktl.values()),
      dtoByWeekDateId: ktlDto
    },
    [OUTLOOK_PLANT.SGP]: {
      rows: Array.from(sgp.values()),
      dtoByWeekDateId: sgpDto
    }
  };
}
