import { Draft } from '@reduxjs/toolkit';
import {
  AsyncThunkFulfilledActionCreator,
  AsyncThunkRejectedActionCreator
} from '@reduxjs/toolkit/dist/createAsyncThunk';
import { ApiException, ProblemDetails } from 'api';
import { BaseState } from './BaseState';

export const getApiExceptionMessage = (error) => {
  if (error instanceof ApiException) {
    if (error.result instanceof ProblemDetails) {
      return error.result.detail;
    }
    return error.message;
  }
  return undefined;
};

export const handleError = <T extends object>(
  state: Draft<BaseState<T>>,
  action: ReturnType<AsyncThunkRejectedActionCreator<unknown>>,
  getPayloadResult?: boolean
) => {
  const { error } = action;
  state.loading = false;
  state.data = undefined;
  if (getPayloadResult && action.payload instanceof ApiException) {
    state.error = getApiExceptionMessage(action.payload);
  } else {
    state.error = error.message;
  }
  // eslint-disable-next-line no-console
  console.error(action);
};

export const handleLoading = <T extends object>(state: Draft<BaseState<T>>) => {
  state.loading = true;
};

export const handleSuccess = <T extends object>(
  state: Draft<BaseState<T>>,
  action: ReturnType<AsyncThunkFulfilledActionCreator<T, unknown>>
) => {
  state.loading = false;
  state.data = action.payload as never;
};
