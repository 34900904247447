import { Draft } from '@reduxjs/toolkit';
import { IMeterStationInputStatus, IWellChildDto } from 'api';
import { MSChangesValueType, OfficialInputState } from 'store/official-inputs/OfficialInputState';

function updateStatus(
  dto: IWellChildDto | IMeterStationInputStatus,
  change: MSChangesValueType | IWellChildDto | undefined
) {
  if (change) {
    dto.status = change.status;
  }
}

export function sealWellInputChanges(state: Draft<OfficialInputState>) {
  for (const msRow of state.wellInput.rows) {
    for (const [weekDay, status] of msRow.msStatuses) {
      updateStatus(status, state.changes.MeterStation.get(msRow.id)?.get(weekDay));
    }

    for (const wellRow of msRow.subRows) {
      for (const [weekDay, status] of wellRow.wellStatuses) {
        updateStatus(status, state.changes.Well.get(wellRow.id)?.get(weekDay));
      }
    }
  }
}
