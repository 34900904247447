import { PropsWithChildren, useEffect } from 'react';

import { useTheme } from '@mui/material';
import { TearecsComponentsConfiguration } from '@tearecs/components/src/configuration/ConfigurationContext';
import { tearecsConfiguration } from './tearecsConfiguration';

export function TearecsComponentsConfigurationProvider({ children }: PropsWithChildren<unknown>) {
  const theme = useTheme();

  useEffect(() => {
    let styleElement = document.head.querySelector('#tearecs');

    if (!styleElement) {
      styleElement = document.createElement('style');
      styleElement.setAttribute('type', 'text/css');
      styleElement.setAttribute('id', 'tearecs');
      document.head.appendChild(styleElement);
    }

    styleElement.innerHTML = `
      :root {
        --primary-color: ${theme.palette.primary.main}!important;
        --secondary-color: ${theme.palette.secondary.main}!important;
      }
    `;
  }, [theme.palette.primary.main, theme.palette.secondary.main]);

  return (
    <TearecsComponentsConfiguration.Provider value={tearecsConfiguration}>
      {children}
    </TearecsComponentsConfiguration.Provider>
  );
}
