import React, { FC, PropsWithChildren, useContext } from 'react';
import { Toolbar } from '../Toolbar';
import { RenderDataGridToolbar } from './components/RenderToolbar';
import { DataGridContext } from './DataGridContext';

/**
 * The props for the {@link DataGridToolbar} component.
 * @category Props
 */
interface DataGridTooolbarProps {}

/**
 * @category Component
 * @group Data Grid
 */
export const DataGridToolbar: FC<PropsWithChildren<DataGridTooolbarProps>> = ({ children }) => {
    const instance = useContext(DataGridContext);
    const { settings } = instance;
    const { chooseColumns, onAdd, onEdit, onDelete } = settings;

    if (children == undefined) {
        if (!chooseColumns && !onAdd && !(typeof onEdit == 'function' ? !!onEdit : false) && !onDelete) {
            return null;
        }

        return (
            <Toolbar disablePadding={false}>
                <RenderDataGridToolbar />
            </Toolbar>
        );
    }

    return <Toolbar disablePadding={false}>{children}</Toolbar>;
};
