import { Stack } from '@mui/material';
import { DataGrid, DataGridColumnOptions } from '@tearecs/components/src/DataGrid';
import { useMemo } from 'react';

export function PressureTable() {
  //specify type once Dto is developed
  const columns = useMemo((): DataGridColumnOptions<any>[] => {
    return [
      { accessor: 'monthOne', Header: '6 Months Forecast', minWidth: 90 },
      { accessor: 'monthTwo', Header: 'Last 6 Months', minWidth: 90 },
      { accessor: 'Pressure', Header: 'Pressure', minWidth: 90 },
      { accessor: 'GOR', Header: 'GOR', minWidth: 90 }
    ];
  }, []);
  const formattedData = [].map((item, index) => ({
    id: index,
    monthOne: item.monthOne,
    monthTwo: item.monthTwo,
    Pressure: item.Pressure,
    GOR: item.GOR
  }));
  return (
    <Stack>
      <DataGrid columns={columns} data={formattedData} />
      {/* <SavePublishFooter /> */}
    </Stack>
  );
}
