import { useParams, useSearchParams } from 'react-router-dom';
import { piHistorianApi } from 'store/piHistorian/piHistorianApi';
import { DEFAULT_MONTH_FILTER } from '../../utils/constants';

export function useWellPiHistorianData() {
  const [searchParams] = useSearchParams();
  const months = parseInt(searchParams.get('months') ?? DEFAULT_MONTH_FILTER);
  const { wellId } = useParams();
  const parsedWellId = parseInt(wellId);
  const { data, isLoading } = piHistorianApi.useGetWellPiHistorianQuery(
    { id: parsedWellId, months },
    {
      skip: isNaN(parsedWellId)
    }
  );

  return { data, isLoading };
}
