import { GetScenarioCasesByIdDto, ScenarioCaseStatus } from 'api';
import { scenariosApi } from 'store/scenarios/scenariosApi';

type UseCaseInfoHookReturnType = {
  currentCase: GetScenarioCasesByIdDto;
  title: string;
  isEditable: boolean;
  isLoading: boolean;
};

export const useCaseInfo = (
  caseId: number,
  editableStatuses: ScenarioCaseStatus[]
): UseCaseInfoHookReturnType => {
  const { data: caseInfo, isLoading } = scenariosApi.useGetCaseInfoQuery(caseId, {
    pollingInterval: 10000,
    refetchOnMountOrArgChange: true
  });

  return {
    currentCase: caseInfo,
    title: `${caseInfo?.scenarioName} / ${caseInfo?.caseName}`,
    isEditable: editableStatuses.includes(caseInfo?.scenarioCaseStatus),
    isLoading
  };
};
