import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { alpha, createTheme } from '@mui/material/styles';
import type { } from '@mui/x-data-grid/themeAugmentation';
import { removeAlpha } from 'utils/color';

declare module '@mui/material/styles' {
  interface Palette {
    extended: Record<string, string>;
  }
  interface PaletteOptions {
    extended: Record<string, string>;
  }
}

const styleOverrides = {
  root: {
    borderRadius: '4px'
  }
};

const AppTheme = createTheme({
  tableShading: {
    lightBlue: '#BCF0FF',
    blue: '#C4E6FF',
    purple: '#EEEAFF',
    green: '#E6F6E5',
    teal: '#DAF8F3',
    red: '#FCDFDF',
    orange: '#FCE9D0',
    yellow: '#FCF7DA'
  },
  chartLines: {
    lightBlue: '#00BCF2',
    blue: '#159AAF',
    purple: '#B4A0FF',
    green: '#9CD997',
    teal: '#6CE2CE',
    red: '#F17F7F',
    orange: '#F3A545',
    yellow: '#F4DE69'
  },
  palette: {
    extended: {
      gray10: '#FAFAFA',
      blue: '#0B79D0',
      textHint: 'rgba(0, 0, 0, 0.38)',
      actionDisabledBg: 'rgba(0, 0, 0, 0.12)',
    },
    primary: {
      main: '#0066B2',
      light: '#009DD9',
      dark: '#0B2D71',
      contrastText: '#FFFFFF'
    },
    secondary: {
      main: '#03A9F4',
      light: '#03A9F4',
      dark: '#0288D1',
      contrastText: '#FFFFFF'
    },
    success: {
      main: '#4CAF50',
      light: '#7BC67E',
      dark: '#3B873E',
      contrastText: '#FFFFFF'
    },
    info: {
      main: '#42454A',
      light: '#6C6E72',
      dark: '#2E3136',
      contrastText: '#FFFFFF'
    },
    warning: {
      main: '#FFC000',
      light: '#FFCC33',
      dark: '#B28600',
      contrastText: 'rgba(0,0,0,0.87)'
    },
    error: {
      main: '#F44336',
      light: '#F88078',
      dark: '#E31B0C'
    },
    background: {
      default: '#F4F4F4'
    },
    text: {
      secondary: 'rgba(0,0,0,0.54)'
    },
    divider: '#E0E0E0'
  },
  shape: {
    borderRadius: 2
  },
  components: {
    MuiButton: {
      defaultProps: {
        variant: 'contained'
      },
      styleOverrides
    },
    MuiTextField: {
      styleOverrides
    },
    MuiOutlinedInput: {
      styleOverrides
    },
    MuiFilledInput: {
      styleOverrides
    },
    MuiAlert: {
      styleOverrides: {
        root: ({ ownerState, theme }) => ({
          borderRadius: '4px',
          fontWeight: 400,
          ...(ownerState.variant === 'filled' && {
            color: '#fff',
          }),
          ...(ownerState.severity === 'info' && ownerState.variant === 'filled' && {
            color: theme.palette.primary.main,
            background: 'linear-gradient(0deg, rgba(0, 102, 178, 0.08), rgba(0, 102, 178, 0.08)), #FFFFFF',
          }),
          ...(ownerState.severity === 'error' && ownerState.variant === 'filled' && {
            color: theme.palette.error.main,
            background: 'linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), #F44336;',
          }),
        })
      }
    },
    MuiDataGrid: {
      styleOverrides: {
        root: {
          backgroundColor: 'white',
          border: 'none'
        },
        columnSeparator: {
          display: 'none'
        }
      }
    },
    MuiAccordion: {
      defaultProps: {
        elevation: 0,
        disableGutters: true,
        sx: {
          mb: 2
        }
      },
      styleOverrides: {
        root: {
          backgroundColor: 'transparent',
          '::before': {
            backgroundColor: 'transparent'
          }
        }
      }
    },
    MuiAccordionSummary: {
      defaultProps: {
        expandIcon: <ArrowForwardIosIcon fontSize="small" />
      },
      styleOverrides: {
        root: {
          flexDirection: 'row-reverse',
          backgroundColor: 'white',
          borderRadius: '4px'
        },
        expandIconWrapper: {
          '&.Mui-expanded': {
            transform: 'rotate(90deg)'
          },
          marginRight: '18px'
        }
      }
    },
    MuiAccordionDetails: {
      defaultProps: {
        sx: {
          mt: 2,
          p: 2,
          pl: 7
        }
      },
      styleOverrides: {
        root: {
          backgroundColor: 'white',
          borderRadius: '4px'
        }
      }
    },
    MuiListItemButton: {
      styleOverrides: {
        root: ({ theme }) => ({
          '&.Mui-selected': {
            color: theme.palette.primary.main
          },
          '&.Mui-selected .MuiListItemIcon-root': {
            color: theme.palette.primary.main
          }
        })
      }
    },
    MuiTableRow: {
      styleOverrides: {
        root: ({ theme }) => ({
          '&.Mui-selected .MuiTableCell-root': {
            backgroundColor: removeAlpha(
              alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity),
              theme.palette.background.default
            )
          }
        })
      }
    },
    MuiTableCell: {
      styleOverrides: {
        root: ({ theme }) => ({
          backgroundColor: theme.palette.background.paper,
          borderColor: theme.palette.divider,
          '&:last-child': {
            borderRightWidth: 0
          }
        })
      }
    }
  }
});

export default AppTheme;
