import cx from 'classnames';
import moment, { isDate } from 'moment';
import React, { forwardRef, HTMLProps, useCallback, useContext, useMemo } from 'react';
import { createChainedFunction } from '../util';
import { CalendarContext } from './CalendarContext';
import styles from './Decade.module.css';
import { defaultYearWrapper, YearButton } from './YearButton';

export function getDecadeStart(year: number) {
    return year - (year % 10);
}

/**
 * The props for the {@link Decade} component.
 * @category Props
 */
interface DecadeProps extends HTMLProps<HTMLDivElement> {}

/**
 * @category Component
 * @group Date Picker
 */
export const Decade = forwardRef<HTMLDivElement, DecadeProps>(({ className, ...other }, ref) => {
    const instance = useContext(CalendarContext);

    const {
        offset,
        selected,
        calendars,
        minDate,
        maxDate,
        onDateSelected,
        handleDepthDowngrade,
        handleOffsetChanged,
        YearComponent = YearButton,
        YearWrapper = defaultYearWrapper
    } = instance;

    const calendar = calendars[0];
    const baseDate = calendar.firstDayOfMonth;
    const decadeStart = getDecadeStart(baseDate.getFullYear());

    const selectedDates = useMemo(() => {
        if (Array.isArray(selected)) {
            return selected;
        }
        return [selected];
    }, [selected]);

    const prepareYear = useCallback(
        (year: number) => {
            const todayDate = new Date();
            const firstDayOfYear = new Date(year, 0, 1);
            const newOffset = (offset ?? 0) + moment(firstDayOfYear).diff(baseDate, 'months');

            const selected =
                selectedDates
                    .filter((x) => isDate(x))
                    .some((date) => date.getFullYear() === firstDayOfYear.getFullYear()) ?? false;

            const selectable =
                (minDate && firstDayOfYear.getFullYear() < minDate.getFullYear()) ||
                (maxDate && maxDate.getFullYear() < firstDayOfYear.getFullYear())
                    ? false
                    : true;

            const today = firstDayOfYear.getFullYear() === todayDate.getFullYear();

            const dateObj = {
                date: firstDayOfYear,
                nextMonth: true,
                prevMonth: true,
                selectable,
                selected,
                today
            };

            function getYearProps({ onClick, ...rest }: Record<string, any> = {}) {
                return {
                    onClick: createChainedFunction(onClick, (event) => {
                        if (handleDepthDowngrade()) {
                            handleOffsetChanged(newOffset);
                            return;
                        }

                        onDateSelected(dateObj, event);
                    }),
                    disabled: !dateObj.selectable,
                    'aria-label': dateObj.date.toDateString(),
                    'aria-pressed': dateObj.selected,
                    role: 'button',
                    ...rest
                };
            }

            return { currentDate: firstDayOfYear, dateObj, selected, selectable, today, getYearProps };
        },
        [baseDate, handleDepthDowngrade, handleOffsetChanged, maxDate, minDate, offset, onDateSelected, selectedDates]
    );
    const RenderYear = useMemo(() => YearWrapper(YearComponent), [YearComponent, YearWrapper]);

    return (
        <div className={cx(styles.years, className)} {...other}>
            <YearButton empty />
            {Array(10)
                .fill(0)
                .map((_, yearIndex) => {
                    const { currentDate, selected, selectable, today, getYearProps } = prepareYear(
                        decadeStart + yearIndex
                    );

                    return (
                        <RenderYear
                            key={yearIndex}
                            {...getYearProps()}
                            selected={selected}
                            unavailable={!selectable}
                            today={today}
                            date={currentDate}
                        />
                    );
                })}
        </div>
    );
});
