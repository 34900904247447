import { ComponentProps, FC, Suspense } from 'react';
import { Loader, LoaderProps } from './Loader';

type SuspenseProps = ComponentProps<typeof Suspense>;

export interface SuspenseLoaderProps extends Omit<SuspenseProps, 'fallback'> {
  loaderProps?: LoaderProps;
  fallback?: SuspenseProps['fallback'];
}

export const SuspenseLoader: FC<SuspenseLoaderProps> = ({ loaderProps, ...other }) => {
  return <Suspense fallback={<Loader {...loaderProps} />} {...other} />;
};
