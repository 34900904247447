import { Draft } from '@reduxjs/toolkit';
import { WellPressureClient, WellPressureDto, WellPressureInputDto } from 'api';
import { isFinite } from 'lodash';
import { createAppAsyncThunk } from 'store/createAppAsyncThunk';
import { WellPressureState } from '../WellPressureState';
import { getWellPressureSnapshot } from '../utils/getWellPressureSnapshot';
import { wellPressurePresentStateSelector } from '../wellPressureSelectors';

const service = new WellPressureClient();

export function getWellPressureSaveState(wellPressure: WellPressureState): WellPressureDto[] {
  const result: WellPressureDto[] = [];

  const snapshot = getWellPressureSnapshot(wellPressure);

  for (const msRow of snapshot) {
    for (const wellRow of msRow.subRows) {
      const wellPressureInputs = Array.from(wellRow.inputs.entries()).map(
        ([weekDateId, input]) => new WellPressureInputDto({ weekDateId, pressureValue: input.pressureValue })
      );

      const newDto = new WellPressureDto({
        id: wellRow.originalId,
        wellId: wellRow.wellId,
        pressureDecline: wellRow.pressureDecline,
        reservoirPressure: wellRow.reservoirPressure,
        wellPressureInputs
      });

      result.push(newDto);
    }
  }

  return result;
}

export const saveWellPressure = createAppAsyncThunk('wellPressure/save', (_: void, { getState }) => {
  const wellPressure = wellPressurePresentStateSelector(getState());

  const data = getWellPressureSaveState(wellPressure);

  return service.save(data);
});

export function sealWellPressureChanges(state: Draft<WellPressureState>) {
  for (const msRow of state.rows) {
    for (const wellRow of msRow.subRows) {
      const pressureDeclineChange = state.pressureDeclineChanges.get(wellRow.id);

      if (isFinite(pressureDeclineChange)) {
        wellRow.pressureDecline = pressureDeclineChange;
      }

      const reservoirPressureChange = state.reservoirPressureChanges.get(wellRow.id);

      if (isFinite(reservoirPressureChange)) {
        wellRow.reservoirPressure = reservoirPressureChange;
      }

      for (const [weekDay, status] of wellRow.inputs) {
        const inputChange = state.inputChanges.get(wellRow.id)?.get(weekDay);

        if (inputChange) {
          status.pressureValue = inputChange.pressureValue;
        }
      }
    }
  }
}
