import { useMemo } from 'react';
import {
    PluginHook,
    useExpanded,
    useFilters,
    useFlexLayout,
    useGroupBy,
    usePagination,
    useResizeColumns,
    useRowSelect,
    useRowState,
    useSortBy
} from 'react-table';
import { DataGridPluginHook, DataGridSettings } from '../types';
import { contextMenuColumnHook } from './contextMenuColumnHook';
import { dragHandleColumnHook } from './dragHandleColumnHook';
import { expanderColumnHook, groupExpanderColumnHook } from './expanderColumnHook';
import { inlineEditHook } from './inlineEdit';
import { selectColumnHook } from './selectColumnHook';
import { selectRowHook } from './selectRowHook';
import { setStateHook } from './setStateHook';
import { stickyColumnHook } from './stickyColumnHook';

type DataGridPluginsOptions = Pick<
    DataGridSettings,
    | 'filtering'
    | 'grouping'
    | 'pagination'
    | 'selection'
    | 'sorting'
    | 'contextMenu'
    | 'dragAndDrop'
    | 'blockLayout'
    | 'resizeColumns'
    | 'inlineEdit'
    | 'expanding'
>;

export function useDataGridPlugins({
    filtering,
    grouping,
    sorting,
    pagination,
    selection,
    blockLayout,
    resizeColumns,
    inlineEdit,
    contextMenu,
    dragAndDrop,
    expanding
}: DataGridPluginsOptions) {
    return useMemo(() => {
        const result = new Set<DataGridPluginHook | PluginHook<any>>();

        result.add(setStateHook);

        let addExpanded = expanding ?? false;

        if (filtering) {
            result.add(useFilters);
            //result.push(useGlobalFilter)
        }
        if (grouping) {
            result.add(useGroupBy);
            addExpanded = true;
        }
        if (sorting) result.add(useSortBy);
        if (addExpanded) {
            result.add(useExpanded);
            if (grouping) {
                result.add(groupExpanderColumnHook);
            } else {
                result.add(expanderColumnHook);
            }
        }
        if (pagination) {
            result.add(useExpanded);
            result.add(usePagination);
        }
        if (selection) {
            result.add(useRowSelect);
            result.add(selectRowHook);
            if (selection.showColumn) result.add(selectColumnHook);
        }
        if (blockLayout) {
            result.add(useFlexLayout);
        }
        if (resizeColumns) {
            result.add(useResizeColumns);
        }
        // if (inlineEdit) {
        result.add(useRowState);
        result.add(inlineEditHook);
        // }
        if (contextMenu) {
            result.add(contextMenuColumnHook);
        }
        if (dragAndDrop?.allow) result.add(dragHandleColumnHook);

        result.add(stickyColumnHook);

        return Array.from(result.values()) as PluginHook<any>[];
    }, []);
}
