import { useCallback, useMemo } from 'react';
import { createDoubleClickHandler } from '../../DataGrid/hooks/useDoubleClick';
import { TimelineState, UniversalTimelineInstance } from '../TimelineTypes';
import { actions } from '../utils/actions';
import { makePropGetter } from '../utils/utils';

actions.resetSelectedItems = 'resetSelectedItems';
actions.toggleItemSelected = 'toggleItemSelected';

export function selectReducer(
    state: TimelineState<any>,
    action,
    previousState,
    instance: UniversalTimelineInstance
): TimelineState<any> {
    if (action.type === actions.init) {
        return {
            selectedItemIds: {},
            ...state,
        };
    }

    if (action.type === actions.resetSelectedItems) {
        return {
            ...state,
            selectedItemIds: {},
        };
    }

    if (action.type === actions.toggleItemSelected) {
        const { id, value: setSelected } = action;
        const { itemsById } = instance;

        const item = itemsById[id];
        const isSelected = item.isSelected;
        const shouldExist = typeof setSelected !== 'undefined' ? setSelected : !isSelected;

        if (isSelected === shouldExist) {
            return state;
        }

        const newSelectedItemIds = {};

        if (shouldExist) {
            newSelectedItemIds[id] = true;
        } else {
            delete newSelectedItemIds[id];
        }

        return {
            ...state,
            selectedItemIds: newSelectedItemIds,
        };
    }

    return state;
}

export function useSelect(instance: UniversalTimelineInstance) {
    const {
        items,
        state: { selectedItemIds },
        dispatch,
    } = instance;

    const selectedFlatItems = useMemo(() => {
        const selectedFlatItems = [];

        items.forEach((row) => {
            const isSelected = !!selectedItemIds[row.id];
            row.isSelected = !!isSelected;

            if (isSelected) {
                selectedFlatItems.push(row);
            }
        });

        return selectedFlatItems;
    }, [items, selectedItemIds]);

    const toggleItemSelected = useCallback(
        (id, value, event) => dispatch({ type: actions.toggleItemSelected, id, value, event }),
        [dispatch]
    );

    const resetSelectedItems = useCallback(() => dispatch({ type: actions.resetSelectedItems }), [dispatch]);

    Object.assign(instance, {
        selectedFlatItems,
        toggleItemSelected,
        resetSelectedItems,
    });
}

export function prepareItem(item, instance) {
    item.toggleItemSelected = (set, event) => instance.toggleItemSelected(item.id, set, event);

    item.getToggleItemSelectedProps = makePropGetter([getToggleItemSelectedProps], {
        instance,
        item,
    });
}

const getToggleItemSelectedProps = (props, { item, instance }) => {
    const { onEdit } = instance;
    const flags = {
        drag: false,
        down: false,
    };
    const checked = item.isSelected;

    const handleSelect = (event) => {
        item.toggleItemSelected(!item.isSelected, event);
    };

    const handleEdit = (event) => {
        if (onEdit) {
            onEdit(item, event);
        }
    };

    const handleDoubleClick = createDoubleClickHandler(handleSelect, handleEdit, 300);

    return [
        props,
        {
            onMouseDown: () => {
                flags.drag = false;
                flags.down = true;
            },
            onMouseMove: () => {
                flags.drag = true;
            },
            onMouseUp: (event) => {
                if (flags.down && !flags.drag) {
                    handleDoubleClick(event);
                }
                flags.down = false;
            },
            style: {
                cursor: 'pointer',
            },
            checked,
            title: 'Toggle Item Selected',
        },
    ];
};
