import cx from 'classnames';
import React, { forwardRef, ReactNode, Ref } from 'react';
import { IconButton, IconButtonProps } from '../Button/IconButton';
import { useControlled, combineClasses } from '../util';
import styles from './SwitchBase.module.css';

/**
 * The props for the {@link SwitchBase} component.
 * @category Props
 */
export interface SwitchBaseProps extends Omit<IconButtonProps, 'onChange'> {
    classes?: {
        root?: string;
        checked?: string;
        disabled?: string;
        input?: string;
    };
    autoFocus?: boolean;
    checked?: boolean;
    checkedIcon: ReactNode;
    defaultChecked?: boolean;
    disabled?: boolean;
    disableRipple?: boolean;
    icon: ReactNode;
    id?: string;
    inputProps?: React.InputHTMLAttributes<HTMLInputElement> & { [key: string]: any };
    inputRef?: Ref<any>;
    name?: string;
    onChange?: (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void;
    readOnly?: boolean;
    required?: boolean;
    tabIndex?: number;
    type?: React.InputHTMLAttributes<HTMLInputElement>['type'];
}

/**
 * @category Component
 * @group Switch
 */
export const SwitchBase = forwardRef(function SwitchBase(props: SwitchBaseProps, ref: any) {
    const {
        classes: classesProp,
        autoFocus,
        checked: checkedProp,
        checkedIcon,
        className,
        defaultChecked,
        disabled: disabledProp,
        icon,
        id,
        inputProps,
        inputRef,
        name,
        onBlur,
        onChange,
        onFocus,
        readOnly,
        required,
        tabIndex,
        type,
        value,
        ...other
    } = props;

    const classes = combineClasses(styles, classesProp);

    const [checked, setCheckedState] = useControlled({
        controlled: checkedProp,
        default: Boolean(defaultChecked)
    });

    //const formControl = useFormControl();

    const handleFocus = (event) => {
        if (onFocus) {
            onFocus(event);
        }

        // if (formControl && formControl.onFocus) {
        //     formControl.onFocus(event);
        // }
    };

    const handleBlur = (event) => {
        if (onBlur) {
            onBlur(event);
        }

        // if (formControl && formControl.onBlur) {
        //     formControl.onBlur(event);
        // }
    };

    const handleInputChange = (event) => {
        // Workaround for https://github.com/facebook/react/issues/9023
        if (event.nativeEvent.defaultPrevented) {
            return;
        }

        const newChecked = event.target.checked;

        setCheckedState(newChecked);

        if (onChange) {
            onChange(event, newChecked);
        }
    };

    const disabled = disabledProp;

    // if (formControl) {
    //     if (typeof disabled === 'undefined') {
    //         disabled = formControl.disabled;
    //     }
    // }

    return (
        <IconButton
            component="span"
            className={cx(
                classes.root,
                {
                    [classes.checked]: checked,
                    [classes.disabled]: disabled
                },
                className
            )}
            disabled={disabled}
            tabIndex={null}
            role={undefined}
            onFocus={handleFocus}
            onBlur={handleBlur}
            ref={ref}
            {...other}
        >
            <input
                autoFocus={autoFocus}
                checked={checked}
                defaultChecked={defaultChecked}
                className={classes.input}
                disabled={disabled}
                id={id}
                name={name}
                onChange={handleInputChange}
                readOnly={readOnly}
                ref={inputRef}
                required={required}
                tabIndex={tabIndex}
                type={type}
                value={value}
                {...inputProps}
            />
            {checked ? checkedIcon : icon}
        </IconButton>
    );
});
