import React, { forwardRef, memo, Ref, useCallback, useContext, useMemo } from 'react';
import { DragDropContext, DraggableChildrenFn, Droppable, DroppableProps } from 'react-beautiful-dnd';
import { FixedSizeList, FixedSizeListProps, ListChildComponentProps } from 'react-window';
import { useForkRef } from '../../util';
import { DataGridContext } from '../DataGridContext';
import { DataGridRowProps } from '../types';
import { DataGridRowRenderer } from './RenderRow';

export const RenderDataGridList = ({ items, children: RowRenderer }) => {
    const { prepareRow } = useContext(DataGridContext);

    return items.map((row: DataGridRowProps, index) => {
        prepareRow(row);
        return <RowRenderer key={index} row={row} index={index} />;
    });
};

export const RenderDataGridSortableList = forwardRef(function RenderDataGridSortableList(
    { items, children, onDragEnd, ...other }: any,
    ref: Ref<HTMLDivElement>
) {
    const { settings } = useContext(DataGridContext);

    const { dragAndDrop } = settings;

    return (
        <DragDropContext onDragEnd={onDragEnd}>
            <Droppable isDropDisabled={!dragAndDrop.allow} droppableId="table">
                {(provided) => {
                    // eslint-disable-next-line react-hooks/rules-of-hooks
                    const containerRef = useForkRef(ref, provided.innerRef);

                    return (
                        <div {...provided.droppableProps} ref={containerRef}>
                            <RenderDataGridList items={items} {...other}>
                                {children}
                            </RenderDataGridList>
                            {provided.placeholder}
                        </div>
                    );
                }}
            </Droppable>
        </DragDropContext>
    );
});

interface DataGridVirtualListProps extends Omit<Partial<FixedSizeListProps>, 'children'> {
    items: any[];
    children: DataGridRowRenderer;
}

export const RenderDataGridVirtualList = function RenderDataGridVirtualList({
    outerRef,
    height,
    items,
    itemCount,
    itemSize,
    children: RowRenderer,
    onScroll,
    ...other
}: DataGridVirtualListProps) {
    const RenderListChild = useMemo(
        () =>
            memo(function FixedSizeListChild({ index, data, style }: ListChildComponentProps) {
                // eslint-disable-next-line react-hooks/rules-of-hooks
                const { prepareRow } = useContext(DataGridContext);

                const row = data[index] as DataGridRowProps;
                prepareRow(row);
                return <RowRenderer key={index} row={row} index={index} style={style} />;
            }),
        [RowRenderer]
    );

    return (
        <FixedSizeList
            outerRef={outerRef}
            itemData={items}
            height={height}
            itemCount={itemCount}
            itemSize={itemSize}
            width="100%"
            overscanCount={5}
            onScroll={onScroll}
            {...other}
        >
            {RenderListChild}
        </FixedSizeList>
    );
};

export const RenderDataGridSortableVirtualList = ({
    outerRef,
    items,
    children: SortableRowRenderer,
    RowRenderer,
    onDragEnd,
    ...other
}) => {
    const { settings, prepareRow } = useContext(DataGridContext);

    const { dragAndDrop } = settings;

    const renderClone: DraggableChildrenFn = useCallback(
        (provided, snapshot, rubric) => {
            const index = rubric.source.index;
            const row = items[index] as DataGridRowProps;

            prepareRow(row);

            return (
                <RowRenderer
                    row={row}
                    index={index}
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    dragHandleProps={provided.dragHandleProps}
                    dragSnapshot={snapshot}
                />
            );
        },
        [RowRenderer, items, prepareRow]
    );

    const RenderList: DroppableProps['children'] = useCallback(
        (provided, snapshot) => {
            // eslint-disable-next-line react-hooks/rules-of-hooks
            const ref = useForkRef(outerRef, provided.innerRef);

            return (
                <RenderDataGridVirtualList outerRef={ref} items={items} {...other}>
                    {SortableRowRenderer}
                </RenderDataGridVirtualList>
            );
        },
        [SortableRowRenderer, outerRef, items, other]
    );

    return (
        <DragDropContext onDragEnd={onDragEnd}>
            <Droppable isDropDisabled={!dragAndDrop.allow} droppableId="table" mode="virtual" renderClone={renderClone}>
                {RenderList}
            </Droppable>
        </DragDropContext>
    );
};
