import { GetWeekDatesDto, IGetWeekDatesDto, InletPressureDto, InletPressureInputDto, UpliftFactorDto } from 'api';
import { getMondaysOnlyDates, groupDatesByWeekNumber } from 'store/official-inputs/lineup/utils/groupDatesByWeekNumber';
import { PlantInputsKey, UpliftFactors } from '../types/PlantInputsState';
import { GetPlantInputsThunk } from '../types/helperTypes';
import { PlantInputsRow } from '../types/tableTypes';

function compensateMissingDates({
  allWeekDates,
  datesLookup,
  rowsLookup
}: {
  allWeekDates: GetWeekDatesDto[];
  datesLookup: Map<number, IGetWeekDatesDto>;
  rowsLookup: Map<number, PlantInputsRow>;
}) {
  for (const [index, weekDate] of allWeekDates.entries()) {
    if (!datesLookup.has(weekDate.id)) {
      datesLookup.set(weekDate.id, weekDate);
    }

    for (const row of rowsLookup.values()) {
      if (!row.inputs.has(weekDate.id)) {
        const prevWeekDate = allWeekDates[index - 1];
        const prevInput = row.inputs.get(prevWeekDate?.id);

        row.inputs.set(weekDate.id, {
          weekDateId: weekDate.id,
          weekDate: weekDate.weekDate,
          rowId: row.rowId,
          value: prevInput?.value ?? row.defaultValue
        });
      }
    }
  }
}

function processInletPressure(
  items: InletPressureDto[],
  datesLookup: Map<number, IGetWeekDatesDto>,
  rowsLookup: Map<number, PlantInputsRow>
) {
  for (const { id: rowId, inletPressureInputs, inletPressureValue, jointRateId, jointRateName } of items) {
    let plantRow: PlantInputsRow = rowsLookup.get(rowId);

    if (!plantRow) {
      plantRow = {
        rowId,
        plantId: jointRateId,
        name: jointRateName,
        defaultValue: inletPressureValue,
        inputs: new Map()
      };
      rowsLookup.set(rowId, plantRow);
    }

    for (const input of inletPressureInputs) {
      const { weekDateId, weekDate } = input;

      if (!datesLookup.has(weekDateId)) {
        datesLookup.set(weekDateId, {
          id: weekDateId,
          weekDate: new Date(weekDate)
        });
      }

      plantRow.inputs.set(weekDateId, {
        id: input.id,
        rowId: input.inletPressureId,
        value: input.inletPressureValue,
        weekDate: input.weekDate,
        weekDateId: input.weekDateId
      });
    }
  }
}

function sortRows(rowsLookup: Map<number, PlantInputsRow>) {
  const rows = Array.from(rowsLookup.values());
  rows.sort((a, b) => a.name?.localeCompare(b.name));
  return rows;
}

function sortDates(datesLookup: Map<number, IGetWeekDatesDto>) {
  const weekDates = Array.from(datesLookup.values());
  weekDates.sort((a, b) => a.weekDate.getTime() - b.weekDate.getTime());
  return weekDates;
}

export function convertInletPressure({ items, weekDates: allWeekDates }: GetPlantInputsThunk, key: PlantInputsKey) {
  const datesById = new Map<number, IGetWeekDatesDto>();
  const rowsLookup = new Map<number, PlantInputsRow>();

  processInletPressure(items, datesById, rowsLookup);

  if (key === 'main') {
    compensateMissingDates({ allWeekDates, datesLookup: datesById, rowsLookup });
  }

  const rows = sortRows(rowsLookup);

  const dates = sortDates(datesById);

  const datesByWeekNumber = groupDatesByWeekNumber(dates);
  const splitableWeekDateIds = getMondaysOnlyDates(dates);

  return { dates, datesById, datesByWeekNumber, splitableWeekDateIds, rows };
}

export function convertSaveInletPressure(rows: PlantInputsRow[]): InletPressureDto[] {
  return rows.map((row) => {
    const inputs: InletPressureInputDto[] = Array.from(row.inputs.values()).map(
      (cell) =>
        new InletPressureInputDto({
          id: cell.id,
          inletPressureId: cell.rowId,
          inletPressureValue: cell.value,
          weekDate: cell.weekDate,
          weekDateId: cell.weekDateId
        })
    );

    return new InletPressureDto({
      id: row.rowId,
      inletPressureValue: row.defaultValue,
      jointRateName: row.name,
      jointRateId: row.plantId,
      inletPressureInputs: inputs
    });
  });
}

export function convertUpliftFactors(items: UpliftFactorDto[]): UpliftFactors {
  const result = {} as UpliftFactors;

  items.forEach((item) => {
    result[item.upliftFactorPlantType] = item;
  });

  return result;
}

export function convertSaveUpliftFactors(upliftFactors: UpliftFactors): UpliftFactorDto[] {
  return Object.values(upliftFactors);
}
