import { AnyAction, createSlice, PayloadAction, Reducer } from '@reduxjs/toolkit';
import { castDraft } from 'immer';
import undoable, { includeAction, StateWithHistory } from 'redux-undo';

import { handleError, handleLoading } from 'store/base/baseStateHandlers';
import { sealWellPressureChanges } from 'store/wellPressure/actions/saveWellPressure';
import { convertWellPressureData } from 'store/wellPressure/utils/convertWellPressureData';
import {
  copyWellPressureColumnsAction,
  pasteWellPressureColumns
} from 'store/wellPressure/utils/copyPasteWellPressure';
import {
  ChangeWellPressureDeclinePayload,
  ChangeWellPressureInputPayload,
  ChangeWellReservoirPressurePayload,
  setWellPressureDeclineChange,
  setWellPressureInputChange,
  setWellReservoirPressureChange
} from 'store/wellPressure/utils/wellPressureStatusChange';
import { WellPressureState } from 'store/wellPressure/WellPressureState';
import { getWellPressureCase, WellPressureCaseParams } from './actions/getWellPressureCase';
import { saveWellPressureCase } from './actions/saveWellPressureCase';

const initialState: WellPressureState<WellPressureCaseParams> = { splitableWeekDateIds: new Set() };

const { actions, reducer } = createSlice({
  initialState,
  name: 'wellPressureCase',
  reducers: {
    reset(state) {
      state.pressureDeclineChanges.clear();
      state.reservoirPressureChanges.clear();
      state.inputChanges.clear();
    },
    setWellPressureInput(state, action: PayloadAction<ChangeWellPressureInputPayload>) {
      setWellPressureInputChange(state, action.payload);
    },
    setWellPressureDecline(state, action: PayloadAction<ChangeWellPressureDeclinePayload>) {
      setWellPressureDeclineChange(state, action.payload);
    },
    setWellReservoirPressure(state, action: PayloadAction<ChangeWellReservoirPressurePayload>) {
      setWellReservoirPressureChange(state, action.payload);
    },
    copyWellPressureCaseColumns(state, action: PayloadAction<number[]>) {
      copyWellPressureColumnsAction(state, state.rows, action.payload);
    },
    pasteColumns(state, action: PayloadAction<number[]>) {
      pasteWellPressureColumns(state, action.payload);
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getWellPressureCase.pending, (state, action) => {
        state.arg = castDraft(action.meta.arg);
        handleLoading(state);
      })
      .addCase(getWellPressureCase.fulfilled, (state, action) => {
        state.arg = castDraft(action.meta.arg);
        state.loading = false;
        state.data = action.payload.items;
        const { rows, dates } = convertWellPressureData(action.payload.items, action.payload.adjustWeekDates);
        state.rows = rows;
        state.dates = dates;
        state.pressureDeclineChanges = new Map();
        state.reservoirPressureChanges = new Map();
        state.inputChanges = new Map();
      })
      .addCase(getWellPressureCase.rejected, (state, action) => {
        state.arg = undefined;
        handleError(state, action);
      });

    builder.addCase(saveWellPressureCase.fulfilled, (state) => {
      sealWellPressureChanges(state);
      state.pressureDeclineChanges.clear();
      state.reservoirPressureChanges.clear();
      state.inputChanges.clear();
    });
  }
});

export const wellPressureCaseActions = actions;

export const {
  reset,
  setWellPressureDecline,
  setWellPressureInput,
  setWellReservoirPressure,
  copyWellPressureCaseColumns,
  pasteColumns
} = actions;

export const WELL_PRESSURE_CASE_UNDO = 'WELL_PRESSURE_CASE_UNDO';
export const WELL_PRESSURE_CASE_UNDO_CLEAR = 'WELL_PRESSURE_CASE_UNDO_CLEAR';

export const getWellPressureCaseUndoableReducer = (
  reducer: Reducer<typeof initialState>
): Reducer<StateWithHistory<WellPressureState<WellPressureCaseParams>>, AnyAction> =>
  undoable(reducer, {
    undoType: WELL_PRESSURE_CASE_UNDO,
    clearHistoryType: WELL_PRESSURE_CASE_UNDO_CLEAR,
    filter: includeAction([
      setWellPressureDecline.type,
      setWellPressureInput.type,
      setWellReservoirPressure.type,
      getWellPressureCase.fulfilled.type,
      saveWellPressureCase.fulfilled.type,
      pasteColumns.type,
      reset.type
    ]),
    debug: false
  });

export default getWellPressureCaseUndoableReducer(reducer);
