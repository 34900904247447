import { faCalendar, faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import IMask, { AnyMaskedOptions, MaskedDate } from 'imask';
import moment, { isDate } from 'moment';
import React, {
    ComponentProps,
    FC,
    forwardRef,
    ForwardRefExoticComponent,
    ReactElement,
    Ref,
    RefAttributes,
    useCallback,
    useMemo,
    useRef,
    useState
} from 'react';
import { IMaskInput } from 'react-imask';
import { getDateWithoutUTCOffset } from '.';
import { IconButton } from '../Button/IconButton';
import { useComponentsConfiguration } from '../configuration/useComponentsConfiguration';
import { FormControl, FormControlProps } from '../Input/FormControl';
import { Input, InputProps } from '../Input/Input';
import { Popover } from '../Popover';
import { useForkRef } from '../util';
import { Calendars, CalendarsProps } from './Calendars';
import { CLIENT_UTC_OFFSET, getDateWithUTCOffset } from './utils';

type MaskOptions = AnyMaskedOptions & {
    pattern: string;
};

function makeMomentMask(format: string): MaskOptions {
    return {
        mask: Date,
        pattern: format,
        format: function (date) {
            return moment(date).format(format);
        },
        parse: function (str) {
            return moment(str, format) as any;
        },
        placeholderChar: '#',
        blocks: {
            DD: {
                mask: IMask.MaskedRange,
                from: 1,
                to: 31
            },
            MM: {
                mask: IMask.MaskedRange,
                from: 1,
                to: 12
            },
            YYYY: {
                mask: IMask.MaskedRange,
                from: 1000,
                to: 9999
            }
        }
    };
}

type Mask = 'momentDate' | 'momentMonth' | 'momentYear';

const masks: Record<Mask, MaskOptions> = {
    momentDate: makeMomentMask('DD.MM.YYYY'),
    momentMonth: makeMomentMask('MM.YYYY'),
    momentYear: makeMomentMask('YYYY')
};

const MaskInput = forwardRef(function CustomMaskInput(
    { inputRef, ...other }: ComponentProps<typeof IMaskInput>,
    ref: Ref<ReactElement>
) {
    const handleRef = useForkRef(inputRef, ref);
    return <IMaskInput inputRef={handleRef} {...other} />;
});

export interface DatePickerAdditionalProps {
    openOnFocus?: boolean;
    hideCalendar?: boolean;
    closeOnSelect?: boolean;
    FormControlComponent?: ForwardRefExoticComponent<FormControlProps & RefAttributes<unknown>>;
    InputComponent?: ForwardRefExoticComponent<InputProps<typeof IMaskInput> & RefAttributes<unknown>>;
    InputProps?: InputProps<typeof IMaskInput>;
}

/**
 * The props of the {@link DatePicker} component.
 */
export interface DatePickerProps
    extends Omit<FormControlProps, 'onChange' | 'control'>,
        DatePickerAdditionalProps,
        CalendarsProps {}

/**
 * @category Component
 * @group Date Picker
 */
export const DatePicker: FC<DatePickerProps> = function DatePicker(props) {
    const {
        fullWidth,
        disableMinWidth,
        width,
        disableMargin,
        placeholder,
        InputProps,
        defaultDepth,
        minDepth = 'month',
        openOnFocus,
        hideCalendar,
        closeOnSelect = true,
        disabled,
        enableClear,
        selectRange,
        date,
        maxDate,
        minDate,
        monthsToDisplay,
        firstDayOfWeek,
        showOutsideDays,
        value,
        offset,
        label,
        onOffsetChanged,
        onChange,
        DayWrapper,
        MonthWrapper,
        YearWrapper,
        DayComponent,
        MonthComponent,
        YearComponent,
        utcOffset = CLIENT_UTC_OFFSET,
        FormControlComponent = FormControl,
        InputComponent = Input,
        ...other
    } = useComponentsConfiguration('DatePicker', props);

    const anchorEl = useRef();

    const [open, setOpen] = useState(false);

    const handleOpen = useCallback(() => {
        setOpen(true);
    }, []);

    const handleClose = useCallback(() => {
        setOpen(false);
    }, []);

    const [mask, inputValue, parseValue] = useMemo(() => {
        const maskName: Mask = minDepth == 'decade' ? 'momentYear' : minDepth == 'year' ? 'momentMonth' : 'momentDate';
        const mask = masks[maskName];
        const parseMoment = (str: string) => getDateWithUTCOffset(moment(str, mask.pattern).toDate(), utcOffset);

        if (selectRange || Array.isArray(date)) {
            return [
                `${maskName} — ${maskName}`,
                `${value?.[0] ? moment(getDateWithoutUTCOffset(value[0], utcOffset)).format(mask.pattern) : ''} — ${
                    value?.[1] ? moment(getDateWithoutUTCOffset(value[1], utcOffset)).format(mask.pattern) : ''
                }`,
                (str: string) => str.split('—').map(parseMoment)
            ];
        }

        if (isDate(value)) {
            return [maskName, moment(getDateWithoutUTCOffset(value, utcOffset)).format(mask.pattern), parseMoment];
        }

        return [maskName, '', parseMoment];
    }, [date, minDepth, selectRange, utcOffset, value]);

    const handleChange = useCallback(
        (value: string, maskRef?: IMask.InputMask<MaskedDate>, event?: InputEvent) => {
            if (maskRef != undefined && event == undefined) {
                return;
            }
            if (value == undefined) {
                onChange(undefined);
            } else {
                const newValue = parseValue(value);
                onChange(newValue as any);
            }
        },
        [onChange, parseValue]
    );

    const handleSelected: CalendarsProps['onChange'] = useCallback(
        (...args) => {
            onChange(...args);
            if (!selectRange && closeOnSelect) {
                handleClose();
            }

            if (selectRange && Array.isArray(args) && args[0].length === 2 && closeOnSelect) {
                handleClose();
            }
        },
        [handleClose, onChange, selectRange]
    );

    const handleFocus = useCallback(() => {
        if (openOnFocus && !open) {
            handleOpen();
        }
    }, [handleOpen, open, openOnFocus]);

    return (
        <FormControlComponent
            ref={anchorEl}
            disabled={disabled}
            disableMargin={disableMargin}
            disableMinWidth={disableMinWidth}
            fullWidth={fullWidth}
            width={width}
            label={label}
            control={
                <>
                    <InputComponent
                        disabled={disabled}
                        inputComponent={MaskInput}
                        inputProps={{
                            mask,
                            lazy: true,
                            autofix: true,
                            unmask: 'typed',
                            blocks: masks,
                            placeholder,
                            onComplete: handleChange,
                            ...(InputProps as any)
                        }}
                        label={label}
                        value={inputValue}
                        type="text"
                        endAdornment={
                            <>
                                {enableClear && !disabled && (
                                    <IconButton
                                        disabled={disabled}
                                        size="small"
                                        onClick={handleChange.bind(undefined, undefined, undefined)}
                                    >
                                        <FontAwesomeIcon icon={faXmark} />
                                    </IconButton>
                                )}
                                {hideCalendar || openOnFocus ? undefined : (
                                    <IconButton size="small" onClick={handleOpen}>
                                        <FontAwesomeIcon icon={faCalendar} />
                                    </IconButton>
                                )}
                            </>
                        }
                        onFocus={handleFocus}
                    />
                    <Popover
                        open={open}
                        anchorEl={anchorEl}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left'
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'left'
                        }}
                        marginThreshold={0}
                        onClose={handleClose}
                    >
                        <Calendars
                            defaultDepth={defaultDepth}
                            minDepth={minDepth}
                            disabled={disabled}
                            enableClear={enableClear}
                            selectRange={selectRange}
                            date={date}
                            maxDate={maxDate}
                            minDate={minDate}
                            monthsToDisplay={monthsToDisplay}
                            firstDayOfWeek={firstDayOfWeek}
                            showOutsideDays={showOutsideDays}
                            value={value}
                            offset={offset}
                            onOffsetChanged={onOffsetChanged}
                            onChange={handleSelected}
                            utcOffset={utcOffset}
                            DayWrapper={DayWrapper}
                            MonthWrapper={MonthWrapper}
                            YearWrapper={YearWrapper}
                            DayComponent={DayComponent}
                            MonthComponent={MonthComponent}
                            YearComponent={YearComponent}
                        />
                    </Popover>
                </>
            }
            {...other}
        />
    );
};
