import cx from 'classnames';
import React, { ComponentPropsWithoutRef, FC, forwardRef } from 'react';
import styles from './TableBody.module.css';

/**
 * The props for the {@link TableBody} component.
 * @category Props
 */
export interface TableBodyProps extends ComponentPropsWithoutRef<'tbody'> {
    blockLayout?: boolean;
}

/**
 * @category Component
 * @group Table
 */
export const TableBody = forwardRef(function TableBody(
    { className, blockLayout: blockLayoutProp = false, children, ...other }: TableBodyProps,
    ref: any
) {
    let isBlockLayout = blockLayoutProp;
    let Component: any = isBlockLayout ? 'div' : 'tbody';

    return (
        <Component
            ref={ref}
            className={cx(styles.body, isBlockLayout ? styles.blockBody : styles.regularBody, className)}
            {...other}
        >
            {children}
        </Component>
    );
});
