import { IUseAsInputsDto, PlantInputsClient, UpdateUseAsInputsDto, UseAsInputsDto } from 'api';
import { createAppAsyncThunk } from 'store/createAppAsyncThunk';
import { UseAsInputsKey } from './types/useAsInputsState';
import { selectUseAsInputsState } from './useAsInputsSelectors';

const client = new PlantInputsClient();
type KeyAccessor = {
  keyAccessor: UseAsInputsKey;
};
export type SaveUseAsInputsDataParams = KeyAccessor & {
  valueAccessor: keyof IUseAsInputsDto;
};
export type FetchUseAsInputsDataParams = KeyAccessor & {
  refetch?: boolean;
};

export const fetchUseAsInputsData = createAppAsyncThunk<UseAsInputsDto, FetchUseAsInputsDataParams>(
  'use-as-inputs/get',
  async ({ keyAccessor }, { rejectWithValue }) => {
    try {
      const request = keyAccessor === 'MAIN' ? client.getUseAsInputs() : client.getUseAsInputsByCaseId(keyAccessor);
      const response = await request;
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
  {
    condition: ({ refetch, keyAccessor }, { getState }) => {
      const useAsInputsState = selectUseAsInputsState(getState());
      const state = useAsInputsState[keyAccessor];
      if (state === undefined || (!state.loading && state.data === undefined) || refetch) {
        return true;
      }

      return false;
    }
  }
);

export const saveUseAsInputs = createAppAsyncThunk(
  'use-as-inputs/save',
  ({ keyAccessor, valueAccessor }: SaveUseAsInputsDataParams, { getState, rejectWithValue }) => {
    try {
      const state = getState();
      const useAsInputsState = selectUseAsInputsState(state);
      const changes = useAsInputsState[keyAccessor].changes;

      if (keyAccessor === 'MAIN') {
        const dto = new UseAsInputsDto({ [valueAccessor]: changes[valueAccessor] });
        return client.updateUseAsInputs(dto);
      }

      const dto = new UpdateUseAsInputsDto({ [valueAccessor]: changes[valueAccessor], scenarioCaseId: keyAccessor });
      return client.updateUseAsInputsByCaseId(dto);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
