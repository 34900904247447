import { faGripVertical } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { serviceColumns } from '../utils/serviceColumns';
import styles from './Columns.module.css';

const DataGridDragHandle = (props) => (
    <div className={styles.drag_handle} {...props}>
        {!props.dragDisabled && <FontAwesomeIcon icon={faGripVertical} />}
    </div>
);

export const dragHandleColumnHook = (hooks) => {
    const template = ({ sideEffects, settings, dragHandleProps, row }) => {
        let disabled = !settings.dragAndDrop?.enabled || !settings.dragAndDrop?.allow;
        if (!disabled && settings.dragAndDrop.disableWhenSideEffects) {
            disabled = disabled || sideEffects;
        }

        return disabled ? null : (
            <DataGridDragHandle
                {...dragHandleProps}
                dragDisabled={settings.dragKey && row.original[settings.dragKey]}
            />
        );
    };
    hooks.visibleColumns.push((columns) => [
        {
            id: serviceColumns.dragHandle,
            className: styles.dragColumn,
            Header: '',
            Cell: template,
            CellEdit: template,
            minWidth: 34,
            width: 34,
            maxWidth: 34,
            disableResizing: true,
            disableEdit: true,
            style: {
                padding: 0
            },
            sticky: columns?.[0]?.sticky
        },
        ...columns
    ]);
};
