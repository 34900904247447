import { Navigate, RouteObject } from 'react-router-dom';
import { lazyComponent } from 'utils/lazyComponent';
import { MeterStationDetailsChart } from './meter-station-details/MeterStationDetailsChart';
import { MeterStationDetailsTable } from './meter-station-details/MeterStationDetailsTable';
import { PiHistorianChart } from './well-details/PiHistorianChart';
import { PiHistorianTable } from './well-details/PiHistorianTable';
import { PressureTable } from './well-details/PressureTable';
import { GorChart, PressureChart, ProductivityIndexChart } from './well-details/wellschart';

const WellInput = lazyComponent(() => import('./WellInput'), 'wellInput');
const MeterStationPage = lazyComponent(() => import('./meter-station-details/MeterStationDetailsPage'), 'meterStationDetailsPage');
const WellDetailsPage = lazyComponent(() => import('./well-details/WellDetailsPage'), 'wellDetailsPage');

export const wellInputRoutes: RouteObject[] = [
  {
    path: 'wells',
    element: <WellInput />
  },
  {
    path: 'wells/ms/:msId',
    element: <MeterStationPage />,
    children: [
      { index: true, element: <Navigate replace to="chart" /> },
      {
        path: 'chart',
        element: <MeterStationDetailsChart />
      },
      {
        path: 'table',
        element: <MeterStationDetailsTable />
      }
    ]
  },
  {
    path: 'wells/ms/:msId/well/:wellId',
    element: <WellDetailsPage />,
    children: [
      { index: true, element: <Navigate replace to="pi-historian" /> },
      {
        path: 'pi-historian',
        children: [
          { index: true, element: <Navigate replace to="chart" /> },
          {
            path: 'chart',
            element: <PiHistorianChart />
          },
          {
            path: 'table',
            element: <PiHistorianTable />
          }
        ]
      },
      {
        path: 'productivity-index',
        children: [
          { index: true, element: <Navigate replace to="chart" /> },
          {
            path: 'chart',
            element: <ProductivityIndexChart />
          },
          {
            path: 'table'
          }
        ]
      },
      {
        path: 'gor',
        children: [
          { index: true, element: <Navigate replace to="chart" /> },
          {
            path: 'chart',
            element: <GorChart />
          },
          {
            path: 'table'
          }
        ]
      },
      {
        path: 'pressure',
        children: [
          { index: true, element: <Navigate replace to="chart" /> },
          {
            path: 'chart',
            element: <PressureChart />
          },
          {
            path: 'table',
            element: <PressureTable />
          }
        ]
      }
    ]
  }
];
