import { Draft } from '@reduxjs/toolkit';
import { SchemeComponentType } from 'api';
import { castDraft } from 'immer';
import { syncWellsWithMS } from 'store/official-inputs/wellInput/actions/wellInputStatusChanges';
import { OfficialInputState } from '../../OfficialInputState';
import { LineupTableRow } from '../types/LineupTableRow';
import {
  ToggleConnectionStatusPayload,
  ToggleLineupStatusPayload,
  ToggleParentStatusPayload,
  ToggleStatusPayload
} from '../types/ToggleStatusPayload';

function getWeekDatesBetween(state: Draft<OfficialInputState>, startWeekDateId: number, endWeekDateId: number) {
  const startTime = state.lineup.datesById.get(startWeekDateId).weekDate.getTime();
  const endTime = state.lineup.datesById.get(endWeekDateId).weekDate.getTime();

  const weekDates = state.lineup.dates.filter((x) => {
    const time = x.weekDate.getTime();
    return time >= startTime && time < endTime;
  });

  return weekDates.map((x) => x.id);
}

export function setMeterStationStatusChange(
  state: Draft<OfficialInputState>,
  { rowId, weekDate, value }: ToggleParentStatusPayload
) {
  const lineup = state.lineup.rowsById.get(rowId)?.parentStatuses?.get(weekDate);
  const wellInput = state.wellInput.rowsById.get(rowId)?.msStatuses?.get(weekDate);

  let rowChanges = state.changes.MeterStation.get(rowId);
  if (!rowChanges) {
    rowChanges = new Map();
    state.changes.MeterStation.set(rowId, rowChanges);
  }

  rowChanges.set(weekDate, {
    wellInput,
    lineup,
    status: value
  });
}

function setHeaderStatusChange(
  state: Draft<OfficialInputState>,
  { rowId, weekDate, value }: ToggleParentStatusPayload
) {
  const lineup = state.lineup.rowsById.get(rowId)?.parentStatuses?.get(weekDate);

  let rowChanges = state.changes.Header.get(rowId);
  if (!rowChanges) {
    rowChanges = new Map();
    state.changes.Header.set(rowId, rowChanges);
  }

  rowChanges.set(weekDate, {
    ...lineup,
    status: value
  });
}

export function toggleLineupMeterStationStatusChange(
  state: Draft<OfficialInputState>,
  payload: ToggleParentStatusPayload
) {
  const { event, value, rowId, weekDate, syncSubRows, syncWells } = payload;

  const row = state.lineup.rowsById.get(rowId);
  let rowChanges = state.changes.MeterStation.get(row.id);
  if (!rowChanges) {
    rowChanges = new Map();
    state.changes.MeterStation.set(row.id, rowChanges);
  }

  const originalStatus = row.parentStatuses.get(weekDate);
  const previousStatus = rowChanges.get(weekDate)?.status ?? originalStatus?.status;
  const newStatus = value ?? !previousStatus;

  if (originalStatus.status !== newStatus) {
    const wellInputRow = state.wellInput.rowsById.get(rowId);
    const wellInput = wellInputRow?.msStatuses?.get(weekDate);
    rowChanges.set(weekDate, {
      wellInput,
      lineup: originalStatus,
      status: newStatus
    });
  } else {
    rowChanges.delete(weekDate);
  }

  if (syncSubRows) {
    // eslint-disable-next-line no-use-before-define
    syncConnectionsWithParent(state, row, weekDate, newStatus);
  }

  if (syncWells) {
    const wellInputRow = state.wellInput.rowsById?.get(row.id);
    if (wellInputRow) syncWellsWithMS(state, wellInputRow, weekDate, newStatus);
  }

  if (!event) {
    return;
  }

  if (event.shiftKey && state.lineup.previousStatusChange?.rowId === rowId) {
    const weekDates = getWeekDatesBetween(state, state.lineup.previousStatusChange.weekDate, weekDate);
    for (const weekDate of weekDates) {
      toggleLineupMeterStationStatusChange(state, { rowId, weekDate, value: newStatus, syncSubRows, syncWells });
    }
  } else {
    state.lineup.previousStatusChange = castDraft(payload);
  }
}

export function toggleHeaderStatusChange(state: Draft<OfficialInputState>, payload: ToggleParentStatusPayload) {
  const { event, value, rowId, weekDate, syncSubRows } = payload;

  const row = state.lineup.rowsById.get(rowId);
  let rowChanges = state.changes.Header.get(rowId);
  if (!rowChanges) {
    rowChanges = new Map();
    state.changes.Header.set(rowId, rowChanges);
  }

  const originalStatus = row.parentStatuses.get(weekDate);
  const previousStatus = rowChanges.get(weekDate)?.status ?? originalStatus?.status;
  const newStatus = value ?? !previousStatus;
  if (originalStatus.status !== newStatus) {
    rowChanges.set(weekDate, {
      ...originalStatus,
      status: newStatus
    });
  } else {
    rowChanges.delete(weekDate);
  }

  if (syncSubRows) {
    // eslint-disable-next-line no-use-before-define
    syncConnectionsWithParent(state, row, weekDate, newStatus);
  }

  if (!event) {
    return;
  }

  if (event.shiftKey && state.lineup.previousStatusChange?.rowId === rowId) {
    const weekDates = getWeekDatesBetween(state, state.lineup.previousStatusChange.weekDate, weekDate);
    for (const weekDate of weekDates) {
      toggleHeaderStatusChange(state, { rowId, weekDate, value: newStatus, syncSubRows });
    }
  } else {
    state.lineup.previousStatusChange = castDraft(payload);
  }
}

export function toggleConnectionStatusChange(state: Draft<OfficialInputState>, payload: ToggleConnectionStatusPayload) {
  const { rowId, event, weekDate, value, syncParentRow } = payload;

  const row = state.lineup.rowsById.get(rowId);
  let rowChanges = state.changes.Valve.get(rowId);
  if (!rowChanges) {
    rowChanges = new Map();
    state.changes.Valve.set(rowId, rowChanges);
  }

  const originalStatus = row.connectionStatuses.get(weekDate);
  const previousStatus = rowChanges.get(weekDate)?.status ?? originalStatus?.status;
  const newStatus = value ?? !previousStatus;

  if (originalStatus && originalStatus.status !== newStatus) {
    rowChanges.set(weekDate, {
      ...originalStatus,
      status: newStatus
    });
  } else {
    rowChanges.delete(weekDate);
  }

  if (newStatus) {
    // eslint-disable-next-line no-use-before-define
    setConnectionParentStatusToYes(state, row, weekDate);
  } else {
    if (syncParentRow) {
      // eslint-disable-next-line no-use-before-define
      syncParentWithConnections(state, row, weekDate, newStatus);
    }
  }

  if (!event) {
    return;
  }

  if (event.shiftKey && state.lineup.previousStatusChange?.rowId === rowId) {
    const weekDates = getWeekDatesBetween(state, state.lineup.previousStatusChange.weekDate, weekDate);
    for (const weekDate of weekDates) {
      toggleConnectionStatusChange(state, { rowId, weekDate, value: newStatus, syncParentRow });
    }
  } else {
    state.lineup.previousStatusChange = castDraft(payload);
  }
}

export function setLineupStatusChanges(state: Draft<OfficialInputState>, payload: ToggleLineupStatusPayload) {
  const { origin } = payload;
  let { selectedRows, selectedWeekDates } = payload;
  const { rowId, weekDate } = origin;
  const row = state.lineup.rowsById.get(rowId);
  let newStatus: boolean = undefined;

  if (state.selectedCells?.length > 0) {
    selectedRows = state.selectedCells.map((selectedCell) => selectedCell.rowId);
    selectedWeekDates = state.selectedCells.map((selectedCell) => selectedCell.weekDate);
  }

  const parentPayload =
    row.rowType === SchemeComponentType.MeterStation || row.rowType === SchemeComponentType.Header
      ? (origin as ToggleParentStatusPayload)
      : undefined;
  const connectionPayload =
    row.rowType === SchemeComponentType.Valve ? (origin as ToggleConnectionStatusPayload) : undefined;

  if (row.rowType === SchemeComponentType.MeterStation) {
    toggleLineupMeterStationStatusChange(state, parentPayload);
    newStatus = state.changes.MeterStation.get(rowId)?.get(weekDate)?.status ?? row.parentStatuses.get(weekDate).status;
  }

  if (row.rowType === SchemeComponentType.Header) {
    toggleHeaderStatusChange(state, parentPayload);
    newStatus = state.changes.Header.get(rowId)?.get(weekDate)?.status ?? row.parentStatuses.get(weekDate).status;
  }

  if (row.rowType === SchemeComponentType.Valve) {
    toggleConnectionStatusChange(state, connectionPayload);
    newStatus = state.changes.Valve.get(rowId)?.get(weekDate)?.status ?? row.connectionStatuses.get(weekDate).status;
  }

  const isSelectedRow = selectedRows.some((x) => x === rowId);
  const isSelectedWeekDate = selectedWeekDates.some((x) => x === weekDate);

  if ((!isSelectedRow && !isSelectedWeekDate) || newStatus === undefined) {
    return;
  }

  const rows = isSelectedWeekDate && !isSelectedRow ? [rowId] : selectedRows;
  const weekDates = isSelectedRow && !isSelectedWeekDate ? [weekDate] : selectedWeekDates;

  for (const selectedRowId of rows) {
    const selectedRow = state.lineup.rowsById.get(selectedRowId);
    for (const selectedWeekDate of weekDates) {
      if (selectedRow.rowType === SchemeComponentType.MeterStation) {
        toggleLineupMeterStationStatusChange(state, {
          rowId: selectedRowId,
          weekDate: selectedWeekDate,
          value: newStatus,
          syncSubRows: parentPayload.syncSubRows,
          syncWells: parentPayload.syncWells
        });
      }

      if (selectedRow.rowType === SchemeComponentType.Header) {
        toggleHeaderStatusChange(state, {
          rowId: selectedRowId,
          weekDate: selectedWeekDate,
          value: newStatus,
          syncSubRows: parentPayload.syncSubRows
        });
      }

      if (selectedRow.rowType === SchemeComponentType.Valve) {
        toggleConnectionStatusChange(state, {
          rowId: selectedRowId,
          weekDate: selectedWeekDate,
          value: newStatus,
          syncParentRow: connectionPayload?.syncParentRow
        });
      }
    }
  }
}

export function setLineupStatusArray(state: Draft<OfficialInputState>, payload: ToggleStatusPayload[]) {
  if (payload?.length === 0) {
    return;
  }

  for (const payloadRow of payload) {
    const { rowId, weekDate, value } = payloadRow;
    const row = state.lineup.rowsById.get(rowId);

    if (row.rowType === SchemeComponentType.MeterStation) {
      toggleLineupMeterStationStatusChange(state, {
        rowId,
        weekDate,
        value,
        syncSubRows: !value,
        syncWells: !value
      });
    }

    if (row.rowType === SchemeComponentType.Header) {
      toggleHeaderStatusChange(state, {
        rowId,
        weekDate,
        value,
        syncSubRows: !value
      });
    }

    if (row.rowType === SchemeComponentType.Valve) {
      toggleConnectionStatusChange(state, {
        rowId,
        weekDate,
        value,
        syncParentRow: true
      });
    }
  }
}

export function syncConnectionsWithParent(
  state: Draft<OfficialInputState>,
  row: LineupTableRow,
  weekDate: number,
  newStatus: boolean
) {
  for (const groupRow of row.subRows) {
    for (const connectionRow of groupRow.subRows) {
      toggleConnectionStatusChange(state, { rowId: connectionRow.id, weekDate, value: newStatus });
    }
  }
}

function setConnectionParentStatusToYes(state: Draft<OfficialInputState>, row: LineupTableRow, weekDate: number) {
  const parentRow = state.lineup.rowsById.get(row.parentId);
  const parentRowStatus = parentRow.parentStatuses.get(weekDate)?.status;
  if (parentRow.rowType === SchemeComponentType.MeterStation) {
    const status = state.changes.MeterStation.get(parentRow.id)?.get(weekDate)?.status ?? parentRowStatus;
    if (status !== true) {
      setMeterStationStatusChange(state, { rowId: row.parentId, weekDate, value: true });
    }
  }
  if (parentRow.rowType === SchemeComponentType.Header) {
    const status = state.changes.Header.get(parentRow.id)?.get(weekDate)?.status ?? parentRowStatus;
    if (status !== true) {
      setHeaderStatusChange(state, { rowId: row.parentId, weekDate, value: true });
    }
  }
}

function syncParentWithConnections(
  state: Draft<OfficialInputState>,
  row: LineupTableRow,
  weekDate: number,
  newStatus: boolean
) {
  const parentRow = state.lineup.rowsById.get(row.parentId);
  const isSameStatus = parentRow.subRows.every((groupRow) =>
    groupRow.subRows.every((connectionRow) => {
      const connectionStatus =
        state.changes.Valve.get(connectionRow.id)?.get(weekDate)?.status ??
        connectionRow.connectionStatuses.get(weekDate)?.status;

      return connectionStatus === newStatus;
    })
  );

  if (!isSameStatus) {
    return;
  }

  if (parentRow.rowType === SchemeComponentType.MeterStation) {
    const msStatus =
      state.changes.MeterStation.get(parentRow.id)?.get(weekDate)?.status ??
      parentRow.parentStatuses?.get(weekDate)?.status;
    if (msStatus !== newStatus)
      toggleLineupMeterStationStatusChange(state, {
        rowId: parentRow.id,
        weekDate,
        value: newStatus,
        syncWells: true
      });
  }

  if (parentRow.rowType === SchemeComponentType.Header) {
    const headerStatus =
      state.changes.Header.get(parentRow.id)?.get(weekDate)?.status ?? parentRow.parentStatuses?.get(weekDate)?.status;
    if (headerStatus !== newStatus)
      toggleHeaderStatusChange(state, { rowId: parentRow.id, weekDate, value: newStatus });
  }
}
