import cx from 'classnames';
import React, { cloneElement, forwardRef, HTMLAttributes, ReactElement, ReactNode, useMemo } from 'react';
import { Typography, TypographyProps } from '../Typography';
import { useId } from '../util';
import styles from './FormControl.module.css';

/**
 * The props for the {@link FormControl} component.
 * @category Props
 */
export interface FormControlProps extends HTMLAttributes<HTMLInputElement> {
    fullWidth?: boolean;
    disabled?: boolean;
    width?: string | number;
    disableMargin?: boolean;
    disableMinWidth?: boolean;
    control: ReactElement;
    required?: boolean;
    label?: string | ReactNode;
    hintText?: string | ReactNode;
    labelProps?: TypographyProps;
    error?: string | ReactNode;
}

/**
 * @category Component
 * @group Input
 */
export const FormControl = forwardRef(
    (
        {
            id: idProp,
            className,
            style,
            disabled,
            required,
            fullWidth,
            width,
            disableMinWidth,
            disableMargin,
            label,
            labelProps = {},
            hintText,
            control,
            error,
        }: FormControlProps,
        ref: any
    ) => {
        const id = useId(control?.props?.id ?? idProp);

        const { className: labelClassName, ...otherLabelProps } = labelProps;

        const controlProps = useMemo(() => {
            const result: any = {
                id,
            };

            if (disabled) {
                result.disabled = disabled;
            }

            return result;
        }, [disabled, id]);

        return (
            <label
                ref={ref}
                className={cx(
                    styles.container,
                    {
                        [styles.fullWidth]: fullWidth,
                        [styles.margin]: !disableMargin,
                        [styles.minWidth]: !disableMinWidth,
                    },
                    className
                )}
                htmlFor={id}
                style={{ ...style, width }}
            >
                {label && (
                    <Typography
                        component="div"
                        variant="caption"
                        color={error ? 'error' : 'textPrimary'}
                        className={cx(styles.label, labelClassName)}
                        {...otherLabelProps}
                    >
                        {label}
                        {required && (
                            <Typography component="span" variant="inherit" color="error">
                                {' '}
                                *
                            </Typography>
                        )}
                    </Typography>
                )}
                {cloneElement(control, controlProps)}
                {error && (
                    <Typography variant="body2" color="error">
                        {error}
                    </Typography>
                )}
                {hintText && (
                    <Typography component="div" variant="caption" color="textSecondary">
                        {hintText}
                    </Typography>
                )}
            </label>
        );
    }
);
