import { Typography } from '@mui/material';

export function ErrorComponent({ error }) {
  return (
    <>
      <Typography variant="h6">An error occurred: </Typography>
      <code>{error.message}</code>
    </>
  );
}
