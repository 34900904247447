import cx from 'classnames';
import React, {
    cloneElement,
    forwardRef,
    ForwardRefExoticComponent,
    memo,
    RefAttributes,
    useCallback,
    useContext,
    useMemo,
    useRef
} from 'react';
import {
    Draggable,
    DraggableChildrenFn,
    DraggableProvidedDragHandleProps,
    DraggableStateSnapshot
} from 'react-beautiful-dnd';
import { unstable_batchedUpdates } from 'react-dom';

import { ClickAwayListener } from '../../ClickAwayListener';
import { ContextMenu, ContextMenuContentProps } from '../../ContextMenu';
import { useForkRef } from '../../util';

import { makePreventSelectionOnShift } from '../../DataGrid/hooks/selectRowHook';
import { createDoubleClickHandler } from '../../DataGrid/hooks/useDoubleClick';

import { serviceColumns } from '../../DataGrid/utils/serviceColumns';
import { TableRow, TableRowProps } from '../../Table/TableRow';
import { DataGridDragIndicator, DataGridInlineToolbar } from '../components';
import { DataGridContext } from '../DataGridContext';
import { DataGridCellProps, DataGridRowProps, DataGridSelectionSettings } from '../types';
import { defaultDataGridCellWrapper, RenderDataGridCell } from './RenderCell';

export interface DataGridRowRendererProps<T extends object = any> extends TableRowProps {
    dragHandleProps?: DraggableProvidedDragHandleProps;
    dragSnapshot?: DraggableStateSnapshot;
    index?: number;
    row: DataGridRowProps<T>;
    style?: object;
}

export type DataGridRowRenderer = ForwardRefExoticComponent<
    React.PropsWithoutRef<DataGridRowRendererProps> & RefAttributes<TableRowProps>
>;

interface RenderDataGridRowContentProps {
    row: DataGridRowProps;
    selected: boolean;
    editing: boolean;
    dragHandleProps?: DataGridRowRendererProps['dragHandleProps'];
    dragSnapshot?: DataGridRowRendererProps['dragSnapshot'];
}

export const RenderDataGridRowContent = memo(function RenderDataGridRowContent({
    row,
    selected,
    dragSnapshot,
    dragHandleProps
}: RenderDataGridRowContentProps) {
    const instance = useContext(DataGridContext);

    const { settings, sideEffects } = instance;
    const { blockLayout, CellWrapper = defaultDataGridCellWrapper, InlineToolbar } = settings;

    const Cell = useMemo(() => CellWrapper(RenderDataGridCell), [CellWrapper]);

    return (
        <>
            {selected && dragSnapshot?.isDragging ? <DataGridDragIndicator /> : null}
            {row.cells.map((cell: DataGridCellProps, index) => (
                <Cell
                    dragHandleProps={cell.column.id === serviceColumns.dragHandle ? dragHandleProps : null}
                    key={index}
                    index={index}
                    row={row}
                    cell={cell}
                />
            ))}
            {!sideEffects && InlineToolbar && (
                <DataGridInlineToolbar blockLayout={blockLayout} index={row.index} Items={InlineToolbar} row={row} />
            )}
        </>
    );
});

export const RenderDataGridRow: DataGridRowRenderer = forwardRef<any, DataGridRowRendererProps>(
    ({ dragHandleProps, dragSnapshot, row, selected: selectedProp, style: styleProp = null, ...other }, ref: any) => {
        const rowRef = useRef();
        const resolvedRef = useForkRef(rowRef, ref);
        const instance = useContext(DataGridContext);

        const { settings, form } = instance;
        const {
            blockLayout,
            selection,
            rowHeight,
            inlineEdit,
            contextMenu,
            onView,
            onEdit,
            onInlineEdit,
            RowComponent = TableRow
        } = settings;

        const style = {
            height: rowHeight,
            ...styleProp
        };

        let rowProps: any = row.getRowProps({
            style,
            ...other
        });

        const selected = selectedProp ?? row.getIsSelected?.() ?? false;

        const viewHandler = useCallback(
            (event) => {
                if (typeof onView === 'function') {
                    onView(row.original, event);
                }
            },
            [row, onView]
        );

        const editHandler = useCallback(
            (event) => {
                if (typeof onEdit === 'function') {
                    onEdit(row.original, event);
                }
            },
            [row, onEdit]
        );

        const inlineEditHandler = useCallback(() => {
            row.startInlineEdit();
        }, [row]);

        const saveHandler = useCallback(
            async (event) => {
                const result = await form.trigger();
                if (!result) return;

                const values = form.watch(`row[${row.index}]`);

                unstable_batchedUpdates(() => {
                    if (onInlineEdit) {
                        const cancel = onInlineEdit(row.original, values, event);
                        if (((cancel as boolean) ?? true).toString() === 'false') {
                            return;
                        }
                    }
                    row.stopInlineEdit();
                });
            },
            [form, row, onInlineEdit]
        );

        let doubleClickPrefire = undefined;

        if (selection && !(selection as DataGridSelectionSettings).showColumn) {
            rowProps = {
                ...rowProps,
                onClick: row.clickSelectRow
            };

            doubleClickPrefire = makePreventSelectionOnShift(instance);
        }

        if (inlineEdit && row.setState && typeof onInlineEdit === 'function') {
            rowProps = {
                ...rowProps,
                onClick: createDoubleClickHandler(rowProps.onClick, inlineEditHandler, undefined, doubleClickPrefire)
            };
        } else if (typeof onEdit === 'function') {
            rowProps = {
                ...rowProps,
                onClick: createDoubleClickHandler(rowProps.onClick, editHandler, undefined, doubleClickPrefire)
            };
        } else if (typeof onView === 'function') {
            rowProps = {
                ...rowProps,
                onClick: createDoubleClickHandler(rowProps.onClick, viewHandler, undefined, doubleClickPrefire)
            };
        }

        let rowChild = (
            <RowComponent ref={resolvedRef} key={row.index} blockLayout={blockLayout} {...rowProps} selected={selected}>
                <RenderDataGridRowContent
                    row={row}
                    editing={!!row?.state?.isEditing}
                    selected={selected}
                    dragHandleProps={dragHandleProps}
                    dragSnapshot={dragSnapshot}
                />
            </RowComponent>
        );

        if (!row.state?.isEditing && contextMenu && (contextMenu?.type?.isRenderContextMenu?.(instance, row) ?? true)) {
            const menuProps = {
                row,
                data: row.original
            } as ContextMenuContentProps;

            rowChild = (
                <ContextMenu key={row.index} menu={cloneElement(contextMenu, menuProps)}>
                    {rowChild}
                </ContextMenu>
            );
        }

        if (inlineEdit && row.state?.isEditing && row.setState) {
            rowChild = (
                <ClickAwayListener key={row.index} onClickAway={saveHandler}>
                    {rowChild}
                </ClickAwayListener>
            );
        }

        return rowChild;
    }
);

export const RenderDataGridSortableRow = forwardRef(
    ({ className, row, index, style }: DataGridRowRendererProps, ref) => {
        const chidlren: DraggableChildrenFn = useCallback(
            (provided, snapshot) => {
                // eslint-disable-next-line react-hooks/rules-of-hooks
                const resolveRef = useForkRef(ref, provided.innerRef);

                return (
                    <RenderDataGridRow
                        ref={resolveRef as any}
                        row={row}
                        dragHandleProps={provided.dragHandleProps}
                        dragSnapshot={snapshot}
                        {...provided.draggableProps}
                        className={cx(provided.draggableProps, className)}
                        style={{
                            ...style,
                            ...provided.draggableProps.style
                        }}
                    />
                );
            },
            [className, ref, row, style]
        );

        const key = Object.values(row.values).filter(Boolean).join(',');

        const { settings } = useContext(DataGridContext);
        const { dragKey, dragAndDrop } = settings;
        const isDragDisabled = (dragKey && row.original[dragKey]) || !dragAndDrop.allow;

        return (
            <Draggable isDragDisabled={isDragDisabled} draggableId={row.id.toString()} index={index} key={key}>
                {chidlren}
            </Draggable>
        );
    }
);

export const defaultDataGridRowWrapper = function DefaultDataGridRowWrapper(rowRenderer: DataGridRowRenderer) {
    return rowRenderer;
};
