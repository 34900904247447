import cx from 'classnames';
import React, { Component, createContext, createRef, forwardRef, HTMLProps, MutableRefObject, Ref } from 'react';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import { Accordion } from '.';
import { DragEndHandler } from '../util';
import styles from './Accordion.module.css';

/**
 * The context type for the {@link AccordionGroup} component
 */
export type AccordionGroupInstance = {
    enableDragAndDrop: boolean;
    register: (accordion, value) => void;
    notify: (event, accordion, value) => void;
};

/**
 * The context produced by the {@link AccordionGroup} component
 */
export const AccordionGroupContext = createContext<AccordionGroupInstance>(null as any);

/**
 * The props for the {@link AccordionGroup} component
 */
interface AccordionGroupProps {
    children: any;
    enableDragAndDrop?: boolean;
    className?: string;
    onDrop?: DragEndHandler;
    onChange?: (value: boolean) => void;
}

/**
 * @category Component
 * @group Accordion
 */
const AccordionGroupBody = forwardRef(
    ({ className, children, ...other }: HTMLProps<HTMLDivElement>, ref: Ref<HTMLDivElement>) => {
        return (
            <div ref={ref} className={cx(styles.group, className)} {...other}>
                {children}
            </div>
        );
    }
);

/**
 * @category Component
 * @group Accordion
 */
const AccordionGroupDroppableBody = ({ onDragEnd, children, ...other }) => {
    return (
        <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="accordionGroup">
                {(provided) => (
                    <AccordionGroupBody ref={provided.innerRef} {...provided.droppableProps} {...other}>
                        {children}
                        {provided.placeholder}
                    </AccordionGroupBody>
                )}
            </Droppable>
        </DragDropContext>
    );
};

/**
 * AccordionGroup is a container for Accordion components. It allows for drag and drop reordering of the Accordion components and also control over the open state of all Accordion components.
 * @category Component
 * @group Accordion
 */
export class AccordionGroup extends Component<AccordionGroupProps> {
    accordions = createRef() as MutableRefObject<[Accordion, boolean][]>;
    onChangeHandlers: Array<(value: boolean) => void>;

    constructor(props) {
        super(props);
        this.accordions.current = [];
        this.onChangeHandlers = [];
    }

    handleChange = (accordion = null, value = null) => {
        const { onChange } = this.props;

        if (accordion) {
            const status = this.accordions.current.find(([x]) => x === accordion);
            if (status) {
                status[1] = value;
            }
        }

        const anyExpanded = this.accordions.current.reduce((acc, [, expanded]) => acc || expanded, false);
        if (onChange) {
            onChange(anyExpanded);
        }
        if (this.onChangeHandlers.length) {
            this.onChangeHandlers.forEach((h) => h(anyExpanded));
        }
    };

    handleDragEnd = (result, provided) => {
        const { onDrop } = this.props;
        if (onDrop) {
            onDrop(result, provided);
        }
    };

    register = (accordion, value) => {
        this.accordions.current.push([accordion, value]);
    };

    onChange = (event, accordion, value) => {
        if (!event) {
            return;
        }

        this.handleChange(accordion, value);
    };

    expand = () => {
        this.accordions.current = this.accordions.current.map(([accordion]) => {
            accordion.expand();
            return [accordion, true];
        });
        this.handleChange();
    };

    expandItem = (index) => {
        const status = this.accordions.current[index];
        if (status) {
            const [accordion] = status;
            accordion.expand();
            this.handleChange(accordion, true);
        }
    };

    collapse = () => {
        this.accordions.current = this.accordions.current.map(([accordion]) => {
            accordion.collapse();
            return [accordion, false];
        });
        this.handleChange();
    };

    collapseItem = (index) => {
        const status = this.accordions.current[index];
        if (status) {
            const [accordion] = status;
            accordion.collapse();
            this.handleChange(accordion, false);
        }
    };

    addOnChange = (handler) => {
        this.onChangeHandlers.push(handler);
    };

    removeOnChange = (handler) => {
        const handlerIndex = this.onChangeHandlers.indexOf(handler);
        if (handlerIndex >= 0) {
            this.onChangeHandlers.splice(handlerIndex, 1);
        }
    };

    render() {
        const { enableDragAndDrop, children: childrenProp, onDrop, onChange, ...other } = this.props;

        const children = enableDragAndDrop ? (
            <AccordionGroupDroppableBody onDragEnd={this.handleDragEnd} {...other}>
                {childrenProp}
            </AccordionGroupDroppableBody>
        ) : (
            <AccordionGroupBody {...other}>{childrenProp}</AccordionGroupBody>
        );

        return (
            <AccordionGroupContext.Provider
                value={{
                    enableDragAndDrop,
                    register: this.register,
                    notify: this.onChange,
                }}
            >
                {children}
            </AccordionGroupContext.Provider>
        );
    }
}
