import { useMemo } from 'react';
import { componentName, isXAxis, numTicksForHeight, numTicksForWidth, propOrFallback } from './chartUtils';

export function useAxisGrids(AxisGroups, groups, innerHeight, innerWidth) {
    return useMemo(
        () =>
            Object.fromEntries(
                groups.map((group) => {
                    const axis = AxisGroups[group];
                    return [
                        group,
                        axis.map((axis) => {
                            const name = componentName(axis);

                            const numTicks = isXAxis(name)
                                ? numTicksForWidth(innerWidth)
                                : numTicksForHeight(innerHeight);

                            return {
                                numTicks: propOrFallback(axis?.props, 'numTicks', numTicks),
                                gridValues: axis?.props?.tickValues ? axis.props.tickValues : null,
                            };
                        }),
                    ];
                })
            ),
        [AxisGroups, groups, innerHeight, innerWidth]
    );
}
