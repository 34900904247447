import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'store';
import { getWellPressureSnapshot } from 'store/wellPressure/utils/getWellPressureSnapshot';
import { makeWellPressureStatus } from 'store/wellPressure/wellPressureSelectors';
import { selectDeactivatedTimestepsChanged } from '../CasesSelector';

export const wellPressureCaseStateSelector = (state: RootState) => state.wellPressureCase.present;
export const previousWellPressureCaseStateSelector = (state: RootState) => state.wellPressureCase.past;
export const wellPressureCaseSnapshotSelector = createSelector(
  [wellPressureCaseStateSelector, (_state, searchQuery?: string) => searchQuery],
  (wellPressure, searchQuery) => getWellPressureSnapshot(wellPressure, searchQuery)
);
export const wellPressureCaseStatusSelector = createSelector(
  [wellPressureCaseStateSelector, selectDeactivatedTimestepsChanged],
  (state, deactivatedTimesteps) => makeWellPressureStatus(state, deactivatedTimesteps)
);
