import { AnyAction, createSlice, PayloadAction, Reducer } from '@reduxjs/toolkit';
import { castDraft } from 'immer';
import { toast } from 'react-toastify';
import undoable, { GroupByFunction, includeAction, StateWithHistory } from 'redux-undo';
import { handleError, handleLoading } from 'store/base/baseStateHandlers';
import { getWellPressure } from './actions/getWellPressure';
import { saveWellPressure, sealWellPressureChanges } from './actions/saveWellPressure';
import { CopySimulationCaseType } from './types/CopySimulationCaseType';
import { convertWellPressureData, WellPressureTableRow } from './utils/convertWellPressureData';
import { copyWellPressureColumnsAction, pasteWellPressureColumns } from './utils/copyPasteWellPressure';
import {
  ChangeWellPressureDeclinePayload,
  ChangeWellPressureInputPayload,
  setWellPressureDeclineBulkChange,
  setWellPressureDeclineChange,
  setWellPressureInputBulkChange,
  setWellPressureInputChange
} from './utils/wellPressureStatusChange';
import { WellPressureState } from './WellPressureState';

const initialState: WellPressureState = {
  splitableWeekDateIds: new Set(),
  pressureDeclineChanges: new Map(),
  reservoirPressureChanges: new Map(),
  inputChanges: new Map()
};

const { actions, reducer } = createSlice({
  initialState,
  name: 'wellPressure',
  reducers: {
    reset(state) {
      state.pressureDeclineChanges.clear();
      state.reservoirPressureChanges.clear();
      state.inputChanges.clear();
    },
    setWellPressureInput(state, action: PayloadAction<ChangeWellPressureInputPayload>) {
      setWellPressureInputChange(state, action.payload);
    },
    setWellPressureInputBulk(state, action: PayloadAction<ChangeWellPressureInputPayload[]>) {
      setWellPressureInputBulkChange(state, action.payload, false);
    },
    setWellPressureDecline(state, action: PayloadAction<ChangeWellPressureDeclinePayload>) {
      setWellPressureDeclineChange(state, action.payload);
    },
    setWellPressureDeclineBulk(state, action: PayloadAction<ChangeWellPressureDeclinePayload[]>) {
      setWellPressureDeclineBulkChange(state, action.payload, false);
    },
    copyWellPressureColumns(state, action: PayloadAction<number[]>) {
      copyWellPressureColumnsAction(state, state.rows, action.payload);
    },
    pasteColumns(state, action: PayloadAction<number[]>) {
      pasteWellPressureColumns(state, action.payload);
    },
    copySimulationCaseWellPressure(state, action: PayloadAction<CopySimulationCaseType<WellPressureTableRow>>) {
      try {
        const { rows, columns, targetWeekDateIds } = action.payload;
        copyWellPressureColumnsAction(state, rows, columns);
        pasteWellPressureColumns(state, targetWeekDateIds, false);
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);
        toast.error(`Pressure: ${error instanceof Error ? error.message : 'Error on transfer data'}`);
      }
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getWellPressure.pending, (state, action) => {
        state.arg = castDraft(action.meta.arg);
        handleLoading(state);
      })
      .addCase(getWellPressure.fulfilled, (state, action) => {
        state.arg = castDraft(action.meta.arg);
        state.loading = false;
        state.data = action.payload.items;
        const { rows, dates, rowsLookup, datesByWeekNumber, splitableWeekDateIds, datesLookup } =
          convertWellPressureData(action.payload.items, action.payload.adjustWeekDates);
        state.rows = rows;
        state.dates = dates;
        state.datesById = datesLookup;
        state.datesByWeekNumber = datesByWeekNumber;
        state.splitableWeekDateIds = splitableWeekDateIds;
        state.rowsLookup = rowsLookup;
      })
      .addCase(getWellPressure.rejected, (state, action) => {
        state.arg = undefined;
        handleError(state, action);
      });

    builder.addCase(saveWellPressure.fulfilled, (state) => {
      sealWellPressureChanges(state);
      state.pressureDeclineChanges.clear();
      state.reservoirPressureChanges.clear();
      state.inputChanges.clear();
    });
  }
});

export const wellPressureActions = actions;
export const { reset, setWellPressureDecline, setWellPressureInput, copyWellPressureColumns, pasteColumns } = actions;

const groupBy: GroupByFunction<WellPressureState, AnyAction> = (action) => {
  if (action.type === getWellPressure.fulfilled.type) {
    return 'well-pressure-data';
  }

  return null;
};

export const getWellPressureUndoableReducer = (
  reducer: Reducer<typeof initialState>
): Reducer<StateWithHistory<WellPressureState>, AnyAction> =>
  undoable(reducer, {
    ignoreInitialState: true,
    groupBy,
    undoType: 'WELL_PRESSURE_UNDO',
    clearHistoryType: 'WELL_PRESSURE_UNDO_CLEAR',
    filter: includeAction([
      setWellPressureInput.type,
      setWellPressureDecline.type,
      getWellPressure.fulfilled.type,
      saveWellPressure.fulfilled.type,
      pasteColumns.type,
      reset.type
    ])
  });

export default getWellPressureUndoableReducer(reducer);
