import React, { forwardRef } from 'react';
import { SvgIcon } from '../SvgIcon';

export function createSvgIcon(path, displayName) {
    const Component = (props, ref) => (
        <SvgIcon data-testid={`${displayName}Icon`} ref={ref} {...props}>
            {path}
        </SvgIcon>
    );

    return React.memo(forwardRef(Component));
}
