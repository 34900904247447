import { Draft } from '@reduxjs/toolkit';
import { SchemeComponentType } from 'api';
import { SelectedCell } from 'store/official-inputs/types/SelectedCell';

import { OfficialInputState } from './../../OfficialInputState';
import {
  toggleConnectionStatusChange,
  toggleHeaderStatusChange,
  toggleLineupMeterStationStatusChange
} from './lineupStatusChanges';

export function copyLineupCellsAction(state: Draft<OfficialInputState>, rowsIds: string[], weekDates: number[]) {
  const statuses = new Map<string, Map<number, boolean>>();
  rowsIds
    .map((rowId) => {
      const row = state.lineup.rowsById.get(rowId);
      return row;
    })
    .filter(
      (row) =>
        row.rowType === SchemeComponentType.MeterStation ||
        row.rowType === SchemeComponentType.Header ||
        row.rowType === SchemeComponentType.Valve
    )
    .forEach((row) => {
      let rowClipboard = statuses.get(row.id);
      if (!rowClipboard) {
        rowClipboard = new Map();
        statuses.set(row.id, rowClipboard);
      }

      for (let i = 0; i < weekDates.length; i++) {
        const weekDate = weekDates[i];
        const status =
          row.rowType === SchemeComponentType.MeterStation || row.rowType === SchemeComponentType.Header
            ? row.parentStatuses.get(weekDate)
            : row.connectionStatuses.get(weekDate);
        const changedStatus = state.changes[row.rowType].get(row.id)?.get(weekDate);
        rowClipboard.set(i, changedStatus?.status ?? status?.status);
      }
    });

  const sourceRowIds = Array.from(statuses.keys());
  const hasConnectionInSource = sourceRowIds.some((sourceRowId) => {
    const sourceRow = state.lineup.rowsById.get(sourceRowId);
    return sourceRow?.rowType === SchemeComponentType.Valve;
  });
  const hasHeadersOrMSInSource = sourceRowIds.some((sourceRowId) => {
    const sourceRow = state.lineup.rowsById.get(sourceRowId);
    return sourceRow?.rowType === SchemeComponentType.Header || sourceRow?.rowType === SchemeComponentType.MeterStation;
  });

  if (hasConnectionInSource && hasHeadersOrMSInSource) {
    throw Error('Unable to copy parent and child items together.');
  }

  state.lineup.cellsClipboard = { weekDates, statuses };
}

export function pasteLineupCellsAction(state: Draft<OfficialInputState>, targetRowId: string, targetWeekDate: number) {
  const { weekDates: copiedWeekDates, statuses } = state.lineup.cellsClipboard;

  const targetRow = state.lineup.rowsById.get(targetRowId);
  const targetParentRowIndex = state.lineup.rows.findIndex((row) => row.id === targetRowId);
  const sourceRowIds = Array.from(statuses.keys());
  const newSelectedCells: SelectedCell[] = [];

  for (let j = 0; j < sourceRowIds.length; j++) {
    const status = statuses.get(sourceRowIds[j]);

    for (let i = 0; i < copiedWeekDates.length; i++) {
      const weekDate = targetWeekDate + i;
      const newStatus = status?.get(i);

      if (newStatus !== undefined) {
        const targetParentRow = state.lineup.rows[targetParentRowIndex + j];

        if (targetRow.parentId) {
          const targetConnectionParentRow = state.lineup.rowsById.get(targetRow.parentId);
          const [matchedSubRow] = targetConnectionParentRow.subRows.filter(
            (subRow) => targetRow.groupId === subRow.id
          );
          const targetConnectionRowIndex = matchedSubRow.subRows.findIndex((row) => row.id === targetRowId);
          const targetConnectionRow = matchedSubRow.subRows[targetConnectionRowIndex + j];

          if (targetConnectionRow) {
            newSelectedCells.push({
              rowId: targetConnectionRow.id,
              weekDate
            });
            toggleConnectionStatusChange(state, {
              rowId: targetConnectionRow.id,
              weekDate,
              value: newStatus,
              syncParentRow: true
            });
          }
        } else {
          const toggleAction = targetParentRow?.rowType === SchemeComponentType.MeterStation
            ? toggleLineupMeterStationStatusChange : toggleHeaderStatusChange;
          toggleAction(state, {
            rowId: targetParentRow.id,
            weekDate,
            value: newStatus,
            syncSubRows: !newStatus,
            syncWells: !newStatus
          });
          newSelectedCells.push({
            rowId: targetParentRow.id,
            weekDate
          });
        }
      }
    }
  }
  state.selectedCells = newSelectedCells;
}
