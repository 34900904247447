import { AnyAction, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SchemeComponentType } from 'api';
import { castDraft } from 'immer';
import undoable, { includeAction } from 'redux-undo';
import { handleError, handleLoading, handleSuccess } from 'store/base/baseStateHandlers';
import {
  copyLineupColumnsAction,
  pasteLineupColumnsAction
} from 'store/official-inputs/lineup/actions/copyPasteColumns';
import { setLineupStatusChanges } from 'store/official-inputs/lineup/actions/lineupStatusChanges';
import { sealLineupChanges } from 'store/official-inputs/lineup/actions/sealLineupChanges';
import { LineupTableRow } from 'store/official-inputs/lineup/types/LineupTableRow';
import { ToggleLineupStatusPayload } from 'store/official-inputs/lineup/types/ToggleStatusPayload';
import { convertLineupData } from 'store/official-inputs/lineup/utils/convertLineupData';
import { CopyPayload } from 'store/official-inputs/types/CopyPastPayload';
import {
  copyWellInputColumnsAction,
  pasteWellInputColumnsAction
} from 'store/official-inputs/wellInput/actions/copyPasteColumns';
import { sealWellInputChanges } from 'store/official-inputs/wellInput/actions/sealWellInputChanges';
import {
  setWellInputStatusChanges,
  setWellStatusBulkChange
} from 'store/official-inputs/wellInput/actions/wellInputStatusChanges';
import {
  SetWellStatusBulkPayload,
  ToggleWellInputStatusPayload
} from 'store/official-inputs/wellInput/types/ToggleStatusPayload';
import { convertWellInputData, WellInputTableRow } from 'store/official-inputs/wellInput/utils/convertWellInputData';
import { casesClearHistoryAction, casesUndoAction } from './actions/casesUndoAction';
import { getDeactivatedTimesteps, saveDeactivatedTimesteps } from './actions/deactivatedTimesteps';
import { saveCasesChanges } from './actions/saveCasesChanges';
import { CasesState } from './CasesState';
import { getLineupCase } from './lineup/actions/getLineupCase';
import { wellGorCaseActions } from './wellGor/wellGorCaseSlice';
import { getWellInputCase } from './wellInput/actions/getWellInputCase';
import { wellPICaseActions } from './wellPI/wellPICaseSlice';
import { wellPressureCaseActions } from './wellPressure/wellPressureCaseSlice';

const resetActions = [wellPressureCaseActions.reset.type, wellPICaseActions.reset.type, wellGorCaseActions.reset.type];

const initialState: CasesState = {
  changes: {
    [SchemeComponentType.Header]: new Map(),
    [SchemeComponentType.MeterStation]: new Map(),
    [SchemeComponentType.Valve]: new Map(),
    [SchemeComponentType.Well]: new Map(),
    [SchemeComponentType.Joint]: new Map()
  },
  lineup: { splitableWeekDateIds: new Set() },
  wellInput: { splitableWeekDateIds: new Set() },
  deactivatedTimesteps: new Map<number, boolean>()
};

const { actions, reducer } = createSlice({
  initialState,
  name: 'cases',
  reducers: {
    toggleLineupCaseStatus(state, action: PayloadAction<ToggleLineupStatusPayload>) {
      setLineupStatusChanges(state, action.payload);
    },
    toggleWellInputCaseStatus(state, action: PayloadAction<ToggleWellInputStatusPayload>) {
      setWellInputStatusChanges(state, action.payload);
    },
    setWellStatusBulkChange(state, action: PayloadAction<SetWellStatusBulkPayload>) {
      setWellStatusBulkChange(state, action.payload);
    },
    copyLineupCaseColumns(state, action: PayloadAction<CopyPayload<LineupTableRow>>) {
      copyLineupColumnsAction(state, action.payload.rows, action.payload.columns);
    },
    pasteLineupCaseColumns(state, action: PayloadAction<number[]>) {
      pasteLineupColumnsAction(state, action.payload);
    },
    copyWellInputCaseColumns(state, action: PayloadAction<CopyPayload<WellInputTableRow>>) {
      copyWellInputColumnsAction(state, action.payload.rows, action.payload.columns);
    },
    pasteWellInputCaseColumns(state, action: PayloadAction<number[]>) {
      pasteWellInputColumnsAction(state, action.payload);
    },
    reset(state) {
      state.changes.MeterStation.clear();
      state.changes.Header.clear();
      state.changes.Valve.clear();
      state.changes.Well.clear();
      state.deactivatedTimesteps.clear();
    },
    resetDeactivatedTimestep(state, action?: PayloadAction<Map<number, boolean>>) {
      state.deactivatedTimesteps = action.payload ?? new Map<number, boolean>();
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getLineupCase.pending, (state) => {
        handleLoading(state.lineup);
        state.lineup.rows = undefined;
      })
      .addCase(getLineupCase.fulfilled, (state, action) => {
        state.lineup.arg = castDraft(action.meta.arg);
        handleSuccess(state.lineup, action);
        const { rows, rowsById, dates, datesById } = convertLineupData(action.payload);
        state.lineup.rows = rows;
        state.lineup.rowsById = rowsById;
        state.lineup.dates = dates;
        state.lineup.datesById = datesById;
      })
      .addCase(getLineupCase.rejected, (state, action) => {
        state.lineup.arg = undefined;
        handleError(state.lineup, action);
      });

    builder
      .addCase(getWellInputCase.pending, (state) => {
        handleLoading(state.wellInput);
      })
      .addCase(getWellInputCase.fulfilled, (state, action) => {
        state.wellInput.arg = castDraft(action.meta.arg);
        handleSuccess(state.wellInput, action);
        const { rows, rowsById, dates, datesById } = convertWellInputData(action.payload);
        state.wellInput.rows = rows;
        state.wellInput.rowsById = rowsById;
        state.wellInput.dates = dates;
        state.wellInput.datesById = datesById;
      })
      .addCase(getWellInputCase.rejected, (state, action) => {
        state.wellInput.arg = undefined;
        handleError(state.wellInput, action);
      });

    builder.addCase(saveCasesChanges.fulfilled, (state) => {
      sealLineupChanges(state);
      sealWellInputChanges(state);

      state.changes.MeterStation.clear();
      state.changes.Header.clear();
      state.changes.Valve.clear();
      state.changes.Well.clear();
    });

    builder.addCase(getDeactivatedTimesteps.fulfilled, (state, action) => {
      state.deactivatedTimestepsDefault = new Map(action.payload.map((id) => [id, true]));
    });

    builder.addCase(saveDeactivatedTimesteps.fulfilled, (state) => {
      state.deactivatedTimestepsDefault = new Map([
        ...state.deactivatedTimestepsDefault,
        ...state.deactivatedTimesteps
      ]);
      state.deactivatedTimesteps.clear();
    });

    builder.addMatcher(
      (action: AnyAction) => resetActions.includes(action.type),
      (state) => {
        state.deactivatedTimesteps.clear();
      }
    );
  }
});

export const casesActions = actions;

const {
  toggleLineupCaseStatus,
  toggleWellInputCaseStatus,
  reset,
  pasteLineupCaseColumns,
  pasteWellInputCaseColumns,
  resetDeactivatedTimestep
} = actions;

export default undoable(reducer, {
  undoType: casesUndoAction.type,
  clearHistoryType: casesClearHistoryAction.type,
  filter: includeAction([
    getWellInputCase.fulfilled.type,
    getLineupCase.fulfilled.type,
    toggleLineupCaseStatus.type,
    toggleWellInputCaseStatus.type,
    reset.type,
    pasteLineupCaseColumns.type,
    pasteWellInputCaseColumns.type,
    saveCasesChanges.fulfilled.type,
    resetDeactivatedTimestep.type
  ])
});
