import { Draft } from '@reduxjs/toolkit';
import { WellGorClient, WellGorDto, WellGorInputDto } from 'api';
import { createAppAsyncThunk } from 'store/createAppAsyncThunk';
import { WellGorState } from '../WellGorState';
import { getWellGorSnapshot } from '../utils/getWellGorSnapshot';
import { wellGorPresentStateSelector } from '../wellGorSelectors';

const service = new WellGorClient();

export function getWellGorSaveState(wellGor: WellGorState): WellGorDto[] {
  const result: WellGorDto[] = [];

  const snapshot = getWellGorSnapshot(wellGor);

  for (const msRow of snapshot) {
    for (const wellRow of msRow.subRows) {
      const wellGorInputs = Array.from(wellRow.inputs.entries()).map(
        ([weekDateId, input]) => new WellGorInputDto({ weekDateId, gorValue: input.gorValue })
      );

      const newDto = new WellGorDto({
        id: wellRow.originalId,
        wellId: wellRow.wellId,
        gorValue: wellRow.gorValue,
        wellGorInputs
      });

      result.push(newDto);
    }
  }

  return result;
}

export const saveWellGor = createAppAsyncThunk('wellGor/save', (_: void, { getState }) => {
  const wellGor = wellGorPresentStateSelector(getState());

  const data = getWellGorSaveState(wellGor);

  return service.save(data);
});

export function sealWellGorChanges(state: Draft<WellGorState>) {
  for (const msRow of state.rows) {
    for (const wellRow of msRow.subRows) {
      const gorChange = state.gorChanges.get(wellRow.id);
      if (gorChange) {
        wellRow.gorValue = gorChange;
      }

      for (const [weekDay, status] of wellRow.inputs) {
        const inputChange = state.inputChanges.get(wellRow.id)?.get(weekDay);
        if (inputChange) {
          status.gorValue = inputChange.gorValue;
        }
      }
    }
  }
}
