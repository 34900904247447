import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { handleError, handleLoading } from 'store/base/baseStateHandlers';
import { UseAsInputsState } from './types/useAsInputsState';
import { SaveUseAsInputsDataParams, fetchUseAsInputsData } from './useAsInputsActions';

const initialState: UseAsInputsState = {
  MAIN: {}
};

type SetChangesParams = SaveUseAsInputsDataParams & {
  value: boolean;
};

const { actions, reducer } = createSlice({
  initialState,
  name: 'use-as-inputs',
  reducers: {
    setChanges(state, action: PayloadAction<SetChangesParams>) {
      const { valueAccessor, value, keyAccessor } = action.payload;
      state[keyAccessor].changes[valueAccessor] = value;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchUseAsInputsData.pending, (state, action) => {
        if (!state[action.meta.arg.keyAccessor]) state[action.meta.arg.keyAccessor] = {};
        handleLoading(state[action.meta.arg.keyAccessor]);
      })
      .addCase(fetchUseAsInputsData.rejected, (state, action) => {
        handleError(state[action.meta.arg.keyAccessor], action, true);
      })
      .addCase(fetchUseAsInputsData.fulfilled, (state, action) => {
        state[action.meta.arg.keyAccessor].loading = false;
        if (!state[action.meta.arg.keyAccessor].changes) {
          state[action.meta.arg.keyAccessor].changes = { ...action.payload };
        }
        state[action.meta.arg.keyAccessor].data = action.payload;
      });
  }
});

export const useAsInputsActions = actions;
export const useAsInputsReducer = reducer;
