import { AnyAction, createSlice, PayloadAction, Reducer } from '@reduxjs/toolkit';
import { castDraft } from 'immer';
import undoable, { includeAction, StateWithHistory } from 'redux-undo';
import { handleError, handleLoading } from 'store/base/baseStateHandlers';
import { convertWellPIData } from 'store/wellPI/utils/convertWellPIData';
import { copyWellPIColumns, pasteWellPIColumns } from 'store/wellPI/utils/copyPasteWellPI';
import {
  ChangeWellPIInputPayload,
  ChangeWellPIPayload,
  ChangeWellPIRatePayload,
  sealWellPIChanges,
  setWellPIChange,
  setWellPIInputChange,
  setWellPIRateChange
} from 'store/wellPI/utils/wellPIInputChange';
import { WellPIState } from 'store/wellPI/WellPIState';
import { getWellPICase, saveWellPICase, WellPICaseParams } from './actions/getWellPICase';

const initialState: WellPIState<WellPICaseParams> = { splitableWeekDateIds: new Set() };

const { actions, reducer } = createSlice({
  initialState,
  name: 'wellPICase',
  reducers: {
    reset(state) {
      state.rateChanges.clear();
      state.piChanges.clear();
      state.inputChanges.clear();
    },
    setWellPIInput(state, action: PayloadAction<ChangeWellPIInputPayload>) {
      setWellPIInputChange(state, action.payload);
    },
    setWellPIRate(state, action: PayloadAction<ChangeWellPIRatePayload>) {
      setWellPIRateChange(state, action.payload);
    },
    setWellPI(state, action: PayloadAction<ChangeWellPIPayload>) {
      setWellPIChange(state, action.payload);
    },
    copyColumns(state, action: PayloadAction<number[]>) {
      copyWellPIColumns(state, state.rows, action.payload);
    },
    pasteColumns(state, action: PayloadAction<number[]>) {
      pasteWellPIColumns(state, action.payload);
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getWellPICase.pending, (state, action) => {
        state.arg = castDraft(action.meta.arg);
        handleLoading(state);
      })
      .addCase(getWellPICase.fulfilled, (state, action) => {
        state.arg = castDraft(action.meta.arg);
        state.loading = false;
        state.data = action.payload.items;
        const { rows, dates } = convertWellPIData(action.payload.items, action.payload.adjustWeekDates);
        state.rows = rows;
        state.dates = dates;
        state.rateChanges = new Map();
        state.piChanges = new Map();
        state.inputChanges = new Map();
      })
      .addCase(getWellPICase.rejected, (state, action) => {
        state.arg = undefined;
        handleError(state, action);
      });

    builder.addCase(saveWellPICase.fulfilled, (state) => {
      sealWellPIChanges(state);
      state.rateChanges.clear();
      state.piChanges.clear();
      state.inputChanges.clear();
    });
  }
});

export const wellPICaseActions = actions;
export const { reset, setWellPIRate, setWellPIInput, copyColumns, pasteColumns } = actions;

export const WELL_PI_CASE_UNDO = 'WELL_PI_CASE_UNDO';
export const WELL_PI_CASE_UNDO_CLEAR = 'WELL_PI_CASE_UNDO_CLEAR';

export const getWellPICaseUndoableReducer = (
  reducer: Reducer<typeof initialState>
): Reducer<StateWithHistory<WellPIState<WellPICaseParams>>, AnyAction> =>
  undoable(reducer, {
    undoType: WELL_PI_CASE_UNDO,
    clearHistoryType: WELL_PI_CASE_UNDO_CLEAR,
    filter: includeAction([
      setWellPIInput.type,
      setWellPIRate.type,
      getWellPICase.fulfilled.type,
      saveWellPICase.fulfilled.type,
      pasteColumns.type,
      reset.type
    ]),
    debug: false
  });

export default getWellPICaseUndoableReducer(reducer);
