import { defaultStyles as defaultTooltipStyles, TooltipWithBounds } from '@visx/tooltip';
import React, { cloneElement, FC, useContext } from 'react';
import { ChartContext, ChartTooltipContext } from '../ChartContext';
import styles from './RenderTooltip.module.css';

/**
 * @category Component
 * @group Chart
 */
export const RenderTooltip: FC = () => {
    const instance = useContext(ChartContext);
    const tooltip = useContext(ChartTooltipContext);

    const { layers } = instance;

    return (
        <>
            {tooltip.tooltipOpen && layers.Tooltip && tooltip.tooltipData && (
                <TooltipWithBounds
                    className={styles.tooltip}
                    key={Math.random()}
                    top={tooltip.tooltipTop}
                    left={tooltip.tooltipLeft}
                    style={defaultTooltipStyles}
                >
                    {cloneElement(layers.Tooltip, tooltip.tooltipData) ?? null}
                </TooltipWithBounds>
            )}
        </>
    );
};
