import { Group } from '@visx/group';
import { Bar } from '@visx/shape';
import React, { CSSProperties, FC, memo, ReactElement, useCallback, useContext, useMemo } from 'react';
import { ChartContext } from '../ChartContext';
import { SeriesLabelComponent, SeriesOptions } from '../chartTypes';
import { FocusBlurHandler } from '../components/FocusBlurHandler';
import { callOrValue, ChartAccessor, isDefined, useAccessors } from '../utils';

export const defaultLabelProps = {
    pointerEvents: 'none',
    stroke: '#fff',
    strokeWidth: 2,
    paintOrder: 'stroke',
    fontSize: 12
};

const noEventsStyles: CSSProperties = { pointerEvents: 'none' };

export const BarSeriesLabel: SeriesLabelComponent = ({ datum, getY, labelProps }) => (
    <text {...labelProps}>{getY?.(datum)}</text>
);

type BarSeriesItem = object & {
    fill?: string;
    fillOpacity?: number;
    stroke?: string;
    strokeWidth?: number;
};

export interface BarSeriesProps<T extends BarSeriesItem> extends SeriesOptions<T> {
    horizontal?: boolean;

    fill: string | (() => string);
    fillOpacity?: number;
    stroke?: string | (() => string);

    strokeWidth?: number | (() => number);
    labelProps?: any;

    showZero?: boolean;
}

/**
 * @category Component
 * @group Chart
 */
let BarSeries = <T extends BarSeriesItem>(props: BarSeriesProps<T>) => {
    const {
        id,
        name,
        axis = 'undefined',
        disableMouseEvents = false,
        data,
        xAccessor,
        yAccessor,
        openAccessor,
        closeAccessor,
        showZero,
        renderLabel: RenderLabel,
        fill,
        fillOpacity,
        stroke,
        strokeWidth,
        labelProps = defaultLabelProps,
        onClick,
        onMouseMove,
        onMouseLeave,
        onContextMenu
    } = props;

    const {
        legend,
        xScales,
        yScales,
        getX: globalGetX,
        getY: globalGetY,
        handleClick: globalOnClick,
        handleMouseMove: globalOnMouseMove,
        handleMouseLeave: globalOnMouseLeave,
        handleContextMenu: globalOnContextMenu
    } = useContext(ChartContext);

    const xScale = useMemo(() => xScales[axis] ?? xScales.undefined ?? xScales.undefined, [axis, xScales]);
    const yScale = useMemo(() => yScales[axis] ?? yScales.undefined ?? yScales.undefined, [axis, yScales]);

    const { getX, getY } = useAccessors(xAccessor, globalGetX, yAccessor, globalGetY);
    const { getY: getOpen } = useAccessors(xAccessor, undefined, openAccessor, undefined);
    const { getY: getClose } = useAccessors(xAccessor, undefined, closeAccessor, undefined);

    const isStacked = getOpen && getClose;

    const x = useMemo(
        (): ChartAccessor<any> =>
            (...args) =>
                xScale?.(getX?.(...args)) ?? 0,
        [getX, xScale]
    );
    const y = useMemo(
        (): ChartAccessor<any> =>
            (...args) =>
                yScale?.(getY?.(...args)) ?? 0,
        [getY, yScale]
    );
    const open = useMemo(
        (): ChartAccessor<any> =>
            (...args) =>
                yScale?.(getOpen?.(...args)) ?? 0,
        [getOpen, yScale]
    );
    const close = useMemo(
        (): ChartAccessor<any> =>
            (...args) =>
                yScale?.(getClose?.(...args)) ?? 0,
        [getClose, yScale]
    );

    const handleClick = useCallback(
        (point: T, color: string, index: number, event) => {
            const args = { name, key: id, event, data, point, color, index, axis, getX, getY, options: props };
            if (onClick) {
                onClick(args);
            }

            if (globalOnClick) {
                globalOnClick(args);
            }
        },
        [axis, data, getX, getY, globalOnClick, id, name, onClick, props]
    );

    const handleMouseMove = useCallback(
        (point: T, color: string, index: number, event) => {
            const args = { name, key: id, event, data, point, color, index, axis, getX, getY, options: props };
            if (onMouseMove) {
                onMouseMove(args);
            }

            if (globalOnMouseMove) {
                globalOnMouseMove(args);
            }
        },
        [axis, data, getX, getY, globalOnMouseMove, id, name, onMouseMove, props]
    );

    const handleMouseLeave = useCallback(
        (point: T, color: string, index: number, event) => {
            const args = { name, key: id, event, data, point, color, index, axis, getX, getY, options: props };

            if (onMouseLeave) {
                onMouseLeave(args);
            }

            if (globalOnMouseLeave) {
                globalOnMouseLeave(args);
            }
        },
        [axis, data, getX, getY, globalOnMouseLeave, id, name, onMouseLeave, props]
    );
    const handleContextMenu = useCallback(
        (point: T, event: React.MouseEvent<SVGElement, MouseEvent>) => {
            const args = {
                name,
                key: id,
                event,
                data,
                axis,
                getX,
                getY,
                point,
                color: point.fill,
                options: props
            };

            if (onContextMenu) {
                onContextMenu(args);
            }

            if (globalOnContextMenu) {
                globalOnContextMenu(args);
            }
        },
        [axis, data, getX, getY, id, name, props]
    );

    if (!xScale || !yScale || legend.state[id]) return null;

    const barWidth = xScale.barWidth;

    const minValue = Math.min(...yScale.domain());
    const middle = yScale(minValue < 0 ? 0 : minValue);

    const barPadding = xScale.barPadding ?? 0;
    const barOffset = xScale.barOffset ?? 0;
    const barIndex = xScale?.barIndexes?.[axis]?.[id] ?? 0;
    const barPositionOffset = (xScale.barWidth ?? 0) * barIndex;

    const Labels: ReactElement[] = []; // Labels on top

    return (
        <Group style={disableMouseEvents ? noEventsStyles : undefined}>
            {(data ?? []).map((point, index) => {
                const barPosition = x?.(point) + barPositionOffset + barPadding + barOffset;
                const barLength = isStacked ? open?.(point) - close?.(point) : middle - y?.(point);
                const color = point.fill || callOrValue(fill, point, index);
                const key = `bar-${barPosition}-${index}`;
                const left = barPosition;
                const top = isStacked ? close?.(point) : y?.(point);
                const width = barWidth;
                const height = barLength || (showZero ? 1 : 0);

                const labelX = left + (width ?? 0) / 2;
                const labelY = top + 0;

                if (RenderLabel) {
                    const Label = (
                        <RenderLabel
                            datum={point}
                            index={index}
                            getY={getY}
                            labelProps={{
                                key,
                                ...labelProps,
                                x: labelX,
                                y: labelY,
                                width: barWidth,
                                height,
                                textAnchor: 'middle',
                                fill: color
                            }}
                        />
                    );

                    if (Label) Labels.push(Label);
                }

                return (
                    isDefined(isDefined(getY?.(point))) && (
                        <FocusBlurHandler
                            key={key}
                            onBlur={disableMouseEvents ? undefined : handleMouseLeave.bind(null, point, color, index)}
                            onFocus={disableMouseEvents ? undefined : handleMouseMove.bind(null, point, color, index)}
                        >
                            <Bar
                                className="bar"
                                x={left}
                                y={top + Math.min(0, height)}
                                width={width}
                                height={Math.abs(height)}
                                fill={color}
                                fillOpacity={point.fillOpacity || callOrValue(fillOpacity, point, index)}
                                stroke={point.stroke || callOrValue(stroke, point, index)}
                                strokeWidth={point.strokeWidth || callOrValue(strokeWidth, point, index)}
                                onClick={disableMouseEvents ? undefined : handleClick.bind(null, point, color, index)}
                                onMouseMove={
                                    disableMouseEvents ? undefined : handleMouseMove.bind(null, point, color, index)
                                }
                                onMouseLeave={
                                    disableMouseEvents ? undefined : handleMouseLeave.bind(null, point, color, index)
                                }
                                onContextMenu={!disableMouseEvents && handleContextMenu.bind(null, point)}
                            />
                        </FocusBlurHandler>
                    )
                );
            })}
            {Labels.map((Label) => Label)}
        </Group>
    );
};

(BarSeries as FC).displayName = 'BarSeries';
BarSeries = memo(BarSeries);

/** @ignore */
export { BarSeries };
