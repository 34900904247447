import { P6Component } from 'api';

export const P6ComponentName: Record<P6Component, string> = {
  [P6Component.MeterStation]: 'Meter Station',
  [P6Component.Header]: 'Header',
  [P6Component.Pipeline]: 'Pipeline',
  [P6Component.Well]: 'Well'
};

export const p6Components = Object.values(P6Component).map((component) => ({
  id: component,
  name: P6ComponentName[component]
}));
