import { useMemo } from 'react';
import { useParams } from 'react-router-dom';

export const useScenarioCaseIdParams = () => {
  const params = useParams();

  return useMemo(() => {
    return { scenarioId: parseInt(params.scenarioId), caseId: parseInt(params.caseId) };
  }, [params.caseId, params.scenarioId]);
};
