import { Draft } from '@reduxjs/toolkit';
import { SchemeComponentType } from 'api';
import { LineupState } from '../lineupState';

import { OfficialInputState } from './../../OfficialInputState';
import {
  toggleConnectionStatusChange,
  toggleHeaderStatusChange,
  toggleLineupMeterStationStatusChange
} from './lineupStatusChanges';

export function copyLineupColumnsAction(
  state: Draft<OfficialInputState>,
  rows: LineupState['rows'],
  weekDates: number[]
) {
  const statuses = new Map<string, Map<number, boolean>>();

  for (const parentRow of rows) {
    let parentRowClipboard = statuses.get(parentRow.id);
    if (!parentRowClipboard) {
      parentRowClipboard = new Map();
      statuses.set(parentRow.id, parentRowClipboard);
    }

    for (let i = 0; i < weekDates.length; i++) {
      const weekDate = weekDates[i];
      const parentStatus = parentRow.parentStatuses.get(weekDate);
      const changedParentStatusSource = parentRow.rowType === SchemeComponentType.Header ? state.changes.Header : state.changes.MeterStation;
      const changedParentStatus = changedParentStatusSource.get(parentRow.id)?.get(weekDate);
      parentRowClipboard.set(i, changedParentStatus?.status ?? parentStatus?.status);
    }

    for (const childRow of parentRow.subRows) {
      for (const connectionRow of childRow.subRows) {
        let connectionRowClipboard = statuses.get(connectionRow.id);
        if (!connectionRowClipboard) {
          connectionRowClipboard = new Map();
          statuses.set(connectionRow.id, connectionRowClipboard);
        }

        for (let i = 0; i < weekDates.length; i++) {
          const weekDate = weekDates[i];
          const connectionStatus = connectionRow.connectionStatuses.get(weekDate);
          const changedConnectionStatus = state.changes.Valve.get(connectionRow.id)?.get(weekDate);
          connectionRowClipboard.set(i, changedConnectionStatus?.status ?? connectionStatus?.status);
        }
      }
    }
  }

  state.lineup.clipboard = { weekDates, statuses };
}

export function pasteLineupColumnsAction(state: Draft<OfficialInputState>, targetWeekDates: number[]) {
  const { weekDates: copiedWeekdates, statuses } = state.lineup.clipboard;
  const isPasteSingleColumn = copiedWeekdates.length === 1;

  if (!isPasteSingleColumn && copiedWeekdates.length !== targetWeekDates.length) {
    throw new Error('The number of copied columns does not match the number of pasted columns');
  }

  for (const parentRow of state.lineup.rows) {
    for (let i = 0; i < targetWeekDates.length; i++) {
      const sourceIndex = isPasteSingleColumn ? 0 : i;
      const weekDate = targetWeekDates[i];
      const newParentStatus = statuses.get(parentRow.id)?.get(sourceIndex);
      if (newParentStatus !== undefined) {
        if (parentRow.rowType === SchemeComponentType.MeterStation)
          toggleLineupMeterStationStatusChange(state, { rowId: parentRow.id, weekDate, value: newParentStatus });

        if (parentRow.rowType === SchemeComponentType.Header)
          toggleHeaderStatusChange(state, { rowId: parentRow.id, weekDate, value: newParentStatus });
      }
    }

    for (const childRow of parentRow.subRows) {
      for (const connectionRow of childRow.subRows) {
        for (let i = 0; i < targetWeekDates.length; i++) {
          const sourceIndex = isPasteSingleColumn ? 0 : i;
          const weekDate = targetWeekDates[i];
          const newConnectionStatus = statuses.get(connectionRow.id)?.get(sourceIndex);
          if (newConnectionStatus !== undefined) {
            toggleConnectionStatusChange(state, {
              rowId: connectionRow.id,
              weekDate,
              value: newConnectionStatus
            });
          }
        }
      }
    }
  }
}
