import { FormControl as MuiFormControl, FormHelperText, InputLabel } from '@mui/material';
import useId from '@mui/material/utils/useId';
import { FormControlProps } from '@tearecs/components/src/Input/FormControl';
import { cloneElement, forwardRef, Ref } from 'react';

export const FormControl = forwardRef(function FormControl(
  { id: idProp, error, label, control, disableMargin, disableMinWidth, ...other }: Omit<FormControlProps, 'color'>,
  ref: Ref<HTMLDivElement>
) {
  const id = useId(idProp);

  return (
    <MuiFormControl
      sx={{
        minWidth: !disableMinWidth && '100px'
      }}
      margin={disableMargin ? 'none' : 'normal'}
      ref={ref}
      error={!!error}
      {...other}
    >
      {label ? <InputLabel htmlFor={id}>{label}</InputLabel> : undefined}
      {cloneElement(control, { id })}
      {error ? <FormHelperText>{error}</FormHelperText> : undefined}
    </MuiFormControl>
  );
});
