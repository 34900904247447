import { AddFlagCommentDto, AddFlagDto, FlagsClient, FlagTab, GetFlagCommentDto } from 'api';
import { rootApi } from 'store/rootApi';
import { FlagComment } from './types';

const service = new FlagsClient();

export const flagsApi = rootApi
  .enhanceEndpoints({ addTagTypes: ['officialInputsFlags', 'officialInputsFlagComments'] })
  .injectEndpoints({
    endpoints: (builder) => ({
      getFlags: builder.query({
        queryFn: (flagTab: FlagTab) =>
          service
            .get(flagTab)
            .then((data) => ({ data }))
            .catch((error) => ({ error })),
        providesTags: (_result, _error, flagTab) => [{ type: 'officialInputsFlags', id: flagTab }]
      }),
      addFlag: builder.mutation({
        queryFn: (dto: AddFlagDto) =>
          service
            .post(dto)
            .then((data) => ({ data }))
            .catch((error) => ({ error })),
        invalidatesTags: (_result, _error, flag) => [{ type: 'officialInputsFlags', id: flag.flagTab }]
      }),
      deleteFlag: builder.mutation({
        queryFn: ({ flagId }: { flagId: number; flagTab: FlagTab }) =>
          service
            .deleteFlag(flagId)
            .then((data) => ({ data }))
            .catch((error) => ({ error })),
        invalidatesTags: (_result, _error, flag) => [{ type: 'officialInputsFlags', id: flag.flagTab }]
      }),
      getComment: builder.query({
        queryFn: (flagCommentId: number) =>
          service
            .getFlagComment(flagCommentId)
            .then((data) => ({
              data: {
                ...data,
                replyComments: [...data.replyComments].sort(
                  (a, b) => a.createEditTime.getTime() - b.createEditTime.getTime()
                )
              } as GetFlagCommentDto
            }))
            .catch((error) => ({ error })),
        providesTags: () => [{ type: 'officialInputsFlagComments', id: 'LIST' }]
      }),
      addComment: builder.mutation({
        queryFn: async (body: FlagComment) => {
          try {
            const commentId = await service.addFlagComment(new AddFlagCommentDto(body));
            const flagId = await service.post(new AddFlagDto({ ...body, flagCommentId: commentId }));

            return { data: { commentId, flagId } };
          } catch (error) {
            return { error };
          }
        },
        invalidatesTags: (_result, _error, flag) => [{ type: 'officialInputsFlags', id: flag.flagTab }]
      }),
      updateComment: builder.mutation({
        queryFn: async ({ comment, deletedReplyIds, username }: { comment: GetFlagCommentDto; deletedReplyIds: number[], username }) => {
          try {
            let commentId: number | null = null;
            if (comment.createdBy === username) {
              commentId = await service.addFlagComment(
                new AddFlagCommentDto({
                  id: comment.id,
                  text: comment.text
                })
              );
            }

            const replyIds = comment.replyComments
              .filter((reply) => reply.createdBy === username)
              .map(async (reply) => {
                await service.addFlagComment(
                  new AddFlagCommentDto({
                    id: reply.id,
                    text: reply.text,
                    parentFlagCommentId: comment.id
                  })
                );
              });

            for (const id of deletedReplyIds) {
              await service.deleteFlagComment(id);
            }

            return { data: { commentId, replyIds } };
          } catch (error) {
            return { error };
          }
        },
        invalidatesTags: () => [{ type: 'officialInputsFlagComments', id: 'LIST' }]
      }),
      toggleCommentResolve: builder.mutation({
        queryFn: ({ flagId }: { flagId: number; flagTab: FlagTab }) =>
          service
            .updateFlagStatus(flagId)
            .then((data) => ({ data }))
            .catch((error) => ({ error })),
        invalidatesTags: (_result, _error, flag) => [{ type: 'officialInputsFlags', id: flag.flagTab }]
      })
    })
  });
