import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IWedgeDto, WedgeFillType } from 'api/clients';
import orderBy from 'lodash/orderBy';
import { hackWedgeLineId } from 'pages/dashboard/outlook/utils/hackWedgeLineId';
import randomColor from 'randomcolor';
import { wedgeMeterStationApi } from 'store/dashboard-wedge-meterstation/dashboardWedgeMeterStationApi';
import { DashboardWedgeState, ResultByWeekDay } from './types/DashboardWedgeState';
import { TreeItem, WedgeInputCellPayload } from './types/WedgeInputCellPayload';
import { WedgePayloadTimestep } from './types/WedgeRow';
import { convertSelectedToDTOs } from './utils/convertSelectedToDTOs';
import { convertWedgeData } from './utils/convertWedgeData';

const initialState: DashboardWedgeState = {
  id: 0,
  color: randomColor({ count: 1, luminosity: 'light', format: 'rgba', alpha: 1 })[0],
  fillType: WedgeFillType.SOLID,
  loading: false,
  name: '',
  msAndWellList: [],
  selectedWellOrSingleMS: new Set<number>(),
  selectedWellDTOs: [],
  selectedMSDTOs: [],
  msTableWeekDateResults: new Map(),
  tableWeekDateResults: new Map()
};

const { reducer, actions } = createSlice({
  initialState,
  name: 'dashboard-wedge',
  reducers: {
    updateRow(state, { payload }: PayloadAction<WedgePayloadTimestep[]>) {
      const { tableWeekDateResults } = state;

      const weekDateIds = new Set<number>();

      for (const { value, weekIds } of payload) {
        for (const i of weekIds) {
          weekDateIds.add(i);
          tableWeekDateResults.set(i, {
            weekDateId: i,
            weekDate: tableWeekDateResults.get(i).weekDate,
            result: Math.round(value)
          });
        }
      }

      // resets those timestep are deleted in Datepicker
      tableWeekDateResults.forEach((_, key) => {
        if (!weekDateIds.has(key)) {
          tableWeekDateResults.set(key, {
            weekDateId: key,
            weekDate: tableWeekDateResults.get(key).weekDate,
            result: 0
          });
        }
      });
    },
    init(state, { payload }: PayloadAction<IWedgeDto>) {
      state.id = payload?.id ?? initialState.id;
      state.name = payload?.name ?? initialState.name;
      state.fillType = payload?.fillType ?? initialState.fillType;
      state.color = payload?.color ?? randomColor({ count: 1, luminosity: 'light', format: 'rgba', alpha: 1 })[0];
    },
    load(state) {
      state.loading = true;
    },
    updateWedge(
      state,
      {
        payload
      }: PayloadAction<{
        key: string;
        value: DashboardWedgeState['color'] | DashboardWedgeState['name'] | DashboardWedgeState['fillType'];
      }>
    ) {
      state[payload.key] = payload.value;
    },
    setSelectedWell(state, { payload }: PayloadAction<Set<number>>) {
      const { msAndWellList } = state;
      state.selectedWellOrSingleMS = payload;
      state.selectedWellDTOs = convertSelectedToDTOs(msAndWellList, payload).selectedWellDTOs;
      state.selectedMSDTOs = convertSelectedToDTOs(msAndWellList, payload).selectedMSDTOs;
    },
    updateTable(state, action: PayloadAction<IWedgeDto>) {
      state.tableWeekDateResults = convertWedgeData(action.payload);
    },
    updateCell(state, action: PayloadAction<WedgeInputCellPayload>) {
      const { tableWeekDateResults } = state;
      const { weekDateId, value } = action.payload;

      tableWeekDateResults.set(weekDateId, {
        weekDateId,
        weekDate: tableWeekDateResults.get(weekDateId).weekDate,
        result: Math.round(value)
      });
    },
    clearTable(state) {
      state.tableWeekDateResults.clear();
    },
    clear() {
      return initialState;
    }
  },
  extraReducers: (builder) => {
    builder.addMatcher(wedgeMeterStationApi.endpoints.getAll.matchFulfilled, (state, { payload }) => {
      if (payload?.length > 0) {
        const msAndWellListFromData: TreeItem[] = [];
        const selectedFromData = new Set<number>();
        msAndWellListFromData.push(
          ...payload.map((item) => ({
            id: hackWedgeLineId(item.id),
            label: item.name,
            children:
              item?.wedgeWells?.map((child) => ({
                id: child.id,
                label: child.name
              })) ?? []
          }))
        );
        payload.forEach((item) => {
          if (item.isSelected) {
            selectedFromData.add(hackWedgeLineId(item.id));
          }
          item?.wedgeWells?.forEach((well) => {
            if (well.isSelected) {
              selectedFromData.add(well.id);
            }
          });
        });

        state.msAndWellList = orderBy(msAndWellListFromData, [(ms) => ms.label]);
        state.selectedWellOrSingleMS = selectedFromData;
        state.selectedWellDTOs = convertSelectedToDTOs(msAndWellListFromData, selectedFromData).selectedWellDTOs;
        state.selectedMSDTOs = convertSelectedToDTOs(msAndWellListFromData, selectedFromData).selectedMSDTOs;
      }
    });
    builder.addMatcher(wedgeMeterStationApi.endpoints.getWedgeWellResults.matchFulfilled, (state, { payload }) => {
      const { msResults, wellResults } = payload;
      const tableWeekDateResults = new Map<number, ResultByWeekDay>();
      wellResults?.forEach((ms) => {
        ms?.wells?.forEach((well) => {
          orderBy(well?.results, 'weekDate').forEach((wellResult) => {
            if (tableWeekDateResults.has(wellResult.weekDateId)) {
              const newResult = tableWeekDateResults.get(wellResult.weekDateId).result + wellResult.result;
              tableWeekDateResults.set(wellResult.weekDateId, {
                weekDateId: wellResult.weekDateId,
                weekDate: wellResult.weekDate,
                result: Math.round(newResult)
              });
            } else {
              const newResult = wellResult.result;
              tableWeekDateResults.set(wellResult.weekDateId, {
                weekDateId: wellResult.weekDateId,
                weekDate: wellResult.weekDate,
                result: Math.round(newResult)
              });
            }
          });
        });
      });
      msResults?.forEach((ms) => {
        orderBy(ms?.results, 'weekDate').forEach((msResult) => {
          if (tableWeekDateResults.has(msResult.weekDateId)) {
            const newResult = tableWeekDateResults.get(msResult.weekDateId).result + msResult.result;
            tableWeekDateResults.set(msResult.weekDateId, {
              weekDateId: msResult.weekDateId,
              weekDate: msResult.weekDate,
              result: Math.round(newResult)
            });
          } else {
            const newResult = msResult.result;
            tableWeekDateResults.set(msResult.weekDateId, {
              weekDateId: msResult.weekDateId,
              weekDate: msResult.weekDate,
              result: Math.round(newResult)
            });
          }
        });
      });
      state.tableWeekDateResults = tableWeekDateResults;
      state.loading = false;
    });
  }
});

export const wedgeActions = actions;
export default reducer;
