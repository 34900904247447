import { localPoint } from '@visx/event';
import { Point } from '@visx/point';
import { useTooltip, useTooltipInPortal } from '@visx/tooltip';
import { useCallback, useMemo, useRef } from 'react';
import {
    ChartInstance,
    ChartMenuParams,
    ChartOptions,
    ChartTooltipParams,
    SeriesMouseEventArgs,
    TooltipData
} from '../chartTypes';
import {
    useAccessors,
    useAnnotations,
    useAxisGrids,
    useBrush,
    useChartDimensions,
    useDataFromChild,
    useGroupDimensions,
    useLayersFromChild,
    useLayersGroups,
    useLegend,
    useScalesFromChild
} from '../utils';
import { DEFAULT_CHART_MARGIN, DEFAULT_SCALE, DEFAULT_SIZE } from '../utils/chartUtils';
import { useChartMenu } from '../utils/useChartMenu';
import { useZoom } from '../utils/useZoom';

type UseChartReturnValue = {
    containerRef: (element: SVGElement | HTMLElement) => void;
    instance: ChartInstance;
    tooltip: ChartTooltipParams;
    contextMenu: ChartMenuParams;
};

export function useChart(options: ChartOptions): UseChartReturnValue {
    const {
        height = DEFAULT_SIZE.height,
        width = DEFAULT_SIZE.width,
        children,
        margin: marginProp = DEFAULT_CHART_MARGIN,
        xScale: xScaleProp,
        yScale: yScaleProp,
        xAccessor,
        yAccessor,
        contextMenu: providedContextMenu,
        snapTooltipToDataX,
        snapTooltipToDataY,
        onClick,
        onMouseMove,
        onMouseLeave
    } = options;

    const xAxisOrientationRef = useRef<string | null>(null);
    const yAxisOrientationRef = useRef<string | null>(null);

    const layers = useLayersFromChild(children);
    const { AxisGroups, SeriesGroups, groups } = useLayersGroups(layers.Axis, layers.Series, layers.Annotations);

    const { margin, innerWidth, innerHeight } = useChartDimensions(marginProp, width, height);

    const groupDimensions = useGroupDimensions(
        AxisGroups,
        groups,
        innerHeight,
        innerWidth,
        DEFAULT_SCALE,
        DEFAULT_SCALE
    );

    const axisGrid = useAxisGrids(AxisGroups, groups, innerHeight, innerWidth);

    const { getX, getY } = useAccessors(xAccessor, undefined, yAccessor);

    const legend = useLegend(layers.Series);

    const allData = useDataFromChild(children, legend, getX, getY);

    const brush = useBrush();
    const zoom = useZoom();

    const { xScales, yScales } = useScalesFromChild(
        allData,
        AxisGroups,
        groupDimensions,
        children,
        xScaleProp,
        yScaleProp,
        brush.state,
        zoom.transformMatrix
    );

    const { Annotations, AnnotationsGroups } = useAnnotations({
        allData,
        groupDimensions,
        Annotations: layers.Annotations,
        getX,
        getY,
        xScales,
        yScales,
        innerWidth,
        innerHeight
    });

    const tooltipTimeoutRef = useRef<any>(null);
    const tooltipRef = useRef<ChartTooltipParams | undefined>(undefined);

    const tooltip = useTooltip<TooltipData>();
    tooltipRef.current = tooltip;

    const { containerRef } = useTooltipInPortal({
        debounce: 200,
        detectBounds: true,
        scroll: true
    });

    const contextMenuRef = useRef<ChartMenuParams>();
    const contextMenu = useChartMenu();
    contextMenuRef.current = contextMenu;

    const getPointCoords = useCallback(
        ({ getX, getY, axis, point }: SeriesMouseEventArgs) => {
            const result: {
                x?: number;
                y?: number;
            } = {};

            const group = axis ?? 'undefined';
            const xScale = xScales[group] ?? xScales['undefined'];
            const yScale = yScales[group] ?? yScales['undefined'];

            // tooltip operates in full width/height space so we must account for margins
            if (point) {
                result.x = xScale(getX?.(point)) + margin.left;
                result.y = yScale(getY?.(point)) + margin.top;
            }

            return result;
        },
        [margin.left, margin.top, xScales, yScales]
    );

    const handleClick = useCallback(
        (args: SeriesMouseEventArgs) => {
            if (onClick) {
                const coords = getPointCoords(args);
                onClick({
                    ...args,
                    coords
                });
            }
        },
        [getPointCoords, onClick]
    );

    const handleContextMenu = useCallback(
        (args: SeriesMouseEventArgs) => {
            const { event } = args;

            if (providedContextMenu !== undefined) {
                event.preventDefault();

                contextMenuRef.current?.showContextMenu({ ...args, onClose: contextMenuRef.current?.hideContextMenu });
            }
        },
        [providedContextMenu]
    );

    const handleMouseDown = useCallback(() => {
        // if (this.fireBrushStart) {
        //   this.fireBrushStart(event);
        // }
    }, []);

    const handleMouseMove = useCallback(
        (args: SeriesMouseEventArgs) => {
            const coords = getPointCoords(args);

            if (onMouseMove) {
                onMouseMove({
                    ...args,
                    coords
                });
            }

            if (args.series == undefined && args.options?.hideFromTooltip) {
                return;
            }

            if (args.series && Object.values(args.series).every((series) => series.options?.hideFromTooltip)) {
                return;
            }

            if (tooltipTimeoutRef.current) {
                clearTimeout(tooltipTimeoutRef.current);
                tooltipTimeoutRef.current = null;
            }

            const isFocusEvent = args?.event?.type === 'focus';
            const tooltipCoords = {
                ...((isFocusEvent || snapTooltipToDataX) && { x: coords.x }),
                ...((isFocusEvent || snapTooltipToDataY) && { y: coords.y }),
                ...args.coords
            };

            let tooltipPoint: Point | null = null;
            if (args.event?.target && args.event?.type !== 'focus' && 'ownerSVGElement' in args.event.target) {
                tooltipPoint = localPoint((args.event.target as SVGElement).ownerSVGElement, args.event);
                //tooltipCoords.x += margin.left;
                //tooltipCoords.y += margin.top;
            }

            tooltipPoint = { ...tooltipPoint, ...(tooltipCoords as any) };

            tooltipRef.current?.showTooltip({
                tooltipLeft: tooltipPoint?.x,
                tooltipTop: tooltipPoint?.y,
                tooltipData: args as any
            });
        },
        [getPointCoords, onMouseMove, snapTooltipToDataX, snapTooltipToDataY]
    );

    const handleMouseLeave = useCallback(
        (event: SeriesMouseEventArgs) => {
            if (onMouseLeave) {
                onMouseLeave(event);
            }

            tooltipTimeoutRef.current = setTimeout(() => {
                tooltipRef.current?.hideTooltip();
            }, 200);
        },
        [onMouseLeave]
    );

    const handlePointMove = handleMouseMove;
    const handlePointClick = handleClick;
    const handlePointLeave = handleMouseLeave;

    const instance = useMemo(
        (): ChartInstance => ({
            ...options,
            xAxisOrientationRef,
            yAxisOrientationRef,
            margin,
            innerWidth,
            innerHeight,
            layers,
            AxisGroups,
            SeriesGroups,
            groups,
            groupDimensions,
            axisGrid,
            getX,
            getY,
            legend,
            allData,
            brush,
            zoom,
            xScales,
            yScales,
            Annotations,
            AnnotationsGroups,
            getPointCoords,
            handleClick,
            handleContextMenu,
            handleMouseDown,
            handleMouseMove,
            handleMouseLeave,
            handlePointMove,
            handlePointLeave,
            handlePointClick
        }),
        [
            Annotations,
            AnnotationsGroups,
            AxisGroups,
            SeriesGroups,
            allData,
            axisGrid,
            brush,
            zoom,
            getPointCoords,
            getX,
            getY,
            groupDimensions,
            groups,
            handleClick,
            handleContextMenu,
            handleMouseDown,
            handleMouseLeave,
            handleMouseMove,
            handlePointClick,
            handlePointLeave,
            handlePointMove,
            innerHeight,
            innerWidth,
            layers,
            legend,
            margin,
            options,
            xScales,
            yScales
        ]
    );

    return {
        containerRef,
        instance,
        tooltip,
        contextMenu
    };
}
