import { GlyphDot } from '@visx/glyph';
import { Group } from '@visx/group';
import React, { FC, memo, useCallback, useContext, useMemo } from 'react';
import { ChartContext } from '../ChartContext';
import { SeriesOptions } from '../chartTypes';
import { FocusBlurHandler } from '../components/FocusBlurHandler';
import './ScatterSeries.css';

import { callOrValue, ChartAccessor, findClosestPoint, isDefined, useAccessors } from '../utils';

export type ScatterSeriesItem = {
    label?: string;
    radius?: number;
    strokeWidth?: number;
    fill?: string | (() => string);

    stroke?: string | (() => string);
};

export interface ScatterSeriesProps<T extends ScatterSeriesItem> extends SeriesOptions<T> {
    radius?: number;
    strokeWidth?: number;
    fill?: string | (() => string);
    stroke?: string | (() => string);
    hoverFill?: string;
    hoverStroke?: string;
}

/**
 * @category Component
 * @group Chart
 */
let ScatterSeries: FC<ScatterSeriesProps<any>> = <T extends ScatterSeriesItem>(props: ScatterSeriesProps<T>) => {
    const {
        id,
        name,
        axis = 'undefined',
        disableMouseEvents = false,
        data,
        xAccessor,
        yAccessor,
        radius = 4,
        strokeWidth = 1,
        fill = 'black',
        stroke = '#FFFFFF',
        renderLabel: RenderLabelComponent,
        onClick,
        onContextMenu,
        onMouseMove,
        onMouseLeave,
        onPointClick,
        onPointMove,
        onPointLeave
    } = props;
    const {
        legend,
        margin,
        groupDimensions,
        xScales,
        yScales,
        getX: globalGetX,
        getY: globalGetY,
        handleMouseMove: globalOnMouseMove,
        handleMouseLeave: globalOnMouseLeave,
        handlePointClick: globalOnPointClick,
        handlePointMove: globalOnPointMove,
        handlePointLeave: globalOnPointLeave,
        handleContextMenu: globalOnContextMenu
    } = useContext(ChartContext);

    const strokeValue = callOrValue(stroke);

    const { getX, getY } = useAccessors(xAccessor, globalGetX, yAccessor, globalGetY);
    const group = axis ?? 'undefined';
    const dimensions = groupDimensions[group];
    const xScale = useMemo(() => xScales[axis] ?? xScales.undefined, [axis, xScales]);
    const yScale = useMemo(() => yScales[axis] ?? yScales.undefined, [axis, yScales]);

    const x = useMemo(
        (): ChartAccessor<any> =>
            (...args) =>
                xScale?.(getX?.(...args)) ?? 0,
        [getX, xScale]
    );

    const y = useMemo(
        (): ChartAccessor<any> =>
            (...args) =>
                yScale?.(getY?.(...args)) ?? 0,
        [getY, yScale]
    );

    const handleMouseMove = useCallback(
        (event: React.MouseEvent<SVGElement, MouseEvent>) => {
            const point = findClosestPoint({
                getX,
                event,
                xScale,
                marginLeft: margin.left,
                dimensions,
                ...props
            });

            const args = { name, key: id, event, data, axis, getX, getY, point, color: strokeValue, options: props };

            if (onMouseMove) {
                onMouseMove(args);
            }

            if (globalOnMouseMove) {
                globalOnMouseMove(args);
            }
        },
        [
            axis,
            data,
            dimensions,
            getX,
            getY,
            globalOnMouseMove,
            id,
            margin.left,
            name,
            onMouseMove,
            props,
            strokeValue,
            xScale
        ]
    );

    const handleMouseLeave = useCallback(
        (event: React.MouseEvent<SVGElement, MouseEvent>) => {
            const point = findClosestPoint({
                getX,
                event,
                xScale,
                marginLeft: margin.left,
                dimensions,
                ...props
            });

            const args = { name, key: id, event, data, axis, getX, getY, point, color: strokeValue, options: props };

            if (onMouseLeave) {
                onMouseLeave(args);
            }

            if (globalOnMouseLeave) {
                globalOnMouseLeave(args);
            }
        },
        [
            axis,
            data,
            dimensions,
            getX,
            getY,
            globalOnMouseLeave,
            id,
            margin.left,
            name,
            onMouseLeave,
            props,
            strokeValue,
            xScale
        ]
    );

    const handlePointClick = useCallback(
        (point: T, event: React.MouseEvent<SVGElement, MouseEvent>) => {
            const args = { name, key: id, event, data, axis, getX, getY, point, color: strokeValue, options: props };

            if (onClick) {
                onClick(args);
            }

            if (onPointClick) {
                onPointClick(args);
            }

            if (globalOnPointClick) {
                globalOnPointClick(args);
            }
        },
        [axis, data, getX, getY, globalOnPointClick, id, name, onClick, onPointClick, props, strokeValue]
    );

    const handleContextMenu = useCallback(
        (point: T, event: React.MouseEvent<SVGElement, MouseEvent>) => {
            const args = { name, key: id, event, data, axis, getX, getY, point, color: strokeValue, options: props };

            if (onContextMenu) {
                onContextMenu(args);
            }

            if (globalOnContextMenu) {
                globalOnContextMenu(args);
            }
        },
        [axis, data, getX, getY, id, name, props, strokeValue]
    );

    const handlePointMove = useCallback(
        (point: T, event: React.MouseEvent<SVGElement, MouseEvent>) => {
            const args = { name, key: id, event, data, axis, getX, getY, point, color: strokeValue, options: props };
            if (onPointMove) {
                onPointMove(args);
            }

            if (globalOnPointMove) {
                globalOnPointMove(args);
            }
        },
        [axis, data, getX, getY, globalOnPointMove, id, name, onPointMove, props, strokeValue]
    );

    const handlePointLeave = useCallback(
        (point: T, event: React.MouseEvent<SVGElement, MouseEvent>) => {
            const args = { name, key: id, event, data, axis, getX, getY, point, color: strokeValue, options: props };
            if (onPointLeave) {
                onPointLeave(args);
            }

            if (globalOnPointLeave) {
                globalOnPointLeave(args);
            }
        },
        [axis, data, getX, getY, globalOnPointLeave, id, name, onPointLeave, props, strokeValue]
    );

    if (!xScale || !yScale || legend.state[id]) return null;

    return (
        <Group left={(xScale.bandwidth?.() ?? 0) / 2}>
            <Group>
                {data.map((point, index) => {
                    const xData = getX?.(point);
                    const yData = getY?.(point);

                    if (isDefined(xData) && isDefined(yData)) {
                        const key = `${xData}${yData}${index}`;
                        const regFill = point.fill || callOrValue(fill, point, index);
                        const regStroke = point.stroke || callOrValue(stroke, point, index);
                        const r = point.radius || callOrValue(radius, point, index);
                        const RendelLabel = RenderLabelComponent ? (
                            <RenderLabelComponent
                                datum={point}
                                index={index}
                                getY={getY}
                                labelProps={{
                                    key,
                                    x: x?.(point),
                                    y: y?.(point),
                                    fill: regFill,
                                    radius
                                }}
                            />
                        ) : null;

                        return (
                            <FocusBlurHandler
                                key={key}
                                onBlur={!disableMouseEvents ? (handleMouseLeave as any) : undefined}
                                onFocus={!disableMouseEvents ? (handleMouseMove as any) : undefined}
                            >
                                <GlyphDot
                                    cx={x?.(point)}
                                    cy={y?.(point)}
                                    r={r}
                                    fill={regFill}
                                    className={!disableMouseEvents ? 'glyph-dot' : undefined}
                                    stroke={regStroke}
                                    strokeWidth={point.strokeWidth || callOrValue(strokeWidth, point, index)}
                                    onClick={!disableMouseEvents ? handlePointClick.bind(null, point) : undefined}
                                    onContextMenu={
                                        !disableMouseEvents ? handleContextMenu.bind(null, point) : undefined
                                    }
                                    onMouseMove={!disableMouseEvents ? handlePointMove.bind(null, point) : undefined}
                                    onMouseLeave={!disableMouseEvents ? handlePointLeave.bind(null, point) : undefined}
                                />
                                {RendelLabel}
                            </FocusBlurHandler>
                        );
                    }
                })}
            </Group>
        </Group>
    );
};

ScatterSeries.displayName = 'ScatterSeries';
ScatterSeries = memo(ScatterSeries);

/** @ignore */
export { ScatterSeries };
