import { FC, PropsWithChildren } from 'react';
import { Loader } from '../Loader';

interface LoadingContainerProps {
  loading?: boolean;
}

export const LoadingContainer: FC<PropsWithChildren<LoadingContainerProps>> = ({ loading, children }) => {
  if (loading) return <Loader />;

  return <>{children}</>;
};
