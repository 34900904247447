import { FC } from 'react';
import { Slide, ToastContainer } from 'react-toastify';
import './ToastifyContainer.css';

export const ToastifyContainer: FC = () => {
  return (
    <ToastContainer
      hideProgressBar
      closeOnClick
      pauseOnHover
      autoClose={4000}
      position="top-center"
      theme="colored"
      transition={Slide}
    />
  );
};
