import { useMemo } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { piHistorianApi } from 'store/piHistorian/piHistorianApi';
import { DEFAULT_MONTH_FILTER } from '../../utils/constants';

export function useMsPiHistorianData() {
  const [searchParams] = useSearchParams();
  const months = parseInt(searchParams.get('months') ?? DEFAULT_MONTH_FILTER);
  const { msId } = useParams();
  const parsedMsId = parseInt(msId);
  const { data, isLoading } = piHistorianApi.useGetMsPiHistorianQuery(
    { id: parsedMsId, months },
    {
      skip: isNaN(parsedMsId)
    }
  );

  const sortedData = useMemo(() => {
    if (!data) {
      return [];
    }
    return [...data].sort((a, b) => a.timestamp.getTime() - b.timestamp.getTime());
  }, [data]);

  return { data: sortedData, isLoading };
}
