import cx from 'classnames';

import React, { forwardRef, ReactNode } from 'react';
import { capitalize } from '../util';
import styles from './SvgIcon.module.css';

/**
 * The props for the {@link SvgIcon} component.
 * @category Props
 */
interface SvgIconProps extends React.HTMLProps<SVGElement> {
    component: any;
    children?: ReactNode;
    color?: 'inherit' | 'primary' | 'secondary' | 'action' | 'disabled' | 'error';
    fontSize?: 'inherit' | 'default' | 'small' | 'large';
    htmlColor?: string;
    shapeRendering?: string;
    titleAccess?: string;
    viewBox?: string;
}

/**
 * @category Component
 * @group SvgIcon
 */
export const SvgIcon = forwardRef(function SvgIcon(props: SvgIconProps, ref) {
    const {
        children,
        className,
        color = 'inherit',
        component: Component = 'svg',
        fontSize = 'default',
        htmlColor,
        titleAccess,
        viewBox = '0 0 24 24',
        ...other
    } = props;

    return (
        <Component
            className={cx(
                styles.root,
                {
                    [styles[`color${capitalize(color)}`]]: color !== 'inherit',
                    [styles[`fontSize${capitalize(fontSize)}`]]: fontSize !== 'default',
                },
                className
            )}
            focusable="false"
            viewBox={viewBox}
            color={htmlColor}
            aria-hidden={titleAccess ? undefined : true}
            role={titleAccess ? 'img' : undefined}
            ref={ref}
            {...other}
        >
            {children}
            {titleAccess ? <title>{titleAccess}</title> : null}
        </Component>
    );
});
