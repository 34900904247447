export const getPropOrDefault = <
  Key extends string | number,
  Value extends object,
  Obj extends Partial<Record<Key, Value>>,
  Target extends Obj[Key]
>(
  key: Key,
  obj: Obj,
  defaultObj = {} as Target
) => {
  let target = obj[key];

  if (!target) {
    target = defaultObj;
    obj[key] = target;
  }

  return target;
};
