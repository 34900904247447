import cx from 'classnames';
import React, { forwardRef, ReactNode } from 'react';
import { capitalize } from '../util';
import styles from './Button.module.css';
import { ButtonBase, ButtonBaseProps } from './ButtonBase';

/**
 * The props for the {@link Button} component
 */
export interface ButtonProps extends ButtonBaseProps {
    component?: any;
    children?: ReactNode;
    color?: 'inherit' | 'primary' | 'secondary' | 'error';
    disabled?: boolean;
    disableElevation?: boolean;
    endIcon?: ReactNode;
    fullWidth?: boolean;
    href?: string;
    size?: 'small' | 'medium' | 'large';
    startIcon?: ReactNode;
    variant?: 'text' | 'outlined' | 'contained';
}

/**
 * @category Component
 * @group Button
 */
export const Button = forwardRef<HTMLButtonElement, ButtonProps>(function Button(props, ref) {
    const {
        children,
        className,
        color = 'inherit',
        component = 'button',
        disabled = false,
        disableElevation = false,
        endIcon: endIconProp,
        fullWidth = false,
        size = 'medium',
        startIcon: startIconProp,
        type = 'button',
        variant = 'text',
        ...other
    } = props;

    const startIcon = startIconProp && (
        <span className={cx(styles.startIcon, styles[`iconSize${capitalize(size)}`])}>{startIconProp}</span>
    );

    const endIcon = endIconProp && (
        <span className={cx(styles.endIcon, styles[`iconSize${capitalize(size)}`])}>{endIconProp}</span>
    );

    return (
        <ButtonBase
            className={cx(
                styles.root,
                styles[variant],
                {
                    [styles[`${variant}${capitalize(color)}`]]: color !== 'inherit',
                    [styles[`${variant}Size${capitalize(size)}`]]: size !== 'medium',
                    [styles[`size${capitalize(size)}`]]: size !== 'medium',
                    [styles.disableElevation]: disableElevation,
                    [styles.disabled]: disabled,
                    [styles.fullWidth]: fullWidth,
                    [styles.colorInherit]: color === 'inherit',
                },
                className
            )}
            component={component}
            disabled={disabled}
            ref={ref}
            type={type}
            {...other}
        >
            <span className={styles.label}>
                {startIcon}
                {children}
                {endIcon}
            </span>
        </ButtonBase>
    );
});
