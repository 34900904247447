import cx from 'classnames';
import React, {
    cloneElement,
    ElementType,
    forwardRef,
    HTMLAttributes,
    ReactElement,
    useCallback,
    useEffect,
    useRef,
    useState
} from 'react';
import { BackdropProps } from '../Dialog/Backdrop';
import { Portal, PortalProps } from '../Tooltip';
import { createChainedFunction, ownerDocument, useEventCallback, useForkRef } from '../util';
import styles from './Modal.module.css';
import { ModalManager, ModalEntity } from './ModalManager';
import { SimpleBackdrop } from './SimpleBackdrop';

const manager = new ModalManager();

function getContainer(container) {
    return typeof container === 'function' ? container() : container;
}

function getHasTransition(props) {
    // eslint-disable-next-line no-prototype-builtins
    return props.children ? props.children.props.hasOwnProperty('in') : false;
}

/**
 * The props of the {@link Modal} component.
 * @category Props
 */
export interface ModalProps extends HTMLAttributes<HTMLDivElement> {
    BackdropComponent?: ElementType<BackdropProps>;
    BackdropProps?: Partial<BackdropProps>;
    children: ReactElement;
    closeAfterTransition?: boolean;
    container?: PortalProps['container'];
    disableAutoFocus?: boolean;
    disableBackdropClick?: boolean;
    disableEnforceFocus?: boolean;
    disableEscapeKeyDown?: boolean;
    disablePortal?: PortalProps['disablePortal'];
    disableRestoreFocus?: boolean;
    disableScrollLock?: boolean;
    hideBackdrop?: boolean;
    keepMounted?: boolean;
    onBackdropClick?: React.ReactEventHandler<{}>;
    onClose?: {
        bivarianceHack(event: {}, reason: 'backdropClick' | 'escapeKeyDown'): void;
    }['bivarianceHack'];
    onEscapeKeyDown?: React.ReactEventHandler<{}>;
    open: boolean;
}

/**
 * @category Component
 * @group Modal
 */
export const Modal = forwardRef(function Modal(props: ModalProps, ref) {
    const {
        className,
        BackdropComponent = SimpleBackdrop,
        BackdropProps,
        children,
        closeAfterTransition = false,
        container,
        disableAutoFocus = false,
        disableBackdropClick = false,
        disableEnforceFocus = false,
        disableEscapeKeyDown = false,
        disablePortal = false,
        disableRestoreFocus = false,
        disableScrollLock = false,
        hideBackdrop = false,
        keepMounted = false,
        onBackdropClick,
        onClose,
        onEscapeKeyDown,
        open,
        ...other
    } = props;
    const [exited, setExited] = useState(true);
    const modal = useRef<ModalEntity>({} as any);
    const mountNodeRef = useRef(null);
    const modalRef = useRef(null);
    const handleRef = useForkRef(modalRef, ref);
    const hasTransition = getHasTransition(props);

    const getDoc = () => ownerDocument(mountNodeRef.current);
    const getModal = () => {
        modal.current.modalRef = modalRef.current;
        modal.current.mountNode = mountNodeRef.current;
        return modal.current;
    };

    const handleMounted = () => {
        manager.mount(getModal(), { disableScrollLock });

        modalRef.current.scrollTop = 0;
    };

    const handleOpen = useEventCallback(() => {
        const resolvedContainer = getContainer(container) || getDoc().body;

        manager.add(getModal(), resolvedContainer);

        if (modalRef.current) {
            handleMounted();
        }
    });

    const isTopModal = useCallback(() => manager.isTopModal(getModal()), []);

    const handlePortalRef = useEventCallback((node) => {
        mountNodeRef.current = node;

        if (!node) {
            return;
        }

        if (open && isTopModal()) {
            handleMounted();
        } else {
            //ariaHidden(modalRef.current, true);
        }
    });

    const handleClose = useCallback(() => {
        manager.remove(getModal());
    }, []);

    useEffect(() => {
        return () => {
            handleClose();
        };
    }, [handleClose]);

    useEffect(() => {
        if (open) {
            handleOpen();
        } else if (!hasTransition || !closeAfterTransition) {
            handleClose();
        }
    }, [open, handleClose, hasTransition, closeAfterTransition, handleOpen]);

    if (!keepMounted && !open && (!hasTransition || exited)) {
        return null;
    }

    const handleEnter = () => {
        setExited(false);
    };

    const handleExited = () => {
        setExited(true);

        if (closeAfterTransition) {
            handleClose();
        }
    };

    const handleBackdropClick = (event) => {
        if (event.target !== event.currentTarget) {
            return;
        }

        if (onBackdropClick) {
            onBackdropClick(event);
        }

        if (!disableBackdropClick && onClose) {
            onClose(event, 'backdropClick');
        }
    };

    const handleKeyDown = (event) => {
        if (event.key !== 'Escape' || !isTopModal()) {
            return;
        }

        if (onEscapeKeyDown) {
            onEscapeKeyDown(event);
        }

        if (!disableEscapeKeyDown) {
            event.stopPropagation();

            if (onClose) {
                onClose(event, 'escapeKeyDown');
            }
        }
    };

    const childProps: any = {};
    if (children.props.tabIndex === undefined) {
        childProps.tabIndex = children.props.tabIndex || '-1';
    }

    // It's a Transition like component
    if (hasTransition) {
        childProps.onEnter = createChainedFunction(handleEnter, children.props.onEnter);
        childProps.onExited = createChainedFunction(handleExited, children.props.onExited);
    }

    return (
        <Portal ref={handlePortalRef} container={container} disablePortal={disablePortal}>
            <div
                className={cx(styles.root, { [styles.hidden]: !open && exited }, className)}
                ref={handleRef}
                onKeyDown={handleKeyDown}
                role="presentation"
                {...other}
            >
                {hideBackdrop ? null : (
                    <BackdropComponent open={open} onClick={handleBackdropClick} {...BackdropProps} />
                )}
                {cloneElement(children, childProps)}
            </div>
        </Portal>
    );
});
