import { Draft } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import { WellPressureState } from '../WellPressureState';
import { setWellPressureInputChange } from './wellPressureStatusChange';

export function copyWellPressureColumnsAction(
  state: Draft<WellPressureState>,
  rows: WellPressureState['rows'],
  weekDates: number[]
) {
  const inputs = new Map<string, Map<number, number>>();

  for (const msRow of rows) {
    for (const wellRow of msRow.subRows) {
      let clipboard = inputs.get(wellRow.id);
      if (!clipboard) {
        clipboard = new Map();
        inputs.set(wellRow.id, clipboard);
      }

      for (let i = 0; i < weekDates.length; i++) {
        const weekDate = weekDates[i];
        const input = wellRow.inputs.get(weekDate);
        const inputChange = state.inputChanges.get(wellRow.id)?.get(weekDate);
        clipboard.set(i, inputChange?.pressureValue ?? input?.pressureValue);
      }
    }
  }

  state.clipboard = { weekDates, inputs };
}

export function pasteWellPressureColumns(
  state: Draft<WellPressureState>,
  targetWeekDates: number[],
  calculateNestedChanges = true
) {
  const { weekDates: copiedWeekdates, inputs } = state.clipboard;
  const isPasteSingleColumn = copiedWeekdates.length === 1;
  const missedRows = new Set();
  if (!isPasteSingleColumn && copiedWeekdates.length !== targetWeekDates.length) {
    throw new Error('The number of copied columns does not match the number of pasted columns');
  }

  for (const msRow of state.rows) {
    for (const wellRow of msRow.subRows) {
      for (let i = 0; i < targetWeekDates.length; i++) {
        const sourceIndex = isPasteSingleColumn ? 0 : i;
        const weekDate = targetWeekDates[i];
        const currentStatus = wellRow.inputs.get(weekDate);
        const caseRowData = inputs.get(wellRow.id);
        if (!caseRowData && !missedRows.has(wellRow.id)) {
          toast.warn(`Pressure: ${msRow.name} ${wellRow.name} does not exist`);
          missedRows.add(wellRow.id);
        }
        const newValue = caseRowData?.get(sourceIndex);
        if (currentStatus && newValue !== undefined) {
          setWellPressureInputChange(
            state,
            {
              row: wellRow,
              weekDate,
              currentStatus,
              value: newValue
            },
            calculateNestedChanges
          );
        }
      }
    }
  }
}
