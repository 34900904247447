import { ConstraintType } from 'api';
import { ConstraintsSelectType } from '../types/ConstraintsSelectType';

export const maxOilRateOption: ConstraintsSelectType = {
  id: ConstraintType.MaxOilRate,
  name: 'Max Oil Rate, t/day',
  type: 'Constraint'
};
export const maxGasRateOption: ConstraintsSelectType = {
  id: ConstraintType.MaxGasRate,
  name: 'Max Gas Rate, sm3/day',
  type: 'Constraint'
};
export const maxPressureOption: ConstraintsSelectType = {
  id: ConstraintType.MaxPressure,
  name: 'Max Pressure, bar',
  type: 'Constraint'
};

export const constraintsOptions = [maxGasRateOption, maxOilRateOption, maxPressureOption];

export const constraintsOptionsMap = new Map<ConstraintType, string>(
  constraintsOptions.map((option) => [option.id, option.name] as [ConstraintType, string])
);
