import { createSlice, Reducer } from '@reduxjs/toolkit';
import { castDraft } from 'immer';
import { handleError, handleLoading, handleSuccess } from 'store/base/baseStateHandlers';
import { convertWellInputData } from 'store/official-inputs/wellInput/utils/convertWellInputData';
import { WellInputState } from 'store/official-inputs/wellInput/WellInputsState';
import { getWellInputHistory } from './actions/getWellInputHistory';

const initialState: WellInputState<number> = { splitableWeekDateIds: new Set() };

const { reducer } = createSlice({
  initialState,
  name: 'wellInputHistory',
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getWellInputHistory.pending, (state) => {
        handleLoading(state);
      })
      .addCase(getWellInputHistory.fulfilled, (state, action) => {
        state.arg = castDraft(action.meta.arg);
        handleSuccess(state, action);
        const { rows, rowsById, dates, datesById } = convertWellInputData(action.payload);
        state.rows = rows;
        state.rowsById = rowsById;
        state.dates = dates;
        state.datesById = datesById;
      })
      .addCase(getWellInputHistory.rejected, (state, action) => {
        state.arg = undefined;
        handleError(state, action);
      });
  }
});

export default reducer as Reducer<typeof initialState>;
