import { useMemo } from 'react';
import { Margin } from '../chartTypes';
import { DEFAULT_CHART_MARGIN } from './chartUtils';

export type ChartDimensions = {
    margin: Margin;
    width: number;
    height: number;
    innerWidth: number;
    innerHeight: number;
};

export function useChartDimensions(margin: Margin, width: number, height: number): ChartDimensions {
    return useMemo(() => {
        const completeMargin = { ...DEFAULT_CHART_MARGIN, ...margin };

        return {
            margin: completeMargin,
            width,
            height,
            innerHeight: Math.max(0, height - completeMargin.top - completeMargin.bottom),
            innerWidth: Math.max(0, width - completeMargin.left - completeMargin.right),
        };
    }, [height, margin, width]);
}
