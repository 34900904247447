import { DeactivateScenarioCaseDto, DeactivateWeekDto, ScenarioClient } from 'api';
import { toast } from 'react-toastify';
import { createAppAsyncThunk } from 'store/createAppAsyncThunk';
import { selectCasesPresentState } from '../CasesSelector';
import { CasesState } from '../CasesState';

export interface LineupCaseParams {
  scenarioCaseId: number;
}

const service = new ScenarioClient();

export function getDeactivatedCaseWeeksState(casesState: CasesState, scenarioCaseId: number) {
  const deactivateWeeks: DeactivateWeekDto[] = [];

  casesState.deactivatedTimesteps.forEach((isDeactivated, weekDateId) =>
    deactivateWeeks.push(new DeactivateWeekDto({ weekDateId, isDeactivated }))
  );

  return new DeactivateScenarioCaseDto({ scenarioCaseId, deactivateWeeks });
}

export const getDeactivatedTimesteps = createAppAsyncThunk(
  'lineupCase/getDeactivatedTimesteps',
  async ({ scenarioCaseId }: LineupCaseParams): Promise<number[]> => {
    try {
      return await service.getDeactivatedWeeks(scenarioCaseId);
    } catch (error) {
      toast.error('Failed to load deactivated timesteps for current case');
      console.error(error);
    }
  }
);

export const saveDeactivatedTimesteps = createAppAsyncThunk(
  'cases/saveDeactivatedTimesteps',
  (caseId: number, { getState }) => {
    const casesState = selectCasesPresentState(getState());
    const lineupDeactivatedTs = getDeactivatedCaseWeeksState(casesState, caseId);

    return service.deactivateCase(lineupDeactivatedTs);
  }
);
