import { IGetWeekDatesDto } from 'api';
import { useEffect } from 'react';
import { toast } from 'react-toastify';
import { useAppDispatch, useAppSelector } from 'store';
import { wellGorCaseStateSelector } from 'store/cases/wellGor/wellGorCaseSelectors';
import { wellPICaseStateSelector } from 'store/cases/wellPI/wellPICaseSelectors';
import { wellPressureCaseStateSelector } from 'store/cases/wellPressure/wellPressureCaseSelectors';
import { wellGorErrorSelector } from 'store/wellGor/wellGorSelectors';
import { wellGorActions } from 'store/wellGor/wellGorSlice';
import { wellPIErrorSelector } from 'store/wellPI/wellPISelectors';
import { wellPIActions } from 'store/wellPI/wellPISlice';
import { wellPressureErrorSelector } from 'store/wellPressure/wellPressureSelectors';
import { wellPressureActions } from 'store/wellPressure/wellPressureSlice';

export function useSubsurfaceDataSimulationCaseTransferHandler() {
  const dispatch = useAppDispatch();

  const wellPressureCaseState = useAppSelector(wellPressureCaseStateSelector);

  const wellGorCaseState = useAppSelector(wellGorCaseStateSelector);

  const wellPICaseState = useAppSelector(wellPICaseStateSelector);

  const wellPressureError = useAppSelector(wellPressureErrorSelector);
  const wellGorError = useAppSelector(wellGorErrorSelector);
  const wellPIError = useAppSelector(wellPIErrorSelector);

  useEffect(() => {
    if (wellPressureError) {
      toast.error(wellPressureError);
    }
    if (wellGorError) {
      toast.error(wellGorError);
    }
    if (wellPIError) {
      toast.error(wellPIError);
    }
  }, [wellPressureError, wellGorError, wellPIError]);

  const handleApply = (startIndex: number, weekDates: IGetWeekDatesDto[]) => {
    dispatch(
      wellPressureActions.copySimulationCaseWellPressure({
        rows: wellPressureCaseState.rows,
        columns: wellPressureCaseState.dates.map((dto) => dto.id),
        targetWeekDateIds: weekDates
          .slice(startIndex, startIndex + wellPressureCaseState.dates.length)
          .map((dto) => dto.id)
      })
    );

    dispatch(
      wellGorActions.copySimulationCaseWellGor({
        rows: wellGorCaseState.rows,
        columns: wellGorCaseState.dates.map((dto) => dto.id),
        targetWeekDateIds: weekDates.slice(startIndex, startIndex + wellGorCaseState.dates.length).map((dto) => dto.id)
      })
    );

    dispatch(
      wellPIActions.copySimulationCaseWellPI({
        rows: wellPICaseState.rows,
        columns: wellPICaseState.dates.map((dto) => dto.id),
        targetWeekDateIds: weekDates.slice(startIndex, startIndex + wellPICaseState.dates.length).map((dto) => dto.id)
      })
    );

    toast.success('Successfully applied');
  };

  return handleApply;
}
