import { WellPIState } from '../WellPIState';
import { WellPITableRow } from './convertWellPIData';

export const getWellPISnapshot = (wellPI: WellPIState, searchQuery = ''): WellPITableRow[] => {
  const search = searchQuery?.toLocaleLowerCase();
  const { rows, rateChanges, piChanges, inputChanges } = wellPI;
  return rows
    ?.map((row) => {
      const isMSName = row.name?.toLocaleLowerCase().includes(search);

      if (isMSName) {
        return row;
      }

      const wellRows = row.subRows.filter((subRow) => subRow.name?.toLocaleLowerCase().includes(search));

      if (wellRows.length > 0) {
        return {
          ...row,
          subRows: wellRows
        };
      }

      return undefined;
    })
    ?.filter(Boolean)
    ?.map((row): WellPITableRow => {
      return {
        ...row,
        subRows: row.subRows.map((subRow): WellPITableRow => {
          return {
            ...subRow,
            rateOfChange: rateChanges?.get(subRow.id) ?? subRow.rateOfChange,
            piValue: piChanges?.get(subRow.id) ?? subRow.piValue,
            inputs: new Map(
              Array.from(subRow.inputs.entries()).map(([key, value]) => {
                return [
                  key,
                  {
                    ...value,
                    piValue: inputChanges?.get(subRow.id)?.get(key)?.piValue ?? value.piValue
                  }
                ];
              })
            )
          };
        })
      };
    });
};
