import { FC } from 'react';
import { Outlet } from 'react-router-dom';
import { PermissionDenied } from './PermissionDenied';
import { useUserData } from './useUserData';

interface GuardedRouteProps {
  roles: string[];
}

export const GuardedRoute: FC<GuardedRouteProps> = ({ roles }) => {
  const { hasPermission } = useUserData();

  const allow = hasPermission(roles);

  return allow ? <Outlet /> : <PermissionDenied requiredRoles={roles} />;
};
