import cx from 'classnames';
import React, { FC } from 'react';
import styles from './List.module.css';
import { ListItem, ListItemProps } from './ListItem';

/**
 * The props of the {@link List} component.
 * @category Props
 */
export interface ListHeaderProps extends ListItemProps {}

/**
 * @category Component
 * @group List
 */
export const ListHeader: FC<ListHeaderProps> = ({ className, ...other }) => {
    return <ListItem className={cx(styles.header, className)} {...other} />;
};
