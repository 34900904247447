import { LoadingOverlay } from 'components/common/Loading/LoadingOverlay';
import { DataGrid, DataGridColumnOptions } from 'components/library';
import orderBy from 'lodash/orderBy';
import { getDate } from 'utils/dateUtil';
import { roundToTwo } from 'utils/roundToTwoDigits';
import { useMsPiHistorianData } from './hooks/useMsPiHistorianData';

interface MsPressureTableRow {
  date?: string;
  pressure: number | string;
}

const columns: DataGridColumnOptions<MsPressureTableRow>[] = [
  {
    accessor: 'date',
    Header: 'Date'
  },
  {
    accessor: 'pressure',
    Header: 'Pressure'
  }
];

export function MeterStationDetailsTable() {
  const { data, isLoading } = useMsPiHistorianData();
  const formattedData = orderBy(data || [], 'timestamp', 'desc').map((item) => ({
    pressure: roundToTwo(item.firstPipeOutletPressure),
    date: getDate(item.timestamp)
  }));

  return (
    <LoadingOverlay loading={isLoading}>
      <DataGrid columns={columns} data={formattedData} />
    </LoadingOverlay>
  );
}
