import React, { forwardRef } from 'react';

import NumberFormat, { NumberFormatProps } from 'react-number-format';
import { TextField, TextFieldProps } from './TextField';

type FakeEvent = {
    target: {
        name: string;
        value: number;
        formattedValue: string;
        stringValue: string;
    };
};

/**
 * @category Component
 * @group Input
 */
function NumberFormatCustom(props) {
    const { inputRef, onChange, ...other } = props;

    return (
        <NumberFormat
            {...other}
            getInputRef={inputRef}
            onValueChange={(values) => {
                onChange({
                    target: {
                        name: props.name,
                        value: values.floatValue,
                        formattedValue: values.formattedValue,
                        stringValue: values.value,
                    },
                } as FakeEvent);
            }}
        />
    );
}

/**
 * The props for the {@link NumberField} component.
 */
interface NumberFieldProps extends Omit<TextFieldProps, 'onChange'> {
    inputProps?: NumberFormatProps;
    value: number;
    onChange: (fakeEvent: FakeEvent) => void;
}

/**
 * @category Component
 * @group Input
 */
export const NumberField = forwardRef(function NumberField({ onChange, ...other }: NumberFieldProps, ref: any) {
    return <TextField ref={ref} inputComponent={NumberFormatCustom} onChange={onChange as any} {...other} />;
});
