import React, { ComponentPropsWithoutRef, memo } from 'react';
import { Paper, PaperProps } from '../Paper/Paper';

/**
 * The props for the {@link DataGridTable} component.
 * @category Props
 */
export interface DataGridTableProps extends ComponentPropsWithoutRef<'div'> {
    paperProps?: PaperProps;
}

/**
 * @category Component
 * @group Data Grid
 */
export const DataGridTable = memo(function DataGridTable({ paperProps, ...other }: DataGridTableProps) {
    return <Paper {...paperProps} {...(other as any)} />;
});
