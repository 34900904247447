import { IGetWeekDatesDto } from 'api';
import { getIsoWeek, getIsoWeekYear, isMonday } from 'utils/dateUtil';

export const weekNumberKeyAccessor = (date: Date) => {
  const year = getIsoWeekYear(date);
  const weekNumber = getIsoWeek(date);

  return `${year}_${weekNumber}`;
};

export const isSplittedWeekday = (dtos: IGetWeekDatesDto[]) => dtos?.length === 7;

export function groupDatesByWeekNumber(dtos: IGetWeekDatesDto[]) {
  const datesByWeekNumber = new Map<string, IGetWeekDatesDto[]>();

  dtos.forEach((dto) => {
    const key = weekNumberKeyAccessor(dto.weekDate);
    let group = datesByWeekNumber.get(key);
    if (!group) {
      group = [];
      datesByWeekNumber.set(key, group);
    }
    group.push(dto);
  });

  return datesByWeekNumber;
}

export function getMondaysOnlyDates(dtos: IGetWeekDatesDto[]) {
  return new Set(dtos.filter((x) => isMonday(x.weekDate)).map((x) => x.id));
}
