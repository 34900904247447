import { Draft } from '@reduxjs/toolkit';
import { WellInputState } from '../WellInputsState';
import { OfficialInputState } from './../../OfficialInputState';
import { toggleWellInputMeterStationStatusChange, toggleWellStatusChange } from './wellInputStatusChanges';

export function copyWellInputColumnsAction(
  state: Draft<OfficialInputState>,
  rows: WellInputState['rows'],
  weekDates: number[]
) {
  const statuses = new Map<string, Map<number, boolean>>();

  for (const msRow of rows) {
    let msRowClipboard = statuses.get(msRow.id);
    if (!msRowClipboard) {
      msRowClipboard = new Map();
      statuses.set(msRow.id, msRowClipboard);
    }

    for (let i = 0; i < weekDates.length; i++) {
      const weekDate = weekDates[i];
      const msStatus = msRow.msStatuses.get(weekDate);
      const changedMSStatus = state.changes.MeterStation.get(msRow.id)?.get(weekDate);

      msRowClipboard.set(i, changedMSStatus?.status ?? msStatus?.status);
    }

    for (const wellRow of msRow.subRows) {
      let wellRowClipboard = statuses.get(wellRow.id);
      if (!wellRowClipboard) {
        wellRowClipboard = new Map();
        statuses.set(wellRow.id, wellRowClipboard);
      }

      for (let i = 0; i < weekDates.length; i++) {
        const weekDate = weekDates[i];
        const wellStatus = wellRow.wellStatuses.get(weekDate);
        const changedWellStatus = state.changes.Well.get(wellRow.id)?.get(weekDate);
        wellRowClipboard.set(i, changedWellStatus?.status ?? wellStatus?.status);
      }
    }
  }

  state.wellInput.clipboard = { weekDates, statuses };
}

export function pasteWellInputColumnsAction(state: Draft<OfficialInputState>, targetWeekDates: number[]) {
  const { weekDates: copiedWeekdates, statuses } = state.wellInput.clipboard;
  const isPasteSingleColumn = copiedWeekdates.length === 1;

  if (!isPasteSingleColumn && copiedWeekdates.length !== targetWeekDates.length) {
    throw new Error('The number of copied columns does not match the number of pasted columns');
  }

  for (const msRow of state.wellInput.rows) {
    for (let i = 0; i < targetWeekDates.length; i++) {
      const sourceIndex = isPasteSingleColumn ? 0 : i;
      const weekDate = targetWeekDates[i];
      const newMsStatus = statuses.get(msRow.id)?.get(sourceIndex);
      if (newMsStatus !== undefined) {
        toggleWellInputMeterStationStatusChange(state, { rowId: msRow.id, weekDate, value: newMsStatus });
      }
    }

    for (const wellRow of msRow.subRows) {
      for (let i = 0; i < targetWeekDates.length; i++) {
        const sourceIndex = isPasteSingleColumn ? 0 : i;
        const weekDate = targetWeekDates[i];
        const newWellStatus = statuses.get(wellRow.id)?.get(sourceIndex);
        if (newWellStatus !== undefined) {
          toggleWellStatusChange(state, { rowId: wellRow.id, weekDate, value: newWellStatus });
        }
      }
    }
  }
}
