import { useState } from 'react';

/**
 * @category Util
 */
export function useId(idOverride) {
    const [id] = useState(() => idOverride ?? `component-${Math.round(Math.random() * 1e5)}`);
    const result = idOverride || id;
    return result;
}
