import { AnyAction, createSlice, PayloadAction, Reducer } from '@reduxjs/toolkit';
import { castDraft } from 'immer';
import undoable, { includeAction, StateWithHistory } from 'redux-undo';
import { handleError, handleLoading } from 'store/base/baseStateHandlers';
import { sealWellGorChanges } from 'store/wellGor/actions/saveWellGor';
import { convertWellGorData } from 'store/wellGor/utils/convertWellGorData';
import { copyWellGorColumns, pasteWellGorColumns } from 'store/wellGor/utils/copyPasteWellGor';
import {
  ChangeWellGorInputPayload,
  ChangeWellGorPayload,
  setWellGorChange,
  setWellGorInputChange
} from 'store/wellGor/utils/wellGorInputChange';
import { WellGorState } from 'store/wellGor/WellGorState';
import { getWellGorCase, saveWellGorCase, WellGorCaseParams } from './actions/getWellGorCase';

const initialState: WellGorState<WellGorCaseParams> = { splitableWeekDateIds: new Set() };

const { actions, reducer } = createSlice({
  initialState,
  name: 'wellGorCase',
  reducers: {
    reset(state) {
      state.gorChanges.clear();
      state.inputChanges.clear();
    },
    setWellGorInput(state, action: PayloadAction<ChangeWellGorInputPayload>) {
      setWellGorInputChange(state, action.payload);
    },
    setWellGor(state, action: PayloadAction<ChangeWellGorPayload>) {
      setWellGorChange(state, action.payload);
    },
    copyColumns(state, action: PayloadAction<number[]>) {
      copyWellGorColumns(state, state.rows, action.payload);
    },
    pasteColumns(state, action: PayloadAction<number[]>) {
      pasteWellGorColumns(state, action.payload);
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getWellGorCase.pending, (state, action) => {
        state.arg = castDraft(action.meta.arg);
        handleLoading(state);
      })
      .addCase(getWellGorCase.fulfilled, (state, action) => {
        state.arg = castDraft(action.meta.arg);
        state.loading = false;
        state.data = action.payload.items;
        const { rows, dates } = convertWellGorData(action.payload.items, action.payload.adjustWeekDates);
        state.rows = rows;
        state.dates = dates;
        state.gorChanges = new Map();
        state.inputChanges = new Map();
      })
      .addCase(getWellGorCase.rejected, (state, action) => {
        state.arg = undefined;
        handleError(state, action);
      });

    builder.addCase(saveWellGorCase.fulfilled, (state) => {
      sealWellGorChanges(state);
      state.gorChanges.clear();
      state.inputChanges.clear();
    });
  }
});

export const wellGorCaseActions = actions;
export const { reset, setWellGor, setWellGorInput, copyColumns, pasteColumns } = actions;

export const WELL_GOR_CASE_UNDO = 'WELL_GOR_CASE_UNDO';
export const WELL_GOR_CASE_UNDO_CLEAR = 'WELL_GOR_CASE_UNDO_CLEAR';

export const getWellGorCaseUndoableReducer = (
  reducer: Reducer<typeof initialState>
): Reducer<StateWithHistory<WellGorState<WellGorCaseParams>>, AnyAction> =>
  undoable(reducer, {
    undoType: WELL_GOR_CASE_UNDO,
    clearHistoryType: WELL_GOR_CASE_UNDO_CLEAR,
    filter: includeAction([
      setWellGorInput.type,
      getWellGorCase.fulfilled.type,
      saveWellGorCase.fulfilled.type,
      pasteColumns.type,
      reset.type
    ]),
    debug: false
  });

export default getWellGorCaseUndoableReducer(reducer);
