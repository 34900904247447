import { Navigate, RouteObject } from 'react-router-dom';
import { lazyComponent } from 'utils/lazyComponent';

const SubsurfaceData = lazyComponent(() => import('./SubsurfaceData'), 'subsurfaceData');
const SubsurfacePressure = lazyComponent(() => import('./pressure/WellPressure'), 'wellPressure');
const SubsurfaceGor = lazyComponent(() => import('./gor/WellGor'), 'wellGor');
const SubsurfacePI = lazyComponent(() => import('./pi/WellPI'), 'wellPI');
const SubsurfacePlantInputs = lazyComponent(() => import('./plant-inputs/PlantInputs'), 'plantInputs');

export const officialSubsurfaceRoute: RouteObject = {
  path: 'input-data',
  element: <SubsurfaceData />,
  children: [
    {
      index: true,
      element: <Navigate replace to="pressure" />
    },
    {
      path: 'pressure',
      element: <SubsurfacePressure />
    },
    {
      path: 'gor',
      element: <SubsurfaceGor />
    },
    {
      path: 'pi',
      element: <SubsurfacePI />
    },
    {
      path: 'plant-inputs',
      element: <SubsurfacePlantInputs />
    }
  ]
};
