import cx from 'classnames';
import React, {
    cloneElement,
    forwardRef,
    HTMLAttributes,
    ReactElement,
    ReactNode,
    SyntheticEvent,
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react';
import { Fade } from 'reactstrap';
import { capitalize, useControlled, useEventCallback, useForkRef, useId, useIsFocusVisible } from '../util';
import { Popper, PopperPlacementType, PopperProps } from './PopperComponent';
import styles from './Tooltip.module.css';

let hystersisOpen = false;
let hystersisTimer = null;

/**
 * This is props for {@link Tooltip} component
 * @category Props
 */
export interface TooltipProps extends Omit<HTMLAttributes<HTMLDivElement>, 'title'> {
    arrow?: boolean;
    children: ReactElement<any, any>;
    disableFocusListener?: boolean;
    disableHoverListener?: boolean;
    disableTouchListener?: boolean;
    enterDelay?: number;
    enterNextDelay?: number;
    enterTouchDelay?: number;
    id?: string;
    interactive?: boolean;
    leaveDelay?: number;
    leaveTouchDelay?: number;
    onClose?: (event: SyntheticEvent | Event) => void;
    onOpen?: (event: SyntheticEvent) => void;
    open?: boolean;
    placement?: PopperPlacementType;
    title: NonNullable<ReactNode>;
    PopperProps?: Partial<PopperProps>;
}

/**
 * @category Component
 * @group Tooltip
 */
export const Tooltip = forwardRef(function Tooltip(props: TooltipProps, ref) {
    const {
        arrow = false,
        children,
        disableFocusListener = false,
        disableHoverListener = false,
        disableTouchListener = false,
        enterDelay = 100,
        enterNextDelay = 0,
        enterTouchDelay = 700,
        id: idProp,
        interactive = false,
        leaveDelay = 0,
        leaveTouchDelay = 1500,
        onClose,
        onOpen,
        open: openProp,
        placement = 'top',
        title,
        ...other
    } = props;

    const [childNode, setChildNode] = useState<HTMLButtonElement>();
    const [arrowRef, setArrowRef] = useState(null);
    const ignoreNonTouchEvents = useRef(false);

    const closeTimer = useRef<any>();
    const enterTimer = useRef<any>();
    const leaveTimer = useRef<any>();
    const touchTimer = useRef<any>();

    const [openState, setOpenState] = useControlled({
        controlled: openProp,
        default: false,
    });

    let open = openState;

    const id = useId(idProp);

    useEffect(() => {
        return () => {
            clearTimeout(closeTimer.current);
            clearTimeout(enterTimer.current);
            clearTimeout(leaveTimer.current);
            clearTimeout(touchTimer.current);
        };
    }, []);

    const handleOpen = (event) => {
        clearTimeout(hystersisTimer);
        hystersisOpen = true;

        setOpenState(true);

        if (onOpen) {
            onOpen(event);
        }
    };

    const handleEnter =
        (forward = true) =>
        (event) => {
            const childrenProps = children.props;

            if (event.type === 'mouseover' && childrenProps.onMouseOver && forward) {
                childrenProps.onMouseOver(event);
            }

            if (ignoreNonTouchEvents.current && event.type !== 'touchstart') {
                return;
            }

            if (childNode) {
                childNode.removeAttribute('title');
            }

            clearTimeout(enterTimer.current);
            clearTimeout(leaveTimer.current);
            if (enterDelay || (hystersisOpen && enterNextDelay)) {
                event.persist();
                enterTimer.current = setTimeout(
                    () => {
                        handleOpen(event);
                    },
                    hystersisOpen ? enterNextDelay : enterDelay
                );
            } else {
                handleOpen(event);
            }
        };

    const {
        isFocusVisibleRef,
        onBlur: handleBlurVisible,
        onFocus: handleFocusVisible,
        ref: focusVisibleRef,
    } = useIsFocusVisible();

    const [, setChildIsFocusVisible] = useState(false);
    const handleBlur = (event) => {
        handleBlurVisible(event);
        if (isFocusVisibleRef.current === false) {
            setChildIsFocusVisible(false);
        }
    };

    const handleFocus =
        (forward = true) =>
        (event) => {
            if (!childNode) {
                setChildNode(event.currentTarget);
            }

            handleFocusVisible(event);
            if (isFocusVisibleRef.current === true) {
                setChildIsFocusVisible(true);
                handleEnter()(event);
            }

            const childrenProps = children.props;
            if (childrenProps.onFocus && forward) {
                childrenProps.onFocus(event);
            }
        };

    const handleClose = useEventCallback((event: SyntheticEvent | Event) => {
        clearTimeout(hystersisTimer);
        hystersisTimer = setTimeout(() => {
            hystersisOpen = false;
        }, 800 + leaveDelay);
        setOpenState(false);

        if (onClose) {
            onClose(event);
        }

        clearTimeout(closeTimer.current);
        closeTimer.current = setTimeout(() => {
            ignoreNonTouchEvents.current = false;
        }, 150);
    });

    const handleLeave =
        (forward = true) =>
        (event) => {
            const childrenProps = children.props;

            if (event.type === 'blur') {
                if (childrenProps.onBlur && forward) {
                    childrenProps.onBlur(event);
                }
                handleBlur(event);
            }

            if (event.type === 'mouseleave' && childrenProps.onMouseLeave && event.currentTarget === childNode) {
                childrenProps.onMouseLeave(event);
            }

            clearTimeout(enterTimer.current);
            clearTimeout(leaveTimer.current);
            event.persist();
            leaveTimer.current = setTimeout(() => {
                handleClose(event);
            }, leaveDelay);
        };

    const detectTouchStart = (event) => {
        ignoreNonTouchEvents.current = true;

        const childrenProps = children.props;
        if (childrenProps.onTouchStart) {
            childrenProps.onTouchStart(event);
        }
    };

    const handleTouchStart = (event) => {
        detectTouchStart(event);
        clearTimeout(leaveTimer.current);
        clearTimeout(closeTimer.current);
        clearTimeout(touchTimer.current);
        event.persist();
        touchTimer.current = setTimeout(() => {
            handleEnter()(event);
        }, enterTouchDelay);
    };

    const handleTouchEnd = (event) => {
        if (children.props.onTouchEnd) {
            children.props.onTouchEnd(event);
        }

        clearTimeout(touchTimer.current);
        clearTimeout(leaveTimer.current);
        event.persist();
        leaveTimer.current = setTimeout(() => {
            handleClose(event);
        }, leaveTouchDelay);
    };

    useEffect(() => {
        if (!open) {
            return undefined;
        }

        function handleKeyDown(nativeEvent: KeyboardEvent) {
            if (nativeEvent.key === 'Escape' || nativeEvent.key === 'Esc') {
                handleClose(nativeEvent);
            }
        }

        document.addEventListener('keydown', handleKeyDown);

        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, [handleClose, open]);

    const handleUseRef = useForkRef(setChildNode, ref);
    const handleFocusRef = useForkRef(focusVisibleRef, handleUseRef);
    const handleRef = useForkRef((children as any).ref, handleFocusRef);

    // There is no point in displaying an empty tooltip.
    if (!title) {
        open = false;
    }

    const shouldShowNativeTitle = !open && !disableHoverListener;
    const childrenProps = {
        'aria-describedby': open ? id : null,
        title: shouldShowNativeTitle && typeof title === 'string' ? title : null,
        ...other,
        ...children.props,
        className: cx(other.className, children.props.className),
        onTouchStart: detectTouchStart,
        ref: handleRef,
    };

    const interactiveWrapperListeners: any = {};

    if (!disableTouchListener) {
        childrenProps.onTouchStart = handleTouchStart;
        childrenProps.onTouchEnd = handleTouchEnd;
    }

    if (!disableHoverListener) {
        childrenProps.onMouseOver = handleEnter();
        childrenProps.onMouseLeave = handleLeave();

        if (interactive) {
            interactiveWrapperListeners.onMouseOver = handleEnter(false);
            interactiveWrapperListeners.onMouseLeave = handleLeave(false);
        }
    }

    if (!disableFocusListener) {
        childrenProps.onFocus = handleFocus();
        childrenProps.onBlur = handleLeave();

        if (interactive) {
            interactiveWrapperListeners.onFocus = handleFocus(false);
            interactiveWrapperListeners.onBlur = handleLeave(false);
        }
    }

    const mergedPopperProps = useMemo(() => {
        return {
            popperOptions: {
                modifiers: {
                    arrow: {
                        enabled: Boolean(arrowRef),
                        element: arrowRef,
                    },
                },
            },
        };
    }, [arrowRef]);

    return (
        <>
            {cloneElement(children, childrenProps)}
            <Popper
                className={cx(styles.popper, {
                    [styles.popperInteractive]: interactive,
                    [styles.popperArrow]: arrow,
                })}
                placement={placement}
                anchorEl={childNode}
                open={childNode ? open : false}
                id={childrenProps['aria-describedby']}
                transition
                {...interactiveWrapperListeners}
                {...mergedPopperProps}
            >
                {({ placement: placementInner, TransitionProps: TransitionPropsInner }) => (
                    <Fade timeout={200} {...TransitionPropsInner}>
                        <div
                            className={cx(
                                styles.tooltip,
                                {
                                    [styles.touch]: ignoreNonTouchEvents.current,
                                    [styles.tooltipArrow]: arrow,
                                },
                                styles[`tooltipPlacement${capitalize(placementInner.split('-')[0])}`]
                            )}
                        >
                            {title}
                            {arrow ? <span className={styles.arrow} ref={setArrowRef} /> : null}
                        </div>
                    </Fade>
                )}
            </Popper>
        </>
    );
});
