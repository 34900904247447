import { HeaderDto, MeterStationDto, P6Component, P6DetailsDto, PipelineDto, WellDto } from 'api';
import { P6DetailProperties } from './p6Changes';

export type P6DetailOption = HeaderDto | MeterStationDto | PipelineDto | WellDto;

export function getP6Detail(
  details: P6DetailsDto,
  headers: HeaderDto[],
  meterStations: MeterStationDto[],
  pipelines: PipelineDto[],
  wells: WellDto[],
  headersLoading?: boolean,
  meterStationsLoading?: boolean,
  pipelinesLoading?: boolean,
  wellsLoading?: boolean
) {
  let value: number = undefined;
  let property: P6DetailProperties = undefined;
  let options: P6DetailOption[] = undefined;
  let loading = false;

  switch (details?.componentId) {
    case P6Component.Header:
      value = details.headerId;
      options = headers;
      loading = headersLoading;
      property = 'headerId';
      break;
    case P6Component.MeterStation:
      value = details.meterStationId;
      options = meterStations;
      loading = meterStationsLoading;
      property = 'meterStationId';
      break;
    case P6Component.Pipeline:
      value = details.pipelineId;
      options = pipelines;
      loading = pipelinesLoading;
      property = 'pipelineId';
      break;
    case P6Component.Well:
      value = details.wellId;
      options = wells;
      loading = wellsLoading;
      property = 'wellId';
      break;
  }

  const option = options?.find((x) => x.id === value);

  return { property, value, option, loading, options };
}
