import {
  ComponentPressureType,
  IGetWeekDatesDto,
  ILineupDto,
  ILineupStateDto,
  IParentLineupInputStatus,
  LineupChildDto,
  LineupStateDto,
  ParentLineupInputStatus,
  SchemeComponentType
} from 'api';
import { getNodeId } from 'store/official-inputs/utils/getNodeId';
import { LineupTableRow } from '../types/LineupTableRow';
import { getMondaysOnlyDates, groupDatesByWeekNumber } from './groupDatesByWeekNumber';

function sortRows(parentRowsById: Map<string, LineupTableRow>) {
  const rows = Array.from(parentRowsById.values());

  rows.sort((a, b) => a.name.localeCompare(b.name));
  for (const parentRow of rows) {
    parentRow.subRows.sort((a, b) => a.name?.localeCompare(b.name));
    for (const groupRow of parentRow.subRows) {
      groupRow.subRows.sort((a, b) => a.name?.localeCompare(b.name));
    }
  }

  return rows;
}

function sortDates(datesById: Map<number, IGetWeekDatesDto>) {
  const dates = Array.from(datesById.values());
  dates.sort((a, b) => a.weekDate.getTime() - b.weekDate.getTime());
  return dates;
}

function updateDates(groupedDateId: number, groupedDate: Date, datesById: Map<number, IGetWeekDatesDto>) {
  if (!datesById.has(groupedDateId)) {
    datesById.set(groupedDateId, {
      id: groupedDateId,
      weekDate: new Date(groupedDate)
    });
  }
}

function processLineupStates(
  lineupStates: LineupStateDto[],
  childRow: LineupTableRow,
  rowsById: Map<string, LineupTableRow>,
  rowIdByNodeId: Map<string, string>,
  groupedDateId: number
) {
  for (const status of lineupStates) {
    const { connectionName, tagName, valveId, valvePressureType, id: lineupId } = status;
    const connectionRowId = childRow.id + `, ${connectionName}${tagName}`;

    let connectionRow = rowsById.get(connectionRowId);

    if (!connectionRow) {
      connectionRow = {
        id: connectionRowId,
        parentId: childRow.parentId,
        groupId: childRow.id,
        valveId,
        name: connectionName,
        pressureType: valvePressureType,
        tagName,
        rowType: SchemeComponentType.Valve,
        connectionStatuses: new Map<number, ILineupStateDto>(),
        lineupId
      };
      childRow.subRows.push(connectionRow);
      rowsById.set(connectionRowId, connectionRow);
      rowIdByNodeId.set(getNodeId(valveId, SchemeComponentType.Valve), connectionRowId);
    }
    connectionRow.connectionStatuses.set(groupedDateId, status);
  }
}

function processLineupChildList(
  lineupChildList: LineupChildDto[],
  parentRow: LineupTableRow,
  rowsById: Map<string, LineupTableRow>,
  rowIdByNodeId: Map<string, string>,
  groupedDateId: number
) {
  for (const { childGroupName, lineupStates, childGroupPressureType } of lineupChildList) {
    const childRowId = parentRow.id + `, ${childGroupName}`;

    let childRow = rowsById.get(childRowId);
    if (!childRow) {
      childRow = {
        id: childRowId,
        parentId: parentRow.id,
        rowType: undefined,
        name: childGroupName,
        pressureType: childGroupPressureType,
        subRows: []
      };
      parentRow.subRows.push(childRow);
      rowsById.set(childRowId, childRow);
    }
    processLineupStates(lineupStates, childRow, rowsById, rowIdByNodeId, groupedDateId);
  }
}

function getParentRowId(parentLineupInputStatus: ParentLineupInputStatus) {
  const { headerId, meterStationId } = parentLineupInputStatus;
  return meterStationId
    ? getNodeId(meterStationId, SchemeComponentType.MeterStation)
    : getNodeId(headerId ?? 0, SchemeComponentType.Header);
}

function getParentRow(
  parentGroupName: string,
  parentPressureType: ComponentPressureType,
  rowsById: Map<string, LineupTableRow>,
  parentRowsById: Map<string, LineupTableRow>,
  parentLineupInputStatus: ParentLineupInputStatus
) {
  const parentRowId = getParentRowId(parentLineupInputStatus);
  let parentRow = rowsById.get(parentRowId);
  if (!parentRow) {
    parentRow = {
      id: parentRowId,
      rowType: parentLineupInputStatus.meterStationId ? SchemeComponentType.MeterStation : SchemeComponentType.Header,
      name: parentGroupName,
      pressureType: parentPressureType,
      parentStatuses: new Map<number, IParentLineupInputStatus>(),
      subRows: []
    };
    rowsById.set(parentRowId, parentRow);
    parentRowsById.set(parentRowId, parentRow);
  }
  return parentRow;
}

function processLineup(
  lineup: ILineupDto[],
  datesById: Map<number, IGetWeekDatesDto>,
  rowsById: Map<string, LineupTableRow>,
  parentRowsById: Map<string, LineupTableRow>,
  rowIdByNodeId: Map<string, string>
) {
  for (const { groupedDateId, groupedDate, lineupParentList } of lineup) {
    updateDates(groupedDateId, groupedDate, datesById);

    for (const { parentGroupName, parentLineupInputStatus, lineupChildList, parentPressureType } of lineupParentList) {
      const parentRow = getParentRow(
        parentGroupName,
        parentPressureType,
        rowsById,
        parentRowsById,
        parentLineupInputStatus
      );

      parentRow.parentStatuses.set(groupedDateId, parentLineupInputStatus);

      processLineupChildList(lineupChildList, parentRow, rowsById, rowIdByNodeId, groupedDateId);
    }
  }
}

export function convertLineupData(lineup: ILineupDto[]) {
  const datesById = new Map<number, IGetWeekDatesDto>();
  const rowsById = new Map<string, LineupTableRow>();
  const parentRowsById = new Map<string, LineupTableRow>();
  const rowIdByNodeId = new Map<string, string>();

  processLineup(lineup, datesById, rowsById, parentRowsById, rowIdByNodeId);

  const rows = sortRows(parentRowsById);

  const dates = sortDates(datesById);

  const datesByWeekNumber = groupDatesByWeekNumber(dates);
  const splitableWeekDateIds = getMondaysOnlyDates(dates);

  return {
    dates,
    datesById,
    datesByWeekNumber,
    splitableWeekDateIds,
    rows,
    rowsById,
    rowIdByNodeId
  };
}

export type LineupData = ReturnType<typeof convertLineupData>;
