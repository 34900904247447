import Paper from '@mui/material/Paper';
import MuiToolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';

import { TimestepPicker } from 'components/common/TimestepPicker';
import { selectedWeekSelector } from 'store/scheme/schemeSelectors';
import { weekDatesApi } from 'store/weekDates/weekDatesApi';

import { ApplyLineupLogicButton } from 'pages/official-inputs/components/lineup-wellInputs/ApplyLineupLogicButton';
import { useAppDispatch, useAppSelector } from 'store';
import { schemeActions } from 'store/scheme/schemeSlice';

type Props = {
  meterStationName: string;
};

function Toolbar({ meterStationName }: Props) {
  const dispatch = useAppDispatch();
  const selectedWeekDto = useAppSelector(selectedWeekSelector);
  const { data: weekDates } = weekDatesApi.useGetWeekDatesQuery(undefined);

  const handleSelectedWeekChange = (date: Date) => {
    const weekDateDto = weekDates?.find((x) => x.weekDate?.getTime() === date?.getTime());

    if (weekDateDto) {
      dispatch(schemeActions.setSelectedWeek(weekDateDto));
    }
  };

  return (
    <Paper elevation={0} sx={{ borderRadius: 4 }}>
      <MuiToolbar sx={{ gap: 2 }}>
        <ApplyLineupLogicButton />
        <TimestepPicker
          disableMargin
          minDate={new Date()}
          value={selectedWeekDto?.weekDate}
          onChange={handleSelectedWeekChange}
          {...{ size: 'small' }}
        />
        {meterStationName && (
          <Typography variant="h6" component="h1">
            Meter station: {meterStationName}
          </Typography>
        )}
      </MuiToolbar>
    </Paper>
  );
}

export default Toolbar;
