import { useCallback, useEffect, useState } from 'react';
import { unstable_batchedUpdates } from 'react-dom';
import { DataGridInstance, DataGridPluginHook } from '../types';

const startUpdater = (state) => ({ ...state, isEditing: true });
const stopUpdater = (state) => ({ ...state, isEditing: false });

function useInstance(instance: DataGridInstance) {
    const [tasks, setTasks] = useState([]);

    const { rows, rowsById, prepareRow } = instance;

    instance.startInlineEdit = useCallback((id) => {
        setTasks((tasks) => tasks.concat([[id, true]]));
    }, []);

    instance.stopInlineEdit = useCallback((id) => {
        setTasks((tasks) => tasks.concat([[id, false]]));
    }, []);

    useEffect(
        () =>
            unstable_batchedUpdates(() => {
                let updateTasks = false;
                const newTasks = [...tasks];

                let index = 0;
                while (index < newTasks.length) {
                    const [rowId, start] = newTasks[index];

                    const row = rowsById[rowId];

                    if (row) {
                        prepareRow(row);

                        if (start) {
                            row.startInlineEdit();
                            // dispatch({
                            //     type: actions.setRowState,
                            //     rowId: rowId,
                            //     value: startUpdater
                            // });
                        } else {
                            row.stopInlineEdit();
                            // dispatch({
                            //     type: actions.setRowState,
                            //     rowId: rowId,
                            //     value: stopUpdater
                            // });
                        }

                        updateTasks = true;
                        newTasks.splice(index, 1);
                    }

                    index++;
                }

                if (updateTasks) {
                    setTasks(newTasks);
                }
            }),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [rows, tasks]
    );
}

export const inlineEditHook: DataGridPluginHook = (hooks) => {
    hooks.prepareRow.push((row, { instance }) => {
        const { fieldArray } = instance;

        if (row.setState) {
            row.startInlineEdit = () => {
                if (row.original.editable === false) {
                    return;
                }
                unstable_batchedUpdates(() => {
                    fieldArray.insert(row.index, row.original, false);
                    row.setState(startUpdater);
                });
            };

            row.stopInlineEdit = () => {
                if (row.original.editable === false) {
                    return;
                }
                unstable_batchedUpdates(() => {
                    fieldArray.remove(row.index);
                    row.setState(stopUpdater);
                });
            };
        }
    });

    hooks.useInstance.push(useInstance);
};
