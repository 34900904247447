import cx from 'classnames';
import React, { forwardRef, HTMLAttributes, ReactNode } from 'react';
import styles from './Paper.module.css';

/**
 * The props of the {@link Paper} component.
 * @category Props
 */
export interface PaperProps extends HTMLAttributes<HTMLDivElement> {
    disableBorder?: boolean;
    children?: ReactNode;
    component?: any;
    elevation?: number;
    square?: boolean;
    variant?: 'elevation' | 'outlined';
}

/**
 * @category Component
 * @group Paper
 */
export const Paper = forwardRef<HTMLDivElement, PaperProps>(function Paper(props, ref) {
    const {
        disableBorder = false,
        className,
        component: Component = 'div',
        square = false,
        elevation = 1,
        variant = 'elevation',
        ...other
    } = props;

    return (
        <Component
            className={cx(
                styles.root,
                {
                    [styles.border]: !disableBorder,
                    [styles.rounded]: !square,
                    [styles[`elevation${elevation}`]]: variant === 'elevation',
                    [styles.outlined]: variant === 'outlined',
                },
                className
            )}
            ref={ref}
            {...other}
        />
    );
});
