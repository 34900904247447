import { localPoint } from '@visx/event';
import React, { Children, ReactElement, ReactNode } from 'react';
import { isElement, isFragment } from 'react-is';
import { ChartInstance, Dimensions, Margin, SeriesOptions, SeriesPoint } from '..';
import { componentName, isBarSeries, isScatterSeries, isSeries, isStackedGroup } from './chartUtils';
import { findClosestPoint } from './findClosestPoint';
import { getChildSeries } from './getChildSeries';
import { getStackedData } from './getStackedData';
import { createAccessor } from './useAccessors';

const DEFAULT_MAX_DISTANCE_PX = 10;

interface Params {
    legend: ChartInstance['legend'];
    children: ReactNode;
    xScales: ChartInstance['xScales'];
    yScales: ChartInstance['yScales'];
    getX: ChartInstance['getX'];
    getY: ChartInstance['getY'];
    margin: Margin;
    event: React.MouseEvent<SVGElement>;
    maxXDistancePx?: number;
    maxYDistancePx?: number;
    groupDimensions: Record<string, Dimensions>;
}

export interface ClosestPoints extends Partial<SeriesPoint> {
    series?: Record<string, SeriesPoint>;
}

export function findClosestPoints<T>({
    legend,
    children,
    xScales,
    yScales,
    getX: chartGetX,
    getY: chartGetY,
    margin,
    event,
    maxXDistancePx = DEFAULT_MAX_DISTANCE_PX,
    maxYDistancePx = DEFAULT_MAX_DISTANCE_PX,
    groupDimensions
}: Params): ClosestPoints | null {
    const gElement = (event?.target as SVGElement)?.ownerSVGElement;

    if (!gElement || !xScales || !yScales) {
        return null;
    }
    const series: Record<string, SeriesPoint> = {};

    const point = localPoint(gElement, event);
    if (!point) {
        return null;
    }
    const { x: svgMouseX, y: svgMouseY } = point;
    const globalMouseX = svgMouseX - (margin.left || 0);
    const globalMouseY = svgMouseY - (margin.top || 0);

    let closestPoint: SeriesPoint = {} as SeriesPoint;
    let minDeltaX = Infinity;
    let minDeltaY = Infinity;

    const flatSeriesChildren: (ReactElement | [ReactElement, ReactNode[]])[] = [];

    function processChildren(Child: ReactNode) {
        if (isFragment(Child)) {
            Children.forEach(Child.props.children, processChildren);
        }

        if (Array.isArray(Child)) {
            Children.forEach(Child, processChildren);
        }

        const name = componentName(Child);

        if (isElement(Child) && isStackedGroup(name)) {
            const series = getStackedData(Child.props, Child.props.children, legend, chartGetX, chartGetY);
            flatSeriesChildren.push(...series.map((x) => x[0]));
        }

        if (isElement(Child) && isSeries(name)) {
            if (name === 'AreaDifferenceSeries') {
                const [nestedSeries] = getChildSeries(Child.props.children);
                flatSeriesChildren.push([Child, nestedSeries]);
            } else {
                flatSeriesChildren.push(Child);
            }
        }
    }

    Children.forEach(children, processChildren);

    // collect data from all series that have an x value near this point
    flatSeriesChildren.forEach((item, childIndex) => {
        let Child: ReactElement<SeriesOptions> | undefined = undefined;
        if (Array.isArray(item)) {
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            const [parent, _nestedSeries] = item;
            //TODO: what to do with nested series?
            Child = parent;
        } else {
            Child = item;
        }

        if (Child.props.hideFromTooltip || Child.props.disableMouseEvents) return;

        const component = componentName(Child);
        const { axis, data, xAccessor, yAccessor, openAccessor, closeAccessor, name, id, stroke } = Child.props;

        const groupName = axis ?? 'undefined';
        const dimensions = groupDimensions[groupName];
        const xScale = xScales[axis] ?? xScales.undefined;
        const yScale = yScales[axis] ?? yScales.undefined;

        const seriesMouseX = globalMouseX - (dimensions.position.left + dimensions.offset.left);
        const seriesMouseY = globalMouseY - (dimensions.position.top + dimensions.offset.top);

        if (!xScale || !yScale) {
            return;
        }

        const getX = createAccessor(xAccessor) ?? chartGetX;
        const getY = createAccessor(yAccessor) ?? chartGetY;
        const getOpen = createAccessor(openAccessor) ?? chartGetX;
        const getClose = createAccessor(closeAccessor) ?? chartGetY;

        if (!getX || !getY || !data) {
            return;
        }

        const point = findClosestPoint({
            xScale,
            getX,
            event,
            dimensions,
            marginLeft: margin.left,
            ...Child.props
        });

        const bandWidth = xScale.bandwidth?.() ?? 0;

        const deltaX = Math.abs(xScale(getX(point || ({} as T))) - seriesMouseX);
        const deltaY = Math.abs(yScale(getY(point || ({} as T))) - seriesMouseY);

        let isXDistance = deltaX <= maxXDistancePx + bandWidth;
        const isYDistance = deltaY <= maxYDistancePx;

        if (isBarSeries(component) && !isXDistance) {
            isXDistance = isXDistance || deltaX <= maxXDistancePx + bandWidth;
        }

        let isDistance = isXDistance;

        if (isScatterSeries(component)) {
            isDistance = isXDistance && isYDistance;
        }

        if (point && isDistance) {
            const key = id || childIndex.toString(); // fall back to child index
            series[key] = {
                axis: groupName,
                data,
                getX,
                getY,
                getOpen,
                getClose,
                key,
                name,
                point,
                stroke,
                component: Child,
                options: Child.props
            };
            const deltaY = Math.abs(yScale(getY?.(point) ?? getClose?.(point) ?? getOpen?.(point)) - seriesMouseY);
            closestPoint =
                deltaY < minDeltaY && deltaX <= minDeltaX
                    ? {
                          axis: groupName,
                          data,
                          getX,
                          getY,
                          getOpen,
                          getClose,
                          key,
                          name,
                          point,
                          stroke,
                          component: Child,
                          options: Child.props
                      }
                    : closestPoint;
            minDeltaX = closestPoint === point ? deltaX : minDeltaX;
            minDeltaY = closestPoint === point ? deltaY : minDeltaY;
        }
    });

    return { series, ...closestPoint };
}
