import { WellPressureClient } from 'api';
import { saveDeactivatedTimesteps } from 'store/cases/actions/deactivatedTimesteps';
import { createAppAsyncThunk } from 'store/createAppAsyncThunk';
import { getWellPressureSaveState } from 'store/wellPressure/actions/saveWellPressure';
import { wellPressureCaseStateSelector } from '../wellPressureCaseSelectors';

const service = new WellPressureClient();

export const saveWellPressureCase = createAppAsyncThunk('wellPressureCase/save', (_: void, { getState, dispatch }) => {
  const wellPressure = wellPressureCaseStateSelector(getState());

  const data = getWellPressureSaveState(wellPressure);

  const saveWellPressure = service.saveScenario(wellPressure.arg.scenarioCaseId, data);
  const saveLineupDeactivatedWeeks = dispatch(saveDeactivatedTimesteps(wellPressure.arg.scenarioCaseId));

  return Promise.allSettled([saveWellPressure, saveLineupDeactivatedWeeks]);
});
