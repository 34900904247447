import cx from 'classnames';
import React, { ComponentPropsWithoutRef, forwardRef } from 'react';
import styles from './TableHeader.module.css';

/**
 * The props for the {@link TableHeader} component.
 * @category Props
 */
export interface TableHeaderProps extends ComponentPropsWithoutRef<'thead'> {
    blockLayout?: boolean;
}

/**
 * @category Component
 * @group Table
 */
export const TableHeader = forwardRef(function TableHeader(
    { className, blockLayout: blockLayoutProp = false, children, ...other }: TableHeaderProps,
    ref: any
) {
    let isBlockLayout = blockLayoutProp;
    let Component: any = isBlockLayout ? 'div' : 'thead';

    return (
        <Component
            ref={ref}
            className={cx(styles.header, isBlockLayout ? styles.blockHeader : styles.tableHeader, className)}
            {...other}
        >
            {children}
        </Component>
    );
});
