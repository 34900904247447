import { Props as DayzedProps, useDayzed } from 'dayzed';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import {
    CalendarContext,
    CalendarDepth,
    CalendarDepthValue,
    CalendarInstance,
    CalendarOptions,
    CalendarValueProps,
    MultiValueCalendar,
    SingleValueCalendar,
} from './CalendarContext';
import { CLIENT_UTC_OFFSET, getDateWithoutUTCOffset, getDateWithUTCOffset } from './utils';

export interface CalendarSettings extends Omit<CalendarOptions, 'onDateSelected'> {
    onDateSelected?: DayzedProps['onDateSelected'];
}

export function useCalendar(props: CalendarSettings): CalendarInstance {
    const parentInstance = useContext(CalendarContext);

    const {
        disabled = parentInstance?.disabled,
        date: dateProp = parentInstance?.date,
        selectRange = parentInstance?.selectRange,
        minDepth = parentInstance?.minDepth,
        defaultDepth = parentInstance?.defaultDepth,
        onDateSelected = parentInstance?.onDateSelected,
        offset: offsetProp = parentInstance?.offset,
        onOffsetChanged,
        value = parentInstance?.value,
        onChange,
        utcOffset = parentInstance?.utcOffset ?? CLIENT_UTC_OFFSET,
        DayWrapper,
        MonthWrapper,
        YearWrapper,
    } = props;

    const [date, setDate] = useState(dateProp);
    const [offset, setOffset] = useState<number | undefined>(offsetProp);
    const [hoveredDate, setHoveredDate] = useState<Date | null>(null);

    useEffect(() => {
        setDate(dateProp);
        setOffset(offsetProp ?? offset);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dateProp?.getTime?.(), offsetProp]);

    const [depth, setDepth] = useState<CalendarDepth>(() => {
        const largerDepth: number[] = [CalendarDepthValue.month];
        if (defaultDepth && CalendarDepthValue[defaultDepth]) {
            largerDepth.push(CalendarDepthValue[defaultDepth]);
        }

        if (minDepth && CalendarDepthValue[minDepth]) {
            largerDepth.push(CalendarDepthValue[minDepth]);
        }

        return CalendarDepthValue[Math.max(...largerDepth)];
    });

    const handleDepthDowngrade = useCallback(() => {
        const newDepth = CalendarDepthValue[depth] - 1;
        if (
            Number.isInteger(newDepth) &&
            CalendarDepthValue[newDepth] &&
            (minDepth ? newDepth >= CalendarDepthValue[minDepth] : true)
        ) {
            setDepth(CalendarDepthValue[newDepth]);
            return true;
        }
        return false;
    }, [depth, minDepth]);

    const handleOffsetChanged = useCallback(
        (offset: number) => {
            if (onOffsetChanged) {
                onOffsetChanged(offset);
                return;
            }

            setOffset(offset);
        },
        [onOffsetChanged]
    );

    const handleSelected: DayzedProps['onDateSelected'] = useCallback(
        (args, event) => {
            if (onDateSelected) {
                onDateSelected(args, event);
                return;
            }

            if (props.disabled) {
                return;
            }

            if (args === undefined) {
                onChange(undefined);
                return;
            }

            const { selectable, date: selectedDate } = args;

            if (!selectable || !selectedDate) {
                return;
            }

            const date = getDateWithUTCOffset(selectedDate, utcOffset);

            if (selectRange) {
                const { onChange } = props as MultiValueCalendar;
                const dateTime = date.getTime();
                const newDates = value ? (Array.isArray(value) ? [...value] : [value]) : [];

                if (newDates.length === 1) {
                    const firstTime = newDates[0].getTime();
                    if (firstTime < dateTime) {
                        newDates.push(date);
                    } else {
                        newDates.unshift(date);
                    }
                    onChange(newDates);
                } else if (newDates.length === 2) {
                    onChange([date]);
                } else {
                    onChange([date]);
                }
            } else {
                const { onChange } = props as SingleValueCalendar;
                onChange(date);
            }
        },
        [onDateSelected, props, utcOffset, selectRange, onChange, value]
    );

    const selected = (() => {
        if (!value) {
            return undefined;
        }

        if (Array.isArray(value)) {
            return value.map((date) => getDateWithoutUTCOffset(date, utcOffset));
        }

        return getDateWithoutUTCOffset(value, utcOffset);
    })();

    const dayzedOptions = useMemo(
        () => ({
            date,
            maxDate: props.maxDate ?? parentInstance?.maxDate,
            minDate: props.minDate ?? parentInstance?.minDate,
            monthsToDisplay: props.monthsToDisplay ?? parentInstance?.monthsToDisplay,
            firstDayOfWeek: props.firstDayOfWeek ?? parentInstance?.firstDayOfWeek,
            showOutsideDays: props.showOutsideDays ?? parentInstance?.showOutsideDays,
            selected,
            offset,
            onOffsetChanged: handleOffsetChanged,
            onDateSelected: handleSelected,
        }),
        [parentInstance, props, date, handleOffsetChanged, handleSelected, offset, selected]
    );

    const dayzed = useDayzed(dayzedOptions);

    const isInRange = useCallback(
        (date: Date) => {
            if (parentInstance?.isInRange) {
                return parentInstance.isInRange(date);
            }

            if (!Array.isArray(value) || !selectRange) {
                return false;
            }

            const dateTime = date.getTime();

            if (value.length) {
                const firstSelected = value[0].getTime();
                if (value.length === 2) {
                    const secondSelected = value[1].getTime();
                    return firstSelected < dateTime && secondSelected > dateTime;
                } else {
                    return (
                        hoveredDate &&
                        ((firstSelected < dateTime && hoveredDate.getTime() >= dateTime) ||
                            (dateTime < firstSelected && dateTime >= hoveredDate.getTime()))
                    );
                }
            }

            return false;
        },
        [hoveredDate, parentInstance, selectRange, value]
    );

    const handleDateHover = useCallback(
        (date: Date, event) => {
            if (parentInstance?.handleDateHover) {
                return parentInstance.handleDateHover(date, event);
            }

            setHoveredDate(date);
        },
        [parentInstance]
    );

    const handleResetHover = useCallback(
        (event) => {
            if (parentInstance?.handleResetHover) {
                return parentInstance.handleResetHover(event);
            }

            setHoveredDate(null);
        },
        [parentInstance]
    );

    const handleClear = useCallback(
        (event) => {
            if (parentInstance?.handleClear) {
                return parentInstance.handleClear(event);
            }

            handleSelected(undefined, event);
        },
        [handleSelected, parentInstance]
    );

    return useMemo((): CalendarInstance => {
        const valuesProps = {
            selectRange,
            value,
        } as CalendarValueProps as any;

        return {
            ...parentInstance,
            ...dayzedOptions,
            ...valuesProps,
            ...dayzed,
            isInRange,
            handleDateHover,
            handleResetHover,
            disabled,
            minDepth,
            defaultDepth,
            depth,
            setDepth,
            date,
            setDate,
            onDateSelected: handleSelected,
            handleDepthDowngrade,
            handleOffsetChanged,
            handleClear,
            setOffset,
            utcOffset,
            DayWrapper,
            MonthWrapper,
            YearWrapper,
        };
    }, [
        disabled,
        value,
        date,
        dayzed,
        dayzedOptions,
        defaultDepth,
        depth,
        handleClear,
        handleDateHover,
        handleDepthDowngrade,
        handleOffsetChanged,
        handleResetHover,
        handleSelected,
        isInRange,
        minDepth,
        parentInstance,
        selectRange,
        utcOffset,
        DayWrapper,
        MonthWrapper,
        YearWrapper,
    ]);
}
