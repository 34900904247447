
import { Draft } from '@reduxjs/toolkit';
import { SchemeComponentType } from 'api';
import { SelectedCell } from 'store/official-inputs/types/SelectedCell';

import { OfficialInputState } from './../../OfficialInputState';
import { toggleWellInputMeterStationStatusChange, toggleWellStatusChange } from './wellInputStatusChanges';

export function copyWellInputCellsAction(
  state: Draft<OfficialInputState>,
  rowsIds: string[],
  weekDates: number[]
) {
  const statuses = new Map<string, Map<number, boolean>>();
  rowsIds
    .map((rowId) => state.wellInput.rowsById.get(rowId))
    .forEach((row) => {
      let rowClipboard = statuses.get(row.id);
      if (!rowClipboard) {
        rowClipboard = new Map();
        statuses.set(row.id, rowClipboard);
      }

      for (let i = 0; i < weekDates.length; i++) {
        const weekDate = weekDates[i];
        const status = row.rowType === SchemeComponentType.MeterStation
          ? row.msStatuses.get(weekDate)
          : row.wellStatuses.get(weekDate);

        const changedStatus = state.changes[row.rowType].get(row.id)?.get(weekDate);
        rowClipboard.set(i, changedStatus?.status ?? status?.status);
      }
    });

  const sourceRowIds = Array.from(statuses.keys());
  const hasWellsInSource = sourceRowIds.some((sourceRowId) => {
    const sourceRow = state.wellInput.rowsById.get(sourceRowId);
    return sourceRow?.rowType === SchemeComponentType.Well;
  });
  const hasMSInSource = sourceRowIds.some((sourceRowId) => {
    const sourceRow = state.wellInput.rowsById.get(sourceRowId);
    return sourceRow?.rowType === SchemeComponentType.MeterStation;
  });
  if (hasWellsInSource && hasMSInSource) {
    throw Error('Unable to copy parent and child items together.');
  }

  state.wellInput.cellsClipboard = { weekDates, statuses };
}

export function pasteWellInputCellsAction(state: Draft<OfficialInputState>, targetRowId: string, targetWeekDate: number) {
  const { weekDates: copiedWeekdates, statuses } = state.wellInput.cellsClipboard;

  const targetRow = state.wellInput.rowsById.get(targetRowId);
  const sourceRowIds = Array.from(statuses.keys());
  const newSelectedCells: SelectedCell[] = [];

  for (let j = 0; j < copiedWeekdates.length; j++) {
    const weekDate = targetWeekDate + j;
    if (targetRow?.rowType === SchemeComponentType.MeterStation) {
      const targetRowIndex = state.wellInput.rows.findIndex((row) => row.id === targetRowId);
      for (let i = 0; i < sourceRowIds.length; i++) {
        const sourceRowId = sourceRowIds[i];
        const newStatus = statuses.get(sourceRowId)?.get(j);
        if (newStatus !== undefined) {
          const targetMSRow = state.wellInput.rows[targetRowIndex + i];
          toggleWellInputMeterStationStatusChange(
            state,
            {
              rowId: targetMSRow.id,
              weekDate,
              value: newStatus,
              syncValves: !newStatus,
              syncWells: !newStatus
            }
          );
          newSelectedCells.push({
            rowId: targetMSRow.id,
            weekDate
          });
        }
      }
    }

    if (targetRow?.rowType === SchemeComponentType.Well) {
      for (let i = 0; i < sourceRowIds.length; i++) {
        const sourceRowId = sourceRowIds[i];
        const newStatus = statuses.get(sourceRowId)?.get(j);
        const targetMSRow = state.wellInput.rowsById.get(targetRow.parentId);
        const targetWellRowIndex = targetMSRow.subRows.findIndex((row) => row.id === targetRowId);
        const targetWellRow = targetMSRow.subRows[targetWellRowIndex + i];
        if (newStatus !== undefined && targetWellRow) {
          toggleWellStatusChange(state, { rowId: targetWellRow.id, weekDate, value: newStatus, syncMS: true });
          newSelectedCells.push({
            rowId: targetWellRow.id,
            weekDate
          });
        }
      }
    }
  }

  state.selectedCells = newSelectedCells;
}
