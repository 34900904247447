import { GuardedRoute } from 'components/Security/GuardedRoute';

import { ROLES } from 'constants/roles';
import { Navigate, RouteObject } from 'react-router-dom';
import { lazyComponent } from 'utils/lazyComponent';
import { dofRoute } from './dof/routes';
import { inputsHistoryRoute } from './history-log/routes';
import { korolevRoute } from './korolev/routes';
import { schemeRoutes } from './scheme/routes';
import { officialSubsurfaceRoute } from './subsurface-data/routes';
import { wellInputRoutes } from './wellInput/routes';

const OfficialInputsPage = lazyComponent(() => import('./index'), 'official-inputs');
const LineupPage = lazyComponent(() => import('./lineup/LineUp'), 'lineup');
const P6 = lazyComponent(() => import('./p6/P6Page'), 'p6');
const InputQC = lazyComponent(() => import('./input-qc/InputQC'), 'inputqc');

export const officialInputsRoutes: RouteObject[] = [
  inputsHistoryRoute,
  {
    path: 'official-inputs',
    element: <GuardedRoute roles={[ROLES.wsoUser]} />,
    children: [
      {
        path: '',
        element: <OfficialInputsPage />,
        children: [
          { index: true, element: <Navigate replace to="p6" /> },
          {
            path: 'lineup',
            element: <LineupPage />
          },
          ...wellInputRoutes,
          ...schemeRoutes,
          officialSubsurfaceRoute,
          {
            path: 'p6',
            element: <P6 />
          },
          korolevRoute,
          dofRoute,
          {
            path: 'qa-qc',
            element: <InputQC />
          }
        ]
      }
    ]
  }
];
