import { createSelector } from 'reselect';
import { RootState } from 'store';
import { ConstraintSelectRow } from './types/ConstraintsRow';
import { ConstraintsStateKeyAccessor } from './types/ConstraintsState';

export const selectConstraintAsInputsState = (state: RootState) => state.constraintsAsInputs;
export const selectConstraintAsInputsDisabled = (state: RootState, key: ConstraintsStateKeyAccessor) =>
  !selectConstraintAsInputsState(state)[key]?.enableSave;
export const selectConstraintAsInputsConstraintsOptions = (state: RootState, key: ConstraintsStateKeyAccessor) =>
  selectConstraintAsInputsState(state)[key]?.constraintsOptions ?? [];

export const selectConstraintAsInputsRows = (state: RootState, key: ConstraintsStateKeyAccessor) =>
  selectConstraintAsInputsState(state)[key]?.rows ?? [];

const constraintSelectRow: ConstraintSelectRow = { id: 0, name: 'option-row', type: 'ConstraintSelect' };
export const selectConstraintAsInputsTableRows = createSelector(selectConstraintAsInputsRows, (rows) => [
  constraintSelectRow,
  ...rows
]);
