import { Group } from '@visx/group';
import React, { memo, useContext, useMemo, cloneElement, ReactElement, FC } from 'react';
import { ChartContext } from '../ChartContext';
import { SeriesOptions, StackedGroupOptions } from '../chartTypes';
import { getChildSeries, getStackedData } from '../utils';
import { AreaSeriesProps } from './AreaSeries';

interface StackedAreaGroupProps<T extends object> extends Omit<SeriesOptions<T>, 'data'>, StackedGroupOptions {
    id: string;
    children: ReactElement[];
}

/**
 * @category Component
 * @group Chart
 */
let StackedAreaGroup = <T extends object>(props: StackedAreaGroupProps<T>) => {
    const { id, disableMouseEvents, children, ...other } = props;
    const { legend, getX: globalGetX, getY: globalGetY } = useContext(ChartContext);

    const [childSeries, restChilds] = useMemo(() => getChildSeries(children), [children]);

    const series = useMemo(
        () => getStackedData<AreaSeriesProps<any>>(props, childSeries, legend, globalGetX, globalGetY),
        [childSeries, globalGetX, globalGetY, legend, props]
    );

    return (
        <Group>
            {restChilds}
            {series.map(([Child], index) =>
                cloneElement(Child, {
                    id: Child.props.id ?? `${id}-child-series-${index}`,
                    key: `${id}-child-series-${index}`,
                    disableMouseEvents: Child.props.disableMouseEvents || disableMouseEvents,
                    ...other,
                })
            )}
        </Group>
    );
};

(StackedAreaGroup as FC).displayName = 'StackedAreaGroup';
StackedAreaGroup = memo(StackedAreaGroup);

export { StackedAreaGroup };
