import { InteractionType } from '@azure/msal-browser';
import { AuthenticatedTemplate, MsalAuthenticationTemplate, MsalProvider } from '@azure/msal-react';
import { ErrorComponent } from 'components/Security/ErrorComponent';
import { LoadingComponent } from 'components/Security/LoadingComponent';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import { routes } from 'routes';
import 'styles/index.css';
import authInstance, { loginRequest } from './app/authInstance';
import reportWebVitals from './reportWebVitals';
import { store } from './store';

const container = document.getElementById('root');
const root = createRoot(container);

const router = createBrowserRouter(routes);

root.render(
  <React.StrictMode>
    <MsalProvider instance={authInstance}>
      <AuthenticatedTemplate>
        <Provider store={store}>
          <RouterProvider router={router} />
        </Provider>
      </AuthenticatedTemplate>
      <MsalAuthenticationTemplate
        interactionType={InteractionType.Redirect}
        authenticationRequest={loginRequest}
        loadingComponent={LoadingComponent}
        errorComponent={ErrorComponent}
      />
    </MsalProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
