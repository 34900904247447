import { FC } from 'react';

interface PermissionDeniedProps {
  requiredRoles: string[];
}

export const PermissionDenied: FC<PermissionDeniedProps> = ({ requiredRoles }) => {
  return (
    <div>
      Please contact the <a href="mailto:omirbekyergaziyev@tengizchevroil.com?subject=TCO WSO Access">TCO WSO admin</a>{' '}
      to obtain access to this page.
      <h6>Required roles: </h6>
      <ul>
        {requiredRoles.map((role) => {
          return <li key={role}>{role}</li>;
        })}
      </ul>
    </div>
  );
};
