import { useAccount } from '@azure/msal-react';
import { ROLES } from 'constants/roles';
import { useCallback, useMemo } from 'react';

export function useUserData() {
  const account = useAccount();

  const hasPermission = useCallback(
    (requiredRoles: string[]) => {
      const userRoles = account.idTokenClaims.roles ?? [];

      return requiredRoles.every((role) => userRoles.includes(role));
    },
    [account]
  );

  const userData = useMemo(() => {
    const hasSaveRole = hasPermission([ROLES.wsoUserSave]);
    const readOnly = !hasSaveRole;

    return {
      username: account.username,
      name: account.name,
      hasSaveRole,
      readOnly,
      hasPermission
    };
  }, [hasPermission, account]);

  return userData;
}
