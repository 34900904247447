import { unstable_batchedUpdates } from 'react-dom';

import { DataGridInstance, DataGridPluginHook } from '../types';

export const makePreventSelectionOnShift =
    ({ settings: { selection } }: DataGridInstance) =>
    (event: MouseEvent) => {
        if (selection.mode == 'multiple' && event.shiftKey) {
            window.getSelection()?.removeAllRanges();
        }
    };

export const selectRowHook: DataGridPluginHook = (hooks) => {
    hooks.prepareRow.push((row, { instance }) => {
        const {
            manualRowSelectedKey,
            rows,
            selectedFlatRows,
            settings: { selection, onSelectRow },
        } = instance;

        row.getIsSelected = () => {
            let checked = false;

            if (row.original && row.original[manualRowSelectedKey]) {
                checked = true;
            } else {
                checked = row.isSelected;
            }

            return checked;
        };

        const preventSelectionOnShift = makePreventSelectionOnShift(instance);

        function resetSelection() {
            if (selectedFlatRows.length > 0) {
                instance.toggleAllRowsSelected(false);
            }
        }

        function expandRowSelection(event: MouseEvent) {
            const indexes = selectedFlatRows.map((row) => row.index);
            indexes.push(row.index);

            const startIndex = Math.min(...indexes);
            const endIndex = Math.max(...indexes);

            const dataSource = rows ?? [];

            const selectionStart = dataSource.findIndex((x) => x.index == startIndex);
            const selectionEnd = dataSource.findIndex((x) => x.index == endIndex);

            const selectedRows = dataSource.slice(selectionStart, selectionEnd + 1);

            unstable_batchedUpdates(() => {
                for (const selectedRow of selectedFlatRows) {
                    if (!selectedRows.some((x) => x.id == selectedRow.id)) {
                        instance.toggleRowSelected(selectedRow.id, false);
                    }
                }

                for (const row of selectedRows) {
                    if (!selectedFlatRows.some((x) => x.id == row.id)) {
                        instance.toggleRowSelected(row.id, true);
                    }
                }
            });
        }

        row.selectRow = (event: React.MouseEvent) => {
            if (selection && row?.state?.isEditing) {
                return;
            }

            const set = !row.isSelected;

            unstable_batchedUpdates(() => {
                if (
                    selection.mode === 'single' ||
                    (!selection.showColumn && !(event.ctrlKey || event.nativeEvent.ctrlKey))
                ) {
                    resetSelection();
                }

                row.toggleRowSelected(set);

                onSelectRow?.(row.original, set);
            });
        };

        row.clickSelectRow = (event: MouseEvent) => {
            preventSelectionOnShift(event);

            if (event.shiftKey && selection.mode === 'multiple') {
                expandRowSelection(event);
            } else {
                row.selectRow(event);
            }
        };
    });
};
