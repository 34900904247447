import React, { cloneElement, FC, ReactElement, RefObject, useCallback, useEffect, useMemo, useRef } from 'react';
import { useForkRef } from './util';

function isScrollBarClick(event: MouseEvent) {
    const target = event.target as HTMLElement;
    return (
        (event.offsetX > target.clientWidth && event.offsetX < target.offsetWidth) ||
        (event.offsetY > target.clientHeight && event.offsetY < target.offsetHeight)
    );
}

/**
 * This is props for {@link ClickAwayListener} component
 * @group Props
 */
export interface ClickAwayListenerProps {
    children: ReactElement;
    container?: HTMLElement | RefObject<HTMLElement>;
    onClickAway: (event: MouseEvent) => void;
}

/**
 * @category Component
 * @group Click Away Listener
 */
export const ClickAwayListener: FC<ClickAwayListenerProps> = ({ children, container = null, onClickAway }) => {
    const nodeRef = useRef(null);

    const handleRef = useForkRef(
        // @ts-expect-error TODO upstream fix
        children.ref,
        nodeRef
    );

    const root = useMemo(() => {
        if (container) {
            if (container instanceof HTMLElement) {
                return container;
            }
            if (container.current instanceof HTMLElement) {
                return container.current;
            }
        }

        return document.getElementById('root');
    }, [container]);

    const handleClick = useCallback(
        (event: any) => {
            const eventPath = event.path || event.composedPath();
            const topElement = eventPath[eventPath.length - 5];
            if (
                !isScrollBarClick(event) &&
                topElement.contains(nodeRef.current) &&
                nodeRef.current &&
                !nodeRef.current.contains(event.target)
            ) {
                onClickAway(event);
            }
        },
        [onClickAway]
    );

    useEffect(() => {
        document.body.addEventListener('mousedown', handleClick);
        return () => {
            document.body.removeEventListener('mousedown', handleClick);
        };
    }, [root, handleClick]);

    const childrenProps = { ref: handleRef };

    return <>{cloneElement(children, childrenProps)}</>;
};
