import { useCallback, useContext, useEffect, useRef } from 'react';
import { setRef } from '../../util';
import { DataGridContext } from '../DataGridContext';

export function useDataGridBodyEvent() {
    const { bodyRef, events, settings } = useContext(DataGridContext);
    const { initialScroll } = settings;
    const handleScrollRef = useRef<(event: any) => void>();

    useEffect(() => {
        bodyRef.current?.scrollTo?.(initialScroll);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [bodyRef.current]);

    return useCallback(
        (element) => {
            bodyRef.current?.removeEventListener('scroll', handleScrollRef.current);
            handleScrollRef.current = (event) => {
                events.emit('scroll', event);
            };
            setRef(bodyRef, element);
            events.emit('bodyMount', element);
            element?.addEventListener('scroll', handleScrollRef.current);
        },
        [bodyRef, events]
    );
}
