import React, { useContext } from 'react';
import { ChartContext } from '../ChartContext';

function RenderChartBackground() {
    const { margin, backgroundColor = null, backgroundOpacity = 1, width, height } = useContext(ChartContext);

    if (!backgroundColor) {
        return null;
    }

    return (
        <rect
            x={margin.left}
            width={width - margin.left * 2 - margin.right}
            height={height - margin.top * 2 - margin.bottom}
            fill={backgroundColor}
            opacity={backgroundOpacity}
        />
    );
}

export default RenderChartBackground;
