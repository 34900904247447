import { useMemo } from 'react';
import { NavLink, Outlet, useOutletContext } from 'react-router-dom';

import { Box, Divider, Stack } from '@mui/material';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';

import { WithSubsurfaceData } from 'pages/official-inputs/subsurface-data/components/WithSubsurfaceData';
import { ApplyButton } from 'pages/scenarios/case/components/ApplyButtonWithDialog';
import { useOutletRoute } from 'utils/useOutletRoute';
import usePageTitle from 'utils/usePageTitle';
import { SimulationCaseOutletContext } from '..';
import { WithSimulationCaseSubsurfaceData } from '../components/WithSimulationCaseSubsurfaceData';
import { useSubsurfaceDataSimulationCaseTransferHandler } from '../hooks/useSubsurfaceDataSimulationCaseTransferHandler';

function SubsurfaceDataSimulationCase() {
  usePageTitle('Input Data | Simulation');

  const childRoute = useOutletRoute();

  const { isEditable } = useOutletContext<SimulationCaseOutletContext>();

  const outletContext = useMemo(
    (): SimulationCaseOutletContext => ({
      isEditable,
      isViewOnly: !isEditable
    }),
    [isEditable]
  );

  const handleApply = useSubsurfaceDataSimulationCaseTransferHandler();

  return (
    <WithSubsurfaceData>
      <WithSimulationCaseSubsurfaceData>
        <Stack direction="row" justifyContent="space-between">
          <Tabs value={childRoute?.path ?? 'pressure'}>
            <Tab label="Pressure" component={NavLink} to="pressure" value="pressure" />
            <Tab label="GOR" component={NavLink} to="gor" value="gor" />
            <Tab label="PI Data" component={NavLink} to="pi" value="pi" />
            <Tab label="Plant Inputs" component={NavLink} to="plant-inputs" value="plant-inputs" />
          </Tabs>
          <Box>
            <ApplyButton onApply={handleApply} />
          </Box>
        </Stack>
        <Divider />
        <Outlet context={outletContext} />
      </WithSimulationCaseSubsurfaceData>
    </WithSubsurfaceData>
  );
}

export default SubsurfaceDataSimulationCase;
