import {
  CalendarContext,
  CalendarDateRange,
  DatePicker,
  DatePickerProps,
  DayWrapper
} from '@tearecs/components/src/DatePicker';
import moment from 'moment';
import { Ref, forwardRef, useCallback, useContext, useMemo } from 'react';
import { startOfWeek } from 'utils/dateUtil';

function processDate(date?: Date, hasSplitTimeSteps?: boolean) {
  const value = moment(date);
  if (!value.isValid()) {
    return undefined;
  }

  if (hasSplitTimeSteps) return value.toDate();

  return startOfWeek(value).toDate();
}

function isTimeStep(timestep: Date, date: Date) {
  const value = moment(timestep);
  if (!timestep || !value.isValid()) {
    return false;
  }

  return value.isSame(date, 'week');
}

export const TimestepDayWrapper: DayWrapper = (DayComponent) =>
  forwardRef(function ({ selected: selectedProp, date, ...other }, ref: Ref<HTMLButtonElement>) {
    const instance = useContext(CalendarContext);

    const { value } = instance;

    const selected = useMemo(() => {
      if (selectedProp) {
        return selectedProp;
      }

      if (Array.isArray(value)) {
        return value.some((x) => isTimeStep(x, date));
      }

      return isTimeStep(value, date);
    }, [date, selectedProp, value]);

    return <DayComponent ref={ref} date={date} selected={selected} {...other} />;
  });

type TimestepPickerProps = {
  hasSplitTimeSteps?: boolean;
} & DatePickerProps;

export const TimestepPicker = function TimestepPicker({ hasSplitTimeSteps, onChange, ...other }: TimestepPickerProps) {
  const handleChange = useCallback(
    (selected: Date | Date[] | CalendarDateRange) => {
      let date: Date | CalendarDateRange = undefined;
      if (Array.isArray(selected)) {
        date = selected.map((selectedDate) => processDate(selectedDate, hasSplitTimeSteps)) as CalendarDateRange;
      } else {
        date = processDate(selected, hasSplitTimeSteps);
      }

      onChange?.(date as never);
    },
    [hasSplitTimeSteps, onChange]
  );
  const dayWrapper = useMemo(() => (!hasSplitTimeSteps ? TimestepDayWrapper : undefined), [hasSplitTimeSteps]);

  return <DatePicker DayWrapper={dayWrapper} onChange={handleChange} {...other} />;
};
