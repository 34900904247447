import React, {
    forwardRef,
    useRef,
    useEffect,
    useImperativeHandle,
    useState,
    useCallback,
    HTMLAttributes,
    ReactNode,
    Ref,
} from 'react';
import PopperJs, { ReferenceObject } from 'popper.js';
import { Portal, PortalProps } from './Portal';
import { useForkRef, setRef, createChainedFunction } from '../util';

function flipPlacement(placement, theme) {
    const direction = (theme && theme.direction) || 'ltr';

    if (direction === 'ltr') {
        return placement;
    }

    switch (placement) {
        case 'bottom-end':
            return 'bottom-start';
        case 'bottom-start':
            return 'bottom-end';
        case 'top-end':
            return 'top-start';
        case 'top-start':
            return 'top-end';
        default:
            return placement;
    }
}

function getAnchorEl(anchorEl) {
    return typeof anchorEl === 'function' ? anchorEl() : anchorEl;
}
export type PopperPlacementType =
    | 'bottom-end'
    | 'bottom-start'
    | 'bottom'
    | 'left-end'
    | 'left-start'
    | 'left'
    | 'right-end'
    | 'right-start'
    | 'right'
    | 'top-end'
    | 'top-start'
    | 'top';

/**
 * The props of the {@link Popper} component.
 * @category Props
 */
export interface PopperProps extends Omit<HTMLAttributes<HTMLDivElement>, 'children'> {
    ref?: Ref<HTMLDivElement>;
    anchorEl?: null | ReferenceObject | (() => ReferenceObject);

    children?:
        | ReactNode
        | ((props: {
              placement: PopperPlacementType;
              TransitionProps?: {
                  in: boolean;
                  onEnter: () => {};
                  onExited: () => {};
              };
          }) => ReactNode);
    container?: PortalProps['container'];
    disablePortal?: PortalProps['disablePortal'];
    keepMounted?: boolean;
    modifiers?: object;
    open: boolean;
    placement?: PopperPlacementType;
    popperOptions?: any;
    popperRef?: Ref<PopperJs>;
    transition?: boolean;
}

const defaultPopperOptions = {};

/**
 * @category Component
 * @group Tooltip
 */
export const Popper = forwardRef(function Popper(props: PopperProps, ref) {
    const {
        anchorEl,
        children,
        container,
        disablePortal = false,
        keepMounted = false,
        modifiers,
        open,
        placement: initialPlacement = 'bottom',
        popperOptions = defaultPopperOptions,
        popperRef: popperRefProp,
        style,
        transition = false,
        ...other
    } = props;
    const tooltipRef = useRef(null);
    const ownRef = useForkRef(tooltipRef, ref);

    const popperRef = useRef(null);
    const handlePopperRef = useForkRef(popperRef, popperRefProp);
    const handlePopperRefRef = useRef(handlePopperRef);
    useEffect(() => {
        handlePopperRefRef.current = handlePopperRef;
    }, [handlePopperRef]);
    useImperativeHandle(popperRefProp, () => popperRef.current, []);

    const [exited, setExited] = useState(true);

    const [placement, setPlacement] = useState(initialPlacement);

    useEffect(() => {
        if (popperRef.current) {
            popperRef.current.update();
        }
    });

    const handleOpen = useCallback(() => {
        if (!tooltipRef.current || !anchorEl || !open) {
            return;
        }

        if (popperRef.current) {
            popperRef.current.destroy();
            handlePopperRefRef.current(null);
        }

        const handlePopperUpdate = (data) => {
            setPlacement(data.placement);
        };

        const popper = new PopperJs(getAnchorEl(anchorEl), tooltipRef.current, {
            placement: initialPlacement,
            ...popperOptions,
            modifiers: {
                ...(disablePortal
                    ? {}
                    : {
                          preventOverflow: {
                              boundariesElement: 'window',
                          },
                      }),
                ...modifiers,
                ...popperOptions.modifiers,
            },

            onCreate: createChainedFunction(handlePopperUpdate, popperOptions.onCreate),
            onUpdate: createChainedFunction(handlePopperUpdate, popperOptions.onUpdate),
        });

        handlePopperRefRef.current(popper);
    }, [anchorEl, disablePortal, initialPlacement, modifiers, open, popperOptions]);

    const handleRef = useCallback(
        (node) => {
            setRef(ownRef, node);
            handleOpen();
        },
        [ownRef, handleOpen]
    );

    const handleEnter = () => {
        setExited(false);
    };

    const handleClose = () => {
        if (!popperRef.current) {
            return;
        }

        popperRef.current.destroy();
        handlePopperRefRef.current(null);
    };

    const handleExited = () => {
        setExited(true);
        handleClose();
    };

    useEffect(() => {
        return () => {
            handleClose();
        };
    }, []);

    useEffect(() => {
        if (!open && !transition) {
            // Otherwise handleExited will call this.
            handleClose();
        }
    }, [open, transition]);

    if (!keepMounted && !open && (!transition || exited)) {
        return null;
    }

    const childProps = { placement, TransitionProps: null };

    if (transition) {
        childProps.TransitionProps = {
            in: open,
            onEnter: handleEnter,
            onExited: handleExited,
        };
    }

    return (
        <Portal disablePortal={disablePortal} container={container}>
            <div
                ref={handleRef}
                role="tooltip"
                {...other}
                style={{
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    display: !open && keepMounted && !transition ? 'none' : null,
                    ...style,
                }}
            >
                {typeof children === 'function' ? children(childProps) : children}
            </div>
        </Portal>
    );
});
