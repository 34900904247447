import { LoadingContainer } from 'components/common/Loading/LoadingContainer';
import { FC, PropsWithChildren, useEffect } from 'react';
import { useAppDispatch, useAppSelector } from 'store';
import { getWellGorCase } from 'store/cases/wellGor/actions/getWellGorCase';
import { wellGorCaseStateSelector } from 'store/cases/wellGor/wellGorCaseSelectors';
import { getWellPICase } from 'store/cases/wellPI/actions/getWellPICase';
import { wellPICaseStateSelector } from 'store/cases/wellPI/wellPICaseSelectors';
import { getWellPressureCase } from 'store/cases/wellPressure/actions/getWellPressureCase';
import { wellPressureCaseStateSelector } from 'store/cases/wellPressure/wellPressureCaseSelectors';
import { useScenarioCaseIdParams } from 'utils/useParams';

export const WithSimulationCaseSubsurfaceData: FC<PropsWithChildren<unknown>> = ({ children }) => {
  const { caseId } = useScenarioCaseIdParams();
  const dispatch = useAppDispatch();

  const { loading: wellPressureLoading } = useAppSelector(wellPressureCaseStateSelector);
  const { loading: wellPILoading } = useAppSelector(wellPICaseStateSelector);
  const { loading: wellGorLoading } = useAppSelector(wellGorCaseStateSelector);

  useEffect(() => {
    dispatch(getWellPressureCase({ scenarioCaseId: caseId }));
    dispatch(getWellPICase({ scenarioCaseId: caseId }));
    dispatch(getWellGorCase({ scenarioCaseId: caseId }));
  }, [caseId, dispatch]);

  return (
    <LoadingContainer loading={wellPressureLoading || wellPILoading || wellGorLoading}>{children}</LoadingContainer>
  );
};
