import React, { FC, memo, PropsWithChildren, useCallback, useContext } from 'react';
import { ChartContext, ChartMiniMapContext } from '../../ChartContext';
import { ProvidedZoomType } from '../../utils/useZoom';

const ZoomButtonControls: FC<PropsWithChildren<{ zoom: ProvidedZoomType }>> = ({ zoom, children }) => {
    const onZoomIn = useCallback(() => {
        zoom.scale({ scaleX: 1.1, scaleY: 1.1 });
    }, []);

    const onZoomOut = useCallback(() => {
        zoom.scale({ scaleX: 0.9, scaleY: 0.9 });
    }, []);

    return (
        <div className="zoom-button-controllers">
            <button type="button" title="Zoom in" onClick={onZoomIn}>
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none" viewBox="0 0 24 24">
                    <path
                        stroke="#000"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeOpacity=".87"
                        strokeWidth="2"
                        d="M12 5v14m-7-7h14"
                    />
                </svg>
            </button>
            <button type="button" title="Zoom out" onClick={onZoomOut}>
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none" viewBox="0 0 24 24">
                    <path
                        stroke="#000"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeOpacity=".87"
                        strokeWidth="2"
                        d="M5 12h14"
                    />
                </svg>
            </button>
            <button type="button" onClick={zoom.reset}>
                Reset
            </button>
            {children}
        </div>
    );
};
/**
 * @category Component
 * @group Chart
 */
const ZoomControls: FC = () => {
    const { zoom } = useContext(ChartContext);
    const { open, toggleMiniMap, options } = useContext(ChartMiniMapContext);

    const { transformMatrix } = zoom;

    if (!transformMatrix) return null;

    const miniMapControlls = options ? (
        <button type="button" onClick={toggleMiniMap}>
            {open ? 'Hide' : 'Show'} mini map
        </button>
    ) : null;

    return <ZoomButtonControls zoom={zoom.ref.current}>{miniMapControlls}</ZoomButtonControls>;
};

export const ZoomButtonController = memo(ZoomControls);
