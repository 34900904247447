import React, { Component, CSSProperties } from 'react';

import { Drag as ChartDrag } from '@visx/drag';
import { Group } from '@visx/group';
import { Bar } from '@visx/shape';
import cx from 'classnames';

import { ChartBrushCorner } from './ChartBrushCorner';
import { ChartBrushHandle } from './ChartBrushHandle';
import { ChartBrushSelection } from './ChartBrushSelection';

type BrushResizeTriggerArea =
    | 'left'
    | 'right'
    | 'top'
    | 'bottom'
    | 'topLeft'
    | 'topRight'
    | 'bottomLeft'
    | 'bottomRight';

/**
 * The props for the {@link RenderChartBrush} component.
 */
export interface RenderBrushProps {
    brushDirection?: 'horizontal' | 'vertical' | 'both';
    width: number;
    height: number;
    left: number;
    top: number;
    onChange?: (state) => void;
    handleSize?: number;
    resizeTriggerAreas?: BrushResizeTriggerArea[];
    onBrushStart?: (state) => void;
    onBrushEnd?: (state) => void;
    selectedBoxStyle?: CSSProperties;
    onMouseLeave?: (event) => void;
    onMouseUp?: (event) => void;
    onMouseMove?: (event) => void;
    onClick?: (event) => void;
    onSelectionClick?: (event) => void;
    clickSensitivity?: number;
    disableDraggingSelection?: boolean;
}
export type Point = {
    x: number;
    y: number;
};

export type Bounds = {
    x0: number;
    x1: number;
    xValues?: unknown[];
    y0: number;
    y1: number;
    yValues?: unknown[];
};

export interface RenderBrushState {
    start: Point;
    end: Point;
    extent: Bounds;
    bounds: Bounds;
}

const defaultExtent = {
    x0: -1,
    x1: -1,
    y0: -1,
    y1: -1
};

/**
 * @category Component
 * @group Chart
 */
export class RenderChartBrush extends Component<RenderBrushProps, RenderBrushState> {
    static defaultProps: Partial<RenderBrushProps> = {
        brushDirection: 'both',
        onChange: null,
        handleSize: 4,
        resizeTriggerAreas: ['left', 'right'],
        onBrushStart: null,
        onBrushEnd: null,
        onMouseLeave: null,
        onMouseUp: null,
        onMouseMove: null,
        onClick: null,
        onSelectionClick: null,
        disableDraggingSelection: false,
        clickSensitivity: 200
    };
    state = {
        start: { x: 0, y: 0 },
        end: { x: 0, y: 0 },
        extent: defaultExtent,
        bounds: {
            x0: 0,
            x1: this.props.width,
            y0: 0,
            y1: this.props.height
        }
    };
    mouseUpTime: Date = null;
    mouseDownTime: Date = null;

    componentDidUpdate(prevProps: RenderBrushProps) {
        if (this.props.width !== prevProps.width || this.props.height !== prevProps.height) {
            // eslint-disable-next-line react/no-did-update-set-state
            this.setState(() => {
                return {
                    bounds: {
                        x0: 0,
                        x1: this.props.width,
                        y0: 0,
                        y1: this.props.height
                    }
                };
            });
        }
    }

    getExtent = (start, end) => {
        const { brushDirection, width, height } = this.props;
        const x0 = brushDirection === 'vertical' ? 0 : Math.min(start.x, end.x);
        const x1 = brushDirection === 'vertical' ? width : Math.max(start.x, end.x);
        const y0 = brushDirection === 'horizontal' ? 0 : Math.min(start.y, end.y);
        const y1 = brushDirection === 'horizontal' ? height : Math.max(start.y, end.y);

        return {
            x0,
            x1,
            y0,
            y1
        };
    };

    handleDragStart = (draw) => {
        const { onBrushStart, left, top } = this.props;
        const start = {
            x: draw.x + draw.dx - left,
            y: draw.y + draw.dy - top
        };
        const end = { ...start };

        if (onBrushStart) {
            onBrushStart(start);
        }

        this.update((prevBrush) => ({
            ...prevBrush,
            start,
            end,
            extent: defaultExtent,
            isBrushing: true
        }));
    };

    handleDragMove = (draw) => {
        const { left, top } = this.props;
        if (!draw.isDragging) return;
        const end = {
            x: draw.x + draw.dx - left,
            y: draw.y + draw.dy - top
        };
        this.update((prevBrush) => {
            const { start } = prevBrush;
            const extent = this.getExtent(start, end);

            return {
                ...prevBrush,
                end,
                extent
            };
        });
    };

    handleDragEnd = () => {
        const { onBrushEnd } = this.props;

        this.update((prevBrush) => {
            const { extent } = prevBrush;

            const newState = {
                ...prevBrush,
                start: {
                    x: extent.x0,
                    y: extent.y0
                },
                end: {
                    x: extent.x1,
                    y: extent.y1
                },
                isBrushing: false
            };
            if (onBrushEnd) {
                onBrushEnd(newState);
            }

            return newState;
        });
    };

    getWidth = () => {
        const { extent } = this.state;
        const { x0, x1 } = extent;

        return Math.max(Math.max(x0, x1) - Math.min(x0, x1), 0);
    };

    getHeight = () => {
        const { extent } = this.state;
        const { y1, y0 } = extent;

        return Math.max(y1 - y0, 0);
    };

    getHandles = () => {
        const { handleSize } = this.props;
        const { extent } = this.state;
        const { x0, x1, y0, y1 } = extent;
        const offset = handleSize / 2;
        const width = this.getWidth();
        const height = this.getHeight();

        return {
            top: {
                x: x0 - offset,
                y: y0 - offset,
                height: handleSize,
                width: width + handleSize
            },
            bottom: {
                x: x0 - offset,
                y: y1 - offset,
                height: handleSize,
                width: width + handleSize
            },
            right: {
                x: x1 - offset,
                y: y0 - offset,
                height: height + handleSize,
                width: handleSize
            },
            left: {
                x: x0 - offset,
                y: y0 - offset,
                height: height + handleSize,
                width: handleSize
            }
        };
    };

    getCorners = () => {
        const { handleSize } = this.props;
        const { extent } = this.state;
        const { x0, x1, y0, y1 } = extent;
        const offset = handleSize / 2;

        return {
            topLeft: {
                x: Math.min(x0, x1) - offset,
                y: Math.min(y0, y1) - offset
            },
            topRight: {
                x: Math.max(x0, x1) - offset,
                y: Math.min(y0, y1) - offset
            },
            bottomLeft: {
                x: Math.min(x0, x1) - offset,
                y: Math.max(y0, y1) - offset
            },
            bottomRight: {
                x: Math.max(x0, x1) - offset,
                y: Math.max(y0, y1) - offset
            }
        };
    };

    update = (updater) => {
        const { onChange } = this.props;
        this.setState(updater, () => {
            if (onChange) {
                onChange(this.state);
            }
        });
    };

    reset = () => {
        const { width, height } = this.props;
        this.update(() => ({
            start: undefined,
            end: undefined,
            extent: {
                x0: undefined,
                x1: undefined,
                y0: undefined,
                y1: undefined
            },
            bounds: {
                x0: 0,
                x1: width,
                y0: 0,
                y1: height
            },
            isBrushing: false,
            activeHandle: undefined
        }));
    };

    render() {
        const { start, end } = this.state;
        const {
            top,
            left,
            width: stageWidth,
            height: stageHeight,
            handleSize,
            onMouseLeave,
            onMouseUp,
            onMouseMove,
            onBrushEnd,
            onClick,
            onSelectionClick,
            resizeTriggerAreas,
            selectedBoxStyle,
            disableDraggingSelection,
            clickSensitivity
        } = this.props;

        const handles = this.getHandles();
        const corners = this.getCorners();
        const width = this.getWidth();
        const height = this.getHeight();
        const resizeTriggerAreaSet = new Set(resizeTriggerAreas);

        return (
            <Group className="vx-brush" top={top} left={left}>
                {/* overlay */}
                <ChartDrag
                    width={stageWidth}
                    height={stageHeight}
                    resetOnStart
                    onDragStart={this.handleDragStart}
                    onDragMove={this.handleDragMove}
                    onDragEnd={this.handleDragEnd}
                >
                    {(draw) => (
                        <Bar
                            className={cx('vx-brush-overlay')}
                            fill="rgb(0,0,0,0.05)"
                            x={0}
                            y={0}
                            rx={4}
                            ry={4}
                            width={stageWidth}
                            height={stageHeight}
                            onDoubleClick={this.reset}
                            onClick={(event) => {
                                const duration = this.mouseUpTime.getTime() - this.mouseDownTime.getTime();
                                if (onClick && duration < clickSensitivity) onClick(event);
                            }}
                            onMouseDown={(event) => {
                                this.mouseDownTime = new Date();
                                draw.dragStart(event);
                            }}
                            onMouseLeave={(event) => {
                                if (onMouseLeave) onMouseLeave(event);
                            }}
                            onMouseMove={(event) => {
                                if (!draw.isDragging && onMouseMove) onMouseMove(event);
                                if (draw.isDragging) draw.dragMove(event);
                            }}
                            onMouseUp={(event) => {
                                this.mouseUpTime = new Date();
                                if (onMouseUp) onMouseUp(event);
                                draw.dragEnd(event);
                            }}
                            style={{ cursor: 'crosshair' }}
                        />
                    )}
                </ChartDrag>
                {/* selection */}
                {start && end && (
                    <ChartBrushSelection
                        updateBrush={this.update}
                        width={width}
                        height={height}
                        stageWidth={stageWidth}
                        stageHeight={stageHeight}
                        brush={{ ...this.state }}
                        disableDraggingSelection={disableDraggingSelection}
                        onBrushEnd={onBrushEnd}
                        onMouseLeave={onMouseLeave}
                        onMouseMove={onMouseMove}
                        onMouseUp={onMouseUp}
                        onClick={onSelectionClick}
                        style={selectedBoxStyle}
                    />
                )}
                {/* handles */}
                {start &&
                    end &&
                    (Object.keys(handles) as BrushResizeTriggerArea[])
                        .filter((handleKey) => resizeTriggerAreaSet.has(handleKey))
                        .map((handleKey) => {
                            const handle = handles[handleKey];

                            return (
                                <ChartBrushHandle
                                    key={`handle-${handleKey}`}
                                    type={handleKey}
                                    handle={handle}
                                    stageWidth={stageWidth}
                                    stageHeight={stageHeight}
                                    updateBrush={this.update}
                                    brush={this.state}
                                    onBrushEnd={onBrushEnd}
                                />
                            );
                        })}
                {/* corners */}
                {start &&
                    end &&
                    (Object.keys(corners) as BrushResizeTriggerArea[])
                        .filter((cornerKey) => resizeTriggerAreaSet.has(cornerKey))
                        .map((cornerKey) => {
                            const corner = corners[cornerKey];

                            return (
                                <ChartBrushCorner
                                    key={`corner-${cornerKey}`}
                                    type={cornerKey}
                                    brush={this.state}
                                    updateBrush={this.update}
                                    stageWidth={stageWidth}
                                    stageHeight={stageHeight}
                                    x={corner.x}
                                    y={corner.y}
                                    width={handleSize}
                                    height={handleSize}
                                    onBrushEnd={onBrushEnd}
                                    fill="transparent"
                                />
                            );
                        })}
            </Group>
        );
    }
}
