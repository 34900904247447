import { faCaretDown, faCaretRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cx from 'classnames';
import React, { CSSProperties, forwardRef, ForwardRefExoticComponent, memo, RefAttributes, useContext } from 'react';
import { DraggableProvidedDragHandleProps } from 'react-beautiful-dnd';
import { TableCell, TableCellProps } from '../../Table/TableCell';
import { DataGridContext } from '../DataGridContext';
import { useStikyColumn } from '../hooks/stickyColumnHook';
import { DataGridCellProps, DataGridRowProps } from '../types';

export interface DataGridCellRendererProps<TItem extends object = any> extends TableCellProps {
    dragHandleProps?: DraggableProvidedDragHandleProps;
    index: number;
    cell: DataGridCellProps<TItem>;
    row: DataGridRowProps<TItem>;
    isEdit?: boolean;
}

export type DataGridCellRenderer = ForwardRefExoticComponent<
    React.PropsWithoutRef<DataGridCellRendererProps> & RefAttributes<HTMLTableCellElement>
>;

export const RenderDataGridCell: DataGridCellRenderer = memo(
    forwardRef(function RenderDataGridCell(props, ref) {
        const {
            dragHandleProps,
            className: classNameProp,
            index,
            cell,
            row,
            style: styleProp,
            children,
            isEdit: isEditProp,
            ...other
        } = props;
        const instance = useContext(DataGridContext);
        const { settings } = instance;

        const stickyStyle = useStikyColumn(cell.column, instance);

        const { blockLayout, CellComponent = TableCell } = settings;

        const className = cx(cell.column.className, classNameProp);

        const style: CSSProperties = {
            ...cell.column.style,
            ...styleProp,
            ...stickyStyle
        };

        const isEdit = isEditProp ?? (row.state?.isEditing && !cell.column.disableEdit);

        if (isEdit) {
            style.padding = 0;
        }

        const cellProps = cell.getCellProps({
            className,
            style,
            ...other
        });

        return (
            <CellComponent
                ref={ref}
                key={index}
                align={(cell as any).column.align}
                blockLayout={blockLayout}
                {...cellProps}
            >
                {children ?? <RenderDataGridCellContent isEdit={isEdit} {...props} />}
            </CellComponent>
        );
    })
);

export const defaultDataGridCellWrapper = function DefaultDataGridCellWrapper(
    wrappingCell: DataGridCellRenderer
): DataGridCellRenderer {
    return wrappingCell;
};

export const RenderDataGridCellContent = ({ cell, row, dragHandleProps, isEdit }: DataGridCellRendererProps) => {
    return (
        <>
            {cell.isGrouped ? (
                <>
                    <span {...row.getToggleRowExpandedProps()}>
                        {row.isExpanded ? (
                            <FontAwesomeIcon icon={faCaretDown} />
                        ) : (
                            <FontAwesomeIcon icon={faCaretRight} />
                        )}
                    </span>
                    {cell.render('Cell')} ({row.subRows.length})
                </>
            ) : cell.isAggregated ? (
                cell.render('Aggregated')
            ) : cell.isPlaceholder ? null : isEdit ? (
                cell.render('CellEdit')
            ) : (
                cell.render('Cell', {
                    dragHandleProps
                })
            )}
        </>
    );
};
