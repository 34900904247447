import { Draft } from '@reduxjs/toolkit';
import { IWellPIInputDto, WellPIInputDto } from 'api';
import { getDifferenceInDays } from 'utils/dateUtil';
import { roundToTwo } from 'utils/roundToTwoDigits';
import { WellPIState } from '../WellPIState';
import { WellPITableRow } from './convertWellPIData';

export function calculateWellPI(piValue: number, rateOfChange: number, dayDifference: number, prevValue?: number) {
  return parseFloat(roundToTwo((prevValue ?? piValue) + rateOfChange * dayDifference));
}

export function reCalculateWellPI(state: Draft<WellPIState>, row: WellPITableRow, startWeekDate?: number) {
  const rowDraft = state.rows.find((x) => x.id === row.parentId)?.subRows?.find((x) => x.id === row.id);

  if (!rowDraft) {
    return;
  }

  const piValue = state.piChanges.get(row.id) ?? rowDraft.piValue;
  const rateOfChange = state.rateChanges.get(row.id) ?? rowDraft.rateOfChange;

  const startIndex = startWeekDate ? state.dates.findIndex((x) => x.id === startWeekDate) : 0;
  for (let i = startIndex + 1; i < state.dates.length; i++) {
    const prevWeekDate = state.dates[i - 1];
    const prevValue =
      state.inputChanges.get(row.id)?.get(prevWeekDate?.id)?.piValue ?? rowDraft.inputs.get(prevWeekDate?.id)?.piValue;

    const currWeekDate = state.dates[i];
    const currentStatus = rowDraft.inputs.get(currWeekDate.id);
    const dayDifference = getDifferenceInDays(prevWeekDate.weekDate, currWeekDate.weekDate);
    const newValue = calculateWellPI(piValue, rateOfChange, dayDifference, prevValue);

    // eslint-disable-next-line no-use-before-define
    setWellPIInputChange(state, { row, currentStatus, weekDate: currWeekDate.id, value: newValue }, false);
  }
}

export type ChangeWellPIInputPayload = {
  row: WellPITableRow;
  weekDate: number;
  currentStatus: IWellPIInputDto;
  value: number;
};

export function setWellPIInputChange(
  state: Draft<WellPIState>,
  payload: ChangeWellPIInputPayload,
  calculateNestedChanges = true
) {
  const { row, weekDate, currentStatus, value } = payload;
  let rowChanges = state.inputChanges.get(row.id);
  if (!rowChanges) {
    rowChanges = new Map();
    state.inputChanges.set(row.id, rowChanges);
  }

  rowChanges.set(
    weekDate,
    new WellPIInputDto({
      ...currentStatus,
      piValue: value
    })
  );

  if (calculateNestedChanges) {
    reCalculateWellPI(state, row, weekDate);
  }
}

export function setWellPIInputBulkChange(
  state: Draft<WellPIState>,
  payload: ChangeWellPIInputPayload[],
  calculateNestedChanges = false
) {
  for (const payloadItem of payload) {
    setWellPIInputChange(state, payloadItem, calculateNestedChanges);
  }
}

export type ChangeWellPIRatePayload = {
  row: WellPITableRow;
  value: number;
};

export function setWellPIRateChange(
  state: Draft<WellPIState>,
  payload: ChangeWellPIRatePayload,
  calculateNestedChanges = true
) {
  const { row, value } = payload;

  state.rateChanges.set(row.id, value);

  if (calculateNestedChanges) {
    reCalculateWellPI(state, row);
  }
}

export function setWellPIRateBulkChange(
  state: Draft<WellPIState>,
  payload: ChangeWellPIRatePayload[],
  calculateNestedChanges = false
) {
  for (const payloadItem of payload) {
    setWellPIRateChange(state, payloadItem, calculateNestedChanges);
  }
}

export type ChangeWellPIPayload = {
  row: WellPITableRow;
  value: number;
};

export function setWellPIChange(state: Draft<WellPIState>, payload: ChangeWellPIPayload) {
  const { row, value } = payload;

  state.piChanges.set(row.id, value);
}

export function sealWellPIChanges(state: Draft<WellPIState>) {
  for (const msRow of state.rows) {
    for (const wellRow of msRow.subRows) {
      const rateChange = state.rateChanges.get(wellRow.id);
      if (rateChange) {
        wellRow.rateOfChange = rateChange;
      }

      const piChange = state.piChanges.get(wellRow.id);
      if (piChange) {
        wellRow.piValue = piChange;
      }

      for (const [weekDay, status] of wellRow.inputs) {
        const inputChange = state.inputChanges.get(wellRow.id)?.get(weekDay);
        if (inputChange) {
          status.piValue = inputChange.piValue;
        }
      }
    }
  }
}
