import cx from 'classnames';

/**
 * @category Util
 */
function normalizeClass(className: any) {
    const result = className ?? '';
    if (typeof result !== 'string') {
        return '';
    }
    return result;
}

/**
 * @category Util
 */
export function combineClasses(styles: object, classes: object) {
    const keys = [...new Set(Object.keys(styles).concat(Object.keys(classes)))];

    return Object.fromEntries(
        keys.map((key) => {
            const defaultClass = normalizeClass(styles[key]);
            const overrideClass = normalizeClass(classes[key]);

            return [key, cx(defaultClass, overrideClass)];
        })
    );
}
