import { IGetWeekDatesDto, IWellGorDto, IWellGorInputDto, SchemeComponentType } from 'api';
import { sortBy } from 'lodash';
import { getMondaysOnlyDates, groupDatesByWeekNumber } from 'store/official-inputs/lineup/utils/groupDatesByWeekNumber';
import {} from './../../../api/clients';
import { calculateWellGor } from './wellGorInputChange';

export type WellGorTableRowType = SchemeComponentType;
export interface WellGorTableRow {
  id: string;
  parentId?: string;
  groupId?: string;
  rowType: WellGorTableRowType;
  name: string;
  subRows?: WellGorTableRow[];
  originalId?: number;
  wellId?: number;
  gorValue?: number;
  inputs?: Map<number, IWellGorInputDto>;
}

function processWellGorData(
  wellGors: IWellGorDto[],
  datesLookup: Map<number, IGetWeekDatesDto>,
  rowsLookup: Map<string, WellGorTableRow>,
  parentRowsLookup: Map<string, WellGorTableRow>,
  adjustWeekDates: IGetWeekDatesDto[] = []
) {
  for (const { meterStationName, id, wellId, wellName, wellGorInputs, gorValue } of wellGors) {
    const msRowId = `${meterStationName}`;
    let msRow = rowsLookup.get(msRowId);
    if (!msRow) {
      msRow = {
        id: msRowId,
        rowType: SchemeComponentType.MeterStation,
        name: meterStationName,
        subRows: []
      };
      rowsLookup.set(msRowId, msRow);
      parentRowsLookup.set(msRowId, msRow);
    }

    const wellRowId = msRowId + `, ${wellId}`;

    let wellRow = rowsLookup.get(wellRowId);
    if (!wellRow) {
      wellRow = {
        id: wellRowId,
        parentId: msRowId,
        rowType: SchemeComponentType.Well,
        name: wellName,
        originalId: id,
        wellId,
        gorValue,
        inputs: new Map()
      };
      msRow.subRows.push(wellRow);
      rowsLookup.set(wellRowId, wellRow);
    }

    for (const input of wellGorInputs) {
      const { weekDateId, weekDate } = input;
      if (!datesLookup.has(weekDateId)) {
        datesLookup.set(weekDateId, {
          id: weekDateId,
          weekDate: new Date(weekDate)
        });
      }

      wellRow.inputs.set(weekDateId, input);
    }
  }

  const sortedAdjustWeekDates = sortBy(adjustWeekDates, 'weekDate');
  for (const [index, weekDate] of sortedAdjustWeekDates.entries()) {
    if (!datesLookup.has(weekDate.id)) {
      datesLookup.set(weekDate.id, weekDate);
    }

    for (const row of rowsLookup.values()) {
      if (row.rowType !== SchemeComponentType.Well) {
        continue;
      }

      if (!row.inputs.has(weekDate.id)) {
        const prevWeekDate = sortedAdjustWeekDates[index - 1];
        const prevInput = row.inputs.get(prevWeekDate?.id);

        row.inputs.set(weekDate.id, {
          weekDateId: weekDate.id,
          weekDate: weekDate.weekDate,
          wellGorId: row.originalId,
          gorValue: calculateWellGor(row.gorValue, prevInput?.gorValue)
        });
      }
    }
  }
}

function sortRows(parentRowsLookup: Map<string, WellGorTableRow>) {
  const rows = Array.from(parentRowsLookup.values());
  rows.sort((a, b) => a.name?.localeCompare(b.name));
  for (const row of rows) {
    row.subRows.sort((a, b) => a.name?.localeCompare(b.name));
  }
  return rows;
}

function sortDates(datesLookup: Map<number, IGetWeekDatesDto>) {
  const dates = Array.from(datesLookup.values());
  dates.sort((a, b) => a.weekDate.getTime() - b.weekDate.getTime());
  return dates;
}

export function convertWellGorData(wellGors: IWellGorDto[], adjustWeekDates: IGetWeekDatesDto[] = []) {
  const datesLookup = new Map<number, IGetWeekDatesDto>();
  const rowsLookup = new Map<string, WellGorTableRow>();
  const parentRowsLookup = new Map<string, WellGorTableRow>();

  processWellGorData(wellGors, datesLookup, rowsLookup, parentRowsLookup, adjustWeekDates);

  const rows = sortRows(parentRowsLookup);

  const dates = sortDates(datesLookup);

  const datesByWeekNumber = groupDatesByWeekNumber(dates);
  const splitableWeekDateIds = getMondaysOnlyDates(dates);

  return {
    dates,
    datesByWeekNumber,
    datesLookup,
    splitableWeekDateIds,
    rows,
    rowsLookup
  };
}

export type WellGorData = ReturnType<typeof convertWellGorData>;
