import { groupBy } from 'lodash';
import { ReactElement, useMemo } from 'react';
import { AnnotationOptions } from '..';
import { Axis, SeriesOptions } from '../chartTypes';

export type AxisGroups = Record<string, ReactElement<Axis>[]>;
export type SeriesGroups = Record<string, ReactElement<SeriesOptions>[]>;
export type AnnotationsGroups = Record<string, ReactElement<AnnotationOptions>[]>;

export function useLayersGroups(
    Axis: ReactElement<Axis>[],
    Series: ReactElement<SeriesOptions>[],
    Annotations: ReactElement<AnnotationOptions>[]
): {
    AxisGroups: AxisGroups;
    SeriesGroups: SeriesGroups;
    AnnotationsGroups: AnnotationsGroups;
    groups: string[];
} {
    return useMemo(() => {
        const AxisGroups = groupBy(Axis, (item) => item.props.id);
        const SeriesGroups = groupBy(Series, (item) => item.props.axis);
        const AnnotationsGroups = groupBy(Annotations, (item) => item.props.axis);
        const groups = [
            ...new Set(Object.keys(AxisGroups).concat(Object.keys(SeriesGroups)).concat(Object.keys(AnnotationsGroups)))
        ];
        return { AxisGroups, SeriesGroups, AnnotationsGroups, groups };
    }, [Axis, Series, Annotations]);
}
