import { Card, CardContent, Grid, Link, Typography } from '@mui/material';
import './help.css';
export const SUPPORT_EMAIL = 'wsdhelp@tengizchevroil.com';

const USER_MANUAL_LINK = process.env.REACT_APP_USER_MANUAL_LINK;

function HelpPage() {
  return (
    <>
      <Typography variant="h5" sx={{ fontWeight: 700 }}>
        Help
      </Typography>
      <Grid container sx={{ justifyContent: 'center', height: '100%' }}>
        <Grid item container direction="column" justifyContent="center" alignItems="center" spacing={2} xs={6}>
          <Card variant="outlined">
            <CardContent>
              <Typography>
                If you encounter any issues or have suggestions regarding TCO WSD, please feel free to contact us:
                <ul className="help-link">
                  <li>
                    <Link href={`mailto:${SUPPORT_EMAIL}`}>{SUPPORT_EMAIL}</Link>
                  </li>
                </ul>
              </Typography>
              <Typography>
                For detailed information on using TCO WSD, please refer to our user manual:
                <ul className="help-link">
                  <li>
                    <Link href={USER_MANUAL_LINK} target="_blank" rel="noopener noreferrer" underline="hover">
                      TCO WSD User Manual
                    </Link>
                  </li>
                </ul>
              </Typography>
              <Typography className="help-feedback">
                Thank you for using TCO WSD. We appreciate your feedback and are here to assist you.
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </>
  );
}

export default HelpPage;
