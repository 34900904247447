import { getEdgePosition } from './getEdgePosition';
import { getNodeIntersection } from './getNodeIntersection';

/** returns the parameters (sx, sy, tx, ty, sourcePos, targetPos) you need to create an edge */
export function getEdgeParams(source, target) {
    const sourceIntersectionPoint = getNodeIntersection(source, target);
    const targetIntersectionPoint = getNodeIntersection(target, source);

    const sourcePosition = getEdgePosition(source, sourceIntersectionPoint);
    const targetPosition = getEdgePosition(target, targetIntersectionPoint);

    return {
        sourceX: sourceIntersectionPoint.x,
        sourceY: sourceIntersectionPoint.y,
        targetX: targetIntersectionPoint.x,
        targetY: targetIntersectionPoint.y,
        sourcePosition,
        targetPosition,
    };
}
