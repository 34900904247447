import { GlyphDot } from '@visx/glyph';
import { Group } from '@visx/group';
import { LinePath } from '@visx/shape';
import { AccessorForArrayItem } from '@visx/shape/lib/types';
import React, { FC, memo, useCallback, useContext, useMemo } from 'react';
import { ChartContext } from '../ChartContext';
import { Interpolation, SeriesOptions } from '../chartTypes';
import { FocusBlurHandler } from '../components/FocusBlurHandler';
import { ChartAccessor, findClosestPoint, interpolatorLookup, useAccessors } from '../utils';
import { callOrValue, isDefined } from '../utils/chartUtils';

type LineSeriesItem = {
    stroke?: string;
    label?: string;
};

export interface LineSeriesProps<T extends LineSeriesItem = any> extends SeriesOptions<T> {
    interpolation?: Interpolation;
    showPoints?: boolean;
    stroke?: string | (() => string);
    strokeDasharray?: string | number | (() => string) | (() => number);
    strokeWidth?: number | (() => number);
    strokeOpacity?: number | (() => number);
    strokeLinecap?: 'butt' | 'square' | 'round' | 'inherit';
}

/**
 * @category Component
 * @group Chart
 */
let LineSeries: FC<LineSeriesProps<any>> = <T extends LineSeriesItem>(props: LineSeriesProps<T>) => {
    const {
        id,
        axis = 'undefined',
        disableMouseEvents = false,
        data,
        xAccessor,
        yAccessor,
        interpolation = 'monotoneX',
        showPoints = false,
        stroke = 'black',
        strokeDasharray = null,
        strokeWidth = 2,
        strokeLinecap = 'round',
        strokeOpacity = 1,
        onClick,
        onMouseMove,
        onMouseLeave
    } = props;

    const {
        legend,
        margin,
        xScales,
        yScales,
        groupDimensions,
        getX: globalGetX,
        getY: globalGetY,
        handleClick: globalOnClick,
        handleMouseMove: globalOnMouseMove,
        handleMouseLeave: globalOnMouseLeave
    } = useContext(ChartContext);

    const strokeValue = callOrValue(stroke);
    const strokeOpacityValue = callOrValue(strokeOpacity);
    const curve = interpolatorLookup[interpolation];

    const { getX, getY } = useAccessors(xAccessor, globalGetX, yAccessor, globalGetY);
    const group = axis ?? 'undefined';
    const dimensions = groupDimensions[group];
    const xScale = useMemo(() => xScales[axis] ?? xScales.undefined ?? xScales.undefined, [axis, xScales]);
    const yScale = useMemo(() => yScales[axis] ?? yScales.undefined ?? yScales.undefined, [axis, yScales]);

    const x = useMemo(
        (): ChartAccessor<any> =>
            (...args) =>
                xScale?.(getX?.(...args)) ?? 0,
        [getX, xScale]
    );
    const y = useMemo(
        (): ChartAccessor<any> =>
            (...args) =>
                yScale?.(getY?.(...args)) ?? 0,
        [getY, yScale]
    );

    const defined = useMemo((): AccessorForArrayItem<T, any> => (point) => isDefined(getY?.(point)), [getY]);

    const handleClick = useCallback(
        (event) => {
            const point = findClosestPoint({
                getX,
                event,
                xScale,
                marginLeft: margin.left,
                dimensions,
                ...props
            });
            const args = { key: id, event, data, axis, getX, getY, point, color: strokeValue, options: props };
            if (onClick) {
                onClick(args);
            }

            if (globalOnClick) {
                globalOnClick(args);
            }
        },
        [axis, data, dimensions, getX, getY, globalOnClick, id, margin.left, onClick, props, strokeValue, xScale]
    );

    const handleMouseMove = useCallback(
        (event) => {
            const point = findClosestPoint({
                getX,
                event,
                xScale,
                marginLeft: margin.left,
                dimensions,
                ...props
            });
            const args = { key: id, event, data, axis, getX, getY, point, color: strokeValue, options: props };
            if (onMouseMove) {
                onMouseMove(args);
            }

            if (globalOnMouseMove) {
                globalOnMouseMove(args);
            }
        },
        [
            axis,
            data,
            dimensions,
            getX,
            getY,
            globalOnMouseMove,
            id,
            margin.left,
            onMouseMove,
            props,
            strokeValue,
            xScale
        ]
    );

    const handleMouseLeave = useCallback(
        (event) => {
            const point = findClosestPoint({
                getX,
                event,
                xScale,
                marginLeft: margin.left,
                dimensions,
                ...props
            });
            const args = { key: id, event, data, axis, getX, getY, point, color: strokeValue, options: props };
            if (onMouseLeave) {
                onMouseLeave(args);
            }

            if (globalOnMouseLeave) {
                globalOnMouseLeave(args);
            }
        },
        [
            axis,
            data,
            dimensions,
            getX,
            getY,
            globalOnMouseLeave,
            id,
            margin.left,
            onMouseLeave,
            props,
            strokeValue,
            xScale
        ]
    );

    if (!xScale || !yScale || legend.state[id]) return null;

    return (
        <Group left={(xScale.bandwidth?.() ?? 0) / 2}>
            <LinePath<T>
                data={data}
                x={x}
                y={y}
                stroke={strokeValue}
                strokeWidth={callOrValue(strokeWidth)}
                strokeDasharray={callOrValue(strokeDasharray)}
                strokeLinecap={strokeLinecap}
                strokeOpacity={strokeOpacityValue}
                curve={curve}
                defined={defined}
                onClick={!disableMouseEvents ? handleClick : undefined}
                onMouseMove={!disableMouseEvents ? handleMouseMove : undefined}
                onMouseLeave={!disableMouseEvents ? handleMouseLeave : undefined}
            />
            <Group>
                {showPoints &&
                    data.map(
                        (point, index) =>
                            // <a /> wrapper is needed for focusing with SVG 1.2 regardless of whether we show a point
                            isDefined(getX?.(point)) &&
                            isDefined(getY?.(point)) && (
                                <FocusBlurHandler
                                    key={`linepoint-${index}`}
                                    onBlur={disableMouseEvents ? undefined : handleMouseLeave}
                                    onFocus={disableMouseEvents ? undefined : handleMouseMove}
                                >
                                    {showPoints && (
                                        <GlyphDot
                                            key={`${index}-${getX?.(point)}`}
                                            cx={xScale(getX?.(point))}
                                            cy={yScale(getY?.(point))}
                                            r={4}
                                            fill={point.stroke || callOrValue(stroke, point, index)}
                                            stroke="#FFFFFF"
                                            strokeWidth={1}
                                            style={{ pointerEvents: 'none' }}
                                        >
                                            {point.label && (
                                                <text
                                                    x={xScale(getX?.(point))}
                                                    y={yScale(getY?.(point))}
                                                    dx={10}
                                                    fill={point.stroke || callOrValue(stroke, point, index)}
                                                    stroke="#fff"
                                                    strokeWidth={1}
                                                    fontSize={12}
                                                >
                                                    {point.label}
                                                </text>
                                            )}
                                        </GlyphDot>
                                    )}
                                </FocusBlurHandler>
                            )
                    )}
            </Group>
        </Group>
    );
};

LineSeries.displayName = 'LineSeries';
LineSeries = memo(LineSeries);

/** @ignore */
export { LineSeries };
