import { createSelector } from '@reduxjs/toolkit';
import { selectOfficialInputHiddenWeekDateIds } from 'store/official-input-page/OfficialInputPageSelectors';
import { RootState } from '..';
import { WellPIState } from './WellPIState';
import { getWellPISnapshot } from './utils/getWellPISnapshot';

export const wellPIPresentStateSelector = (state: RootState) => state.wellPI.present;
export const previousWellPIStateSelector = (state: RootState) => state.wellPI.past;
export const wellPIDataSelector = (state: RootState) => state.wellPI.present.data;
export const wellPILoadingSelector = (state: RootState) => state.wellPI.present.loading;
export const wellPIErrorSelector = (state: RootState) => state.wellPI.present.error;

const selectWellPIDates = (state: RootState) => wellPIPresentStateSelector(state).dates;
const selectWellPISplitableWeekDateIds = (state: RootState) => wellPIPresentStateSelector(state).splitableWeekDateIds;

export const selectWellPIWeekDays = createSelector(
  [selectWellPIDates, selectWellPISplitableWeekDateIds, selectOfficialInputHiddenWeekDateIds],
  (dates, splitableWeekDateIds, hiddenWeekDateIds) => {
    return dates?.filter((x) => splitableWeekDateIds.has(x.id) || !hiddenWeekDateIds.has(x.id));
  }
);

export const wellPISnapshotSelector = createSelector(
  [wellPIPresentStateSelector, (_state, searchQuery?: string) => searchQuery],
  (wellPI, searchQuery) => getWellPISnapshot(wellPI, searchQuery)
);

export function makeWellPIStatus(wellPI: WellPIState, deactivatedTimesteps?: Map<number, boolean>) {
  const hasPIRateChanges = wellPI.rateChanges?.size > 0;
  const hasPIChanges = wellPI.piChanges?.size > 0;
  const hasInputChanges = wellPI.inputChanges?.size > 0;

  const hasChanges = hasPIRateChanges || hasPIChanges || hasInputChanges || deactivatedTimesteps?.size;

  return {
    hasPIRateChanges,
    hasPIChanges,
    hasInputChanges,
    hasChanges
  };
}

export const wellPIStatusSelector = createSelector(wellPIPresentStateSelector, makeWellPIStatus);
