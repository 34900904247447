import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useCallback, useMemo, useState } from 'react';
import { Controller, useFieldArray, useForm, useWatch } from 'react-hook-form';
import { FilterValue, IdType, Row } from 'react-table';
import { PopoverBody } from 'reactstrap';
import { useDebouncedCallback } from 'use-debounce/lib';
import { Button } from '../../Button/Button';
import { Checkbox } from '../../Checkbox';
import { TextField } from '../../Input/TextField';
import { Toolbar } from '../../Toolbar';
import { DataGridFilterProps } from '../types/DataGridFilterProps';
import { DATA_GRID_FILTER_BLANK_OPTIONS } from './DataGridFilterBlankOptions';

const BLANK_VALUE = '__blanks__';

function filterOptions(filterValue, rowValue) {
    return !filterValue.some((hiddenOption) => {
        return hiddenOption == BLANK_VALUE
            ? DATA_GRID_FILTER_BLANK_OPTIONS.includes(rowValue)
            : hiddenOption == rowValue;
    });
}

export function filterSelect<D extends object>(
    rows: Array<Row<D>>,
    id: IdType<D>,
    filterValue: FilterValue
): Array<Row<D>> {
    return rows.filter((row) => {
        const rowValue = row.values[id];
        return filterOptions(filterValue, rowValue);
    });
}

interface ValueRecord {
    value: string;
    isSelected: boolean;
}

export const DataGridSelectFilter = <T extends object>({
    column: { id, filterValue = [], preFilteredRows, setFilter, render },
    onClose
}: DataGridFilterProps<T>) => {
    const [search, setSearch] = useState('');
    const [subFilter, setSubFilter] = useState('');

    const applyFilter = useDebouncedCallback(
        (value: string) => {
            setSubFilter(value);
        },
        300,
        { maxWait: 1000 }
    );

    const handleSearchChange = useCallback(
        (event) => {
            const value = event.target.value;
            setSearch(value);
            applyFilter.callback(value);
        },
        [applyFilter]
    );

    const options = useMemo(() => {
        const options = new Set();
        let blanksFlag = false;
        preFilteredRows.forEach((row) => {
            const value = row.values[id];
            if (!DATA_GRID_FILTER_BLANK_OPTIONS.includes(value)) {
                options.add(value);
            } else {
                blanksFlag = true;
            }
        });

        const sortedOptions = [...options.values()].sort((a: string, b: string) =>
            a?.toString()?.localeCompare?.(b?.toString(), undefined, { numeric: true, sensitivity: 'base' })
        );

        if (blanksFlag) {
            sortedOptions.unshift(BLANK_VALUE);
        }

        return sortedOptions.map((value: string): ValueRecord => {
            const rowValue = !value ? BLANK_VALUE : value;
            return {
                value: rowValue,
                isSelected: filterOptions(filterValue, value)
            };
        });
    }, [filterValue, id, preFilteredRows]);

    const { register, setValue, control, handleSubmit } = useForm({
        shouldUnregister: false,
        defaultValues: {
            value: options
        }
    });

    const { fields } = useFieldArray<ValueRecord>({
        control,
        name: 'value'
    });

    const state = useWatch({
        control,
        name: 'value',
        defaultValue: options
    });

    const rows = useMemo(() => fields.map((_, index) => index), [fields]);

    const filteredRows = useMemo(
        () =>
            rows.filter((index) => {
                const value = state[index]?.value;
                if (value && !value.toString().toLowerCase().includes(subFilter.toLowerCase())) return false;

                return true;
            }),
        [rows, state, subFilter]
    );

    const selected = useMemo(
        () => filteredRows.reduce((acc, index) => (state[index]?.isSelected ? acc + 1 : acc), 0),
        [filteredRows, state]
    );

    const indeterminate = useMemo(() => selected > 0 && selected < rows.length, [rows, selected]);

    const [all, setAll] = useState(selected > 0);

    const handleAll = useCallback(() => {
        const newState = !all;

        fields.forEach((_, index) => setValue(`value[${index}].isSelected`, newState));
        setAll(newState);
    }, [all, fields, setValue]);

    const onSubmit = useCallback(
        (result) => {
            const hiddenValues = rows
                .filter((index) => !result.value[index].isSelected || !filteredRows.includes(index))
                .map((index) => {
                    return result.value[index].value;
                    //return value === BLANK_VALUE ? undefined : value;
                });

            if (!hiddenValues.length) {
                setFilter(undefined);
            } else {
                setFilter(hiddenValues);
            }

            onClose();
        },
        [filteredRows, onClose, rows, setFilter]
    );

    const handleClear = useCallback(() => {
        setFilter(undefined);
        onClose();
    }, [onClose, setFilter]);

    return (
        <>
            <PopoverBody>
                <TextField
                    fullWidth
                    disableMargin
                    startAdornment={<FontAwesomeIcon icon={faSearch} />}
                    value={search}
                    onChange={handleSearchChange}
                />
            </PopoverBody>
            <form onSubmit={handleSubmit(onSubmit)}>
                <PopoverBody style={{ maxHeight: 200, overflow: 'auto' }}>
                    <Checkbox
                        name="all"
                        indeterminate={indeterminate}
                        checked={all}
                        label="Select all"
                        onChange={handleAll}
                    />

                    {filteredRows.map((index) => {
                        const itemValue = state[index]?.value;
                        return (
                            <Controller
                                key={index}
                                name={`value[${index}].isSelected`}
                                control={control}
                                render={({ value, onChange }) => (
                                    <Checkbox
                                        label={
                                            <>
                                                <input
                                                    type="hidden"
                                                    name={`value[${index}].value`}
                                                    defaultValue={itemValue}
                                                    ref={register()}
                                                />
                                                {itemValue === BLANK_VALUE
                                                    ? 'Blanks'
                                                    : render('Cell', {
                                                          value: itemValue
                                                      })}
                                            </>
                                        }
                                        checked={value}
                                        onChange={(event) => onChange(event.target.checked)}
                                    />
                                )}
                            />
                        );
                    })}
                </PopoverBody>
                <Toolbar disablePadding={false}>
                    <div style={{ flex: 1 }} />
                    <Button color="primary" type="submit" size="small">
                        Filter
                    </Button>
                    <Button onClick={handleClear} size="small">
                        Clear
                    </Button>
                </Toolbar>
            </form>
        </>
    );
};

export const DataGridSelectFilterFull = {
    filter: filterSelect,
    Filter: DataGridSelectFilter
};
