import cx from 'classnames';
import React, { ComponentPropsWithoutRef, forwardRef, PropsWithChildren, Ref } from 'react';
import styles from './TableRow.module.css';

/**
 * The props for the {@link TableRow} component.
 * @category Props
 */
export interface TableRowProps extends ComponentPropsWithoutRef<'tr'> {
    blockLayout?: boolean;
    selected?: boolean;
}

/**
 * @category Component
 * @group Table
 */
export const TableRow = forwardRef(function TableRow(
    { className, blockLayout, selected, ...other }: PropsWithChildren<TableRowProps>,
    ref: Ref<HTMLTableRowElement>
) {
    const Component = blockLayout ? 'div' : 'tr';
    return (
        <Component
            ref={ref}
            className={cx(className, styles.row, blockLayout ? styles.blockRow : styles.tableRow, {
                [styles.selected]: selected
            })}
            {...other}
        />
    );
});
