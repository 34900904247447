import { DataGridColumnInstance, DataGridColumnOptions } from '@tearecs/components/src/DataGrid';
import { IGetWeekDatesDto } from 'api';
import { useMemo } from 'react';
import { makeHeaderWithTooltip } from './makeHeaderWithTooltip';

const monthNames = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December'
];

export type WeekDayColumnType = 'year' | 'month' | 'weekDate';

export function getWeekDaysTree(weekDays: IGetWeekDatesDto[] = []) {
  const years = new Map<number, Map<number, Map<number, IGetWeekDatesDto>>>();

  for (const weekDay of weekDays) {
    const year = weekDay.weekDate.getFullYear();
    const month = weekDay.weekDate.getMonth();
    const day = weekDay.weekDate.getDate();

    let currentYear = years.get(year);
    if (!currentYear) {
      currentYear = new Map();
      years.set(year, currentYear);
    }

    let currentMonth = currentYear.get(month);
    if (!currentMonth) {
      currentMonth = new Map();
      currentYear.set(month, currentMonth);
    }

    currentMonth.set(day, weekDay);
  }

  return years;
}

export function getYearColumnId(year: number) {
  return `year_${year}`;
}

export function getColumnYear(columnId: string) {
  const [type, year] = columnId.split('_');

  if (type !== 'year') {
    return undefined;
  }

  return parseInt(year);
}

export function getMonthColumnId(month: number, year: number) {
  return `month_${month}_${year}`;
}

export function getColumnMonth(columnId: string) {
  const [type, month, year] = columnId.split('_');

  if (type !== 'month') {
    return undefined;
  }

  return { month: parseInt(month), year: parseInt(year) };
}

export const parseWeekDate = (weekDateId: number | string) => `weekDate_${weekDateId}`;

export function getWeekDateColumnId(weekDate: IGetWeekDatesDto) {
  return parseWeekDate(weekDate.id);
}

export function getColumnWeekDate(columnId: string) {
  const [type, id] = columnId.split('_');

  if (type !== 'weekDate') {
    return undefined;
  }

  return parseInt(id);
}

export function getWeekDateColumnType(columnId: string): WeekDayColumnType {
  const [type] = columnId.split('_');
  return type as WeekDayColumnType;
}

export function getWeekDaysColumns<TItem extends object>(
  weekDays: IGetWeekDatesDto[],
  dayAccessor: (row: TItem, weekDay: IGetWeekDatesDto) => unknown,
  weekDayOptions: Partial<DataGridColumnOptions<TItem>> = {
    disableFilters: true
  },
  monthOptions: Partial<DataGridColumnOptions<TItem>> = undefined,
  yearOptions: Partial<DataGridColumnOptions<TItem>> = undefined,
  reverseWeekDays = false
): DataGridColumnOptions<TItem>[] {
  const columns: DataGridColumnOptions<TItem>[] = [];

  const years = getWeekDaysTree(weekDays);

  for (const [year, months] of years.entries()) {
    const id = getYearColumnId(year);
    const yearColumn: DataGridColumnOptions<TItem> = {
      id,
      Header: makeHeaderWithTooltip(year.toString()),
      name: year.toString(),
      columns: [],
      ...(yearOptions as any)
    };

    for (const [month, weekDays] of months.entries()) {
      const id = getMonthColumnId(month, year);
      const monthColumn: DataGridColumnOptions<TItem> = {
        id,
        Header: makeHeaderWithTooltip(monthNames[month]),
        name: monthNames[month],
        columns: [],
        ...(monthOptions as any)
      };

      for (const [day, weekDate] of weekDays.entries()) {
        const id = getWeekDateColumnId(weekDate);
        const accessor = (row: TItem) => dayAccessor(row, weekDate);
        const dayColumn: DataGridColumnOptions<TItem> = {
          id,
          Header: day.toString(),
          accessor,
          ...weekDayOptions
        };
        monthColumn.columns.push(dayColumn);
      }

      monthColumn.columns.sort((a, b) => {
        const aName = parseInt(a.Header as string);
        const bName = parseInt(b.Header as string);
        return reverseWeekDays ? bName - aName : aName - bName;
      });
      yearColumn.columns.push(monthColumn);
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    yearColumn.columns.sort((a: any, b: any) => {
      const aName = monthNames.indexOf(a.name as string);
      const bName = monthNames.indexOf(b.name as string);
      return reverseWeekDays ? bName - aName : aName - bName;
    });
    columns.push(yearColumn);
  }

  columns.sort((a, b) => {
    const aName = parseInt(a.name);
    const bName = parseInt(b.name);
    return reverseWeekDays ? bName - aName : aName - bName;
  });

  return columns;
}

export function useWeekDateColumnDays(years: ReturnType<typeof getWeekDaysTree>, column: DataGridColumnInstance) {
  const columnType = useMemo(() => getWeekDateColumnType(column.id), [column.id]);
  const days = useMemo(() => {
    switch (columnType) {
      case 'year': {
        const year = getColumnYear(column.id);
        const months = Array.from(years.get(year).values());
        return months
          .map((month) => Array.from(month.values()))
          .flat()
          .map((x) => x.id);
      }
      case 'month': {
        const { year, month } = getColumnMonth(column.id);
        return Array.from(years.get(year).get(month).values()).map((x) => x.id);
      }
      case 'weekDate': {
        const weekDate = getColumnWeekDate(column.id);
        return [weekDate];
      }
      default: {
        return undefined;
      }
    }
  }, [columnType, column.id, years]);

  return { days, columnType };
}
