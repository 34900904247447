import React, { ChangeEvent } from 'react';
import { useController } from 'react-hook-form';
import { TextField } from '../../Input/TextField';
import { Typography } from '../../Typography';
import { useFormCell } from '../hooks';
import { DataGridCellProps } from '../types';

/**
 * @category Component
 * @group Data Grid Cell
 */
export const DataGridNumberCell = <T extends object>({ value, typographyProps }: DataGridCellProps<T>) => {
    return (
        <Typography noWrap variant="inherit" component="div" {...typographyProps}>
            {value}
        </Typography>
    );
};

/**
 * @category Component
 * @group Data Grid Cell
 */
export const DataGridNumberCellEdit = <T extends object>({ column, row }: DataGridCellProps<T>) => {
    const {
        form: { control },
        name,
        defaultValue
    } = useFormCell(column, row);
    const { field } = useController({ control, name, defaultValue });
    return (
        <TextField
            disableMargin
            fullWidth
            name={name}
            onChange={(e: ChangeEvent<HTMLInputElement>): void => {
                const acceptsOnlyNumbersAtMostFiveDecimals = /^-?\d+(\.\d{1,5})?$/;
                const { value } = e.target;
                if (acceptsOnlyNumbersAtMostFiveDecimals.test(value) || value.length === 0) {
                    return field.onChange(value);
                }
            }}
            value={field.value}
            type="number"
            style={{
                minWidth: 0
            }}
        />
    );
};

/**
 * @category Component
 * @group Data Grid Cell Config
 */
export const DataGridNumberCellFull = {
    Cell: DataGridNumberCell,
    CellEdit: DataGridNumberCellEdit
};
