import { GridColumns, GridRows } from '@visx/grid';
import { AllGridColumnsProps } from '@visx/grid/lib/grids/GridColumns';
import { AllGridRowsProps } from '@visx/grid/lib/grids/GridRows';
import { Group } from '@visx/group';
import React, { FC, memo, useContext } from 'react';
import { ChartContext } from '../ChartContext';
import { componentName, isDefined, isXAxis } from '../utils/chartUtils';

interface RenderGridProps {
    gridProps?: Partial<AllGridColumnsProps<any>> & Partial<AllGridRowsProps<any>>;
    orientations?: ('x' | 'y')[];
}

/**
 * @category Component
 * @group Chart
 */
let RenderGrid: FC<RenderGridProps> = ({ orientations = ['x', 'y'], gridProps }) => {
    const instance = useContext(ChartContext);

    const { innerHeight, innerWidth, groups, AxisGroups, groupDimensions, axisGrid, xScales, yScales } = instance;

    return (
        <>
            {groups.map((group) => {
                const axis = AxisGroups[group];
                const {
                    position: { top, left },
                    offset,
                    size: { height, width }
                } = groupDimensions[group];

                return (
                    <Group
                        key={`${group}-grids`}
                        top={top}
                        left={left}
                        height={height}
                        width={width}
                        className={`render-grids ${group}-grids`}
                    >
                        {axis.map((Child, index) => {
                            const name = componentName(Child);
                            const orientation = isXAxis(name) ? 'x' : 'y';
                            const { id, gridOffset, showGrid, globalGrid, gridStroke = 'lightgray' } = Child.props;
                            if (!showGrid || !orientations.includes(orientation)) {
                                return null;
                            }
                            const { gridValues, numTicks } = axisGrid[group][index];
                            const Component: any = isXAxis(name) ? GridColumns : GridRows;
                            const scale = isXAxis(name) ? xScales[id] : yScales[id];

                            if (!scale) {
                                return null;
                            }

                            const leftPosition = orientation === 'x' ? offset.left : 0;

                            return (
                                scale && (
                                    <Component
                                        key={`${group}-axis-${index}-grid`}
                                        scale={scale}
                                        top={globalGrid ? 0 - top : 0}
                                        left={globalGrid ? 0 - left : leftPosition}
                                        height={globalGrid ? innerHeight : height}
                                        width={globalGrid ? innerWidth : width}
                                        numTicks={numTicks}
                                        stroke={gridStroke}
                                        strokeWidth={1}
                                        tickValues={gridValues}
                                        offset={
                                            isDefined(gridOffset)
                                                ? gridOffset
                                                : (scale?.bandwidth && scale.bandwidth() / 2) || 0
                                        }
                                        {...gridProps}
                                    />
                                )
                            );
                        })}
                    </Group>
                );
            })}
        </>
    );
};

RenderGrid = memo(RenderGrid);

export { RenderGrid };
