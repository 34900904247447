import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'store';
import { getWellPISnapshot } from 'store/wellPI/utils/getWellPISnapshot';
import { makeWellPIStatus } from 'store/wellPI/wellPISelectors';
import { selectDeactivatedTimestepsChanged } from '../CasesSelector';

export const wellPICaseStateSelector = (state: RootState) => state.wellPICase.present;
export const previousWellPICaseStateSelector = (state: RootState) => state.wellPICase.past;
export const wellPICaseSnapshotSelector = createSelector(
  [wellPICaseStateSelector, (_state, searchQuery?: string) => searchQuery],
  (wellPI, searchQuery) => getWellPISnapshot(wellPI, searchQuery)
);
export const wellPICaseStatusSelector = createSelector(
  [wellPICaseStateSelector, selectDeactivatedTimestepsChanged],
  (state, deactivatedTimesteps) => makeWellPIStatus(state, deactivatedTimesteps)
);
