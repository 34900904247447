import React, { ComponentType, FC, useCallback, useMemo } from 'react';
import { BezierEdge, EdgeProps, SmoothStepEdge, StepEdge, StraightEdge, useStore } from 'reactflow';
import { DiagramEdgeData, EdgePathType } from '../types';
import { getEdgeParams } from '../utils/getEdgeParams';

const edgeTypes: Record<EdgePathType, ComponentType<EdgeProps<any>>> = {
    bezier: BezierEdge,
    straight: StraightEdge,
    step: StepEdge,
    smoothstep: SmoothStepEdge,
};

/**
 * The props for the {@link DiagramEdge} component.
 * @category Props
 */
export interface DiagramEdgeProps<TItem extends DiagramEdgeData = any> extends EdgeProps<TItem> {
    className?: string;
    floating?: DiagramEdgeData['floating'];
    pathType?: DiagramEdgeData['pathType'];
}

/**
 * @category Component
 * @group Diagram
 */
export const DiagramEdge: FC<DiagramEdgeProps> = (props) => {
    const { className, source, target, data, floating: floatingProp, pathType: pathTypeProp } = props;
    const { pathType, floating } = data ?? {};

    const sourceNode = useStore(useCallback((store) => store.nodeInternals.get(source), [source]));
    const targetNode = useStore(useCallback((store) => store.nodeInternals.get(target), [target]));

    const floatingProps = useMemo(() => {
        if (!floating && !floatingProp) {
            return undefined;
        }

        return getEdgeParams(sourceNode, targetNode);
    }, [floating, floatingProp, sourceNode, targetNode]);

    const EdgeComponent = edgeTypes[pathTypeProp ?? pathType ?? 'bezier'];

    return (
        <g className={className}>
            <EdgeComponent {...props} {...floatingProps} />
        </g>
    );
};
