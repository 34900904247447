import {
  Checkbox as MuiCheckbox,
  CheckboxProps as MuiCheckboxProps,
  FormControlLabel,
  FormControlLabelProps
} from '@mui/material';

export interface CheckboxProps extends Omit<FormControlLabelProps, 'control'> {
  value: MuiCheckboxProps['checked'];
  onChange: MuiCheckboxProps['onChange'];
  checkboxProps?: MuiCheckboxProps;
}

export function Checkbox({ onChange, value, checkboxProps, ...other }: CheckboxProps) {
  return (
    <FormControlLabel control={<MuiCheckbox checked={value} onChange={onChange} {...checkboxProps} />} {...other} />
  );
}

export default Checkbox;
