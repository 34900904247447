import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { ConstraintType, IGetWeekDatesDto } from 'api';
import { handleError, handleLoading } from 'store/base/baseStateHandlers';
import { ConstraintsAsInputsKeyAccessor, getConstrainsAsInputData } from './actions/getConstrainsAsInputData';
import { fillMissedInputs } from './actions/setMissedInputs';
import {
  ConstraintEquipmentNameRowPayload,
  updateConstraintEquipmentNameRow
} from './actions/updateConstraintEquipmentNameRow';
import { ConstraintsAsInputTableRow, EquipmentNameRow, EquipmentTypeRow } from './types/ConstraintsRow';
import { ConstraintsSelectType } from './types/ConstraintsSelectType';
import { ConstraintsState } from './types/ConstraintsState';
import { EquipmentTypeAccessor } from './types/EquipmentTypeAccessor';
import { constraintsOptions, constraintsOptionsMap, equipmentTypeOptions } from './utils';
import { convertConstrainsAsInputData } from './utils/convertConstrainsAsInputData';
import { setConstraintsInitialState } from './utils/getConstraintsInitialState';

export const initialState: ConstraintsState = {
  MAIN: {
    rows: [],
    weekDates: [],
    constraintsOptions,
    equipmentTypeOptions,
    enableSave: false
  }
};

type ConstraintRowIdType = {
  constraintRowId: ConstraintType;
};
type EquipmentTypeRowIdType = {
  equipmentTypeRowId: EquipmentTypeAccessor;
};
type EquipmentTypeNameRowIdType = {
  equipmentNameRowId: number;
};
type WeekDatesType = {
  weekDates: IGetWeekDatesDto[];
};
type EquipmentTypeOption = {
  option: { id: EquipmentTypeAccessor; name: string };
};
type EquipmentNameOption = {
  option: { id: number; name: string };
};
type ConstraintsAsInputTableRowParam = {
  row: ConstraintsAsInputTableRow;
};
type ConstraintsSelectTypeParams = {
  option: ConstraintsSelectType;
};

export const { reducer: constraintsAsInputsReducer, actions: constraintsAsInputsActions } = createSlice({
  name: 'constraints-as-inputs',
  initialState,
  reducers: {
    addConstraint(
      state: ConstraintsState,
      action: PayloadAction<ConstraintsAsInputsKeyAccessor & ConstraintRowIdType>
    ) {
      const { constraintRowId, keyAccessor } = action.payload;
      const eqTypeRow: EquipmentTypeRow = {
        id: undefined,
        type: 'EquipmentType',
        constraintRowId,
        subRows: []
      };
      const constraintRow: ConstraintsAsInputTableRow = {
        id: constraintRowId,
        name: constraintsOptionsMap.get(constraintRowId) ?? 'Unknown',
        type: 'Constraint',
        subRows: [eqTypeRow]
      };
      state[keyAccessor].rows.push(constraintRow);

      state[keyAccessor].constraintsOptions = state[keyAccessor].constraintsOptions.filter(
        (option) => option.id !== constraintRowId
      );
    },
    removeConstraint(
      state: ConstraintsState,
      action: PayloadAction<ConstraintsAsInputsKeyAccessor & ConstraintsSelectTypeParams>
    ) {
      const { keyAccessor, option } = action.payload;
      state[keyAccessor].rows = state[keyAccessor].rows.filter((row) => row.id !== option.id);
      state[keyAccessor].constraintsOptions.push(option);
      state[keyAccessor].enableSave = option.id !== undefined;
    },
    setMissedInputs(state: ConstraintsState, action: PayloadAction<ConstraintsAsInputsKeyAccessor & WeekDatesType>) {
      const { weekDates, keyAccessor } = action.payload;
      setConstraintsInitialState(state, keyAccessor);
      state[keyAccessor].weekDates = weekDates;
      fillMissedInputs(state[keyAccessor]);
    },
    addEquipmentType(
      state: ConstraintsState,
      action: PayloadAction<ConstraintsAsInputsKeyAccessor & ConstraintsAsInputTableRowParam>
    ) {
      const { keyAccessor, row } = action.payload;
      const rows = state[keyAccessor].rows;
      const index = rows.findIndex((x) => x.id === row.id);
      const subRow: EquipmentTypeRow = {
        id: undefined,
        constraintRowId: rows[index].id as ConstraintType,
        type: 'EquipmentType',
        subRows: []
      };
      rows[index].subRows.push(subRow);
    },
    setEquipmentType(
      state: ConstraintsState,
      action: PayloadAction<
        ConstraintsAsInputsKeyAccessor &
          EquipmentTypeOption &
          ConstraintRowIdType &
          EquipmentTypeRowIdType &
          WeekDatesType
      >
    ) {
      const { constraintRowId, equipmentTypeRowId, option, weekDates, keyAccessor } = action.payload;
      const rows = state[keyAccessor].rows;
      const { subRows: eqTypeRows } = rows.find((r) => r.id === constraintRowId);
      const index = eqTypeRows.findIndex((x) => x.id === equipmentTypeRowId);
      const eqNameRow: EquipmentNameRow = {
        id: undefined,
        type: 'EquipmentName',
        constraintRowId,
        equipmentTypeRowId: option.id,
        inputs: new Map(
          weekDates.map((dto) => [
            dto.id,
            {
              weekDateId: dto.id,
              constraintValue: 0
            }
          ])
        )
      };
      eqTypeRows[index] = { ...eqTypeRows[index], ...option, subRows: [eqNameRow] };
      state[keyAccessor].enableSave = true;
    },
    removeEquipmentType(
      state: ConstraintsState,
      action: PayloadAction<ConstraintsAsInputsKeyAccessor & ConstraintRowIdType & EquipmentTypeRowIdType>
    ) {
      const { constraintRowId, equipmentTypeRowId, keyAccessor } = action.payload;
      const rows = state[keyAccessor].rows;
      const { subRows } = rows.find((r) => r.id === constraintRowId);
      const index = subRows.findIndex((x) => x.id === equipmentTypeRowId);
      subRows.splice(index, 1);
      state[keyAccessor].enableSave = true;
    },
    addEquipmentName(
      state: ConstraintsState,
      action: PayloadAction<
        ConstraintsAsInputsKeyAccessor & ConstraintRowIdType & EquipmentTypeRowIdType & WeekDatesType
      >
    ) {
      const { constraintRowId, equipmentTypeRowId, weekDates, keyAccessor } = action.payload;
      const rows = state[keyAccessor].rows;
      const { subRows: eqTypeRows } = rows.find((r) => r.id === constraintRowId);
      const { subRows } = eqTypeRows.find((x) => x.id === equipmentTypeRowId);
      const eqNameRow: EquipmentNameRow = {
        id: undefined,
        type: 'EquipmentName',
        constraintRowId,
        equipmentTypeRowId,
        inputs: new Map(
          weekDates.map((dto) => [
            dto.id,
            {
              weekDateId: dto.id,
              constraintValue: 0
            }
          ])
        )
      };
      subRows.push(eqNameRow);
    },
    setEquipmentName(
      state: ConstraintsState,
      action: PayloadAction<
        ConstraintsAsInputsKeyAccessor &
          ConstraintRowIdType &
          EquipmentTypeRowIdType &
          EquipmentTypeNameRowIdType &
          EquipmentNameOption
      >
    ) {
      const { constraintRowId, equipmentTypeRowId, equipmentNameRowId, option, keyAccessor } = action.payload;
      const rows = state[keyAccessor].rows;
      const { subRows: eqTypeRows } = rows.find((r) => r.id === constraintRowId);
      const { subRows } = eqTypeRows.find((r) => r.id === equipmentTypeRowId);
      const index = subRows.findIndex((x) => x.id === equipmentNameRowId);
      subRows[index] = { ...subRows[index], ...option, equipmentTypeEntityId: option.id };
      state[keyAccessor].enableSave = true;
    },
    removeEquipmentName(
      state: ConstraintsState,
      action: PayloadAction<
        ConstraintsAsInputsKeyAccessor & ConstraintRowIdType & EquipmentTypeRowIdType & EquipmentTypeNameRowIdType
      >
    ) {
      const { constraintRowId, equipmentTypeRowId, equipmentNameRowId, keyAccessor } = action.payload;
      const rows = state[keyAccessor].rows;
      const { subRows: eqTypeRows } = rows.find((r) => r.id === constraintRowId);
      const { subRows } = eqTypeRows.find((r) => r.id === equipmentTypeRowId);
      const index = subRows.findIndex((x) => x.id === equipmentNameRowId);
      subRows.splice(index, 1);
      state[keyAccessor].enableSave = true;
    },
    editRow(state: ConstraintsState, action: PayloadAction<ConstraintEquipmentNameRowPayload>) {
      updateConstraintEquipmentNameRow(state, action.payload);
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getConstrainsAsInputData.pending, (state, action) => {
        const keyAccessor = action.meta.arg.keyAccessor;
        setConstraintsInitialState(state, keyAccessor);
        handleLoading(state[keyAccessor]);
      })
      .addCase(getConstrainsAsInputData.rejected, (state, action) => {
        const keyAccessor = action.meta.arg.keyAccessor;
        handleError(state[keyAccessor], action);
      })
      .addCase(getConstrainsAsInputData.fulfilled, (state, action) => {
        const keyAccessor = action.meta.arg.keyAccessor;
        const { rows, constraintTypeOptions } = convertConstrainsAsInputData(action.payload);
        state[keyAccessor].rows = rows;
        state[keyAccessor].constraintsOptions = constraintTypeOptions;
        fillMissedInputs(state[keyAccessor]);
        state[keyAccessor].enableSave = false;
      });
  }
});
