import { Accessor } from '@visx/shape/lib/types';
import { useMemo } from 'react';

export type ChartSeriesAccessor<T> = Accessor<T, any> | string;
export type ChartAccessor<T> = Accessor<T, any> | undefined;

export const createAccessor = <T>(accessor?: ChartSeriesAccessor<T>): ChartAccessor<T> => {
    return typeof accessor == 'function'
        ? (point: T) => accessor(point)
        : typeof accessor == 'string'
        ? (point: T) => (point as any)?.[accessor as any]
        : undefined;
};

export function useAccessors<T>(
    xAccessor?: ChartSeriesAccessor<T>,
    globalGetX?: ChartAccessor<T>,
    yAccessor?: ChartSeriesAccessor<T>,
    globalGetY?: ChartAccessor<T>
) {
    return useMemo(() => {
        const getX = createAccessor(xAccessor) ?? globalGetX;
        const getY = createAccessor(yAccessor) ?? globalGetY;
        return { getX, getY };
    }, [globalGetX, globalGetY, xAccessor, yAccessor]);
}
