import {
  ChildConstraintDto,
  ConstraintInputDto,
  ConstraintTypeDto,
  IChildConstraintDto,
  IConstraintTypeDto
} from 'api';
import { ConstraintRow, ConstraintsAsInputTableRow, EquipmentNameRow } from '../types/ConstraintsRow';

export const convertConstraintsAsInputsRowsIntoDto = (rows: ConstraintsAsInputTableRow[]): ConstraintTypeDto[] => {
  const dtos = [];
  rows.forEach((row: ConstraintRow) => {
    if (row.id === undefined) return;
    const dto: IConstraintTypeDto = {
      groupName: row.id,
      childConstraint: []
    };
    row.subRows.forEach((eqTypeRow) => {
      eqTypeRow.subRows.forEach((eqNameRow: EquipmentNameRow) => {
        if (eqNameRow.id === undefined) return;
        const childConstraint: IChildConstraintDto = {
          id: eqNameRow.entityId,
          [eqNameRow.equipmentTypeRowId]: eqNameRow.equipmentTypeEntityId,
          constraintInputs: []
        };
        eqNameRow.inputs.forEach((constraintInputDto) => {
          childConstraint.constraintInputs.push(ConstraintInputDto.fromJS(constraintInputDto));
        });
        dto.childConstraint.push(ChildConstraintDto.fromJS(childConstraint));
      });
    });
    dtos.push(ConstraintTypeDto.fromJS(dto));
  });

  return dtos;
};
