import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'store';
import { getWellGorSnapshot } from 'store/wellGor/utils/getWellGorSnapshot';
import { makeWellGorStatus } from 'store/wellGor/wellGorSelectors';
import { selectDeactivatedTimestepsChanged } from '../CasesSelector';

export const wellGorCaseStateSelector = (state: RootState) => state.wellGorCase.present;
export const previousWellGorCaseStateSelector = (state: RootState) => state.wellGorCase.past;
export const wellGorCaseSnapshotSelector = createSelector(
  [wellGorCaseStateSelector, (_state, searchQuery?: string) => searchQuery],
  (wellGor, searchQuery) => getWellGorSnapshot(wellGor, searchQuery)
);
export const wellGorCaseStatusSelector = createSelector(
  [wellGorCaseStateSelector, selectDeactivatedTimestepsChanged],
  (state, deactivatedTimesteps) => makeWellGorStatus(state, deactivatedTimesteps)
);
