import { createSelector } from '@reduxjs/toolkit';
import { SchemeComponentType } from 'api';
import { RootState } from 'store';
import { selectCasesPresentState } from '../cases/CasesSelector';

export const selectOfficialInputState = (state: RootState) => state.officialInput;
export const selectOfficialInputPresentState = (state: RootState) => state.officialInput.present;
export const hasOfficialInputPastHistoryLength = (state: RootState) => state.officialInput.past.length > 0;

export const selectOfficialInputUserChanges = (state: RootState) => selectOfficialInputPresentState(state).changes;
export const selectOfficialInputDisableApplyLineupButton = (state: RootState) =>
  selectOfficialInputPresentState(state).disableApplyLogicButton;

export const selectOfficialInputStateHasChanges = createSelector(selectOfficialInputUserChanges, (changes) => {
  const hasMeterStationChanges = Array.from(changes.MeterStation.values()).some((rowChanges) => rowChanges.size > 0);
  const hasHeaderChanges = Array.from(changes.Header.values()).some((rowChanges) => rowChanges.size > 0);
  const hasWellChanges = Array.from(changes.Well.values()).some((rowChanges) => rowChanges.size > 0);
  const hasValveChanges = Array.from(changes.Valve.values()).some((rowChanges) => rowChanges.size > 0);

  return hasMeterStationChanges || hasHeaderChanges || hasValveChanges || hasWellChanges;
});

export const changedStatusSelector =
  (rowType: SchemeComponentType, rowId: string, weekId: number, isOfficialInput: boolean) => (state: RootState) => {
    const { changes } = isOfficialInput ? selectOfficialInputPresentState(state) : selectCasesPresentState(state);

    return changes[rowType]?.get(rowId)?.get(weekId);
  };
