import { IWedgeDto } from 'api';
import { ResultByWeekDay } from '../types/DashboardWedgeState';

export function convertWedgeData(wedge: IWedgeDto) {
  const weekDates = new Map<number, ResultByWeekDay>();

  for (const wedgeResult of wedge?.wedgeResults ?? []) {
    const { weekDate, result, weekDateId } = wedgeResult;
    weekDates.set(weekDateId, {
      weekDate,
      weekDateId,
      result: Math.round(result)
    });
  }

  return weekDates;
}
