import {
  ISetScenarioCaseActivityRequestDto,
  P6Client,
  P6Dto,
  ScenarioClient,
  SetScenarioCaseActivityRequestDto,
  WebJobHistoryClient,
  WebJobHistoryDto,
  WsdWebJobType
} from 'api';
import { rootApi } from 'store/rootApi';
import { P6_ACTIVITIES_BY_CASES_CACHE_TAG, P6_UPDATE_DATE_CACHE_TAG } from './constants';

const webJobHistoryService = new WebJobHistoryClient();
const p6Service = new P6Client();
const scenarioService = new ScenarioClient();

export type SetActivityVisiblePayload = P6Dto & {
  isKTL: boolean;
}

type GetAllQuery = {
  isKtl: boolean;
  scenarioCaseId: number;
  wedgeGraphId?: number;
  isWedgeGraph?: boolean;
}

export const p6Api = rootApi
  .enhanceEndpoints({ addTagTypes: [P6_ACTIVITIES_BY_CASES_CACHE_TAG, P6_UPDATE_DATE_CACHE_TAG] })
  .injectEndpoints({
    endpoints: (builder) => ({
      p6ActivitiesByScenarioCaseId: builder.query({
        queryFn: ({ isKtl, scenarioCaseId, wedgeGraphId, isWedgeGraph }: GetAllQuery) => {
          return p6Service
            .getAll(scenarioCaseId, wedgeGraphId, isKtl, isWedgeGraph)
            .then((data) => ({ data }))
            .catch((error) => ({ error }));
        },
        providesTags: (_result, _error, { scenarioCaseId }) => [
          { type: P6_ACTIVITIES_BY_CASES_CACHE_TAG, id: scenarioCaseId }
        ]
      }),
      getP6UpdateDate: builder.query({
        queryFn: (_: void) =>
          webJobHistoryService
            .get(new WebJobHistoryDto({ webJobType: WsdWebJobType.P6ReplicationJob }))
            .then((data) => ({ data }))
            .catch((error) => ({ error })),
        providesTags: () => [P6_UPDATE_DATE_CACHE_TAG]
      }),
      setActivityVisible: builder.mutation({
        queryFn: (inputData: ISetScenarioCaseActivityRequestDto) =>
          scenarioService
            .setActivity(SetScenarioCaseActivityRequestDto.fromJS(inputData))
            .then((data) => ({ data }))
            .catch((error) => ({ error })),
        invalidatesTags: (_result, _error, { scenarioCaseId }) => [
          { type: P6_ACTIVITIES_BY_CASES_CACHE_TAG, id: scenarioCaseId }
        ]
      })
    })
  });
