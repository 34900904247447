import { Grid, Typography } from '@mui/material';
import { useUserData } from 'components/Security/useUserData';
import { TileButton } from 'components/common';
import usePageTitle from '../../utils/usePageTitle';

function StartPage() {
  usePageTitle('Start page');

  const { name } = useUserData();

  return (
    <>
      <Typography variant="h5" sx={{ fontWeight: 700, mb: 1 }}>
        Hello, {name}
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <TileButton text="Official Inputs" to="official-inputs" />
        </Grid>
        <Grid item xs={6}>
          <TileButton text="Scenarios" to="scenarios" />
        </Grid>
        <Grid item xs={6}>
          <TileButton text="Simulation" to="simulation" />
        </Grid>
        <Grid item xs={6}>
          <TileButton text="Dashboard" to="dashboard/results" />
        </Grid>
      </Grid>
    </>
  );
}

export default StartPage;
