import { Outlet, RouteObject } from 'react-router-dom';
import { lazyComponent } from 'utils/lazyComponent';
import { caseRoute } from './case/routes';
import { scenarioCompareCaseRoute } from './compare/routes';

const Scenarios = lazyComponent(() => import('./index'), 'scenarios');

export const scenariosRoutes: RouteObject[] = [
  {
    path: 'scenarios',
    element: <Outlet />,
    children: [
      {
        index: true,
        element: <Scenarios />
      },
      {
        path: '',
        element: <Outlet />,
        children: [scenarioCompareCaseRoute]
      },
      {
        path: ':scenarioId',
        element: <Outlet />,
        children: [caseRoute]
      }
    ]
  }
];
