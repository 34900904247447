import React, { FC, PropsWithChildren, useCallback, useMemo, useState } from 'react';
import { ChartMiniMapContext } from '../../ChartContext';
import { ChartMiniMapParams, ChartOptions } from '../../chartTypes';

interface Props {
    options: ChartOptions['miniMap'];
}
export const ChartMiniMapProvider: FC<PropsWithChildren<Props>> = ({ children, options }) => {
    const [open, setOpen] = useState(true);
    const toggleMiniMap = useCallback(() => setOpen((open) => !open), []);

    const value: ChartMiniMapParams = useMemo(
        () => ({
            options,
            open,
            toggleMiniMap
        }),
        [open, options]
    );

    return <ChartMiniMapContext.Provider value={value}>{children}</ChartMiniMapContext.Provider>;
};
