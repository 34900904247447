import { WeekDateClient } from 'api';
import { rootApi } from 'store/rootApi';

const service = new WeekDateClient();

export const weekDatesApi = rootApi.enhanceEndpoints({ addTagTypes: ['weekDates'] }).injectEndpoints({
  endpoints: (builder) => ({
    getWeekDates: builder.query({
      queryFn: (forwardMonths = 18) =>
        service
          .get(forwardMonths)
          .then((data) => ({ data }))
          .catch((error) => ({ error })),
      providesTags: (_result, _error, forwardMonths) => [{ type: 'weekDates', id: forwardMonths }]
    })
  })
});
