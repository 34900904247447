import { ILineupSchemeResponseDto } from 'api';
import { SchemeType } from '../types';

import { convertToDiagramEdges } from './convertToDiagramEdges';
import { convertToDiagramNodeData } from './convertToDiagramNodeData';
import { createHandlesBasedOnNodes } from './createHandlesBasedOnNodes';

export const convertToDiagramData = (scheme: Pick<ILineupSchemeResponseDto, 'edges' | 'nodes'>): SchemeType => {
  const edges = convertToDiagramEdges(scheme.edges);

  const handles = createHandlesBasedOnNodes(scheme.nodes);

  const nodes = convertToDiagramNodeData(scheme.nodes, handles);

  return { nodes, edges };
};
