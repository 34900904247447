import { PiWellDto } from 'api';
import { useMemo } from 'react';
import { getMonthYear } from 'utils/dateUtil';

interface ChartSeriesItem {
  value: number;
  date: Date;
  month?: string;
}

interface ChartSeries {
  tubingPressure?: ChartSeriesItem[];
  ftpRate?: ChartSeriesItem[];
}

export function useWellPiHistorianChartSeries(data: PiWellDto[]) {
  return useMemo(() => {
    const fieldsToExtract = ['tubingPressure', 'ftpRate'];
    return fieldsToExtract.reduce((prev: ChartSeries, current) => {
      return {
        ...prev,
        [current]: data
          ?.map((item) => ({
            value: item[current],
            date: item.timestamp,
            month: getMonthYear(item.timestamp)
          }))
          .sort((a, b) => a.date.getTime() - b.date.getTime())
      };
    }, {});
  }, [data]);
}
