import { RouteObject } from 'react-router-dom';
import { lazyComponent } from 'utils/lazyComponent';
import Scheme from '.';

const MainScheme = lazyComponent(() => import('./components/mainScheme/MainScheme'), 'mainScheme');
const MeterStationScheme = lazyComponent(() => import('./components/meterStationScheme/MeterStationScheme'), 'meterStationScheme');

export const schemeRoutes: RouteObject[] = [
  {
    path: 'scheme',
    element: <Scheme />,
    children: [
      {
        path: '',
        element: <MainScheme />
      },
      {
        path: ':meterStationId',
        element: <MeterStationScheme />
      }
    ]
  }
];
