import React, {
    cloneElement,
    forwardRef,
    HTMLProps,
    ReactElement,
    SyntheticEvent,
    useCallback,
    useRef,
    useState
} from 'react';

import { useForkRef } from '../util';

import { Popover, PopoverProps } from '../Popover';

/**
 * The props for the {@link ContextMenu} component.
 * @category Props
 */
export interface ContextMenuProps {
    children: any;
    menu: ReactElement<ContextMenuContentProps>;
    popoverProps?: Partial<PopoverProps>;
}

export interface ContextMenuContentProps {
    onCloseMenu?: (event: any, reason?: string) => void;
}

interface ContextMenuState {
    open: boolean;
    popoverProps: Partial<PopoverProps>;
}

/**
 * @category Component
 * @group Context Menu
 */
export const ContextMenu = forwardRef(function ContextMenu(
    { children, menu: menuNode, popoverProps = {} }: ContextMenuProps,
    ref
) {
    const { onClose, ...otherPopoverProps } = popoverProps;
    const nodeRef = useRef(null);
    const childrenRef = useForkRef(children.ref, nodeRef);
    const handleRef = useForkRef(childrenRef, ref);

    const [state, setState] = useState<ContextMenuState>({
        open: false,
        popoverProps: null
    });

    const handleClose = useCallback(
        (event, reason = '') => {
            setState({
                open: false,
                popoverProps: null
            });

            if (onClose) {
                onClose(event, reason);
            }
        },
        [onClose]
    );

    const handleClick = useCallback((event: SyntheticEvent<HTMLDivElement, MouseEvent>) => {
        event.stopPropagation();
    }, []);

    const handleContextMenu = useCallback(
        (event: SyntheticEvent<HTMLDivElement, MouseEvent>) => {
            event.preventDefault();

            if (event.nativeEvent.clientX && event.nativeEvent.clientY) {
                setState({
                    open: true,
                    popoverProps: {
                        anchorReference: 'anchorPosition',
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'left'
                        },
                        anchorPosition: {
                            top: event.nativeEvent.clientY,
                            left: event.nativeEvent.clientX
                        }
                    }
                });
            } else {
                setState({
                    open: true,
                    popoverProps: {
                        anchorReference: 'anchorEl',
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        },
                        anchorEl: event.target as Element
                    }
                });
            }
        },
        [setState]
    );

    const childrenProps: HTMLProps<HTMLDivElement> = { ref: handleRef, onContextMenu: handleContextMenu };
    const child = cloneElement(children, childrenProps);

    const menuProps: ContextMenuContentProps = { onCloseMenu: handleClose };
    const menu = cloneElement(menuNode, menuProps);

    return (
        <>
            {child}
            <Popover
                open={state.open}
                {...otherPopoverProps}
                {...state.popoverProps}
                onClick={handleClick}
                onClose={handleClose}
            >
                <div onClick={handleClick}>{menu}</div>
            </Popover>
        </>
    );
});
