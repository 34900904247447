import cx from 'classnames';
import moment, { isDate } from 'moment';
import React, { forwardRef, HTMLProps, useCallback, useContext, useMemo } from 'react';
import { createChainedFunction } from '../util';
import { CalendarContext } from './CalendarContext';
import { monthNamesShort } from './calendarUtils';
import { defaultMonthWrapper, MonthButton } from './MonthButton';
import styles from './Year.module.css';

/**
 * The props for the {@link Year} component.
 * @category Props
 */
interface YearProps extends HTMLProps<HTMLDivElement> {}

/**
 * @category Component
 * @group Date Picker
 */
export const Year = forwardRef<HTMLDivElement, YearProps>(({ className, ...other }, ref) => {
    const instance = useContext(CalendarContext);

    const {
        offset,
        selected,
        calendars,
        minDate,
        maxDate,
        onDateSelected,
        handleDepthDowngrade,
        handleOffsetChanged,
        MonthComponent = MonthButton,
        MonthWrapper = defaultMonthWrapper
    } = instance;

    const calendar = calendars[0];
    const baseDate = calendar.firstDayOfMonth;

    const selectedDates = useMemo(() => {
        if (Array.isArray(selected)) {
            return selected;
        }
        return [selected];
    }, [selected]);

    const prepareMonth = useCallback(
        (month: number) => {
            const todayDate = new Date();
            const firstDayOfMonth = new Date(calendar.firstDayOfMonth.getFullYear(), month, 1);
            const newOffset = (offset ?? 0) + moment(firstDayOfMonth).diff(baseDate, 'months');

            const selected =
                selectedDates
                    .filter((x) => isDate(x))
                    .some(
                        (date) =>
                            date.getMonth() === firstDayOfMonth.getMonth() &&
                            date.getFullYear() === firstDayOfMonth.getFullYear()
                    ) ?? false;

            const selectable =
                (minDate &&
                    firstDayOfMonth.getMonth() < minDate.getMonth() &&
                    firstDayOfMonth.getFullYear() <= minDate.getFullYear()) ||
                (maxDate &&
                    maxDate.getMonth() < firstDayOfMonth.getMonth() &&
                    maxDate.getFullYear() <= firstDayOfMonth.getFullYear())
                    ? false
                    : true;

            const today =
                firstDayOfMonth.getMonth() === todayDate.getMonth() &&
                firstDayOfMonth.getFullYear() === todayDate.getFullYear();

            const dateObj = {
                date: firstDayOfMonth,
                nextMonth: true,
                prevMonth: true,
                selectable,
                selected,
                today
            };

            function getMonthProps({ onClick, ...rest }: Record<string, any> = {}) {
                return {
                    onClick: createChainedFunction(onClick, (event) => {
                        if (handleDepthDowngrade()) {
                            handleOffsetChanged(newOffset);
                            return;
                        }

                        onDateSelected(dateObj, event);
                    }),
                    disabled: !dateObj.selectable,
                    'aria-label': dateObj.date.toDateString(),
                    'aria-pressed': dateObj.selected,
                    role: 'button',
                    ...rest
                };
            }

            return { currentDate: firstDayOfMonth, dateObj, selected, selectable, today, getMonthProps };
        },
        [
            baseDate,
            calendar.firstDayOfMonth,
            handleDepthDowngrade,
            handleOffsetChanged,
            maxDate,
            minDate,
            offset,
            onDateSelected,
            selectedDates
        ]
    );

    const RenderMonth = useMemo(() => MonthWrapper(MonthComponent), [MonthComponent, MonthWrapper]);

    return (
        <div className={cx(styles.months, className)} {...other}>
            {monthNamesShort.map((_, monthIndex) => {
                const key = `${monthIndex}${calendar.year}`;
                const { currentDate, selected, selectable, today, getMonthProps } = prepareMonth(monthIndex);

                return (
                    <RenderMonth
                        key={key}
                        {...getMonthProps()}
                        selected={selected}
                        unavailable={!selectable}
                        today={today}
                        date={currentDate}
                    />
                );
            })}
        </div>
    );
});
