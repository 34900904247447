import { AnyAction, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SchemeComponentType } from 'api';
import { castDraft } from 'immer';
import undoable, { GroupByFunction, includeAction } from 'redux-undo';
import { handleError, handleLoading, handleSuccess } from 'store/base/baseStateHandlers';
import { sealWellInputChanges } from 'store/official-inputs/wellInput/actions/sealWellInputChanges';
import { CopySimulationCaseType } from 'store/wellPressure/types/CopySimulationCaseType';
import { applyLineupLogicAction } from './actions/applyLineupLogicAction';
import { officialInputClearHistory, OfficialInputJumpToPastType, officialInputUndo } from './actions/officialInputUndo';
import { saveOfficialInputChanges } from './actions/saveOfficialInputChanges';
import { setSelectedCells } from './actions/setSelectedCells';
import { copyLineupCellsAction, pasteLineupCellsAction } from './lineup/actions/copyPasteCells';
import { copyLineupColumnsAction, pasteLineupColumnsAction } from './lineup/actions/copyPasteColumns';
import { getLineup } from './lineup/actions/getLineup';
import { setLineupStatusArray, setLineupStatusChanges } from './lineup/actions/lineupStatusChanges';
import { sealLineupChanges } from './lineup/actions/sealLineupChanges';
import { LineupTableRow } from './lineup/types/LineupTableRow';
import { ToggleLineupStatusPayload, ToggleStatusPayload } from './lineup/types/ToggleStatusPayload';
import { convertLineupData } from './lineup/utils/convertLineupData';
import { OfficialInputExtendedState } from './OfficialInputState';
import { CopyPayload, PasteCellsPayload } from './types/CopyPastPayload';
import { SelectedCell } from './types/SelectedCell';
import { mapApplyLineLogicResponse } from './utils/mapApplyLineLogicResponse';
import { copyWellInputCellsAction, pasteWellInputCellsAction } from './wellInput/actions/copyPasteCells';
import { copyWellInputColumnsAction, pasteWellInputColumnsAction } from './wellInput/actions/copyPasteColumns';
import { getWellInput } from './wellInput/actions/getWellInput';
import {
  setWellInputStatusArray,
  setWellInputStatusChanges,
  setWellStatusBulkChange
} from './wellInput/actions/wellInputStatusChanges';
import { SetWellStatusBulkPayload, ToggleWellInputStatusPayload } from './wellInput/types/ToggleStatusPayload';
import { convertWellInputData, WellInputTableRow } from './wellInput/utils/convertWellInputData';

const initialState: OfficialInputExtendedState = {
  changes: {
    [SchemeComponentType.Header]: new Map(),
    [SchemeComponentType.MeterStation]: new Map(),
    [SchemeComponentType.Valve]: new Map(),
    [SchemeComponentType.Well]: new Map(),
    [SchemeComponentType.Joint]: new Map()
  },
  lineup: { splitableWeekDateIds: new Set() },
  wellInput: { splitableWeekDateIds: new Set() },
  selectedCells: [],
  disableApplyLogicButton: true
};

const { actions, reducer } = createSlice({
  initialState,
  name: 'official-inputs',
  reducers: {
    setSelectedCells(state, action: PayloadAction<SelectedCell[]>) {
      setSelectedCells(state, action.payload);
      state.disableApplyLogicButton = false;
    },
    toggleLineupStatusArray(state, action: PayloadAction<ToggleStatusPayload[]>) {
      setLineupStatusArray(state, action.payload);
      state.disableApplyLogicButton = false;
    },
    toggleLineupStatus(state, action: PayloadAction<ToggleLineupStatusPayload>) {
      setLineupStatusChanges(state, action.payload);
      state.disableApplyLogicButton = false;
    },
    toggleWellInputStatusArray(state, action: PayloadAction<ToggleStatusPayload[]>) {
      setWellInputStatusArray(state, action.payload);
      state.disableApplyLogicButton = false;
    },
    toggleWellInputStatus(state, action: PayloadAction<ToggleWellInputStatusPayload>) {
      setWellInputStatusChanges(state, action.payload);
      state.disableApplyLogicButton = false;
    },
    setWellStatusBulkChange(state, action: PayloadAction<SetWellStatusBulkPayload>) {
      setWellStatusBulkChange(state, action.payload);
      state.disableApplyLogicButton = false;
    },
    copyLineupColumns(state, action: PayloadAction<CopyPayload<LineupTableRow>>) {
      copyLineupColumnsAction(state, action.payload.rows, action.payload.columns);
    },
    copyLineupCells(state, action: PayloadAction<CopyPayload<string>>) {
      copyLineupCellsAction(state, action.payload.rows, action.payload.columns);
    },
    pasteLineupColumns(state, action: PayloadAction<number[]>) {
      pasteLineupColumnsAction(state, action.payload);
      state.disableApplyLogicButton = false;
    },
    pasteLineupCells(state, action: PayloadAction<PasteCellsPayload<string>>) {
      pasteLineupCellsAction(state, action.payload.row, action.payload.column);
      state.disableApplyLogicButton = false;
    },
    copySimulationCaseLineup(state, action: PayloadAction<CopySimulationCaseType<LineupTableRow>>) {
      const { rows, columns, targetWeekDateIds } = action.payload;
      copyLineupColumnsAction(state, rows, columns);
      pasteLineupColumnsAction(state, targetWeekDateIds);
      state.disableApplyLogicButton = false;
    },
    copyWellInputColumns(state, action: PayloadAction<CopyPayload<WellInputTableRow>>) {
      copyWellInputColumnsAction(state, action.payload.rows, action.payload.columns);
    },
    copyWellInputCells(state, action: PayloadAction<CopyPayload<string>>) {
      copyWellInputCellsAction(state, action.payload.rows, action.payload.columns);
    },
    pasteWellInputColumns(state, action: PayloadAction<number[]>) {
      pasteWellInputColumnsAction(state, action.payload);
      state.disableApplyLogicButton = false;
    },
    pasteWellInputCells(state, action: PayloadAction<PasteCellsPayload<string>>) {
      pasteWellInputCellsAction(state, action.payload.row, action.payload.column);
      state.disableApplyLogicButton = false;
    },
    copySimulationCaseWellInput(state, action: PayloadAction<CopySimulationCaseType<WellInputTableRow>>) {
      const { rows, columns, targetWeekDateIds } = action.payload;
      copyWellInputColumnsAction(state, rows, columns);
      pasteWellInputColumnsAction(state, targetWeekDateIds);
      state.disableApplyLogicButton = false;
    },
    reset(state) {
      state.changes.MeterStation.clear();
      state.changes.Header.clear();
      state.changes.Valve.clear();
      state.changes.Well.clear();
      state.changes.Joint.clear();
      state.lineup.cellsClipboard = { weekDates: [], statuses: new Map() };
      state.wellInput.cellsClipboard = { weekDates: [], statuses: new Map() };
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getLineup.pending, (state) => {
        handleLoading(state.lineup);
        state.lineup.rows = undefined;
      })
      .addCase(getLineup.fulfilled, (state, action) => {
        state.lineup.arg = castDraft(action.meta.arg);
        handleSuccess(state.lineup, action);
        const { rows, rowsById, dates, datesById, rowIdByNodeId, datesByWeekNumber, splitableWeekDateIds } =
          convertLineupData(action.payload);
        state.lineup.rows = rows;
        state.lineup.rowsById = rowsById;
        state.lineup.rowIdByNodeId = rowIdByNodeId;
        state.lineup.dates = dates;
        state.lineup.datesById = datesById;
        state.lineup.datesByWeekNumber = datesByWeekNumber;
        state.lineup.splitableWeekDateIds = splitableWeekDateIds;
      })
      .addCase(getLineup.rejected, (state, action) => {
        state.lineup.arg = undefined;
        handleError(state.lineup, action);
      });

    builder
      .addCase(getWellInput.pending, (state, action) => {
        state.wellInput.arg = castDraft(action.meta.arg);
        handleLoading(state.wellInput);
      })
      .addCase(getWellInput.fulfilled, (state, action) => {
        state.wellInput.arg = castDraft(action.meta.arg);
        handleSuccess(state.wellInput, action);
        const { rows, rowsById, dates, datesById, rowIdByNodeId, datesByWeekNumber, splitableWeekDateIds } =
          convertWellInputData(action.payload);
        state.wellInput.rows = rows;
        state.wellInput.rowsById = rowsById;
        state.wellInput.rowIdByNodeId = rowIdByNodeId;
        state.wellInput.dates = dates;
        state.wellInput.datesById = datesById;
        state.wellInput.splitableWeekDateIds = splitableWeekDateIds;
        state.wellInput.datesByWeekNumber = datesByWeekNumber;
      })
      .addCase(getWellInput.rejected, (state, action) => {
        state.wellInput.arg = undefined;
        handleError(state.wellInput, action);
      });

    builder.addCase(applyLineupLogicAction.fulfilled, (state, action) => {
      mapApplyLineLogicResponse(state, action.payload);
      state.disableApplyLogicButton = true;
    });

    builder.addCase(saveOfficialInputChanges.fulfilled, (state) => {
      sealLineupChanges(state);
      sealWellInputChanges(state);

      state.changes.MeterStation.clear();
      state.changes.Header.clear();
      state.changes.Valve.clear();
      state.changes.Well.clear();
      state.changes.Joint.clear();
    });
  }
});

export const officialInputActions = actions;

const {
  copyLineupCells,
  copyWellInputCells,
  toggleLineupStatus,
  toggleWellInputStatus,
  toggleLineupStatusArray,
  toggleWellInputStatusArray,
  reset,
  pasteLineupColumns,
  pasteLineupCells,
  pasteWellInputColumns,
  pasteWellInputCells,
  copySimulationCaseLineup,
  copySimulationCaseWellInput
} = actions;

const groupBy: GroupByFunction<OfficialInputExtendedState, AnyAction> = (action) => {
  if (action.type === getLineup.fulfilled.type || action.type === getWellInput.fulfilled.type) {
    return 'official-input-data';
  }

  return null;
};

export default undoable(reducer, {
  undoType: officialInputUndo.type,
  clearHistoryType: officialInputClearHistory.type, // Remove all items from past[] and future[] arrays
  jumpToPastType: OfficialInputJumpToPastType,
  groupBy,
  ignoreInitialState: true,
  filter: includeAction([
    copyLineupCells.type,
    copyWellInputCells.type,
    getWellInput.fulfilled.type,
    getLineup.fulfilled.type,
    toggleLineupStatus.type,
    toggleLineupStatusArray.type,
    toggleWellInputStatus.type,
    toggleWellInputStatusArray.type,
    reset.type,
    applyLineupLogicAction.fulfilled.type,
    pasteLineupColumns.type,
    pasteLineupCells.type,
    pasteWellInputColumns.type,
    pasteWellInputCells.type,
    saveOfficialInputChanges.fulfilled.type,
    copySimulationCaseLineup.type,
    copySimulationCaseWellInput.type
  ])
});
