import React, { FC, useContext } from 'react';
import { Button, ButtonProps } from '../../Button/Button';
import { Tooltip } from '../../Tooltip';
import { DataGridContext } from '../DataGridContext';
import { DataGridInstance } from '../types';

/**
 * The props for the {@link DataGridButton} component.
 * @category Props
 */
export interface DataGridButtonProps extends Omit<ButtonProps, 'disabled'> {
    enableSelected?: boolean | number;
    disabled?: boolean | ((instance: DataGridInstance) => boolean);
}

/**
 * @category Component
 * @group Data Grid
 */
export const DataGridButton: FC<DataGridButtonProps> = ({ enableSelected, disabled, ...other }) => {
    const instance = useContext(DataGridContext);

    const props: Partial<ButtonProps> = {
        disabled: disabled as any,
    };

    if (enableSelected === true) {
        props.disabled = instance.selectedFlatRows?.length === 0;
    }

    if (typeof enableSelected === 'number') {
        props.disabled = instance.selectedFlatRows ? instance.selectedFlatRows.length != enableSelected : true;
    }

    if (typeof disabled == 'function') {
        props.disabled = disabled(instance);
    }

    let result = <Button {...other} {...props} />;

    if (enableSelected && props.disabled && typeof disabled !== 'function') {
        result = <Tooltip title="Select row to perform action">{result}</Tooltip>;
    }

    return result;
};
