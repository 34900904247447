import { Navigate, RouteObject } from 'react-router-dom';
import { lazyComponent } from 'utils/lazyComponent';
import { subsurfaceCaseRoute } from './subsurface-data-case/routes';

const ScenarioCasePage = lazyComponent(() => import('./index'), 'scenarioCasePage');
const LineupScenarioCase = lazyComponent(() => import('./lineup-case/LineupCase'), 'lineupCase');
const WellsScenarioCase = lazyComponent(() => import('./wells-case/WellInputCase'), 'wellInputCase');

export const caseRoute: RouteObject = {
  path: 'case/:caseId',
  element: <ScenarioCasePage />,
  children: [
    {
      index: true,
      element: <Navigate replace to="lineup" />
    },
    {
      path: 'lineup',
      element: <LineupScenarioCase />
    },
    {
      path: 'wells',
      element: <WellsScenarioCase />
    },
    subsurfaceCaseRoute
  ]
};
