import { LineupTableRow } from '../types/LineupTableRow';

export const getLineupSnapshot = (rows: LineupTableRow[] = [], searchQuery = ''): LineupTableRow[] => {
  const search = searchQuery?.toLocaleLowerCase();

  const snapshotResult = [];
  for (const parentRow of rows) {
    if (parentRow.name?.toLocaleLowerCase().includes(search)) {
      snapshotResult.push({ ...parentRow });
    } else {
      const lines = [];
      for (const lineRow of parentRow.subRows) {
        if (
          lineRow.name?.toLocaleLowerCase().includes(search) ||
          lineRow.tagName?.toLocaleLowerCase().includes(search)
        ) {
          lines.push(lineRow);
        } else {
          const connections = [];
          for (const connectionRow of lineRow.subRows) {
            if (
              connectionRow.name?.toLocaleLowerCase().includes(search) ||
              connectionRow.tagName?.toLocaleLowerCase().includes(search)
            ) {
              connections.push(connectionRow);
            }
          }
          if (connections.length) {
            lines.push({ ...lineRow, subRows: connections });
          }
        }
      }

      if (lines.length) {
        snapshotResult.push({ ...parentRow, subRows: lines });
      }
    }
  }
  return snapshotResult;
};
