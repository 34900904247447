import { AnyAction, createAction, createSlice, PayloadAction, Reducer } from '@reduxjs/toolkit';
import undoable, { GroupByFunction, includeAction, StateWithHistory } from 'redux-undo';
import { handleError, handleLoading } from 'store/base/baseStateHandlers';
import { getPropOrDefault } from 'utils/getPropOrDefault';
import { getGasConstraintValues, getInletPressureValues, getUpliftFactorValues } from './actions/getPlantInputs';
import { savePlantInputs } from './actions/savePlantInputs';
import { SetPlantInputValuePayload, SetUpliftFactorsValuePayload } from './types/helperTypes';
import { PlantInputsState } from './types/PlantInputsState';
import { convertInletPressure, convertUpliftFactors } from './utils/convertPlantInputs';
import { getPlantInputsSnapshot, getUpliftFactorsSnapshot } from './utils/getPlantInputsSnapshot';
import { plantInputsKeyAccessor } from './utils/helpers';
import { setPlantInputsChange, setUpliftFactorsChange } from './utils/plantInputsChanges';

export const initialState: PlantInputsState = {};

const plantInputsSlice = createSlice({
  name: 'plantInputs',
  initialState,
  reducers: {
    reset(state, action: PayloadAction<number>) {
      const keyAccessor = plantInputsKeyAccessor(action.payload);
      const itemState = state[keyAccessor];

      if (itemState) {
        itemState.inletPressure?.changes?.clear();
        itemState.gasConstraint?.changes?.clear();
        if (itemState.upliftFactors) itemState.upliftFactors.changes = {};
      }
    },
    setPlantInputValue(state, action: PayloadAction<SetPlantInputValuePayload>) {
      setPlantInputsChange(state, action.payload);
    },
    setUpliftFactorsValue(state, action: PayloadAction<SetUpliftFactorsValuePayload>) {
      setUpliftFactorsChange(state, action.payload);
    }
  },
  extraReducers(builder) {
    builder
      .addCase(getInletPressureValues.pending, (state, action) => {
        const key = plantInputsKeyAccessor(action.meta.arg?.caseId);
        getPropOrDefault(key, state);
        getPropOrDefault('inletPressure', state[key], { splitableWeekDateIds: new Set<number>() });
        handleLoading(state[key].inletPressure);
      })
      .addCase(getInletPressureValues.rejected, (state, action) => {
        const key = plantInputsKeyAccessor(action.meta.arg?.caseId);
        getPropOrDefault(key, state);
        getPropOrDefault('inletPressure', state[key], { splitableWeekDateIds: new Set<number>() });
        handleError(state[key].inletPressure, action);
      })
      .addCase(getInletPressureValues.fulfilled, (state, action) => {
        const key = plantInputsKeyAccessor(action.meta.arg?.caseId);
        const inletPressureState = state[key].inletPressure;
        inletPressureState.loading = false;
        inletPressureState.data = action.payload.items;
        const { dates, datesById, datesByWeekNumber, splitableWeekDateIds, rows } = convertInletPressure(
          action.payload,
          key
        );
        inletPressureState.dates = dates;
        inletPressureState.datesById = datesById;
        inletPressureState.datesByWeekNumber = datesByWeekNumber;
        inletPressureState.splitableWeekDateIds = splitableWeekDateIds;
        inletPressureState.rows = rows;
      });
    builder
      .addCase(getGasConstraintValues.pending, (state, action) => {
        const key = plantInputsKeyAccessor(action.meta.arg?.caseId);
        getPropOrDefault(key, state);
        getPropOrDefault('gasConstraint', state[key], { splitableWeekDateIds: new Set<number>() });
        handleLoading(state[key].gasConstraint);
      })
      .addCase(getGasConstraintValues.rejected, (state, action) => {
        const key = plantInputsKeyAccessor(action.meta.arg?.caseId);
        handleError(state[key].gasConstraint, action);
      })
      .addCase(getGasConstraintValues.fulfilled, (state, action) => {
        const key = plantInputsKeyAccessor(action.meta.arg?.caseId);
        const gcState = state[key].gasConstraint;
        gcState.loading = false;
        gcState.data = action.payload.items;
        const { dates, datesById, datesByWeekNumber, splitableWeekDateIds, rows } = convertInletPressure(
          action.payload,
          key
        );
        gcState.dates = dates;
        gcState.datesById = datesById;
        gcState.datesByWeekNumber = datesByWeekNumber;
        gcState.splitableWeekDateIds = splitableWeekDateIds;
        gcState.rows = rows;
      });

    builder
      .addCase(getUpliftFactorValues.pending, (state, action) => {
        const key = plantInputsKeyAccessor(action.meta.arg?.caseId);
        getPropOrDefault(key, state);
        getPropOrDefault('upliftFactors', state[key], { splitableWeekDateIds: new Set<number>() });
        handleLoading(state[key].upliftFactors);
      })
      .addCase(getUpliftFactorValues.rejected, (state, action) => {
        const key = plantInputsKeyAccessor(action.meta.arg?.caseId);
        handleError(state[key].upliftFactors, action);
      })
      .addCase(getUpliftFactorValues.fulfilled, (state, action) => {
        const key = plantInputsKeyAccessor(action.meta.arg?.caseId);
        const upliftFactorsState = state[key].upliftFactors;
        upliftFactorsState.loading = false;
        upliftFactorsState.data = action.payload;
        upliftFactorsState.record = convertUpliftFactors(action.payload);
      });

    builder.addCase(savePlantInputs.fulfilled, (state, action) => {
      const key = plantInputsKeyAccessor(action.meta.arg);

      const inletPressureChanges = state[key].inletPressure?.changes;
      if (inletPressureChanges) {
        const inletPressureRow = state[key].inletPressure?.rows;
        state[key].inletPressure.rows = getPlantInputsSnapshot(inletPressureRow, inletPressureChanges);
        inletPressureChanges.clear();
      }

      const gasConstraintChanges = state[key].gasConstraint?.changes;
      if (gasConstraintChanges) {
        const gasConstraintRow = state[key].gasConstraint?.rows;
        state[key].gasConstraint.rows = getPlantInputsSnapshot(gasConstraintRow, gasConstraintChanges);
        gasConstraintChanges.clear();
      }

      const upliftFactorsChanges = state[key].upliftFactors?.changes;
      if (upliftFactorsChanges) {
        const upliftFactors = state[key].upliftFactors.record;
        state[key].upliftFactors.record = getUpliftFactorsSnapshot(upliftFactors, upliftFactorsChanges);
        state[key].upliftFactors.changes = {};
      }
    });
  }
});

const undo = createAction('PLANT_INPUTS_UNDO');
const clearHistory = createAction('PLANT_INPUTS_CLEAR_HISTORY');

export const plantInputsActions = {
  ...plantInputsSlice.actions,
  clearHistory,
  undo,
  saveChanges: savePlantInputs,
  getInletPressureValues,
  getGasConstraintValues,
  getUpliftFactorValues
};

const groupBy: GroupByFunction<PlantInputsState, AnyAction> = (action) => {
  if (action.type === plantInputsActions.getInletPressureValues.fulfilled.type) {
    return 'plant-inputs-getInletPressureValues-data';
  }
  if (action.type === plantInputsActions.getUpliftFactorValues.fulfilled.type) {
    return 'plant-inputs-getUpliftFactorValues-data';
  }

  return null;
};

const getPlantInputsUndoableReducer = (
  reducer: Reducer<typeof initialState>
): Reducer<StateWithHistory<PlantInputsState>, AnyAction> =>
  undoable(reducer, {
    groupBy,
    undoType: undo.type,
    clearHistoryType: clearHistory.type,
    filter: includeAction([
      plantInputsActions.getInletPressureValues.fulfilled.type,
      plantInputsActions.getGasConstraintValues.fulfilled.type,
      plantInputsActions.getUpliftFactorValues.fulfilled.type,
      plantInputsActions.setPlantInputValue.type,
      plantInputsActions.setUpliftFactorsValue.type,
      plantInputsActions.reset.type
    ]),
    debug: false
  });
const plantInputsReducer = getPlantInputsUndoableReducer(plantInputsSlice.reducer);

export default plantInputsReducer;
