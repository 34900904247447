import { Group } from '@visx/group';
import { PatternCircles } from '@visx/pattern';
import { Area, LinePath } from '@visx/shape';
import React, { CSSProperties, FC, memo, useCallback, useContext, useMemo } from 'react';
import { ChartContext } from '../ChartContext';
import { Interpolation, SeriesOptions } from '../chartTypes';
import { FocusBlurHandler } from '../components/FocusBlurHandler';
import { ChartAccessor, findClosestPoint, interpolatorLookup, useAccessors } from '../utils';
import { callOrValue, isDefined } from '../utils/chartUtils';

const noEventsStyles: CSSProperties = { pointerEvents: 'none' };

interface AreaSeriesItem extends Object {
    stroke?: string;
    label?: string;
}

export interface AreaSeriesProps<T extends AreaSeriesItem = any> extends SeriesOptions<T> {
    interpolation?: Interpolation;
    isDotted?: boolean;
    stroke?: string | (() => string);
    strokeDasharray?: string | (() => string);
    strokeWidth?: number | (() => number);
    strokeOpacity?: number | (() => number);
    strokeLinecap?: 'butt' | 'square' | 'round' | 'inherit';
    fill?: string | (() => string);
    fillOpacity?: number | (() => number);
    hideLinePath?: boolean;
}

/**
 * @category Component
 * @group Chart
 */
let AreaSeries = <T extends AreaSeriesItem = any>(props: AreaSeriesProps<T>) => {
    const {
        id,
        isDotted,
        axis,
        data,
        xAccessor,
        yAccessor,
        openAccessor,
        closeAccessor,
        disableMouseEvents,
        interpolation = 'monotoneX',
        stroke = 'black',
        strokeWidth = 2,
        strokeOpacity = 1,
        strokeDasharray = null,
        strokeLinecap = 'round',
        hideLinePath = false,
        fill = 'black',
        fillOpacity = 0.3,
        onClick,
        onMouseMove,
        onMouseLeave
    } = props;

    const {
        legend,
        margin,
        xScales,
        yScales,
        groupDimensions,
        getX: globalGetX,
        getY: globalGetY,
        handleClick: globalOnClick,
        handleMouseMove: globalOnMouseMove,
        handleMouseLeave: globalOnMouseLeave
    } = useContext(ChartContext);

    const group = axis ?? 'undefined';
    const dimensions = groupDimensions[group];
    const xScale = xScales[axis] ?? xScales.undefined;
    const yScale = yScales[axis] ?? yScales.undefined;

    const { getX, getY } = useAccessors(xAccessor, globalGetX, yAccessor, globalGetY);
    const { getY: getOpen } = useAccessors(xAccessor, globalGetX, openAccessor, undefined);
    const { getY: getClose } = useAccessors(xAccessor, globalGetX, closeAccessor, undefined);

    const x = useMemo(
        (): ChartAccessor<any> =>
            (...args) =>
                xScale?.(getX?.(...args)) ?? 0,
        [getX, xScale]
    );
    const y = useMemo(
        (): ChartAccessor<any> =>
            (...args) =>
                yScale?.(getY?.(...args)) ?? 0,
        [getY, yScale]
    );
    const open = useMemo(
        (): ChartAccessor<any> =>
            (...args) =>
                yScale?.(getOpen?.(...args)) ?? 0,
        [getOpen, yScale]
    );
    const close = useMemo(
        (): ChartAccessor<any> =>
            (...args) =>
                yScale?.(getClose?.(...args)) ?? 0,
        [getClose, yScale]
    );

    const definedClosed = useMemo(
        (): ChartAccessor<any> =>
            (...args) =>
                isDefined(getY?.(...args)),
        [getY]
    );
    const definedOpen = useMemo(
        (): ChartAccessor<any> =>
            (...args) =>
                isDefined(getOpen?.(...args)) && isDefined(getClose?.(...args)),
        [getClose, getOpen]
    );

    const strokeOpacityValue = callOrValue(strokeOpacity, data);
    const strokeDasharrayValue = callOrValue(strokeDasharray, data);
    const strokeValue = callOrValue(stroke, data);
    const strokeWidthValue = callOrValue(strokeWidth, data);
    const fillValue = callOrValue(fill, data);
    const curve = interpolatorLookup[interpolation];

    const handleClick = useCallback(
        (event) => {
            const point = findClosestPoint({
                getX,
                event,
                xScale,
                marginLeft: margin.left,
                dimensions,
                ...props
            });

            const args = {
                key: id,
                event,
                data,
                axis,
                getX,
                getY,
                getOpen,
                getClose,
                point,
                color: strokeValue,
                options: props
            };

            if (onClick) {
                onClick(args);
            }

            if (globalOnClick) {
                globalOnClick(args);
            }
        },
        [
            axis,
            data,
            dimensions,
            getClose,
            getOpen,
            getX,
            getY,
            globalOnClick,
            id,
            margin.left,
            onClick,
            props,
            strokeValue,
            xScale
        ]
    );

    const handleMouseMove = useCallback(
        (event) => {
            const point = findClosestPoint({
                getX,
                event,
                xScale,
                marginLeft: margin.left,
                dimensions,
                ...props
            });

            const args = {
                key: id,
                event,
                data,
                axis,
                getX,
                getY,
                getOpen,
                getClose,
                point,
                color: strokeValue,
                options: props
            };

            if (onMouseMove) {
                onMouseMove(args);
            }

            if (globalOnMouseMove) {
                globalOnMouseMove(args);
            }
        },
        [
            axis,
            data,
            dimensions,
            getClose,
            getOpen,
            getX,
            getY,
            globalOnMouseMove,
            id,
            margin.left,
            onMouseMove,
            props,
            strokeValue,
            xScale
        ]
    );

    const handleGlyphMouseMove = useCallback(
        (point, color, index, event) => {
            const args = {
                key: id,
                event,
                data,
                axis,
                getX,
                getY,
                getOpen,
                getClose,
                point,
                color,
                index,
                options: props
            };

            if (onMouseMove) {
                onMouseMove(args);
            }

            if (globalOnMouseMove) {
                globalOnMouseMove(args);
            }
        },
        [axis, data, getClose, getOpen, getX, getY, globalOnMouseMove, id, onMouseMove, props]
    );

    const handleMouseLeave = useCallback(
        (event) => {
            const point = findClosestPoint({
                getX,
                event,
                xScale,
                marginLeft: margin.left,
                dimensions,
                ...props
            });

            const args = {
                key: id,
                event,
                data,
                axis,
                getX,
                getY,
                getOpen,
                getClose,
                point,
                color: strokeValue,
                options: props
            };

            if (onMouseLeave) {
                onMouseLeave(args);
            }

            if (globalOnMouseLeave) {
                globalOnMouseLeave(args);
            }
        },
        [
            axis,
            data,
            dimensions,
            getClose,
            getOpen,
            getX,
            getY,
            globalOnMouseLeave,
            id,
            margin.left,
            onMouseLeave,
            props,
            strokeValue,
            xScale
        ]
    );

    const isClosed = useMemo(() => !data.some((point) => definedOpen?.(point)), [data, definedOpen]);

    if (!xScale || !yScale || legend.state[id]) return null;

    const yMax = yScale.domain()[1];
    const y0 = isClosed ? () => yMax : open;
    const y1 = isClosed ? y : close;
    const defined = isClosed ? definedClosed : definedOpen;

    return (
        <Group
            left={(xScale.bandwidth?.() ?? 0) / 2}
            style={disableMouseEvents ? noEventsStyles : undefined}
            onClick={disableMouseEvents ? undefined : handleClick}
            onMouseMove={disableMouseEvents ? undefined : handleMouseMove}
            onMouseLeave={disableMouseEvents ? undefined : handleMouseLeave}
        >
            <Area
                data={data}
                x={x}
                y={y}
                y0={y0}
                y1={y1}
                fill={fillValue}
                fillOpacity={callOrValue(fillOpacity, data)}
                fillRule="nonzero"
                stroke="transparent"
                strokeWidth={strokeWidthValue}
                curve={curve}
                defined={defined}
            />
            {isDotted && (
                <Area
                    data={data}
                    x={x}
                    y={y}
                    y0={y0}
                    y1={y1}
                    fill={'url(#circles)'}
                    fillOpacity={callOrValue(fillOpacity, data)}
                    fillRule="nonzero"
                    stroke="transparent"
                    strokeWidth={strokeWidthValue}
                    curve={curve}
                    defined={defined}
                />
            )}
            <PatternCircles id="circles" height={8} width={8} radius={1} stroke="#333" strokeWidth={1} />
            {/* only draw a stroke for the top and bottom */}
            {!hideLinePath && strokeWidthValue > 0 && !isClosed && (
                <LinePath
                    data={data}
                    x={x}
                    y={y0}
                    stroke={strokeValue}
                    strokeWidth={strokeWidthValue}
                    strokeDasharray={strokeDasharrayValue}
                    strokeOpacity={strokeOpacityValue}
                    strokeLinecap={strokeLinecap}
                    curve={curve}
                    defined={defined}
                />
            )}
            {/* draw this path even if strokewidth is 0, for focus/blur support */}
            {!hideLinePath && (
                <LinePath
                    data={data}
                    x={x}
                    y={y1}
                    stroke={strokeValue}
                    strokeWidth={strokeWidthValue}
                    strokeOpacity={strokeOpacityValue}
                    strokeDasharray={strokeDasharrayValue}
                    strokeLinecap={strokeLinecap}
                    curve={curve}
                    defined={defined}
                />
            )}
            <Group>
                {data.map((point, index) => (
                    <FocusBlurHandler
                        key={`areapoint-${index}`}
                        onBlur={disableMouseEvents ? undefined : handleMouseLeave}
                        onFocus={
                            disableMouseEvents ? undefined : handleGlyphMouseMove.bind(null, point, strokeValue, index)
                        }
                    />
                ))}
            </Group>
        </Group>
    );
};

(AreaSeries as FC).displayName = 'AreaSeries';
AreaSeries = memo(AreaSeries);

export { AreaSeries };
