import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IGetWeekDatesDto } from 'api';
import { handleError } from 'store/base/baseStateHandlers';

import { getScheme } from './actions/getScheme';

import { SchemeState } from './types';
import { convertToDiagramData } from './utils';

const initialState: SchemeState = {
  data: { main: undefined },
  loading: false,
  schemes: { main: undefined },
  schemeView: { view: 'main', main: {}, meterStation: {} }
};

const { reducer, actions } = createSlice({
  name: 'scheme',
  initialState,
  reducers: {
    setSelectedWeek(state, action: PayloadAction<IGetWeekDatesDto>) {
      state.selectedWeekDateDto = action.payload;
    },
    saveSchemeMainViewParams(state, action: PayloadAction<Partial<SchemeState['schemeView']['main']>>) {
      state.schemeView.main = action.payload;
      state.schemeView.view = 'main';
    },
    saveSchemeMeterStationViewParams(state, action: PayloadAction<Partial<SchemeState['schemeView']['meterStation']>>) {
      state.schemeView.meterStation = action.payload;
      state.schemeView.view = 'meterStation';
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getScheme.pending, (state) => {
        state.loading = true;
      })
      .addCase(getScheme.fulfilled, (state, action) => {
        const key = action.meta.arg.meterStationId ?? 'main';
        state.data[key] = action.payload;
        state.schemes[key] = convertToDiagramData(action.payload);
        state.loading = false;
      })
      .addCase(getScheme.rejected, (state, action) => {
        handleError(state, action);
      });
  }
});

export const schemeActions = actions;
export default reducer;
