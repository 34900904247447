import { Link as RouterLink, LinkProps } from 'react-router-dom';

import { ArrowForward } from '@mui/icons-material';
import { ButtonBase, Typography } from '@mui/material';

export type TileButtonProps = {
  to: LinkProps['to'];
  text: string;
};

export default function TileButton({ to, text }: TileButtonProps) {
  return (
    <ButtonBase
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',
        height: 138,
        backgroundColor: (theme) => theme.palette.background.paper,
        px: 2,
        borderRadius: (theme) => theme.shape.borderRadius,
        boxShadow: (theme) => theme.shadows[2]
      }}
      component={RouterLink}
      to={to}
    >
      <Typography variant="h5" sx={{ fontWeight: 700 }}>
        {text}
      </Typography>
      <ArrowForward />
    </ButtonBase>
  );
}
