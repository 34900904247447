import { WellPIClient } from 'api';
import { ForceableQuery } from 'domain/QueryParams';
import { createAppAsyncThunk } from 'store/createAppAsyncThunk';
import { weekDatesApi } from 'store/weekDates/weekDatesApi';
import { GetWellPIPayload } from '../types/GetWellPIPayload';
import { wellPIPresentStateSelector } from '../wellPISelectors';

const service = new WellPIClient();

export const getWellPI = createAppAsyncThunk(
  'wellPI/get',
  async (_: ForceableQuery, { dispatch }): Promise<GetWellPIPayload> => {
    const weekDates = dispatch(weekDatesApi.endpoints.getWeekDates.initiate(undefined)).unwrap();
    const items = service.get();

    await Promise.allSettled([weekDates, items]);

    return { adjustWeekDates: await weekDates, items: await items };
  },
  {
    condition: ({ force }, { getState }) => {
      const wellPI = wellPIPresentStateSelector(getState());

      if (!wellPI.loading && (force || wellPI.data === undefined)) {
        return true;
      }

      return false;
    }
  }
);
