import React from 'react';

import { ContextMenuButton } from '../../ContextMenu';
import { DataGridInstance } from '../types';
import { serviceColumns } from '../utils/serviceColumns';

export const contextMenuColumnHook = (hooks) => {
    const template = ({ row, ...instance }: DataGridInstance & { row: any }) => {
        const contextMenu = instance?.settings?.contextMenu;
        const isRender = contextMenu?.type?.isRenderContextMenu?.(instance, row) ?? true;
        if (!isRender || row.state?.isEditing) {
            return null;
        }
        return <ContextMenuButton />;
    };
    hooks.visibleColumns.push((columns) => [
        ...columns,
        {
            id: serviceColumns.contextMenu,
            Header: '',
            Cell: template,
            CellEdit: template,
            minWidth: 34,
            width: 34,
            maxWidth: 34,
            disableResizing: true,
            style: {
                padding: 0
            },
            sticky: columns?.[columns.length - 1]?.sticky
        }
    ]);
};
