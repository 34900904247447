import { GetFlagDto, IP6Dto } from 'api';
import { P6ComponentName } from 'domain/p6';
import { P6ReferenceData } from '../actions/getP6Data';
import { P6State } from '../P6State';
import { getP6Detail } from './getP6Detail';

export interface P6TableRow extends IP6Dto {
  componentName: string;
  detailsName: string;
  plantName: string;
  status: boolean;
  flag?: GetFlagDto;
}

export function getP6Snapshot(
  { data, edits }: P6State,
  { headers, meterStations, pipelines, wells, plants, flags }: P6ReferenceData
) {
  const rows: P6TableRow[] = [];

  for (const item of data ?? []) {
    const rowChanges = edits.get(item.id);
    const p6Detail = rowChanges?.p6Detail ?? item?.p6Detail;
    const { option } = getP6Detail(p6Detail, headers, meterStations, pipelines, wells);
    const plant = plants.find((x) => x.id === p6Detail?.plantId);
    const flag = flags.find((x) => x.p6Id === item?.id);

    const row: P6TableRow = {
      ...item,
      activityName: rowChanges?.activityName ?? item.activityName,
      shortName: rowChanges?.shortName ?? item.shortName,
      startDate: rowChanges?.startDate ?? item.startDate,
      finishDate: rowChanges?.finishDate ?? item.finishDate,
      p6Detail,
      componentName: P6ComponentName[p6Detail?.componentId],
      detailsName: option?.name,
      plantName: plant?.name,
      status: Boolean(p6Detail?.status),
      flag
    };

    rows.push(row);
  }

  return rows;
}
