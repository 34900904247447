import cx from 'classnames';
import React, { forwardRef } from 'react';
import { ListItem, ListItemProps } from '../List/ListItem';
import styles from './MenuItem.module.css';

/**
 * The props for the {@link MenuItem} component.
 * @category Props
 */
export interface MenuItemProps extends ListItemProps {}

/**
 * @category Component
 * @group Menu
 */
export const MenuItem = forwardRef(function MenuItem(props: MenuItemProps, ref: any) {
    const {
        className,
        component = 'li',
        disableMargin = true,
        gutters = true,
        role = 'menuitem',
        selected,
        tabIndex: tabIndexProp,
        ...other
    } = props;

    let tabIndex;
    if (!props.disabled) {
        tabIndex = tabIndexProp !== undefined ? tabIndexProp : -1;
    }

    return (
        <ListItem
            button
            role={role}
            tabIndex={tabIndex}
            component={component}
            selected={selected}
            disableMargin={disableMargin}
            gutters={gutters}
            className={cx(
                styles.root,
                {
                    [styles.selected]: selected,
                    [styles.gutters]: !gutters,
                },
                className
            )}
            ref={ref}
            {...other}
        />
    );
});
