import { ChartInstance } from '../../chartTypes';

export const trimChartInstanceForBrush = (instance: ChartInstance): ChartInstance => {
    const voidFunction = () => undefined;
    return {
        ...instance,
        layers: {
            Annotations: null,
            Toolbar: null,
            Axis: null,
            Series: instance.layers.Series,
            CrossHairs: null,
            Brush: null,
            Zoom: null,
            Tooltip: null,
            RestChildren: null,
            Legend: null
        },
        Annotations: [],
        AnnotationsGroups: {},
        handleClick: voidFunction,
        handleMouseMove: voidFunction,
        handleMouseLeave: voidFunction,
        handleMouseDown: voidFunction,
        handleContextMenu: voidFunction,
        handlePointClick: voidFunction,
        handlePointLeave: voidFunction,
        handlePointMove: voidFunction,
        legend: {
            state: {},
            toggleSeries: voidFunction
        },
        brush: {
            setBrush: voidFunction,
            state: {
                x: {},
                y: {}
            },
            setInitialState: voidFunction,
            initialState: {
                x: {},
                y: {}
            }
        },
        zoom: {
            ref: null,
            transformMatrix: null,
            updateTransformMatrix: null
        }
    };
};
