import { PlantInputChange, UpliftFactors } from '../types/PlantInputsState';
import { PlantInputsCell, PlantInputsRow } from '../types/tableTypes';

export const getPlantInputsSnapshot = (rows: PlantInputsRow[], changes?: PlantInputChange): PlantInputsRow[] => {
  if (!changes?.size || !rows) {
    return rows;
  }

  return rows.map((row) => {
    const changedRow = changes.get(row.rowId);

    if (changedRow) {
      const inputs: Map<number, PlantInputsCell> = new Map();

      row.inputs.forEach((cell, weekDateId) => {
        const changedValue = changedRow.get(weekDateId)?.value;

        if (changedValue) {
          inputs.set(weekDateId, {
            ...cell,
            value: changedValue
          });
        } else {
          inputs.set(weekDateId, cell);
        }
      });

      return { ...row, inputs };
    }

    return row;
  });
};

export const getUpliftFactorsSnapshot = (upliftFactors: UpliftFactors, changes?: Partial<UpliftFactors>) => {
  if (!upliftFactors || !changes || Object.keys(changes).length === 0) {
    return upliftFactors;
  }

  return { ...upliftFactors, ...changes };
};
