import {
  FilteringOption,
  FlagTab,
  GetFlagDto,
  HeaderDto,
  IPageSearchArgs,
  MeterStationDto,
  P6Client,
  PageSearchArgs,
  PagingStrategy,
  PipelineDto,
  PlantDto,
  WellDto
} from 'api';
import { createAppAsyncThunk } from 'store/createAppAsyncThunk';
import { flagsApi } from 'store/flags/flagsApi';
import { headersApi } from 'store/headers/headersApi';
import { meterStationsApi } from 'store/meterStations/meterStationsApi';
import { pipelinesApi } from 'store/pipelines/pipelinesApi';
import { plantsApi } from 'store/plants/plantsApi';
import { wellsApi } from 'store/wells/wellsApi';
import { getFormattedDate, MAX_DATE, MIN_DATE } from 'utils/dateUtil';
import { p6StateSelector } from '../p6Selector';

const service = new P6Client();

interface FetchP6DataRequest {
  paging?: IPageSearchArgs;
  force?: boolean;
}

export const defaultPaging: IPageSearchArgs = {
  filteringOptions: [
    new FilteringOption({
      field: 'StartDateRange',
      value: `${getFormattedDate(MIN_DATE, 'MM.DD.yyyy')}-${getFormattedDate(MAX_DATE, 'MM.DD.yyyy')}`
    })
  ],
  pagingStrategy: PagingStrategy.NoPaging
};

export interface P6ReferenceData {
  headers: HeaderDto[];
  pipelines: PipelineDto[];
  meterStations: MeterStationDto[];
  wells: WellDto[];
  plants: PlantDto[];
  flags: GetFlagDto[];
}

export const getP6ReferenceData = createAppAsyncThunk(
  'p6/fetchReferenceData',
  async (_: void, { dispatch }): Promise<P6ReferenceData> => {
    const headersPromise = dispatch(headersApi.endpoints.getAllHeaders.initiate()).unwrap();
    const pipelinesPromise = dispatch(pipelinesApi.endpoints.getAllPipelines.initiate()).unwrap();
    const meterStationsPromise = dispatch(meterStationsApi.endpoints.getAllMeterStations.initiate()).unwrap();
    const wellsPromise = dispatch(wellsApi.endpoints.getAllWells.initiate()).unwrap();
    const plantsPromise = dispatch(plantsApi.endpoints.getAllPlants.initiate()).unwrap();
    const flagsPromise = dispatch(flagsApi.endpoints.getFlags.initiate(FlagTab.P6)).unwrap();

    await Promise.allSettled([headersPromise, pipelinesPromise, meterStationsPromise, wellsPromise, plantsPromise]);

    return {
      headers: await headersPromise,
      pipelines: await pipelinesPromise,
      meterStations: await meterStationsPromise,
      wells: await wellsPromise,
      plants: await plantsPromise,
      flags: await flagsPromise
    };
  }
);

export const getP6Data = createAppAsyncThunk(
  'p6/fetch',
  async (arg: FetchP6DataRequest, { dispatch, rejectWithValue }) => {
    const { paging = defaultPaging } = arg ?? {};

    try {
      const referenceDataPromise = dispatch(getP6ReferenceData()).unwrap();
      const p6Promise = service.get(new PageSearchArgs(paging));

      await Promise.allSettled([referenceDataPromise, p6Promise]);

      return { referenceData: await referenceDataPromise, data: await p6Promise };
    } catch (error) {
      return rejectWithValue(error);
    }
  },
  {
    condition: (arg, { getState }) => {
      const { paging = defaultPaging, force } = arg ?? {};

      if (force) {
        return true;
      }

      const p6 = p6StateSelector(getState());

      if (!p6.loading && p6.data === undefined) {
        return true;
      }

      if (
        p6.paging?.pageIndex !== paging.pageIndex ||
        p6.paging?.pageSize !== paging.pageSize ||
        p6.paging?.pagingStrategy !== paging.pagingStrategy
      ) {
        return true;
      }

      return false;
    }
  }
);
