import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'store';
import { SchemeType } from './types';

import { SchemeComponentType } from 'api';
import { selectOfficialInputPresentState } from 'store/official-inputs/OfficialSelectors';
import { LineupState } from 'store/official-inputs/lineup/lineupState';
import { getNodeId } from 'store/official-inputs/utils/getNodeId';
import { WellInputState } from 'store/official-inputs/wellInput/WellInputsState';
import { applyEdgeStatuses } from './utils/applyEdgeStatuses';
import { applyNodeStatuses } from './utils/applyNodeStatuses';

const emptyScheme: SchemeType = { edges: [], nodes: [] };

const meterStationIdSelector = (_state: RootState, meterStationId?: number | 'main') => meterStationId;

export const schemeSelector = (state: RootState) => state.scheme;

export const schemeLoadingSelector = (state: RootState) => schemeSelector(state).loading;

export const schemeErrorSelector = (state: RootState) => schemeSelector(state).error;

export const schemesSelector = (state: RootState) => schemeSelector(state).schemes;

export const selectedWeekSelector = (state: RootState) => schemeSelector(state).selectedWeekDateDto;

const NodeTypes = Object.values(SchemeComponentType);

const updateStatusMap = (
  statusMap: Record<string, boolean>,
  change: Map<string, Map<number, { status: boolean }>>,
  weekId: number,
  type: SchemeComponentType,
  lineup: LineupState<unknown>,
  wellInput: WellInputState<unknown>
) => {
  for (const [id, parentChanges] of change) {
    const statusObj = parentChanges.get(weekId);

    if (statusObj) {
      if (type === SchemeComponentType.Well) {
        const wellInputRow = wellInput.rowsById.get(id);
        statusMap[getNodeId(wellInputRow.wellId, SchemeComponentType.Well)] = statusObj.status;
      } else if (type === SchemeComponentType.Valve) {
        const lineupRow = lineup.rowsById.get(id);
        statusMap[getNodeId(lineupRow.valveId, SchemeComponentType.Valve)] = statusObj.status;
      } else {
        statusMap[id] = statusObj.status;
      }
    }
  }
};

const selectChangesByWeekId = createSelector(
  [selectOfficialInputPresentState, selectedWeekSelector],
  ({ changes, lineup, wellInput }, weekDate) => {
    const statusMap: Record<string, boolean> = {};

    if (!weekDate) {
      return statusMap;
    }

    const weekId = weekDate.id;

    NodeTypes.forEach((type) => {
      const change = changes?.[type];

      if (change) {
        updateStatusMap(statusMap, change, weekId, type, lineup, wellInput);
      }
    });

    return statusMap;
  }
);

export const mainSchemeSelector = createSelector([schemesSelector, selectChangesByWeekId], (data, changesStatuses) => {
  if (!data.main) {
    return emptyScheme;
  }
  const nodes = applyNodeStatuses(data.main.nodes, changesStatuses);
  const edges = applyEdgeStatuses(nodes, data.main.edges);

  return { edges, nodes } as SchemeType;
});

export const meterStationSchemeSelector = createSelector(
  [schemesSelector, meterStationIdSelector, selectChangesByWeekId],
  (schemes, meterStationId, changesStatuses) => {
    const data = schemes[meterStationId];

    if (data) {
      const nodes = applyNodeStatuses(data.nodes, changesStatuses);
      const edges = applyEdgeStatuses(nodes, data.edges);

      return { edges, nodes } as SchemeType;
    }

    return emptyScheme;
  }
);

export const meterStationNameSelector = createSelector(
  [(state: RootState) => state, meterStationIdSelector],
  (state, meterStationId) => {
    const scheme = meterStationSchemeSelector(state, meterStationId);

    return scheme.nodes.find((node) => node.data.facilityId === meterStationId)?.data.label ?? '';
  }
);
