import cx from 'classnames';
import { isEqual, uniqWith } from 'lodash';
import React, { CSSProperties, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { Edge, getConnectedEdges, Handle, HandleProps, NodeProps, Position, useEdges, useStore } from 'reactflow';
import { useStoreApi, useUpdateNodeInternals } from 'reactflow';
import { DiagramContext } from '../DiagramContext';
import { DiagramEdgeData, DiagramNodeData } from '../types';
import { getDedicatedHandleStyle } from '../utils/getDedicatedHandleStyle';
import { NodeSize, useNodeResize } from '../utils/useNodeResize';
import styles from './DiagramNode.module.css';

/**
 * The props for the {@link DiagramNode} component.
 * @category Props
 */
export interface DiagramNodeProps<TItem extends DiagramNodeData = any> extends NodeProps<TItem> {
    className?: string;
    label?: DiagramNodeData<TItem>['label'];
    style?: CSSProperties;
}

/**
 * @category Component
 * @group Diagram
 */
export const DiagramNode = <TItem extends DiagramNodeData>(props: DiagramNodeProps<TItem>) => {
    const {
        id,
        xPos,
        yPos,
        className,
        data,
        isConnectable,
        label: labelProp,
        style: styleProp,
        selected,
        dragging
    } = props;
    const { label, size: sizeProp, resizable: resizableSetting, backgroundColor } = data;
    const { nodesResizable } = useContext(DiagramContext);
    const store = useStoreApi();
    const updateNodeInternals = useUpdateNodeInternals();

    const resizable = resizableSetting || nodesResizable;

    const nodeRef = useRef();

    const { node, nodesDraggable, nodesConnectable, snapGrid } = useStore((store) => ({
        node: store.nodeInternals.get(id),
        nodesDraggable: store.nodesDraggable,
        nodesConnectable: store.nodesConnectable,
        snapGrid: store.snapGrid
    }));

    const edges = useEdges<DiagramEdgeData>();

    const initialSize = useCallback(
        (): NodeSize => ({
            width: sizeProp?.width ?? (styleProp?.width as number),
            height: sizeProp?.height ?? (styleProp?.height as number)
        }),
        [sizeProp?.height, sizeProp?.width, styleProp?.height, styleProp?.width]
    );
    const nodeSize = useState(initialSize);

    const [size, setSize] = nodeSize;

    useEffect(() => {
        setSize(initialSize());
    }, [initialSize, setSize]);

    const dimensions = useRef({ position: { xPos, yPos }, size });
    dimensions.current = { position: { xPos, yPos }, size };

    const { ResizeButton, handleWrapperClick } = useNodeResize({
        id,
        resizable,
        nodeRef,
        selected,
        dragging,
        snapGrid,
        nodeSize,
        dimensions,
        store
    });

    const handleClass = cx(styles.handle, { [styles.handle_disabled]: !isConnectable || !nodesConnectable });

    const handles = useMemo(() => {
        const connectors: (HandleProps & { offset: number })[] = [];
        const connectedEdges = getConnectedEdges([node], edges) as Edge<DiagramEdgeData>[];
        for (const edge of connectedEdges) {
            if (edge.source === id) {
                connectors.push({
                    id: edge.sourceHandle,
                    type: 'source',
                    position: edge.data.originalSourcePosition,
                    offset: 0
                });
            }

            if (edge.target === id) {
                connectors.push({
                    id: edge.targetHandle,
                    type: 'target',
                    position: edge.data.originalTargetPosition,
                    offset: 0
                });
            }
        }

        const uniqConnectors = uniqWith(connectors, isEqual);

        const edgeTotalCount = new Map<Position, number>();
        for (const conn of uniqConnectors) {
            edgeTotalCount.set(conn.position, (edgeTotalCount.get(conn.position) ?? 1) + 1);
        }

        const edgeCounter = new Map<Position, number>();
        for (const connector of uniqConnectors) {
            const totalCount = edgeTotalCount.get(connector.position);
            edgeCounter.set(connector.position, (edgeCounter.get(connector.position) ?? 0) + 1);
            const count = edgeCounter.get(connector.position);

            connector.offset = (count * 100) / totalCount;
        }

        return uniqConnectors.map(({ offset, ...props }, idx) => (
            <Handle
                key={idx}
                className={handleClass}
                isConnectable={isConnectable}
                {...props}
                style={getDedicatedHandleStyle(props.position, offset)}
            />
        ));
    }, [edges, id, node]);

    useEffect(() => {
        updateNodeInternals(id);
    }, [id, updateNodeInternals, handles.length]);

    return (
        <div
            className={cx('node', styles.node, { nodrag: !nodesDraggable }, className)}
            ref={nodeRef}
            onClick={handleWrapperClick}
        >
            {handles}
            <span className={cx('label', styles.label)}>{labelProp ?? label}</span>
            {ResizeButton}
        </div>
    );
};
