import { IP6Dto } from 'api';

export function convertP6Data(data: IP6Dto[]) {
  const rows = [...data];
  rows.sort((a, b) => a.startDate.getTime() - b.startDate.getTime());

  const rowsById = new Map<number, IP6Dto>();

  for (const item of rows) {
    rowsById.set(item.id, item);
  }

  return { rows, rowsById };
}
