import React, { FC, useCallback, useContext } from 'react';
import { Button, ButtonProps } from '../Button/Button';
import { CalendarContext } from './CalendarContext';

/**
 * @category Component
 * @group Date Picker
 */
export const TodayButton: FC<ButtonProps> = (props) => {
    const { setDate, setOffset } = useContext(CalendarContext);

    const handleClick = useCallback(() => {
        setDate(new Date());
        setOffset(0);
    }, [setOffset, setDate]);

    return (
        <Button size="small" onClick={handleClick} {...props}>
            Today
        </Button>
    );
};
