import { faAngleDoubleLeft, faAngleDoubleRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import BigNumber from 'bignumber.js';
import cx from 'classnames';
import moment from 'moment';
import React, {
    CSSProperties,
    FC,
    forwardRef,
    ForwardRefExoticComponent,
    useCallback,
    useContext,
    useMemo,
    useState,
} from 'react';
import { unstable_batchedUpdates } from 'react-dom';
import reactable from 'reactablejs';
import { Tooltip, TooltipProps } from '../../Tooltip';
import { useForkRef } from '../../util';
import { TimelineContext } from '../TimelineContext';
import { TimelineItem, TimelineRow } from '../TimelineTypes';
import {
    useMoveItem,
    cursorChecker,
    getNearestRowId,
    getStylesFromPosition,
    getTimeFromPosition,
    InteractionTime,
    useWrappedCallback,
} from '../utils/interactions';
import styles from './TimelineItem.module.css';

export type TooltipRenderer<TItem> = FC<{
    time: InteractionTime;
    item: TimelineItem<TItem>;
}>;

export const defaultTooltip: TooltipRenderer<any> = ({ time }) => {
    const start = moment(time.start);
    const end = moment(time.end);
    const durationMinutes = start.diff(end, 'minutes');

    return (
        <table>
            <tr>
                <td>Start</td>
                <td>{start.format('DD.MM.YYYY HH:mm')}</td>
            </tr>
            <tr>
                <td>Duration</td>
                <td>{moment.duration(durationMinutes, 'minutes').humanize()}</td>
            </tr>
            <tr>
                <td>End</td>
                <td>{end.format('DD.MM.YYYY HH:mm')}</td>
            </tr>
        </table>
    );
};

export interface TimelineItemRendererProps<TItem, TResource> {
    tooltipProps?: TooltipProps;
    ref?: any;
    getRef?: any;
    className?: string;
    index: number;
    item: TimelineItem<TItem>;
    row: TimelineRow<TItem, TResource>;
    style?: CSSProperties;
}

export type TimelineItemRenderer<TItem = any, TResource = any> = ForwardRefExoticComponent<
    TimelineItemRendererProps<TItem, TResource>
>;

export const RenderItem: TimelineItemRenderer = forwardRef(
    ({ tooltipProps, getRef, className: classNameProp, index, item, row, style: styleProp, ...other }, ref: any) => {
        const handleRef = useForkRef(ref, getRef);

        const instance = useContext(TimelineContext);
        const {
            dragAndDrop,
            resize,
            cellWidth,
            state: {
                dateRange: { startDate, endDate },
                dimensions: { totalWidth, durationMinutes, minuteWidth },
            },
            Item = 'div',
        } = instance;

        const { left, width, startOverlap, endOverlap } = useMemo(
            () => calculateItemDimensions(item, cellWidth, startDate, durationMinutes, minuteWidth, totalWidth),
            [cellWidth, durationMinutes, item, minuteWidth, startDate, totalWidth]
        );

        const selectProps = useMemo(() => item.getToggleItemSelectedProps(), [item]);

        let result = (
            <div
                {...selectProps}
                ref={handleRef}
                className={cx(styles.item, { [styles.item_selected]: item.isSelected })}
                style={{ left, width, ...styleProp, ...selectProps.style }}
                {...other}
            >
                {startOverlap && (
                    <div className={styles.item_overlap}>
                        <FontAwesomeIcon size="xs" icon={faAngleDoubleLeft} />
                    </div>
                )}
                <div className={styles.item_body}>
                    <Item index={index} item={item} row={row} />
                </div>
                {endOverlap && (
                    <div className={styles.item_overlap}>
                        <FontAwesomeIcon size="xs" icon={faAngleDoubleRight} />
                    </div>
                )}
            </div>
        );

        if (dragAndDrop || resize) {
            result = (
                <Tooltip arrow enterDelay={0} {...tooltipProps}>
                    {result}
                </Tooltip>
            );
        }

        return result;
    }
);

interface ReactableTimelineItemRendererProps extends Omit<TimelineItemRendererProps<any, any>, 'getRef'> {
    getRef: any;
}

const TimelineInteractItem = reactable<ReactableTimelineItemRendererProps>(RenderItem as any);

export const RenderInteractItem: TimelineItemRenderer = forwardRef(({ item, row, style, ...other }, ref) => {
    const { position, onStart, onMove, onEnd } = useMoveItem();
    const [tooltipState, setTooltip] = useState<Omit<TooltipProps, 'children'>>({
        open: false,
        title: '',
    });
    const instance = useContext(TimelineContext);

    const {
        rowsById,
        snap,
        dragAndDrop,
        resize,
        state: {
            dateRange: { startDate },
            dimensions: { minuteWidth, interactSnapGrid, interactRestrict },
        },
        Tooltip,
        onDrag,
    } = instance;

    const handleDragStart = useWrappedCallback(
        useCallback(
            (event) => {
                onStart(event);
                setTooltip({
                    open: true,
                    title: '',
                    placement: 'top',
                });
            },
            [onStart]
        )
    );

    const handleDragMove = useWrappedCallback(
        useCallback(
            (event) => {
                const position = onMove(event);

                const time = getTimeFromPosition(position, startDate, minuteWidth, snap);

                setTooltip({
                    open: true,
                    title: <Tooltip time={time} item={item} />,
                    placement: 'top',
                });
            },
            [onMove, startDate, minuteWidth, snap, Tooltip, item]
        )
    );

    const handleDragEnd = useWrappedCallback(
        useCallback(
            (event) => {
                const position = onEnd(event);
                const time = getTimeFromPosition(position, startDate, minuteWidth, snap);
                const rowId = getNearestRowId(event.clientX, event.clientY);
                const row = rowsById[rowId];

                unstable_batchedUpdates(() => {
                    setTooltip({
                        open: false,
                        title: '',
                    });

                    onDrag(
                        {
                            item,
                            row,
                            time,
                        },
                        event
                    );
                });
            },
            [item, minuteWidth, onDrag, onEnd, rowsById, snap, startDate]
        )
    );

    const handleResizeStart = useWrappedCallback(
        useCallback(
            (event) => {
                onStart(event);
                setTooltip({
                    open: true,
                    title: '',
                    placement: event.edges.right ? 'top-end' : 'top-start',
                });
            },
            [onStart]
        )
    );

    const handleResizeMove = useWrappedCallback(
        useCallback(
            (event) => {
                const position = onMove(event);

                const time = getTimeFromPosition(position, startDate, minuteWidth, snap);

                setTooltip({
                    open: true,
                    title: <Tooltip time={time} item={item} />,
                    placement: event.edges.right ? 'top-end' : 'top-start',
                });
            },
            [onMove, startDate, minuteWidth, snap, Tooltip, item]
        )
    );

    const handleResizeEnd = useWrappedCallback(
        useCallback(
            (event) => {
                const position = onEnd(event);
                const time = getTimeFromPosition(position, startDate, minuteWidth, snap);

                unstable_batchedUpdates(() => {
                    setTooltip({
                        open: false,
                        title: '',
                    });

                    onDrag(
                        {
                            item,
                            row,
                            time,
                        },
                        event
                    );
                });
            },
            [item, minuteWidth, onDrag, onEnd, row, snap, startDate]
        )
    );

    const interactProps = useMemo(() => {
        const result: any = {};

        if (dragAndDrop) {
            result.draggable = { cursorChecker };
            result.onDragStart = handleDragStart;
            result.onDragMove = handleDragMove;
            result.onDragEnd = handleDragEnd;
        }

        if (resize) {
            result.resizable = {
                edges: { left: true, right: true, bottom: false, top: false },
                margin: 4,
                //modifiers: [interactSnapGrid]
            };
            result.onResizeStart = handleResizeStart;
            result.onResizeMove = handleResizeMove;
            result.onResizeEnd = handleResizeEnd;
        }

        return result;
    }, [
        dragAndDrop,
        handleDragEnd,
        handleDragMove,
        handleDragStart,
        handleResizeEnd,
        handleResizeMove,
        handleResizeStart,
        resize,
    ]);

    return (
        <TimelineInteractItem
            ref={ref}
            item={item}
            row={row}
            {...other}
            tooltipProps={tooltipState}
            {...interactProps}
            style={{ ...style, ...getStylesFromPosition(position) }}
        />
    );
});

export const defaultItemWrapper = (wrappingItem: TimelineItemRenderer): TimelineItemRenderer => wrappingItem;

function calculateItemDimensions(item, cellWidth, startDate, durationMinutes, minuteWidth, totalWidth) {
    let left = null,
        width = null;

    const startOffset = new BigNumber(moment(item.start).diff(startDate, 'minutes'));
    const itemDuration = new BigNumber(moment(item.end).diff(item.start, 'minutes'));

    let startOverlap = false;
    let endOverlap = false;

    if (cellWidth == 'flex') {
        left = startOffset.times(100).div(durationMinutes).decimalPlaces(4).toNumber();
        width = itemDuration.times(100).div(durationMinutes).decimalPlaces(4).toNumber();

        if (left < 0) {
            width += left;
            left = 0;
            startOverlap = true;
        }

        const itemTotalWidth = left + width;
        if (itemTotalWidth > 100) {
            width -= itemTotalWidth - 100;
            endOverlap = true;
        }

        left += '%';
        width += '%';
    } else {
        left = startOffset.times(minuteWidth).decimalPlaces(2).toNumber();
        width = itemDuration.times(minuteWidth).decimalPlaces(2).toNumber();

        if (left < 0) {
            width += left;
            left = 0;
            startOverlap = true;
        }

        const itemTotalWidth = left + width;
        if (itemTotalWidth > totalWidth) {
            width -= itemTotalWidth - totalWidth;
            endOverlap = true;
        }
    }

    return { left, width, startOverlap, endOverlap };
}
