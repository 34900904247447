import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'store';
import {
  createWellInputSelector,
  getWellInputSnapshot
} from 'store/official-inputs/wellInput/utils/getWellInputSnapshot';
import { selectCasesChanges, selectCasesPresentState } from '../CasesSelector';

export const wellInputCaseStateSelector = createSelector(selectCasesPresentState, (state) => state.wellInput);

const wellInputRowsSelector = (state: RootState) => wellInputCaseStateSelector(state).rows;
const wellInputDatesSelector = (state: RootState) => wellInputCaseStateSelector(state).dates;

const searchQuerySelector = (_: RootState, searchQuery?: string) => searchQuery;
const hideOfflineSelector = (_: RootState, _searchQuery?: string, hideOffline?: boolean) => hideOffline;

export const wellInputCaseSelector = createWellInputSelector(
  [wellInputRowsSelector, wellInputDatesSelector, selectCasesChanges, searchQuerySelector, hideOfflineSelector],
  (rows, dates, changes, searchQuery, hideOffline) =>
    getWellInputSnapshot(rows, dates, changes, searchQuery, hideOffline)
);
