import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Button, Grid, Typography } from '@mui/material';
import React, { MouseEventHandler } from 'react';

interface Props {
  title: string;
  isViewOnly?: boolean;
  goBack: MouseEventHandler<HTMLButtonElement>;
}

const ScenarioCasePageHeader: React.FC<Props> = ({ title, isViewOnly, goBack }) => {
  return (
    <Grid container sx={{ mb: 2 }} spacing={1} justifyContent="start" alignItems="center">
      <Button
        variant="text"
        sx={{ color: 'black', textTransform: 'none' }}
        startIcon={<ArrowBackIcon />}
        onClick={goBack}
      >
        <Grid item xs="auto">
          <Typography sx={{ fontWeight: 700 }} display="inline">
            {title}
          </Typography>
          {isViewOnly && (
            <Typography sx={{ color: 'GrayText' }} display="inline">
              &nbsp;view only
            </Typography>
          )}
        </Grid>
      </Button>
    </Grid>
  );
};

export default ScenarioCasePageHeader;
