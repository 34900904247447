import { createSlice, Reducer } from '@reduxjs/toolkit';
import { castDraft } from 'immer';
import { handleError, handleLoading, handleSuccess } from 'store/base/baseStateHandlers';
import { LineupState } from 'store/official-inputs/lineup/lineupState';
import { convertLineupData } from 'store/official-inputs/lineup/utils/convertLineupData';
import { getLineupHistory } from './actions/getLineupHistory';

const initialState: LineupState<number> = { splitableWeekDateIds: new Set() };

const { reducer } = createSlice({
  initialState,
  name: 'lineupHistory',
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getLineupHistory.pending, (state, action) => {
        state.arg = castDraft(action.meta.arg);
        handleLoading(state);
        state.rows = undefined;
        state.dates = undefined;
        state.datesById = undefined;
      })
      .addCase(getLineupHistory.fulfilled, (state, action) => {
        state.arg = castDraft(action.meta.arg);
        handleSuccess(state, action);
        const { rows, rowsById, dates, datesById } = convertLineupData(action.payload);
        state.rows = rows;
        state.rowsById = rowsById;
        state.dates = dates;
        state.datesById = datesById;
      })
      .addCase(getLineupHistory.rejected, (state, action) => {
        state.arg = undefined;
        handleError(state, action);
      });
  }
});

export default reducer as Reducer<typeof initialState>;
