import BigNumber from 'bignumber.js';
import { memoize } from 'lodash';
import moment, { Moment } from 'moment';
import { CSSProperties, useCallback, useRef, useState } from 'react';
import { TimelineSnap } from '../TimelineTypes';

export interface InteractionPosition {
    left: number;
    top: number;
    width: number;
    height: number;
}

export interface InteractionTime {
    start: Date;
    end: Date;
}

const defaultOffset = { left: 0, top: 0 };

const getMomentSnap = memoize((snap: TimelineSnap) => {
    switch (snap) {
        case 'month':
            return (date: Moment) => date.startOf('month');
        case 'day':
            return (date: Moment) => date.startOf('day');
        case 'hour':
            return (date: Moment) => date.startOf('hour');
        default:
            return (date: Moment) => date.startOf('minute');
    }
});

export function getTimeFromPosition(
    position: InteractionPosition,
    startDate,
    minuteWidth,
    snap: TimelineSnap
): InteractionTime {
    const { left, width } = position;

    const startMinutes = new BigNumber(left).div(minuteWidth).toNumber();
    let start = moment(startDate).add(startMinutes, 'minutes');

    const durationMinutes = new BigNumber(width).div(minuteWidth).toNumber();
    let end = start.clone();
    end.add(durationMinutes, 'minutes');

    const snapper = getMomentSnap(snap);
    start = snapper(start);
    end = snapper(end);

    return {
        start: start.toDate(),
        end: end.toDate(),
    };
}

export function getStylesFromPosition(position: InteractionPosition): CSSProperties {
    if (!position) return null;

    const { left, top, width, height } = position;
    return {
        left: left + 'px',
        top: top + 'px',
        width: width + 'px',
        height: height + 'px',
        zIndex: 2,
    };
}

export function useWrappedCallback<T>(callback: T): T {
    const ref = useRef<any>();
    ref.current = callback;

    return ((...args) => {
        return ref.current(...args);
    }) as unknown as T;
}

export function useMoveItem() {
    const [position, setPosition] = useState<InteractionPosition>(null);
    const offset = useRef<{
        left: number;
        top: number;
    }>(defaultOffset);

    const onStart = useCallback((event) => {
        const target = event.target as HTMLDivElement;

        const { left, top } = event.rect;

        offset.current = {
            left: left - target.offsetLeft,
            top: top - target.offsetTop,
        };
    }, []);

    const onMove = useCallback((event) => {
        const { left, top, width, height } = event.rect;

        const position: InteractionPosition = {
            left: left - offset.current.left,
            top: top - offset.current.top,
            width,
            height,
        };

        setPosition(position);
        return position;
    }, []);

    const onEnd = useCallback((event) => {
        const { left, top, width, height } = event.rect;

        const position: InteractionPosition = {
            left: left - offset.current.left,
            top: top - offset.current.top,
            width,
            height,
        };

        setPosition(null);
        offset.current = defaultOffset;

        return position;
    }, []);

    return {
        position,
        onStart,
        onMove,
        onEnd,
    };
}

export const getNearestRowId = (x, y, container = document) => {
    const elements = document.elementsFromPoint(x, y);
    const target = elements.find((e) => {
        const isInside = container.contains(e);
        return isInside && e.hasAttribute('data-timeline-row');
    });
    return target ? target.getAttribute('data-timeline-row') : null;
};

export function cursorChecker(action, interactable, element, interacting) {
    switch (action.axis) {
        case 'x':
            return 'ew-resize';
        case 'y':
            return 'ns-resize';
        default:
            return interacting ? 'grabbing' : 'grab';
    }
}
