import { Props as DayzedProps, Props, RenderProps } from 'dayzed';
import { createContext, ForwardRefExoticComponent, MouseEvent, RefAttributes } from 'react';
import { DayButtonProps, DayWrapper } from './DayButton';
import { MonthButtonProps, MonthWrapper } from './MonthButton';
import { YearButtonProps, YearWrapper } from './YearButton';

export type CalendarDepth = 'month' | 'year' | 'decade';

export const CalendarDepthValue: Record<CalendarDepth, number> & Record<number, CalendarDepth> = {
    month: 1,
    1: 'month',
    year: 2,
    2: 'year',
    decade: 3,
    3: 'decade'
};

export type CalendarDateRange = [start: Date, end?: Date];

export type SingleValueCalendar = {
    selectRange?: false;
    value?: undefined | Date;
    onChange?: (selected?: undefined | Date) => void;
};

export type MultiValueCalendar = {
    selectRange?: true;
    value?: undefined | Date[] | CalendarDateRange;
    onChange?: (selected?: undefined | Date[] | CalendarDateRange) => void;
};

export type CalendarValueProps = SingleValueCalendar | MultiValueCalendar;

export type CalendarOptions = Omit<DayzedProps, 'selected' | 'children' | 'render'> & {
    disabled?: boolean;
    utcOffset?: number;
    enableTime?: boolean;
    minDepth?: CalendarDepth;
    defaultDepth?: CalendarDepth;
    DayComponent?: ForwardRefExoticComponent<DayButtonProps & RefAttributes<HTMLButtonElement>>;
    DayWrapper?: DayWrapper;
    MonthComponent?: ForwardRefExoticComponent<MonthButtonProps & RefAttributes<HTMLButtonElement>>;
    MonthWrapper?: MonthWrapper;
    YearComponent?: ForwardRefExoticComponent<YearButtonProps & RefAttributes<HTMLButtonElement>>;
    YearWrapper?: YearWrapper;
} & CalendarValueProps;

export type CalendarInstance = Props &
    RenderProps &
    CalendarOptions & {
        selectRange?: boolean;
        date?: Date;
        setDate: React.Dispatch<React.SetStateAction<Date>>;
        depth: CalendarDepth;
        setDepth: React.Dispatch<React.SetStateAction<CalendarDepth>>;
        handleDateHover: (date: Date, event: MouseEvent) => void;
        handleResetHover: (event: MouseEvent) => void;
        handleClear: (event: MouseEvent) => void;
        handleDepthDowngrade: () => boolean;
        setOffset: React.Dispatch<React.SetStateAction<number>>;
        handleOffsetChanged: (offset: number) => void;
        isInRange: (date: Date) => boolean;
    };

export const CalendarContext = createContext<CalendarInstance>(null as any);
