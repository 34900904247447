import { CSSProperties, useCallback, useEffect, useMemo, useRef } from 'react';
import { DataGridColumnInstance, DataGridInstance, DataGridPluginHook, DataGridState } from '../types';

const SET_COLUMN_STICKY_POSITION = 'setColumnStickyPosition';
const RESET_COLUMN_STICKY_POSITION = 'resetColumnStickyPosition';

export const stickyColumnHook: DataGridPluginHook = (hooks) => {
    hooks.stateReducers.push((state: DataGridState, action) => {
        if (action.type === RESET_COLUMN_STICKY_POSITION) {
            state.sticky.delete(action.columnId);
        }

        if (action.type === SET_COLUMN_STICKY_POSITION) {
            state.sticky.set(action.columnId, action.position);
        }

        return {
            ...state,
            sticky: state.sticky ?? new Map()
        };
    });

    hooks.useInstance.push((instance: DataGridInstance) => {
        const { dispatch } = instance;
        const setColumnStickyPosition = useCallback(
            (columnId: string, position: number) => {
                dispatch({ type: SET_COLUMN_STICKY_POSITION, columnId, position });
            },
            [dispatch]
        );

        const resetColumnStickyPosition = useCallback(
            (columnId: string) => {
                dispatch({ type: RESET_COLUMN_STICKY_POSITION, columnId });
            },
            [dispatch]
        );

        Object.assign(instance, {
            setColumnStickyPosition,
            resetColumnStickyPosition
        });
    });
};

export function useStikyColumn(column: DataGridColumnInstance, instance: DataGridInstance): CSSProperties {
    const value = instance.state.sticky.get(column.id);
    const sticky = column.sticky === 'left' ? 'left' : 'right';

    const stickyStyle: CSSProperties = useMemo(() => {
        if (sticky && value >= 0) {
            return {
                position: 'sticky',
                zIndex: 1,
                [sticky]: value,
            }
        }
        return {}; 
    }, [sticky, value]);

    return stickyStyle;
}

export function useStikyColumnHeader(column: DataGridColumnInstance, instance: DataGridInstance) {
    const headerRef = useRef<HTMLElement>();

    const sticky = useMemo(() => {
        function getStickyValue(column: DataGridColumnInstance) {
            if (column.sticky) {
                return column.sticky;
            }

            if (column.placeholderOf) {
                return getStickyValue(column.placeholderOf);
            }
        }

        return getStickyValue(column);
    }, []);
    const value = instance.state.sticky.get(column.id);

    const handleMeasure = useCallback((): number | undefined  => {
        if (!sticky) {
            return;
        }

        const bodyRect = instance.bodyRef.current?.getBoundingClientRect();
        const itemRect = headerRef.current?.getBoundingClientRect();

        if (!bodyRect || !itemRect) {
            return;
        }

        const scrollWidth = instance.bodyRef.current.offsetWidth - instance.bodyRef.current.clientWidth;

        let newValue =
            sticky === 'left' ? itemRect.left - bodyRect.left : itemRect.right - bodyRect.right + scrollWidth;
        if (newValue < 0) {
            newValue += instance.bodyRef.current.scrollLeft;
        }
        if (newValue !== value) {
            return newValue;
        }
        return value;
    }, [instance.bodyRef.current, headerRef.current, sticky, value]);

    useEffect(() => {
        if (!sticky) {
            return;
        }
        instance.setColumnStickyPosition(column.id, handleMeasure());
    }, [sticky, handleMeasure]);

    const stickyStyle: CSSProperties = useMemo(() => {
        if (!sticky) {
            return {};
        }

        return {
            position: 'sticky',
            zIndex: 1,
            [sticky]: handleMeasure(),
        } 
    }, [sticky, handleMeasure]);

    return {
        headerRef,
        stickyStyle
    };
}
