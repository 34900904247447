import { createSelector } from 'reselect';
import { RootState } from 'store';
import { selectOfficialInputHiddenWeekDateIds } from 'store/official-input-page/OfficialInputPageSelectors';
import { selectOfficialInputPresentState, selectOfficialInputUserChanges } from '../OfficialSelectors';
import { createWellInputSelector, getWellInputSnapshot } from './utils/getWellInputSnapshot';

export const wellInputStateSelector = (state: RootState) => selectOfficialInputPresentState(state).wellInput;
export const wellInputLoadingSelector = (state: RootState) => wellInputStateSelector(state).loading;

const wellInputRowsSelector = (state: RootState) => wellInputStateSelector(state).rows;
const wellInputDatesSelector = (state: RootState) => wellInputStateSelector(state).dates;
const selectWellInputSplitableWeekDates = (state: RootState) => wellInputStateSelector(state).splitableWeekDateIds;

const searchQuerySelector = (_: RootState, searchQuery?: string) => searchQuery;
const hideOfflineSelector = (_: RootState, _searchQuery?: string, hideOffline?: boolean) => hideOffline;

export const filterWellInputWeekDays = createSelector(
  [wellInputDatesSelector, selectWellInputSplitableWeekDates, selectOfficialInputHiddenWeekDateIds],
  (dates, splitableWeekDateIds, hiddenWeekDateIds) => {
    return dates?.filter((x) => splitableWeekDateIds.has(x.id) || !hiddenWeekDateIds.has(x.id));
  }
);

export const wellInputSelector = createWellInputSelector(
  [
    wellInputRowsSelector,
    wellInputDatesSelector,
    selectOfficialInputUserChanges,
    searchQuerySelector,
    hideOfflineSelector
  ],
  (rows, dates, changes, searchQuery, hideOffline) =>
    getWellInputSnapshot(rows, dates, changes, searchQuery, hideOffline)
);
