import { ChildConstraintDto, ConstraintTypeDto } from 'api';
import { ConstraintRow, EquipmentNameRow, EquipmentTypeRow } from '../types/ConstraintsRow';
import { ConstraintsSelectType } from '../types/ConstraintsSelectType';
import { EquipmentTypeAccessor } from '../types/EquipmentTypeAccessor';
import { constraintsOptions } from './constraintOptions';
import { equipmentTypeOptions } from './equipmentTypeOptions';

const getEquipmentTypeAccessor = ({
  wellRateId,
  jointRateId,
  separatorId,
  groupConstraintId
}: ChildConstraintDto): EquipmentTypeAccessor => {
  if (wellRateId) return 'wellRateId';
  if (jointRateId) return 'jointRateId';
  if (separatorId) return 'separatorId';
  if (groupConstraintId) return 'groupConstraintId';

  throw new Error('Undefined EquipmentTypeAccessor ');
};

function getConstraintsSelectType(dtos: ConstraintTypeDto[]): ConstraintsSelectType[] {
  return constraintsOptions.filter((option) => dtos.findIndex((dto) => dto.groupName === option.id) === -1);
}

function convertChildConstraintToMap(childConstraint: ChildConstraintDto[]) {
  const eqTypeRows = new Map<EquipmentTypeAccessor, ChildConstraintDto[]>();
  childConstraint.forEach((childDto) => {
    const property = getEquipmentTypeAccessor(childDto);
    let dtos = eqTypeRows.get(property);
    if (!dtos) {
      dtos = [];
      eqTypeRows.set(property, dtos);
    }
    dtos.push(childDto);
  });
  return eqTypeRows;
}

function processEquipmentNameRow(
  constraintRow: ConstraintRow,
  eqTypeRow: EquipmentTypeRow,
  childDtos: ChildConstraintDto[]
) {
  for (const childDto of childDtos) {
    const equipmentTypeAccessor = getEquipmentTypeAccessor(childDto);
    const eqNameRow: EquipmentNameRow = {
      id: childDto.id,
      name: childDto.constraintName,
      constraintRowId: constraintRow.id,
      equipmentTypeEntityId: childDto[equipmentTypeAccessor],
      entityId: childDto.id,
      equipmentTypeRowId: equipmentTypeAccessor,
      inputs: new Map(childDto.constraintInputs.map((inputDto) => [inputDto.weekDateId, inputDto])),
      type: 'EquipmentName'
    };
    eqTypeRow.subRows.push(eqNameRow);
  }
}

function processEquipmentTypeRow(
  constraintRow: ConstraintRow,
  eqTypeRows: Map<EquipmentTypeAccessor, ChildConstraintDto[]>
) {
  eqTypeRows.forEach((childDtos, property) => {
    const eqTypeOptionRow = equipmentTypeOptions.find((option) => option.id === property);
    const eqTypeRow: EquipmentTypeRow = {
      ...eqTypeOptionRow,
      constraintRowId: constraintRow.id,
      type: 'EquipmentType',
      subRows: []
    };

    processEquipmentNameRow(constraintRow, eqTypeRow, childDtos);

    constraintRow.subRows.push(eqTypeRow);
  });
}

function processConstraintRow(
  constraintOptionRow: ConstraintsSelectType,
  childConstraint: ChildConstraintDto[]
): ConstraintRow {
  const constraintRow: ConstraintRow = {
    ...constraintOptionRow,
    type: 'Constraint',
    subRows: []
  };

  const eqTypeRows = convertChildConstraintToMap(childConstraint);
  processEquipmentTypeRow(constraintRow, eqTypeRows);

  return constraintRow;
}

function getRows(dtos: ConstraintTypeDto[]): ConstraintRow[] {
  const rows: ConstraintRow[] = [];

  dtos.forEach(({ groupName, childConstraint }) => {
    const constraintOptionRow = constraintsOptions.find((option) => option.id === groupName);
    if (constraintOptionRow) {
      const constraintRow = processConstraintRow(constraintOptionRow, childConstraint);
      rows.push(constraintRow);
    }
  });

  return rows;
}

export function convertConstrainsAsInputData(dtos: ConstraintTypeDto[]) {
  const constraintTypeOptions = getConstraintsSelectType(dtos);
  const rows = getRows(dtos);
  return {
    constraintTypeOptions,
    rows
  };
}
