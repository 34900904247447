import { ApplyLinupLogicRequestDto, LineupLogicClient, LineupLogicItem, SchemeComponentType } from 'api';
import { createAppAsyncThunk } from 'store/createAppAsyncThunk';
import { selectOfficialInputPresentState } from 'store/official-inputs/OfficialSelectors';
import { MSChangesValueType, OfficialInputState } from '../OfficialInputState';

type ValueKey = 'wellId' | 'valveId' | 'headerId';

type Status = {
  [key in ValueKey]?: number;
} & {
  status?: boolean;
};

const service = new LineupLogicClient();

const mapParents = (dict: Map<number, LineupLogicItem[]>, arr: IterableIterator<Map<number, MSChangesValueType>>) => {
  for (const rowChange of arr) {
    rowChange.forEach(({ status, lineup, wellInput }, weekDateId) => {
      let column = dict.get(weekDateId);

      if (!column) {
        column = [];
        dict.set(weekDateId, column);
      }

      if (lineup) {
        column.push(
          new LineupLogicItem({
            status,
            componentType: SchemeComponentType.MeterStation,
            id: lineup?.meterStationId
          })
        );
      }

      if (wellInput) {
        column.push(
          new LineupLogicItem({
            status,
            componentType: SchemeComponentType.MeterStation,
            id: wellInput?.id
          })
        );
      }
    });
  }
};

const mapChildren = <T extends Status>(
  dict: Map<number, LineupLogicItem[]>,
  arr: IterableIterator<Map<number, T>>,
  valueKey: ValueKey,
  componentType: SchemeComponentType
) => {
  for (const rowChange of arr) {
    rowChange.forEach((item, weekDateId) => {
      let column = dict.get(weekDateId);

      if (!column) {
        column = [];
        dict.set(weekDateId, column);
      }

      column.push(
        new LineupLogicItem({
          componentType,
          status: item.status,
          id: item[valueKey]
        })
      );
    });
  }
};

function getApplyLogicData(officialInputState: OfficialInputState): ApplyLinupLogicRequestDto[] {
  const dict = new Map<number, LineupLogicItem[]>();

  mapParents(dict, officialInputState.changes.MeterStation.values());
  mapChildren(dict, officialInputState.changes.Header.values(), 'headerId', SchemeComponentType.Header);
  mapChildren(dict, officialInputState.changes.Valve.values(), 'valveId', SchemeComponentType.Valve);
  mapChildren(dict, officialInputState.changes.Well.values(), 'wellId', SchemeComponentType.Well);

  return Array.from(
    dict,
    ([weekDateId, lineupLogicItems]) =>
      new ApplyLinupLogicRequestDto({
        weekDateId,
        lineupLogicItems
      })
  );
}

export const applyLineupLogicAction = createAppAsyncThunk(
  'official-inputs/lineuplogic/applylogic',
  (_: void, { getState }) => {
    const officialInputState = selectOfficialInputPresentState(getState());

    const dtos = getApplyLogicData(officialInputState);

    return service.applyLogic(dtos);
  }
);
