import React, { forwardRef, HTMLProps } from 'react';

export const styles = {
    root: {
        zIndex: -1,
        position: 'fixed',
        right: 0,
        bottom: 0,
        top: 0,
        left: 0,
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        WebkitTapHighlightColor: 'transparent',
    },

    invisible: {
        backgroundColor: 'transparent',
    },
};

/**
 * The props for the {@link SimpleBackdrop} component.
 * @category Props
 */
interface SimpleBackdropProps extends HTMLProps<HTMLDivElement> {
    invisible?: boolean;
    open: boolean;
}

/**
 * @category Component
 * @group Popover
 */
export const SimpleBackdrop = forwardRef(function SimpleBackdrop(props: SimpleBackdropProps, ref: any) {
    const { invisible = false, open, ...other } = props;

    return open ? (
        <div
            aria-hidden
            ref={ref}
            {...other}
            style={{
                ...styles.root,
                ...(invisible ? styles.invisible : ({} as any)),
                ...other.style,
            }}
        />
    ) : null;
});
