import { useCallback, useRef, useState } from 'react';

/**
 * @category Util
 */
export function useControlled({ controlled, default: defaultProp }) {
    const { current: isControlled } = useRef(controlled !== undefined);
    const [valueState, setValue] = useState(defaultProp);
    const value = isControlled ? controlled : valueState;

    const setValueIfUncontrolled = useCallback((newValue) => {
        if (!isControlled) {
            setValue(newValue);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return [value, setValueIfUncontrolled];
}
