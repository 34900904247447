import interact from 'interactjs';
import moment from 'moment';
import { TimelineState, UniversalTimelineInstance } from '../TimelineTypes';

import BigNumber from 'bignumber.js';
import { useCallback, useEffect, useMemo, useRef } from 'react';
import { useDebouncedCallback } from 'use-debounce/lib';
import { setRef, useSyncEffect } from '../../util';
import { actions } from '../utils/actions';

actions.dimensions = 'dimensions';

function calculateDimensions(cellWidth, rowHeight, resourceWidth, body: Element, startDate, endDate, duration) {
    const totalWidth = cellWidth == 'flex' ? body?.clientWidth - resourceWidth : cellWidth * duration;
    const rowWidth = cellWidth == 'flex' ? ('100%' as any) : totalWidth;
    const durationMinutes = moment(endDate).diff(startDate, 'minutes');
    const minuteWidth = new BigNumber(totalWidth).div(durationMinutes);

    return {
        totalWidth,
        durationMinutes,
        rowWidth,
        minuteWidth,
        interactSnapGrid: interact.modifiers.snap({
            targets: [interact.createSnapGrid({ x: 40, y: rowHeight })],
            range: Infinity,
            relativePoints: [{ x: 0, y: 0 }],
        }),
        interactRestrict: {
            restriction: body,
            elementRect: { top: 0, left: 0, bottom: 1, right: 1 },
            endOnly: true,
        },
    };
}

export function dimensionsReducer(
    state: TimelineState<any>,
    action,
    previousState,
    instance: UniversalTimelineInstance
): TimelineState<any> {
    if (action.type === actions.init) {
        const { cellWidth, rowHeight, resourceWidth } = instance;

        const { startDate, endDate, duration } = state.dateRange;

        const dimensions = calculateDimensions(
            cellWidth,
            rowHeight,
            resourceWidth,
            '100%' as any,
            startDate,
            endDate,
            duration
        );

        return {
            ...state,
            dimensions,
        };
    }

    if (action.type === actions.dimensions) {
        return {
            ...state,
            dimensions: {
                ...state.dimensions,
                ...action.payload,
            },
        };
    }

    return state;
}

export function useDimensions(instance: UniversalTimelineInstance) {
    const {
        cellWidth,
        rowHeight,
        resourceWidth,
        state: {
            dateRange: { startDate, endDate, duration },
            dimensions: { totalWidth, durationMinutes },
        },
        state,
        dispatch,
        events,
    } = instance;

    const bodyRef = useRef();

    const updateDimensions = useCallback(
        (dispatchAction = true) => {
            const body: any = bodyRef.current;
            if (body) {
                const newDimensions = calculateDimensions(
                    cellWidth,
                    rowHeight,
                    resourceWidth,
                    body,
                    startDate,
                    endDate,
                    duration
                );

                if (newDimensions.totalWidth != totalWidth || newDimensions.durationMinutes != durationMinutes) {
                    if (dispatchAction) {
                        dispatch({
                            type: actions.dimensions,
                            payload: newDimensions,
                        });
                    }
                    return newDimensions;
                }
            }
        },
        [cellWidth, dispatch, duration, durationMinutes, endDate, resourceWidth, rowHeight, startDate, totalWidth]
    );

    useMemo(() => {
        const dimensions = updateDimensions(false);
        if (dimensions) {
            Object.assign(instance.state.dimensions, dimensions);
        }
    }, [instance.state.dimensions, updateDimensions]);

    const handleResize = useDebouncedCallback((event: UIEvent) => {
        updateDimensions();
    }, 300);

    useEffect(() => {
        window.addEventListener('resize', handleResize.callback);

        return () => {
            window.removeEventListener('resize', handleResize.callback);
        };
    }, [handleResize]);

    useSyncEffect(() => {
        const handleBodyMount = (element: Element) => {
            if (!element) return;
            setRef(bodyRef, element);

            updateDimensions();
        };

        events.on('bodyMount', handleBodyMount);

        return () => {
            events.removeListener('bodyMount', handleBodyMount);
        };
    }, [events, updateDimensions]);
}
