import { useEffect } from 'react';

export default function usePageTitle(title: string, override = false) {
  useEffect(() => {
    document.title = override ? title : `${title} | WSD`;

    return () => {
      document.title = 'White Space Dashboard';
    };
  }, [title, override]);
}
