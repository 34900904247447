import { configureStore } from '@reduxjs/toolkit';
import { enableMapSet } from 'immer';
import { EqualityFn, useDispatch, useSelector } from 'react-redux';
import { rootApi } from './rootApi';
import { rootReducer } from './rootReducer';

enableMapSet();

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      //TODO: remove this to catch potential bugs
      serializableCheck: false
    }).concat(rootApi.middleware)
});

export type AppDispatch = typeof store['dispatch'];
export const useAppDispatch: () => AppDispatch = useDispatch;
export type RootState = ReturnType<typeof rootReducer>;
export const useAppSelector: <Selected = unknown>(
  selector: (state: RootState) => Selected,
  equalityFn?: EqualityFn<Selected> | undefined
) => Selected = useSelector;
