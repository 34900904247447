import { GuardedRoute } from 'components/Security/GuardedRoute';
import { ROLES } from 'constants/roles';
import { Navigate, RouteObject } from 'react-router-dom';
import { lazyComponent } from 'utils/lazyComponent';
import { caseRoutes } from './case/routes';

const Simulation = lazyComponent(() => import('./index'), 'simulation');
const ScheduleTab = lazyComponent(() => import('./schedule/ScheduleTab'), 'scheduleTab');
const ActivityDashboardTab = lazyComponent(
  () => import('./activity-dashboard/ActivityDashboardTab'),
  'activityDashboardTab'
);

export const simulationRoutes: RouteObject[] = [
  {
    path: 'simulation',
    element: <GuardedRoute roles={[ROLES.wsoUser]} />,
    children: [
      {
        index: true,
        element: <Navigate replace to="schedule" />
      },
      {
        path: '',
        element: <Simulation />,
        children: [
          {
            path: 'schedule',
            element: <ScheduleTab />
          },
          {
            path: 'activity-dashboard',
            element: <ActivityDashboardTab />
          },
          {
            path: 'archive',
            element: <h3>TBD</h3>
          }
        ]
      }
    ]
  },
  ...caseRoutes
];
