import { AxisLeft, AxisRight } from '@visx/axis';
import React, { FC, memo, useContext } from 'react';
import { ChartContext } from '../ChartContext';
import { Axis } from '../chartTypes';

export interface ChartYAxisProps extends Axis {
    orientation?: 'left' | 'right';
}

/**
 * @category Component
 * @group Chart
 */
let ChartYAxis: FC<ChartYAxisProps> = ({
    id,
    tickLabelProps: passedTickLabelProps = null,
    axisStyles = {},
    hideZero = false,
    label = null,
    labelOffset = 34,
    labelProps = null,
    numTicks: numTicksProp = null,
    orientation = 'left',
    rangePadding = null,
    scale = null,
    tickComponent = null,
    tickFormat = null,
    tickStyles = {},
    tickValues = undefined
}) => {
    const { groupDimensions } = useContext(ChartContext);

    if (!scale) return null;

    const dimensions = groupDimensions[id];
    const Axis = orientation === 'left' ? AxisLeft : AxisRight;

    let tickLabelProps = passedTickLabelProps;
    if (!tickLabelProps) {
        tickLabelProps =
            tickStyles.label && tickStyles.label[orientation] ? () => tickStyles.label[orientation] : undefined;
    }

    const numTicks = numTicksProp;

    return (
        <Axis
            top={0}
            left={orientation === 'right' ? dimensions.size.width : 0}
            rangePadding={rangePadding}
            hideTicks={numTicks === 0}
            hideZero={hideZero}
            label={label}
            labelOffset={labelOffset}
            labelProps={labelProps || (axisStyles.label || {})[orientation]}
            numTicks={numTicks}
            scale={scale}
            stroke={axisStyles.stroke}
            strokeWidth={axisStyles.strokeWidth}
            tickComponent={tickComponent}
            tickFormat={tickFormat}
            tickLabelProps={tickLabelProps}
            tickLength={tickStyles.tickLength}
            tickStroke={tickStyles.stroke}
            tickValues={tickValues}
            orientation={orientation}
        />
    );
};

ChartYAxis.displayName = 'ChartYAxis';
ChartYAxis = memo(ChartYAxis);

/** @ignore */
export { ChartYAxis };
