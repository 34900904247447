import {
  CreateScenarioDto,
  DuplicateScenarioCaseDto,
  GetActivityDashboardCasesDto,
  GetScenarioCasesByIdDto,
  ScenarioCaseStatus,
  ScenarioCaseUpdateDto,
  ScenarioClient,
  ScenarioDto
} from 'api';
import { Dictionary, keyBy } from 'lodash';
import { rootApi } from 'store/rootApi';

const service = new ScenarioClient();

const PRE_SIM_STATUSES = [ScenarioCaseStatus.Draft, ScenarioCaseStatus.RolledBack, ScenarioCaseStatus.SentForSimuation];

export type ScenarioParams = {
  scenarioId: number;
};
export type ScenarioCaseParams = {
  scenarioCaseId: number;
};
export type ScenarioAndCaseParams = ScenarioCaseParams & ScenarioParams;
type ScenarioCaseDuplicateParams = ScenarioParams & {
  duplicatingCase?: DuplicateScenarioCaseDto;
};

const simulationScheduleScenarioCasesTag = 'simulationScheduleScenarioCases';

export const scenariosApi = rootApi
  .enhanceEndpoints({
    addTagTypes: [
      'scenario',
      'filteredScenario',
      'filteredScenarioCases',
      'simulationScheduleScenario',
      simulationScheduleScenarioCasesTag,
      'scenarioCases',
      'completedScenarioCases',
      'scenariosWithCompletedCases',
      'activityDashboardCases',
      'activityDashboardCasesStatus',
      'scenarioCasesByIdStatuses',
      'currentSimulationTimestep',
      'getStatusOfCases',
      'scenarioCaseGapStatus'
    ]
  })
  .injectEndpoints({
    endpoints: (builder) => ({
      scenarios: builder.query<ScenarioDto[], void>({
        queryFn: () =>
          service
            .getScenarios()
            .then((data) => ({ data }))
            .catch((error) => ({ error })),
        providesTags: (result) =>
          result
            ? [...result.map(({ id }) => ({ type: 'scenario' as const, id })), { type: 'scenario', id: 'LIST' }]
            : [{ type: 'scenario', id: 'LIST' }]
      }),
      filteredScenarios: builder.query({
        queryFn: (searchText?: string) =>
          service
            .getFilteredScenarios(searchText)
            .then((data) => ({ data }))
            .catch((error) => ({ error })),
        providesTags: (result) =>
          result
            ? [
                ...result.map(({ id }) => ({ type: 'filteredScenario' as const, id })),
                { type: 'filteredScenario', id: 'LIST' }
              ]
            : [{ type: 'filteredScenario', id: 'LIST' }]
      }),
      filteredScenarioCasesById: builder.query({
        queryFn: ({
          scenarioId,
          scenarioCaseStatuses,
          searchText,
          filterCallback
        }: {
          scenarioId: number;
          scenarioCaseStatuses: ScenarioCaseStatus[];
          searchText: string;
          filterCallback?: (data: GetScenarioCasesByIdDto) => boolean;
        }) =>
          service
            .getScenarioCasesById(scenarioId, scenarioCaseStatuses, searchText)
            .then((data) => ({ data: filterCallback ? data.filter(filterCallback) : data }))
            .catch((error) => ({ error })),
        providesTags: (_result, _error, { scenarioId }) => [{ type: 'filteredScenarioCases', id: scenarioId }]
      }),
      scenarioCasesByIdStatuses: builder.query({
        queryFn: (scenarioId: number) =>
          service
            .getScenarioCasesById(scenarioId, PRE_SIM_STATUSES, '')
            .then((data) => ({ data: keyBy(data, 'id') }))
            .catch((error) => ({ error })),
        providesTags: (_result, _error, scenarioId) => [{ type: 'scenarioCasesByIdStatuses', id: scenarioId }]
      }),
      simulationScheduleScenarios: builder.query({
        queryFn: (searchText?: string) =>
          service
            .getSimulationScheduleScenarios(searchText)
            .then((data) => ({ data }))
            .catch((error) => ({ error })),
        providesTags: (result) =>
          result
            ? [
                ...result.map(({ id }) => ({ type: 'simulationScheduleScenario' as const, id })),
                { type: 'simulationScheduleScenario', id: 'LIST' }
              ]
            : [{ type: 'simulationScheduleScenario', id: 'LIST' }]
      }),
      simulationScheduleScenarioCasesById: builder.query({
        queryFn: ({ scenarioId, searchText }: { scenarioId: number; searchText: string }) =>
          service
            .getSimulationScheduleScenarioCasesById(scenarioId, searchText)
            .then((data) => ({ data }))
            .catch((error) => ({ error })),
        providesTags: (_result, _error, { scenarioId }) => [
          { type: simulationScheduleScenarioCasesTag, id: scenarioId }
        ]
      }),
      scenarioCasesById: builder.query({
        queryFn: (scenarioId: number) =>
          service
            .getScenarioCases(scenarioId)
            .then((data) => ({ data }))
            .catch((error) => ({ error })),
        providesTags: (_result, _error, scenarioId) => [{ type: 'scenarioCases', id: scenarioId }]
      }),
      completedScenarioCasesById: builder.query({
        queryFn: (scenarioId: number) =>
          service
            .getCompletedScenarioCases(scenarioId)
            .then((data) => ({ data }))
            .catch((error) => ({ error })),
        providesTags: (_result, _error, scenarioId) => [{ type: 'completedScenarioCases', id: scenarioId }]
      }),
      scenariosWithCompletedCases: builder.query<ScenarioDto[], void>({
        queryFn: () =>
          service
            .getScenariosWithCompletedCases()
            .then((data) => ({ data }))
            .catch((error) => ({ error })),
        providesTags: (result) =>
          result
            ? [
                ...result.map(({ id }) => ({ type: 'scenariosWithCompletedCases' as const, id })),
                { type: 'scenariosWithCompletedCases', id: 'LIST' }
              ]
            : [{ type: 'scenariosWithCompletedCases', id: 'LIST' }]
      }),
      activityDashboardCases: builder.query({
        queryFn: (searchText?: string) =>
          service
            .getActivityDashboardCases(searchText)
            .then((data) => ({ data }))
            .catch((error) => ({ error })),
        providesTags: () => [{ type: 'activityDashboardCases', id: 'LIST' }]
      }),
      activityDashboardCasesStatus: builder.query<Dictionary<GetActivityDashboardCasesDto>, void>({
        queryFn: () =>
          service
            .getActivityDashboardCases('')
            .then((data) => ({ data: keyBy(data, 'id') }))
            .catch((error) => ({ error })),
        providesTags: () => [{ type: 'activityDashboardCasesStatus', id: 'LIST' }]
      }),
      sentToSimulationSchedule: builder.mutation({
        queryFn: ({ scenarioCaseId }: ScenarioAndCaseParams) =>
          service
            .sentToSimulationSchedule(scenarioCaseId)
            .then((data) => ({ data }))
            .catch((error) => ({ error })),
        invalidatesTags: (_result, _error, { scenarioId }) => [
          { type: 'scenarioCasesByIdStatuses', id: scenarioId },
          { type: simulationScheduleScenarioCasesTag, id: scenarioId }
        ]
      }),
      getCurrentTimestepByCaseId: builder.query({
        queryFn: ({ scenarioCaseId }: ScenarioCaseParams) =>
          service
            .getStatusOfCurrentTS(scenarioCaseId)
            .then((data) => ({ data }))
            .catch((error) => ({ error })),
        providesTags: (_result, _error, { scenarioCaseId }) => [
          {
            type: 'currentSimulationTimestep',
            id: scenarioCaseId
          }
        ],
        keepUnusedDataFor: 5
      }),
      sentAllToSimulationSchedule: builder.mutation({
        queryFn: ({ scenarioId }: ScenarioParams) =>
          service
            .sentAllToSimulationSchedule(scenarioId)
            .then((data) => ({ data }))
            .catch((error) => ({ error })),
        invalidatesTags: (_result, _error, { scenarioId }) => [
          { type: 'filteredScenarioCases', id: scenarioId },
          { type: simulationScheduleScenarioCasesTag, id: scenarioId },
          { type: 'simulationScheduleScenario', id: 'LIST' }
        ]
      }),
      getCaseInfo: builder.query({
        queryFn: (caseId: number) =>
          service
            .getStatusOfCases(caseId)
            .then((data) => ({ data }))
            .catch((error) => ({ error })),
        providesTags: (_result, _error, caseId) => [
          {
            type: 'getStatusOfCases',
            id: caseId
          }
        ]
      }),
      duplicateCase: builder.mutation({
        queryFn: ({ duplicatingCase }: ScenarioCaseDuplicateParams) =>
          service
            .duplicateCase(duplicatingCase)
            .then((data) => ({ data }))
            .catch((error) => ({ error })),
        invalidatesTags: (_result, _error, { scenarioId }) => [
          { type: 'filteredScenario', id: 'LIST' },
          { type: 'filteredScenarioCases', id: scenarioId }
        ]
      }),
      deleteCase: builder.mutation({
        queryFn: ({ scenarioCaseId }: ScenarioAndCaseParams) =>
          service
            .deleteScenarioCase(scenarioCaseId)
            .then((data) => ({ data }))
            .catch((error) => ({ error })),
        invalidatesTags: (_result, _error, { scenarioId }) => [
          { type: 'filteredScenario', id: 'LIST' },
          { type: 'filteredScenarioCases', id: scenarioId },
          { type: 'activityDashboardCases', id: 'LIST' },
          { type: 'simulationScheduleScenario', id: 'LIST' },
          { type: simulationScheduleScenarioCasesTag, id: scenarioId }
        ]
      }),
      createScenario: builder.mutation({
        queryFn: (payload: CreateScenarioDto) =>
          service
            .createScenario(payload)
            .then((data) => ({ data }))
            .catch((error) => ({ error })),
        invalidatesTags: [
          { type: 'scenario', id: 'LIST' },
          { type: 'filteredScenario', id: 'LIST' },
          { type: 'simulationScheduleScenario', id: 'LIST' }
        ]
      }),
      updateScenarioCases: builder.mutation({
        queryFn: (payload: ScenarioCaseUpdateDto) =>
          service
            .updateScenarioCases(payload)
            .then((data) => ({ data }))
            .catch((error) => ({ error })),
        invalidatesTags: (_result, _error, { scenarioId }) => [{ type: 'filteredScenarioCases', id: scenarioId }]
      }),
      getStatusOfCurrentTSGapInit: builder.query({
        queryFn: ({ scenarioCaseId }: ScenarioCaseParams) =>
          service
            .getStatusOfCurrentTSGapInit(scenarioCaseId)
            .then((data) => ({ data }))
            .catch((error) => ({ error })),
        providesTags: (_result, _error, { scenarioCaseId }) => [
          {
            type: 'scenarioCaseGapStatus',
            id: scenarioCaseId
          }
        ]
      }),
      cancelGapInit: builder.mutation({
        queryFn: ({ scenarioCaseId }: ScenarioAndCaseParams) =>
          service
            .cancelGapInit(scenarioCaseId)
            .then((data) => ({ data }))
            .catch((error) => ({ error })),
        invalidatesTags: (_result, _error, { scenarioId, scenarioCaseId }) => [
          { type: 'scenarioCaseGapStatus', id: scenarioCaseId },
          { type: simulationScheduleScenarioCasesTag, id: scenarioId }
        ]
      }),
      runGapInitScenarioCase: builder.mutation({
        queryFn: ({ scenarioCaseId }: ScenarioAndCaseParams) =>
          service
            .runGapInitScenarioCase(scenarioCaseId)
            .then((data) => ({ data }))
            .catch((error) => ({ error })),
        invalidatesTags: (_result, _error, { scenarioId }) => [
          { type: simulationScheduleScenarioCasesTag, id: scenarioId }
        ]
      })
    })
  });

export const { useSimulationScheduleScenariosQuery } = scenariosApi;
