import React, { FC, memo, useContext } from 'react';
import { TableBody, TableBodyProps } from '../Table/TableBody';
import { RenderDataGridBody } from './components';
import { DataGridContext } from './DataGridContext';
import { useDataGridBodyEvent } from './hooks/useDataGridBodyEvent';

/**
 * The props for the {@link DataGridBody} component.
 * @category Props
 */
export interface DataGridBodyProps extends TableBodyProps {
    height?: number;
    width?: number;
}

/**
 * @category Component
 * @group Data Grid
 */
export const DataGridBody: FC<DataGridBodyProps> = memo(
    ({ blockLayout: blockLayoutProp = false, height: heightProp, width: widthProp, children, style, ...other }) => {
        const instance = useContext(DataGridContext);
        const { settings, getTableBodyProps } = instance;

        const {
            virtualScrolling,
            blockLayout,
            width: widthSetting,
            height: heightSetting,
            BodyComponent = TableBody
        } = settings;

        const handleRef = useDataGridBodyEvent();

        const width = widthProp ?? widthSetting;
        const height = heightProp ?? heightSetting;

        return (
            <BodyComponent
                ref={virtualScrolling ? null : handleRef}
                blockLayout={blockLayoutProp || blockLayout}
                {...other}
                {...getTableBodyProps({
                    style: {
                        ...style,
                        width,
                        height
                    }
                })}
            >
                {children ?? <RenderDataGridBody width={width} height={height} />}
            </BodyComponent>
        );
    }
);
