import { IGetWeekDatesDto, IWellPressureDto, IWellPressureInputDto, SchemeComponentType } from 'api';
import { sortBy } from 'lodash';
import { getMondaysOnlyDates, groupDatesByWeekNumber } from 'store/official-inputs/lineup/utils/groupDatesByWeekNumber';
import { getDifferenceInDays } from 'utils/dateUtil';
import { calculateWellPressure } from './wellPressureStatusChange';

export type WellPressureTableRowType = SchemeComponentType;

export interface WellPressureTableRow {
  id: string;
  parentId?: string;
  groupId?: string;
  rowType: WellPressureTableRowType;
  name: string;
  subRows?: WellPressureTableRow[];
  originalId?: number;
  wellId?: number;
  pressureDecline?: number;
  reservoirPressure?: number;
  inputs?: Map<number, IWellPressureInputDto>;
}

function sortDates(datesById: Map<number, IGetWeekDatesDto>) {
  const dates = Array.from(datesById.values());
  dates.sort((a, b) => a.weekDate.getTime() - b.weekDate.getTime());
  return dates;
}

function sortRows(parentRowsLookup: Map<string, WellPressureTableRow>) {
  const rows = Array.from(parentRowsLookup.values());
  rows.sort((a, b) => a.name?.localeCompare(b.name));
  for (const row of rows) {
    row.subRows.sort((a, b) => a.name?.localeCompare(b.name));
  }

  return rows;
}

function processWellPressureData(
  wellPressures: IWellPressureDto[],
  datesLookup: Map<number, IGetWeekDatesDto>,
  rowsLookup: Map<string, WellPressureTableRow>,
  parentRowsLookup: Map<string, WellPressureTableRow>,
  adjustWeekDates?: IGetWeekDatesDto[]
) {
  for (const {
    id,
    wellId,
    wellName,
    meterStationName,
    wellPressureInputs,
    pressureDecline,
    reservoirPressure
  } of wellPressures) {
    const msRowId = `${meterStationName}`;
    let msRow = rowsLookup.get(msRowId);
    if (!msRow) {
      msRow = {
        id: msRowId,
        rowType: SchemeComponentType.MeterStation,
        name: meterStationName,
        subRows: []
      };
      rowsLookup.set(msRowId, msRow);
      parentRowsLookup.set(msRowId, msRow);
    }

    const wellRowId = msRowId + `, ${wellId}`;

    let wellRow = rowsLookup.get(wellRowId);
    if (!wellRow) {
      wellRow = {
        id: wellRowId,
        parentId: msRowId,
        rowType: SchemeComponentType.Well,
        name: wellName,
        originalId: id,
        wellId,
        pressureDecline,
        reservoirPressure,
        inputs: new Map()
      };
      msRow.subRows.push(wellRow);
      rowsLookup.set(wellRowId, wellRow);
    }

    for (const input of wellPressureInputs) {
      const { weekDateId, weekDate } = input;
      if (!datesLookup.has(weekDateId)) {
        datesLookup.set(weekDateId, {
          id: weekDateId,
          weekDate: new Date(weekDate)
        });
      }

      wellRow.inputs.set(weekDateId, input);
    }
  }

  const sortedAdjustWeekDays = sortBy(adjustWeekDates, 'weekDate');
  for (const [index, weekDate] of sortedAdjustWeekDays.entries()) {
    if (!datesLookup.has(weekDate.id)) {
      datesLookup.set(weekDate.id, weekDate);
    }

    for (const row of rowsLookup.values()) {
      if (row.rowType !== SchemeComponentType.Well) {
        continue;
      }

      if (!row.inputs.has(weekDate.id)) {
        const prevWeekDate = sortedAdjustWeekDays[index - 1];
        const prevInput = row.inputs.get(prevWeekDate?.id);
        const dayDifference = getDifferenceInDays(prevWeekDate?.weekDate, weekDate?.weekDate) ?? 7;
        row.inputs.set(weekDate.id, {
          weekDateId: weekDate.id,
          weekDate: weekDate.weekDate,
          pressureValue: calculateWellPressure(
            row.reservoirPressure,
            row.pressureDecline,
            dayDifference,
            prevInput?.pressureValue
          )
        });
      }
    }
  }
}

export function convertWellPressureData(wellPressures: IWellPressureDto[], adjustWeekDates?: IGetWeekDatesDto[]) {
  const datesLookup = new Map<number, IGetWeekDatesDto>();
  const rowsLookup = new Map<string, WellPressureTableRow>();
  const parentRowsLookup = new Map<string, WellPressureTableRow>();

  processWellPressureData(wellPressures, datesLookup, rowsLookup, parentRowsLookup, adjustWeekDates);

  const rows = sortRows(parentRowsLookup);

  const dates = sortDates(datesLookup);

  const datesByWeekNumber = groupDatesByWeekNumber(dates);
  const splitableWeekDateIds = getMondaysOnlyDates(dates);

  return {
    dates,
    datesLookup,
    datesByWeekNumber,
    splitableWeekDateIds,
    rows,
    rowsLookup
  };
}

export type WellPressureData = ReturnType<typeof convertWellPressureData>;
