import { forwardRef, Ref, useImperativeHandle, useState } from 'react';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import Stack from '@mui/material/Stack';
import { IGetWeekDatesDto } from 'api';
import { TimestepPicker } from 'components/common/TimestepPicker';
import { weekDatesApi } from 'store/weekDates/weekDatesApi';

export type ApplyDialogRef = {
  open(): void;
};

export type ApplyDialogProps = {
  onApply: (selectedIndex: number, weekDates: IGetWeekDatesDto[]) => void;
};

function ApplyToOfficialInputDialog({ onApply }: ApplyDialogProps, ref: Ref<ApplyDialogRef>) {
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const { data: weekDates } = weekDatesApi.useGetWeekDatesQuery(undefined);
  const [selectedWeekDateIndex, setSelectedWeekDateIndex] = useState<number>(0);

  const handleSelectedWeekChange = (date: Date) => {
    const idx = weekDates?.findIndex((x) => x.weekDate?.getTime() === date?.getTime());
    if (idx >= 0) setSelectedWeekDateIndex(idx);
  };

  useImperativeHandle(
    ref,
    () => ({
      open() {
        setIsDialogOpen(true);
      }
    }),
    []
  );

  const handleClose = () => {
    setIsDialogOpen(false);
  };

  const handleApply = () => {
    onApply(selectedWeekDateIndex, weekDates);
    setIsDialogOpen(false);
  };

  if (!weekDates?.length) return null;

  return (
    <Dialog open={isDialogOpen} onClose={handleClose}>
      <Box sx={{ paddingY: 2, paddingX: 3 }}>
        <DialogTitle
          sx={{
            fontSize: '1.25rem',
            fontWeight: 700,
            padding: 0,
            marginBottom: 2
          }}
        >
          Transfer data to Official Inputs
        </DialogTitle>
        <TimestepPicker
          disableMargin
          fullWidth
          minDate={new Date()}
          value={weekDates[selectedWeekDateIndex].weekDate}
          onChange={handleSelectedWeekChange}
        />
        <Stack direction="row" justifyContent="flex-end" spacing={1} marginTop={1}>
          <Button variant="contained" onClick={handleApply}>
            APPLY
          </Button>
          <Button variant="text" onClick={handleClose}>
            CANCEL
          </Button>
        </Stack>
      </Box>
    </Dialog>
  );
}

export default forwardRef(ApplyToOfficialInputDialog);
