import { LoadingContainer } from 'components/common/Loading/LoadingContainer';
import { FC, PropsWithChildren, useEffect } from 'react';
import { useAppDispatch, useAppSelector } from 'store';
import { getWellGor } from 'store/wellGor/actions/getWellGor';
import { wellGorLoadingSelector } from 'store/wellGor/wellGorSelectors';
import { getWellPI } from 'store/wellPI/actions/getWellPI';
import { wellPILoadingSelector } from 'store/wellPI/wellPISelectors';
import { getWellPressure } from 'store/wellPressure/actions/getWellPressure';
import { wellPressureLoadingSelector } from 'store/wellPressure/wellPressureSelectors';

export const WithSubsurfaceData: FC<PropsWithChildren<unknown>> = ({ children }) => {
  const dispatch = useAppDispatch();

  const wellPressureLoading = useAppSelector(wellPressureLoadingSelector);
  const wellPILoading = useAppSelector(wellPILoadingSelector);
  const wellGorLoading = useAppSelector(wellGorLoadingSelector);

  useEffect(() => {
    dispatch(getWellPressure({}));
    dispatch(getWellPI({}));
    dispatch(getWellGor({}));
  }, [dispatch]);

  return (
    <LoadingContainer loading={wellPressureLoading || wellGorLoading || wellPILoading}>{children}</LoadingContainer>
  );
};
