import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '..';

type DashboardOutlookState = {
  shownCaseId: number | null;
};

const initialState: DashboardOutlookState = {
  shownCaseId: null
};

const dashboardOutlook = createSlice({
  name: 'dashboardOutlook',
  initialState,
  reducers: {
    setShownCaseId(state, action: PayloadAction<number>) {
      state.shownCaseId = action.payload;
    }
  }
});

export const dashboardOutlookSelector = (state: RootState) => state.dashboardOutlook;
export const shownCaseIdSelector = (state: RootState) => dashboardOutlookSelector(state).shownCaseId;

const dashboardOutlookReducer = dashboardOutlook.reducer;
export const dashboardOutlookActions = dashboardOutlook.actions;
export default dashboardOutlookReducer;
