import { createSelector } from '@reduxjs/toolkit';
import { selectOfficialInputHiddenWeekDateIds } from 'store/official-input-page/OfficialInputPageSelectors';
import { RootState } from '..';
import { WellPressureState } from './WellPressureState';
import { getWellPressureSnapshot } from './utils/getWellPressureSnapshot';

export const wellPressurePresentStateSelector = (state: RootState) => state.wellPressure.present;
export const previousWellPressureStateSelector = (state: RootState) => state.wellPressure.past;
export const wellPressureDataSelector = (state: RootState) => state.wellPressure.present.data;
export const wellPressureLoadingSelector = (state: RootState) => state.wellPressure.present.loading;
export const wellPressureErrorSelector = (state: RootState) => state.wellPressure.present.error;

export const wellPressureSnapshotSelector = createSelector(
  [wellPressurePresentStateSelector, (_state, searchQuery?: string) => searchQuery],
  (wellPressure, searchQuery) => getWellPressureSnapshot(wellPressure, searchQuery)
);

const selectWellPressureDates = (state) => wellPressurePresentStateSelector(state).dates;
const selectWellPressureSplitableWeekDates = (state) => wellPressurePresentStateSelector(state).splitableWeekDateIds;
export const filterWellPressureWeekDays = createSelector(
  [selectWellPressureDates, selectWellPressureSplitableWeekDates, selectOfficialInputHiddenWeekDateIds],
  (dates, splitableWeekDateIds, hiddenWeekDateIds) => {
    return dates?.filter((x) => splitableWeekDateIds.has(x.id) || !hiddenWeekDateIds.has(x.id));
  }
);

export function makeWellPressureStatus(wellPressure: WellPressureState, deactivatedTimesteps?: Map<number, boolean>) {
  const hasPressureDeclineChanges = wellPressure.pressureDeclineChanges?.size > 0;
  const hasReservoirPressureChanges = wellPressure.reservoirPressureChanges?.size > 0;
  const hasInputChanges = wellPressure.inputChanges?.size > 0;

  const hasChanges =
    hasPressureDeclineChanges || hasReservoirPressureChanges || hasInputChanges || deactivatedTimesteps?.size;

  return {
    hasPressureDeclineChanges,
    hasReservoirPressureChanges,
    hasInputChanges,
    hasChanges
  };
}

export const wellPressureStatusSelector = createSelector(wellPressurePresentStateSelector, makeWellPressureStatus);
