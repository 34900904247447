import { useMemo } from 'react';

export function useControlledState(state, { instance }) {
    return useMemo(() => {
        if (state.groupBy?.length) {
            return {
                ...state,
                hiddenColumns: [...state.hiddenColumns, ...state.groupBy].filter((d, i, all) => all.indexOf(d) === i),
            };
        }
        return state;
    }, [state]);
}
