import { LineupClient } from 'api';
import { createAppAsyncThunk } from 'store/createAppAsyncThunk';
import { lineupHistoryStateSelector } from '../lineupHistorySelector';

const service = new LineupClient();

export const getLineupHistory = createAppAsyncThunk('lineupHistory/get', (month: number) => service.getHistory(month), {
  condition: (arg, { getState }) => {
    const lineup = lineupHistoryStateSelector(getState());

    if (!lineup.loading && lineup.data === undefined) {
      return true;
    }

    if (arg !== lineup.arg) {
      return true;
    }

    return false;
  }
});
