import React from 'react';
import { Outlet } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import './App.css';

import { Box, CssBaseline, Grid, ThemeProvider } from '@mui/material';
import { SuspenseLoader } from 'components/common/SuspenseLoader';
import { ToastifyContainer } from 'components/common/toast/ToastifyContainer';
import { TearecsComponentsConfigurationProvider } from 'components/tearecs/TearecsConfigurationProvider';
import AppTheme from 'styles/theme';
import Header from './components/Header';
import AuthProvider from './components/Security/AuthProvider';
import Sidebar from './components/Sidebar';

const App = () => {
  const [open, setOpen] = React.useState(true);
  const toggleSidebar = () => {
    setOpen(!open);
  };

  return (
    <ThemeProvider theme={AppTheme}>
      <TearecsComponentsConfigurationProvider>
        <CssBaseline />
        <Header toggleSidebar={toggleSidebar} />
        <AuthProvider>
          <Grid container spacing={0} sx={{ flexGrow: 1 }}>
            <Grid item xs="auto">
              <Sidebar open={open} />
            </Grid>
            <Grid item xs>
              <Box
                component="main"
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  height: '100%',
                  pr: 3,
                  pb: 3,
                  pt: 11,
                  transition: (theme) => theme.transitions.create('padding-left'),
                  pl: 3,
                  ...(open && {
                    transition: (theme) => theme.transitions.create('padding-left'),
                    pl: 35
                  })
                }}
              >
                <ToastifyContainer />
                <SuspenseLoader>
                  <Outlet />
                </SuspenseLoader>
              </Box>
            </Grid>
          </Grid>
        </AuthProvider>
      </TearecsComponentsConfigurationProvider>
    </ThemeProvider>
  );
};

export default App;
