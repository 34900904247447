import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IWedgeGraphDto, IWedgeLineDto, IWedgeLineResultDto, LineFillType } from 'api/clients';
import randomColor from 'randomcolor';
import { ResultByWeekDay } from '../dashboard-wedge/types/DashboardWedgeState';
import { WedgeInputCellPayload } from '../dashboard-wedge/types/WedgeInputCellPayload';
import { WedgePayloadTimestep } from '../dashboard-wedge/types/WedgeRow';
import { DashboardWedgeLineState, SelectBPPayload, UpdateWedgeLinePayload } from './types/DashboardWedgeLineState';
import { convertWedgeLineData } from './utils/convertWedgeLineData';

const initialState: DashboardWedgeLineState = {
  wedgeLine: {
    id: 0,
    isDefault: true,
    name: '',
    color: randomColor({ count: 1, luminosity: 'light', format: 'rgba', alpha: 1 })[0],
    fillType: LineFillType.SOLID,
  },
  loading: false,
  tableWeekDateResults: new Map(),
};

const { reducer, actions } = createSlice({
  initialState,
  name: 'dashboard-wedge-line',
  reducers: {
    updateRow(state, { payload }: PayloadAction<WedgePayloadTimestep[]>) {
      const { tableWeekDateResults } = state;

      const weekDateIds = new Set<number>();

      for (const { value, weekIds } of payload) {
        for (const i of weekIds) {
          weekDateIds.add(i);
          tableWeekDateResults.set(i, {
            weekDateId: i,
            weekDate: tableWeekDateResults.get(i).weekDate,
            result: Math.round(value),
          });
        }
      }

      // resets those timestep are deleted in Datepicker
      tableWeekDateResults.forEach((_, key) => {
        if (!weekDateIds.has(key)) {
          tableWeekDateResults.set(key, {
            weekDateId: key,
            weekDate: tableWeekDateResults.get(key).weekDate,
            result: 0,
          });
        }
      });
    },
    init(state, { payload }: PayloadAction<IWedgeLineDto>) {
      if (payload) {
        state.wedgeLine = payload;
      }
    },
    initTableWeekDates(state, { payload }: PayloadAction<IWedgeGraphDto>) {
      const tableWeekDateResults = new Map<number, ResultByWeekDay>();
      if (tableWeekDateResults.size === 0) {
        payload?.weekDates?.forEach(({ weekDate, weekDateId }) => {
          tableWeekDateResults.set(weekDateId, {
            weekDate,
            weekDateId,
            result: 0,
          });
        });
        state.tableWeekDateResults = tableWeekDateResults;
      }
    },
    load(state) {
      state.loading = true;
    },
    selectBP(state, { payload }: PayloadAction<SelectBPPayload>) {
      const { dashboardBP, name } = payload;
      const selectedBP = dashboardBP.find((bpItem) => bpItem.groupName === name);
      if (selectedBP) {
        const tableWeekDateResults = new Map<number, ResultByWeekDay>();
        selectedBP.resultList.forEach(({ resultValue, weekDate, weekDateId }) => {
          tableWeekDateResults.set(weekDateId, {
            weekDate,
            weekDateId,
            result: Math.round(resultValue || 0),
          });
        });
        state.tableWeekDateResults = tableWeekDateResults;
      }

      state.wedgeLine = {
        ...state.wedgeLine,
        name,
      };
    },
    updateWedgeLine(
      state,
      { payload }: PayloadAction<UpdateWedgeLinePayload>
    ) {
      state.wedgeLine = {
        ...state.wedgeLine,
        [payload.key]: payload.value,
      };
    },
    updateTable(state, action: PayloadAction<IWedgeLineResultDto[]>) {
      state.tableWeekDateResults = convertWedgeLineData(action.payload);
    },
    updateCell(state, action: PayloadAction<WedgeInputCellPayload>) {
      const { tableWeekDateResults } = state;
      const { weekDateId, value } = action.payload;

      tableWeekDateResults.set(weekDateId, {
        weekDateId,
        weekDate: tableWeekDateResults.get(weekDateId).weekDate,
        result: Math.round(value),
      });
    },
    clear() {
      return initialState;
    }
  },
});

export const wedgeLineActions = actions;
export default reducer;
