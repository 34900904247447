import { HeaderClient, HeaderDto } from 'api';
import { sortBy } from 'lodash';
import { rootApi } from 'store/rootApi';

const service = new HeaderClient();

export const headersApi = rootApi.enhanceEndpoints({ addTagTypes: ['header'] }).injectEndpoints({
  endpoints: (builder) => ({
    getAllHeaders: builder.query({
      queryFn: (_: void) =>
        service
          .getAll()
          .then((data) => ({ data: sortBy(data, 'name') }))
          .catch((error) => ({ error })),
      providesTags: (result) =>
        result
          ? [...result.map(({ id }) => ({ type: 'header' as const, id })), { type: 'header', id: 'LIST' }]
          : [{ type: 'header', id: 'LIST' }]
    }),
    saveHeader: builder.mutation({
      queryFn: (dtos: HeaderDto[]) =>
        service
          .saveHeader(dtos)
          .then((data) => ({ data }))
          .catch((error) => ({ error })),
      invalidatesTags: () => [{ type: 'header', id: 'LIST' }]
    }),
    updateHeader: builder.mutation({
      queryFn: (dto: HeaderDto) =>
        service
          .updateHeader(dto)
          .then((data) => ({ data }))
          .catch((error) => ({ error })),
      invalidatesTags: () => [{ type: 'header', id: 'LIST' }]
    })
  })
});
