import { WellInputClient } from 'api';
import { ForceableQuery } from 'domain/QueryParams';
import { createAppAsyncThunk } from 'store/createAppAsyncThunk';
import { wellInputStateSelector } from '../wellInputSelectors';

const service = new WellInputClient();

type WellInputRequest = ForceableQuery & {
  hide?: boolean;
  searchText?: string;
};

export const getWellInput = createAppAsyncThunk(
  'wellInput/get',
  ({ searchText, hide = false }: WellInputRequest) => service.get(hide, searchText),
  {
    condition: (arg, { getState }) => {
      if (arg.force) {
        return true;
      }

      const wellInput = wellInputStateSelector(getState());

      if (!wellInput.loading && wellInput.data === undefined) {
        return true;
      }

      return false;
    }
  }
);
