import { P6Client, P6DetailsDto, P6Dto } from 'api';
import { createAppAsyncThunk } from 'store/createAppAsyncThunk';
import { getUtcDate } from 'utils/dateUtil';
import { p6Api } from '../p6Api';
import { p6StateSelector } from '../p6Selector';

const service = new P6Client();

export const saveP6Data = createAppAsyncThunk('p6/save', async (_: void, { dispatch, getState, rejectWithValue }) => {
  const state = getState();
  const p6 = p6StateSelector(state);

  const changes = Array.from(p6.edits.values()).map(({ p6Detail, ...p6 }) => {
    const startDateTS = p6Detail.startDateTS !== undefined ? getUtcDate(p6Detail.startDateTS) : undefined;
    const finishDateTS = p6Detail.finishDateTS !== undefined ? getUtcDate(p6Detail.finishDateTS) : undefined;
    const newP6Detail =
      startDateTS !== undefined && finishDateTS !== undefined
        ? new P6DetailsDto({ ...p6Detail, startDateTS, finishDateTS })
        : new P6DetailsDto({ ...p6Detail });
    return new P6Dto({ ...p6, p6Detail: newP6Detail });
  });

  try {
    await service.post(changes);
    dispatch(p6Api.util.invalidateTags(['p6ActivitiesByCases']));
  } catch (error) {
    return rejectWithValue(error);
  }
});
