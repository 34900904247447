import React, { ComponentType, FC, useCallback, useRef, useState } from 'react';
import {
    ColorPickerProps as ReactColorProps,
    ColorResult,
    CompactPicker,
    SketchPicker,
    SwatchesPicker,
    TwitterPicker
} from 'react-color';
import { Button } from '../Button/Button';
import { ClickAwayListener } from '../ClickAwayListener';
import { Popover } from '../Popover/Popover';

const cellStyle = {
    height: '20px',
    width: '20px',
    border: '1px solid black',
    borderRadius: '0px'
};

type ColorPickerType = 'sketch' | 'twitter' | 'compact' | 'swatches';

const colorPickers: Record<ColorPickerType, ComponentType<ReactColorProps<any>>> = {
    sketch: SketchPicker,
    twitter: TwitterPicker,
    compact: CompactPicker,
    swatches: SwatchesPicker
};

export interface ColorPickerProps extends Omit<ReactColorProps<never>, 'color' | 'onChange'> {
    picker?: ColorPickerType;
    value?: string;
    onChange: (hex: string, color: ColorResult) => any;
}

export const defaultColor = '#008000';

export const ColorIcon: FC<any> = ({ value }) => {
    return <div style={{ ...cellStyle, backgroundColor: value }}></div>;
};

/**
 * @category Component
 * @group Color Picker
 */
export const ColorPicker: FC<ColorPickerProps> = ({ picker = 'sketch', value, onChange, ...other }) => {
    const anchor = useRef();

    const [showPicker, setShowPicker] = useState(false);

    const Component = colorPickers[picker];

    const handleClose = useCallback(() => setShowPicker(false), []);

    const handleChange = useCallback(
        (color: ColorResult) => {
            onChange(color.hex, color);
        },
        [onChange]
    );

    return (
        <>
            <Button
                ref={anchor}
                style={{ ...cellStyle, backgroundColor: value }}
                onClick={() => setShowPicker(!showPicker)}
            />
            <Popover
                open={showPicker}
                anchorEl={anchor}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right'
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left'
                }}
            >
                <ClickAwayListener onClickAway={handleClose}>
                    <div>
                        <Component {...other} color={value || defaultColor} onChange={handleChange} />
                    </div>
                </ClickAwayListener>
            </Popover>
        </>
    );
};
