import DashboardIcon from '@mui/icons-material/Dashboard';
import FolderIcon from '@mui/icons-material/Folder';
import HomeIcon from '@mui/icons-material/Home';
import SettingsIcon from '@mui/icons-material/Settings';
import SettingsInputComponentIcon from '@mui/icons-material/SettingsInputComponent';
import TableChartIcon from '@mui/icons-material/TableChart';

export default [
  {
    id: 0,
    icon: <HomeIcon />,
    label: 'Start Page',
    route: '/'
  },
  {
    id: 1,
    icon: <TableChartIcon />,
    label: 'Official Inputs',
    route: '/official-inputs'
  },
  {
    id: 2,
    icon: <FolderIcon />,
    label: 'Scenarios',
    route: '/scenarios'
  },
  {
    id: 3,
    icon: <SettingsInputComponentIcon />,
    label: 'Simulation',
    route: '/simulation'
  },
  {
    id: 4,
    icon: <DashboardIcon />,
    label: 'Dashboard',
    route: '/dashboard'
  },
  {
    id: 5,
    icon: <SettingsIcon />,
    label: 'Settings',
    route: '/settings'
  }
];
