import { Box } from '@mui/system';
import { Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';

export const ProductivityIndexChart = ({ data, forecastLabelValue, labelValue }: any) => {
  return (
    <Box>
      <Box bgcolor="white" sx={{ mb: '14px', mt: '14px' }} width="100%" height={670}>
        <ResponsiveContainer>
          <LineChart width={1300} height={470} data={data}>
            <Line dataKey="pressure" stroke="#0066B2" strokeWidth={2} dot={false} />
            <Line dataKey="GOR" stroke="#9FD6EB" strokeDasharray="3 4 5 2" dot={false} />
            <XAxis xAxisId="0" dataKey="monthOne" tickCount={10} tick={{ fill: '#24272A' }} />
            <XAxis xAxisId="1" dataKey="monthTwo" allowDuplicatedCategory={false} tickLine={false} axisLine={false} />
            <YAxis tick={{ fill: '#24272A' }} />
            <Tooltip />
            {/* <Legend content={<CustomLegend forecast={forecastLabelValue} current={labelValue} />} /> */}
          </LineChart>
        </ResponsiveContainer>
      </Box>
    </Box>
  );
};

export const GorChart = ({ data, forecastLabelValue, labelValue }: any) => {
  return (
    <Box bgcolor="white" sx={{ mb: '14px', mt: '14px' }} width="100%" height={670}>
      <ResponsiveContainer>
        <LineChart width={1000} height={470} data={data}>
          <Line dataKey="pressure" stroke="#0066B2" strokeWidth={2} dot={false} />
          <Line dataKey="GOR" stroke="#9FD6EB" strokeDasharray="3 4 5 2" dot={false} />
          <XAxis xAxisId="0" dataKey="monthOne" tickCount={6} tick={{ fill: '#24272A' }} />
          <XAxis xAxisId="1" dataKey="monthTwo" allowDuplicatedCategory={false} tickLine={false} axisLine={false} />
          <YAxis tick={{ fill: '#24272A' }} />
          <Tooltip />
          {/* <Legend content={<CustomLegend forecast={forecastLabelValue} current={labelValue} />} /> */}
        </LineChart>
      </ResponsiveContainer>
    </Box>
  );
};

export const PressureChart = ({ data, forecastLabelValue, labelValue }: any) => {
  return (
    <Box bgcolor="white" sx={{ mb: '14px', mt: '14px' }} width="100%" height={670}>
      <ResponsiveContainer>
        <LineChart width={1000} height={470} data={data}>
          <Line dataKey="pressure" stroke="#0066B2" strokeWidth={2} dot={false} />
          <Line dataKey="GOR" stroke="#9FD6EB" strokeDasharray="3 4 5 2" dot={false} />
          <XAxis xAxisId="0" dataKey="monthOne" tickCount={6} tick={{ fill: '#24272A' }} />
          <XAxis xAxisId="1" dataKey="monthTwo" allowDuplicatedCategory={false} tickLine={false} axisLine={false} />
          <YAxis tick={{ fill: '#24272A' }} />
          <Tooltip />
          {/* <Legend content={<CustomLegend forecast={forecastLabelValue} current={labelValue} />} /> */}
        </LineChart>
      </ResponsiveContainer>
    </Box>
  );
};
