import { LineupClient, WellInputClient } from 'api';
import { createAppAsyncThunk } from 'store/createAppAsyncThunk';
import { getLineupSaveState, getWellInputSaveState } from 'store/official-inputs/actions/saveOfficialInputChanges';
import { selectCasesPresentState, selectHasCaseInputChanges } from '../CasesSelector';

const lineupClient = new LineupClient();
const wellInputClient = new WellInputClient();

export const saveCasesChanges = createAppAsyncThunk('cases/save', (_: void, { getState }) => {
  const casesState = selectCasesPresentState(getState());
  const hasCaseInputChanges = selectHasCaseInputChanges(getState());
  const promises = [];

  if (hasCaseInputChanges) {
    const lineData = getLineupSaveState(casesState, casesState.lineup.arg?.scenarioCaseId);
    const wellInputData = getWellInputSaveState(casesState, casesState.wellInput.arg?.scenarioCaseId);

    promises.push(
      lineupClient.saveLineupInputScenarioStates(lineData),
      wellInputClient.saveWellInputScenarioStates(wellInputData)
    );
  }

  return Promise.allSettled(promises);
});
