import React, { FC, useCallback, useContext, useEffect, useMemo } from 'react';
import { Typography } from '../../Typography';
import styles from '../Chart.module.css';

import { ChartContext } from '../ChartContext';
import { ChartInstance, SeriesMouseEventArgs, SeriesPoint } from '../chartTypes';
import { ChartSeriesPreview, ChartSeriesPreviewProvider } from './ChartSeriesPreview';

export type PreviewTemplate = (series: SeriesPoint) => any;
export type LabelTemplate = (series: SeriesPoint) => any;
export type ValueTemplate = (series: SeriesPoint, format: any) => any;
export type SeriesTemplate = (series: SeriesPoint) => any;
export type TableTemplate = (
    series: SeriesPoint[],
    renderPreview: SeriesTemplate,
    renderLabel: SeriesTemplate,
    renderValue: SeriesTemplate,
    yScales: ChartInstance['yScales']
) => any;

/**
 * The props for the {@link ChartTooltip} component.
 */
export interface ChartTooltipProps extends Partial<SeriesMouseEventArgs> {
    titleTemplate?: (value: any, format: any, series: Partial<SeriesPoint>, renderPreview: SeriesTemplate) => any;
    tableTemplate?: TableTemplate;
    previewTemplate?: PreviewTemplate;
    labelTemplate?: LabelTemplate;
    valueTemplate?: ValueTemplate;
}

const defaultFormatter = (value: any) => value;

/**
 * @category Component
 * @group Chart
 */
const ChartTooltip: FC<ChartTooltipProps> = (props) => {
    const {
        name,
        data = [],
        component = undefined,
        options = undefined,
        axis = undefined,
        getX = undefined,
        getY = undefined,
        getOpen = undefined,
        getClose = undefined,
        point = undefined,
        series = undefined,
        titleTemplate = undefined,
        tableTemplate = undefined,
        previewTemplate = undefined,
        labelTemplate = undefined,
        valueTemplate = undefined
    } = props;
    const { legend, xScales, yScales } = useContext(ChartContext);

    const value = getX?.(point);
    const xScale = xScales[axis] ?? xScales.undefined;
    const formatX = xScale?.tickFormat?.() ?? defaultFormatter;

    const renderPreview: SeriesTemplate = useCallback(
        (series: SeriesPoint) => {
            if (previewTemplate) return previewTemplate(series);

            return series?.component ? <ChartSeriesPreview>{series.component}</ChartSeriesPreview> : null;
        },
        [previewTemplate]
    );

    const renderTitle = useCallback(
        (value, series: Partial<SeriesPoint>) =>
            titleTemplate ? titleTemplate(value, formatX, series, renderPreview) : formatX(value),
        [formatX, renderPreview, titleTemplate]
    );

    const renderLabel: SeriesTemplate = useCallback(
        (series: SeriesPoint) => {
            if (labelTemplate) return labelTemplate(series);

            const { name } = series;
            return name;
        },
        [labelTemplate]
    );

    const renderValue: SeriesTemplate = useCallback(
        (series: SeriesPoint) => {
            const { axis, getY, getOpen, getClose, point } = series;

            const group = axis ?? 'undefined';
            const formatY = (yScales[group] ?? yScales.undefined)?.tickFormat();

            if (valueTemplate) return valueTemplate(series, formatY);

            const value = getY?.(point) ?? getOpen?.(point) ?? getClose?.(point);

            if (isNaN(value) || value == null) {
                return formatY?.(undefined as any);
            }

            return formatY?.(value);
        },
        [valueTemplate, yScales]
    );

    const children = useMemo(
        (): SeriesPoint[] =>
            series
                ? Object.values(series).filter((x: any) => !legend.state[x.key])
                : [
                      {
                          key: 'undefined',
                          name,
                          data,
                          component,
                          options,
                          axis,
                          getX,
                          getY,
                          getOpen,
                          getClose,
                          point
                      }
                  ].filter((series: SeriesPoint) => !series.options?.hideFromTooltip),
        [axis, component, data, getClose, getOpen, getX, getY, legend.state, name, options, point, series]
    );

    const renderTable = useCallback(
        (series: SeriesPoint[]) => {
            if (tableTemplate) return tableTemplate(series, renderPreview, renderLabel, renderValue, yScales);

            return (
                <table>
                    <tbody>
                        {series.map((series, index) => {
                            return (
                                <tr key={index}>
                                    <td>{renderPreview(series)}</td>
                                    <td>
                                        <Typography>{renderLabel(series)}</Typography>
                                    </td>
                                    <td>
                                        <Typography>{renderValue(series)}</Typography>
                                    </td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            );
        },
        [renderLabel, renderPreview, renderValue, tableTemplate, yScales]
    );

    useEffect(() => {
        document.body.classList.add(styles.overflowHidden);

        return () => document.body.classList.remove(styles.overflowHidden);
    }, []);

    if (children.length === 0) return null;

    return (
        <>
            <Typography>{renderTitle(value, props)}</Typography>
            <ChartSeriesPreviewProvider>{renderTable(children)}</ChartSeriesPreviewProvider>
        </>
    );
};

ChartTooltip.displayName = 'ChartTooltip';

/** @ignore */
export { ChartTooltip };
