import { PlantInputsClient, UpliftFactorClient, UpliftFactorDto } from 'api';
import { isNil, sortBy } from 'lodash';
import { createAppAsyncThunk } from 'store/createAppAsyncThunk';

import { weekDatesApi } from 'store/weekDates/weekDatesApi';
import {
  gasConstraintLoadingSelector,
  gasConstraintValuesSelector,
  inletPressureLoadingSelector,
  inletPressureValuesSelector,
  upliftFactorsLoadingSelector,
  upliftFactorsSelector
} from '../plantInputsSelector';
import { GetPlantInputsThunk } from '../types/helperTypes';

const plantInputsClient = new PlantInputsClient();
const upliftFactorsClient = new UpliftFactorClient();

type MayBeNumber = number | undefined;
type Params = {
  caseId: MayBeNumber;
  force?: boolean;
};

export const getInletPressureValues = createAppAsyncThunk(
  'plantInputs/getInletPressureValues',
  async ({ caseId }: Params, { dispatch }): Promise<GetPlantInputsThunk> => {
    const [weekDates, items] = await Promise.all([
      dispatch(weekDatesApi.endpoints.getWeekDates.initiate(undefined)).unwrap(),
      caseId ? plantInputsClient.getScenario(caseId) : plantInputsClient.get()
    ]);

    sortBy(weekDates, (week) => week.id);

    return { weekDates, items };
  },
  {
    condition: ({ caseId, force }: Params, { getState }) => {
      const state = getState();
      const loading = inletPressureLoadingSelector(caseId)(state);
      const data = inletPressureValuesSelector(state, caseId);

      return !loading && (force || isNil(data));
    }
  }
);

// TODO: need to rewrite to gas constraint client
export const getGasConstraintValues = createAppAsyncThunk(
  'plantInputs/getGasConstraintValues',
  async ({ caseId }: Params, { dispatch }): Promise<GetPlantInputsThunk> => {
    const [weekDates, items] = await Promise.all([
      dispatch(weekDatesApi.endpoints.getWeekDates.initiate(undefined)).unwrap(),
      caseId ? plantInputsClient.getScenario(caseId) : plantInputsClient.get()
    ]);

    sortBy(weekDates, (week) => week.id);

    return { weekDates, items };
  },
  {
    condition: ({ caseId, force }: Params, { getState }) => {
      const state = getState();
      const loading = gasConstraintLoadingSelector(caseId)(state);
      const data = gasConstraintValuesSelector(state, caseId);

      return !loading && (force || isNil(data));
    }
  }
);

export const getUpliftFactorValues = createAppAsyncThunk(
  'plantInputs/getUpliftFactorValues',
  async ({ caseId }: Params): Promise<UpliftFactorDto[]> => {
    return caseId ? upliftFactorsClient.getScenario(caseId) : upliftFactorsClient.get();
  },
  {
    condition: ({ caseId, force }: Params, { getState }) => {
      const state = getState();
      const loading = upliftFactorsLoadingSelector(caseId)(state);
      const data = upliftFactorsSelector(caseId)(state);

      return !loading && (force || isNil(data));
    }
  }
);
