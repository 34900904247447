import { AxisBottom, AxisTop } from '@visx/axis';
import React, { FC, memo, useContext } from 'react';
import { ChartContext } from '../ChartContext';
import { Axis } from '../chartTypes';

/**
 * Props for the {@link ChartXAxis} component.
 */
export interface ChartXAxisProps extends Axis {
    orientation?: 'bottom' | 'top';
}

/**
 * @category Component
 * @group Chart
 */
let ChartXAxis: FC<ChartXAxisProps> = ({
    id,
    tickLabelProps: passedTickLabelProps = null,
    axisStyles = {},
    hideZero = false,
    label = null,
    labelOffset = 14,
    labelProps = null,
    numTicks = null,
    orientation = 'bottom',
    rangePadding = null,
    scale = null,
    tickComponent = null,
    tickFormat = null,
    tickStyles = {},
    tickValues
}) => {
    const { innerHeight, groupDimensions } = useContext(ChartContext);

    if (!scale || !innerHeight) return null;

    const Axis = orientation === 'bottom' ? AxisBottom : AxisTop;

    const dimensions = groupDimensions[id];

    const tickStyleLabelProps =
        !passedTickLabelProps && tickStyles.label && tickStyles.label[orientation]
            ? () => tickStyles.label[orientation]
            : undefined;
    const tickLabelProps = passedTickLabelProps ?? tickStyleLabelProps;

    return (
        <Axis
            top={orientation === 'top' ? dimensions.offset.top : dimensions.size.height}
            left={dimensions.offset.left}
            orientation={orientation}
            rangePadding={rangePadding}
            hideTicks={numTicks === 0}
            hideZero={hideZero}
            label={label}
            labelOffset={labelOffset}
            labelProps={labelProps || (axisStyles.label || {})[orientation]}
            numTicks={numTicks}
            scale={scale}
            stroke={axisStyles.stroke}
            strokeWidth={axisStyles.strokeWidth}
            tickComponent={tickComponent}
            tickFormat={tickFormat}
            tickLabelProps={tickLabelProps}
            tickLength={tickStyles.tickLength}
            tickStroke={tickStyles.stroke}
            tickValues={tickValues}
        />
    );
};

ChartXAxis.displayName = 'ChartXAxis';
ChartXAxis = memo(ChartXAxis);

/** @ignore */
export { ChartXAxis };
