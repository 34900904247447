import cx from 'classnames';
import React, { ComponentProps, CSSProperties, ElementType, HTMLProps, ReactNode, Ref, useMemo } from 'react';
import { IMaskMixin } from 'react-imask';
import styles from './Input.module.css';

/**
 * The props for the {@link Input} component.
 * @category Props
 */
export interface InputProps<TComponent extends ElementType = 'input'>
    extends Omit<HTMLProps<HTMLInputElement>, 'label'> {
    label?: React.ReactNode;
    inputRef?: Ref<any>;
    inputComponent?: TComponent;
    fullWidth?: boolean;
    multiline?: boolean;
    inputProps?: Partial<ComponentProps<TComponent>>;
    startAdornment?: ReactNode;
    endAdornment?: ReactNode;
    variant?: 'underline' | 'blank';
}

/**
 * @category Component
 * @group Input
 */
export const Input = <TComponent extends ElementType = 'input'>({
    className,
    fullWidth = false,
    width,
    multiline = false,
    disabled,
    id: inputId,
    name,
    inputComponent,
    inputProps = {},
    inputRef,
    startAdornment,
    endAdornment,
    variant = 'underline',
    value,
    style,
    ...other
}: InputProps<TComponent>) => {
    const { className: inputClassName, ...otherInputProps } = inputProps;

    const id = inputId ?? name;

    const InputComponent = (inputComponent ? inputComponent : multiline ? 'textarea' : 'input') as any;

    const inputStyle = useMemo(() => {
        const result: CSSProperties = { ...style, ...otherInputProps.style };

        if (width == 'auto' && typeof value === 'string') {
            result.width = `${value.length}ch`;
        }

        return result;
    }, [otherInputProps?.style, style, value, width]);

    return (
        <div
            className={cx(
                styles[variant],
                {
                    [styles.fullWidth]: fullWidth,
                    [styles.disabled]: disabled
                },
                styles.container,
                className
            )}
        >
            {startAdornment}
            <InputComponent
                ref={inputRef}
                className={cx({ [styles.input_disabled]: disabled }, styles.input, inputClassName)}
                disabled={disabled}
                type="text"
                id={id}
                name={name}
                autoComplete="off"
                value={value}
                {...other}
                {...otherInputProps}
                style={inputStyle}
            />
            {endAdornment}
        </div>
    );
};

/**
 * @category Component
 * @group Input
 */
export const MaskedInput = IMaskMixin(({ inputRef, ref, ...props }) => <Input inputRef={inputRef} {...props} />);
