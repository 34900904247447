import { PiHistorianClient } from 'api';
import { rootApi } from 'store/rootApi';

const service = new PiHistorianClient();

export interface PiHistorianRequestParams {
  id: number;
  months: number;
}

export const piHistorianApi = rootApi
  .enhanceEndpoints({
    addTagTypes: ['wellPiHistorian', 'msPiHistorian']
  })
  .injectEndpoints({
    endpoints: (builder) => ({
      getWellPiHistorian: builder.query({
        queryFn: ({ id, months }: PiHistorianRequestParams) =>
          service
            .getPiHistorianWells(id, months)
            .then((data) => ({ data }))
            .catch((error) => ({ error })),
        providesTags: (_result, _error, { id }) => [{ type: 'wellPiHistorian', id }]
      }),
      getMsPiHistorian: builder.query({
        queryFn: ({ id, months }: PiHistorianRequestParams) =>
          service
            .getPiHistorianMeterStation(id, months)
            .then((data) => ({ data }))
            .catch((error) => ({ error })),
        providesTags: (_result, _error, { id }) => [{ type: 'msPiHistorian', id }]
      })
    })
  });
