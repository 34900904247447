import { useCallback } from 'react';
import ReactVirtualizedAutoSizer, { Size } from 'react-virtualized-auto-sizer';

import styles from '../utils/ChartContainer.module.css';

import { useTheme } from '@mui/material';
import { LoadingOverlay } from 'components/common/Loading/LoadingOverlay';
import { Chart, ChartLegend, ChartTooltip, ChartXAxis, ChartYAxis, LineSeries, SeriesPoint } from 'components/library';
import { getTitleTemplate } from '../components/shared/ChartTooltipTemplates';

import { HEIGHT_OFFSET } from '../utils/constants';
import { useMsPiHistorianData } from './hooks/useMsPiHistorianData';

const getValueTemplate = (series: SeriesPoint) => {
  return series.point.firstPipeOutletPressure;
};

export function MeterStationDetailsChart() {
  const theme = useTheme();
  const { data, isLoading } = useMsPiHistorianData();

  const renderChart = useCallback(
    ({ height, width }: Size) => (
      <Chart
        xAccessor="timestamp"
        yAccessor="firstPipeOutletPressure"
        xScale={{
          type: 'time'
        }}
        yScale={{
          type: 'linear',
          includeZero: true
        }}
        height={height - HEIGHT_OFFSET}
        width={width}
      >
        <ChartXAxis showGrid={true} />
        <ChartYAxis showGrid={true} />
        <ChartLegend />
        <LineSeries
          id="1"
          data={data}
          name="Pressure"
          interpolation="stepAfter"
          stroke={theme.chartLines.blue}
          strokeWidth={2}
        />
        <ChartTooltip titleTemplate={getTitleTemplate} valueTemplate={getValueTemplate} />
      </Chart>
    ),
    [data, theme.chartLines.blue]
  );

  return (
    <LoadingOverlay loading={isLoading} className={styles.container}>
      <ReactVirtualizedAutoSizer>{renderChart}</ReactVirtualizedAutoSizer>
    </LoadingOverlay>
  );
}
