import React, { ElementType, forwardRef, ReactElement, Ref } from 'react';
import { FormControl, FormControlProps } from './FormControl';
import { Input, InputProps } from './Input';
import styles from './TextField.module.css';

const defaultLabelProps = {};

/**
 * The props for the {@link TextField} component.
 * @category Props
 */
export interface TextFieldProps<TInputComponent extends ElementType = 'input'>
    extends Omit<FormControlProps, 'control'>,
        InputProps<TInputComponent> {}

/**
 * @category Component
 * @group Input
 */
export const TextField = forwardRef(function TextField<TInputComponent extends ElementType = 'input'>(
    {
        required,
        disabled,
        disableMargin = false,
        fullWidth = false,
        disableMinWidth = false,
        className,
        style,
        label,
        labelProps = defaultLabelProps,
        error,
        hintText,
        width,
        ...other
    }: TextFieldProps<TInputComponent>,
    ref: any
) {
    return (
        <FormControl
            required={required}
            ref={ref}
            disabled={disabled}
            disableMargin={disableMargin}
            fullWidth={fullWidth}
            className={className}
            label={label}
            labelProps={labelProps}
            control={<Input fullWidth={fullWidth} width={width} className={styles.input} {...other} />}
            error={error}
            hintText={hintText}
            style={style}
            disableMinWidth={disableMinWidth}
            width={width}
        />
    );
}) as <TInputComponent extends ElementType = 'input'>(
    props: TextFieldProps<TInputComponent> & { ref?: Ref<any> }
) => ReactElement;
