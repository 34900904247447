import { JointRateDto, MeterStationClient, MeterStationDto, UpdateMeterStationDto } from 'api';
import { sortBy } from 'lodash';
import { rootApi } from 'store/rootApi';

const service = new MeterStationClient();

const getJointRatesTag = 'getJointRates-tag';

export const meterStationsApi = rootApi
  .enhanceEndpoints({
    addTagTypes: ['meterStations', 'meterStation', getJointRatesTag]
  })
  .injectEndpoints({
    endpoints: (builder) => ({
      getAllMeterStations: builder.query<MeterStationDto[], void>({
        queryFn: () =>
          service
            .getAll()
            .then((data) => ({ data: sortBy(data, 'name') }))
            .catch((error) => ({ error })),
        providesTags: (result) =>
          result
            ? [
                ...result.map(({ id }) => ({ type: 'meterStations' as const, id })),
                { type: 'meterStations', id: 'LIST' }
              ]
            : [{ type: 'meterStations', id: 'LIST' }]
      }),
      getMeterStation: builder.query({
        queryFn: (id: number) =>
          service
            .get(id)
            .then((data) => ({ data }))
            .catch((error) => ({ error })),
        providesTags: (_result, _error, id) => [{ type: 'meterStation', id }]
      }),
      saveMeterStation: builder.mutation({
        queryFn: (msNames: string[]) =>
          service
            .saveMeterStation(msNames)
            .then((data) => ({ data }))
            .catch((error) => ({ error })),
        invalidatesTags: () => [{ type: 'meterStations', id: 'LIST' }]
      }),
      updateMeterStation: builder.mutation({
        queryFn: (body: UpdateMeterStationDto) =>
          service
            .updateMeterStation(body)
            .then((data) => ({ data }))
            .catch((error) => ({ error }))
      }),
      getJointRates: builder.query<JointRateDto[], void>({
        queryFn: () =>
          service
            .getJointRates()
            .then((data) => ({ data }))
            .catch((error) => ({ error })),
        providesTags: () => [{ type: getJointRatesTag, id: 'LIST' }]
      })
    })
  });
