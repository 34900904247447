import { WellInputClient } from 'api';
import { createAppAsyncThunk } from 'store/createAppAsyncThunk';
import { wellInputHistoryStateSelector } from '../wellInputHistorySelector';

const service = new WellInputClient();

export const getWellInputHistory = createAppAsyncThunk(
  'wellInputHistory/get',
  (month: number) => service.getHistory(month),
  {
    condition: (arg, { getState }) => {
      const wellInputHistory = wellInputHistoryStateSelector(getState());

      if (!wellInputHistory.loading && wellInputHistory.data === undefined) {
        return true;
      }

      if (arg !== wellInputHistory.arg) {
        return true;
      }

      return false;
    }
  }
);
