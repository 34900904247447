import { useRef, useMemo } from 'react';

/**
 * @category Util
 */
export function useSyncEffect(effect: React.EffectCallback, deps?: React.DependencyList) {
    const cleanup = useRef<ReturnType<React.EffectCallback>>();

    const result = useMemo(() => {
        if (typeof cleanup.current === 'function') {
            cleanup.current();
        }

        return effect();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, deps);

    cleanup.current = result;
}
