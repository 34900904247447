import React, { FC, memo, PropsWithChildren, useContext, useMemo } from 'react';
import { TableRow } from '../../Table/TableRow';
import { DataGridContext } from '../DataGridContext';
import { DataGridColumnInstance } from '../types';
import { defaultDataGridHeaderCellWrapper, RenderDataGridHeaderCell } from './RenderHeaderCell';

export interface RenderDataGridHeaderProps {
    blockLayout?: boolean;
}

export const RenderDataGridHeader: FC<PropsWithChildren<RenderDataGridHeaderProps>> = memo(
    ({ blockLayout: blockLayoutProp }) => {
        const { settings, headerGroups } = useContext(DataGridContext);

        const { blockLayout, HeaderCellWrapper = defaultDataGridHeaderCellWrapper, RowComponent = TableRow } = settings;
        const isBlockLayout = blockLayoutProp || blockLayout;

        const Cell = useMemo(() => HeaderCellWrapper(RenderDataGridHeaderCell), [HeaderCellWrapper]);

        return (
            <>
                {headerGroups.map((headerGroup, index) => {
                    const rowProps = headerGroup.getHeaderGroupProps();
                    const totalWidth = headerGroup.headers.reduce((acc, column) => acc + column.totalWidth, 0);

                    return (
                        <RowComponent key={index} blockLayout={isBlockLayout} {...rowProps}>
                            {headerGroup.headers.map((column: DataGridColumnInstance, index) => {
                                return (
                                    <Cell
                                        key={index}
                                        index={index}
                                        blockLayout={isBlockLayout}
                                        column={column}
                                        totalWidth={totalWidth}
                                    />
                                );
                            })}
                        </RowComponent>
                    );
                })}
            </>
        );
    }
);
