import { useMemo } from 'react';
import { DataGridColumnOptions } from '../types';

export function useDataGridColumns<TItem extends object = any>(dirtyColumns: DataGridColumnOptions<TItem>[]) {
    return useMemo(() => {
        function prepareColumn(
            column: DataGridColumnOptions<TItem>,
            parent?: DataGridColumnOptions<TItem>
        ): DataGridColumnOptions<TItem> {
            const result: DataGridColumnOptions<TItem> = { ...column };
            if (!result.accessor) {
                result.accessor = result.id as any;
            }

            if (parent?.sticky) {
                result.sticky = parent.sticky;
            }

            if (parent && result.sticky) {
                parent.sticky = result.sticky;
            }

            if (result.columns) {
                result.columns = result.columns.map((column) => prepareColumn(column, result));
            }

            return result;
        }

        return dirtyColumns.map((column) => prepareColumn(column));
    }, [dirtyColumns]);
}
