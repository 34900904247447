import {
  ComponentPressureType,
  IGetWeekDatesDto,
  IMeterStationInputStatus,
  IWellChildDto,
  IWellInputDto,
  SchemeComponentType
} from 'api';
import { ExtendedWithFlagRow } from 'pages/official-inputs/types/officialInputsTypes';
import { getMondaysOnlyDates, groupDatesByWeekNumber } from 'store/official-inputs/lineup/utils/groupDatesByWeekNumber';
import { getNodeId } from 'store/official-inputs/utils/getNodeId';

export type WellInputTableRowType = SchemeComponentType;

export interface WellInputTableRow {
  id: string;
  parentId?: string;
  rowType: WellInputTableRowType;
  pressureType?: ComponentPressureType;
  name: string;
  wellId?: number;
  msStatuses?: Map<number, ExtendedWithFlagRow<IMeterStationInputStatus>>;
  wellStatuses?: Map<number, ExtendedWithFlagRow<IWellChildDto>>;
  subRows?: WellInputTableRow[];
  groupedDate: Date;
}

function processWellInput(
  wellInput: IWellInputDto[],
  datesById: Map<number, IGetWeekDatesDto>,
  rowsById: Map<string, WellInputTableRow>,
  parentRowsById: Map<string, WellInputTableRow>,
  rowIdByNodeId: Map<string, string>
) {
  for (const { groupedDateId, groupedDate, wellParentList } of wellInput) {
    if (!datesById.has(groupedDateId)) {
      datesById.set(groupedDateId, {
        id: groupedDateId,
        weekDate: new Date(groupedDate)
      });
    }

    for (const { meterStationName, meterStationInputStatus, wellChildList } of wellParentList) {
      const parentRowId = getNodeId(meterStationInputStatus.id, SchemeComponentType.MeterStation);
      let parentRow = rowsById.get(parentRowId);
      if (!parentRow) {
        parentRow = {
          id: parentRowId,
          rowType: SchemeComponentType.MeterStation,
          name: meterStationName,
          msStatuses: new Map<number, IMeterStationInputStatus>(),
          subRows: [],
          groupedDate
        };
        rowsById.set(parentRowId, parentRow);
        parentRowsById.set(parentRowId, parentRow);
      }

      parentRow.msStatuses.set(groupedDateId, meterStationInputStatus);

      for (const [index, wellStatus] of wellChildList.entries()) {
        const { wellName, wellId, wellPressureType } = wellStatus;
        const wellRowId = parentRowId + `, ${index}`;

        let wellRow = rowsById.get(wellRowId);
        if (!wellRow) {
          wellRow = {
            id: wellRowId,
            parentId: parentRowId,
            name: wellName,
            pressureType: wellPressureType,
            wellId,
            rowType: SchemeComponentType.Well,
            wellStatuses: new Map<number, IWellChildDto>(),
            groupedDate
          };
          parentRow.subRows.push(wellRow);
          rowsById.set(wellRowId, wellRow);
          rowIdByNodeId.set(getNodeId(wellId, SchemeComponentType.Well), wellRowId);
        }

        wellRow.wellStatuses.set(groupedDateId, wellStatus);
      }
    }
  }
}

function sortRows(parentRowsById: Map<string, WellInputTableRow>) {
  const rows = Array.from(parentRowsById.values());
  rows.sort((a, b) => a.name.localeCompare(b.name));
  for (const msRow of rows) {
    msRow.subRows.sort((a, b) => a.name?.localeCompare(b.name));
  }

  return rows;
}

function sortDates(datesById: Map<number, IGetWeekDatesDto>) {
  const dates = Array.from(datesById.values());
  dates.sort((a, b) => a.weekDate.getTime() - b.weekDate.getTime());
  return dates;
}

export function convertWellInputData(wellInput: IWellInputDto[]) {
  const datesById = new Map<number, IGetWeekDatesDto>();
  const rowsById = new Map<string, WellInputTableRow>();
  const parentRowsById = new Map<string, WellInputTableRow>();
  const rowIdByNodeId = new Map<string, string>();

  processWellInput(wellInput, datesById, rowsById, parentRowsById, rowIdByNodeId);

  const rows = sortRows(parentRowsById);

  const dates = sortDates(datesById);

  const datesByWeekNumber = groupDatesByWeekNumber(dates);
  const splitableWeekDateIds = getMondaysOnlyDates(dates);

  return {
    dates,
    datesById,
    datesByWeekNumber,
    splitableWeekDateIds,
    rows,
    rowsById,
    rowIdByNodeId
  };
}

export type WellInputData = ReturnType<typeof convertWellInputData>;
