import { WedgeWellLinkDto } from 'api/clients';
import { hackWedgeLineId } from 'pages/dashboard/outlook/utils/hackWedgeLineId';
import { TreeItem } from '../types/WedgeInputCellPayload';

export const convertSelectedToDTOs = (msAndWellList: TreeItem[], selectedWell: Set<number>) => {
  const selectedWellDTOs: WedgeWellLinkDto[] = [];
  const selectedMSDTOs: WedgeWellLinkDto[] = [];
  Array.from(selectedWell.values())
    .sort((a, b) => b - a)
    .forEach((selectedId) => {
      const wedgeMeterStationId: number = msAndWellList.find((ms) => ms.children?.some((well) => well.id === selectedId))?.id;
      if (selectedId > 0) {
        if (wedgeMeterStationId) {
          selectedWellDTOs.push(WedgeWellLinkDto.fromJS({
            wedgeWellId: selectedId,
            wedgeMeterStationId: Math.abs(wedgeMeterStationId)
          }));
        }
      }
      const containsInSelectedWellDTOS = selectedWellDTOs.some((well) => well.wedgeMeterStationId === Math.abs(selectedId) );
      if (!containsInSelectedWellDTOS && selectedId < 0) {
        selectedMSDTOs.push(WedgeWellLinkDto.fromJS({ wedgeMeterStationId: hackWedgeLineId(selectedId) }));
      }
    });
  return {
    selectedWellDTOs,
    selectedMSDTOs,
    selectedMSIds: selectedMSDTOs.map((ms) => ms.wedgeMeterStationId)
  };
};
