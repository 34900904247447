import moment from 'moment';

import { UniversalTimelineInstance, TimelineState } from '../TimelineTypes';

import { actions } from '../utils/actions';
import { useMemo, useCallback } from 'react';

actions.setStartDate = 'setStartDate';
actions.setDuration = 'setDuration';
actions.setEndDate = 'setEndDate';

export function dateRangeReducer(
    state: TimelineState<any>,
    action,
    previousState,
    instance: UniversalTimelineInstance
) {
    const { initialState } = instance;
    if (action.type === actions.init) {
        const { startDate = new Date(new Date().getFullYear(), 0, 1), duration = 60 } = initialState?.dateRange ?? {};

        return {
            ...state,
            dateRange: {
                startDate,
                duration,
                endDate: moment(startDate as Date)
                    .add(duration, 'months')
                    .toDate(),
            },
        };
    }

    if (action.type === actions.setStartDate) {
        const startDate = moment(action.payload).startOf('month').toDate();
        return {
            ...state,
            dateRange: {
                ...state.dateRange,
                startDate,
                endDate: moment(startDate).add(state.dateRange.duration, 'months').toDate(),
            },
        };
    }

    if (action.type === actions.setDuration) {
        return {
            ...state,
            dateRange: {
                ...state.dateRange,
                duration: action.payload,
                endDate: moment(state.dateRange.startDate).add(action.payload, 'months').toDate(),
            },
        };
    }

    if (action.type === actions.setEndDate) {
        const endDate = moment(action.payload).endOf('month').toDate();
        return {
            ...state,
            dateRange: {
                ...state.dateRange,
                duration: moment(endDate).diff(state.dateRange.startDate, 'months'),
                endDate,
            },
        };
    }

    return state;
}

export function useDateRange(instance: UniversalTimelineInstance) {
    const {
        items,
        flatItems,
        itemsById,
        state: {
            dateRange: { startDate, endDate },
        },
        dispatch,
    } = instance;

    const [filteredItems, filteredFlatItems, filteredItemsById] = useMemo(() => {
        const startDateTime = moment(startDate);
        const endDateTime = moment(endDate);

        const filteredFlatItems = [];
        const filteredItemsById = {};

        const filteredItems = items.filter((x) => endDateTime.isAfter(x.start) && startDateTime.isBefore(x.end));

        filteredItems.forEach((row) => {
            filteredFlatItems.push(row);
            filteredItemsById[row.id] = row;
        });

        return [filteredItems, filteredFlatItems, filteredItemsById];
    }, [startDate, endDate, items]);

    const handleStartDateChange = useCallback(
        (startDate: Date) =>
            dispatch({
                type: actions.setStartDate,
                payload: startDate,
            }),
        [dispatch]
    );

    const handleDurationChange = useCallback(
        (duration: number) =>
            dispatch({
                type: actions.setDuration,
                payload: duration,
            }),
        [dispatch]
    );

    Object.assign(instance, {
        preFilteredItems: items,
        preFilteredFlatItems: flatItems,
        preFilteredItemsById: itemsById,
        filteredItems,
        filteredFlatItems,
        filteredItemsById,
        items: filteredItems,
        flatItems: filteredFlatItems,
        itemsById: filteredItemsById,
        setStartDate: handleStartDateChange,
        setDuration: handleDurationChange,
    });
}
