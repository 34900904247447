import { WellInputClient, WellInputDto } from 'api';
import { casesActions } from 'store/cases/CasesSlice';
import { createAppAsyncThunk } from 'store/createAppAsyncThunk';
import { wellInputCaseStateSelector } from '../wellInputCaseSelectors';

const service = new WellInputClient();

export interface WellInputCaseParams {
  scenarioCaseId: number;
}

type WellInputCaseRequest = WellInputCaseParams & {
  force?: boolean;
};

export const getWellInputCase = createAppAsyncThunk(
  'cases/wellInput/get',
  ({ scenarioCaseId }: WellInputCaseRequest, { getState, dispatch }): Promise<WellInputDto[]> => {
    const wellInput = wellInputCaseStateSelector(getState());

    if (wellInput.arg && wellInput.arg.scenarioCaseId !== scenarioCaseId) {
      dispatch(casesActions.reset());
    }

    return service.getScenarioCase(scenarioCaseId, undefined);
  },
  {
    condition: (arg, { getState }) => {
      if (arg.force) {
        return true;
      }

      const wellInput = wellInputCaseStateSelector(getState());

      if (!wellInput.loading && wellInput.data === undefined) {
        return true;
      }

      if (wellInput.arg && arg.scenarioCaseId !== wellInput.arg.scenarioCaseId) {
        return true;
      }

      return false;
    }
  }
);
