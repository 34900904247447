import cx from 'classnames';
import React, { ComponentPropsWithoutRef, forwardRef, PropsWithChildren, Ref, useMemo } from 'react';
import { capitalize } from '../util';
import styles from './TableCell.module.css';

export type TableCellAlign = 'left' | 'center' | 'right';

/**
 * The props for the {@link TableCell} component.
 * @category Props
 */
export interface TableCellProps extends ComponentPropsWithoutRef<'td'> {
    blockLayout?: boolean;
    align?: TableCellAlign;
    disableRelative?: boolean;
}

const useCellProps = ({ align = 'left', disableRelative = false, blockLayout, className, ...other }: TableCellProps) =>
    useMemo(
        () => ({
            className: cx(
                styles.cell,
                blockLayout ? styles.blockCell : styles.tableCell,
                { [styles[`align${capitalize(align)}`]]: align !== 'left', [styles.relative]: !disableRelative },
                className
            ),
            ...other
        }),
        [align, disableRelative, blockLayout, className, other]
    );

/**
 * @category Component
 * @group Table
 */
export const TableHeaderCell = forwardRef(function TableHeaderCell(
    props: PropsWithChildren<TableCellProps>,
    ref: Ref<HTMLTableHeaderCellElement>
) {
    const Component = props.blockLayout ? 'div' : 'th';
    const newProps = useCellProps(props);
    return <Component ref={ref} {...newProps} />;
});

/**
 * @category Component
 * @group Table
 */
export const TableCell = forwardRef(function TableCell(
    props: PropsWithChildren<TableCellProps>,
    ref: Ref<HTMLTableDataCellElement>
) {
    const Component = props.blockLayout ? 'div' : 'td';
    const newProps = useCellProps(props);
    return <Component ref={ref} {...newProps} />;
});
