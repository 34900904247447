import { Edge, MarkerType } from 'reactflow';

import { ILineupSchemeEdges } from 'api';
import { DiagramEdgeData } from 'domain/scheme';

export const defaultEdgeColor = '#b1b1b7';
export const getNodeHandleId = (side: string, index?: number) => `${side}-${index ?? 0}`;
export const splitHandleId = (handleId: string) => {
  const data = handleId.split('-');
  return {
    side: data[0],
    position: data.length > 1 ? +data[1] : 0
  };
};

export const convertToDiagramEdges = (edges: ILineupSchemeEdges[]): Edge<DiagramEdgeData>[] =>
  edges.map((edge) => {
    const color = edge.backgroundColor ?? defaultEdgeColor;

    return {
      id: edge.id.toString(),
      type: 'pipeline',
      data: {
        original: edge,
        pipelineId: edge.pipelineId,
        offset: edge.offset ?? undefined,
        centerX: edge.centerX,
        centerY: edge.centerY,
        hideLabel: edge.hideLabel,
        labelOffset: edge.labelOffset ?? undefined
      },
      label: edge.name,
      markerEnd: edge.isJumperLine
        ? undefined
        : {
            type: MarkerType.ArrowClosed,
            color,
            width: 8,
            height: 8,
            strokeWidth: 2
          },
      style: {
        strokeWidth: 4,
        stroke: color
      },
      source: edge.sourceNodeId.toString(),
      target: edge.targetNodeId.toString(),
      sourceHandle: getNodeHandleId(edge.sourceHandleSide, edge.sourceHandlePosition),
      targetHandle: getNodeHandleId(edge.targetHandleSide, edge.targetHandlePosition)
    };
  });
