import { useMemo } from 'react';
import { Navigate, NavigateProps, To, useSearchParams } from 'react-router-dom';

export const NavigatePreserveSearch = ({ to, ...other }: NavigateProps) => {
  const [searchParams] = useSearchParams();

  const there = useMemo(() => {
    const result: To = {
      search: searchParams.toString()
    };
    if (typeof to === 'string') {
      result.pathname = to;
    } else {
      Object.assign(result, to);
    }
    return result;
  }, [searchParams, to]);
  return <Navigate to={there} {...other} />;
};
