import { PlantClient } from 'api';
import { sortBy } from 'lodash';
import { rootApi } from 'store/rootApi';

const service = new PlantClient();

export const plantsApi = rootApi
  .enhanceEndpoints({
    addTagTypes: ['plant']
  })
  .injectEndpoints({
    endpoints: (builder) => ({
      getAllPlants: builder.query({
        queryFn: (_: void) =>
          service
            .getAll()
            .then((data) => ({ data: sortBy(data, 'name') }))
            .catch((error) => ({ error })),
        providesTags: (result) =>
          result
            ? [...result.map(({ id }) => ({ type: 'plant' as const, id })), { type: 'plant', id: 'LIST' }]
            : [{ type: 'plant', id: 'LIST' }]
      })
    })
  });
