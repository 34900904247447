import { useContext, useMemo } from 'react';
import { DataGridContext } from '../DataGridContext';
import { DataGridColumnInstance } from '../types/DataGridColumnInstance';
import { DataGridRowProps } from '../types/DataGridRowProps';

export function useFormCell({ id }: DataGridColumnInstance, { index, original, values }: DataGridRowProps) {
    const { form, fieldArray } = useContext(DataGridContext);
    const { getValues } = form;
    const name = useMemo(() => `row[${index}].${id}`, [id, index]);
    const defaultValue = useMemo(
        () => getValues(name) ?? original?.[id] ?? values?.[id],
        [getValues, id, name, original]
    );

    return { form, fieldArray, name, defaultValue };
}
