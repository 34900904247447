import { decomposeColor, recomposeColor } from '@mui/material';

export function removeAlpha(color: string, background: string) {
  const {
    type,
    values: [r, g, b, a],
    colorSpace
  } = decomposeColor(color);
  const {
    values: [r2, g2, b2]
  } = decomposeColor(background);
  const r3 = Math.round((1 - a) * r2 + a * r);
  const g3 = Math.round((1 - a) * g2 + a * g);
  const b3 = Math.round((1 - a) * b2 + a * b);
  return recomposeColor({ type, values: [r3, g3, b3], colorSpace });
}
