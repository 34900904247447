import { faFilter } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { cloneElement, FC, PropsWithChildren, useCallback, useRef, useState } from 'react';
import { IconButton } from '../../Button/IconButton';
import { Popover } from '../../Popover';
import { Typography } from '../../Typography';
import { DataGridColumnProps } from '../types';

import styles from './TableFilter.module.css';

interface DataGridFilterProps {
    column: DataGridColumnProps;
}

export const DataGridFilter: FC<PropsWithChildren<DataGridFilterProps>> = ({ column, children }) => {
    const [open, setOpen] = useState(false);

    const buttonRef = useRef();

    const handleOpen = useCallback(() => {
        setOpen(true);
    }, []);

    const handleClose = useCallback(() => {
        setOpen(false);
    }, []);

    const child = cloneElement(children as any, { onClose: handleClose });

    return (
        <>
            <Typography
                className={styles.root}
                noWrap
                component="span"
                variant="inherit"
                color={column.filterValue ? 'primary' : 'inherit'}
            >
                <IconButton ref={buttonRef} size="small" onClick={handleOpen}>
                    <FontAwesomeIcon icon={faFilter} size="xs" />
                </IconButton>
            </Typography>
            <Popover
                anchorEl={buttonRef}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left'
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left'
                }}
                open={open}
                onClose={handleClose}
            >
                {child}
            </Popover>
        </>
    );
};
