import DateRangeIcon from '@mui/icons-material/DateRange';
import { Button } from '@mui/material';
import { ScenarioCaseStatus } from 'api';
import { useMemo, useRef } from 'react';
import { scenariosApi } from 'store/scenarios/scenariosApi';
import { useScenarioCaseIdParams } from 'utils/useParams';
import ApplyToOfficialInputDialog, { ApplyDialogProps, ApplyDialogRef } from './ApplyToOfficialInputDialog';

export function ApplyButton({ onApply }: ApplyDialogProps) {
  const dialogRef = useRef<ApplyDialogRef>(null);

  const { caseId } = useScenarioCaseIdParams();
  const { data: cases } = scenariosApi.useActivityDashboardCasesQuery(undefined);

  const caseIsCompleted = useMemo(() => {
    return cases?.find((item) => item.id === caseId)?.status === ScenarioCaseStatus.Completed;
  }, [cases, caseId]);

  const handleDialogOpen = () => {
    dialogRef.current?.open();
  };

  if (!caseIsCompleted) return null;

  return (
    <>
      <Button startIcon={<DateRangeIcon />} onClick={handleDialogOpen}>
        Transfer to Official Inputs
      </Button>
      <ApplyToOfficialInputDialog ref={dialogRef} onApply={onApply} />
    </>
  );
}
