import { Draft } from '@reduxjs/toolkit';
import { IGetWeekDatesDto } from 'api';
import { EquipmentNameRow } from '../types/ConstraintsRow';
import { ConstraintsStateValue } from '../types/ConstraintsState';

function fillMissingInputForRow(eqNameRow: EquipmentNameRow, weekDates: IGetWeekDatesDto[]) {
  for (let i = 0; i < weekDates.length; i++) {
    const weekDateId = weekDates[i].id;
    const input = eqNameRow.inputs.get(weekDateId);
    if (!input) {
      const prevWeekDateId = weekDates[Math.max(i - 1, 0)].id;
      const prevInputValue = eqNameRow.inputs.get(prevWeekDateId).constraintValue ?? 0;
      eqNameRow.inputs.set(weekDateId, {
        weekDateId,
        constraintValue: prevInputValue
      });
    }
  }
}
export function fillMissedInputs(state: Draft<ConstraintsStateValue>) {
  const weekDates: IGetWeekDatesDto[] = state.weekDates;
  if (weekDates.length) {
    for (const row of state.rows) {
      for (const eqTypeRow of row.subRows) {
        for (const eqNameRow of eqTypeRow.subRows) {
          fillMissingInputForRow(eqNameRow as EquipmentNameRow, weekDates);
        }
      }
    }
  }
}
