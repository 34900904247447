import cx from 'classnames';
import React, { Component, ComponentType, FC, Fragment } from 'react';
import { Toolbar } from '../../Toolbar';
import { DataGridRowProps } from '../types';
import styles from './InlineToolbar.module.css';

export interface DataGridInlineToolbarItemsProps<TItem extends object = any> {
    index: number;
    row: DataGridRowProps<TItem>;
}

export type DataGridInlineToolbarItems<TItem extends object = any> = ComponentType<
    DataGridInlineToolbarItemsProps<TItem>
>;

/**
 * The props for the {@link DataGridInlineToolbar} component.
 * @category Props
 */
export interface DataGridInlineToolbarProps<TItem extends object = any> {
    blockLayout?: boolean;
    index: number;
    row: DataGridRowProps<TItem>;
    Items: DataGridInlineToolbarItems<TItem>;
}

/**
 * @category Component
 * @group Data Grid
 */
export const DataGridInlineToolbar: FC<DataGridInlineToolbarProps> = ({ blockLayout, index, Items, ...other }) => {
    const [Wrapper, wrapperProps] = blockLayout ? [Fragment, {}] : ['td', { width: 0 }];

    return (
        <Wrapper {...wrapperProps}>
            {index == 0 && (
                <div className={cx(styles.root, styles.root_top)}>
                    <Toolbar className={styles.toolbar}>
                        <Items index={index} {...other} />
                    </Toolbar>
                </div>
            )}
            <div className={styles.root}>
                <Toolbar className={styles.toolbar}>
                    <Items index={index + 1} {...other} />
                </Toolbar>
            </div>
        </Wrapper>
    );
};
