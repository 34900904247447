import { appInsights } from 'app/appInsights';
import ErrorBoundary from 'components/common/ErrorBoundary';
import { SuspenseLoader } from 'components/common/SuspenseLoader';
import { lazy, PropsWithChildren, PropsWithRef } from 'react';

type DefaultAsyncComponent<T> = Promise<{
  default: React.ComponentType<T>;
}>;

export type ComponentImport<Props> = () => DefaultAsyncComponent<Props>;

function lazyRetry<Props>(importStatement: ComponentImport<Props>, filename: string): DefaultAsyncComponent<Props> {
  return new Promise((resolve, reject) => {
    const alreadyRefreshed = JSON.parse(window.sessionStorage.getItem('lazy-error-refreshed') || 'false');

    importStatement()
      .then((component) => {
        window.sessionStorage.setItem(`lazy-error-${filename}refreshed`, 'false');
        resolve(component);
      })
      .catch((err) => {
        if (err.name === 'ChunkLoadError' && !alreadyRefreshed) {
          window.sessionStorage.setItem(`lazy-error-${filename}refreshed`, 'true');
          window.location.reload();
        }
        reject(err);
      });
  });
}

export const lazyComponent = <Props,>(importStatement: ComponentImport<Props>, filename: string) => {
  const Component = lazy(() => lazyRetry(importStatement, filename));

  return (props: PropsWithRef<Props> & PropsWithChildren<Props>) => (
    <ErrorBoundary appInsights={appInsights}>
      <SuspenseLoader>
        <Component {...props} />
      </SuspenseLoader>
    </ErrorBoundary>
  );
};
