import { ScenarioCaseStatus } from 'api';
import { useMemo } from 'react';
import { NavLink, Outlet, useLocation, useNavigate } from 'react-router-dom';

import { CircularProgress, Divider } from '@mui/material';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';

import { ScenarioCaseOutletContext } from 'pages/scenarios/case';
import { ColumnDisabledProvider } from 'pages/scenarios/case/components/ColumnDisabledProvider';
import { useCaseInfo } from 'pages/scenarios/case/hooks/useCaseInfo';
import ScenarioCasePageHeader from 'pages/scenarios/components/ScenarioCasePageHeader';
import { useOutletRoute } from 'utils/useOutletRoute';
import { useScenarioCaseIdParams } from 'utils/useParams';

export type SimulationCaseOutletContext = ScenarioCaseOutletContext;

const editableStatuses = [ScenarioCaseStatus.Completed, ScenarioCaseStatus.Failed];

function SentToSimCasePage() {
  const childRoute = useOutletRoute();
  const navigate = useNavigate();
  const location = useLocation();
  const { caseId } = useScenarioCaseIdParams();
  const { isEditable, isLoading, title } = useCaseInfo(caseId, editableStatuses);

  const outletContext = useMemo(
    (): SimulationCaseOutletContext => ({
      isEditable,
      isViewOnly: !isEditable
    }),
    [isEditable]
  );

  const goBack = () => {
    const links = location.pathname.split('/');
    const backLink = `/${links[1]}/${links[2]}`;
    navigate(backLink);
  };

  if (isLoading) {
    return <CircularProgress size={70} sx={{ display: 'block', mx: 'auto', mt: 3 }} />;
  }

  return (
    <>
      <ScenarioCasePageHeader title={title} isViewOnly={!isEditable} goBack={goBack} />
      <Tabs value={childRoute?.path ?? 'lineup'}>
        <Tab label="Lineup" component={NavLink} to="lineup" value="lineup" />
        <Tab label="Wells" component={NavLink} to="wells" value="wells" />
        <Tab label="Input Data" component={NavLink} to="input-data" value="input-data" />
      </Tabs>
      <Divider sx={{ mb: 2 }} />
      <ColumnDisabledProvider>
        <Outlet context={outletContext} />
      </ColumnDisabledProvider>
    </>
  );
}

export default SentToSimCasePage;
