import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { InputQCState, SetSelectedIdsPayload } from './types';

const initialState: InputQCState = {
  selectedWellIds: new Set<number>(),
  selectedWeekDateIds: new Set<number>()
};

const inputQCSlice = createSlice({
  name: 'inputQC',
  initialState,
  reducers: {
    setSelectedIds(state, action: PayloadAction<SetSelectedIdsPayload>) {
      const { selectedWellIds, selectedWeekDateIds } = action.payload;

      state.selectedWellIds = selectedWellIds;
      state.selectedWeekDateIds = selectedWeekDateIds;
    }
  }
});

export const inputQCActions = inputQCSlice.actions;

export default inputQCSlice.reducer;
