import { isUndefined } from 'lodash';
import { useEffect } from 'react';
import { Outlet, useParams } from 'react-router';
import { useNavigate } from 'react-router-dom';
import 'reactflow/dist/style.css';

import Box from '@mui/material/Box';
import { LoadingContainer } from 'components/common/Loading/LoadingContainer';
import Toolbar from './components/SchemeToolbar';

import { useAppDispatch, useAppSelector } from 'store';
import { meterStationNameSelector, schemeSelector, selectedWeekSelector } from 'store/scheme/schemeSelectors';
import { schemeActions } from 'store/scheme/schemeSlice';
import { weekDatesApi } from 'store/weekDates/weekDatesApi';

function LineupScheme() {
  const params = useParams<{ meterStationId: string }>();
  const dispatch = useAppDispatch();
  const meterStationId = Number(params?.meterStationId);

  const { data, isLoading } = weekDatesApi.useGetWeekDatesQuery(undefined);
  const selectedWeekDto = useAppSelector(selectedWeekSelector);
  const meterStationName = useAppSelector((state) => meterStationNameSelector(state, meterStationId));
  const { schemeView } = useAppSelector(schemeSelector);
  const navigate = useNavigate();

  useEffect(() => {
    //need to check only on first render
    if (schemeView.meterStation.meterStationId && schemeView.view === 'meterStation') {
      navigate(schemeView.meterStation.meterStationId.toString());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isUndefined(selectedWeekDto) && data?.length) {
      dispatch(schemeActions.setSelectedWeek(data[0]));
    }
  }, [dispatch, data, selectedWeekDto]);

  return (
    <LoadingContainer loading={isLoading}>
      <Toolbar meterStationName={meterStationName} />
      <Box height="100%" width="100%">
        <Outlet />
      </Box>
    </LoadingContainer>
  );
}

export default LineupScheme;
