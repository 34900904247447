import Moveable, { OnResize } from 'moveable';
import React, { MutableRefObject, RefObject, useCallback, useEffect, useMemo, useState } from 'react';
import { NodeChange, SnapGrid, useStoreApi, useUpdateNodeInternals } from 'reactflow';

import styles from '../components/DiagramNode.module.css';

import { faMaximize } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconButton } from '../../Button';

export interface NodeSize {
    width: number;
    height: number;
}

interface Options {
    id: string;
    resizable: boolean;
    nodeRef: RefObject<HTMLDivElement>;
    selected: boolean;
    dragging: boolean;
    snapGrid: SnapGrid;
    dimensions: MutableRefObject<{
        position: {
            xPos: number;
            yPos: number;
        };
        size: NodeSize;
    }>;
    nodeSize: [NodeSize, React.Dispatch<React.SetStateAction<NodeSize>>];
    store: ReturnType<typeof useStoreApi>;
}

export function useNodeResize({
    id,
    resizable,
    nodeRef,
    selected,
    dragging,
    snapGrid,
    nodeSize,
    dimensions,
    store
}: Options) {
    const updateNodeInternals = useUpdateNodeInternals();

    const [size, setSize] = nodeSize;
    const [resize, setResize] = useState(false);

    const handleResize = useCallback((event: React.MouseEvent) => {
        event.preventDefault();
        event.stopPropagation();

        setResize(true);
    }, []);

    const handleWrapperClick = useCallback(
        (event: React.MouseEvent) => {
            if (resize) {
                event.preventDefault();
                event.stopPropagation();
            }
        },
        [resize]
    );

    useEffect(() => {
        if (!resizable || !resize || !nodeRef.current || !selected || dragging) {
            return;
        }

        function handleResize(event: OnResize) {
            const {
                position: { yPos, xPos },
                size: { width, height }
            } = dimensions.current;

            const {
                drag: { left: leftDelta, top: topDelta },
                width: rawWidth,
                height: rawHeight
            } = event;

            const newLeft = leftDelta;
            const newTop = topDelta;
            const newWidth = Math.round(rawWidth);
            const newHeight = Math.round(rawHeight);

            const changes: NodeChange[] = [];

            if (newLeft !== yPos || newTop !== xPos) {
                changes.push({
                    id,
                    type: 'position',
                    position: {
                        x: newLeft,
                        y: newTop
                    }
                });
            }

            if (newHeight !== height || newWidth !== width) {
                setSize({ width: newWidth, height: newHeight });
            }

            if (changes.length > 0) {
                store.getState().onNodesChange(changes);
            }
        }

        const moveable = new Moveable(document.body, {
            target: nodeRef.current,
            className: 'nodrag',
            draggable: false,
            snappable: true,
            resizable: true,
            scalable: false,
            rotatable: false,
            warpable: false,
            pinchable: false,
            origin: false,
            keepRatio: false,
            edge: false,
            throttleDrag: 1,
            throttleResize: 1,
            throttleScale: 0,
            throttleRotate: 0,
            dragArea: false,
            snapGridWidth: snapGrid?.[0],
            snapGridHeight: snapGrid?.[1],
            preventClickEventOnDrag: false,
            preventClickDefault: false
        });

        moveable.on('resize', handleResize);

        return () => {
            moveable.destroy();
            setResize(false);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selected, dragging, resizable, id, store, snapGrid, resize]);

    useEffect(() => {
        updateNodeInternals(id);
    }, [size, id, updateNodeInternals]);

    const ResizeButton = useMemo(
        () =>
            resizable && !resize ? (
                <IconButton className={styles.resize} color="default" size="small">
                    <FontAwesomeIcon icon={faMaximize} onClick={handleResize} />
                </IconButton>
            ) : null,
        [handleResize, resizable, resize]
    );

    return { ResizeButton, handleWrapperClick };
}
