import { Group } from '@visx/group';
import { sortBy } from 'lodash';
import React, { Fragment, memo, useContext } from 'react';
import { ChartContext } from '../ChartContext';
import { AnnotationLayer } from '../chartTypes';

interface RenderChartProps {
    layer: AnnotationLayer;
}

/**
 * @category Component
 * @group Chart
 */
let RenderChart = ({ layer }: RenderChartProps) => {
    const instance = useContext(ChartContext);

    const { margin, groups, SeriesGroups, groupDimensions, innerWidth, innerHeight } = instance;
    return (
        <>
            <defs>
                <clipPath id={`${layer}-series-clip-path`}>
                    <rect x={0} y={0 - margin.top} width={innerWidth} height={innerHeight + margin.top} />
                </clipPath>
            </defs>
            <Group className={`${layer}-render-chart`} clipPath={`url(#${layer}-series-clip-path)`}>
                {groups.map((group) => {
                    const series = SeriesGroups?.[group]?.filter(
                        (series) => (series?.props?.layer ?? 'front') === layer
                    );

                    const dimensions = groupDimensions[group];
                    const top = dimensions.position.top + dimensions.offset.top;
                    const left = dimensions.position.left + dimensions.offset.left;

                    const children = sortBy(series, (series) => series?.props?.renderOrder);

                    return (
                        <Fragment key={group}>
                            <defs key={group + 'defs'}>
                                <clipPath id={`${group}-group-clip-path`}>
                                    <rect x={0} y={0} width={dimensions.size.width} height={dimensions.size.height} />
                                </clipPath>
                            </defs>
                            <Group
                                key={group + 'series'}
                                top={top}
                                left={left}
                                clipPath={`url(#${group}-group-clip-path)`}
                            >
                                {children}
                            </Group>
                        </Fragment>
                    );
                })}
            </Group>
        </>
    );
};

RenderChart = memo(RenderChart);

export { RenderChart };
