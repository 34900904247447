import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'store';
import { getLineupSnapshot } from 'store/official-inputs/lineup/utils/getLineupSnapshot';
import { selectCasesPresentState } from '../CasesSelector';

export const lineupCaseStateSelector = createSelector(selectCasesPresentState, (state) => state.lineup);

const lineupRowsSelector = (state: RootState) => lineupCaseStateSelector(state).rows;

export const lineupCaseSelector = createSelector(
  [lineupRowsSelector, (_state, searchQuery?: string) => searchQuery],
  (rows, searchQuery) => getLineupSnapshot(rows, searchQuery)
);
