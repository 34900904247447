import { ILineupSchemeNodes } from 'api';
import { Edge, HandleType, Position } from 'reactflow';

const groupEdgesByNodeId = <T>(edges: Edge<T>[]): Record<string, Edge<T>[]> => {
  return edges.reduce((acc, edge) => {
    const { source: sourceNodeId, target: targetNodeId } = edge;

    if (!acc[sourceNodeId]) {
      acc[sourceNodeId] = [];
    }

    if (!acc[targetNodeId]) {
      acc[targetNodeId] = [];
    }

    acc[sourceNodeId].push(edge);
    acc[targetNodeId].push(edge);

    return acc;
  }, {});
};

export const getHandlePosition = (handleId: string): Position => {
  if (handleId.startsWith('top')) {
    return Position.Top;
  }

  if (handleId.startsWith('right')) {
    return Position.Right;
  }

  if (handleId.startsWith('bottom')) {
    return Position.Bottom;
  }

  if (handleId.startsWith('left')) {
    return Position.Left;
  }

  throw new TypeError(`There is no such Node Position: ${handleId}`);
};

/**
 * Expected expression is: 'position-index'
 *
 * if (match) {
      const position = match[1];
      const index = parseInt(match[2]);
    }
 */
//
export function isQuantitySpecified(input: string) {
  const regex = /^(.+?)-(\d+)$/;
  return regex.exec(input);
}

function createHandles<T extends object>(edges: Edge<T>[], nodeId: string) {
  return edges.reduce<Record<HandleType, Partial<Record<Position, Set<string>>>>>(
    (acc, edge) => {
      const sourceHandleId = edge.sourceHandle;
      const targetHandleId = edge.targetHandle;
      const type = edge.source === nodeId ? 'source' : 'target';
      const id = type === 'source' ? sourceHandleId : targetHandleId;
      const position = getHandlePosition(id);
      let handles = acc[type][position];

      if (!handles) {
        handles = new Set<string>();
        acc[type][position] = handles;
      }

      handles.add(id);

      return acc;
    },
    {
      source: {},
      target: {}
    }
  );
}

export const positionMapper: Record<
  Position,
  keyof Pick<ILineupSchemeNodes, 'leftHandlesCount' | 'rightHandlesCount' | 'topHandlesCount' | 'bottomHandlesCount'>
> = {
  [Position.Left]: 'leftHandlesCount',
  [Position.Top]: 'topHandlesCount',
  [Position.Right]: 'rightHandlesCount',
  [Position.Bottom]: 'bottomHandlesCount'
};

function processHandles(groupedByNodeId: Record<string, Edge[]>, nodes: ILineupSchemeNodes[]) {
  return Object.keys(groupedByNodeId).reduce((acc, nodeId) => {
    acc[nodeId] = [];
    const edges = groupedByNodeId[nodeId];
    const newHandles = createHandles(edges, nodeId);

    const schemeNode = nodes.find((x) => x.id === +nodeId);
    for (const type of Object.keys(newHandles)) {
      for (const position of Object.values(Position)) {
        const handlesCount = schemeNode[positionMapper[position]];
        const handlesSet = newHandles[type][position];
        if (handlesSet) {
          for (let i = 0; i < handlesCount; i++) {
            const id = `${position}-${i}`;
            handlesSet.add(id);
          }

          const nodeHandles = Array.from(handlesSet.values(), (id) => ({ id, position, type }));
          acc[nodeId].push(...nodeHandles);
        }
      }
    }
    return acc;
  }, {});
}

export const createHandlesBasedOnEdges = <T extends object>(edges: Edge<T>[], nodes: ILineupSchemeNodes[]) => {
  const groupedByNodeId = groupEdgesByNodeId(edges);

  const handles = processHandles(groupedByNodeId, nodes);

  return { handles };
};
