import { Children, ReactElement, ReactNode, useMemo } from 'react';
import { isElement, isFragment } from 'react-is';
import { AnnotationOptions, SeriesOptions } from '..';
import { Axis, ChartInstance } from '../chartTypes';
import { ChartCrossHairProps } from '../components/ChartCrossHair';
import {
    componentName,
    isAnnotation,
    isAxis,
    isBrush,
    isCrossHair,
    isLegend,
    isSeries,
    isStackedGroup,
    isToolbar,
    isTooltip,
    isZoom
} from './chartUtils';

export function useLayersFromChild(children: ReactNode): ChartInstance['layers'] {
    return useMemo(() => {
        let Toolbar: ReactElement | undefined;
        const Axis: ReactElement<Axis>[] = [];
        const Series: ReactElement<SeriesOptions>[] = [];
        const Annotations: ReactElement<AnnotationOptions>[] = [];
        const CrossHairs: ReactElement<ChartCrossHairProps>[] = [];
        let Legend: ReactElement | undefined;
        let Brush: ReactElement | undefined;
        let Zoom: ReactElement | undefined;
        let Tooltip: ReactElement | undefined;
        const RestChildren: ReactElement[] = [];

        function precessChild(Child: ReactNode) {
            if (Array.isArray(Child)) {
                Children.forEach(Child, precessChild);
                return;
            }

            if (isFragment(Child)) {
                Children.forEach(Child.props.children, precessChild);
                return;
            }

            const isEl = Child && isElement(Child);
            const name = componentName(Child);

            if (isEl) {
                if (isToolbar(name)) {
                    Toolbar = Child;
                    return;
                }

                if (isAxis(name)) {
                    Axis.push(Child);
                    return;
                }

                if (isSeries(name) || isStackedGroup(name)) {
                    Series.push(Child);
                    return;
                }

                if (isAnnotation(name)) {
                    Annotations.push(Child);
                    return;
                }

                if (isCrossHair(name)) {
                    CrossHairs.push(Child);
                    return;
                }

                if (isBrush(name)) {
                    Brush = Child;
                    return;
                }

                if (isZoom(name)) {
                    Zoom = Child;
                    return;
                }

                if (isLegend(name)) {
                    Legend = Child;
                    return;
                }

                if (isTooltip(name)) {
                    Tooltip = Child;
                    return;
                }

                RestChildren.push(Child);
            }
        }

        Children.forEach(children, precessChild);

        return { Toolbar, Axis, Series, Annotations, CrossHairs, Brush, Zoom, Tooltip, RestChildren, Legend };
    }, [children]);
}
