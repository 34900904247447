import { ProvidedZoom, TransformMatrix } from '@visx/zoom/lib/types';
import { useCallback, useMemo, useRef, useState } from 'react';
import { ChartInstance } from '../chartTypes';

type ZoomState = {
    initialTransformMatrix: TransformMatrix;
    transformMatrix: TransformMatrix;
    isDragging: boolean;
};

export type ProvidedZoomType = ProvidedZoom<SVGSVGElement> & ZoomState;

export const defaultInitialTransformMatrix: TransformMatrix = {
    scaleX: 1,
    scaleY: 1,
    translateX: 0,
    translateY: 0,
    skewX: 0,
    skewY: 0
};

export function useZoom(): ChartInstance['zoom'] {
    const ref = useRef<ProvidedZoomType>();
    const [transformMatrix, setTransformMatrix] = useState();

    const updateTransformMatrix = useCallback((newState) => setTransformMatrix({ ...newState }), []);

    return useMemo(() => ({ ref, updateTransformMatrix, transformMatrix }), [transformMatrix]);
}
