import { Navigate, RouteObject } from 'react-router-dom';
import { lazyComponent } from 'utils/lazyComponent';
import SentToSimCasePage from '.';
import SubsurfaceDataSimulationCase from './subsurface-data-case/SubsurfaceDataSimulationCase';

const LineupSentToSimCase = lazyComponent(() => import('./lineup-case/SimulationLineupCase'), 'lineupSentToSimCase');
const WellsSentToSimCase = lazyComponent(() => import('./wells-case/SimulationWellInputCase'), 'wellsSentToSimCase');
const SubsurfacePressureSimulationCase = lazyComponent(
  () => import('./subsurface-data-case/pressure/SimulationWellPressureCase'),
  'SimulationWellPressureCase'
);
const SubsurfaceGorSimulationCase = lazyComponent(
  () => import('./subsurface-data-case/gor/SimulationWellGorCase'),
  'SimulationWellGorCase'
);
const SubsurfaceSimulationPICase = lazyComponent(
  () => import('./subsurface-data-case/pi/SimulationWellPICase'),
  'SimulationWellPICase'
);
const SubsurfaceSimulationPlantInputsCase = lazyComponent(
  () => import('./subsurface-data-case/plant-inputs/SimulationPlantInputsCase'),
  'plantInputsCase'
);

const children: RouteObject[] = [
  {
    index: true,
    element: <Navigate replace to="lineup" />
  },
  {
    path: 'lineup',
    element: <LineupSentToSimCase />
  },
  {
    path: 'wells',
    element: <WellsSentToSimCase />
  },
  {
    path: 'input-data',
    element: <SubsurfaceDataSimulationCase />,
    children: [
      {
        index: true,
        element: <Navigate replace to="pressure" />
      },
      {
        path: 'pressure',
        element: <SubsurfacePressureSimulationCase />
      },
      {
        path: 'gor',
        element: <SubsurfaceGorSimulationCase />
      },
      {
        path: 'pi',
        element: <SubsurfaceSimulationPICase />
      },
      {
        path: 'plant-inputs',
        element: <SubsurfaceSimulationPlantInputsCase />
      }
    ]
  }
];

export const caseRoutes: RouteObject[] = [
  {
    path: 'simulation/schedule/:scenarioId/case/:caseId',
    element: <SentToSimCasePage />,
    children
  },
  {
    path: 'simulation/activity-dashboard/:scenarioId/case/:caseId',
    element: <SentToSimCasePage />,
    children
  }
];
