import { createSelector } from '@reduxjs/toolkit';
import { selectOfficialInputHiddenWeekDateIds } from 'store/official-input-page/OfficialInputPageSelectors';
import { RootState } from '..';
import { WellGorState } from './WellGorState';
import { getWellGorSnapshot } from './utils/getWellGorSnapshot';

export const wellGorPresentStateSelector = (state: RootState) => state.wellGor.present;
export const previousWellGorStateSelector = (state: RootState) => state.wellGor.past;
export const wellGorDataSelector = (state: RootState) => state.wellGor.present.data;
export const wellGorLoadingSelector = (state: RootState) => state.wellGor.present.loading;
export const wellGorErrorSelector = (state: RootState) => state.wellGor.present.error;

const selectWellGorDates = (state: RootState) => wellGorPresentStateSelector(state).dates;
const selectWellGorSplittableDates = (state: RootState) => wellGorPresentStateSelector(state).splitableWeekDateIds;

export const selectWellGorWeekDays = createSelector(
  [selectWellGorDates, selectOfficialInputHiddenWeekDateIds, selectWellGorSplittableDates],
  (dates, hiddenWeekDateIds, splitableWeekDateIds) => {
    return dates?.filter((x) => splitableWeekDateIds.has(x.id) || !hiddenWeekDateIds.has(x.id));
  }
);

export const wellGorSnapshotSelector = createSelector(
  [wellGorPresentStateSelector, (_state, searchQuery?: string) => searchQuery],
  (wellGor, searchQuery) => getWellGorSnapshot(wellGor, searchQuery)
);

export function makeWellGorStatus(wellGor: WellGorState, deactivatedTimesteps?: Map<number, boolean>) {
  const hasGorChanges = wellGor.gorChanges?.size > 0;
  const hasInputChanges = wellGor.inputChanges?.size > 0;

  const hasChanges = hasGorChanges || hasInputChanges || deactivatedTimesteps?.size;

  return {
    hasGorChanges,
    hasInputChanges,
    hasChanges
  };
}

export const wellGorStatusSelector = createSelector(wellGorPresentStateSelector, makeWellGorStatus);
