import { Draft } from '@reduxjs/toolkit';
import { IWellGorInputDto, WellGorInputDto } from 'api';
import { WellGorState } from '../WellGorState';
import { WellGorTableRow } from './convertWellGorData';

export function calculateWellGor(gorValue: number, prevValue: number) {
  return prevValue ?? gorValue;
}

export function reCalculateWellGor(state: Draft<WellGorState>, row: WellGorTableRow, startWeekDate?: number) {
  const rowDraft = state.rows.find((x) => x.id === row.parentId)?.subRows?.find((x) => x.id === row.id);

  if (!rowDraft) {
    return;
  }

  const gorValue = state.gorChanges.get(row.id) ?? rowDraft.gorValue;

  const startIndex = startWeekDate ? state.dates.findIndex((x) => x.id === startWeekDate) : 0;
  for (let i = startIndex + 1; i < state.dates.length; i++) {
    const prevWeekDate = state.dates[i - 1];
    const prevValue =
      state.inputChanges.get(row.id)?.get(prevWeekDate?.id)?.gorValue ??
      rowDraft.inputs.get(prevWeekDate?.id)?.gorValue;

    const weekDate = state.dates[i];
    const currentStatus = rowDraft.inputs.get(weekDate.id);

    const newValue = calculateWellGor(gorValue, prevValue);

    // eslint-disable-next-line no-use-before-define
    setWellGorInputChange(state, { row, currentStatus, weekDate: weekDate.id, value: newValue }, false);
  }
}

export type ChangeWellGorInputPayload = {
  row: WellGorTableRow;
  weekDate: number;
  currentStatus: IWellGorInputDto;
  value: number;
};

export function setWellGorInputChange(
  state: Draft<WellGorState>,
  payload: ChangeWellGorInputPayload,
  calculateNestedChanges = true
) {
  const { row, weekDate, currentStatus, value } = payload;
  let rowChanges = state.inputChanges.get(row.id);
  if (!rowChanges) {
    rowChanges = new Map();
    state.inputChanges.set(row.id, rowChanges);
  }

  rowChanges.set(
    weekDate,
    new WellGorInputDto({
      ...currentStatus,
      gorValue: value
    })
  );

  if (calculateNestedChanges) {
    reCalculateWellGor(state, row, weekDate);
  }
}
export function setWellGorInputBulkChange(
  state: Draft<WellGorState>,
  payload: ChangeWellGorInputPayload[],
  calculateNestedChanges = false
) {
  for(const payloadItem of payload){
    setWellGorInputChange(state, payloadItem, calculateNestedChanges);
  }
}

export type ChangeWellGorPayload = {
  row: WellGorTableRow;
  value: number;
};

export function setWellGorChange(
  state: Draft<WellGorState>,
  payload: ChangeWellGorPayload,
  calculateNestedChanges = true
) {
  const { row, value } = payload;

  state.gorChanges.set(row.id, value);

  if (calculateNestedChanges) {
    reCalculateWellGor(state, row);
  }
}
