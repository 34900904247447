import { Navigate, RouteObject } from 'react-router-dom';
import { lazyComponent } from 'utils/lazyComponent';

const SubsurfaceDataCase = lazyComponent(() => import('./SubsurfaceDataCase'), 'subsurfaceDataCase');
const SubsurfacePressureCase = lazyComponent(() => import('./pressure/WellPressureCase'), 'wellPressureCase');
const SubsurfaceGorCase = lazyComponent(() => import('./gor/WellGorCase'), 'wellGorCase');
const SubsurfacePICase = lazyComponent(() => import('./pi/WellPICase'), 'wellPICase');
const PlantInputsCase = lazyComponent(() => import('./plant-inputs/PlantInputsCase'), 'plantInputsCase');

export const subsurfaceCaseRoute: RouteObject = {
  path: 'input-data',
  element: <SubsurfaceDataCase />,
  children: [
    {
      index: true,
      element: <Navigate replace to="pressure" />
    },
    {
      path: 'pressure',
      element: <SubsurfacePressureCase />
    },
    {
      path: 'gor',
      element: <SubsurfaceGorCase />
    },
    {
      path: 'pi',
      element: <SubsurfacePICase />
    },
    {
      path: 'plant-inputs',
      element: <PlantInputsCase />
    }
  ]
};
