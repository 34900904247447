import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ClearIcon from '@mui/icons-material/Clear';
import {
  Box,
  Grow,
  IconButton,
  InputAdornment,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  TextField,
  TextFieldProps,
  Typography,
  useTheme
} from '@mui/material';
import React, { useCallback, useMemo, useRef } from 'react';
import useSelect from 'use-select';
import styles from './Select.module.css';

type Fields<TItem extends object> = {
  value?: keyof TItem;
  label?: keyof TItem;
};

type OptionItem = {
  value?: string;
  label?: string;
};

export type OptionsFilterFunction = <T extends OptionItem>(options: T[], searchValue: string) => T[];

export type SelectProps<TItem extends object> = Omit<TextFieldProps, 'onChange'> & {
  loading?: boolean;
  noOptionsText?: string;
  multi?: boolean;
  fields?: Fields<TItem>;
  enableClear?: boolean;
  value: TItem;
  options?: TItem[];
  filterFunction?: OptionsFilterFunction;
  onChange?: (value: TItem) => void;
  onClear?: () => void;
};

const POPPER_MODIFIERS = [
  {
    name: 'flip',
    enabled: true,
    options: {
      altBoundary: true,
      rootBoundary: 'viewport',
      padding: 8
    }
  },
  {
    name: 'preventOverflow',
    enabled: false,
    options: {
      altAxis: true,
      altBoundary: true,
      tether: true,
      rootBoundary: 'viewport',
      padding: 8
    }
  }
];

const defaultFilterFunction = <T extends OptionItem>(options: T[], searchValue: string) => {
  return options
    .filter((option) => option?.label.toLowerCase().includes(searchValue.toLowerCase()))
    .sort((option) => option?.label.toLowerCase().indexOf(searchValue.toLowerCase()));
};

export const Select = <TItem extends object>({
  disabled,
  multi,
  enableClear,
  fields: fieldsProp,
  value: valueProp,
  options: optionsProp,
  filterFunction = defaultFilterFunction,
  onChange,
  onClear,
  InputProps,
  noOptionsText = 'No options',
  loading,
  ...other
}: SelectProps<TItem>) => {
  const theme = useTheme();

  const fields = useMemo((): Fields<TItem> => {
    return {
      value: 'id' as never,
      label: 'name' as never,
      ...fieldsProp
    };
  }, [fieldsProp]);

  const value = useMemo(() => {
    return valueProp?.[fields.value]?.toString();
  }, [fields.value, valueProp]);

  const options = useMemo(() => {
    return optionsProp?.map((item) => ({
      value: item[fields.value]?.toString(),
      label: item[fields.label]
    }));
  }, [optionsProp, fields.value, fields.label]);

  const textFieldRef = useRef<null | HTMLElement>(null);
  const optionsRef = useRef();

  const handleChange = useCallback(
    (value) => {
      const item = optionsProp?.find((item) => item[fields.value]?.toString() === value);
      onChange?.(item);
    },
    [fields.value, onChange, optionsProp]
  );

  const { visibleOptions, isOpen, setOpen, selectedOption, getInputProps, getOptionProps } = useSelect({
    multi,
    options,
    value,
    onChange: handleChange,
    optionsRef,
    filterFn: filterFunction
  });

  const { ref: inputRef, value: inputValue, ...otherInputProps } = getInputProps();

  const handleOpenSelectMenu = useCallback(() => {
    setOpen(!isOpen);
  }, [isOpen, setOpen]);

  function handleListKeyDown(event) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    } else if (event.key === 'Escape') {
      setOpen(false);
    }
  }

  const handleClearSelection = (e: React.MouseEvent) => {
    e.stopPropagation();
    onChange?.(undefined);
    onClear?.();
  };

  return (
    <>
      <TextField
        ref={textFieldRef}
        inputRef={inputRef}
        {...other}
        {...(disabled ? {} : otherInputProps)}
        value={inputValue}
        disabled={disabled}
        InputProps={{
          ...InputProps,
          endAdornment: (
            <InputAdornment position="end">
              {InputProps?.endAdornment}
              {enableClear && (
                <IconButton disabled={disabled} edge="end" onClick={handleClearSelection}>
                  <ClearIcon />
                </IconButton>
              )}
              <IconButton disabled={disabled} edge="end" onClick={handleOpenSelectMenu}>
                <ArrowDropDownIcon />
              </IconButton>
            </InputAdornment>
          )
        }}
      />
      <Popper
        transition
        open={isOpen}
        anchorEl={textFieldRef.current}
        role={undefined}
        placement="bottom-start"
        modifiers={POPPER_MODIFIERS}
        style={{ zIndex: theme.zIndex.modal, width: textFieldRef.current?.clientWidth }}
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: placement === 'bottom-start' ? 'left top' : 'left bottom'
            }}
          >
            <Paper ref={optionsRef} className={styles.options}>
              {visibleOptions.length === 0 ? (
                <Box padding={2}>
                  <Typography
                    role="presentation"
                    onMouseDown={(event) => {
                      // Prevent input blur when interacting with the "no options" content
                      event.preventDefault();
                    }}
                  >
                    {loading ? 'Loading...' : noOptionsText}
                  </Typography>
                </Box>
              ) : null}
              {visibleOptions.length > 0 ? (
                <MenuList onKeyDown={handleListKeyDown}>
                  {visibleOptions.map((option, index) => {
                    return (
                      <MenuItem
                        {...getOptionProps({ index, option })}
                        key={index}
                        selected={selectedOption.value === option.value}
                      >
                        {option.label}
                      </MenuItem>
                    );
                  })}
                </MenuList>
              ) : null}
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
};
