import cx from 'classnames';
import React, { FC } from 'react';
import { ProvidedZoomType } from '../../utils/useZoom';

interface ZoomControllerProps {
    zoom: ProvidedZoomType;
    x: number;
    y: number;
    width: number;
    height: number;
}
export const ZoomController: FC<ZoomControllerProps> = (props) => {
    const { zoom } = props;
    return (
        <rect
            className={cx('zoom-controller', { grabbing: zoom.isDragging, grab: !zoom.isDragging })}
            {...props}
            fill="transparent"
            onTouchStart={zoom.dragStart}
            onTouchMove={zoom.dragMove}
            onTouchEnd={zoom.dragEnd}
            onMouseDown={zoom.dragStart}
            onMouseMove={zoom.dragMove}
            onMouseUp={zoom.dragEnd}
            onMouseLeave={() => {
                if (zoom.isDragging) zoom.dragEnd();
            }}
            onWheel={zoom.handleWheel}
        />
    );
};
