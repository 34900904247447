import { faChevronDown, faGripVertical } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cx from 'classnames';
import React, {
    Component,
    createContext,
    FC,
    forwardRef,
    HTMLProps,
    PropsWithChildren,
    Ref,
    useCallback,
    useContext,
    useRef,
} from 'react';
import { Draggable, DraggableProvidedDragHandleProps } from 'react-beautiful-dnd';
import { Collapse } from 'reactstrap';
import { Paper } from '../Paper';
import { Toolbar } from '../Toolbar';
import { ToolbarProps } from '../Toolbar/Toolbar';
import styles from './Accordion.module.css';
import { AccordionGroupContext, AccordionGroupInstance } from './AccordionGroup';

/**
 * The context for the {@link Accordion} component.
 */
export const AccordionContext = createContext<{
    enableDragAndDrop: boolean;
    expanded: boolean;
    disabled: boolean;
    toggle: (event) => void;
    dragHandleProps?: DraggableProvidedDragHandleProps;
}>(null as any);

/**
 * @category Component
 */
const AccordionGrip = ({ className = '', ...other }) => (
    <span className={cx(styles.summary_dnd, className)} {...other}>
        <FontAwesomeIcon icon={faGripVertical} />
    </span>
);

/**
 * The props type for {@link AccordionSummary}
 */
export interface AccordionSummaryProps extends ToolbarProps {}

/**
 * @category Component
 * @group Accordion
 */
export const AccordionSummary = forwardRef(
    ({ children, onClick, ...other }: PropsWithChildren<AccordionSummaryProps>, ref: any) => {
        const { enableDragAndDrop, expanded, toggle, dragHandleProps } = useContext(AccordionContext);

        const handleChange = useCallback(
            (event) => {
                if (toggle) {
                    toggle(event);
                }
                if (onClick) {
                    onClick(event);
                }
            },
            [onClick, toggle]
        );

        return (
            <Toolbar ref={ref} className={styles.summary} onClick={handleChange} {...other}>
                {enableDragAndDrop && <AccordionGrip {...dragHandleProps} />}
                <FontAwesomeIcon
                    className={cx(styles.summary_icon, {
                        [styles.summary_icon_expanded]: expanded,
                    })}
                    icon={faChevronDown}
                />
                {children}
            </Toolbar>
        );
    }
);

/**
 * The props type for {@link AccordionDetails}
 */
export interface AccordionDetailsProps extends HTMLProps<HTMLDivElement> {
    disableMargin?: boolean;
    immediateMount?: boolean;
    disableUnmount?: boolean;
}

/**
 * @category Component
 * @group Accordion
 */
export const AccordionDetails: FC<AccordionDetailsProps> = ({
    disableMargin = false,
    immediateMount,
    disableUnmount,
    children,
}) => {
    const { expanded } = useContext(AccordionContext);

    const shouldRender = useRef(false);
    if (disableUnmount && !shouldRender.current && expanded) {
        shouldRender.current = true;
    }

    return (
        <div className={cx(styles.details_content, { [styles.details_margin]: !disableMargin })}>
            {immediateMount || disableUnmount
                ? immediateMount || shouldRender.current
                    ? children
                    : null
                : expanded
                ? children
                : null}
        </div>
    );
};

/**
 * @category Component
 * @group Accordion
 */
export const AccordionBody = forwardRef(
    (
        { disableMargin = false, expanded, className, children: childrenProp, onChange, ...other }: AccordionProps,
        ref: Ref<HTMLDivElement>
    ) => {
        const [summary, ...children] = React.Children.toArray(childrenProp);

        return (
            <Paper
                ref={ref}
                className={cx(styles.accordion, { [styles.margin]: !disableMargin }, className)}
                {...(other as any)}
            >
                {summary}
                <Collapse isOpen={expanded}>{children}</Collapse>
            </Paper>
        );
    }
);

/**
 * The props type for {@link Accordion}
 */
export interface AccordionProps extends Omit<HTMLProps<HTMLDivElement>, 'onChange'> {
    disabled?: boolean;
    disableMargin?: boolean;
    id?: any;
    index?: number;
    enableDragAndDrop?: boolean;
    defaultExpanded?: boolean;
    expanded?: boolean;
    onChange?: (event, value: boolean) => void;
}

/**
 * @category Component
 * @group Accordion
 */
export class Accordion extends Component<AccordionProps> {
    static contextType = AccordionGroupContext;

    state = {
        expanded: this.props.expanded ?? this.props.defaultExpanded ?? false,
        enableDragAndDrop: false,
    };

    constructor(props, context) {
        super(props, context);

        if (context) {
            const { enableDragAndDrop, register } = context;
            register(this, this.state.expanded);
            this.state.enableDragAndDrop = enableDragAndDrop;
        }
    }
    handleChange = (event, value) => {
        const { onChange } = this.props;
        const { notify } = (this.context ?? {}) as AccordionGroupInstance;

        this.setState({
            expanded: value,
        });

        if (onChange) {
            onChange(event, value);
        }

        if (notify) {
            notify(event, this, value);
        }
    };

    expand = (event = null) => {
        this.handleChange(event, true);
    };

    collapse = (event = null) => {
        this.handleChange(event, false);
    };

    toggle = (event) => {
        this.handleChange(event, !this.state.expanded);
    };

    render() {
        const { ref, enableDragAndDrop, id, index, expanded: expandedProp, disabled, onChange, ...other } = this.props;

        const expanded = expandedProp ?? this.state.expanded;

        return this.state.enableDragAndDrop ? (
            <Draggable draggableId={id?.toString()} index={index}>
                {(provided) => (
                    <AccordionContext.Provider
                        value={{
                            enableDragAndDrop: this.state.enableDragAndDrop,
                            disabled,
                            expanded,
                            toggle: this.toggle,
                            dragHandleProps: provided.dragHandleProps,
                        }}
                    >
                        <AccordionBody
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            expanded={expanded}
                            {...other}
                        />
                    </AccordionContext.Provider>
                )}
            </Draggable>
        ) : (
            <AccordionContext.Provider
                value={{
                    enableDragAndDrop: this.state.enableDragAndDrop,
                    disabled,
                    expanded,
                    toggle: this.toggle,
                }}
            >
                <AccordionBody expanded={expanded} {...other} />
            </AccordionContext.Provider>
        );
    }
}
