import { LineupClient, LineupDto } from 'api';
import { casesActions } from 'store/cases/CasesSlice';
import { createAppAsyncThunk } from 'store/createAppAsyncThunk';
import { lineupCaseStateSelector } from '../lineupCaseSelectors';

const service = new LineupClient();

export interface LineupCaseParams {
  scenarioCaseId: number;
}

export const getLineupCase = createAppAsyncThunk(
  'lineupCase/get',
  ({ scenarioCaseId }: LineupCaseParams, { getState, dispatch }): Promise<LineupDto[]> => {
    const lineup = lineupCaseStateSelector(getState());

    if (lineup.arg && lineup.arg.scenarioCaseId !== scenarioCaseId) {
      dispatch(casesActions.reset());
    }

    return service.getLineupInputScenario(scenarioCaseId, undefined);
  },
  {
    condition: (arg, { getState }) => {
      const lineup = lineupCaseStateSelector(getState());

      if (!lineup.loading && lineup.data === undefined) {
        return true;
      }

      if (lineup.arg && lineup.arg.scenarioCaseId !== arg.scenarioCaseId) {
        return true;
      }

      return false;
    }
  }
);
