import { rollup, union } from 'd3-array';
import {
    stack,
    stackOffsetDiverging,
    stackOffsetExpand,
    stackOffsetNone,
    stackOffsetSilhouette,
    stackOffsetWiggle,
    stackOrderAppearance,
    stackOrderAscending,
    stackOrderDescending,
    stackOrderInsideOut,
    stackOrderNone,
    stackOrderReverse,
} from 'd3-shape';
import { isNumber } from 'lodash';
import React, { Children, cloneElement, ReactElement, ReactNode } from 'react';
import { isElement, isFragment } from 'react-is';
import { ChartInstance, SeriesOptions, StackedGroupOptions, StackOffset, StackOrder } from '../chartTypes';
import { componentName, isSeries } from './chartUtils';
import { createAccessor } from './useAccessors';

type SeriesDataItem = {
    category: any;
    id: string;
    value: any;
};

export type StackedItem = { x: number; y: number; y0: number; y1: number };

export type StackedSeries<TSeries> = [child: ReactElement<TSeries>, data: StackedItem[]];

type CategorySerializer = [
    serializer: (value: any) => string,
    deserializer: (category: string) => any,
    sorter: (a: string, b: string) => number
];

const stackOffset: Record<StackOffset, any> = {
    none: stackOffsetNone,
    diverging: stackOffsetDiverging,
    expand: stackOffsetExpand,
    silhouette: stackOffsetSilhouette,
    wiggle: stackOffsetWiggle,
};

const stackOrder: Record<StackOrder, any> = {
    none: stackOrderNone,
    appearance: stackOrderAppearance,
    ascending: stackOrderAscending,
    descending: stackOrderDescending,
    insideOut: stackOrderInsideOut,
    reverse: stackOrderReverse,
};

const createCategorySerializer = (value: any): CategorySerializer => {
    if (value instanceof Date) {
        return [
            (value: Date) => value.getTime().toString(),
            (key) => new Date(parseInt(key)),
            (keyA, keyB) => parseInt(keyA) - parseInt(keyB),
        ];
    }

    if (isNumber(value)) {
        return [
            (value: number) => value.toString(),
            (key) => parseInt(key),
            (keyA, keyB) => parseInt(keyA) - parseInt(keyB),
        ];
    }

    return [(value) => value.toString(), (key) => key, () => 0];
};

function findSeries(children: ChartInstance['children']): ReactElement<SeriesOptions<any>>[] {
    const series: ReactElement<SeriesOptions>[] = [];

    function processChild(Child: ReactNode) {
        if (Array.isArray(Child)) {
            Children.forEach(Child, processChild);
            return;
        }

        if (isFragment(Child)) {
            Children.forEach(Child.props.children, processChild);
            return;
        }

        const name = componentName(Child);

        if (isElement(Child) && isSeries(name)) {
            series.push(Child);
        }
    }

    Children.forEach(children, processChild);

    return series;
}

const xAccessor = (x: StackedItem) => x.x;
const yAccessor = (x: StackedItem) => x.y;
const openAccessor = (x: StackedItem) => x.y0;
const closeAccessor = (x: StackedItem) => x.y1;

export function getStackedData<TSeries extends SeriesOptions<any> = SeriesOptions<any>>(
    options: StackedGroupOptions,
    children: ChartInstance['children'],
    legend: ChartInstance['legend'],
    globalGetX: ChartInstance['getX'],
    globalGetY: ChartInstance['getY']
): StackedSeries<TSeries>[] {
    const { order = 'none', offset = 'none' } = options;
    const series = findSeries(children);

    const dataSource: SeriesDataItem[] = [];
    const childs: Record<string, ReactElement<SeriesOptions>> = {};

    const keys = series.map((x) => {
        const id = x.props.id;
        childs[id] = x;
        return id;
    });

    let categorySerilizer: CategorySerializer | undefined = undefined;
    const seriesCategories: string[][] = [];

    for (const Child of series) {
        const { id, data, xAccessor, yAccessor } = Child.props;

        if (legend.state[id]) {
            continue;
        }

        const getX = createAccessor(xAccessor) ?? globalGetX;
        const getY = createAccessor(yAccessor) ?? globalGetY;
        let serializeCategory: CategorySerializer[0] | undefined = categorySerilizer?.[0];
        const categories: string[] = [];

        data?.forEach((item) => {
            const category = getX?.(item);
            const value = getY?.(item);

            if (!serializeCategory || !categorySerilizer) {
                categorySerilizer = createCategorySerializer(category);
                serializeCategory = categorySerilizer[0];
            }

            const serializedCategory = serializeCategory(category);

            categories.push(serializedCategory);

            dataSource.push({
                category: serializedCategory,
                id,
                value,
            });
        });

        seriesCategories.push(categories);
    }

    const categories = Array.from<string>(union(...seriesCategories));

    categories.sort(categorySerilizer?.[2]);

    const categoriesOrder = categories.reduce((acc, category, index) => {
        acc[category] = index;
        return acc;
    }, {} as Record<string, number>);

    dataSource.sort((a, b) => categoriesOrder[a.category] - categoriesOrder[b.category]);

    const rolls = Array.from<[string, Map<string, any>]>(
        rollup(
            dataSource,
            ([d]) => d.value,
            (d) => d.category,
            (d) => d.id
        )
    );

    const stacks = stack<[string, Map<string, any>], string>()
        .keys(keys)
        .value((item, key) => {
            const [, values] = item;
            return values.get(key);
        })
        .order(stackOrder[order])
        .offset(stackOffset[offset])(rolls);

    const result = stacks.map((items): StackedSeries<TSeries> => {
        const id = items.key;
        const deSerializeCategory = categorySerilizer?.[1]!;

        const dataSource = [];

        for (const item of items) {
            const [category, values] = item.data;
            const [open, close] = item;

            if (isNaN(open) || isNaN(close)) {
                continue;
            }

            const value = values.get(id);

            dataSource.push({
                x: deSerializeCategory(category),
                y: value,
                y0: open,
                y1: close,
            });
        }

        return [
            cloneElement(childs[id], {
                data: dataSource,
                xAccessor,
                yAccessor,
                openAccessor,
                closeAccessor,
            }) as any,
            dataSource,
        ];
    });

    return result;
}
