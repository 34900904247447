import { WellClient, WellDto, WellRateDto } from 'api';
import { sortBy } from 'lodash';
import { rootApi } from 'store/rootApi';

const service = new WellClient();
const getWellRatesTag = 'getWellRates-tag';
export const wellsApi = rootApi.enhanceEndpoints({ addTagTypes: ['well', getWellRatesTag] }).injectEndpoints({
  endpoints: (builder) => ({
    getAllWells: builder.query({
      queryFn: (_: void) =>
        service
          .getAll()
          .then((data) => ({ data: sortBy(data, 'name') }))
          .catch((error) => ({ error })),
      providesTags: (result) =>
        result
          ? [...result.map(({ id }) => ({ type: 'well' as const, id })), { type: 'well', id: 'LIST' }]
          : [{ type: 'well', id: 'LIST' }]
    }),
    getWell: builder.query({
      queryFn: (id: number) =>
        service
          .get(id)
          .then((data) => ({ data }))
          .catch((error) => ({ error })),
      providesTags: (_result, _error, id) => [{ type: 'well', id }]
    }),
    addWell: builder.mutation({
      queryFn: (payload: WellDto[]) =>
        service
          .post(payload)
          .then((data) => ({ data }))
          .catch((error) => ({ error })),
      invalidatesTags: [{ type: 'well', id: 'LIST' }]
    }),
    updateWell: builder.mutation({
      queryFn: (payload: WellDto) =>
        service
          .put(payload)
          .then((data) => ({ data }))
          .catch((error) => ({ error })),
      invalidatesTags: (_result, _error, well) => [{ type: 'well', id: well.id }]
    }),
    removeWell: builder.mutation({
      queryFn: (id: number) =>
        service
          .delete(id)
          .then((data) => ({ data }))
          .catch((error) => ({ error })),
      invalidatesTags: (_result, _error, id) => [{ type: 'well', id }]
    }),
    getWellRates: builder.query<WellRateDto[], void>({
      queryFn: () =>
        service
          .getWellRates()
          .then((data) => ({ data }))
          .catch((error) => ({ error })),
      providesTags: () => [{ type: getWellRatesTag, id: 'LIST' }]
    })
  })
});
