import cx from 'classnames';
import React, { forwardRef, HTMLAttributes } from 'react';
import styles from './List.module.css';

/**
 * The props of the {@link List} component.
 * @category Props
 */
export interface ListProps extends HTMLAttributes<HTMLUListElement> {
    disableMargin?: boolean;
}

/**
 * @category Component
 * @group List
 */
export const List = forwardRef(function List({ className, disableMargin = false, ...other }: ListProps, ref: any) {
    return <ul ref={ref} className={cx(styles.list, { [styles.list_margin]: !disableMargin }, className)} {...other} />;
});
