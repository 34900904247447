import { ConstraintClient, ConstraintTypeDto } from 'api';
import { createAppAsyncThunk } from 'store/createAppAsyncThunk';
import { selectConstraintAsInputsState } from '../constrainsAsInputsSelector';
import { ConstraintsStateKeyAccessor } from '../types/ConstraintsState';
import { convertConstraintsAsInputsRowsIntoDto } from '../utils';

const service = new ConstraintClient();
export type ConstraintsAsInputsKeyAccessor = {
  keyAccessor: ConstraintsStateKeyAccessor;
};
export const getConstrainsAsInputData = createAppAsyncThunk(
  'constraints-as-inputs/getConstrains',
  ({ keyAccessor }: ConstraintsAsInputsKeyAccessor) =>
    keyAccessor === 'MAIN' ? service.getConstraints() : service.getConstraintsByCaseId(keyAccessor)
);

export const saveConstrainsAsInputData = createAppAsyncThunk(
  'constraints-as-inputs/saveConstrains',
  async ({ keyAccessor }: ConstraintsAsInputsKeyAccessor, { dispatch, getState, rejectWithValue }) => {
    try {
      const state = selectConstraintAsInputsState(getState());
      const dto: ConstraintTypeDto[] = convertConstraintsAsInputsRowsIntoDto(state[keyAccessor].rows);
      const response =
        keyAccessor === 'MAIN' ? await service.save(dto) : await service.saveScenarioConstraints(keyAccessor, dto);

      dispatch(getConstrainsAsInputData({ keyAccessor }));

      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
