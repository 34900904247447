import cx from 'classnames';
import React, { forwardRef, HTMLProps, useContext, useMemo } from 'react';
import { Typography } from '../Typography';
import { CalendarContext } from './CalendarContext';
import { weekdayNamesShort } from './calendarUtils';
import { DayButton, defaultDayWrapper } from './DayButton';
import styles from './Month.module.css';

/**
 * The props for the {@link Month} component.
 * @category Props
 */
interface MonthProps extends HTMLProps<HTMLDivElement> {}

/**
 * @category Component
 * @group Date Picker
 */
export const Month = forwardRef<HTMLDivElement, MonthProps>(({ disabled, className, ...other }, ref) => {
    const instance = useContext(CalendarContext);

    const {
        firstDayOfWeek,
        calendars,
        getDateProps,
        handleDateHover,
        isInRange,
        handleResetHover,
        DayComponent = DayButton,
        DayWrapper = defaultDayWrapper
    } = instance;
    const calendar = calendars[0];

    const RenderDay = useMemo(() => DayWrapper(DayComponent), [DayComponent, DayWrapper]);

    const weekdayNames = useMemo(() => {
        let resut = [...weekdayNamesShort];
        if (firstDayOfWeek > 0) {
            let weekdaysFromFront = resut.splice(0, firstDayOfWeek);
            resut = resut.concat(weekdaysFromFront);
        }

        return resut;
    }, []);

    return (
        <div className={cx(styles.days, className)} {...other} onMouseLeave={handleResetHover}>
            {weekdayNames.map((weekday) => (
                <Typography
                    className={styles.weekday}
                    align="center"
                    variant="caption"
                    color="textSecondary"
                    key={`${calendar.month}${calendar.year}${weekday}`}
                >
                    {weekday}
                </Typography>
            ))}
            {calendar.weeks.map((week, weekIndex) =>
                week.map((dateObj, dayIndex) => {
                    const key = `${calendar.month}${calendar.year}${weekIndex}${dayIndex}`;
                    if (!dateObj) {
                        return <DayButton empty key={key} />;
                    }
                    const { date, selected, selectable, today } = dateObj;
                    return (
                        <RenderDay
                            key={key}
                            disabled={disabled}
                            {...getDateProps({
                                disabled,
                                dateObj,
                                onMouseEnter: handleDateHover.bind(undefined, date),
                                onFocus: handleDateHover.bind(undefined, date)
                            })}
                            selected={selected}
                            unavailable={!selectable}
                            today={today}
                            isInRange={isInRange(date)}
                            date={date}
                        />
                    );
                })
            )}
        </div>
    );
});
