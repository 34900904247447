import { Button, ButtonProps, CircularProgress } from '@mui/material';
import { PropsWithoutRef, forwardRef, useImperativeHandle, useState } from 'react';
import styles from './ProgressButton.module.css';

export interface ProgressButtonProps extends PropsWithoutRef<ButtonProps> {
  loading?: boolean;
}

export type ProgressButtonHandle = {
  setLoading;
};

export const ProgressButton = forwardRef<ProgressButtonHandle, ProgressButtonProps>(function ProgressButton(
  { children, loading: loadingProp, variant = 'text', color = 'primary', size = 'medium', ...other },
  ref
) {
  const [loading, setLoading] = useState(false);

  useImperativeHandle(
    ref,
    () => ({
      setLoading
    }),
    []
  );

  const isLoading = loadingProp ?? loading;

  let progressSize = 18;
  if (size === 'large') {
    progressSize = 30;
  } else if (size === 'medium') {
    progressSize = 24;
  }

  const progressColor = variant === 'contained' ? 'secondary' : color;

  return (
    <Button size={size} color={color} variant={variant} {...other}>
      <span className={styles.wrapper}>
        {isLoading && (
          <span className={styles.loader}>
            <CircularProgress color={progressColor} size={progressSize} />
          </span>
        )}
        <span className={isLoading ? styles.hiddenContent : styles.content}>{children}</span>
      </span>
    </Button>
  );
});
