import { faChevronDown, faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cx from 'classnames';
import React, { ComponentType, CSSProperties, forwardRef, ReactElement, useCallback, useMemo } from 'react';
import ReactSelect, {
    ActionMeta,
    components,
    InputProps,
    OptionProps,
    OptionsType,
    Props as ReactSelectProps,
    SingleValueProps
} from 'react-select';
import CreatableSelect, { CreatableProps } from 'react-select/creatable';
import { IconButton } from '../Button/IconButton';
import styles from './Select.module.css';
import { TextField, TextFieldProps } from './TextField';
import { getValue } from './utils/getValue';

const IndicatorSeparator = () => {
    return null;
};

const Input = ({ className, ...other }: InputProps) => (
    <components.Input className={cx(styles.input, className)} {...other} />
);

const DropdownIndicator = (props) => (
    <components.ClearIndicator {...props}>
        <IconButton size="small">
            <FontAwesomeIcon icon={faChevronDown} />
        </IconButton>
    </components.ClearIndicator>
);

const ClearIndicator = (props) => (
    <components.ClearIndicator {...props}>
        <IconButton size="small">
            <FontAwesomeIcon icon={faXmark} />
        </IconButton>
    </components.ClearIndicator>
);

type Option = { label: string; value: string };
type NewOption = Option & { __isNew__: boolean };

interface SelectFields<T extends object = any> {
    value: string | ((option: T | NewOption) => string);
    label: string | ((option: T | NewOption) => string);
}

const defaultFields: SelectFields = { value: 'Id', label: 'Name' };
const defaultInputProps = {};

interface SelectInputProps<T extends object>
    extends ReactSelectProps<T | NewOption>,
        CreatableProps<Option | NewOption, false> {
    classNamePrefix?: string;
    className?: string;
    styles?: any;
}

export type SelectOptionComponentProps<T extends object> = OptionProps<T | NewOption, false>;
export type SelectValueComponentProps<T extends object> = SingleValueProps<T | NewOption>;

/**
 * The props for the {@link Select} component.
 * @category Props
 */
export interface SelectProps<T extends object> extends Omit<TextFieldProps, 'value' | 'inputProps' | 'onChange'> {
    creatable?: boolean;
    portal?: boolean;
    value?: T;
    onChange: (value: T, action: ActionMeta<T>) => void;
    options: OptionsType<T>;
    fields?: SelectFields<T>;
    optionComponent?: ComponentType<SelectOptionComponentProps<T>>;
    valueComponent?: ComponentType<SelectValueComponentProps<T>>;
    inputProps?: SelectInputProps<T>;
}

function getInputStyles(base: CSSProperties, props: InputProps): CSSProperties {
    return {
        ...base,
        margin: 0,
        padding: 0
    };
}

/**
 * @category Component
 * @group Input
 */
export const Select = forwardRef(function Select<T extends object = any>(
    {
        creatable,
        portal = false,
        disabled,
        fields = defaultFields,
        inputProps = defaultInputProps,
        optionComponent: OptionComponent,
        valueComponent: ValueComponent,
        value,
        onChange,
        ...other
    }: SelectProps<T>,
    ref
) {
    const { className: inputClassName, ...otherInputProps } = inputProps;

    const optionFields = useMemo(() => Object.assign({}, defaultFields, fields), [fields]);

    const getOptionValue = useCallback(
        (option) => {
            if (option.__isNew__) {
                return option.value;
            }

            if (typeof optionFields.value == 'string') {
                return getValue(optionFields.value, option);
            }

            if (typeof optionFields.value == 'function') {
                return optionFields.value(option);
            }

            return undefined;
        },
        [optionFields]
    );

    const getOptionLabel = useCallback(
        (option) => {
            if (option.__isNew__) {
                return option.label;
            }

            if (typeof optionFields.label == 'string') {
                return getValue(optionFields.label, option);
            }

            if (typeof optionFields.label == 'function') {
                return optionFields.label(option);
            }

            return undefined;
        },
        [optionFields]
    );

    const getNewOptionData = useCallback(
        (inputValue, optionLabel) => ({
            label: optionLabel,
            value: inputValue,
            __isNew__: true
        }),
        []
    );

    const optionComponent = useMemo(
        (): ComponentType<OptionProps<T | NewOption, false>> =>
            ({ children, ...other }) =>
                (
                    <components.Option {...other}>
                        {OptionComponent ? <OptionComponent {...other}>{children}</OptionComponent> : children}
                    </components.Option>
                ),
        [OptionComponent]
    );

    const valueComponent = useMemo(
        (): ComponentType<SingleValueProps<T | NewOption>> =>
            ({ children, ...other }) =>
                (
                    <components.SingleValue {...other}>
                        {ValueComponent ? <ValueComponent {...other}>{children}</ValueComponent> : children}
                    </components.SingleValue>
                ),
        [ValueComponent]
    );

    const Components = useMemo(
        () => ({
            IndicatorSeparator,
            ClearIndicator,
            DropdownIndicator,
            Option: optionComponent,
            SingleValue: valueComponent,
            Input
        }),
        [optionComponent, valueComponent]
    );

    const InputProps: SelectInputProps<T> = {
        isDisabled: disabled,
        className: cx(styles.component, inputClassName),
        styles: {
            input: getInputStyles
        },
        getOptionValue,
        getOptionLabel,
        getNewOptionData,
        ...otherInputProps,
        classNamePrefix: 'select',
        components: Components
    };

    if (portal) {
        Object.assign(InputProps, {
            menuPortalTarget: document.body,
            menuPosition: 'absolute',
            styles: { ...InputProps.styles, menuPortal: (base) => ({ ...base, zIndex: 9999 }) }
        });
    }

    const inputComponent = creatable ? CreatableSelect : ReactSelect;

    return (
        <TextField
            ref={ref}
            disabled={disabled}
            inputComponent={inputComponent}
            inputProps={InputProps as any}
            value={value as any}
            onChange={onChange as any}
            {...other}
        />
    );
}) as <T extends object>(props: SelectProps<T> & { ref?: any }) => ReactElement;
