import { DashboardDraResponseDto, DashboardResultClient, IDashboardDraResponseDto } from 'api';
import { OUTLOOK_PLANT } from 'pages/dashboard/outlook/constants';
import { createAppAsyncThunk } from 'store/createAppAsyncThunk';
import { selectDashboardDraByCaseId } from '../draSelectors';
import { CaseDraContent } from '../types/DashboardDraState';
import { DRA_TABLE_ROW } from '../types/DraRow';

const service = new DashboardResultClient();

const getSavedDashboardDraData = (caseDra: CaseDraContent, accessor: OUTLOOK_PLANT, scenarioCaseId: number) => {
  const { rows, dtoByWeekDateId } = caseDra[accessor];

  const hs = new Map<number, IDashboardDraResponseDto>();

  for (const { id, weekDates } of rows) {
    for (const [weekDateId, value] of weekDates) {
      let dto = hs.get(weekDateId);

      if (!dto) {
        const originalDto = dtoByWeekDateId?.get(weekDateId);
        dto = {
          weekDateId,
          scenarioCaseId,
          isKTL: accessor === OUTLOOK_PLANT.KTL,
          isSGP: accessor === OUTLOOK_PLANT.SGP,
          ...originalDto
        };
        hs.set(weekDateId, dto);
      }

      if (id === DRA_TABLE_ROW.DRA) {
        dto.dra = value;
      } else if (id === DRA_TABLE_ROW.LDHI) {
        dto.ldhi = value;
      } else {
        dto.tL14 = value;
      }
    }
  }

  return Array.from(hs.values()).map((dto) => new DashboardDraResponseDto(dto));
};

type UpdateDashboardDraDataParams = {
  accessors: OUTLOOK_PLANT[];
  scenarioCaseId: number;
};

export const updateDashboardDraData = createAppAsyncThunk(
  'dashboard/dra/save',
  async ({ accessors, scenarioCaseId }: UpdateDashboardDraDataParams, { getState }) => {
    const state = getState();
    const caseDra = selectDashboardDraByCaseId(scenarioCaseId)(state);
    const dtos = accessors.flatMap((accessor) => getSavedDashboardDraData(caseDra, accessor, scenarioCaseId));

    return service.upsertDashboardDra(dtos);
  }
);
