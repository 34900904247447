import cx from 'classnames';
import React, { forwardRef, HTMLProps, ReactNode, Ref } from 'react';
import styles from './ButtonBase.module.css';

/**
 * The props for the {@link ButtonBase} component
 */
export interface ButtonBaseProps extends Omit<HTMLProps<HTMLButtonElement>, 'size'> {
    ref?: Ref<HTMLButtonElement>;
    buttonRef?: Ref<unknown>;
    children?: ReactNode;
    disabled?: boolean;
    component?: any;
}

/**
 * @category Component
 * @group Button
 */
export const ButtonBase = forwardRef<HTMLButtonElement, ButtonBaseProps>(function ButtonBase(props, ref) {
    const { children, className, component = 'button', disabled = false, tabIndex = 0, ...other } = props;

    let ComponentProp = component;

    if (ComponentProp === 'button' && other.href) {
        ComponentProp = 'a';
    }

    const buttonProps: HTMLProps<HTMLButtonElement> = {};
    if (ComponentProp === 'button') {
        buttonProps.type = other.type === undefined ? 'button' : other.type;
        buttonProps.disabled = disabled;
    } else {
        if (ComponentProp !== 'a' || !other.href) {
            buttonProps.role = 'button';
        }
        buttonProps['aria-disabled'] = disabled;
    }

    return (
        <ComponentProp
            className={cx(
                styles.root,
                {
                    [styles.disabled]: disabled,
                },
                className
            )}
            ref={ref}
            tabIndex={disabled ? -1 : tabIndex}
            {...buttonProps}
            {...other}
        >
            {children}
        </ComponentProp>
    );
});
