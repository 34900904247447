import cx from 'classnames';
import React, { ComponentProps, ElementType, forwardRef, ReactElement, Ref, RefAttributes } from 'react';
import { capitalize } from '../util';
import styles from './Typography.module.css';

/**
 * Typography variants
 * @group Typography
 */
type TypographyVariants =
    | 'h1'
    | 'h2'
    | 'h3'
    | 'h4'
    | 'h5'
    | 'h6'
    | 'subtitle1'
    | 'subtitle2'
    | 'body1'
    | 'body2'
    | 'overline'
    | 'caption'
    | 'button'
    | 'inherit';

/**
 * @param {string} variant - The variant to use.
 * @group Typography
 */
interface TypographyVariantMapping {
    [variantName: string]: string;
}

/**
 * @group Typography
 */
const defaultVariantMapping: TypographyVariantMapping = {
    h1: 'h1',
    h2: 'h2',
    h3: 'h3',
    h4: 'h4',
    h5: 'h5',
    h6: 'h6',
    subtitle1: 'h6',
    subtitle2: 'h6',
    body1: 'p',
    body2: 'p',
    overline: 'p',
    inherit: 'p',
};

/**
 * This is props for {@link Typography} component
 * @category Props
 */
export type TypographyProps<TComponent extends ElementType = 'p'> = ComponentProps<TComponent> & {
    ref?: Ref<HTMLParagraphElement>;
    align?: 'left' | 'center' | 'right' | 'justify' | 'inherit';
    color?: 'primary' | 'secondary' | 'textPrimary' | 'textSecondary' | 'error' | 'initial' | 'inherit';
    component?: TComponent;
    display?: 'inline' | 'block' | 'initial';
    gutterBottom?: boolean;
    noWrap?: boolean;
    paragraph?: boolean;
    variant?: TypographyVariants;
    variantMapping?: TypographyVariantMapping;
    highlight?: boolean;
};

/**
 * @category Component
 * @group Typography
 */
export const Typography = forwardRef(function Typography<TComponent extends ElementType = 'p'>(
    {
        align = 'inherit',
        className,
        color = 'initial',
        component,
        display = 'initial',
        gutterBottom = false,
        noWrap = false,
        paragraph = false,
        variant = 'body2',
        highlight,
        variantMapping = defaultVariantMapping,
        ...other
    }: TypographyProps<TComponent>,
    ref
) {
    const Component =
        component || (paragraph ? 'p' : variantMapping[variant] || defaultVariantMapping[variant]) || 'span';

    return (
        <Component
            className={cx(
                styles.root,
                {
                    [styles[variant]]: variant,
                    [styles[`color${capitalize(color)}`]]: color !== 'initial',
                    [styles.noWrap]: noWrap,
                    [styles.gutterBottom]: gutterBottom,
                    [styles.paragraph]: paragraph,
                    [styles.highlight]: highlight,
                    [styles[`align${capitalize(align)}`]]: align !== 'inherit',
                    [styles[`display${capitalize(display)}`]]: display !== 'initial',
                },
                className
            )}
            ref={ref}
            {...other}
        />
    );
}) as <TComponent extends ElementType = 'p'>(
    props: TypographyProps<TComponent> & RefAttributes<HTMLParagraphElement>
) => ReactElement;
