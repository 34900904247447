import App from 'App';
import AboutPage from 'pages/about';
import { dashboardRoute } from 'pages/dashboard/routes';
import Error404 from 'pages/errors/Error404';
import HelpPage from 'pages/help';
import StartPage from 'pages/home';
import { officialInputsRoutes } from 'pages/official-inputs/routes';
import { scenariosRoutes } from 'pages/scenarios/routes';
import { settingsRoutes } from 'pages/settings/routes';
import { simulationRoutes } from 'pages/simulation/routes';
import { RouteObject } from 'react-router-dom';

export const routes: RouteObject[] = [
  {
    path: '/',
    element: <App />,
    children: [
      {
        index: true,
        element: <StartPage />
      },
      ...officialInputsRoutes,
      ...scenariosRoutes,
      ...simulationRoutes,
      dashboardRoute,
      ...settingsRoutes,
      {
        path: 'help',
        element: <HelpPage />
      },
      {
        path: 'about',
        element: <AboutPage />
      },
      {
        path: '*',
        element: <Error404 />
      }
    ]
  }
];
