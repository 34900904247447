import { PropsWithChildren, useEffect, useMemo } from 'react';

import { ColumnDisabledContext } from 'components/common/DataGrid/DisabledWeekDateColumns/ColumnDisabledContext';

import { useAppDispatch, useAppSelector } from 'store';
import { selectDeactivatedTimesteps } from 'store/cases/CasesSelector';
import { casesActions } from 'store/cases/CasesSlice';
import { getDeactivatedTimesteps } from 'store/cases/actions/deactivatedTimesteps';

import { useScenarioCaseIdParams } from 'utils/useParams';

export const ColumnDisabledProvider = ({ children }: PropsWithChildren) => {
  const { caseId } = useScenarioCaseIdParams();
  const dispatch = useAppDispatch();
  const deactivatedTimesteps = useAppSelector(selectDeactivatedTimesteps);

  useEffect(() => {
    dispatch(getDeactivatedTimesteps({ scenarioCaseId: caseId }));
  }, [caseId, dispatch]);

  const value = useMemo(
    () => ({
      map: deactivatedTimesteps,
      reset(map: Map<number, boolean>) {
        dispatch(casesActions.resetDeactivatedTimestep(map));
      }
    }),
    [deactivatedTimesteps, dispatch]
  );

  return <ColumnDisabledContext.Provider value={value}>{children}</ColumnDisabledContext.Provider>;
};
