import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';

const reactPlugin = new ReactPlugin();
const appInsights = new ApplicationInsights({
  config: {
    instrumentationKey: process.env.REACT_APP_APP_INSIGHTS_KEY,
    extensions: [reactPlugin],
    disableAjaxTracking: true,
    disableFetchTracking: true
  }
});

appInsights.loadAppInsights();

export { appInsights };
