import { Group } from '@visx/group';
import React, { FC, memo, ReactElement, useContext, useMemo, cloneElement } from 'react';
import { ChartContext } from '../ChartContext';
import { SeriesOptions, StackedGroupOptions } from '../chartTypes';
import { getChildSeries, getStackedData } from '../utils';

import { BarSeriesProps } from './BarSeries';

interface StackedBarGroupProps<T extends object> extends Omit<SeriesOptions<T>, 'data'>, StackedGroupOptions {
    id: string;
    children: ReactElement[];
}

/**
 * @category Component
 * @group Chart
 */
let StackedBarGroup = <T extends object>(props: StackedBarGroupProps<T>) => {
    const { id, disableMouseEvents, children, ...other } = props;
    const { legend, getX: globalGetX, getY: globalGetY } = useContext(ChartContext);

    const [childSeries, restChilds] = useMemo(() => getChildSeries(children), [children]);

    const series = useMemo(
        () => getStackedData<BarSeriesProps<any>>(props, childSeries, legend, globalGetX, globalGetY),
        [childSeries, globalGetX, globalGetY, legend, props]
    );

    return (
        <Group>
            {restChilds}
            {series.map(([Child], index) =>
                cloneElement(Child, {
                    id: Child.props.id ?? `${id}-child-series-${index}`,
                    key: `${id}-child-series-${index}`,
                    disableMouseEvents: Child.props.disableMouseEvents || disableMouseEvents,
                    ...other,
                })
            )}
        </Group>
    );
};

(StackedBarGroup as FC).displayName = 'StackedBarGroup';
StackedBarGroup = memo(StackedBarGroup);

export { StackedBarGroup };
