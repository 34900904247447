import React, { FC, memo, useCallback, useContext } from 'react';
import { ChartContext } from '../ChartContext';
import { findClosestPoints } from '../utils';

/**
 * @category Component
 * @group Chart
 */
let RenderContainer: FC = () => {
    const instance = useContext(ChartContext);

    const {
        legend,
        innerHeight,
        innerWidth,
        children,
        getX,
        getY,
        xScales,
        yScales,
        margin,
        groupDimensions,
        handleClick,
        handleMouseDown: globalOnMouseDown,
        handleMouseMove,
        handleMouseLeave: globalOnMouseLeave,
    } = instance;

    const handleContainerEvent = useCallback(
        (event) => {
            const closestPoints = findClosestPoints({
                legend,
                children,
                event,
                getX,
                getY,
                xScales,
                yScales,
                margin,
                groupDimensions,
            });

            if (closestPoints?.point || Object.keys(closestPoints?.series ?? {}).length > 0) {
                event.persist();
                const args = {
                    event,
                    ...closestPoints,
                };
                if (event.type === 'mousemove') {
                    handleMouseMove(args);
                } else if (event.type === 'click') {
                    handleClick(args);
                }
            }
        },
        [children, getX, getY, groupDimensions, handleClick, handleMouseMove, legend, margin, xScales, yScales]
    );

    const handleMouseDown = useCallback(
        (event) => {
            const closesPoints = findClosestPoints({
                legend,
                children,
                event,
                getX,
                getY,
                xScales,
                yScales,
                margin,
                groupDimensions,
            });

            if (closesPoints?.point || Object.keys(closesPoints?.series ?? {}).length > 0) {
                event.persist();
                const args = {
                    ...closesPoints,
                    event,
                };

                globalOnMouseDown(args);
            }
        },
        [children, getX, getY, globalOnMouseDown, groupDimensions, legend, margin, xScales, yScales]
    );

    const handleMouseLeave = useCallback(
        (event) => {
            globalOnMouseLeave(event);
        },
        [globalOnMouseLeave]
    );

    return (
        <rect
            x={0}
            y={0}
            width={innerWidth}
            height={innerHeight}
            fill="transparent"
            fillOpacity={0}
            onMouseDown={handleMouseDown}
            onClick={handleContainerEvent}
            onMouseMove={handleContainerEvent}
            onMouseLeave={handleMouseLeave}
        />
    );
};

RenderContainer = memo(RenderContainer);

export { RenderContainer };
