import { Accessor } from '@visx/shape/lib/types';
import { groupBy } from 'lodash';
import moment from 'moment';
import { Children, ReactElement, ReactNode, useMemo } from 'react';
import { isElement, isFragment } from 'react-is';
import { SeriesOptions } from '..';
import { ChartInstance, Scale, ScaleParams } from '../chartTypes';
import { BarSeriesProps } from '../series/BarSeries';
import { componentName, isBarSeries, isCirclePackSeries, isXAxis } from './chartUtils';
import { AllData, DataPoint, xMaxAccessor, xMinAccessor, yMaxAccessor, yMinAccessor } from './getDataFromChildSeries';
import { getScaleForAccessor } from './getScaleForAccessor';
import { GroupDimensions } from './useGroupDimensions';
import { AxisGroups } from './useLayersGroups';

export function getScalesFromProps(
    allData: AllData,
    AxisGroups: AxisGroups,
    groupDimensions: GroupDimensions,
    children: ChartInstance['children'],
    xScale?: ScaleParams,
    yScale?: ScaleParams,
    brush?: ChartInstance['brush']['state'],
    zoomTransformMatrix?: ChartInstance['zoom']['transformMatrix']
) {
    const groupedData = groupBy(allData, 'axis');
    const xScales: Record<string, Scale> = {};
    const yScales: Record<string, Scale> = {};

    for (const group in AxisGroups) {
        const data = group === 'undefined' ? allData : groupedData[group];
        const list = AxisGroups[group];
        for (const axis of list) {
            const name = componentName(axis);
            if (isXAxis(name)) {
                const {
                    size: { width }
                } = groupDimensions[group];
                const xDomain = brush?.['x']?.[group] ?? brush?.['x']?.['undefined'];
                const yDomain = !xDomain ? brush?.['y']?.[group] ?? brush?.['y']?.['undefined'] : undefined;

                let scale = getScaleForAccessor({
                    data,
                    minAccessor: xMinAccessor,
                    maxAccessor: xMaxAccessor,
                    range: [0, width],
                    domain: xDomain ? Object.values(xDomain) : undefined,
                    filterDomain: yDomain,
                    filterMinAccessor: yMinAccessor,
                    filterMaxAccessor: yMaxAccessor,
                    ...xScale!,
                    ...axis?.props?.scaleParams
                });

                if (zoomTransformMatrix) {
                    const newDomain = scale.range().map((r) => {
                        return scale.invert((r - zoomTransformMatrix.translateX) / zoomTransformMatrix.scaleX);
                    });

                    scale = scale.copy().domain(newDomain);
                }

                xScales[group] = scale;
            } else {
                const {
                    size: { height }
                } = groupDimensions[group];

                const yDomain = brush?.['y']?.[group] ?? brush?.['y']?.['undefined'];
                const xDomain = !yDomain ? brush?.['x']?.[group] ?? brush?.['x']?.['undefined'] : undefined;

                let scale = getScaleForAccessor({
                    data,
                    minAccessor: yMinAccessor,
                    maxAccessor: yMaxAccessor,
                    range: [height, 0],
                    domain: yDomain ? Object.values(yDomain) : undefined,
                    filterDomain: xDomain,
                    filterMinAccessor: xMinAccessor,
                    filterMaxAccessor: xMaxAccessor,
                    ...yScale!,
                    ...axis?.props?.scaleParams
                });

                if (zoomTransformMatrix) {
                    const newDomain = scale.range().map((r) => {
                        return scale.invert((r - zoomTransformMatrix.translateY) / zoomTransformMatrix.scaleY);
                    });

                    scale = scale.copy().domain(newDomain);
                }

                yScales[group] = scale;
            }
        }
    }

    const barSeriesGroups: Record<string, ReactElement<SeriesOptions>[]> = {};

    function collectBarSeries(Child: ReactNode) {
        if (Array.isArray(Child)) {
            Children.forEach(Child, collectBarSeries);
            return;
        }

        if (isFragment(Child)) {
            Children.forEach(Child.props.children, collectBarSeries);
            return;
        }

        const name = componentName(Child);
        if (!isBarSeries(name) || !isElement(Child)) {
            return;
        }

        const { axis } = Child.props;
        if (!barSeriesGroups[axis]) {
            barSeriesGroups[axis] = [];
        }

        barSeriesGroups[axis].push(Child);
    }
    Children.forEach(children, collectBarSeries);

    /** Child-specific scales or adjustments here */
    function adjustScales(Child: ReactNode) {
        if (Array.isArray(Child)) {
            Children.forEach(Child, adjustScales);
            return;
        }

        if (isFragment(Child)) {
            Children.forEach(Child.props.children, adjustScales);
            return;
        }

        const name = componentName(Child);

        if (isElement(Child) && isBarSeries(name)) {
            const { id, axis, horizontal } = Child.props as BarSeriesProps<any>;
            const groupName = axis ?? 'undefined';

            const index = barSeriesGroups[groupName].findIndex((x) => x.props.id === id);

            const x: Accessor<DataPoint, any> = (d) => d.x;
            const y: Accessor<DataPoint, any> = (d) => d.y;

            const categoryScaleObject = horizontal ? yScale : xScale;

            const [categoryScale, categoryAxis] = horizontal
                ? yScales[groupName]
                    ? [yScales[groupName], groupName]
                    : [yScales['undefined'], 'undefined']
                : xScales[groupName]
                ? [xScales[groupName], groupName]
                : [xScales['undefined'], 'undefined'];

            const {
                size: { height, width }
            } = groupDimensions[groupName];
            const range = horizontal ? height : width;
            const data = groupedData[categoryAxis ?? 'undefined'];
            const dummyBand: Scale = getScaleForAccessor({
                data,
                minAccessor: horizontal ? y : x,
                maxAccessor: horizontal ? y : x,
                type: 'band',
                domain: horizontal ? data?.map(y) : data?.map(x) ?? categoryScale?.domain?.(),
                range: categoryScale?.range?.(),
                rangeRound: [0, range],
                paddingInner: 0.2,
                paddingOuter: 0,
                align: categoryScale?.align?.()
            });

            if (categoryScale) {
                if (!categoryScale.barIndexes) {
                    categoryScale.barIndexes = {};
                }

                if (!categoryScale.barIndexes[groupName]) {
                    categoryScale.barIndexes[groupName] = {};
                }

                categoryScale.barIndexes[groupName][id] = index;
                categoryScale.barIndexes[groupName].length = barSeriesGroups[groupName].length;

                if (categoryScaleObject?.type === 'time' || categoryScaleObject?.type === 'timeUtc') {
                    categoryScale.barWidth =
                        categoryScale(moment().startOf('day').toDate()) -
                        categoryScale(moment().add(-1, 'day').startOf('day').toDate());
                    categoryScale.barWidth = Math.max(categoryScale.barWidth, 1);
                } else {
                    categoryScale.barWidth = dummyBand.bandwidth() / barSeriesGroups[groupName].length;
                    categoryScale.barOffset = categoryScaleObject?.type !== 'band' ? -(categoryScale.barWidth / 2) : 0;
                    categoryScale.barPadding = (dummyBand.step() * dummyBand.paddingInner()) / 2;
                    categoryScale.barPadding =
                        dummyBand.step() * dummyBand.paddingOuter() * dummyBand.align() * 2 + categoryScale.barPadding;
                }
            }
        }

        if (isElement(Child) && isCirclePackSeries(name)) {
            const { axis } = Child.props;
            const {
                size: { height }
            } = groupDimensions[axis];
            const scale = yScales[axis] ?? yScales['undefined'];
            scale.domain([-height / 2, height / 2]);
        }
    }

    Children.forEach(children, adjustScales);

    return {
        xScales,
        yScales
    };
}

export function useScalesFromChild(...args: Parameters<typeof getScalesFromProps>) {
    return useMemo(
        () => getScalesFromProps(...args),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [...args]
    );
}
