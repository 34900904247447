import cx from 'classnames';
import React, { DetailedHTMLProps, forwardRef } from 'react';
import styles from './DayButton.module.css';

/**
 * The props for the {@link DayButton} component.
 * @category Props
 */
export interface DayButtonProps
    extends DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> {
    date?: Date;
    empty?: boolean;
    selected?: boolean;
    unavailable?: boolean;
    today?: boolean;
    isInRange?: boolean;
}

/**
 * @category Component
 * @group Date Picker
 */
export const DayButton = forwardRef<HTMLButtonElement, DayButtonProps>(
    ({ disabled, className, today, empty, date, selected, unavailable, isInRange, ...other }, ref) => {
        return (
            <button
                ref={ref as any}
                className={cx(
                    styles.root,
                    {
                        [styles.disabled]: disabled,
                        [styles.today]: today,
                        [styles.inRange]: isInRange,
                        [styles.selected]: selected,
                        [styles.unavailable]: unavailable,
                        [styles.empty]: empty
                    },
                    className
                )}
                {...other}
            >
                {date?.getDate()}
            </button>
        );
    }
);

export function defaultDayWrapper(dayComponent: typeof DayButton): typeof DayButton {
    return dayComponent;
}

export type DayWrapper = typeof defaultDayWrapper;
