import { FilteredScenarioDto, ScenarioDto } from 'api';
import { BaseState } from 'store/base/BaseState';

export interface IScenariosState {
  scenarios: BaseState<ScenarioDto[]>;
  filteredScenarios: BaseState<FilteredScenarioDto[]>;
  publishScenarios: number[];
  expandedScenarioIds: number[];
}

export const scenariosInitialState: IScenariosState = {
  scenarios: {
    data: []
  },
  filteredScenarios: {
    data: []
  },
  publishScenarios: [],
  expandedScenarioIds: []
};
