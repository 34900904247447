import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { getObjectFromLocalstorage } from 'utils/getObjectFromLocalstorage';
import { OfficialInputSharedState } from './OfficialInputPageState';

const hiddenWeekDaysKey = 'hiddenWeekDays';
const hiddenIds = getObjectFromLocalstorage(hiddenWeekDaysKey, []);
const initialState: OfficialInputSharedState = {
  hiddenWeekDateIds: new Set(hiddenIds)
};

export const { actions: officialInputPageActions, reducer: officialInputPageReducer } = createSlice({
  initialState,
  name: 'official-inputs-page-shared',
  reducers: {
    toggleHiddenWeekDateIds(state, action: PayloadAction<number[]>) {
      action.payload.forEach((weekDateId) => {
        if (state.hiddenWeekDateIds.has(weekDateId)) {
          state.hiddenWeekDateIds.delete(weekDateId);
        } else {
          state.hiddenWeekDateIds.add(weekDateId);
        }
      });

      const hiddenWeekDays = Array.from(state.hiddenWeekDateIds);
      localStorage.setItem(hiddenWeekDaysKey, JSON.stringify(hiddenWeekDays));
    }
  }
});
