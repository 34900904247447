import { useCallback, useMemo, useState } from 'react';
import { ChartMenuParams } from '../chartTypes';

export function useChartMenu(): ChartMenuParams {
    const [args, setArgs] = useState<ChartMenuParams['args']>();

    const showContextMenu = useCallback((props: ChartMenuParams['args']) => {
        setArgs(props);
    }, []);

    const hideContextMenu = useCallback(() => {
        setArgs(undefined);
    }, []);

    return useMemo(
        () => ({
            open: args !== undefined,
            args,
            showContextMenu,
            hideContextMenu
        }),
        [args]
    );
}
