import { IWedgeLineResultDto } from 'api';
import { ResultByWeekDay } from '../../dashboard-wedge/types/DashboardWedgeState';

export function convertWedgeLineData(wedgeLineResults: IWedgeLineResultDto[]) {
  const weekDates = new Map<number, ResultByWeekDay>();

  for (const wedgeLineResult of wedgeLineResults || []) {
    const { weekDate, result, weekDateId } = wedgeLineResult;
    weekDates.set(weekDateId, {
      weekDate,
      weekDateId,
      result: Math.round(result)
    });
  }

  return weekDates;
}
