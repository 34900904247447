import React, {
    cloneElement,
    forwardRef,
    ReactNode,
    Ref,
    useCallback,
    useEffect,
    useImperativeHandle,
    useRef,
} from 'react';
import { findDOMNode } from 'react-dom';

import { getScrollbarSize, ownerDocument, useForkRef } from '../util';

import { List } from '../List';
import { ListProps } from '../List/List';

function nextItem(list, item, disableListWrap) {
    if (list === item) {
        return list.firstChild;
    }
    if (item && item.nextElementSibling) {
        return item.nextElementSibling;
    }
    return disableListWrap ? null : list.firstChild;
}

function previousItem(list, item, disableListWrap) {
    if (list === item) {
        return disableListWrap ? list.firstChild : list.lastChild;
    }
    if (item && item.previousElementSibling) {
        return item.previousElementSibling;
    }
    return disableListWrap ? null : list.lastChild;
}

function textCriteriaMatches(nextFocus, textCriteria) {
    if (textCriteria === undefined) {
        return true;
    }
    let text = nextFocus.innerText;
    if (text === undefined) {
        // jsdom doesn't support innerText
        text = nextFocus.textContent;
    }
    text = text.trim().toLowerCase();
    if (text.length === 0) {
        return false;
    }
    if (textCriteria.repeating) {
        return text[0] === textCriteria.keys[0];
    }
    return text.indexOf(textCriteria.keys.join('')) === 0;
}

function moveFocus(
    list,
    currentFocus,
    disableListWrap,
    disabledItemsFocusable,
    traversalFunction,
    textCriteria = undefined
) {
    let wrappedOnce = false;
    let nextFocus = traversalFunction(list, currentFocus, currentFocus ? disableListWrap : false);

    while (nextFocus) {
        // Prevent infinite loop.
        if (nextFocus === list.firstChild) {
            if (wrappedOnce) {
                return;
            }
            wrappedOnce = true;
        }

        // Same logic as useAutocomplete.js
        const nextFocusDisabled = disabledItemsFocusable
            ? false
            : nextFocus.disabled || nextFocus.getAttribute('aria-disabled') === 'true';

        if (!nextFocus.hasAttribute('tabindex') || !textCriteriaMatches(nextFocus, textCriteria) || nextFocusDisabled) {
            // Move to the next element.
            nextFocus = traversalFunction(list, nextFocus, disableListWrap);
        } else {
            nextFocus.focus();
            return;
        }
    }
}

/**
 * The props of the {@link MenuList} component.
 * @category Props
 */
export interface MenuListProps extends ListProps {
    actions: Ref<{
        adjustStyleForScrollbar?(container);
    }>;
    autoFocus?: boolean;
    autoFocusItem?: boolean;
    children?: ReactNode;
    disabledItemsFocusable?: boolean;
    disableListWrap?: boolean;
    variant?: 'menu' | 'selectedMenu';
}

/**
 * @category Component
 * @group Menu
 */
export const MenuList = forwardRef(function MenuList(props: MenuListProps, ref) {
    const {
        actions,
        autoFocus = false,
        autoFocusItem = false,
        children,
        className,
        disabledItemsFocusable = false,
        disableListWrap = false,
        onKeyDown,
        variant = 'selectedMenu',
        ...other
    } = props;
    const listRef = useRef(null);
    const textCriteriaRef = useRef({
        keys: [],
        repeating: true,
        previousKeyMatched: true,
        lastTime: null,
    });

    useEffect(() => {
        if (autoFocus) {
            listRef.current.focus();
        }
    }, [autoFocus]);

    useImperativeHandle(
        actions,
        () => ({
            adjustStyleForScrollbar: (containerElement) => {
                const noExplicitWidth = !listRef.current.style.width;
                if (containerElement.clientHeight < listRef.current.clientHeight && noExplicitWidth) {
                    const scrollbarSize = `${getScrollbarSize(document)}px`;
                    listRef.current.style['paddingLeft'] = scrollbarSize;
                    listRef.current.style.width = `calc(100% + ${scrollbarSize})`;
                }
                return listRef.current;
            },
        }),
        []
    );

    const handleKeyDown = (event) => {
        const list = listRef.current;
        const key = event.key;

        const currentFocus = ownerDocument(list).activeElement;

        if (key === 'ArrowDown') {
            event.preventDefault();
            moveFocus(list, currentFocus, disableListWrap, disabledItemsFocusable, nextItem);
        } else if (key === 'ArrowUp') {
            event.preventDefault();
            moveFocus(list, currentFocus, disableListWrap, disabledItemsFocusable, previousItem);
        } else if (key === 'Home') {
            event.preventDefault();
            moveFocus(list, null, disableListWrap, disabledItemsFocusable, nextItem);
        } else if (key === 'End') {
            event.preventDefault();
            moveFocus(list, null, disableListWrap, disabledItemsFocusable, previousItem);
        } else if (key.length === 1) {
            const criteria = textCriteriaRef.current;
            const lowerKey = key.toLowerCase();
            const currTime = performance.now();
            if (criteria.keys.length > 0) {
                if (currTime - criteria.lastTime > 500) {
                    criteria.keys = [];
                    criteria.repeating = true;
                    criteria.previousKeyMatched = true;
                } else if (criteria.repeating && lowerKey !== criteria.keys[0]) {
                    criteria.repeating = false;
                }
            }
            criteria.lastTime = currTime;
            criteria.keys.push(lowerKey);
            const keepFocusOnCurrent =
                currentFocus && !criteria.repeating && textCriteriaMatches(currentFocus, criteria);
            if (
                criteria.previousKeyMatched &&
                (keepFocusOnCurrent || moveFocus(list, currentFocus, false, disabledItemsFocusable, nextItem, criteria))
            ) {
                event.preventDefault();
            } else {
                criteria.previousKeyMatched = false;
            }
        }

        if (onKeyDown) {
            onKeyDown(event);
        }
    };

    const handleOwnRef = useCallback((instance) => {
        // eslint-disable-next-line react/no-find-dom-node
        listRef.current = findDOMNode(instance);
    }, []);
    const handleRef = useForkRef(handleOwnRef, ref);

    let activeItemIndex = -1;

    React.Children.forEach(children, (child, index) => {
        if (!React.isValidElement(child)) {
            return;
        }

        if (!child.props.disabled) {
            if (variant === 'selectedMenu' && child.props.selected) {
                activeItemIndex = index;
            } else if (activeItemIndex === -1) {
                activeItemIndex = index;
            }
        }
    });

    const items = React.Children.map(children, (child: any, index) => {
        if (index === activeItemIndex) {
            const newChildProps: any = {};
            if (autoFocusItem) {
                newChildProps.autoFocus = true;
            }
            if (child.props.tabIndex === undefined && variant === 'selectedMenu') {
                newChildProps.tabIndex = 0;
            }

            return cloneElement(child, newChildProps);
        }

        return child;
    });

    return (
        <List
            role="menu"
            ref={handleRef}
            className={className}
            onKeyDown={handleKeyDown}
            tabIndex={autoFocus ? 0 : -1}
            {...other}
        >
            {items}
        </List>
    );
});
