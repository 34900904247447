import React, { FC, SyntheticEvent, useCallback, useRef } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { faEllipsisH, faEllipsisV } from '@fortawesome/free-solid-svg-icons';
import { IconButton, IconButtonProps } from '../Button/IconButton';

/**
 * The props of the {@link ContextMenuButton} component.
 * @category Props
 */
interface ContextMenuButtonProps extends IconButtonProps {
    variant?: 'vertical' | 'horizontal';
}

/**
 * @category Component
 * @group Context Menu
 */
export const ContextMenuButton: FC<ContextMenuButtonProps> = ({ variant = 'vertical', onClick, ...other }) => {
    const ref = useRef<HTMLDivElement>(null);

    const handleClick = useCallback(
        (event: SyntheticEvent<HTMLDivElement, MouseEvent>) => {
            event.preventDefault();
            event.stopPropagation();

            ref.current.dispatchEvent(
                new MouseEvent('contextmenu', {
                    view: window,
                    bubbles: true,
                    cancelable: true
                })
            );

            if (onClick) {
                onClick(event as any);
            }
        },
        [ref, onClick]
    );

    return (
        <IconButton ref={ref as any} size="small" {...other} onClick={handleClick as any}>
            <FontAwesomeIcon icon={variant == 'vertical' ? faEllipsisV : faEllipsisH} />
        </IconButton>
    );
};
