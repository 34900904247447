import React, { FC, memo, PropsWithChildren, useCallback, useContext, useMemo } from 'react';
import { unstable_batchedUpdates } from 'react-dom';
import { DragEndHandler } from '../../util';
import { DataGridContext } from '../DataGridContext';
import { useDataGridBodyEvent } from '../hooks/useDataGridBodyEvent';
import { DataGridRowProps } from '../types';

import {
    RenderDataGridList,
    RenderDataGridSortableList,
    RenderDataGridSortableVirtualList,
    RenderDataGridVirtualList
} from './RenderList';
import { defaultDataGridRowWrapper, RenderDataGridRow, RenderDataGridSortableRow } from './RenderRow';

export interface RenderDataGridBodyProps {
    height?: number;
    width?: number;
}

export const RenderDataGridBody: FC<PropsWithChildren<RenderDataGridBodyProps>> = memo(
    ({ height: heightProp, width: widthProp }) => {
        const instance = useContext(DataGridContext);
        const { settings, rows, page, events, selectedFlatRows } = instance;
        const {
            RowWrapper = defaultDataGridRowWrapper,
            virtualScrolling,
            pagination,
            dragAndDrop,
            rowHeight,
            onDrag,
            height,
            width,
            initialScroll
        } = settings;

        const handleRef = useDataGridBodyEvent();

        const handleVirtualScroll = useCallback(
            (event) => {
                events.emit('virtualScroll', event);
            },
            [events]
        );

        const source = pagination ? page : rows;

        const handleDrag: DragEndHandler = useCallback(
            (result, provided) => {
                if (result.source.index == result.destination.index) {
                    return;
                }

                const row = source[result.source.index] as DataGridRowProps<any>;

                const selected = row.getIsSelected();
                unstable_batchedUpdates(() => {
                    if (selected) {
                        onDrag({ items: selectedFlatRows.map((x) => x.original), ...result }, provided);
                    } else {
                        onDrag({ items: [row.original], ...result }, provided);
                    }
                });
            },
            [onDrag, selectedFlatRows, source]
        );

        const sortableRow = useMemo(() => RowWrapper(RenderDataGridSortableRow as any), [RowWrapper]);

        const row = useMemo(() => RowWrapper(RenderDataGridRow), [RowWrapper]);

        return virtualScrolling ? (
            dragAndDrop ? (
                <RenderDataGridSortableVirtualList
                    initialScrollOffset={initialScroll?.top}
                    outerRef={handleRef}
                    height={heightProp ?? height}
                    width={widthProp ?? width}
                    items={source}
                    itemCount={source.length}
                    itemSize={rowHeight}
                    onScroll={handleVirtualScroll}
                    onDragEnd={handleDrag}
                    RowRenderer={row}
                >
                    {sortableRow}
                </RenderDataGridSortableVirtualList>
            ) : (
                <RenderDataGridVirtualList
                    initialScrollOffset={initialScroll?.top}
                    outerRef={handleRef}
                    height={heightProp ?? height}
                    width={widthProp ?? width}
                    items={source}
                    itemCount={source.length}
                    itemSize={rowHeight}
                    onScroll={handleVirtualScroll}
                >
                    {row}
                </RenderDataGridVirtualList>
            )
        ) : dragAndDrop ? (
            <RenderDataGridSortableList ref={handleRef} items={source} onDragEnd={handleDrag} RowRenderer={row}>
                {sortableRow}
            </RenderDataGridSortableList>
        ) : (
            <RenderDataGridList items={source}>{row}</RenderDataGridList>
        );
    }
);
