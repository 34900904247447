import { LineupClient } from 'api';
import { ForceableQuery } from 'domain/QueryParams';
import { createAppAsyncThunk } from 'store/createAppAsyncThunk';
import { lineupStateSelector } from '../lineupSelectors';

const service = new LineupClient();

type LineupRequest = ForceableQuery & {
  searchText?: string;
};

export const getLineup = createAppAsyncThunk(
  'lineup/get',
  ({ searchText }: LineupRequest) => service.getAll(searchText),
  {
    condition: (arg, { getState }) => {
      if (arg.force) {
        return true;
      }

      const lineup = lineupStateSelector(getState());

      if (!lineup.loading && lineup.data === undefined) {
        return true;
      }

      return false;
    }
  }
);
