import { createSlice, PayloadAction, Reducer } from '@reduxjs/toolkit';
import { ScenarioClient } from 'api';
import { handleError, handleLoading, handleSuccess } from 'store/base/baseStateHandlers';
import { createAppAsyncThunk } from 'store/createAppAsyncThunk';
import { scenariosInitialState } from './scenariosInitialState';

const service = new ScenarioClient();

export const getScenarios = createAppAsyncThunk('scenarios/loadScenarios', () => service.getScenarios());
export const fetchFilteredScenariosData = createAppAsyncThunk(
  'scenarios/loadFilteredScenarios',
  (searchText?: string) => service.getFilteredScenarios(searchText)
);

const { actions, reducer } = createSlice({
  initialState: scenariosInitialState,
  name: 'scenarios',
  reducers: {
    setPublishScenario: (state, action: PayloadAction<number>) => {
      state.publishScenarios.push(action.payload);
    },
    expandScenario: (state, action: PayloadAction<number>) => {
      if (!state.expandedScenarioIds.includes(action.payload)) {
        state.expandedScenarioIds.push(action.payload);
      }
    },
    collapseScenario: (state, action: PayloadAction<number>) => {
      if (state.expandedScenarioIds.includes(action.payload)) {
        state.expandedScenarioIds = state.expandedScenarioIds.filter((id) => id !== action.payload);
      }
    },
    resetPublishScenario: (state) => {
      state.publishScenarios = [];
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getScenarios.pending, (store) => {
        handleLoading(store.scenarios);
      })
      .addCase(getScenarios.fulfilled, (store, action) => {
        handleSuccess(store.scenarios, action);
      })
      .addCase(getScenarios.rejected, (store, action) => {
        handleError(store.scenarios, action);
      })
      .addCase(fetchFilteredScenariosData.pending, (store) => {
        handleLoading(store.filteredScenarios);
      })
      .addCase(fetchFilteredScenariosData.fulfilled, (store, action) => {
        handleSuccess(store.filteredScenarios, action);
      })
      .addCase(fetchFilteredScenariosData.rejected, (store, action) => {
        handleError(store.filteredScenarios, action);
      });
  }
});

export const scenarioActions = actions;

export default reducer as Reducer<typeof scenariosInitialState>;
