import { WellPressureClient } from 'api';
import { ForceableQuery } from 'domain/QueryParams';
import { createAppAsyncThunk } from 'store/createAppAsyncThunk';
import { weekDatesApi } from 'store/weekDates/weekDatesApi';
import { GetWellPressurePayload } from '../types/GetWellPressurePayload';
import { wellPressurePresentStateSelector } from '../wellPressureSelectors';

const service = new WellPressureClient();

export const getWellPressure = createAppAsyncThunk(
  'wellPressure/get',
  async (_: ForceableQuery, { dispatch }): Promise<GetWellPressurePayload> => {
    const weekDates = dispatch(weekDatesApi.endpoints.getWeekDates.initiate(undefined)).unwrap();
    const items = service.get();

    await Promise.allSettled([weekDates, items]);

    return { adjustWeekDates: await weekDates, items: await items };
  },
  {
    condition: ({ force }, { getState }) => {
      const wellPressure = wellPressurePresentStateSelector(getState());

      if (!wellPressure.loading && (force || wellPressure.data === undefined)) {
        return true;
      }

      return false;
    }
  }
);
