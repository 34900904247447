import ReactVirtualizedAutoSizer, { Size } from 'react-virtualized-auto-sizer';

import styles from '../utils/ChartContainer.module.css';

import { useTheme } from '@mui/material';
import {
  Chart,
  ChartLegend,
  ChartTooltip,
  ChartXAxis,
  ChartYAxis,
  LineSeries,
  SeriesPoint
} from '@tearecs/components/src/Chart';
import { LoadingOverlay } from 'components/common/Loading/LoadingOverlay';
import { getTitleTemplate } from '../components/shared/ChartTooltipTemplates';

import { useCallback } from 'react';
import { HEIGHT_OFFSET } from '../utils/constants';
import { useWellPiHistorianChartSeries } from './hooks/useWellPiHistorianChartSeries';
import { useWellPiHistorianData } from './hooks/useWellPiHistorianData';

const getValueTemplate = (series: SeriesPoint) => {
  return series.point.value;
};

export function PiHistorianChart() {
  const theme = useTheme();

  const { data, isLoading } = useWellPiHistorianData();

  const series = useWellPiHistorianChartSeries(data);
  const renderChart = useCallback(
    ({ width, height }: Size) => (
      <Chart
        xAccessor="date"
        yAccessor="value"
        xScale={{
          type: 'time'
        }}
        yScale={{
          type: 'linear',
          includeZero: true
        }}
        width={width}
        height={height - HEIGHT_OFFSET}
      >
        <ChartXAxis showGrid={true} />
        <ChartYAxis showGrid={true} scaleParams={{ domainPadding: [0, 10] }} />
        <ChartLegend />
        <LineSeries
          id="1"
          data={series.tubingPressure}
          name="Tubing pressure"
          interpolation="stepAfter"
          stroke={theme.chartLines.lightBlue}
          strokeWidth={2}
        />
        <LineSeries
          id="2"
          data={series.ftpRate}
          name="FTP Rate"
          interpolation="stepAfter"
          stroke={theme.chartLines.blue}
          strokeWidth={2}
        />
        <ChartTooltip titleTemplate={getTitleTemplate} valueTemplate={getValueTemplate} />
      </Chart>
    ),
    [series.ftpRate, series.tubingPressure, theme.chartLines.blue, theme.chartLines.lightBlue]
  );

  return (
    <LoadingOverlay loading={isLoading} className={styles.container}>
      <ReactVirtualizedAutoSizer>{renderChart}</ReactVirtualizedAutoSizer>
    </LoadingOverlay>
  );
}
