import { Draft } from '@reduxjs/toolkit';
import { ILineupStateDto, IParentLineupInputStatus } from 'api';
import { OfficialInputState } from 'store/official-inputs/OfficialInputState';

function updateStatus(
  dto: ILineupStateDto | IParentLineupInputStatus,
  change: IParentLineupInputStatus | ILineupStateDto | undefined
) {
  if (change) {
    dto.status = change.status;
  }
}

export function sealLineupChanges(state: Draft<OfficialInputState>) {
  for (const parentRow of state.lineup.rows) {
    for (const [weekDay, status] of parentRow.parentStatuses) {
      updateStatus(status, state.changes.MeterStation.get(parentRow.id)?.get(weekDay));
      updateStatus(status, state.changes.Header.get(parentRow.id)?.get(weekDay));
    }

    for (const childRow of parentRow.subRows) {
      for (const connectionRow of childRow.subRows) {
        for (const [weekDay, status] of connectionRow.connectionStatuses) {
          updateStatus(status, state.changes.Valve.get(connectionRow.id)?.get(weekDay));
        }
      }
    }
  }
}
