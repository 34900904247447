import { AuthenticationResult, Configuration, EventType, PublicClientApplication } from '@azure/msal-browser';

const config: Configuration = {
  auth: {
    clientId: process.env.REACT_APP_CLIENT_ID,
    authority: `https://login.microsoftonline.com/${process.env.REACT_APP_TENANT_ID}/`,
    redirectUri: '/'
  },
  system: {
    iframeHashTimeout: 10000
  }
};

const msalInstance = new PublicClientApplication(config);

msalInstance.addEventCallback((event) => {
  if (event.eventType === EventType.LOGIN_SUCCESS) {
    msalInstance.setActiveAccount((event.payload as AuthenticationResult).account);
  }
});

export const loginRequest = {
  scopes: [process.env.REACT_APP_API_SCOPE]
};

export default msalInstance;
