import styles from './ChartBrush.module.css';

import { Group } from '@visx/group';
import React, { createElement, FC, ReactElement, useContext, useEffect, useMemo, useRef } from 'react';
import { ChartContext } from '../../ChartContext';
import { RenderChart, RenderGrid } from '../../instance';

import { useAxisGrids, useChartDimensions, useGroupDimensions, useScalesFromChild } from '../../utils';
import { DEFAULT_SIZE, getDomainFromExtent, getExtentFromDomain } from '../../utils/chartUtils';
import { RenderBrushProps, RenderChartBrush } from './RenderChartBrush';

import { PatternLines } from '@visx/pattern';
import { throttle } from 'lodash';
import { Axis, ChartInstance } from '../../chartTypes';
import { trimChartInstanceForBrush } from './utils';

const PATTERN_ID = 'brush_pattern';
const accentColor = '#f6acc8';

const selectionStyle = {
    fill: `url(#${PATTERN_ID})`,
    stroke: 'white',
    strokeWidth: 1,
    strokeOpacity: 0.8
};

const SAFE_PIXEL = 0.1;

/**
 * The props for the {@link ChartBrush} component.
 */
export interface ChartBrushProps
    extends Omit<
        RenderBrushProps,
        'height' | 'width' | 'left' | 'top' | 'brushDirection' | 'resizeTriggerAreas' | 'disableDraggingSelection'
    > {
    axis?: string;
    height?: number;
}

/**
 * @category Component
 * @group Chart
 */

const ChartBrush: FC<ChartBrushProps> = ({ axis, height: heightProp = 50, onChange, ...other }) => {
    const chartBrush = useRef<RenderChartBrush>();
    const instance = useContext(ChartContext);

    const {
        allData,
        children,
        AxisGroups,
        xScale: xScaleProp,
        yScale: yScaleProp,
        groups,
        innerHeight: originalInnerHeight,
        innerWidth: originalInnerWidth,
        height: originalHeight = DEFAULT_SIZE.height,
        width: originalWidth = DEFAULT_SIZE.width,
        margin: originalMargin,
        brush
    } = instance;

    const marginProp = useMemo(() => ({ ...originalMargin, top: 0, bottom: 0 }), [originalMargin]);
    const height = useMemo(() => heightProp, [heightProp]);
    const heightScale = useMemo(() => height / originalHeight, [height, originalHeight]);
    const width = useMemo(() => originalWidth, [originalWidth]);
    const widthScale = useMemo(() => width / originalWidth, [originalWidth, width]);

    const { margin, innerWidth, innerHeight } = useChartDimensions(marginProp, width, height);

    const groupDimensions = useGroupDimensions(
        AxisGroups,
        groups,
        originalInnerHeight,
        originalInnerWidth,
        heightScale,
        widthScale
    );

    const { xScales, yScales } = useScalesFromChild(
        allData,
        AxisGroups,
        groupDimensions,
        children,
        xScaleProp,
        yScaleProp
    );

    // to create axis grids independent from main chart
    const fixedAxisGroup: Record<string, ReactElement<Axis>[]> = groups.reduce((acc, group) => {
        const currentAxis = AxisGroups[group].map((elem) =>
            createElement(() => {
                elem.props = {} as Axis;
                return elem;
            })
        );

        acc[group] = currentAxis;

        return acc;
    }, {});

    const axisGrid = useAxisGrids(fixedAxisGroup, groups, originalInnerHeight, originalInnerWidth);

    const newInstance: ChartInstance = useMemo(
        () =>
            trimChartInstanceForBrush({
                ...instance,
                height,
                width,
                margin,
                innerWidth,
                innerHeight,
                groupDimensions,
                xScales,
                yScales,
                axisGrid
            }),
        [groupDimensions, height, innerHeight, innerWidth, instance, margin, width, xScales, yScales]
    );

    const [xGroup, xScale] = axis === undefined ? ['undefined', xScales['undefined']] : [axis, xScales[axis]];

    const updateBrush = useMemo(
        () => (brushState: typeof brush.state) => {
            const stateStart = brushState.x[xGroup]?.start;
            const stateEnd = brushState.x[xGroup]?.end;
            const chartBrushState = chartBrush.current?.state;

            // to reset brush if chart instance's brush state is empty
            if (
                chartBrushState &&
                isNaN(stateStart) &&
                isNaN(stateEnd) &&
                !(
                    chartBrushState.start?.x === 0 &&
                    chartBrushState.start?.y === 0 &&
                    chartBrushState.end?.x === 0 &&
                    chartBrushState.end?.y === 0
                )
            ) {
                chartBrush.current.reset();

                return;
            }

            const [start, end] = getExtentFromDomain(xScale, stateStart, stateEnd);

            if (isNaN(start) || isNaN(end) || !chartBrush.current) {
                return;
            }

            chartBrush.current.update((prevState) => {
                const { x0, x1, y0, y1 } = chartBrush.current.getExtent({ x: start }, { x: end });

                return {
                    ...prevState,
                    start: {
                        x: x0,
                        y: y0
                    },
                    end: {
                        x: x1,
                        y: y1
                    },
                    extent: { x0, x1, y0, y1 }
                };
            });
        },
        [groupDimensions, xGroup, axis]
    );

    useEffect(() => {
        updateBrush(brush.initialState);
    }, [brush.initialState]);

    const handleChange = throttle((domain) => {
        const { x0, x1 } = domain.extent;

        if (x0 === -1 || x1 === -1) {
            brush.setBrush(true, xGroup, null);
            return;
        }

        const xDomain = getDomainFromExtent(xScale, x0, x1, SAFE_PIXEL);

        if (isNaN(xDomain.start) || isNaN(xDomain.end)) {
            brush.setBrush(true, xGroup, null);
            return;
        }

        brush.setBrush(true, xGroup, xDomain);

        if (onChange) {
            onChange(xDomain);
        }
    }, 200);

    const handleClick = () => {
        brush.setBrush(true, xGroup, null);
        brush.setInitialState(true, xGroup, null);

        if (onChange) {
            onChange(null);
        }
    };

    const offset = useMemo(
        () => ({
            left: groupDimensions[axis].position.left + groupDimensions[axis].offset.left,
            right: groupDimensions[axis].position.left + groupDimensions[axis].offset.right
        }),
        [groupDimensions, axis]
    );

    const result = (
        <div className={styles.root} style={{ width }}>
            <svg role="img" viewBox={`0 0 ${width} ${height}`} preserveAspectRatio="xMinYMin meet">
                <Group left={margin.left} top={margin.top}>
                    <RenderGrid orientations={['x']} gridProps={{ stroke: 'gray' }} />
                    <RenderChart layer="front" />
                </Group>
                <PatternLines
                    id={PATTERN_ID}
                    height={8}
                    width={8}
                    stroke={accentColor}
                    strokeWidth={1}
                    orientation={['diagonal']}
                />
                <RenderChartBrush
                    ref={chartBrush}
                    width={innerWidth - offset.left}
                    height={height}
                    left={margin.left + offset.left}
                    top={0}
                    resizeTriggerAreas={['left', 'right']}
                    brushDirection="horizontal"
                    selectedBoxStyle={selectionStyle}
                    handleSize={8}
                    onChange={handleChange}
                    onClick={handleClick}
                    {...other}
                />
            </svg>
        </div>
    );

    return <ChartContext.Provider value={newInstance}>{result}</ChartContext.Provider>;
};

ChartBrush.displayName = 'ChartBrush';

/** @ignore */
export { ChartBrush };
