import React from 'react';
import { Stack, Typography } from '@mui/material';
import usePageTitle from '../../utils/usePageTitle';

export default function AboutPage() {
  usePageTitle('About');
  return (
    <Stack direction="column" alignItems="center">
      <img
        src="/images/logo.svg"
        height={200}
      />
      <Typography variant="h3" mt={2} mb={4}>
        White Space Dashboard
      </Typography>
      <div>
        White Space Dashboard (WSD) is a forecasting solution that simulates different production
        {' '}
        scenarios and identifies optimization opportunities.
        {' '}
        Examples of benefits that the Dashboard offers:
        <ul>
          <li>Automation of WS workflows.</li>
          <li>Digitalization of workflows to manage Lost Production Opportunity (LPO) efficiently.</li>
          <li>The most up to date state (production &amp; scheduling) on the ground.</li>
          <li>Streamlining of the QA/QC process for data.</li>
          <li>Opportunities to future proof the solution e.g. virtual metering, compositional tracking (GOR changes).</li>
        </ul>
        With the help of Dashboard time/effort to generate WS forecasts to enable the business to
        {' '}
        react faster to schedule changes is reduced.
        {' '}
        As an outcome, higher accuracy and mitigation of the potential for errors of WS forecasting
        {' '}
        will further enable a more robust system capacity and production planning for TCO.
      </div>
    </Stack>
  );
}
