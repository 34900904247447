import {} from 'react-is';
import { Scale } from '..';
import { BrushState, Margin } from '../chartTypes';

export function callOrValue(maybeFn: Function | any, ...args: any[]) {
    if (typeof maybeFn === 'function') {
        return maybeFn(...args);
    }

    return maybeFn;
}

export function isDefined(val: any) {
    return typeof val !== 'undefined' && val !== null && !isNaN(val);
}

export const DEFAULT_SCALE = 1;

export const DEFAULT_CHART_MARGIN: Margin = {
    top: 16,
    right: 16,
    bottom: 0,
    left: 0
};

export const DEFAULT_SIZE = {
    height: 350,
    width: 800
};

export function componentName(component: any) {
    if (component?.type) {
        return (
            component?.type?.type?.displayName ??
            component?.type?.displayName ??
            component?.type?.type?.name ??
            component?.type?.name ??
            'Component'
        );
    }

    return '';
}

export function isAxis(name: string) {
    return /axis/gi.test(name);
}

export function isTooltip(name: string) {
    return /tooltip/gi.test(name);
}

export function isStackedGroup(name: string) {
    return /stacked/gi.test(name) && /group/gi.test(name);
}

export function isXAxis(name: string) {
    return name === 'ChartXAxis';
}

export function isYAxis(name: string) {
    return name === 'ChartYAxis';
}

export function isToolbar(name: string) {
    return name === 'Toolbar';
}

export function isSeries(name: string) {
    return /series/gi.test(name);
}

export function isBarSeries(name: string) {
    return /bar/gi.test(name) && !isToolbar(name);
}

export function isScatterSeries(name: string) {
    return /scatter/gi.test(name);
}

export function isCirclePackSeries(name: string) {
    return name === 'CirclePackSeries';
}

export function isCrossHair(name: string) {
    return /crosshair/gi.test(name);
}

export function isBrush(name: string) {
    return name === 'ChartBrush';
}

export function isZoom(name: string) {
    return name === 'ChartZoom';
}

export function isLegend(name: string) {
    return name === 'ChartLegend';
}

export function isAnnotation(name: string) {
    return name === 'ChartAnnotation';
}

export function numTicksForHeight(height: number) {
    if (height <= 300) return 3;
    if (height <= 600) return 5;

    return 8;
}

export function numTicksForWidth(width: number) {
    if (width <= 300) return 3;
    if (width <= 400) return 5;

    return 10;
}

export function propOrFallback<TProps>(props: TProps, propName: keyof TProps, fallback: any) {
    return props && isDefined(props[propName]) ? props[propName] : fallback;
}

export function connectEventHandler(originalHandler: Function, handler: Function) {
    return (event: Event) => {
        if (originalHandler) {
            originalHandler(event);
        }
        if (handler) {
            handler(event);
        }
    };
}

export function scaleInvert(scale: Scale, value: number) {
    // Test if the scale is an ordinalScale or not,
    // Since an ordinalScale doesn't support invert function.
    if ('invert' in scale && typeof scale.invert !== 'undefined') {
        return scale.invert(value).valueOf();
    }
    const [start, end] = scale.range() as number[];
    let i = 0;
    // ordinal should have step
    const step = 'step' in scale && typeof scale.step !== 'undefined' ? scale.step() : 1;
    const width = (step * (end - start)) / Math.abs(end - start);
    if (width > 0) {
        while (value > start + width * (i + 1)) {
            i += 1;
        }
    } else {
        while (value < start + width * (i + 1)) {
            i += 1;
        }
    }

    return i;
}

export function getDomainFromExtent(scale: Scale, start: number, end: number, tolerentDelta: number) {
    let domain: Partial<BrushState>;
    const invertedStart = scaleInvert(scale, start + (start < end ? -tolerentDelta : tolerentDelta));
    const invertedEnd = scaleInvert(scale, end + (end < start ? -tolerentDelta : tolerentDelta));
    const minValue = Math.min(invertedStart, invertedEnd);
    const maxValue = Math.max(invertedStart, invertedEnd);
    if ('invert' in scale && typeof scale.invert !== 'undefined') {
        domain = {
            start: minValue,
            end: maxValue
        };
    } else {
        const values: number[] = [];
        const scaleDomain = scale.domain();
        for (let i = minValue; i <= maxValue; i += 1) {
            values.push(scaleDomain[i]);
        }
        domain = {
            values
        };
    }

    return domain;
}

export function getExtentFromDomain(scale: Scale, start: number, end: number): [number, number] {
    const invertedStart = scale(start);
    const invertedEnd = scale(end);
    const minValue = Math.min(invertedStart, invertedEnd);
    const maxValue = Math.max(invertedStart, invertedEnd);

    return [minValue, maxValue];
}
