import { NavigatePreserveSearch } from 'components/common/NavigatePreserveSearch';
import { RouteObject } from 'react-router-dom';
import { lazyComponent } from 'utils/lazyComponent';
import { OUTLOOK_TAB, OUTLOOK_VIEW_MODE } from './outlook/constants';

const Dashboard = lazyComponent(() => import('./DashboardPage'), 'dashboard');
const Results = lazyComponent(() => import('./results/ResultsPage'), 'results');
const Outlook = lazyComponent(() => import('./outlook/WSOutlookPage'), 'outlook');
const ForecastPage = lazyComponent(() => import('./outlook/forecast/ForecastPage'), 'forecastPage');
const ForecastChartPage = lazyComponent(
  () => import('./outlook/forecast/forecastChart/ForecastChartPage'),
  'forecastChartPage'
);
const ForecastTablePage = lazyComponent(
  () => import('./outlook/forecast/forecastTable/ForecastTablePage'),
  'forecastTablePage'
);
const Lookback = lazyComponent(() => import('./outlook/lookback/Lookback'), 'lookback');
const LookbackChart = lazyComponent(() => import('./outlook/lookback/LookbackChart'), 'lookbarChart');
const LookbackTable = lazyComponent(() => import('./outlook/lookback/LookbackTable'), 'lookbackTable');
const WedgeGraphPageIndex = lazyComponent(() => import('./outlook/wedge-graph/WedgeGraphPage'), 'wedgeGraph');
const WedgeGraphPage = lazyComponent(() => import('./outlook/wedge-graph/wedgeGraph/WedgeGraphPage'), 'wedgeGraph');
const WedgeGraphTablePage = lazyComponent(
  () => import('./outlook/wedge-graph/wedgeGraphTable/WedgeGraphTablePage'),
  'wedgeGraphTable'
);

export const dashboardRoute: RouteObject = {
  path: 'dashboard',
  element: <Dashboard />,
  children: [
    {
      path: 'results',
      element: <Results />
    },
    {
      path: 'outlook',
      element: <Outlook />,
      children: [
        { index: true, element: <NavigatePreserveSearch replace to="forecast" /> },
        {
          path: OUTLOOK_TAB.forecast,
          element: <ForecastPage />,
          children: [
            {
              path: OUTLOOK_VIEW_MODE.chart,
              element: <ForecastChartPage />
            },
            {
              path: OUTLOOK_VIEW_MODE.table,
              element: <ForecastTablePage />
            }
          ]
        },
        {
          path: OUTLOOK_TAB.lookback,
          element: <Lookback />,
          children: [
            {
              path: OUTLOOK_VIEW_MODE.chart,
              element: <LookbackChart />
            },
            {
              path: OUTLOOK_VIEW_MODE.table,
              element: <LookbackTable />
            }
          ]
        },
        {
          path: OUTLOOK_TAB.wedgeGraphRoute,
          element: <WedgeGraphPageIndex />,
          children: [
            {
              path: OUTLOOK_VIEW_MODE.chart,
              element: <WedgeGraphPage />
            },
            {
              path: OUTLOOK_VIEW_MODE.table,
              element: <WedgeGraphTablePage />
            }
          ]
        }
      ]
    }
  ]
};
