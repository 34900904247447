import { PipelineClient, PipelineDto } from 'api';
import { sortBy } from 'lodash';
import { rootApi } from 'store/rootApi';

const service = new PipelineClient();

export const pipelinesApi = rootApi.enhanceEndpoints({ addTagTypes: ['pipeline'] }).injectEndpoints({
  endpoints: (builder) => ({
    getAllPipelines: builder.query<PipelineDto[], void>({
      queryFn: () =>
        service
          .getAll()
          .then((data) => ({ data: sortBy(data, 'name') }))
          .catch((error) => ({ error })),
      providesTags: (result) =>
        result
          ? [...result.map(({ id }) => ({ type: 'pipeline' as const, id })), { type: 'pipeline', id: 'LIST' }]
          : [{ type: 'pipeline', id: 'LIST' }]
    }),
    getPipeline: builder.query({
      queryFn: (id: number) =>
        service
          .getById(id)
          .then((data) => ({ data }))
          .catch((error) => ({ error })),
      providesTags: (_result, _error, id) => [{ type: 'pipeline', id }]
    }),
    savePipeline: builder.mutation({
      queryFn: (payload: PipelineDto[]) =>
        service
          .savePipeline(payload)
          .then((data) => ({ data }))
          .catch((error) => ({ error })),
      invalidatesTags: [{ type: 'pipeline', id: 'LIST' }]
    }),
    updatePipeline: builder.mutation({
      queryFn: (payload: PipelineDto) =>
        service
          .updatePipeline(payload)
          .then((data) => ({ data }))
          .catch((error) => ({ error })),
      invalidatesTags: [{ type: 'pipeline', id: 'LIST' }]
    })
  })
});
