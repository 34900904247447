export enum DRA_TABLE_ROW {
  DRA = 'DRA',
  LDHI = 'LDHI',
  TL14 = 'TL14'
}

export type DraRow = {
  id: DRA_TABLE_ROW;
  weekDates: Map<number, number>;
};
