import React, { ForwardedRef, forwardRef, memo, PropsWithChildren, useMemo } from 'react';
import ReactFlow, { Edge, Node, ReactFlowProps } from 'reactflow';
import 'reactflow/dist/style.css';
import { DiagramEdge, DiagramNode } from './components';
import { DiagramContext } from './DiagramContext';
import { DiagramInstance } from './DiagramInstance';
import { DiagramEdgeData, DiagramNodeData } from './types';

/**
 * The props for the {@link Diagram} component.
 * @category Props
 */
export interface DiagramProps<TNode extends DiagramNodeData = any, TEdge extends DiagramEdgeData = any>
    extends PropsWithChildren<ReactFlowProps> {
    nodesResizable?: boolean;
    nodes?: Node<TNode>[];
    edges?: Edge<TEdge>[];
}

const RenderDiagram = <TNode extends DiagramNodeData = any, TEdge extends DiagramEdgeData = any>(
    props: DiagramProps<TNode, TEdge>,
    ref: ForwardedRef<HTMLDivElement>
) => {
    const {
        nodeTypes: nodeTypesProp,
        edgeTypes: edgeTypesProp,
        nodesResizable = false,
        onMoveStart,
        ...reactFlowProps
    } = props;
    const { children, ...other } = reactFlowProps;

    const instance = useMemo(
        (): DiagramInstance => ({
            nodesResizable
        }),
        [nodesResizable]
    );

    const nodeTypes = useMemo(() => ({ default: DiagramNode, ...nodeTypesProp }), [nodeTypesProp]);
    const edgeTypes = useMemo(() => ({ default: DiagramEdge, ...edgeTypesProp }), [edgeTypesProp]);

    return (
        <DiagramContext.Provider value={instance}>
            <ReactFlow ref={ref} nodeTypes={nodeTypes} edgeTypes={edgeTypes} {...other}>
                {children}
            </ReactFlow>
        </DiagramContext.Provider>
    );
};

/**
 * @category Component
 * @group Diagram
 */
export const Diagram = memo(forwardRef(RenderDiagram));
