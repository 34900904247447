import { WellPressureState } from '../WellPressureState';
import { WellPressureTableRow } from './convertWellPressureData';

export const getWellPressureSnapshot = (wellPressure: WellPressureState, searchQuery = ''): WellPressureTableRow[] => {
  const search = searchQuery?.toLocaleLowerCase();
  const { rows, pressureDeclineChanges, reservoirPressureChanges, inputChanges } = wellPressure;
  return rows
    ?.map((row) => {
      const isMSName = row.name?.toLocaleLowerCase().includes(search);

      if (isMSName) {
        return row;
      }

      const wellRows = row.subRows.filter((subRow) => subRow.name?.toLocaleLowerCase().includes(search));

      if (wellRows.length > 0) {
        return {
          ...row,
          subRows: wellRows
        };
      }

      return undefined;
    })
    ?.filter(Boolean)
    ?.map((row): WellPressureTableRow => {
      return {
        ...row,
        subRows: row.subRows.map((subRow): WellPressureTableRow => {
          return {
            ...subRow,
            pressureDecline: pressureDeclineChanges?.get(subRow.id) ?? subRow.pressureDecline,
            reservoirPressure: reservoirPressureChanges?.get(subRow.id) ?? subRow.reservoirPressure,
            inputs: new Map(
              Array.from(subRow.inputs.entries()).map(([key, value]) => {
                return [
                  key,
                  {
                    ...value,
                    pressureValue: inputChanges?.get(subRow.id)?.get(key)?.pressureValue ?? value.pressureValue
                  }
                ];
              })
            )
          };
        })
      };
    });
};
