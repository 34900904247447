import { useCallback } from 'react';
import { TimelineState, UniversalTimelineInstance } from '../TimelineTypes';
import { actions } from '../utils/actions';

actions.openQuickInfo = 'openQuickInfo';
actions.closeQuickInfo = 'closeQuickInfo';

export function quickInfoReducer(
    state: TimelineState<any>,
    action,
    previousState,
    instance: UniversalTimelineInstance
): TimelineState<any> {
    const { itemsById } = instance;
    if (action.type === actions.init) {
        return {
            ...state,
            quickInfo: {
                open: false,
                item: null,
                anchorEl: null,
            },
        };
    }

    if (action.type === actions.toggleItemSelected) {
        const { id, value, event } = action;

        if (value) {
            const item = itemsById[id];

            return {
                ...state,
                quickInfo: {
                    open: true,
                    item,
                    anchorEl: event.currentTarget || event.target,
                },
            };
        }
    }

    if (action.type === actions.closeQuickInfo) {
        return {
            ...state,
            quickInfo: {
                ...state.quickInfo,
                open: false,
            },
            selectedItemIds: {},
        };
    }

    return state;
}

export function useQuickInfo(instance: UniversalTimelineInstance) {
    const { dispatch } = instance;

    const closeQuickInfo = useCallback(() => {
        dispatch({ type: actions.closeQuickInfo });
    }, [dispatch]);

    Object.assign(instance, {
        closeQuickInfo,
    });
}
