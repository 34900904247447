import { ProgressButton } from 'components/common/ProgressButton';
import { FC, useState } from 'react';
import { toast } from 'react-toastify';
import { useAppDispatch, useAppSelector } from 'store';
import {
  selectOfficialInputDisableApplyLineupButton,
  selectOfficialInputStateHasChanges
} from 'store/official-inputs/OfficialSelectors';
import { applyLineupLogicAction } from 'store/official-inputs/actions/applyLineupLogicAction';

export const ApplyLineupLogicButton: FC = () => {
  const dispatch = useAppDispatch();
  const hasChanges = useAppSelector(selectOfficialInputStateHasChanges);
  const disabled = useAppSelector(selectOfficialInputDisableApplyLineupButton);
  const [loading, setLoading] = useState(false);

  const handleApplyLineupLogic = async () => {
    try {
      setLoading(true);
      await dispatch(applyLineupLogicAction()).unwrap();
      toast.success('Successfully applied');
    } catch {
      toast.error('Apply failed');
    } finally {
      setLoading(false);
    }
  };

  return (
    <ProgressButton
      disabled={!hasChanges || disabled}
      loading={loading}
      variant="outlined"
      onClick={handleApplyLineupLogic}
    >
      Apply line-up logic
    </ProgressButton>
  );
};
