import { localPoint } from '@visx/event';
import { bisectLeft as d3BisectLeft, bisector } from 'd3-array';
import { Dimensions, Scale, SeriesOptions } from '../chartTypes';
import { ChartAccessor } from './useAccessors';

interface Params<T extends object> extends SeriesOptions<T> {
    data: T[];
    getX: ChartAccessor<T>;
    xScale?: Scale;
    event?: React.MouseEvent<SVGElement>;
    dimensions: Dimensions;
    marginLeft?: number;
}

export function findClosestPoint<T extends object>({
    data,
    getX,
    xScale,
    event,
    marginLeft = 0,
    dimensions,
}: Params<T>): T | null {
    const target = (event?.target as SVGElement)?.ownerSVGElement;
    if (!target || !getX || !xScale) return null;
    const bisect = bisector(getX).left;

    // if the g element has a transform we need to be in g coords not svg coords
    const svgCoords = localPoint(target, event);
    if (!svgCoords) {
        return null;
    }

    const mouseX = svgCoords.x - marginLeft - dimensions.position.left - dimensions.offset.left;

    const isOrdinalScale = typeof xScale.invert !== 'function';
    let point;
    if (isOrdinalScale) {
        // Ordinal scales don't have an invert function so we do it maually
        const xDomain = xScale.domain();
        const scaledXValues = xDomain.map((val) => xScale(val));
        const index = d3BisectLeft(scaledXValues, mouseX);
        const d0 = data[index - 1];
        const d1 = data[index];
        point = d0 || d1;
    } else {
        const dataX = xScale.invert(mouseX);
        const index = bisect(data, dataX, 0);
        const d0 = data[index - 1];
        const d1 = data[index] || ({} as T);
        point = !d0 || Math.abs(dataX - getX(d0)) > Math.abs(dataX - getX(d1)) ? d1 : d0;
    }

    return point;
}
