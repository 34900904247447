import cx from 'classnames';
import React, { ComponentProps, ElementType, forwardRef, ReactElement, Ref } from 'react';
import styles from './Divider.module.css';

/**
 * The props for the {@link Divider} component.
 * @category Props
 */
export type DividerProps<T extends ElementType = 'hr'> = {
    component?: T;
    absolute?: boolean;
    flexItem?: boolean;
    light?: boolean;
    orientation?: 'horizontal' | 'vertical';
    textAlign?: 'center' | 'right' | 'left';
    variant?: 'fullWidth' | 'inset' | 'middle';
} & ComponentProps<T>;

/**
 * @category Component
 * @group Divider
 */
export const Divider = forwardRef(function Divider<T extends ElementType = 'hr'>(
    {
        absolute = false,
        children,
        className,
        component: Component = children ? 'div' : 'hr',
        flexItem = false,
        light = false,
        orientation = 'horizontal',
        role = Component !== 'hr' ? 'separator' : undefined,
        textAlign = 'center',
        variant = 'fullWidth',
        ...other
    }: DividerProps<T>,
    ref
) {
    return (
        <Component
            className={cx(
                styles.root,
                {
                    [styles[variant]]: variant !== 'fullWidth',
                    [styles.absolute]: absolute,
                    [styles.flexItem]: flexItem,
                    [styles.light]: light,
                    [styles.vertical]: orientation === 'vertical',
                },
                className
            )}
            role={role}
            ref={ref}
            {...other}
        >
            {children ? (
                <span className={cx(styles.wrapper, { [styles.wrapperVertical]: orientation === 'vertical' })}>
                    {children}
                </span>
            ) : null}
        </Component>
    );
}) as <T extends ElementType = 'hr'>(props: DividerProps<T> & { ref?: Ref<T> }) => ReactElement;
