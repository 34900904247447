import { Draft } from '@reduxjs/toolkit';
import { ILineupLogicResponseDto, SchemeComponentType } from 'api';
import { OfficialInputState } from '../OfficialInputState';
import { getNodeId } from './getNodeId';

export function mapApplyLineLogicResponse(state: Draft<OfficialInputState>, payload: ILineupLogicResponseDto[]) {
  for (const { weekDateId, nodes } of payload) {
    for (const node of nodes) {
      if (node.componentType === SchemeComponentType.MeterStation) {
        const rowId = getNodeId(node.componentId, SchemeComponentType.MeterStation);
        let rowChanges = state.changes.MeterStation.get(rowId);
        if (!rowChanges) {
          rowChanges = new Map();
          state.changes.MeterStation.set(rowId, rowChanges);
        }
        rowChanges.set(weekDateId, {
          lineup: { meterStationId: node.componentId },
          wellInput: { id: node.componentId },
          status: node.status
        });
      }

      if (node.componentType === SchemeComponentType.Header) {
        const rowId = getNodeId(node.componentId, SchemeComponentType.Header);
        let rowChanges = state.changes.Header.get(rowId);
        if (!rowChanges) {
          rowChanges = new Map();
          state.changes.Header.set(rowId, rowChanges);
        }
        rowChanges.set(weekDateId, {
          headerId: node.componentId,
          status: node.status
        });
      }

      if (node.componentType === SchemeComponentType.Valve) {
        const nodeId = getNodeId(node.componentId, SchemeComponentType.Valve);
        const rowId = state.lineup.rowIdByNodeId.get(nodeId);
        if (!rowId) {
          // eslint-disable-next-line no-console
          console.warn('Node is not found in lineup', node);
        } else {
          let rowChanges = state.changes.Valve.get(rowId);
          if (!rowChanges) {
            rowChanges = new Map();
            state.changes.Valve.set(rowId, rowChanges);
          }
          rowChanges.set(weekDateId, { valveId: node.componentId, status: node.status, id: node.nodeId });
        }
      }

      if (node.componentType === SchemeComponentType.Well) {
        const nodeId = getNodeId(node.componentId, SchemeComponentType.Well);
        const rowId = state.wellInput.rowIdByNodeId.get(nodeId);
        if (!rowId) {
          // eslint-disable-next-line no-console
          console.warn('Node is not found in wells', node);
        } else {
          let rowChanges = state.changes.Well.get(rowId);
          if (!rowChanges) {
            rowChanges = new Map();
            state.changes.Well.set(rowId, rowChanges);
          }
          rowChanges.set(weekDateId, { wellId: node.componentId, status: node.status, id: node.nodeId });
        }
      }

      if (node.componentType === SchemeComponentType.Joint) {
        const rowId = getNodeId(node.componentId, SchemeComponentType.Joint);
        let rowChanges = state.changes.Joint.get(rowId);
        if (!rowChanges) {
          rowChanges = new Map();
          state.changes.Joint.set(rowId, rowChanges);
        }
        rowChanges.set(weekDateId, { ...node });
      }
    }
  }
}
