import { WellPIClient } from 'api';
import { saveDeactivatedTimesteps } from 'store/cases/actions/deactivatedTimesteps';
import { createAppAsyncThunk } from 'store/createAppAsyncThunk';
import { getWellPISaveState } from 'store/wellPI/actions/saveWellPI';
import { GetWellPIPayload } from 'store/wellPI/types/GetWellPIPayload';
import { wellPICaseStateSelector } from '../wellPICaseSelectors';
import { WELL_PI_CASE_UNDO_CLEAR } from '../wellPICaseSlice';

export interface WellPICaseParams {
  scenarioCaseId: number;
}

const service = new WellPIClient();

export const getWellPICase = createAppAsyncThunk(
  'wellPICase/get',
  async ({ scenarioCaseId }: WellPICaseParams, { dispatch }): Promise<GetWellPIPayload> => {
    dispatch({ payload: undefined, type: WELL_PI_CASE_UNDO_CLEAR });

    return { items: await service.getScenario(scenarioCaseId) };
  },
  {
    condition: (arg, { getState }) => {
      const wellPI = wellPICaseStateSelector(getState());

      if (!wellPI.loading && wellPI.data === undefined) {
        return true;
      }

      if (wellPI.arg && arg.scenarioCaseId !== wellPI.arg.scenarioCaseId) {
        return true;
      }

      return false;
    }
  }
);

export const saveWellPICase = createAppAsyncThunk('wellPICase/save', (_: void, { getState, dispatch }) => {
  const wellPI = wellPICaseStateSelector(getState());

  const data = getWellPISaveState(wellPI);
  const saveLineupDeactivatedWeeks = dispatch(saveDeactivatedTimesteps(wellPI.arg.scenarioCaseId));

  return Promise.allSettled([service.saveScenario(wellPI.arg.scenarioCaseId, data), saveLineupDeactivatedWeeks]);
});
