import moment from 'moment';

export const CLIENT_UTC_OFFSET = moment().utcOffset();

export function getDateWithUTCOffset(date: Date, utcOffset: number = CLIENT_UTC_OFFSET): Date {
    const currentMoment = moment(date);
    const manipulatedMoment = currentMoment.clone().add(CLIENT_UTC_OFFSET - utcOffset, 'minutes');
    return moment(manipulatedMoment.toISOString()).toDate();
}

export function getDateWithoutUTCOffset(date: Date, utcOffset: number = CLIENT_UTC_OFFSET): Date {
    const currentMoment = moment(date);
    const manipulatedMoment = currentMoment.clone().subtract(CLIENT_UTC_OFFSET - utcOffset, 'minutes');
    return moment(manipulatedMoment.toISOString()).toDate();
}
