import cx from 'classnames';
import { debounce } from 'lodash';
import React, { forwardRef, ReactNode, Ref, useCallback, useEffect, useImperativeHandle, useRef } from 'react';
import { Fade, FadeProps } from 'reactstrap';
import { Paper, PaperProps } from '../Paper';
import { ownerDocument, ownerWindow } from '../util';
import { Modal, ModalProps } from './Modal';
import styles from './Popover.module.css';

export function getOffsetTop(rect, vertical) {
    let offset = 0;

    if (typeof vertical === 'number') {
        offset = vertical;
    } else if (vertical === 'center') {
        offset = rect.height / 2;
    } else if (vertical === 'bottom') {
        offset = rect.height;
    }

    return offset;
}

export function getOffsetLeft(rect, horizontal) {
    let offset = 0;

    if (typeof horizontal === 'number') {
        offset = horizontal;
    } else if (horizontal === 'center') {
        offset = rect.width / 2;
    } else if (horizontal === 'right') {
        offset = rect.width;
    }

    return offset;
}

function getTransformOriginValue(transformOrigin) {
    return [transformOrigin.horizontal, transformOrigin.vertical]
        .map((n) => (typeof n === 'number' ? `${n}px` : n))
        .join(' ');
}

// Sum the scrollTop between two elements.
function getScrollParent(parent, child) {
    let element = child;
    let scrollTop = 0;

    while (element && element !== parent) {
        element = element.parentElement;
        scrollTop += element.scrollTop;
    }
    return scrollTop;
}

function getAnchorEl(anchorEl) {
    return typeof anchorEl === 'function' ? anchorEl() : anchorEl?.current ?? anchorEl;
}

export interface PopoverOrigin {
    vertical: 'top' | 'center' | 'bottom' | number;
    horizontal: 'left' | 'center' | 'right' | number;
}

export interface PopoverPosition {
    top: number;
    left: number;
}

export type PopoverReference = 'anchorEl' | 'anchorPosition' | 'none';

export interface PopoverActions {
    updatePosition(): void;
}

/**
 * The props of the {@link Popover} component.
 * @category Props
 */
export interface PopoverProps extends Omit<ModalProps, 'children' | 'onClose'> {
    className?: string;
    action?: Ref<PopoverActions>;
    anchorEl?: null | Ref<Element> | Element | ((element: HTMLElement) => HTMLElement);
    anchorOrigin?: PopoverOrigin;
    anchorPosition?: PopoverPosition;
    anchorReference?: PopoverReference;
    children?: ReactNode;
    container?: ModalProps['container'];
    elevation?: number;
    getContentAnchorEl?: null | ((element: HTMLDivElement) => HTMLDivElement);
    marginThreshold?: number;
    onClose?: (event: {}, reason: 'backdropClick' | 'escapeKeyDown' | string) => void;
    open: boolean;
    PaperProps?: Partial<PaperProps>;
    transformOrigin?: PopoverOrigin;
    TransitionProps?: FadeProps;
}

/**
 * @category Component
 * @group Popover
 */
export const Popover = forwardRef(function Popover(props: PopoverProps, ref) {
    const {
        action,
        anchorEl,
        anchorOrigin = {
            vertical: 'top',
            horizontal: 'left'
        },
        anchorPosition,
        anchorReference = 'anchorEl',
        children,
        className,
        container: containerProp,
        elevation = 8,
        getContentAnchorEl,
        marginThreshold = 16,
        open,
        PaperProps = {},
        transformOrigin = {
            vertical: 'top',
            horizontal: 'left'
        },
        TransitionProps,
        ...other
    } = props;
    const paperRef = useRef<HTMLDivElement>();

    // Returns the top/left offset of the position
    // to attach to on the anchor element (or body if none is provided)
    const getAnchorOffset = useCallback(
        (contentAnchorOffset) => {
            if (anchorReference === 'anchorPosition') {
                return anchorPosition;
            }

            const resolvedAnchorEl = getAnchorEl(anchorEl);

            // If an anchor element wasn't provided, just use the parent body element of this Popover
            const anchorElement =
                resolvedAnchorEl && resolvedAnchorEl.nodeType === 1
                    ? resolvedAnchorEl
                    : ownerDocument(paperRef.current).body;
            const anchorRect = anchorElement.getBoundingClientRect();

            const anchorVertical = contentAnchorOffset === 0 ? anchorOrigin.vertical : 'center';

            return {
                top: anchorRect.top + getOffsetTop(anchorRect, anchorVertical),
                left: anchorRect.left + getOffsetLeft(anchorRect, anchorOrigin.horizontal)
            };
        },
        [anchorEl, anchorOrigin.horizontal, anchorOrigin.vertical, anchorPosition, anchorReference]
    );

    const getContentAnchorOffset = useCallback(
        (element) => {
            let contentAnchorOffset = 0;

            if (getContentAnchorEl && anchorReference === 'anchorEl') {
                const contentAnchorEl = getContentAnchorEl(element);

                if (contentAnchorEl && element.contains(contentAnchorEl)) {
                    const scrollTop = getScrollParent(element, contentAnchorEl);
                    contentAnchorOffset = contentAnchorEl.offsetTop + contentAnchorEl.clientHeight / 2 - scrollTop || 0;
                }
            }

            return contentAnchorOffset;
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [anchorOrigin.vertical, anchorReference, getContentAnchorEl]
    );

    const getTransformOrigin = useCallback(
        (elemRect, contentAnchorOffset = 0) => {
            return {
                vertical: getOffsetTop(elemRect, transformOrigin.vertical) + contentAnchorOffset,
                horizontal: getOffsetLeft(elemRect, transformOrigin.horizontal)
            };
        },
        [transformOrigin.horizontal, transformOrigin.vertical]
    );

    const getPositioningStyle = useCallback(
        (element) => {
            const contentAnchorOffset = getContentAnchorOffset(element);
            const elemRect = {
                width: element.offsetWidth,
                height: element.offsetHeight
            };

            const elemTransformOrigin = getTransformOrigin(elemRect, contentAnchorOffset);

            if (anchorReference === 'none') {
                return {
                    top: null,
                    left: null,
                    transformOrigin: getTransformOriginValue(elemTransformOrigin)
                };
            }

            const anchorOffset = getAnchorOffset(contentAnchorOffset);

            let top = anchorOffset.top - elemTransformOrigin.vertical;
            let left = anchorOffset.left - elemTransformOrigin.horizontal;
            const bottom = top + elemRect.height;
            const right = left + elemRect.width;

            const containerWindow = ownerWindow(getAnchorEl(anchorEl));

            const heightThreshold = containerWindow.innerHeight - marginThreshold;
            const widthThreshold = containerWindow.innerWidth - marginThreshold;

            if (top < marginThreshold) {
                const diff = top - marginThreshold;
                top -= diff;
                elemTransformOrigin.vertical += diff;
            } else if (bottom > heightThreshold) {
                const diff = bottom - heightThreshold;
                top -= diff;
                elemTransformOrigin.vertical += diff;
            }

            if (left < marginThreshold) {
                const diff = left - marginThreshold;
                left -= diff;
                elemTransformOrigin.horizontal += diff;
            } else if (right > widthThreshold) {
                const diff = right - widthThreshold;
                left -= diff;
                elemTransformOrigin.horizontal += diff;
            }

            return {
                top: `${Math.round(top)}px`,
                left: `${Math.round(left)}px`,
                transformOrigin: getTransformOriginValue(elemTransformOrigin)
            };
        },
        [anchorEl, anchorReference, getAnchorOffset, getContentAnchorOffset, getTransformOrigin, marginThreshold]
    );

    const setPositioningStyles = useCallback(() => {
        const element = paperRef.current;

        if (!element) {
            return;
        }

        const positioning = getPositioningStyle(element);

        if (positioning.top !== null) {
            element.style.top = positioning.top;
        }
        if (positioning.left !== null) {
            element.style.left = positioning.left;
        }
        element.style.transformOrigin = positioning.transformOrigin;
    }, [getPositioningStyle]);

    const handleEntering = (element, isAppearing) => {
        setPositioningStyles();

        if (TransitionProps?.onEntering) {
            TransitionProps.onEntering(element, isAppearing);
        }
    };

    useEffect(() => {
        if (open) {
            setPositioningStyles();
        }
    });

    useImperativeHandle(
        action,
        () =>
            open
                ? {
                      updatePosition: () => {
                          setPositioningStyles();
                      }
                  }
                : null,
        [open, setPositioningStyles]
    );

    useEffect(() => {
        if (!open) {
            return undefined;
        }

        const handleResize = debounce(() => {
            setPositioningStyles();
        }, 166);

        const containerWindow = ownerWindow(anchorEl as any);
        containerWindow.addEventListener('resize', handleResize);
        return () => {
            handleResize.cancel();
            containerWindow.removeEventListener('resize', handleResize);
        };
    }, [anchorEl, open, setPositioningStyles]);

    // If the container prop is provided, use that
    // If the anchorEl prop is provided, use its parent body element as the container
    // If neither are provided let the Modal take care of choosing the container
    const container = containerProp || (anchorEl ? ownerDocument(getAnchorEl(anchorEl)).body : undefined);

    return (
        <Modal
            container={container}
            open={open}
            ref={ref}
            BackdropProps={{ invisible: true }}
            className={cx(styles.root, className)}
            {...other}
        >
            <Fade appear in={open} timeout={200} {...TransitionProps} onEntering={handleEntering}>
                <Paper
                    elevation={elevation}
                    ref={paperRef}
                    {...PaperProps}
                    className={cx(styles.paper, PaperProps.className)}
                >
                    {children}
                </Paper>
            </Fade>
        </Modal>
    );
});
