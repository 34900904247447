/**
 * @category Util
 */
export function ownerDocument(node: Node | undefined): Document {
    return (node && node.ownerDocument) || document;
}

/**
 * @category Util
 */
export function ownerWindow(node: Node | undefined): Window {
    const doc = ownerDocument(node);
    return doc.defaultView || window;
}
