import React, { FC, useCallback, useContext, useRef, useState } from 'react';
import { useForkRef, useSyncEffect } from '../util';

import { TableHeader, TableHeaderProps } from '../Table/TableHeader';
import { RenderDataGridHeader } from './components/RenderHeader';
import { DataGridContext } from './DataGridContext';

/**
 * The props for the {@link DataGridHeader} component.
 * @category Props
 */
interface DataGridHeaderProps extends TableHeaderProps {}

/**
 * @category Component
 * @group Data Grid
 */
export const DataGridHeader: FC<DataGridHeaderProps> = ({
    className,
    blockLayout: blockLayoutProp,
    children = <RenderDataGridHeader />,
    style: styleProp,
    ...other
}) => {
    const instance = useContext(DataGridContext);
    const { bodyRef, data, settings, events } = instance;

    const { blockLayout, HeaderComponent = TableHeader } = settings;

    let isBlockLayout = blockLayoutProp || blockLayout;

    const headerRef = useRef<HTMLDivElement>();

    const [scrollSize, setScrollSize] = useState(0);

    const updateScrollWidth = useCallback(
        (event: any = undefined) => {
            const body = (bodyRef.current as any) ?? event?.target;
            if (headerRef.current && body) {
                headerRef.current.scrollLeft = body.scrollLeft;
                const scrollWidth = body.offsetWidth - body.clientWidth;
                if (scrollSize != scrollWidth) {
                    setScrollSize(scrollWidth);
                }
            }
        },
        [bodyRef, scrollSize]
    );

    useSyncEffect(() => {
        updateScrollWidth();
    }, [data, bodyRef?.current?.clientWidth, updateScrollWidth]);

    const handleRef = useForkRef(headerRef, () => updateScrollWidth());

    useSyncEffect(() => {
        const bodyScrollHandler = updateScrollWidth;
        const observer = new ResizeObserver(bodyScrollHandler);

        const handleBodyMount = (element) => {
            if (element) {
                observer.observe(element);
            }

            bodyScrollHandler({
                target: element
            });
        };

        events.addListener('bodyMount', handleBodyMount);
        events.addListener('scroll', bodyScrollHandler);
        events.addListener('virtualScroll', bodyScrollHandler);

        return () => {
            observer.disconnect();
            events.removeListener('bodyMount', handleBodyMount);
            events.removeListener('scroll', bodyScrollHandler);
            events.removeListener('virtualScroll', bodyScrollHandler);
        };
    }, [events, updateScrollWidth]);

    const style = {
        ...styleProp,
        marginRight: scrollSize
    };

    return (
        <HeaderComponent ref={handleRef} blockLayout={isBlockLayout} style={style} {...other}>
            {children}
        </HeaderComponent>
    );
};
