import { combineReducers } from 'redux';
import { rootApi } from './rootApi';

import casesReducer from './cases/CasesSlice';
import wellGorCaseReducer from './cases/wellGor/wellGorCaseSlice';
import wellPICaseReducer from './cases/wellPI/wellPICaseSlice';
import wellPressureCaseReducer from './cases/wellPressure/wellPressureCaseSlice';
import { constraintsAsInputsReducer } from './constraints-as-inputs/constrainsAsInputsSlice';
import dashboardDraReducer from './dashboard-dra/draSlice';
import dashboardWedgeLineReducer from './dashboard-wedge-line/wedgeLineSlice';
import dashboardWedgeReducer from './dashboard-wedge/wedgeSlice';
import dashboardOutlookReducer from './dashboardOutlook/dashboardOutlookSlice';
import inputQCReducer from './inputQC/inputQCSlice';
import lineupHistoryReducer from './lineup-history/lineupHistorySlice';
import { officialInputPageReducer } from './official-input-page/OfficialInputPageSlice';
import officialInputReducer from './official-inputs/OfficialInputSlice';
import p6Reducer from './p6/p6Slice';
import plantInputsReducer from './plant-inputs/plantInputsSlice';
import scenariosReducer from './scenarios/scenariosSlice';
import schemeReducer from './scheme/schemeSlice';
import { useAsInputsReducer } from './use-as-inputs/useAsInputsSlice';
import wellGorReducer from './wellGor/wellGorSlice';
import wellInputHistoryReducer from './wellInput-history/wellInputHistorySlice';
import wellPIReducer from './wellPI/wellPISlice';
import wellPressureReducer from './wellPressure/wellPressureSlice';

export const rootReducer = combineReducers({
  scenarios: scenariosReducer,
  cases: casesReducer,
  officialInput: officialInputReducer,
  lineupHistory: lineupHistoryReducer,
  officialInputPage: officialInputPageReducer,
  wellInputHistory: wellInputHistoryReducer,
  wellPressure: wellPressureReducer,
  wellPressureCase: wellPressureCaseReducer,
  wellGor: wellGorReducer,
  wellGorCase: wellGorCaseReducer,
  wellPI: wellPIReducer,
  wellPICase: wellPICaseReducer,
  p6: p6Reducer,
  plantInputs: plantInputsReducer,
  inputQC: inputQCReducer,
  scheme: schemeReducer,
  dashboardDra: dashboardDraReducer,
  dashboardOutlook: dashboardOutlookReducer,
  dashboardWedge: dashboardWedgeReducer,
  dashboardWedgeLine: dashboardWedgeLineReducer,
  useAsInputs: useAsInputsReducer,
  constraintsAsInputs: constraintsAsInputsReducer,
  [rootApi.reducerPath]: rootApi.reducer
});
