import { CSSProperties } from 'react';
import { Position } from 'reactflow';

export const padding = 15;

export function getDedicatedHandleStyle(position: Position, offset: number): CSSProperties {
    const result: CSSProperties = {};

    const percent = offset; //(offset * (100 - padding * 2)) / 100 + padding;

    switch (position) {
        case 'top': {
            result.left = `${percent}%`;
            break;
        }
        case 'right': {
            result.top = `${percent}%`;
            break;
        }
        case 'bottom': {
            result.left = `${percent}%`;
            break;
        }
        case 'left': {
            result.top = `${percent}%`;
            break;
        }
    }

    return result;
}
