import { DashboardResultClient, DashboardResultDto, PlantType, WedgeMeterStationClient, WedgeWellMeterStationIdsDto } from 'api';
import { rootApi } from 'store/rootApi';
import { WS_WEDGE_METER_STATION_CACHE_TAG, WS_WEDGE_METER_STATION_RESULTS_CACHE_TAG } from './constants';

type GetWedgeWellResultsQuery = {
  scenarioCaseId: number,
  plantType: PlantType,
  wells: WedgeWellMeterStationIdsDto[]
  ms: number[]
}

type GetWellAndMSProductionResultsData = {
  data: { wellProductionResults: DashboardResultDto[], msProductionResults: DashboardResultDto[] }
}

const service = new WedgeMeterStationClient();
const resultsService = new DashboardResultClient();

export const wedgeMeterStationApi = rootApi
  .enhanceEndpoints({
    addTagTypes: [WS_WEDGE_METER_STATION_CACHE_TAG, WS_WEDGE_METER_STATION_RESULTS_CACHE_TAG]
  })
  .injectEndpoints({
    endpoints: (builder) => ({
      getAll: builder.query({
        queryFn: (wedgeId?: number) =>
          service
            .get(wedgeId)
            .then((data) => ({ data }))
            .catch((error) => ({ error })),
        providesTags: (_result, _error, wedgeId) => [{ type: WS_WEDGE_METER_STATION_CACHE_TAG, id: wedgeId }]
      }),
      getWellAndMSProductionResults: builder.query({
        queryFn: (scenarioCaseId: number): Promise<GetWellAndMSProductionResultsData> => {
          const promises = [
            resultsService
              .getWellRates(scenarioCaseId),
            resultsService
              .getMsRates(scenarioCaseId),
          ];
          return Promise.all(promises)
            .then(([wellProductionResults, msProductionResults]) => ({
              data: {
                wellProductionResults: wellProductionResults || [],
                msProductionResults: msProductionResults || []
              },
            }));
        },
        providesTags: (_result, _error, scenarioCaseId: number) =>
          [{ type: WS_WEDGE_METER_STATION_RESULTS_CACHE_TAG, id: scenarioCaseId }]
      }),
      getWedgeWellResults: builder.mutation({
        queryFn: ({ scenarioCaseId, plantType, wells, ms }: GetWedgeWellResultsQuery) => {
          const promises = [];
          if (wells.length > 0) {
            promises.push(
              service
                .getWedgeWellResults(scenarioCaseId, plantType, wells)
                .then((data) => ({ data }))
                .catch((error) => ({ error }))
            );
          } else {
            promises.push(Promise.resolve([]));
          }
          if (ms.length > 0) {
            promises.push(
              service
                .getWedgeMeterStationsResults(scenarioCaseId, plantType, ms)
                .then((data) => ({ data }))
                .catch((error) => ({ error }))
            );
          } else {
            promises.push(Promise.resolve([]));
          }
          return Promise.all(promises)
            .then(([wellResults, msResults]) => ({
              data: {
                wellResults: wellResults.data,
                msResults: msResults.data
              },
            }));
        }
      }),
    }),
  });
