import { Navigate, RouteObject } from 'react-router-dom';
import { lazyComponent } from 'utils/lazyComponent';

const Korolev = lazyComponent(() => import('./Korolev'), 'korolev');
const KorolevTable = lazyComponent(() => import('./table/KorolevTable'), 'korolevTable');
const KorolevChart = lazyComponent(() => import('./chart/KorolevChart'), 'korolevChart');

export const korolevRoute: RouteObject = {
  path: 'korolev',
  element: <Korolev />,
  children: [
    {
      index: true,
      element: <Navigate replace to="table" />
    },
    {
      path: 'table',
      element: <KorolevTable />
    },
    {
      path: 'chart',
      element: <KorolevChart />
    }
  ]
};
