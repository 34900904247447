import cx from 'classnames';
import { ComponentProps } from 'react';
import { Loader } from '../Loader';

import styles from './LoadingOverlay.module.css';

interface LoadingOverlayProps extends ComponentProps<'div'> {
  loading?: boolean;
}

export const LoadingOverlay: React.FC<LoadingOverlayProps> = ({ loading, className, children, ...other }) => {
  return (
    <div className={cx(styles.container, className)} {...other}>
      {children}
      {loading ? (
        <div className={styles.loader}>
          <Loader />
        </div>
      ) : null}
    </div>
  );
};
