import { FilledInput, Input as StandartInput, OutlinedInput, useFormControl } from '@mui/material';
import { InputProps } from '@tearecs/components/src/Input/Input';
import { forwardRef, Ref } from 'react';

const variantComponent = {
  standard: StandartInput,
  filled: FilledInput,
  outlined: OutlinedInput
};

export const Input = forwardRef(function Input(
  props: Omit<InputProps<never>, 'ref' | 'color' | 'size' | 'onChange'>,
  ref: Ref<HTMLInputElement>
) {
  const formControl = useFormControl();
  const Component = variantComponent[formControl?.variant || 'standard'];
  return <Component inputRef={ref} {...props} />;
});
