import React, { cloneElement, ComponentType, FC, memo, PropsWithChildren, ReactElement } from 'react';
import {
    Annotation as ReactAnnotation,
    BracketNote,
    Connector,
    ConnectorCurve,
    ConnectorElbow,
    ConnectorLine,
    Note,
    Subject,
    SubjectBadge,
    SubjectBracket,
    SubjectCircle,
    SubjectCustom,
    SubjectRect,
    SubjectThreshold
} from 'react-annotation';
import { AnnotationNoteProps, AnnotationOptions } from '../chartTypes';

import { setRef } from '../../util';
import styles from './ChartAnnotation.module.css';

/**
 * Props for the {@link ChartPointAnnotation} component.
 */
export interface ChartPointAnnotationProps extends AnnotationOptions {
    noteRef?: any;
}

const subjectVariants = {
    default: <Subject />,
    circle: <SubjectCircle />,
    rect: <SubjectRect />,
    threshold: <SubjectThreshold />,
    custom: <SubjectCustom />,
    bracket: <SubjectBracket />,
    badge: <SubjectBadge />
};

const connectorVariants = {
    default: <Connector />,
    line: <ConnectorLine />,
    elbow: <ConnectorElbow />,
    curve: <ConnectorCurve />
};

const noteVariants = {
    default: <Note />,
    bracket: <BracketNote />
};

/**
 * @category Component
 * @group Chart
 */
export const ChartPointAnnotation = memo(
    ({
        point,
        axis,
        subject = 'default',
        subjectProps,
        connector = 'line',
        connectorProps,
        note = 'default',
        noteProps,
        noteRef,
        children,
        ...other
    }: PropsWithChildren<ChartPointAnnotationProps>) => {
        //const [manualPosition, setManualPosition] = useState({ nx: undefined, ny: undefined });

        const Subject =
            typeof subject == 'string' && subjectVariants[subject]
                ? subjectVariants[subject]
                : (subject as ReactElement);
        const Connector =
            typeof connector == 'string' && connectorVariants[connector]
                ? connectorVariants[connector]
                : (connector as ReactElement);
        const Note = typeof note == 'string' && noteVariants[note] ? noteVariants[note] : (note as ReactElement);

        return (
            <ReactAnnotation {...other}>
                {children}
                {cloneElement(Subject, subjectProps)}
                {cloneElement(Connector, connectorProps)}
                {cloneElement(Note, {
                    lineType: 'vertical',
                    className: styles.note,
                    ...noteProps,
                    //...manualPosition,
                    ref: (element) => {
                        //setRef(drag, element?.note);
                        setRef(noteRef, element);
                    }
                } as AnnotationNoteProps)}
            </ReactAnnotation>
        );
    }
);

/**
 * Props for the {@link ChartAnnotation} component.
 */
export interface ChartAnnotationProps extends AnnotationOptions {
    component?: ComponentType<AnnotationOptions>;
}

/**
 * @category Component
 * @group Chart
 */
let ChartAnnotation: FC<ChartAnnotationProps> = ({ component: Component = ChartPointAnnotation, ...other }) => {
    return <Component {...other} />;
};

ChartAnnotation.displayName = 'ChartAnnotation';
ChartAnnotation = memo(ChartAnnotation);

/** @ignore */
export { ChartAnnotation };
