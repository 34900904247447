import { faCaretDown, faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { FC, forwardRef, Ref, useCallback, useContext, useMemo, useRef, useState } from 'react';
import { Controller, useFieldArray, useForm, useWatch } from 'react-hook-form';
import { PopoverBody } from 'reactstrap';
import { useDebouncedCallback } from 'use-debounce/lib';
import { Button } from '../../Button/Button';
import { Checkbox } from '../../Checkbox';
import { TextField } from '../../Input';
import { Popover } from '../../Popover';
import { Toolbar } from '../../Toolbar';
import { DataGridContext } from '../DataGridContext';
import { DataGridColumnInstance } from '../types/DataGridColumnInstance';

import { serviceColumns } from '../utils/serviceColumns';

const hiddenColumns = Object.values(serviceColumns);

interface ColumnChooserContentProps {
    onClose?: () => void;
    onChange?: (hiddenColumns: string[]) => void;
}

const ColumnChooserContent = forwardRef(function ColumnChooserContent(
    { onChange, onClose }: ColumnChooserContentProps,
    ref: Ref<HTMLDivElement>
) {
    const [search, setSearch] = useState('');
    const [filter, setFilter] = useState('');

    const applyFilter = useDebouncedCallback(
        (value: string) => {
            setFilter(value);
        },
        1000,
        { maxWait: 2000 }
    );

    const handleSearchChange = useCallback(
        (event) => {
            const value = event.target.value;
            setSearch(value);
            applyFilter.callback(value);
        },
        [applyFilter]
    );

    const instance = useContext(DataGridContext);

    const { allColumns } = instance;

    const { register, setValue, control, handleSubmit } = useForm({
        defaultValues: {
            column: allColumns
        }
    });

    const { fields } = useFieldArray<DataGridColumnInstance<any>>({
        control,
        name: 'column'
    });

    const state = useWatch({
        control,
        name: 'column',
        defaultValue: allColumns
    });

    const rows = useMemo(
        () =>
            fields
                .map((column, index): [Partial<DataGridColumnInstance<any>>, number] => [column, index])
                .filter(([column]) => !hiddenColumns.includes(column.id)),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [state, fields]
    );

    const onSubmit = useCallback(
        (values, event) => {
            const hiddenColumns = rows
                .filter(([_, index]) => !values.column[index].isVisible)
                .map(([_, index]) => allColumns[index].id);
            instance.setHiddenColumns(hiddenColumns);

            onChange?.(hiddenColumns);
            onClose?.();
        },
        [rows, instance, onChange, allColumns]
    );

    const handleFormSubmit = useCallback(
        (event) => {
            event.stopPropagation();
            event.preventDefault();
            handleSubmit(onSubmit)(event);
        },
        [handleSubmit, onSubmit]
    );

    const selected = useMemo(
        () => rows.reduce((acc, [_, index]) => (state[index].isVisible ? acc + 1 : acc), 0),
        [state, rows]
    );

    const indeterminate = useMemo(() => selected > 0 && selected < rows.length, [rows, selected]);

    const [all, setAll] = useState(selected == rows.length);

    const handleAll = useCallback(() => {
        const newState = !all;

        fields.forEach((_, index) => setValue(`column[${index}].isVisible`, newState));
        setAll(newState);
    }, [all, fields, setValue]);

    return (
        <div ref={ref}>
            <PopoverBody>
                <TextField
                    fullWidth
                    disableMargin
                    startAdornment={<FontAwesomeIcon icon={faSearch} />}
                    value={search}
                    onChange={handleSearchChange}
                />
            </PopoverBody>
            <form onSubmit={handleFormSubmit}>
                <PopoverBody style={{ maxHeight: 200, overflow: 'auto' }}>
                    <Checkbox
                        fullWidth
                        name="all"
                        indeterminate={indeterminate}
                        label="Select all"
                        checked={all}
                        onChange={handleAll}
                    />
                    {rows
                        .filter(([column]) => {
                            const name =
                                column.name ??
                                (typeof column.Header == 'string' ? column.Header.toString() : null) ??
                                column.id;

                            if (!name.toLowerCase().includes(filter.toLowerCase())) return false;

                            return true;
                        })
                        .map(([column, index]) => (
                            <Controller
                                key={index}
                                name={`column[${index}].isVisible`}
                                control={control}
                                render={({ value, onChange }) => (
                                    <Checkbox
                                        fullWidth
                                        label={column.name ?? (column.render('Header') as any)}
                                        checked={value}
                                        onChange={(event) => onChange(event.target.checked)}
                                    />
                                )}
                            />
                        ))}
                </PopoverBody>
                <Toolbar disablePadding={false}>
                    <div style={{ flex: 1 }} />
                    <Button color="primary" type="submit" size="small">
                        Ok
                    </Button>
                    <Button size="small" onClick={onClose}>
                        Cancel
                    </Button>
                </Toolbar>
            </form>
        </div>
    );
});

/**
 * The props of the {@link DataGridColumnChooser} component.
 */
export interface DataGridColumnChooserProps extends Pick<ColumnChooserContentProps, 'onChange'> {}

/**
 * @category Component
 * @group Data Grid
 */
export const DataGridColumnChooser: FC<DataGridColumnChooserProps> = ({ onChange }) => {
    const buttonRef = useRef();

    const [open, setOpen] = useState(false);

    const handleToggle = useCallback(() => {
        setOpen((state) => !state);
    }, []);

    const handleClose = useCallback(() => {
        setOpen(false);
    }, []);

    return (
        <>
            <Button ref={buttonRef} endIcon={<FontAwesomeIcon icon={faCaretDown} />} onClick={handleToggle}>
                Columns
            </Button>
            <Popover
                anchorEl={buttonRef}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right'
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right'
                }}
                open={open}
                onClose={handleClose}
            >
                <ColumnChooserContent onClose={handleClose} onChange={onChange} />
            </Popover>
        </>
    );
};
