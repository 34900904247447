import React, { cloneElement, FC, PropsWithChildren, ReactElement, useContext } from 'react';
import { ChartContext } from '../ChartContext';
import { componentName, getScaleForAccessor } from '../utils';

const legendData = [
    { x: 1, y: 3, open: 0, close: 6 },
    { x: 3, y: 3, open: 0, close: 6 },
    { x: 5, y: 3, open: 0, close: 6 }
];

const differenceData1 = [
    { x: 1, y: 1, open: 0, close: 3 },
    { x: 3, y: 1, open: 0, close: 3 },
    { x: 5, y: 1, open: 0, close: 3 }
];

const differenceData2 = [
    { x: 1, y: 5, open: 3, close: 6 },
    { x: 3, y: 5, open: 3, close: 6 },
    { x: 5, y: 5, open: 3, close: 6 }
];

const previewHeight = 20;
const previewWidth = 20;

const xAccessor = 'x';
const yAccessor = 'y';
const openAccessor = 'open';
const closeAccessor = 'close';

const seriesProps = {
    data: legendData,
    axis: 'undefined',
    xAccessor,
    yAccessor,
    openAccessor,
    closeAccessor,
    renderLabel: undefined
};

const bandScale = getScaleForAccessor({
    data: legendData,
    minAccessor: (x) => x.x,
    maxAccessor: (x) => x.x,
    range: [0, previewWidth],
    type: 'band',
    domain: [0, 6],
    paddingInner: 0.4,
    paddingOuter: 1
});

const xScale = getScaleForAccessor({
    data: legendData,
    minAccessor: (x) => x.x,
    maxAccessor: (x) => x.x,
    range: [0, previewWidth],
    type: 'linear',
    domain: [0, 6]
});

xScale.barWidth = bandScale.bandwidth();
xScale.barOffset = xScale.barWidth / 2;

const yScale = getScaleForAccessor({
    data: legendData,
    minAccessor: (y) => y.y,
    maxAccessor: (y) => y.y,
    range: [previewHeight, 0],
    type: 'linear',
    domain: [0, 6]
});

yScale.barWidth = 2;
yScale.barOffset = 1;

const context = {
    brush: {
        state: {
            x: null,
            y: null
        }
    },
    legend: {
        state: {}
    },
    margin: {
        top: 0,
        bottom: 0,
        left: 0,
        right: 0
    },
    innerWidth: 20,
    innerHeight: 20,
    getX: (x) => x.x,
    getY: (x) => x.y,
    xScales: {
        undefined: xScale
    },
    yScales: {
        undefined: yScale
    },
    tooltip: null,
    handleClick: null,
    handleMouseDown: null,
    handleMouseLeave: null,
    handleMouseMove: null
};

/**
 * @category Component
 * @group Chart
 */
export const ChartSeriesPreviewProvider: FC<PropsWithChildren<unknown>> = ({ children }) => {
    const instance = useContext(ChartContext);

    return (
        <ChartContext.Provider
            value={{
                ...instance,
                ...context,
                legend: {
                    ...instance.legend,
                    ...context.legend
                },
                brush: {
                    ...instance.brush,
                    ...context.brush
                }
            }}
        >
            {children}
        </ChartContext.Provider>
    );
};

/**
 * The props for the {@link ChartSeriesPreview} component.
 */
export interface ChartSeriesPreviewProps {
    children?: ReactElement;
}

/**
 * @category Component
 * @group Chart
 */
export const ChartSeriesPreview: FC<ChartSeriesPreviewProps> = ({ children }) => {
    const name = componentName(children);
    let child = null;

    if (name == 'AreaDifferenceSeries') {
        child = cloneElement(children, {
            ...seriesProps,
            //key: 'preview-' + children.props.key,
            children: React.Children.map(children.props.children, (Child, index) =>
                cloneElement(Child, {
                    ...seriesProps,
                    //key: 'preview-' + children.props.key + Child.props.key,
                    data: index % 2 == 0 ? differenceData1 : differenceData2
                })
            )
        });
    } else if (name == 'ScatterSeries') {
        child = cloneElement(children, {
            ...seriesProps,
            disableMouseEvents: true,
            data: [
                {
                    ...children.props.data?.[0],
                    x: 3,
                    y: 2,
                    open: 0,
                    close: 6
                }
            ]
        });
    } else {
        child = cloneElement(children, {
            ...seriesProps
            //key: 'preview-' + children.props.key
        });
    }

    return (
        <svg height={previewHeight} width={previewWidth}>
            {child}
        </svg>
    );
};
