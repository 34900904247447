import { WellPressureClient } from 'api';
import { createAppAsyncThunk } from 'store/createAppAsyncThunk';
import { GetWellPressurePayload } from 'store/wellPressure/types/GetWellPressurePayload';
import { wellPressureCaseStateSelector } from '../wellPressureCaseSelectors';
import { WELL_PRESSURE_CASE_UNDO_CLEAR } from '../wellPressureCaseSlice';

export interface WellPressureCaseParams {
  scenarioCaseId: number;
}

const service = new WellPressureClient();

export const getWellPressureCase = createAppAsyncThunk(
  'wellPressureCase/get',
  async ({ scenarioCaseId }: WellPressureCaseParams, { dispatch }): Promise<GetWellPressurePayload> => {
    dispatch({ payload: undefined, type: WELL_PRESSURE_CASE_UNDO_CLEAR });

    return { items: await service.getScenario(scenarioCaseId) };
  },
  {
    condition: (arg, { getState }) => {
      const wellPressure = wellPressureCaseStateSelector(getState());
      if (!wellPressure.loading && wellPressure.data === undefined) {
        return true;
      }

      if (wellPressure.arg && arg.scenarioCaseId !== wellPressure.arg.scenarioCaseId) {
        return true;
      }

      return false;
    }
  }
);
