import { createSelector } from '@reduxjs/toolkit';
import { FlagTab } from 'api';
import { RootState } from 'store';
import { flagsApi } from 'store/flags/flagsApi';
import { headersApi } from 'store/headers/headersApi';
import { meterStationsApi } from 'store/meterStations/meterStationsApi';
import { pipelinesApi } from 'store/pipelines/pipelinesApi';
import { plantsApi } from 'store/plants/plantsApi';
import { wellsApi } from 'store/wells/wellsApi';
import { getP6Snapshot } from './utils/getP6Snapshot';

export const p6StateSelector = (state: RootState) => state.p6;
export const p6SnapshotSelector = createSelector(
  [
    p6StateSelector,
    headersApi.endpoints.getAllHeaders.select(),
    pipelinesApi.endpoints.getAllPipelines.select(),
    meterStationsApi.endpoints.getAllMeterStations.select(),
    wellsApi.endpoints.getAllWells.select(),
    plantsApi.endpoints.getAllPlants.select(),
    flagsApi.endpoints.getFlags.select(FlagTab.P6)
  ],
  (p6, headers, pipelines, meterStations, wells, plants, flags) =>
    getP6Snapshot(p6, {
      headers: headers.data,
      pipelines: pipelines.data,
      meterStations: meterStations.data,
      wells: wells.data,
      plants: plants.data,
      flags: flags.data
    })
);

export const p6StatusSelector = createSelector(p6StateSelector, (p6) => {
  const hasChanges = p6.edits.size > 0;
  return {
    hasChanges
  };
});
