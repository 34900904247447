import ArrowKeysReact from 'arrow-keys-react';
import cx from 'classnames';
import React, { forwardRef } from 'react';
import { Button } from '../Button/Button';
import { Paper, PaperProps } from '../Paper/Paper';
import { Calendar, CalendarProps } from './Calendar';
import { CalendarContext, CalendarOptions } from './CalendarContext';
import styles from './Calendars.module.css';
import { useCalendar } from './useCalendar';
import { CLIENT_UTC_OFFSET } from './utils';

/**
 * The props for the {@link Calendars} component.
 * @category Props
 */
export interface CalendarsProps extends Omit<CalendarOptions, 'onDateSelected'> {
    enableToday?: boolean;
    enableClear?: boolean;
    CalendarProps?: Partial<CalendarProps>;
}

/**
 * @category Component
 * @group Date Picker
 */
export const Calendars = forwardRef<HTMLDivElement, CalendarsProps & Omit<PaperProps, 'onChange' | 'selected'>>(
    (props, ref) => {
        const {
            disabled,
            enableToday,
            enableClear,
            className,
            monthsToDisplay,
            CalendarProps,
            defaultDepth,
            minDepth = 'month',
            selectRange,
            date,
            maxDate,
            minDate,
            firstDayOfWeek,
            showOutsideDays,
            value,
            offset,
            onOffsetChanged,
            onChange,
            DayWrapper,
            MonthWrapper,
            YearWrapper,
            DayComponent,
            MonthComponent,
            YearComponent,
            utcOffset = CLIENT_UTC_OFFSET,
            ...other
        } = props;

        const instance = useCalendar({
            disabled,
            defaultDepth,
            minDepth,
            selectRange,
            date,
            maxDate,
            minDate,
            firstDayOfWeek,
            showOutsideDays,
            value,
            offset,
            onOffsetChanged,
            DayWrapper,
            MonthWrapper,
            YearWrapper,
            DayComponent,
            MonthComponent,
            YearComponent,
            utcOffset,
            monthsToDisplay: monthsToDisplay ?? (props.selectRange ? 2 : 1),
            onChange
        });

        const { handleResetHover, handleClear, calendars } = instance;

        const getKeyOffset = (number: number) => {
            const e = document.activeElement;
            const buttons = document.querySelectorAll('button');
            buttons.forEach((el, i) => {
                const newNodeKey = i + number;
                if (el == e) {
                    if (newNodeKey <= buttons.length - 1 && newNodeKey >= 0) {
                        buttons[newNodeKey].focus();
                    } else {
                        buttons[0].focus();
                    }
                }
            });
        };

        ArrowKeysReact.config({
            left: () => {
                getKeyOffset(-1);
            },
            right: () => {
                getKeyOffset(1);
            },
            up: () => {
                getKeyOffset(-7);
            },
            down: () => {
                getKeyOffset(7);
            }
        });

        return (
            <CalendarContext.Provider value={instance}>
                <Paper ref={ref} className={cx(className, styles.root)} {...other}>
                    <div className={styles.calendars} onMouseLeave={handleResetHover}>
                        {calendars.map((calendar, index) => (
                            <Calendar
                                disabled={disabled}
                                date={
                                    props.selectRange || Array.isArray(props.value)
                                        ? props.value?.[index]
                                        : index == 0
                                        ? props.value
                                        : undefined
                                }
                                elevation={0}
                                enableClear={false}
                                enableToday={enableToday}
                                calendar={calendar}
                                key={`${calendar.month}${calendar.year}`}
                                DayWrapper={props.DayWrapper}
                                MonthWrapper={props.MonthWrapper}
                                YearWrapper={props.YearWrapper}
                                DayComponent={props.DayComponent}
                                MonthComponent={props.MonthComponent}
                                YearComponent={props.YearComponent}
                                {...CalendarProps}
                            />
                        ))}
                    </div>
                    {enableClear && (
                        <Button disabled={disabled} size="small" onClick={handleClear}>
                            Clear
                        </Button>
                    )}
                </Paper>
            </CalendarContext.Provider>
        );
    }
);
