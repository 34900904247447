import MenuIcon from '@mui/icons-material/Menu';
import PersonIcon from '@mui/icons-material/Person';
import { Link, Popover } from '@mui/material';
import AppBar from '@mui/material/AppBar';
import IconButton from '@mui/material/IconButton';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { useUserData } from 'components/Security/useUserData';
import React from 'react';
import { NavLink } from 'react-router-dom';

function Header(props) {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const { hasSaveRole, name, username } = useUserData();

  return (
    <AppBar position="fixed" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
      <Toolbar>
        <IconButton
          size="large"
          edge="start"
          color="inherit"
          aria-label="menu"
          sx={{ mr: 2 }}
          onClick={props.toggleSidebar}
        >
          <MenuIcon />
        </IconButton>
        <Link to="/" component={NavLink} sx={{ textDecoration: 'none', display: 'flex', alignItems: 'center' }}>
          <img style={{ maxWidth: 50 }} src="/images/logo.svg" />
          <Typography variant="h6" sx={{ ml: 1, color: 'white' }}>
            WS Dashboard
          </Typography>
        </Link>
        <IconButton color="inherit" sx={{ ml: 'auto' }} onClick={handleClick}>
          <PersonIcon />
        </IconButton>
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right'
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right'
          }}
          sx={{
            width: '400px',
            '& .MuiPaper-root': { padding: '13px' }
          }}
          onClose={handleClose}
        >
          <Typography noWrap variant="h6" title={name}>
            {name}
          </Typography>
          <Typography noWrap color="textSecondary" title={username}>
            {username}
          </Typography>
          {hasSaveRole ? null : <Typography variant="caption">View only</Typography>}
        </Popover>
      </Toolbar>
    </AppBar>
  );
}

export default Header;
