import { DashboardResultClient } from 'api';
import { isNil } from 'lodash';
import { createAppAsyncThunk } from 'store/createAppAsyncThunk';
import { selectDashboardDraByCaseId, selectDashboardDraState } from '../draSelectors';

const service = new DashboardResultClient();

export const getDashboardDraData = createAppAsyncThunk(
  'dashboard/dra/get',
  (scenarioCaseId: number) => service.getDashboardDra(scenarioCaseId),
  {
    condition: (arg, { getState }) => {
      const draState = selectDashboardDraState(getState());
      const dra = selectDashboardDraByCaseId(arg)(getState());

      return !draState.loading && isNil(dra);
    }
  }
);
