import moment from 'moment';
import React, { useCallback } from 'react';
import { FieldError, useController, useForm } from 'react-hook-form';
import { PopoverBody } from 'reactstrap';
import { Button } from '../../Button/Button';
import { Checkbox } from '../../Checkbox';
import { DatePicker } from '../../DatePicker';
import { Select } from '../../Input';
import { Toolbar } from '../../Toolbar';
import { DataGridColumnOptions } from '../types';
import { DataGridFilterProps } from '../types/DataGridFilterProps';

const BLANK_OPTIONS: any[] = [undefined, null, ''];

export enum DateFilterMode {
    Blank,
    NotBlank,
    After,
    Before,
    On,
    Between
}

const valueModes = [DateFilterMode.On, DateFilterMode.After, DateFilterMode.Before, DateFilterMode.Between] as const;
const fuzzyModes = [DateFilterMode.After, DateFilterMode.Before, DateFilterMode.Between] as const;

const modeNames: Partial<Record<DateFilterMode, string>> = {
    [DateFilterMode.NotBlank]: 'Not blank'
};

const modeOptions = Object.values(DateFilterMode)
    .filter((value) => typeof value === 'string')
    .map((option) => {
        const value = DateFilterMode[option];
        return {
            value,
            label: modeNames[value] ?? option
        };
    });

export interface DateFilterValue {
    mode: DateFilterMode;
    date: Date | [Date, Date] | undefined;
    include?: boolean;
}

function filterOptions(rowValue: Date, filterValue: DateFilterValue) {
    const rowMoment = moment(rowValue);
    const isValid = rowValue && rowMoment.isValid() && !BLANK_OPTIONS.includes(rowValue);
    switch (filterValue.mode) {
        case DateFilterMode.Blank:
            return BLANK_OPTIONS.includes(rowValue);
        case DateFilterMode.NotBlank:
            return isValid;
        case DateFilterMode.On:
            return isValid && rowMoment.isSame(filterValue.date as Date, 'day');
        case DateFilterMode.After: {
            const method = filterValue.include ? 'isSameOrAfter' : 'isAfter';
            return isValid && rowMoment[method](filterValue.date as Date, 'day');
        }
        case DateFilterMode.Before: {
            const method = filterValue.include ? 'isSameOrBefore' : 'isBefore';
            return isValid && rowMoment[method](filterValue.date as Date, 'day');
        }
        case DateFilterMode.Between: {
            const [start, end] = filterValue.date as [Date, Date];
            const inclusivity = filterValue.include ? '[]' : '()';
            return isValid && rowMoment.isBetween(start, end, 'day', inclusivity);
        }
        default:
            return true;
    }
}

export const filterDate: DataGridColumnOptions['filter'] = (rows, id, filterValue: DateFilterValue) => {
    return rows.filter((row) => {
        const rowValue = row.values[id as any] as Date;
        return filterOptions(rowValue, filterValue);
    });
};

export const DataGridDateFilter = <T extends object>({
    column: { filterValue, setFilter },
    onClose
}: DataGridFilterProps<T>) => {
    const { errors, control, handleSubmit } = useForm<DateFilterValue>({
        shouldUnregister: false,
        defaultValues: filterValue as DateFilterValue
    });

    const {
        field: { value: selectedMode, onChange: selectMode }
    } = useController({
        name: 'mode',
        defaultValue: DateFilterMode.After,
        rules: { required: 'Please select mode' },
        control
    });

    const {
        field: { value: selectedDate, onChange: selectDate }
    } = useController({
        name: 'date',
        defaultValue: undefined,
        rules: {
            required: valueModes.includes(selectedMode) ? 'Please select date' : false,
            validate: {
                range: (value) => {
                    if (selectedMode === DateFilterMode.Between && (!Array.isArray(value) || value.length !== 2)) {
                        return 'Please select date range';
                    }

                    return true;
                }
            }
        },
        control
    });

    const {
        field: { value: includeValue, onChange: setInclude }
    } = useController({
        name: 'include',
        defaultValue: false,
        control
    });

    const onSubmit = useCallback(
        (result) => {
            setFilter(result);
            onClose();
        },
        [onClose, setFilter]
    );

    const handleClear = useCallback(() => {
        setFilter(undefined);
        onClose();
    }, [onClose, setFilter]);

    return (
        <>
            <form onSubmit={handleSubmit(onSubmit)}>
                <PopoverBody>
                    <Select
                        portal
                        options={modeOptions}
                        fields={{ label: 'label', value: 'value' }}
                        value={modeOptions.find((x) => x.value === selectedMode)}
                        onChange={(option) => selectMode(option.value)}
                    />
                    {valueModes.includes(selectedMode) ? (
                        <DatePicker
                            selectRange={selectedMode === DateFilterMode.Between}
                            value={selectedDate}
                            onChange={selectDate}
                            error={(errors.date as FieldError)?.message}
                        />
                    ) : null}
                    {fuzzyModes.includes(selectedMode) ? (
                        <Checkbox
                            label="Include date"
                            checked={includeValue}
                            onChange={(_, checked) => setInclude(checked)}
                        />
                    ) : null}
                </PopoverBody>
                <Toolbar disablePadding={false}>
                    <div style={{ flex: 1 }} />
                    <Button color="primary" type="submit" size="small">
                        Filter
                    </Button>
                    <Button onClick={handleClear} size="small">
                        Clear
                    </Button>
                </Toolbar>
            </form>
        </>
    );
};

export const DataGridDateFilterFull = {
    filter: filterDate,
    Filter: DataGridDateFilter
};
