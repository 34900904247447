import { WellPIClient, WellPIDto, WellPIInputDto } from 'api';
import { createAppAsyncThunk } from 'store/createAppAsyncThunk';
import { WellPIState } from '../WellPIState';
import { getWellPISnapshot } from '../utils/getWellPISnapshot';
import { wellPIPresentStateSelector } from '../wellPISelectors';

const service = new WellPIClient();

export function getWellPISaveState(wellPI: WellPIState): WellPIDto[] {
  const result: WellPIDto[] = [];

  const snapshot = getWellPISnapshot(wellPI);

  for (const msRow of snapshot) {
    for (const wellRow of msRow.subRows) {
      const wellPIInputs = Array.from(wellRow.inputs.entries()).map(
        ([weekDateId, input]) => new WellPIInputDto({ weekDateId, piValue: input.piValue })
      );

      const newDto = new WellPIDto({
        id: wellRow.originalId,
        wellId: wellRow.wellId,
        rateOfChange: wellRow.rateOfChange,
        piValue: wellRow.piValue,
        wellPIInputs
      });

      result.push(newDto);
    }
  }

  return result;
}

export const saveWellPI = createAppAsyncThunk('wellPI/save', (_: void, { getState }) => {
  const wellPI = wellPIPresentStateSelector(getState());

  const data = getWellPISaveState(wellPI);

  return service.save(data);
});
