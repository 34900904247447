import { AnyAction, createSlice, PayloadAction, Reducer } from '@reduxjs/toolkit';
import { castDraft } from 'immer';
import { toast } from 'react-toastify';
import undoable, { GroupByFunction, includeAction, StateWithHistory } from 'redux-undo';
import { handleError, handleLoading } from 'store/base/baseStateHandlers';
import { CopySimulationCaseType } from 'store/wellPressure/types/CopySimulationCaseType';
import { getWellGor } from './actions/getWellGor';
import { saveWellGor, sealWellGorChanges } from './actions/saveWellGor';
import { convertWellGorData, WellGorTableRow } from './utils/convertWellGorData';
import { copyWellGorColumns, pasteWellGorColumns } from './utils/copyPasteWellGor';
import {
  ChangeWellGorInputPayload,
  setWellGorInputBulkChange,
  setWellGorInputChange
} from './utils/wellGorInputChange';
import { WellGorState } from './WellGorState';

const initialState: WellGorState = {
  splitableWeekDateIds: new Set(),
  gorChanges: new Map(),
  inputChanges: new Map()
};

const { actions, reducer } = createSlice({
  initialState,
  name: 'wellGor',
  reducers: {
    reset(state) {
      state.gorChanges.clear();
      state.inputChanges.clear();
    },
    setWellGorInput(state, action: PayloadAction<ChangeWellGorInputPayload>) {
      setWellGorInputChange(state, action.payload);
    },
    setWellGorInputBulk(state, action: PayloadAction<ChangeWellGorInputPayload[]>) {
      setWellGorInputBulkChange(state, action.payload, false);
    },
    copyColumns(state, action: PayloadAction<number[]>) {
      copyWellGorColumns(state, state.rows, action.payload);
    },
    pasteColumns(state, action: PayloadAction<number[]>) {
      pasteWellGorColumns(state, action.payload);
    },
    copySimulationCaseWellGor(state, action: PayloadAction<CopySimulationCaseType<WellGorTableRow>>) {
      try {
        const { rows, columns, targetWeekDateIds } = action.payload;
        copyWellGorColumns(state, rows, columns);
        pasteWellGorColumns(state, targetWeekDateIds, false);
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);
        toast.error(`GOR: ${error instanceof Error ? error.message : 'Error on transfer data'}`);
      }
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getWellGor.pending, (state) => {
        handleLoading(state);
      })
      .addCase(getWellGor.fulfilled, (state, action) => {
        state.arg = castDraft(action.meta.arg);
        state.loading = false;
        state.data = action.payload.items;
        const { rows, dates, rowsLookup, datesByWeekNumber, splitableWeekDateIds, datesLookup } = convertWellGorData(
          action.payload.items,
          action.payload.adjustWeekDates
        );
        state.rows = rows;
        state.dates = dates;
        state.datesByWeekNumber = datesByWeekNumber;
        state.datesById = datesLookup;
        state.splitableWeekDateIds = splitableWeekDateIds;
        state.rowsLookup = rowsLookup;
      })
      .addCase(getWellGor.rejected, (state, action) => {
        state.arg = undefined;
        handleError(state, action);
      });

    builder.addCase(saveWellGor.fulfilled, (state) => {
      sealWellGorChanges(state);
      state.gorChanges.clear();
      state.inputChanges.clear();
    });
  }
});

export const wellGorActions = actions;
export const { reset, setWellGorInput, copyColumns, pasteColumns } = actions;

const groupBy: GroupByFunction<WellGorState, AnyAction> = (action) => {
  if (action.type === getWellGor.fulfilled.type) {
    return 'well-gor-data';
  }

  return null;
};

const getWellGorUndoableReducer = (
  reducer: Reducer<typeof initialState>
): Reducer<StateWithHistory<WellGorState>, AnyAction> =>
  undoable(reducer, {
    ignoreInitialState: true,
    groupBy,
    undoType: 'WELL_GOR_UNDO',
    clearHistoryType: 'WELL_GOR_UNDO_CLEAR',
    filter: includeAction([
      setWellGorInput.type,
      getWellGor.fulfilled.type,
      saveWellGor.fulfilled.type,
      pasteColumns.type,
      reset.type
    ])
  });

export default getWellGorUndoableReducer(reducer);
