import cx from 'classnames';
import React, { ForwardedRef, forwardRef, useCallback, useContext, useMemo } from 'react';

import { ChartContext } from '../ChartContext';

import { Typography } from '../../Typography';
import { getLegendSeries } from '../utils/useLegend';
import styles from './ChartLegend.module.css';
import { ChartSeriesPreview, ChartSeriesPreviewProvider } from './ChartSeriesPreview';

/**
 * @category Component
 * @group Chart
 */
const ChartLegend = forwardRef((_, ref: ForwardedRef<HTMLDivElement>) => {
    const { legend, layers } = useContext(ChartContext);
    const { Series } = layers;

    const handleClick = useCallback(
        (seriesId: string) => {
            legend.toggleSeries(seriesId);
        },
        [legend]
    );

    const children = useMemo(() => getLegendSeries(Series), [Series]);

    return (
        <ChartSeriesPreviewProvider>
            <div ref={ref} className={styles.root}>
                {children.map((series, index) => {
                    if (series.props.hideFromLegend) return null;

                    const seriesId = series.props.id ?? index;

                    return (
                        <div
                            key={seriesId}
                            className={cx(styles.item, { [styles.disabled]: legend.state[seriesId] })}
                            onClick={handleClick.bind(null, seriesId)}
                        >
                            <div className={styles.preview}>
                                <ChartSeriesPreview>{series}</ChartSeriesPreview>
                            </div>
                            <div className={styles.name}>
                                <Typography noWrap>{series.props.name}</Typography>
                            </div>
                        </div>
                    );
                })}
            </div>
        </ChartSeriesPreviewProvider>
    );
});

ChartLegend.displayName = 'ChartLegend';

/** @ignore */
export { ChartLegend };
