import { SchemeComponentType } from 'api';
import { DiagramNodeData } from 'domain/scheme';
import produce from 'immer';
import { Node } from 'reactflow';
import { getNodeId } from 'store/official-inputs/utils/getNodeId';
import { defaultEdgeColor } from './convertToDiagramEdges';

function getNodeColorByStatus(node: Node<DiagramNodeData>, status: boolean | undefined) {
  return status !== false && node.data.original.backgroundColor ? node.data.original.backgroundColor : defaultEdgeColor;
}

export const applyNodeStatuses = (
  nodes: Node<DiagramNodeData>[],
  statuses: Record<string, boolean>
): Node<DiagramNodeData>[] => {
  if (!statuses) {
    return nodes;
  }

  return nodes.map((node) => {
    const nodeId = getNodeId(node.data.facilityId, node.type as SchemeComponentType);
    const status = statuses[nodeId] ?? node.data.original.status;
    const color = getNodeColorByStatus(node, status);

    return produce(node, (draft) => {
      draft.data.status = status;
      draft.data.backgroundColor = color;
    });
  });
};
