import { faPen, faPlus, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useCallback, useContext } from 'react';
import { DataGridContext } from '../DataGridContext';
import { DataGridButton } from './DataGridButton';
import { DataGridColumnChooser } from './DataGridColumnChooser';

export function RenderDataGridToolbar() {
    const instance = useContext(DataGridContext);
    const { settings } = instance;
    const { chooseColumns, onAdd, onEdit, onDelete } = settings;

    const handleAdd = useCallback(
        (event) => {
            onAdd(event);
        },
        [onAdd]
    );

    const handleEdit = useCallback(
        (event) => {
            if (instance.selectedFlatRows.length === 1) {
                const row = instance.selectedFlatRows[0];
                if (typeof onEdit === 'function') {
                    onEdit(row.original, event);
                } else if ((row as any).startInlineEdit) {
                    (row as any).startInlineEdit();
                }
            }
        },
        [instance.selectedFlatRows, onEdit]
    );

    const handleDelete = useCallback(
        (event) => {
            if (instance.selectedFlatRows.length) {
                onDelete(
                    instance.selectedFlatRows.map((x) => x.original),
                    event
                );
            }
        },
        [instance, onDelete]
    );

    return (
        <>
            {onAdd && (
                <DataGridButton startIcon={<FontAwesomeIcon icon={faPlus} />} onClick={handleAdd}>
                    Add
                </DataGridButton>
            )}
            {onEdit && (
                <DataGridButton enableSelected={1} startIcon={<FontAwesomeIcon icon={faPen} />} onClick={handleEdit}>
                    Edit
                </DataGridButton>
            )}
            {onDelete && (
                <DataGridButton enableSelected startIcon={<FontAwesomeIcon icon={faTrash} />} onClick={handleDelete}>
                    Delete
                </DataGridButton>
            )}
            <div className="flex-grow-1" />
            {chooseColumns && <DataGridColumnChooser />}
        </>
    );
}
