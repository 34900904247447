import React, { FC } from 'react';
import { useController } from 'react-hook-form';
import { TextField } from '../../Input/TextField';
import { Typography } from '../../Typography';
import { useFormCell } from '../hooks';
import { DataGridCellProps } from '../types';

/**
 * @category Component
 * @group Data Grid Cell
 */
export const DataGridTextCell: FC<DataGridCellProps> = ({ value, typographyProps }) => {
    return (
        <Typography noWrap variant="inherit" component="div" {...typographyProps}>
            {value}
        </Typography>
    );
};

/**
 * @category Component
 * @group Data Grid Cell
 */
export const DataGridReadOnlyTextCellEdit: FC<DataGridCellProps> = ({ column, row, typographyProps }) => {
    const {
        form: { control },
        name,
        defaultValue
    } = useFormCell(column, row);

    const { field } = useController({ control, name, defaultValue });

    return (
        <Typography noWrap variant="inherit" component="div" {...typographyProps}>
            {field.value}
        </Typography>
    );
};

/**
 * @category Component
 * @group Data Grid Cell
 */
export const DataGridTextCellEdit: FC<DataGridCellProps> = ({ column, row }) => {
    const {
        form: { register },
        name,
        defaultValue
    } = useFormCell(column, row);

    return (
        <TextField
            disableMargin
            fullWidth
            name={name}
            defaultValue={defaultValue}
            inputRef={register()}
            style={{
                minWidth: 0
            }}
        />
    );
};

/**
 * @category Component
 * @group Data Grid Cell Config
 */
export const DataGridTextCellFull = {
    Cell: DataGridTextCell,
    CellEdit: DataGridTextCellEdit
};
