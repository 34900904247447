import { cloneDeep } from 'lodash';
import React, { forwardRef, PropsWithChildren, ReactElement, ReactNode, Ref, useEffect, useMemo, useRef } from 'react';

import {
    useForm,
    FormProvider,
    SubmitHandler,
    UseFormOptions,
    UseFormMethods,
    DeepPartial,
    UnpackNestedValue,
} from 'react-hook-form';
import { setRef } from '../util';

/**
 * The props for the {@link Form} component.
 * @category Props
 */
export interface FormProps<T extends object> {
    model?: T;
    onSubmit: SubmitHandler<T>;
    settings?: UseFormOptions<T>;
    children: ReactNode;
}

let RenderForm = <T extends object>(
    { model, children, onSubmit, settings }: FormProps<T>,
    ref: Ref<UseFormMethods<T>>
) => {
    const methods = useForm<T>({
        ...settings,
        defaultValues: model as unknown as UnpackNestedValue<DeepPartial<T>>,
    });

    setRef(ref, methods);

    return (
        <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(onSubmit)}>{children}</form>
        </FormProvider>
    );
};

const FormWithRef = forwardRef(RenderForm) as <T extends object>(
    props: FormProps<T> & { ref?: Ref<UseFormMethods<T>> }
) => ReactElement;

/**
 * @category Component
 * @group Form
 */
let Form: typeof RenderForm = ({ model, settings, ...other }, ref) => {
    const index = useRef(0);

    const key = useMemo(() => {
        index.current++;
        return index.current;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [model, settings]);

    return <FormWithRef key={key} ref={ref} model={model} settings={settings} {...other} />;
};

Form = forwardRef(Form) as <T extends object>(props: FormProps<T> & { ref?: Ref<UseFormMethods<T>> }) => ReactElement;

export { Form };
